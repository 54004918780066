import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import WarningModalTailwind from './warningModalTailwind';

class MigrateClients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            migrate_from: { id: 0, name: "Search ..." },
            migrate_to: null,
            affected_clients: {
                total: 0,
                channels: {}
            },
            open: false,
            loading: false,
            loading_affected: false,
            show_warning: false,
            search: "",
            error: null,
            open_migrate: false,
            loading_migrate: false,
            show_warning_migrate: false,
            error_migrate: null,
            loading_affected: false,
            migrate_search: "",
            migrate_from: { id: 0, name: "Search ..." },
            migrate_to: null,
            affected_clients: {
                total: 0,
                channels: {}
            },
            open_reassign: true
        }
    };

    componentDidMount() {
        this.setState({
            agents: Array.isArray(this.props.agents) ? this.props.agents : [],
            migrate_from: this.props.migrateFrom ? this.props.migrateFrom : {}
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            agents: Array.isArray(nextProps.agents) ? nextProps.agents : [],
            migrate_from: nextProps.migrateFrom ? nextProps.migrateFrom : {}
        })
    }

    functions = {
        onSubmit: async () => {
            await this.promisedSetState({
                show_warning_migrate: true
            });
        },
        getAffectedClients: async (agent) => {
            this.state.loading_affected = true;
            this.state.error_migrate = null;
            await this.promisedSetState({ loading_affected: this.state.loading_affected });
            try {
                if (this.state.open_reassign) {
                    let response = await this.calls.getAffectedReassigClients(agent);
                    if (response.data.total) {
                        this.state.affected_clients.total = response.data.total;
                    }
                } else {
                    let response = await this.calls.getAffectedClients(agent);
                    if (response.data.total) {
                        this.state.affected_clients.total = response.data.total;
                        this.state.affected_clients.channels = response.data.channels;
                    }
                }
                this.state.migrate_to = agent;
                await this.promisedSetState({
                    affected_clients: this.state.affected_clients,
                    migrate_to: this.state.migrate_to
                });
            } catch (error) {
                this.state.error_migrate = error.body && error.body.message ? error.body.message : "An error occured";
                await this.promisedSetState({ error_migrate: this.state.error_migrate });
            }
            this.state.loading_affected = false;
            await this.promisedSetState({ loading_affected: this.state.loading_affected });
        },
        handleMigrateClients: async () => {
            await this.promisedSetState({ loading_migrate: true });
            try {
                let data = {
                    migrate_from: this.state.migrate_from.id,
                    migrate_to: this.state.migrate_to.id
                }
                await this.calls.reassignAgentClients(data);
                /*
                if (this.state.open_reassign) {
                    let data = {
                        migrate_from: this.state.migrate_from.id,
                        migrate_to: this.state.migrate_to.id
                    }
                    await this.calls.reassignAgentClients(data);
                } else {
                    let data = {
                        migrate_from: this.state.migrate_from.id,
                        migrate_to: this.state.migrate_to,
                        channels: this.state.affected_clients ? this.state.affected_clients.channels.filter(channel => channel.value === true) : []
                    }
                    await this.calls.migrateClients(data);
                }
                */
                await this.promisedSetState({
                    open_migrate: false,
                    open_reassign: false,
                    show_warning_migrate: false,
                    affected_clients: { total: 0, channels: {} }
                });
                this.props.refreshAgents();
            } catch (error) {
                await this.promisedSetState({
                    error_migrate: error.body && error.body.message ? error.body.message : "An error occured",
                    show_warning_migrate: false
                });
            }
            await this.promisedSetState({ loading_migrate: false });
        }
    }

    calls = {
        getAffectedClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAffectedClients?migrate_from=" + this.state.migrate_from.id + "&migrate_to=" + this.state.migrate_to.id;
            return apiRegister.call(options, url);
        },
        getAffectedReassigClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAffectedReassigClients?migrate_from=" + this.state.migrate_from.id;
            return apiRegister.call(options, url);
        },
        migrateClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/migrateAgentClients";
            return apiRegister.call(options, url);
        },
        reassignAgentClients: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/reassignAgentClients";
            return apiRegister.call(options, url);
        }
    }

    renders = {

    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    render() {
        return (
            <div className='w-full'>

                {/* MIGRATE WARNING */}
                <WarningModalTailwind
                    open={this.state.show_warning_migrate ? true : false}
                    title={"Reassign"}
                    description={"Are you sure you want to reassign ?"}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Yes"}
                    decisionModal={true}
                    loading={this.state.loading_migrate}
                    onClose={async () => {
                        this.state.show_warning_migrate = false;
                        await this.promisedSetState({ show_warning_migrate: this.state.show_warning_migrate });
                    }}
                    onSubmit={() => {
                        console.log("heh");
                        this.functions.handleMigrateClients()
                    }}
                />

                <div className='w-full'>
                    <div className="flex flex-col w-full space-y-4">
                        <div className="w-full">
                            <DropdownTailwind
                                ref="searchDropdown"
                                label={"From:"}
                                searchInput={true}
                                locked={true}
                                selected={this.state.migrate_from && this.state.migrate_from.id ? this.state.migrate_from : { id: 0, name: "Search ..." }}
                                options={Array.isArray(this.state.agents) && this.state.agents.length > 0 ? this.state.agents : [{ id: 0, name: "No agents available" }]}
                                loadingSearch={this.state.loading}
                                onChange={async (user) => { }}
                                onSearch={async (value) => { }}
                            />
                        </div>
                        <div className="w-full">
                            <DropdownTailwind
                                ref="searchDropdown"
                                label={"To:"}
                                searchInput={true}
                                search={this.state.search}
                                selected={this.state.migrate_to !== null ? this.state.migrate_to : { id: 0, name: "Search ..." }}
                                options={
                                    Array.isArray(this.state.agents) && this.state.agents.length > 0
                                        ? this.state.agents.filter(agent => agent.id !== this.state.migrate_from.id)
                                        : [{ id: 0, name: "No agents available" }]
                                }
                                loadingSearch={this.state.loading}
                                onChange={async (agent) => {
                                    this.state.migrate_to = agent;
                                    await this.promisedSetState({
                                        migrate_to: this.state.migrate_to,
                                        error: null
                                    });
                                    //await this.props.getAffectedClients(agent.id)
                                }}
                                onSearch={async (value) => {

                                }}
                            />
                        </div>
                    </div>
                    {
                        false &&
                        this.state.loading_affected &&
                        <div className='flex flex-col w-full pt-8 items-center justify-center space-y-4'>
                            <div style={{ borderTopColor: "transparent" }}
                                class={"w-4 h-4 border-2 border-indigo-600 border-solid rounded-full animate-spin"}></div>
                            <div className="text-sm font-medium text-gray-500">Loading ...</div>
                        </div>
                    }

                    {
                        false &&
                        !this.state.loading_affected && this.state.migrate_to !== null && this.state.affected_clients && this.state.affected_clients.total > 0 &&
                        <div className='bg-blue-100 rounded-md text-blue-500 p-4 text-sm font-medium mt-4 w-full'>
                            <div>
                                This will affect <span className=''>{this.state.affected_clients.total}</span> client(s)
                            </div>
                            {
                                this.state.affected_clients && Array.isArray(this.state.affected_clients.channels) && this.state.affected_clients.channels.length > 0 &&
                                <div className='flex flex-col pt-4'>

                                    <div className='border-t border-blue-300 py-2'>
                                        Channels:
                                    </div>
                                    {
                                        this.state.affected_clients.channels.map((channel, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`flex justify-between items-center ${index === 0 ? 'border-none' : 'border-t'} border-blue-300 ${index === this.state.affected_clients.channels.length - 1 ? 'pt-2' : 'py-2'}`}
                                                >
                                                    <div>{channel.name.charAt(0).toUpperCase() + channel.name.slice(1).replace(/_/, " ")}</div>
                                                    <div className='transform scale-75'>
                                                        <SwitchTailwind
                                                            value={channel.value}
                                                            onSwitch={async () => {
                                                                this.state.affected_clients.channels.find(c => c.name === channel.name).value = !channel.value;
                                                                await this.promisedSetState({
                                                                    affected_clients: this.state.affected_clients
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className='w-full pt-4'>
                            <div className="bg-red-100 text-red-500 rounded-md p-4 font-medium text-sm">
                                {this.state.error}
                            </div>
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default MigrateClients;
