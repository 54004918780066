import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, TrashIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, FilterIcon, UserAddIcon, LightningBoltIcon, ExternalLinkIcon, PauseIcon, MenuAlt1Icon, PlayIcon, PencilAltIcon, RefreshIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import { Doughnut, Line } from 'react-chartjs-2';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';
import CreateBingAdAccount from '../moduleFiles/createBingAdAccount';
import CreateAccountBudget from '../moduleFiles/createAccountBudget';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';
import TableFilters from '../moduleFiles/tableFilters';
var moment = require('moment');

class ClientBudgetList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            loading: true,
            loading_partial: false,
            meta: {},
            user: {},
            limit: 10,
            page: 1,
            sort: "name",
            direction: "descending",
            search: "",
            columns: [
                { name: 'name', value: 'name', min_width: '350px' },
                { name: 'google', value: 'google', min_width: '100px' },
                { name: 'bing', value: 'bing', min_width: '100px' },
                { name: 'facebook', value: 'facebook', min_width: '100px' },
                { name: 'linkedin', value: 'linkedin', min_width: '100px' },
                { name: 'tiktok', value: 'tiktok', min_width: '100px' },
                { name: 'snapchat', value: 'snapchat', min_width: '100px' }
            ],
            client: {},
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "period",
                    placeholder: "Current",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "Current month", value: "current" },
                        { id: 2, name: "Upcoming month", value: "upcoming" },
                    ],
                    selected: { id: 1, name: "Current month", value: "current" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "clientStatus",
                    placeholder: "Client status",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "All client stats", value: "all" },
                        { id: 2, name: "Only active clients", value: "active" },
                        { id: 3, name: "Only paused clients", value: "paused" }
                    ],
                    selected: { id: 1, name: "All client stats", value: "all" },
                    loading: false
                }
            ],
            updated: false,
            selected_tags: [],
        };
    }

    componentDidMount() {
        this.functions.getUser();
        this.functions.client();
        this.functions.getClients(true);
    }

    componentWillReceiveProps(nextProps, nextContext) { }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        getClients: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name;
                });
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.getClients(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({ clients: response.data, meta: response.meta });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                page: 1,
                client: client
            });
            this.functions.getClients();
        },
    };

    renders = {
        date: (date) => {
            let parsed_date = null;
            try {
                parsed_date = moment(date).format("DD MMMM");
            } catch (error) { }
            return parsed_date ? parsed_date : date;
        },
        number: (number) => {
            let parsed_number = null;
            try {
                parsed_number = number.toFixed(2);
            } catch (error) { }
            return parsed_number ? parsed_number : number;
        }
    }

    calls = {
        getClients: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientBudgets?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeClient?client=" + id;
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative">
                            <div className="text-2xl font-bold">
                                Budgets
                            </div>
                        </div>
                        <div className="flex">
                            
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading budgets ...</div>
                    </div>
                }

                {/*CREATE ACCOUNT BUDGET*/}
                <SlideoutTailwind
                    title={"Manage client budgets"}
                    submitButtonText={"Create"}
                    medium={true}
                    noSubmit={true}
                    secondaryButton={false}
                    open={this.state.create_budget ? true : false}
                    loader={this.state.create_budget_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_budget: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_budget_loading: true
                        });
                        this.refs.CreateAccountBudget.functions.createOrder();
                    }}
                >
                    {
                        this.state.create_budget &&
                        <CreateAccountBudget
                            ref="CreateAccountBudget"
                            client={this.state.create_budget}
                            onCreated={async (response) => {

                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_budget_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>
                <SuccessModal
                    open={!!this.state.updated}
                    title="Updated"
                    button="OK"
                    onClose={() => {
                        this.setState({
                            updated: false
                        })
                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                            <TableFilters
                                onAdvancedFilter={async (value, type) => {
                                    this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                        if (item.type === type) {
                                            item.selected = value;
                                        }
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: 10,
                                        advancedFilters: this.state.advancedFilters,
                                    });
                                    this.functions.getClients();
                                }}
                                onLimit={async (value) => {
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: value
                                    })
                                    this.functions.getClients();
                                }}
                                onNext={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.getClients();
                                }}
                                onPrevious={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.getClients();
                                }}
                                onSearch={async (value) => {
                                    await this.promisedSetState({
                                        loading_partial: true,
                                        search: value
                                    });
                                    setTimeout(async () => {
                                        if (value === this.state.search) {
                                            await this.promisedSetState({
                                                page: 1
                                            });
                                            this.functions.getClients(false, value);
                                        }
                                    }, 400);
                                }}
                                limit={this.state.limit}
                                page={this.state.page}
                                total={this.state.meta ? this.state.meta.total : 0}
                                showPaginaton={!this.state.loading}
                                showPaginationSearch={!this.state.loading}
                                showAdvanced={!this.state.loading}
                                advancedFilter={true}
                                advancedFilters={this.state.advancedFilters}
                                filters={[]}
                                hideUserDropdown={true}
                                breadcrumb={"Orders"}
                                buttonTypes={{}}
                                buttonLoaders={{}}
                                tagType={"order"}
                                searchInputValue={this.state.search}
                                search={this.state.search}
                                client={{}}
                            />

                            {/*NO ORDERS*/}
                            {
                                this.state.clients.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No clients available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }
                            {/*TABLE*/}
                            {
                                this.state.clients.length > 0 &&
                                <div className="relative h-full flex flex-1 border-t">
                                    <div className="overflow-auto absolute table-overflow w-full h-full">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-55 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                            <thead className="sticky z-60 bg-white top-0">
                                                <tr>
                                                    <th style={{ maxWidth: "50px", width: "50px" }} scope="col" className='bg-gray-100 border-r bg-opacity-100'></th>
                                                    {this.state.columns.map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.getClients();
                                                                    }
                                                                }}
                                                                style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 bg-gray-100 text-left text-xxs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                            >
                                                                <div className="flex flex-row items-center">
                                                                    <div className={'w-3 h-3 mr-2 bg-' + item.value + '-500 rounded-full'}>
                                                                    </div>
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex flex-col">
                                                                            <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white tiny_table_row divide-gray-300 relative">
                                                {
                                                    this.state.clients.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        item.hover = true;
                                                                        this.setState({
                                                                            clients: this.state.clients
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        item.hover = false;
                                                                        this.setState({
                                                                            clients: this.state.clients
                                                                        })
                                                                    }}
                                                                    className="border-b" key={item.id}>
                                                                    <td className={(item.hover ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " px-4 border-r border-gray-300 overflow-visible"}>
                                                                        <Menu as="div" className="">
                                                                            <div>
                                                                                <Menu.Button className={"cursor-pointer bg-gray-50 flex relative w-6 h-6 justify-center items-center rounded border border-gray-300 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                    <ChevronDownIcon className="w-4" aria-hidden="true" />
                                                                                    {
                                                                                        item.loading &&
                                                                                        <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                            <div style={{ borderTopColor: "transparent" }}
                                                                                                class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                        </div>
                                                                                    }
                                                                                </Menu.Button>
                                                                            </div>
                                                                            <Transition
                                                                                as={Fragment}
                                                                                enter="transition ease-out duration-100"
                                                                                enterFrom="transform opacity-0 scale-95"
                                                                                enterTo="transform opacity-100 scale-100"
                                                                                leave="transition ease-in duration-75"
                                                                                leaveFrom="transform opacity-100 scale-100"
                                                                                leaveTo="transform opacity-0 scale-95"
                                                                            >
                                                                                <Menu.Items className={`border absolute z-60 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                    <div className="py-1">
                                                                                        {
                                                                                            ["Edit"].map((option) => {
                                                                                                return (
                                                                                                    <Menu.Item>
                                                                                                        {({ active }) => (
                                                                                                            <div
                                                                                                                onClick={() => {
                                                                                                                    if (option === "Edit") {
                                                                                                                        this.setState({
                                                                                                                            create_budget: item
                                                                                                                        });
                                                                                                                    }
                                                                                                                }}
                                                                                                                className={cn(
                                                                                                                    active ? (option !== "Delete" ? 'bg-gray-50 text-gray-900' : '') : (option !== "Delete" ? 'text-gray-700' : ''),
                                                                                                                    option === "Delete" && item.disabled ? 'bg-red-500 text-white' : '',
                                                                                                                    'px-4 py-2 text-sm relative items-center flex flex-row cursor-pointer',
                                                                                                                    option == "Claim client" ? 'bg-purple-100 text-purple-500' : ''
                                                                                                                )}
                                                                                                            >
                                                                                                                <div className="flex flex-1">
                                                                                                                    {option}
                                                                                                                </div>
                                                                                                                {
                                                                                                                    option == "Edit" &&
                                                                                                                    <PencilAltIcon className="w-5" />
                                                                                                                }
                                                                                                            </div>

                                                                                                        )}
                                                                                                    </Menu.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </Menu.Items>
                                                                            </Transition>
                                                                        </Menu>
                                                                    </td>
                                                                    <td onClick={() => {
                                                                        this.setState({
                                                                            create_budget: item
                                                                        });
                                                                    }} style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xms border-gray-300"}>
                                                                        <div className="flex flex-row items-center">
                                                                            <div>
                                                                                <div className="h-6 w-6 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                    <img src={item.logo} className="bg-cover" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="ml-4">
                                                                                <div className="flex flex-row items-center">
                                                                                    {item.name}
                                                                                    {item.status == "active" &&
                                                                                        <div className="h-3 w-3 ml-2 rounded-full bg-green-500"></div>
                                                                                    }
                                                                                    {item.status == "disabled" &&
                                                                                        <div className="h-3 w-3 ml-2 rounded-full bg-orange-500"></div>
                                                                                    }
                                                                                    {item.status == "archived" &&
                                                                                        <div className="h-3 w-3 ml-2 rounded-full bg-gray-500"></div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        ["google", "bing", "facebook", "linkedin", "tiktok", "snapchat"].map((channel) => {
                                                                            return (
                                                                                <td className={((this.state.sort === channel || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                    {
                                                                                        (item[channel] && item[channel].connected) &&
                                                                                        item[channel] && item[channel].budget && item[channel].budget.id &&
                                                                                        <div className="flex flex-row items-center justify-start">
                                                                                            {this.renders.number(item[channel].budget.spendLimit)} {item[channel].currency}
                                                                                            {
                                                                                                item[channel].autoRenewBudget &&
                                                                                                <RefreshIcon className="w-3 ml-2"></RefreshIcon>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item[channel] && item[channel].connected) &&
                                                                                        item[channel] && item[channel].budget && item[channel].budget.id &&
                                                                                        <div className="text-xxs text-gray-300 font-medium">{this.renders.date(item[channel].budget.start)}{" - "}{this.renders.date(item[channel].budget.end)}</div>
                                                                                    }
                                                                                    {
                                                                                        (item[channel] && item[channel].connected) &&
                                                                                        !(item[channel] && item[channel].budget && item[channel].budget.id) &&
                                                                                        <div className="text-xms font-medium text-gray-500">
                                                                                            No budget
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        !(item[channel] && item[channel].connected) &&
                                                                                        <div className="text-xms font-medium text-gray-500">
                                                                                            Not connected
                                                                                        </div>
                                                                                    }
                                                                                </td>
                                                                            )
                                                                        })
                                                                    }
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientBudgetList;