import React, { PureComponent, Fragment } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import FeatherIcon from 'feather-icons-react';
import SlideDown from 'react-slidedown';
import cx from "classnames";
import AdvancedReportCellNumber from './advancedReportCellNumber';
import AdvancedReportCellChart from './advancedReportCellChart';
import AdvancedReportCellText from './advancedReportCellText';
import AdvancedReportCellCreative from './advancedReportCellCreative';
import AdvancedReportCellPreview from './advancedReportCellPreview';
import AdvancedReportCellTable from './advancedReportCellTable.js';
import {
    ArrowsExpandIcon,
    CalendarIcon,
    CheckIcon,
    ClipboardCheckIcon,
    ClipboardIcon,
    DesktopComputerIcon,
    DeviceMobileIcon,
    DuplicateIcon,
    ExclamationCircleIcon,
    MenuIcon,
    MinusIcon,
    PencilAltIcon,
    PencilIcon,
    PlusIcon,
    RefreshIcon,
    SaveIcon,
    SwitchVerticalIcon,
    TrashIcon,
    ViewGridAddIcon,
} from '@heroicons/react/outline';
import InputTailwind from './inputTailwind.js';
import { Menu, Transition } from '@headlessui/react';
import { userRegister } from "../services/userRegister";
import ReactTooltip from 'react-tooltip';
import WarningModalTailwind from './warningModalTailwind';

export default class ReactGridLayout extends PureComponent {

    static defaultProps = {
        className: 'layout',
        breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        cols: { lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 },
        maxRows: 11,
        margin: [0, 0],
        containerPadding: [0, 0],
        rowHeight: 100
    };

    constructor(props) {
        super(props);
        this.eleRefs = []
        this.state = {
            layout: [],
            items: [],
            cell_items: [],
            breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
            cols: { lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 },
            isBounded: this.props.isBounded || false,
            preventCollision: this.props.preventCollision || false,
            verticalCompact: this.props.verticalCompact || false,
            hover: {},
            rows: [1, 2, 3, 4, 5, 6],
            visible_rows: 6,
            groups: [],
            data_sources: [],
            initiated: false,
            user: {},
        };
        this.onEditItem = this.onEditItem.bind(this);
        this.onUpdateItem = this.onUpdateItem.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onRefreshItem = this.onRefreshItem.bind(this);
        this.onCopyItem = this.onCopyItem.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
    }

    updateCell(cell) {
        try {
            this.refs[cell].parseData();
        } catch (error) {
            //console.log(error);
        }
    }

    updateLoaderCell(cell) {
        try {
            this.refs[cell].setLoader();
        } catch (error) {
            //console.log(error);
        }
    }

    getUniqueErrors(errors) {
        const uniqueErrors = new Set();

        errors.forEach(item => {
            if (typeof item === 'string') {
                uniqueErrors.add(item);
            } else if (item.message) {
                uniqueErrors.add(item.message);
            }
        });

        return Array.from(uniqueErrors);
    }

    componentDidMount() {
        this.setState({
            enableSaveButton: this.props.enableSaveButton,
            loadingSave: this.props.loadingSave,
            channels: this.props.channels ? this.props.channels : [],
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            cell_items: this.props.items ? this.props.items : [],
            items: this.props.initialItems ? this.props.initialItems : [],
            groups: this.props.groups ? this.props.groups : [],
            static: this.props.static,
            tabLoading: this.props.tabLoading,
            transparent_cells: this.props.transparent_cells,
            reportBackgroundEnabled: this.props.reportBackgroundEnabled,
            reportBackgroundColor: this.props.reportBackgroundColor,
            master_template: this.props.masterTemplate,
            scale: this.props.scale,
            template: this.props.template
        });
        this.functions.user();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            enableSaveButton: nextProps.enableSaveButton,
            loadingSave: nextProps.loadingSave,
            channels: nextProps.channels ? nextProps.channels : [],
            accounts: nextProps.accounts ? nextProps.accounts : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            cell_items: nextProps.items ? nextProps.items : [],
            items: nextProps.initialItems ? nextProps.initialItems : [],
            groups: nextProps.groups ? nextProps.groups : [],
            static: nextProps.static,
            tabLoading: nextProps.tabLoading,
            transparent_cells: nextProps.transparent_cells,
            reportBackgroundEnabled: nextProps.reportBackgroundEnabled,
            reportBackgroundColor: nextProps.reportBackgroundColor,
            master_template: nextProps.masterTemplate,
            scale: nextProps.scale,
            template: nextProps.template
        });
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
            if (this.state.user && this.state.user.userRole === "sales") {
                await this.promisedSetState({
                    static: true
                });
            }
        },

    };

    template(cell) {
        try {
            if (cell.typeOfBlock === "table" || cell.typeOfBlock === "number" || cell.typeOfBlock === "chart" || cell.typeOfBlock === "preview") {
                return (cell.data_sources.channels && Object.keys(cell.data_sources.channels).length < 1) && Object.keys(cell.data_sources.accounts).length < 1 && Object.keys(cell.data_sources.campaigns).length < 1 && Object.keys(cell.data_sources.adgroups).length < 1 && Object.keys(cell.data_sources.ads).length < 1
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    disableGrid() {
        this.setState({
            disabled_grid: true
        })
    }

    enableGrid() {
        this.setState({
            disabled_grid: false
        })
    }

    createElement(el, items, group) {
        const i = el.add ? '+' : el.i;

        const renderBlock = (el, group) => {

            const { typeOfBlock, header, data, i, format, loading, metric, chart } = el;

            switch (typeOfBlock) {
                case 'chart':
                    return (
                        <AdvancedReportCellChart
                            ref={el.i}
                            source={el}
                            items={items}
                            page={group}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            data={data}
                            metric={metric}
                            header={header}
                            loading={loading}
                            typeOfBlock={typeOfBlock}
                            static={this.state.static}
                            transparent_cells={this.state.transparent_cells}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            onUpdateItem={(data) => {
                                el.data = data;
                                this.onUpdateItem(el);
                            }}
                        />
                    );
                case 'table':
                    return (
                        <AdvancedReportCellTable
                            ref={el.i}
                            source={el}
                            items={items}
                            page={group}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            static={this.state.static}
                            transparent_cells={this.state.transparent_cells}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            updateSort={(data) => {
                                if (!el.settings) {
                                    el.settings = {};
                                }
                                el.settings.sortTable = data;
                                this.onUpdateItem(el);
                            }}
                            updateData={(data) => {

                            }}
                        />
                    );
                case 'img':
                    return (
                        <AdvancedReportCellCreative
                            onImage={(value) => {
                                el.data = value.data;
                                el.settings = { ...el.settings, ...value.settings };
                                el.format = "image";
                                this.onUpdateItem(el);
                            }}
                            onVideo={(value) => {
                                el.data = value.data;
                                el.settings = { ...el.settings, ...value.settings };
                                el.format = "video";
                                this.onUpdateItem(el);
                            }}
                            page={group}
                            source={el}
                            header={header}
                            data={data}
                            format={format}
                            transparent_cells={this.state.transparent_cells}
                            typeOfBlock={typeOfBlock}
                            static={this.state.static}
                        />
                    );
                case 'preview':
                    return (
                        <AdvancedReportCellPreview
                            ref={el.i}
                            source={el}
                            page={group}
                            masterTemplate={this.state.master_template}
                            transparent_cells={this.state.transparent_cells}
                            static={this.state.static}
                            cell_items={this.state.cell_items}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            onUpdate={(value) => {
                                el.preview_url = value.data;
                                this.onUpdateItem(el);
                            }}
                        />
                    );
                case 'input':
                    return (
                        <>
                            <AdvancedReportCellText
                                header={header}
                                data={data}
                                typeOfBlock={typeOfBlock}
                                page={group}
                                source={el}
                                transparent_cells={this.state.transparent_cells}
                                onInput={(htmlState, textAlign, verticalAlign) => {
                                    el.data = htmlState;
                                    el.textAlign = textAlign;
                                    el.verticalAlign = verticalAlign;
                                    this.onUpdateItem(el);
                                }}
                                static={this.state.static}
                            />
                        </>
                    );
                case 'number':
                    return (
                        <AdvancedReportCellNumber
                            ref={el.i}
                            source={el}
                            items={items}
                            page={group}
                            campaigns={this.state.campaigns}
                            adgroups={this.state.adgroups}
                            ads={this.state.ads}
                            onEditItem={() => {
                                this.onEditItem(el);
                            }}
                            onUpdateItem={(data) => {
                                el.data = data;
                                this.onUpdateItem(el);
                            }}
                            static={this.state.static}
                            transparent_cells={this.state.transparent_cells}
                        />
                    );

                default:
                    return (
                        <div className="flex justify-center items-center text-center font-bold text-xs h-full">
                            Something went wrong
                        </div>
                    );
            }
        };

        return (
            <div id={el.i + "_cell"} key={i} data-grid={el}>
                {!el.add &&
                    <>
                        <div
                            onMouseOver={() => {
                                if (!this.state.static) {
                                    try {
                                        var select = document.getElementById("select_" + el.i);
                                        select.style.opacity = 100;
                                    } catch (error) { }
                                    try {
                                        var move = document.getElementById("move_" + el.i);
                                        move.style.opacity = 100;
                                        var copy = document.getElementById("copy_" + el.i);
                                        copy.style.opacity = 100;
                                        var remove = document.getElementById("remove_" + el.i);
                                        remove.style.opacity = 100;
                                        var edit = document.getElementById("edit_" + el.i);
                                        edit.style.opacity = 100;
                                    } catch (error) { }
                                }
                            }}
                            onMouseLeave={() => {
                                if (!this.state.static) {
                                    try {
                                        if (!el.selected) {
                                            var select = document.getElementById("select_" + el.i);
                                            select.style.opacity = 0;
                                        }
                                    } catch (error) { }
                                    try {
                                        var elem = document.getElementById("move_" + el.i);
                                        elem.style.opacity = 0;
                                        var copy = document.getElementById("copy_" + el.i);
                                        copy.style.opacity = 0;
                                        var remove = document.getElementById("remove_" + el.i);
                                        remove.style.opacity = 0;
                                        var edit = document.getElementById("edit_" + el.i);
                                        edit.style.opacity = 0;
                                    } catch (error) { }
                                }
                            }}
                            className={(el && el.typeOfBlock == "input" ? "" : "overflow-hidden") + (!(el.settings && el.settings.disable_margin_top) ? " pt-2 " : "") + (!(el.settings && el.settings.disable_margin_left) ? " pl-2 " : "") + (!(el.settings && el.settings.disable_margin_right) ? " pr-2 " : "") + (!(el.settings && el.settings.disable_margin_bottom) ? " pb-2 " : "") + " w-full h-full relative"}>
                            {
                                !(Array.isArray(el.errors) && el.errors.length > 0) &&
                                !this.template(el) &&
                                <div
                                    id={el.i + "_preview"}
                                    style={{ cursor: "default" }}
                                    className={(!(el && el.settings && (el.settings.background_color || el.settings.transparent)) ? " bg-white " : "") + (el && el.settings && el.settings.shadow ? " shadow-md " : "") + (el && el.typeOfBlock == "input" ? "" : " overflow-hidden ") + (!(el.settings && (el.settings.disable_margin_top || el.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(el.settings && (el.settings.disable_margin_top || el.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(el.settings && (el.settings.disable_margin_bottom || el.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(el.settings && (el.settings.disable_margin_bottom || el.settings.disable_margin_left)) ? " rounded-bl-md " : "") + " w-full h-full relative cursor-default"}>
                                    {renderBlock(el, group)}
                                </div>

                            }
                            {
                                !(Array.isArray(el.errors) && el.errors.length > 0) &&
                                this.template(el) &&
                                <div style={{ cursor: "default" }} className="text p-4 text-center w-full h-full overflow-hidden shadow-xl cursor-default">
                                    <div className="rounded-md bg-orange-100 p-4">
                                        <div className="flex">
                                            <div className="">
                                                <div className="text-sm text-orange-700">
                                                    <ul role="list" className="list-disc text-sm space-y-1 pl-5">
                                                        <li><span className="text-sm font-medium">Select datasources</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => {
                                        this.onEditItem(el);
                                    }} style={{ top: "22px", right: "22px" }}
                                        className="transition-all duration-200 absolute px-2 leading-none flex items-center hover:bg-purple-200 justify-center mr-1 h-10 bg-purple-100 text-purple-500 rounded">
                                        {
                                            el.typeOfBlock === "table" &&
                                            <MenuIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                        }
                                        {
                                            el && el.settings && el.settings.title && el.settings.title !== "" &&
                                            <div className="ml-2 text-sm font-medium">{el.settings.title}</div>
                                            ||
                                            <div className="ml-2 text-sm font-medium">...</div>
                                        }
                                    </button>
                                </div>
                            }
                            {
                                (Array.isArray(el.errors) && el.errors.length > 0) &&
                                <div style={{ cursor: "default" }} className="text p-4 text-center w-full h-full overflow-hidden shadow-xl cursor-default">
                                    <div className="rounded-md bg-orange-100 p-4">
                                        <div className="flex">
                                            <div className="">
                                                <div className="text-sm text-orange-700">
                                                    <ul role="list" className="list-disc text-sm space-y-1 pl-5">
                                                        {this.getUniqueErrors(el.errors).map((error, index) => (
                                                            <li key={index}>{error}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div onClick={() => {
                                        if (!el.loading) {
                                            this.onRefreshItem(el)
                                        }
                                    }} style={{ top: "22px", right: "22px" }}
                                        className={(el.loading ? " cursor-not-allowed " : "cursor-pointer ") + " transition-all duration-200 absolute px-2 leading-none flex hover:bg-purple-200 items-center justify-center mr-1 h-10 bg-purple-100 text-purple-500 rounded"}>
                                        <RefreshIcon className={"stroke-current w-4"} icon={"align-justify"} />
                                        <div className="ml-2 whitespace-no-wrap text-sm font-medium">Refresh data</div>
                                    </div>
                                </div>
                            }
                            {
                                !this.state.static &&
                                <div id={"select_" + el.i} style={{ top: "22px", left: "22px" }}
                                    className={((el.w < 2 || el.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + (el.loading ? " cursor-not-allowed " : "cursor-pointer ") + (el.selected ? " bg-purple-500 text-white " : "bg-purple-100 text-purple-500 hover:bg-purple-200") + " opacity-0 transition-all duration-200 absolute leading-none flex items-center justify-center mr-1 rounded"}
                                    onClick={() => {
                                        if (!el.loading) {
                                            this.onSelectItem(el);
                                        }
                                    }}>
                                    {
                                        el.selected &&
                                        <CheckIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                    }
                                </div>
                            }
                            {
                                !this.state.master_template &&
                                !this.state.static &&
                                <div id={"remove_" + el.i} style={{ bottom: "22px", left: "22px" }}
                                    className={(group.mobile ? "cursor-not-allowed " : "cursor-pointer ") + ((el.w < 2 || el.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + " opacity-0 transition-all duration-200 absolute leading-none flex items-center border-red-100 hover:bg-red-200 hover:border-red-200 justify-center mr-1 bg-red-100 text-red-500 rounded"}
                                    onClick={() => {
                                        if (!group.mobile) {
                                            this.onRemoveItem(el)
                                        }
                                    }}>
                                    <TrashIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                </div>
                            }
                            {
                                !this.state.master_template &&
                                <div style={{ bottom: "22px", right: "21px" }} className="flex absolute flex-row">
                                    {
                                        !this.state.static &&
                                        <div id={"edit_" + el.i} onClick={() => {
                                            if (!el.loading) {
                                                this.onEditItem(el);
                                            }
                                        }}
                                            className={((el.w < 2 || el.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + (el.loading ? " cursor-not-allowed " : "cursor-pointer ") + "opacity-0 transition-all duration-200 leading-none flex items-center justify-center hover:bg-purple-200 hover:border-purple-200 border-purple-100  bg-purple-100 text-purple-500 rounded rounded-r-none"}>
                                            <PencilAltIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                        </div>
                                    }
                                    {
                                        !this.state.static &&
                                        <div id={"copy_" + el.i} onClick={() => {
                                            if (!el.loading) {
                                                this.onCopyItem(el);
                                            }
                                        }}
                                            className={((el.w < 2 || el.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + (el.loading ? " cursor-not-allowed " : "cursor-pointer ") + " opacity-0 transition-all duration-200 leading-none flex items-center justify-center hover:bg-purple-200 hover:border-purple-200 border-purple-100 bg-purple-100 text-purple-500"}>
                                            <DuplicateIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                        </div>
                                    }
                                    {
                                        !this.state.static &&
                                        <div id={"move_" + el.i} className={((el.w < 2 || el.h < 2) ? "w-6 h-6 " : "w-10 h-10 ") + " opacity-0 cursor-pointer drag-handle hover:bg-purple-200 hover:border-purple-200 border-purple-100 transition-all duration-200 leading-none flex items-center justify-center  bg-purple-100 text-purple-500 rounded rounded-l-none"}>
                                            <ArrowsExpandIcon className={(el.w < 2 || el.h < 2) ? "h-3" : "h-5"} />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        );
    }

    async onLayoutChange(layout, group) {
        this.state.items = this.state.items.map((item) => {
            if (group.id == item.group) {
                for (let m = 0; m < layout.length; m++) {
                    if (item.i === layout[m].i) {
                        item.x = layout[m].x;
                        item.y = layout[m].y;
                        item.w = layout[m].w;
                        item.h = layout[m].h;
                    }
                    if (group.mobile && item.i === layout[m].i) {
                        item.x_mobile = layout[m].x;
                        item.y_mobile = layout[m].y;
                        item.w_mobile = layout[m].w;
                        item.h_mobile = layout[m].h;
                    }
                    if (!group.mobile && item.i === layout[m].i) {
                        item.x_desktop = layout[m].x;
                        item.y_desktop = layout[m].y;
                        item.w_desktop = layout[m].w;
                        item.h_desktop = layout[m].h;
                    }
                }
            }
            return item;
        });
        await this.promisedSetState({
            items: this.state.items
        });
        if (this.props.onGridChange) {
            this.props.onGridChange(this.state.items);
        }
    }

    onUpdateItem(item) {
        if (this.props.onUpdateItem) {
            this.props.onUpdateItem(item);
        }
    }

    onRefreshItem(item) {
        if (this.props.onRefreshItem) {
            this.props.onRefreshItem(item);
        }
    }

    onRemoveItem(item) {
        if (this.props.onRemove) {
            this.props.onRemove(item);
        }
    }

    onCopyItem(item) {
        if (this.props.onCopyItem) {
            this.props.onCopyItem(item);
        }
    }

    onEditItem(item) {
        if (this.props.onEditItem) {
            this.props.onEditItem(item);
        }
    }

    onSelectItem(item) {
        if (this.props.onSelectItem) {
            this.props.onSelectItem(item);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div id={"grid_box_container"}>

                <WarningModalTailwind
                    open={this.state.remove_page ? true : false}
                    title={"Delete page"}
                    description={'Are you sure you want to delete this page? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Delete"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({
                            remove_page: false
                        });
                    }}
                    onSubmit={async () => {
                        if (this.state.remove_page) {
                            this.props.removePage(this.state.remove_page);
                            await this.promisedSetState({
                                remove_page: false
                            });
                        }
                    }}
                />

                {
                    this.state.groups.map((item, index) => {

                        //PAGE ABOVE CLOSED
                        let page_above_closed = false;
                        try {
                            if (this.state.groups[(index - 1)] && this.state.groups[(index - 1)].closed) {
                                page_above_closed = true;
                            }
                        } catch (error) {
                            console.log(error);
                        }

                        let rows_array = [];
                        if (item.mobile && "mobile_rows" in item) {
                            for (let i = 0; i < item.mobile_rows; i++) {
                                rows_array.push(i);
                            }
                        } else {
                            for (let i = 0; i < item.rows; i++) {
                                rows_array.push(i);
                            }
                        }
                        return (
                            <div key={item.id}>
                                <div className="w-full border-1.5 border-white relative">
                                    {
                                        !this.state.static &&
                                        <div
                                            style={{ zIndex: 150 - index }}
                                            className={(item.closed ? "" : "border-b") + " top-0 p-4 bg-white w-full sticky flex flex-row justify-center align-middle items-center"}>
                                            <div
                                                className="transition-all leading-none flex items-center justify-center text-sm font-medium w-10 h-10 bg-purple-100 text-purple-500 border-r rounded-l-md">
                                                {index + 1}
                                            </div>
                                            <Menu as="div" className="relative inline-block text-left mr-2">
                                                {
                                                    this.state.master_template &&
                                                    <div
                                                        className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                                }
                                                <div>
                                                    <Menu.Button
                                                        className="transition-all duration-200 cursor-pointer hover:bg-purple-200 leading-none flex items-center justify-center text-sm font-medium w-10 h-10 bg-purple-100 text-purple-500 rounded-r-md">
                                                        <SwitchVerticalIcon className="h-5 w-5" />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items
                                                        className="absolute left-0 z-60 mt-2 origin-top-left border rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div
                                                            style={{ overflowX: "auto", maxHeight: "80vh" }}
                                                            className="py-1">
                                                            {
                                                                Array.isArray(this.state.groups) &&
                                                                this.state.groups.map((row, index) => {
                                                                    return { title: "Move to -> ", name: row.name }
                                                                }).map((group, inner_index) => {
                                                                    return (
                                                                        <Menu.Item>
                                                                            {({ active }) => (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        if (index !== inner_index) {
                                                                                            if (inner_index >= this.state.groups.length) {
                                                                                                var k = inner_index - this.state.groups.length + 1;
                                                                                                while (k--) {
                                                                                                    this.state.groups.push(undefined);
                                                                                                }
                                                                                            }
                                                                                            this.state.groups.splice(inner_index, 0, this.state.groups.splice(index, 1)[0]);
                                                                                            this.setState({
                                                                                                groups: this.state.groups
                                                                                            }, () => {
                                                                                                if (this.props.updateGroups) {
                                                                                                    this.props.updateGroups(this.state.groups);
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    className={cx(
                                                                                        active ? 'bg-gray-100 text-gray-900 whitespace-nowrap' : 'text-gray-700',
                                                                                        'block px-4 py-2 text-sm font-medium cursor-pointer whitespace-nowrap'
                                                                                    )}
                                                                                >
                                                                                    {group.title} <span
                                                                                        className="text-purple-500">{group.name ? group.name : inner_index + 1}</span>
                                                                                </div>
                                                                            )}
                                                                        </Menu.Item>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                            {
                                                !(this.state.master_template || this.state.groups.length < 2) &&
                                                <button
                                                    data-tip='' data-for={item.id + "_remove"} key={item.id + "_remove"}
                                                    disabled={(this.state.master_template || this.state.groups.length < 2)}
                                                    className={((this.state.master_template || this.state.groups.length < 2) ? "opacity-25 cursor-not-allowed" : "") + " mr-2 transition-all hover:bg-red-200 duration-200 leading-none flex items-center justify-center px-4 font-medium text-sm h-10 bg-red-100 text-red-500 rounded-md"}
                                                    onClick={() => {
                                                        if (this.props.removePage && this.state.groups.length > 1 && !this.state.master_template) {
                                                            //this.props.removePage(item);
                                                            this.setState({
                                                                remove_page: item
                                                            });
                                                        }
                                                    }}>
                                                    <TrashIcon className="h-5" />
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_remove"}>
                                                        Remove page
                                                    </ReactTooltip>
                                                </button>
                                            }
                                            {
                                                !(Array.isArray(this.state.cell_items) &&
                                                    this.state.cell_items.filter((inner_item) => {
                                                        return inner_item.group == item.id
                                                    }).filter((item) => {
                                                        return !item.abstract && item.selected
                                                    }).length > 0) &&
                                                <div
                                                    className={((!this.state.enableSaveButton || item.closed) ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                    <div onClick={() => {
                                                        if (this.props.saveReport && !item.closed && this.state.enableSaveButton) {
                                                            this.props.saveReport();
                                                        }
                                                    }}
                                                        className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-600") + " transition-all mr-2 overflow-hidden  duration-200 relative leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-500 text-white rounded-md"}>
                                                        <div className="mr-2">Save report</div>
                                                        <SaveIcon className="h-5" />
                                                        {
                                                            this.state.loadingSave &&
                                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20">
                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            <div className="flex flex-1 mr-2">
                                                <div className="w-full">
                                                    <InputTailwind
                                                        readOnly={this.state.master_template}
                                                        noHeight={true}
                                                        noMarginTop={true}
                                                        bgcolor={false}
                                                        title={"Name"}
                                                        sign={false}
                                                        noTitle={true}
                                                        noBorder={false}
                                                        placeholder={"Page name ..."}
                                                        white={false}
                                                        value={item.name}
                                                        type={"text"}
                                                        size={'full'}
                                                        onChange={(value) => {
                                                            item.name = value;
                                                            this.setState({
                                                                groups: this.state.groups
                                                            }, () => {
                                                                if (this.props.updateGroups) {
                                                                    this.props.updateGroups(this.state.groups);
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                !item.mobile &&
                                                item.rows > 6 &&
                                                <div data-tip='' data-for={item.id + "_ppxt_warning"} key={item.id + "_ppxt_warning"} className="relative mr-2 text-sm font-medium border-1.5 border-yellow-500 flex items-center justify-center px-4 h-10 bg-yellow-100 text-yellow-500 rounded-md">
                                                    <ExclamationCircleIcon className="w-5" />
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_ppxt_warning"}>
                                                        OBS!Not eligible for Power Point.Maximum 6 rows
                                                    </ReactTooltip>
                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.cell_items) &&
                                                this.state.cell_items.filter((inner_item) => {
                                                    return inner_item.group == item.id
                                                }).filter((item) => {
                                                    return !item.abstract && item.selected
                                                }).length > 0 &&
                                                <div
                                                    className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                    <div onClick={() => {
                                                        if (this.props.editSelected && !item.closed) {
                                                            this.props.editSelected();
                                                        }
                                                    }}
                                                        className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-600") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-500 text-white rounded-md"}>
                                                        <div className="mr-2">Edit selected</div>
                                                        <CheckIcon className="h-5" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.cell_items) &&
                                                this.state.cell_items.filter((inner_item) => {
                                                    return inner_item.group == item.id
                                                }).filter((item) => {
                                                    return !item.abstract && item.selected
                                                }).length < 1 &&
                                                <div
                                                    className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                    <div onClick={() => {
                                                        if (this.props.selectAllCells && !item.closed) {
                                                            this.props.selectAllCells(item, true);
                                                        }
                                                    }} data-tip='' data-for={item.id + "_select_all"} key={item.id + "_select_all"} className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-100 text-purple-500  rounded-md"}>
                                                        <ClipboardCheckIcon className="h-5" />
                                                        <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_select_all"}>
                                                            Select all cells on this page
                                                        </ReactTooltip>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                Array.isArray(this.state.cell_items) &&
                                                this.state.cell_items.filter((inner_item) => {
                                                    return inner_item.group == item.id
                                                }).filter((item) => {
                                                    return !item.abstract && item.selected
                                                }).length > 0 &&
                                                <div
                                                    className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                    <div onClick={() => {
                                                        if (this.props.selectAllCells && !item.closed) {
                                                            this.props.selectAllCells(item, false);
                                                        }
                                                    }} data-tip='' data-for={item.id + "_select_all"} key={item.id + "_select_all"} className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-100 text-purple-500  rounded-md"}>
                                                        <ClipboardIcon className="h-5" />
                                                        <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_select_all"}>
                                                            Deselect all cells on this page
                                                        </ReactTooltip>
                                                    </div>
                                                </div>
                                            }
                                            <div
                                                className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row relative mr-1"}>
                                                <div onClick={() => {
                                                    if (this.props.updateDates && !item.closed) {
                                                        this.props.updateDates(item);
                                                    }
                                                }} data-tip='' data-for={item.id + "_update_date"}
                                                    key={item.id + "_update_date"}
                                                    className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-100 text-purple-500  rounded-md"}>
                                                    <CalendarIcon className="h-5" />
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_update_date"}>
                                                        Update page dates
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            <div
                                                className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row mr-1"}>
                                                <div onClick={async () => {

                                                    item.mobile = false;
                                                    item.disable_grid = true;

                                                    //SET ALL COORDINATES TO DESKTOP
                                                    try {
                                                        this.state.items = this.state.items.map((cell) => {
                                                            if (cell.group == item.id) {
                                                                cell.x_mobile = cell.x;
                                                                cell.y_mobile = cell.y;
                                                                cell.w_mobile = cell.w;
                                                                cell.h_mobile = cell.h;
                                                                if ('x_desktop' in cell && cell.x_desktop !== undefined) {
                                                                    cell.x = cell.x_desktop;
                                                                }
                                                                if ('y_desktop' in cell && cell.y_desktop !== undefined) {
                                                                    cell.y = cell.y_desktop;
                                                                }
                                                                if ('h_desktop' in cell && cell.h_desktop !== undefined) {
                                                                    cell.h = cell.h_desktop;
                                                                }
                                                                if ('w_desktop' in cell && cell.w_desktop !== undefined) {
                                                                    cell.w = cell.w_desktop;
                                                                }
                                                            }
                                                            return cell;
                                                        });
                                                    } catch (error) {
                                                        console.log(error);
                                                    }

                                                    await this.promisedSetState({
                                                        groups: this.state.groups,
                                                        items: this.state.items
                                                    });
                                                    if (this.props.updateGroups) {
                                                        this.props.updateGroups(this.state.groups);
                                                    }
                                                    item.disable_grid = false;
                                                    await this.promisedSetState({
                                                        groups: this.state.groups,
                                                    });
                                                    if (this.props.updateGroups) {
                                                        this.props.updateGroups(this.state.groups);
                                                    }
                                                }} data-tip='' data-for={item.id + "_desktop"} key={item.id + "_desktop"} className={(!item.mobile ? "bg-purple-500 text-white " : "bg-purple-100 text-purple-500 hover:bg-purple-200 ") + (item.closed ? " cursor-not-allowed" : " cursor-pointer") + " transition-all overflow-hidden  duration-200 relative leading-none flex items-center text-sm font-medium justify-center px-4 h-10  rounded-l-md"}>
                                                    <DesktopComputerIcon className="h-5" />
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_desktop"}>
                                                        Desktop view
                                                    </ReactTooltip>
                                                </div>
                                                <div onClick={async () => {

                                                    item.mobile = true;
                                                    item.disable_grid = true;

                                                    //SET ALL COORDINATES TO DESKTOP
                                                    this.state.items = this.state.items.map((cell) => {
                                                        if (cell.group == item.id) {
                                                            cell.x_desktop = cell.x;
                                                            cell.y_desktop = cell.y;
                                                            cell.w_desktop = cell.w;
                                                            cell.h_desktop = cell.h;
                                                            if ('x_mobile' in cell && cell.x_mobile !== undefined) {
                                                                cell.x = cell.x_mobile;
                                                            }
                                                            if ('y_mobile' in cell && cell.y_mobile !== undefined) {
                                                                cell.y = cell.y_mobile;
                                                            }
                                                            if ('h_mobile' in cell && cell.h_mobile !== undefined) {
                                                                cell.h = cell.h_mobile;
                                                            }
                                                            if ('w_mobile' in cell && cell.w_mobile !== undefined) {
                                                                cell.w = cell.w_mobile;
                                                            }
                                                        }
                                                        return cell;
                                                    });

                                                    await this.promisedSetState({
                                                        groups: this.state.groups,
                                                        items: this.state.items
                                                    });
                                                    if (this.props.updateGroups) {
                                                        this.props.updateGroups(this.state.groups);
                                                    }
                                                    item.disable_grid = false;

                                                    await this.promisedSetState({
                                                        groups: this.state.groups,
                                                    });
                                                    if (this.props.updateGroups) {
                                                        this.props.updateGroups(this.state.groups);
                                                    }
                                                }} data-tip='' data-for={item.id + "_mobile"} key={item.id + "_mobile"} className={(item.mobile ? "bg-purple-500 text-white " : "bg-purple-100 text-purple-500 hover:bg-purple-200 ") + (item.closed ? " cursor-not-allowed" : " cursor-pointer") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 rounded-r-md"}>
                                                    <DeviceMobileIcon className="h-5" />
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_mobile"}>
                                                        Mobile view
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            <div
                                                className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex flex-row relative mr-1"}>
                                                <div onClick={() => {
                                                    if (this.props.updateGroups && !item.closed && !this.state.master_template) {
                                                        this.props.updatePage(item);
                                                    }
                                                }} data-tip='' data-for={item.id + "_edit_page"}
                                                    key={item.id + "_edit_page"}
                                                    className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-100 text-purple-500  rounded-md"}>
                                                    <PencilIcon className="h-5" />
                                                    {
                                                        this.state.master_template &&
                                                        <div
                                                            className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                                    }
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_edit_page"}>
                                                        Edit page
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            <div
                                                className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex relative flex-row mr-1"}>
                                                <div onClick={() => {
                                                    if (this.props.addCell && !item.closed && !this.state.master_template) {
                                                        this.props.addCell(item.id);
                                                    }
                                                }} data-tip='' data-for={item.id + "_add_cell"}
                                                    key={item.id + "_add_cell"}
                                                    className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200") + " transition-all overflow-hidden  duration-200 relative  leading-none flex items-center text-sm font-medium justify-center px-4 h-10 bg-purple-100 text-purple-500  rounded-md"}>
                                                    <ViewGridAddIcon className="h-5" />
                                                    {
                                                        this.state.tabLoading &&
                                                        <div
                                                            className="w-full h-full absolute flex flex-col justify-center items-center bg-purple-500">
                                                            <div className="">
                                                                <div style={{ borderTopColor: "transparent" }}
                                                                    className="w-3 h-3 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.master_template &&
                                                        <div
                                                            className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                                    }
                                                    <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_add_cell"}>
                                                        Add cell
                                                    </ReactTooltip>
                                                </div>
                                            </div>
                                            <div
                                                className={(item.closed ? "opacity-25 cursor-not-allowed" : "") + " flex relative justify-end"}>
                                                {
                                                    this.state.master_template &&
                                                    <div
                                                        className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                                }
                                                <div className="flex flex-row">
                                                    <div
                                                        className="transition-all duration-200  leading-none flex items-center text-sm font-medium justify-center px-4 border-grey-300 border-r h-10 bg-purple-100 text-purple-500 rounded-md rounded-r-none">
                                                        {
                                                            item.mobile &&
                                                            "mobile_rows" in item &&
                                                            <div className="">{item.mobile_rows} rows</div>
                                                            ||
                                                            <div className="">{item.rows} rows</div>
                                                        }
                                                    </div>
                                                    <div onClick={() => {
                                                        if (this.props.addRows && !item.closed && !this.state.master_template) {
                                                            this.props.addRows(item);
                                                        }
                                                    }}
                                                        className={(item.closed ? "cursor-not-allowed" : "cursor-pointer hover:bg-purple-200 ") + " transition-all duration-200  leading-none flex items-center text-xs font-medium justify-center border-grey-300 border-r w-10 h-10 bg-purple-100 text-purple-500"}>
                                                        <PlusIcon className="h-5" />
                                                    </div>
                                                    <div onClick={() => {
                                                        if (item.mobile) {
                                                            if (this.props.removeRows && item.mobile_rows > 6 && !item.closed && !this.state.master_template) {
                                                                this.props.removeRows(item);
                                                            }
                                                        } else {
                                                            if (this.props.removeRows && item.rows > 6 && !item.closed && !this.state.master_template) {
                                                                this.props.removeRows(item);
                                                            }
                                                        }
                                                    }}
                                                        className={(item.mobile ? (item.mobile_rows > 6 && !item.closed ? "cursor-pointer hover:bg-purple-200" : "cursor-not-allowed ") : (item.rows > 6 && !item.closed ? "cursor-pointer hover:bg-purple-200" : "cursor-not-allowed ")) + " transition-all duration-200  leading-none flex items-center text-xs font-medium justify-center mr-1 w-10 h-10 bg-purple-100 text-purple-500 rounded-md rounded-l-none"}>
                                                        <MinusIcon className="h-5" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div onClick={() => {
                                                if (this.props.clonePage && (!this.state.scale || (this.state.scale && this.state.template))) {
                                                    this.props.clonePage(item);
                                                }
                                            }} data-tip='' data-for={item.id + "_clone_page"}
                                                key={item.id + "_clone_page"}
                                                className="transition-all relative duration-200 cursor-pointer leading-none hover:bg-purple-200 flex items-center justify-center px-4 flex-row mr-1 text-sm font-medium h-10 bg-purple-100 text-purple-500 rounded-md">
                                                <DuplicateIcon className="h-5" />
                                                {
                                                    this.state.master_template &&
                                                    <div
                                                        className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                                }
                                                <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_clone_page"}>
                                                    Clone page
                                                </ReactTooltip>
                                            </div>
                                            <div onClick={async () => {
                                                item.closed = !item.closed;
                                                if (!item.closed) {
                                                    item.disable_grid = true;
                                                }
                                                await this.promisedSetState({
                                                    groups: this.state.groups
                                                });
                                                if (this.props.updateGroups) {
                                                    this.props.updateGroups(this.state.groups);
                                                }
                                                item.disable_grid = false;
                                                await this.promisedSetState({
                                                    groups: this.state.groups
                                                });
                                                if (this.props.updateGroups) {
                                                    this.props.updateGroups(this.state.groups);
                                                }
                                            }} data-tip='' data-for={item.id + "_minimize"} key={item.id + "_minimize"} className=" transition-all duration-200 cursor-pointer leading-none hover:bg-purple-200 flex items-center justify-center text-xs font-medium px-4 h-10 bg-purple-100 text-purple-500 rounded-md">
                                                <FeatherIcon className={"stroke-current font-bold"} size={18} icon={!item.closed ? "minimize-2" : "maximize-2"} />
                                                <ReactTooltip place={(index > 0 && page_above_closed) ? "bottom" : ""} effect='solid' id={item.id + "_minimize"}>
                                                    {
                                                        item.closed &&
                                                        <span>Expand</span>
                                                    }
                                                    {
                                                        !item.closed &&
                                                        <span>Close</span>
                                                    }
                                                </ReactTooltip>
                                            </div>
                                        </div>
                                    }
                                    <SlideDown closed={item.closed && !this.state.static}>
                                        <div style={{
                                            ...(item.enable_image && item.enable_background_full && {
                                                backgroundImage: "url(" + item.pageImage + ")",
                                                backgroundSize: "cover"
                                            }),
                                            ...(!item.enable_image && item.enable_background_full && { backgroundColor: "#" + item.background_color }),
                                            ...(!item.enable_image && !item.enable_background_full && (this.state.reportBackgroundEnabled ? { backgroundColor: "#" + this.state.reportBackgroundColor } : { backgroundColor: "#f5f5f5" }))
                                        }} className="">
                                            {/*style={item.enable_image && item.enable_background_full ? { backgroundImage: "url(" + item.pageImage + ")", backgroundSize: "cover" } : (item.enable_background_full ? { backgroundColor: "#" + item.background_color } : (this.state.reportBackgroundEnabled ? { backgroundColor: "#" + this.state.reportBackgroundColor } : { backgroundColor: "#f5f5f5" }))}*/}
                                            <div
                                                style={item.mobile && ("mobile_rows" in item) ? { ...{ height: 100 * item.mobile_rows + "px" } } : { ...{ height: 100.7 * item.rows + "px" } }}>
                                                <div style={{
                                                    ...(item.mobile && { maxWidth: "450px", margin: 'auto' }),
                                                    ...(!item.mobile && { maxWidth: "1200px", margin: 'auto' }),
                                                    ...(!item.enable_image && item.enable_background && { backgroundColor: "#" + item.background_color }),
                                                    ...(item.enable_image && !item.enable_background_full && { backgroundImage: "url(" + item.pageImage + ")", backgroundSize: "cover" })
                                                }} className={(item.enable_image || !item.enable_background ? "bg-transparent" : "") + " relative z-10 h-full border-2 border-gray-200 border-dashed overflow-hidden"}>
                                                    {/*item.mobile ? { ...{ maxWidth: "450px", margin: 'auto' }, ...(!item.enable_image && item.enable_background && { backgroundColor: "#" + item.background_color }) } : { ...{ maxWidth: "1200px", margin: 'auto' }, ...(!item.enable_image && item.enable_background && { backgroundColor: "#" + item.background_color }) }*/}
                                                    {
                                                        (item.disable_grid || this.state.disabled_grid) &&
                                                        <div className="absolute w-full h-full flex justify-center bg-white bg-opacity-50 items-center">
                                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.disabled_grid &&
                                                        !item.disable_grid &&
                                                        <ResponsiveGridLayout
                                                            {...this.props}
                                                            measureBeforeMount={true}
                                                            draggableHandle=".drag-handle"
                                                            rowHeight={100}
                                                            layout={Array.isArray(this.state.items) ? this.state.items.filter((inner_item) => {
                                                                return !inner_item.abstract
                                                            }).filter((inner_item) => {
                                                                return inner_item.group == item.id
                                                            }).map((cell) => {
                                                                let cell_coordinates = { x: cell.x, y: cell.y, h: cell.h, w: cell.w };
                                                                return cell_coordinates;
                                                            }) : []}
                                                            autoSize={false}
                                                            maxRows={item.mobile && ("mobile_rows" in item) ? item.mobile_rows : item.rows}
                                                            //static={this.state.static}
                                                            //isResizable={this.state.static}
                                                            cols={item.mobile ? { lg: 3, md: 3, sm: 3, xs: 3, xxs: 3 } : { lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 }}
                                                            isDraggable={!this.state.static && !this.state.master_template}
                                                            isBounded={this.state.isBounded}
                                                            verticalCompact={true}
                                                            preventCollision={true}
                                                            compactType={null}
                                                            onDragStop={() => { }}
                                                            onResizeStop={() => { }}
                                                            onLayoutChange={(layout, layouts) => {
                                                                this.onLayoutChange(layout, item);
                                                            }}
                                                            resizeHandles={this.state.static || this.state.master_template ? [] : ['se']}
                                                        >
                                                            {
                                                                Array.isArray(this.state.items) &&
                                                                this.state.items.filter((inner_item) => {
                                                                    return !inner_item.abstract
                                                                }).filter((inner_item) => {
                                                                    return inner_item.group == item.id
                                                                }).map((inner_item) => {
                                                                    return this.createElement(inner_item, this.state.items, item);
                                                                })
                                                            }
                                                        </ResponsiveGridLayout>
                                                    }
                                                    {
                                                        !this.state.static &&
                                                        <div className={"pattern absolute inset-0 overflow-hidden"} style={{ zIndex: '-1' }}>
                                                            {rows_array.map((e, i) => {
                                                                return (
                                                                    <div key={i} className={"flex flex-row w-full items-stretch relative"} style={{ height: '100px' }}>
                                                                        <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        {
                                                                            !item.mobile &&
                                                                            <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        }
                                                                        {
                                                                            !item.mobile &&
                                                                            <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        }
                                                                        {
                                                                            !item.mobile &&
                                                                            <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        }
                                                                        {
                                                                            !item.mobile &&
                                                                            <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        }
                                                                        {
                                                                            !item.mobile &&
                                                                            <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-r-2 border-dashed border-gray-200"}></span>
                                                                        }
                                                                        <span className={(item.mobile ? "w-1/3 " : "w-1/6 ") + (i !== 0 ? "border-t-2" : "") + " border-dashed border-gray-200"}></span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </SlideDown>
                                </div>
                                {
                                    !(index === this.state.groups.length - 1) &&
                                    item.closed &&
                                    <div class="w-full border-b"></div>
                                }
                                {
                                    !this.state.tabLoading &&
                                    !this.state.static &&
                                    !item.closed &&
                                    <div className={((index === this.state.groups.length - 1) ? "" : "bg-gray-50 border-b") + " cursor-pointer flex justify-center align-middle items-center w-full pb-4 pt-4"}>
                                        <div className="relative mr-2 rounded-md overflow-hidden">
                                            {
                                                this.state.master_template &&
                                                <div className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                            }
                                            <button className="text-sm w-48 font-medium hover:bg-purple-200 flex items-center px-4 h-10 justify-center bg-purple-100 text-purple-500 rounded-md transition-all duration-200"
                                                onClick={() => {
                                                    if (this.props.addPage && !this.state.master_template) {
                                                        this.props.addPage(index + 1);
                                                    }
                                                }}>
                                                Add page
                                            </button>
                                        </div>
                                        <div className="relative ml-2 rounded-md overflow-hidden">
                                            {
                                                this.state.master_template &&
                                                <div className="absolute left-0 right-0 top-0 cursor-not-allowed bottom-0 bg-white bg-opacity-75 z-20"></div>
                                            }
                                            <button className="text-sm w-48 font-medium hover:bg-purple-200 flex items-center px-4 h-10 justify-center bg-purple-100 text-purple-500 rounded-md transition-all duration-200"
                                                onClick={() => {
                                                    if (this.props.addCell && !item.closed && !this.state.master_template) {
                                                        this.props.addCell(item.id);
                                                    }
                                                }}>
                                                Add cell
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

const ResponsiveGridLayout = WidthProvider(Responsive);
