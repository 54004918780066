import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import SwitchTailwind from './switchTailwind';
import { LockClosedIcon } from '@heroicons/react/outline';

class TableColumnSorter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            name_col: {},
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            columns: this.props.columns,
        })
        let buff = this.state.columns.filter(item => item.value === "name");
        if (buff.length > 0) { await this.promisedSetState({ name_col: buff[0] }) }

        if (buff.length > 0) {
            let cols = this.state.columns.filter(item => item.value !== "name");
            await this.promisedSetState({
                columns: cols
            })
        }

    }

    functions = {
        saveColumnOrder: () => {
            if (this.props.saveColumnOrder) {
                let buff = this.state.columns.unshift(this.state.name_col);
                this.props.saveColumnOrder(this.state.columns)
            }
        }

    };

    renders = {
        columnName: (item) => {
            try {
                if (item.name) {
                    return item.name.charAt(0).toUpperCase() + item.name.slice(1);
                } else {
                    return `Column ${item.index ? item.index + 1 : 0 + 1}`
                }
            } catch (error) {
                console.log(error)
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    render() {
        const SortableItem = SortableElement(({ item, index }) => {
            return (
                <div style={{ zIndex: 99999999 }}
                    className={"w-full flex flex-row justify-center cursor-pointer align-middle items-center mb-2 select-none transform transition-all duration-150"}>
                    <div className='bg-gray-100 h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md font-semibold text-sm'>
                        {item.index + 2}
                    </div>
                    <div className="text-xs flex flex-1 ml-2">
                        <div className="flex flex-1 flex-col truncate">
                            <div className="font-semibold text-sm capitalize">
                                {this.renders.columnName(item)}
                            </div>
                        </div>
                    </div>
                    <div className='flex space-x-2 items-center justify-center'>
                        <div className='transform scale-75'>
                            <div className='flex flex-col w-full'>
                                <SwitchTailwind
                                    value={item.value === "name" ? true : !item.disabled ? true : false}
                                    locked={item.value === "name" ? true : false}
                                    disabled={item.value === "name" ? true : false}
                                    onSwitch={async () => {
                                        item.disabled = !item.disabled;
                                        await this.promisedSetState({
                                            columns: this.state.columns
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="bg-gray-100 h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md">
                            {
                                item.value === "name" &&
                                <div>
                                    <LockClosedIcon className="stroke-current w-5 h-5" />
                                </div>
                            }
                            {
                                item.value !== "name" &&
                                <FeatherIcon className="stroke-current transform rotate-90"
                                    size={18}
                                    icon={'code'}
                                />
                            }

                        </div>
                    </div>
                </div>
            )
        });

        const SortableList = SortableContainer(({ items }) => (
            <div className="">
                {items.map((item, index) => {
                    item.index = index;
                    if (item) {
                        return (
                            <SortableItem
                                index={index}
                                item={item}
                                disabled={item.value === "name" ? true : false}
                                locked={item.value === "name" ? true : false}
                            />
                        )
                    }
                })}
            </div>
        ));

        const onSortEnd = async ({ oldIndex, newIndex }) => {
            if (!Array.isArray(this.state.columns)) {
                this.state.columns = [];
            }
            this.state.columns = arrayMove(this.state.columns, oldIndex, newIndex);
            await this.promisedSetState({
                columns: this.state.columns
            })
        };

        return (
            <>
                {/* STATIC NAME COLUMN */}
                {
                    this.state.name_col &&
                    <div style={{ zIndex: 99999999 }}
                        className="w-full flex flex-row justify-center cursor-pointer align-middle items-center pb-2">
                        <div
                            className="bg-gray-100 h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md font-semibold text-sm">
                            1
                        </div>
                        <div className="text-xs flex flex-1 ml-2">
                            <div className="flex flex-1 flex-col truncate">
                                <div className="font-semibold text-sm capitalize">
                                    {this.renders.columnName(this.state.name_col)}
                                </div>
                            </div>
                        </div>
                        <div className='flex space-x-2 items-center justify-center'>
                            <div className='transform scale-75'>
                                <div className='flex flex-col w-full'>
                                    <SwitchTailwind
                                        value={true}
                                        locked={true}
                                        onSwitch={async () => {
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="bg-gray-100 h-10 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md font-semibold text-sm">
                                <LockClosedIcon className="stroke-current w-5 h-5" />
                            </div>

                        </div>
                    </div>
                }

                {/* OTHER COLUMNS */}
                <div className="pb-6">
                    <SortableList
                        items={Array.isArray(this.state.columns) ? this.state.columns.filter((item) => { return item.value !== "name" }) : []}
                        onSortEnd={onSortEnd}
                        axis="y"
                        distance={1}
                    />
                </div>

            </>
        )
    }
}

export default TableColumnSorter;



