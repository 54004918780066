import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, DownloadIcon, ExclamationCircleIcon, PencilAltIcon, TrashIcon, UserGroupIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { clientRegister } from '../services/clientRegister';
import CreateAgent from '../moduleFiles/createAgent';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import UpdateAgent from '../moduleFiles/updateAgent';

class AgentListNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            download: false,
            loading: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            orders: [],
            order: { id: 0, name: "Filter by order" },
            drafts: [],
            campaigns: [],
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'email', value: 'email', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '150px' },
                //{ name: 'manager', value: 'agencyManager', min_width: '150px' },
                { name: 'role', value: 'userRole', min_width: '150px' },
                { name: 'last login', value: 'lastLogin', min_width: '150px' },
                { name: 'created', value: 'createdAt', min_width: '150px' },
                { name: 'agency', value: 'agency', min_width: '250px' },
                { name: 'orders', value: 'orders', min_width: '250px' },
                { name: 'campaigns', value: 'campaigns', min_width: '250px' }
            ],
            status: {},
            stats: [],
            client: {},
            agency: {},
            filters: [],
            search: "",
            throttling: {}
        }
    };

    componentDidMount() {
        this.functions.query();
    }

    functions = {
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");

            //CHECK IF AGENCY
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("agency=") !== -1) {
                    let agency = queries[i].replace("agency=", "");
                    await this.promisedSetState({
                        agency: agency ? { id: agency } : this.state.agency
                    });
                }
            }

            this.functions.wizards(true);
        },
        wizards: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.wizards();
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        campaigns: response.data,
                        meta: response.meta
                    });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        update: async (item, option) => {
            this.state.campaigns = this.state.campaigns.map((agent) => {
                if (agent.id === item.id) {
                    agent.loading = true;
                }
                return agent;
            });
            //item.loading = true;
            await this.promisedSetState({
                campaigns: this.state.campaigns
            });
            let body = {};
            //item.agencyManager = !item.agencyManager;
            try {
                body = {
                    id: item.id,
                    userRole: option === "Change to admin" ? "admin" : (option === "Change to manager" ? "manager" : (option === "Change to regular" ? "regular" : "sales")),
                    agencyManager: option === "Change to manager" ? true : false
                }
                let response = await this.calls.update(body);
            } catch (error) {
                //item.agencyManager = !item.agencyManager;
            }
            this.state.campaigns = this.state.campaigns.map((agent) => {
                if (agent.id === item.id) {
                    agent.loading = false;
                    agent = {...agent, ...body}
                }
                return agent;
            });
            //item.loading = false;
            await this.promisedSetState({
                campaigns: this.state.campaigns
            });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ campaigns: this.state.campaigns });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ campaigns: this.state.campaigns.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.campaigns.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ campaigns: this.state.campaigns });
            }
        },
        listAgentsEmails: async () => {
            await this.promisedSetState({ download: true });
            try {
                let emails = await this.calls.listAgentsEmails();
                this.functions.exportToCSv(emails.data);
            } catch (error) { }
            await this.promisedSetState({ download: false });
        },
        menu: (option, row) => {
            if (option === "Login as user") {
                console.log("http://localhost:5001/login/" + row.id);
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/login/" + row.id);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/login/" + row.id);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/login/" + row.id);
                }
            } else if (["Change to admin", "Change to manager", "Change to regular", "Change to sales"].includes(option)) {
                this.functions.update(row, option);
            } else if (option === "Delete") {
                this.setState({
                    remove: row
                });
            } else if (option === "Update password") {
                this.setState({
                    update_agent: row
                });
            }
        },
        exportToCSv: (items) => {

            var headers = {
                agency: 'Agency',
                name: "Name",
                email: "Email"
            };

            var itemsFormatted = [];

            items.forEach((item) => {
                itemsFormatted.push({
                    listing: item.agency ? item.agency : "-",
                    phone: item.name ? item.name : "-",
                    email: item.email ? item.email : "-"
                });
            });

            var fileTitle = 'User Emails';

            exportCSVFile(headers, itemsFormatted, fileTitle);

            function exportCSVFile(headers, items, fileTitle) {
                if (headers) {
                    items.unshift(headers);
                }
                // Convert Object to JSON
                var jsonObject = JSON.stringify(items);
                var csv = convertToCSV(jsonObject);
                var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    var link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }

            function convertToCSV(objArray) {
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = '';
                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }
                    str += line + '\r\n';
                }
                return str;
            }

        }
    };

    calls = {
        wizards: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + "&order=" + this.state.order.id + "&status=" + this.state.status.value + (this.state.agency && this.state.agency.id ? "&agency=" + this.state.agency.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "");
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgent?id=" + data.id;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgent?id=" + id;
            return apiRegister.call(options, url);
        },
        listAgentsEmails: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgentsEmails";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                {/*TOP MENU*/}
                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        miniMizeOnLoad={false}
                        minimizeMenu={(option) => {
                            if (this.props.minimizeMenu) {
                                this.props.minimizeMenu(option);
                            }
                        }}
                        onButton={(type) => {
                            if (type === "new") {
                                this.setState({
                                    create_order: true
                                })
                            } else if (type == 'download') {
                                this.functions.listAgentsEmails();
                            }
                        }}
                        onLimit={async (value) => {
                            await this.promisedSetState({
                                page: 1,
                                limit: value
                            })
                            this.functions.wizards();
                        }}
                        onNext={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        onPrevious={async (value) => {
                            await this.promisedSetState({
                                page: value
                            })
                            this.functions.wizards();
                        }}
                        onSearch={async (value) => {
                            await this.promisedSetState({
                                loading_partial: true,
                                search: value
                            });
                            setTimeout(async () => {
                                if (value === this.state.search) {
                                    await this.promisedSetState({
                                        page: 1
                                    });
                                    this.functions.wizards(false, value);
                                }
                            }, 400);
                        }}
                        limit={this.state.limit}
                        page={this.state.page}
                        total={this.state.meta ? this.state.meta.total : 0}
                        showPaginaton={!this.state.loading}
                        showPaginationSearch={!this.state.loading}
                        showFilters={!this.state.loading}
                        hideUserDropdown={true}
                        buttons={[
                            { text: "Create new", icon: UserGroupIcon, value: "new", loading: false, show: !this.state.loading },
                            { text: "Download", icon: DownloadIcon, value: "download", loading: this.state.download, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={false}
                        lockClient={false}
                    />
                </div>

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading agents ...</div>
                    </div>
                }

                {/*UPDATE AGENT*/}
                <SlideoutTailwind
                    title={"Update Agent"}
                    submitButtonText={"Update"}
                    large={true}
                    secondaryButton={false}
                    open={this.state.update_agent ? true : false}
                    loader={this.state.update_agent_loading}
                    onCancel={() => {
                        this.promisedSetState({ update_agent: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            update_agent_loading: true
                        });
                        this.refs.UpdateAgent.functions.updateAgent();
                    }}
                >
                    {
                        this.state.update_agent &&
                        <UpdateAgent
                            ref="UpdateAgent"
                            agent={this.state.update_agent}
                            onCreated={async () => {
                                await this.promisedSetState({
                                    update_agent: false,
                                    update_agent_loading: false
                                });
                            }}
                            onError={async () => {
                                await this.promisedSetState({
                                    update_agent_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*CREATE AGENT*/}
                <SlideoutTailwind
                    title={"Create Agent"}
                    submitButtonText={"Create"}
                    //large={true}
                    secondaryButton={false}
                    open={this.state.create_order ? true : false}
                    loader={this.state.create_order_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_order: false, order: {} });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_order_loading: true
                        });
                        this.refs.CreateAgent.functions.createAgent();
                    }}
                >
                    {
                        this.state.create_order &&
                        <CreateAgent
                            ref="CreateAgent"
                            admin={true}
                            onCreated={async (order) => {
                                await this.promisedSetState({
                                    create_order: false,
                                    create_order_loading: false,
                                    sort: 'createdAt',
                                    direction: 'descending'
                                });
                                this.functions.wizards();
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_order_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/* TABLE */}
                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className="shadow bg-white rounded-lg w-full h-full overflow-hidden">

                            {/*NO DRAFTS*/}
                            {
                                this.state.campaigns.length < 1 &&
                                <div className="border-t border-gray-200 h-full flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No agents available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.campaigns.length > 0 &&
                                <div className="relative overflow-scroll h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white">
                                            <tr>
                                                <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase"></th>
                                                {this.state.columns.map((item, index) => {
                                                    return (
                                                        <th
                                                            onClick={async () => {
                                                                if (!item.noSort) {
                                                                    await this.promisedSetState({
                                                                        page: 1,
                                                                        sort: item.value,
                                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                    })
                                                                    this.functions.wizards();
                                                                }
                                                            }}
                                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                            scope="col"
                                                            className={(this.state.sort === item.value ? "bg-gray-100 bg-opacity-75 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer" : "")) + " px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <div className="mr-4">{item.name}</div>
                                                                {
                                                                    !item.noSort &&
                                                                    <div className="flex flex-col">
                                                                        <ChevronUpIcon className={(this.state.direction === 'ascending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 -mb-1 cursor-pointer"} aria-hidden="true" />
                                                                        <ChevronDownIcon className={(this.state.direction === 'descending' && this.state.sort === item.value ? "text-gray-900" : "opacity-25") + " h-3 w-3 cursor-pointer"} aria-hidden="true" />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">
                                            {
                                                this.state.campaigns.map((item, index) => {
                                                    return (
                                                        <Fragment>
                                                            <tr key={item.id} className="border-b">
                                                                <td style={{ width: "100px", maxWidth: "100px" }} className={"px-4 sm:px-6 py-4 flex flex-row border-gray-300"}>
                                                                    <Menu as="div" className="relative inline-block text-left">
                                                                        <div>
                                                                            <Menu.Button className={"cursor-pointer flex relative w-10 h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                                                                {
                                                                                    item.loading &&
                                                                                    <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                    </div>
                                                                                }
                                                                            </Menu.Button>
                                                                        </div>
                                                                        <Transition
                                                                            as={Fragment}
                                                                            enter="transition ease-out duration-100"
                                                                            enterFrom="transform opacity-0 scale-95"
                                                                            enterTo="transform opacity-100 scale-100"
                                                                            leave="transition ease-in duration-75"
                                                                            leaveFrom="transform opacity-100 scale-100"
                                                                            leaveTo="transform opacity-0 scale-95"
                                                                        >
                                                                            <Menu.Items className="border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                                <div className="py-1">
                                                                                    {
                                                                                        (["Login as user", "Update password", "Change to admin", "Change to manager", "Change to regular", "Change to sales", "Delete"]).filter((option) => {
                                                                                            if (option === "Change to admin" && item.userRole === "admin") {
                                                                                                return false;
                                                                                            } else if (option === "Change to manager" && item.agencyManager) {
                                                                                                return false;
                                                                                            } else if (option === "Change to regular" && !item.agencyManager && (!item.userRole || item.userRole === "regular")) {
                                                                                                return false;
                                                                                            } else if (option === "Change to sales" && item.userRole === "sales") {
                                                                                                return false;
                                                                                            } else return true;
                                                                                        }).map((option) => {
                                                                                            return (
                                                                                                <Menu.Item>
                                                                                                    {({ active }) => (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                this.functions.menu(option, item);
                                                                                                            }}
                                                                                                            className={cn(
                                                                                                                active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                'px-4 py-2 text-sm relative flex flex-row cursor-pointer'
                                                                                                            )}
                                                                                                        >
                                                                                                            {option}
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </Menu.Items>
                                                                        </Transition>
                                                                    </Menu>
                                                                </td>
                                                                <td onClick={() => {
                                                                    this.functions.menu("Login as user", item);
                                                                }} className={(this.state.sort === 'name' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    {item.name ? item.name : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'email' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.email}
                                                                </td>
                                                                <td className={(this.state.sort === 'status' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-green-100 text-green-500"]: item.status === "active",
                                                                                ["bg-purple-100 text-purple-500"]: item.status === "upcoming",
                                                                                ["bg-gray-100 text-gray-500"]: item.status === "pending",
                                                                                ["bg-red-100 text-red-600"]: item.status === "disabled"
                                                                            })
                                                                        }>
                                                                        {item.status ? item.status : "-"}
                                                                    </span>
                                                                </td>
                                                                {/*
                                                                <td className={(this.state.sort === 'agencyManager' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full",
                                                                            {
                                                                                ["bg-purple-100 text-purple-500"]: item.agencyManager,
                                                                                ["bg-gray-100 text-gray-500"]: !item.agencyManager
                                                                            })
                                                                        }>
                                                                        {item.agencyManager ? "manager" : "regular"}
                                                                    </span>
                                                                </td>
                                                                */}
                                                                <td className={(this.state.sort === 'userRole' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                    <span
                                                                        className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full bg-purple-100 text-purple-500",
                                                                            {/*
                                                                                ["bg-purple-100 text-purple-500"]: item.userRole,
                                                                                ["bg-gray-100 text-gray-500"]: !item.userRole
                                                                            */})
                                                                        }>
                                        
                                                                        {item.userRole && item.userRole === "sales"  ? "Sales" : (item.userRole && item.userRole === "admin" ? "Admin" : (item.agencyManager ? "Manager" : "Regular"))}
                                                                    </span>
                                                                </td>
                                                                <td className={(this.state.sort === 'lastLogin' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.lastLogin ? moment(item.lastLogin).format("YYYY-MM-DD hh:mm a") : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'createdAt' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer  items-center text-sm   border-gray-300"}>
                                                                    {item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD") : "-"}
                                                                </td>
                                                                <td onClick={() => {
                                                                    //this.functions.menu("Edit", item);
                                                                }} className={(this.state.sort === 'agency' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium  cursor-pointer hover:text-purple-500 items-center text-sm hover:underline  border-gray-300"}>
                                                                    <div onClick={() => {
                                                                        this.props.history.push("/admin/agents?agency=" + (item.agency.id ? item.agency.id : item.agency._id));
                                                                        this.functions.query();
                                                                    }} className="flex flex-row items-center">
                                                                        <div className="h-8 w-8 overflow-hidden flex justify-center items-center">
                                                                            <img src={item.agency.logo} className="bg-cover" />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            {item.agency.name ? item.agency.name : "-"}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className={(this.state.sort === 'orders' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                    {item.orders ? item.orders : "-"}
                                                                </td>
                                                                <td className={(this.state.sort === 'campaigns' ? "bg-gray-50" : "") + " px-4 sm:px-6 py-4 font-medium cursor-pointer items-center text-sm border-gray-300"}>
                                                                    {item.campaigns ? item.campaigns : "-"}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default AgentListNew;
