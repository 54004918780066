import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, BeakerIcon, StopIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrderABTest from '../moduleFiles/createOrderABTest';
import SuccessModal from '../moduleFiles/successModal';
import moment from 'moment';
import { random, round } from '@turf/turf';
import SyncWithBing from '../moduleFiles/syncWithBing';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import AbTest from '../moduleFiles/AbTest';
import AdvancedReportCellChart from '../moduleFiles/advancedReportCellChart';
import Loader from '../components/loader';


class orderPerformance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_abtest: {},
            wizzard: "",
            edit_loader: false,
            loading: true,
            order: {},
            client: {},
            campaigns: [],
            campaign: { channel: 'all' },
            adgroups: [],
            adgroup: {},
            ad: {},
            ads: [],
            keywords: [],
            negative_keywords: [],
            search_terms: [],
            channels: [],
            channel: { id: "all", name: "All channels", value: "all" },
            syncToBing: false,
            importAlertShow: false,
            status: {},
            search_term: {},
            edit_negative: {},
            edit_positive: {},
            keyword: {},
            selected_adgroup: { id: 0, name: "Select adgroup" },
            create_wizard: false,
            create_keyword: false,
            edit_keyword: false,
            add_keyword: false,
            locked_adgroup: false,
            selected_adgroup_error: false,
            pagination: "1",
            negative_pagination: "1",
            sortBy: 'name',
            orderBy: 'DESC',
            pageSize: 50,
            loading_wizard: false,
            open: false,
            performance: false,
            columns: [
                { name: 'name', value: 'name', min_width: '250px' },
                { name: 'start', value: 'startDate', min_width: '250px' },
                { name: 'end', value: 'endDate', min_width: '250px' },
                { name: 'status', value: 'status', min_width: '250px' },
                { name: 'channels', value: 'channels', min_width: '250px' }
            ],
            error_budget: false,
            budget_planer: false,
            month_dates: [],
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }

        await this.functions.getCampaigns();
        //this.props.updateStatistics();
        //if (scheduleActivated && budgetPlanerActivated || (scheduleAdgroups && Obkect.keys(scheduleAdgroups).length > 0 && Obkect.keys(scheduleAdgroups).filter((budgetPlanerActivated && scheduleActivated)).length > 0)) {
        //await this.functions.getPerformance();
        //this.functions.getOrder();

        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });

    }

    functions = {
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        calculate_performance: async () => {

            await this.promisedSetState({ loading: true });
            try {

                let month = moment().month();
                let today = moment().startOf('month');
                let month_dates = [];
                let dates = []
                while (moment(today).month() === month) {
                    month_dates.push({
                        day: today.format("YYYY-MM-DD"),
                        spend: 0,
                        daily: 0,
                        channels: [],
                    });
                    if (moment().isAfter(today)) {
                        dates.push(today.format("YYYY-MM-DD"));
                    }
                    today.add(1, 'd');
                }
                dates.pop();

                let campaigns = [];

                if (!this.state.order.maxBudgetEnabled) {
                    let days = {
                        scheduleMonday: true,
                        scheduleTuesday: true,
                        scheduleWednesday: true,
                        scheduleThursday: true,
                        scheduleFriday: true,
                        scheduleSaturday: true,
                        scheduleSunday: true
                    }
                    let monthly = this.renders.budgetPlannerGraph(this.state.order.maxBudget, days, false)[0];
                    // console.log("NO BUDGET ENABLED", (parseInt(this.state.order.maxBudget) / 30).toFixed(2));
                    month_dates = month_dates.map((month_date) => {
                        month_date.daily += monthly.daily_budget;
                        //month_date.daily += parseInt(this.state.order.maxBudget) / 30;
                        //month_date.daily = +month_date.daily.toFixed(2);
                        return month_date;
                    });
                    //console.log(month_dates, "MONTH DATES");
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        campaign.fullfilled = true;
                        month_dates = month_dates.map((month_date) => {
                            if (!month_date.channels.includes(campaign.channel)) {
                                month_date.channels.push(campaign.channel);
                            }
                            return month_date;
                        });
                        return campaign;
                    });

                    let collect_data_campaigns = this.state.campaigns.map((campaign) => { return campaign.campaignId });
                    if (dates.length > 0) {
                        let response = await this.calls.getPerformanceData({
                            campaigns: collect_data_campaigns,
                            dates: dates,
                        });
                        response.data.map((item) => {
                            month_dates = month_dates.map((month_date) => {
                                if (month_date.day == item.createdAtString) {
                                    month_date.spend += +item.spend;
                                }
                                return month_date;
                            });
                        })
                    }

                    await this.promisedSetState({
                        campaigns: this.state.campaigns,
                        month_dates: month_dates,
                        loading: false
                    });


                } else {
                    if (this.state.order.googleBudgets && this.state.order.googleBudgets.length > 0) {

                        this.state.order.googleBudgets.map((item) => {
                            this.state.campaigns.map((campaign) => {
                                if (campaign.budgetId && campaign.budgetId === item.id) {
                                    campaigns.push(campaign.campaignId);
                                }
                            })
                            if (item.budgetPlaner && item.budgetPlanerTotal && item.budgetPlanerTotal > 0) {
                                let days = {
                                    scheduleMonday: true,
                                    scheduleTuesday: true,
                                    scheduleWednesday: true,
                                    scheduleThursday: true,
                                    scheduleFriday: true,
                                    scheduleSaturday: true,
                                    scheduleSunday: true
                                }
                                month_dates = month_dates.map((month_date) => {

                                    /*
                                                                        let date = false;
                                                                        if (this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).length > 0) {
                                                                            date = true;
                                                                        }
                                                                        let month = this.renders.budgetPlannerGraph(item.budgetPlanerTotal, days, false)[0];
                                    
                                                                        if (date) {
                                                                            let current_budget_plan = false;
                                                                            this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).map((plan) => {
                                                                                if (!current_budget_plan) {
                                                                                    current_budget_plan = plan;
                                                                                } else {
                                                                                    if (moment(plan.date).isAfter(moment(current_budget_plan.date)) && moment(plan.date).isBefore(moment(month_date.day).add(2, 'h'))) {
                                                                                        current_budget_plan = plan;
                                                                                    }
                                                                                }
                                                                            });
                                    
                                                                            let budget = current_budget_plan.budget;
                                                                            if (this.state.order.budgetSplit && this.state.order.bingBudgets.length > 0) {
                                                                                budget = current_budget_plan.budget * (this.state.order.budgetSplit.google_budget / 100);
                                                                            }
                                                                            if (current_budget_plan.sub_action.id === 1) {
                                                                                month = this.renders.budgetPlannerGraph(budget, days, current_budget_plan.date)[0];
                                                                            } else {
                                                                                month = this.renders.budgetPlannerGraph(budget, days, false)[0];
                                                                            }
                                                                            if (moment(month_date.day).isBefore(moment(current_budget_plan.date))) {
                                                                                month_date.daily += 0;
                                                                            } else {
                                                                                month_date.daily += month.daily_budget;
                                                                            }
                                                                        } else {
                                                                            month_date.daily += month.daily_budget;
                                                                        }
                                    */

                                    if (!month_date.channels.includes("google")) {
                                        month_date.channels.push("google");
                                    }
                                    return month_date;
                                });
                            }


                        });
                    }
                    if (this.state.order.bingBudgets && this.state.order.bingBudgets.length > 0) {
                        this.state.order.bingBudgets.map((item) => {
                            this.state.campaigns.map((campaign) => {
                                if (campaign.budget_id && campaign.budget_id === item.id) {
                                    campaigns.push(campaign.campaignId);

                                }
                            })

                            if (item.budgetPlaner && item.budgetPlanerTotal && item.budgetPlanerTotal > 0) {
                                let days = {
                                    scheduleMonday: true,
                                    scheduleTuesday: true,
                                    scheduleWednesday: true,
                                    scheduleThursday: true,
                                    scheduleFriday: true,
                                    scheduleSaturday: true,
                                    scheduleSunday: true
                                }
                                month_dates = month_dates.map((month_date) => {

                                    /*
                                                                        let date = false;
                                                                        if (this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).length > 0) {
                                                                            date = true;
                                                                        }
                                                                        let month = this.renders.budgetPlannerGraph(item.budgetPlanerTotal, days, false)[0];
                                    
                                                                        if (date) {
                                                                            let current_budget_plan = false;
                                                                            this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).map((plan) => {
                                                                                if (!current_budget_plan) {
                                                                                    current_budget_plan = plan;
                                                                                } else {
                                                                                    if (moment(plan.date).isAfter(moment(current_budget_plan.date)) && moment(plan.date).isBefore(moment(month_date.day).add(2, 'h'))) {
                                                                                        current_budget_plan = plan;
                                                                                    }
                                                                                }
                                                                            });
                                                                            let budget = current_budget_plan.budget;
                                                                            if (this.state.order.budgetSplit) {
                                                                                budget = current_budget_plan.budget * (this.state.order.budgetSplit.bing_budget / 100);
                                                                            }
                                                                            if (current_budget_plan.sub_action.id === 1) {
                                                                                month = this.renders.budgetPlannerGraph(budget, days, current_budget_plan.date)[0];
                                                                            } else {
                                                                                month = this.renders.budgetPlannerGraph(budget, days, false)[0];
                                                                            }
                                                                            if (moment(month_date.day).isBefore(moment(current_budget_plan.date))) {
                                                                                month_date.daily += 0;
                                                                            } else {
                                                                                month_date.daily += month.daily_budget;
                                                                            }
                                                                        } else {
                                                                            month_date.daily += month.daily_budget;
                                                                        }
                                    
                                                                        */




                                    if (!month_date.channels.includes("bing")) {
                                        month_date.channels.push("bing");
                                    }
                                    return month_date;
                                });
                            }

                        });
                    }

                    let other_campaigns = this.state.campaigns.filter((campaign) => { return !campaigns.includes(campaign.campaignId) });
                    let buffer = [];
                    let buffer_days = ["0", "scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"];
                    other_campaigns = other_campaigns.map((campaign) => {
                        if ((parseInt(campaign.budgetPlanerTotal) > 0 && !isNaN(parseInt(campaign.budgetPlanerTotal)) && campaign.scheduleActivated) || (campaign.scheduleAdgroups && Object.keys(campaign.scheduleAdgroups).map((item) => {
                            if (campaign.scheduleAdgroups[item].scheduleActivated && campaign.scheduleAdgroups[item].budgetPlanerActivated) {
                                return true;
                            } else return false;
                        }).filter((item) => { return item }).length > 0)) {
                            if (parseInt(campaign.budgetPlanerTotal) > 0 && !isNaN(parseInt(campaign.budgetPlanerTotal)) && campaign.scheduleActivated) {
                                month_dates = month_dates.map((month_date) => {
                                    /*
                                    let date = false;
                                    if (this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).length > 0) {
                                        date = true;
                                    }
                                    let month = this.renders.budgetPlannerGraph(campaign.budgetPlanerTotal, campaign, false)[0];

                                    if (date) {
                                        //Fixes Need to add previous budget plan to the current budget plan
                                        //WRONG COS IM LOOKING AT ORDER BUDGET TOTAL AND TAKE IT INTO GRAPH, SO MISSING PART IS FOR EACH BUDGET PLAN ITEMS NEED CAMPAIGN BUDGET TOTAL FOR EACH CAMPAIGN
                                        let current_budget_plan = false;
                                        let latest_budget_plan = false;
                                        let temp = JSON.parse(JSON.stringify(this.state.order.budgetPlans));
                                        temp = temp.sort((x, y) => { return moment(x.date) - moment(y.date) });

                                        let a = temp.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) })[0];
                                        let first_plan_id = a.id ? { id: a.id } : (a.createdAt ? { createdAt: a.createdAt } : false);

                                        temp.map((plan, index) => {
                                            if (first_plan_id && index > 0) {
                                                if ((Object.keys(plan)).includes(Object.keys(first_plan_id)[0]) && plan[Object.keys(first_plan_id)[0]] === first_plan_id[Object.keys(first_plan_id)[0]]) {
                                                    latest_budget_plan = temp[index - 1];
                                                }
                                            }
                                        });
                                        this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).map((plan) => {
                                            if (!current_budget_plan) {
                                                current_budget_plan = plan;
                                            } else {
                                                if (moment(plan.date).isAfter(moment(current_budget_plan.date)) && moment(plan.date).isBefore(moment(month_date.day).add(2, 'h'))) {
                                                    current_budget_plan = plan;
                                                }
                                            }
                                        });

                                        let budget = current_budget_plan.budget;
                                        if (this.state.order.budgetSplit) {
                                            if (campaign.channel === "bing") {
                                                budget = current_budget_plan.budget * (this.state.order.budgetSplit.bing_budget / 100);
                                            } else if (campaign.channel === "google") {
                                                budget = current_budget_plan.budget * (this.state.order.budgetSplit.google_budget / 100);
                                            }
                                        }

                                        if (current_budget_plan.sub_action.id === 1) {
                                            month = this.renders.budgetPlannerGraph(budget, campaign, current_budget_plan.date)[0];
                                        } else {
                                            month = this.renders.budgetPlannerGraph(budget, campaign, false)[0];
                                        }
                                        if (moment(month_date.day).isBefore(moment(current_budget_plan.date))) {
                                            if (latest_budget_plan && current_budget_plan[Object.keys(first_plan_id)[0]] === first_plan_id[Object.keys(first_plan_id)[0]]) {
                                                let last_budget = latest_budget_plan.budget
                                                if (this.state.order.budgetSplit) {
                                                    if (campaign.channel === "bing") {
                                                        last_budget = latest_budget_plan.budget * (this.state.order.budgetSplit.bing_budget / 100);
                                                    } else if (campaign.channel === "google") {
                                                        last_budget = latest_budget_plan.budget * (this.state.order.budgetSplit.google_budget / 100);
                                                    }
                                                }
                                                let last_plan_month = this.renders.budgetPlannerGraph(last_budget, campaign, false)[0];

                                                month_date.daily += last_plan_month.daily_budget;
                                            } else {
                                                month_date.daily += 0;
                                            }
                                        } else {
                                            if (campaign[buffer_days[moment(month_date.day).isoWeekday()]]) {
                                                month_date.daily += month.daily_budget;
                                            }
                                        }
                                    } else {
                                        if (campaign[buffer_days[moment(month_date.day).isoWeekday()]]) {
                                            month_date.daily += month.daily_budget;
                                        }
                                        //month_date.daily += month.daily_budget;
                                    }


                                    if (!month_date.channels.includes(campaign.channel)) {
                                        month_date.channels.push(campaign.channel);
                                    }
                                */
                                    return month_date;
                                });
                                campaign.fullfilled = true;
                            } else {
                                Object.keys(campaign.scheduleAdgroups).map((item) => {
                                    if (campaign.scheduleAdgroups[item].scheduleActivated && parseInt(campaign.scheduleAdgroups[item].budgetPlanerTotal) > 0 && !isNaN(parseInt(campaign.scheduleAdgroups[item].budgetPlanerTotal))) {
                                        /*month_dates = month_dates.map((month_date) => {
                                            let date = false;
                                            if (this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).length > 0) {
                                                date = true;
                                            }
                                            let month = this.renders.budgetPlannerGraph(campaign.scheduleAdgroups[item].budgetPlanerTotal, campaign.scheduleAdgroups[item], false)[0];

                                            if (date) {
                                                let current_budget_plan = false;
                                                this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).map((plan) => {
                                                    if (!current_budget_plan) {
                                                        current_budget_plan = plan;
                                                    } else {
                                                        if (moment(plan.date).isAfter(moment(current_budget_plan.date)) && moment(plan.date).isBefore(moment(month_date.day).add(2, 'h'))) {
                                                            current_budget_plan = plan;
                                                        }
                                                    }
                                                });
                                                if (current_budget_plan.sub_action.id === 1) {
                                                    month = this.renders.budgetPlannerGraph(current_budget_plan.budget, campaign.scheduleAdgroups[item], current_budget_plan.date)[0];
                                                } else {
                                                    month = this.renders.budgetPlannerGraph(current_budget_plan.budget, campaign.scheduleAdgroups[item], false)[0];
                                                }
                                                if (moment(month_date.day).isBefore(moment(current_budget_plan.date))) {
                                                    month_date.daily += 0;
                                                } else {
                                                    if (campaign.scheduleAdgroups[item][buffer_days[moment(month_date.day).isoWeekday()]]) {
                                                        month_date.daily += month.daily_budget;
                                                    }
                                                }
                                            } else {
                                                if (campaign.scheduleAdgroups[item][buffer_days[moment(month_date.day).isoWeekday()]]) {
                                                    month_date.daily += month.daily_budget;
                                                }
                                            }
                                            if (!month_date.channels.includes(campaign.channel)) {
                                                month_date.channels.push(campaign.channel);
                                            }
                                            return month_date;
                                        });*/
                                        campaign.fullfilled = true;
                                    }

                                })

                            }
                        }
                        return campaign;
                    })

                    let days = {
                        scheduleMonday: true,
                        scheduleTuesday: true,
                        scheduleWednesday: true,
                        scheduleThursday: true,
                        scheduleFriday: true,
                        scheduleSaturday: true,
                        scheduleSunday: true
                    }
                    let monthly = this.renders.budgetPlannerGraph(this.state.order.maxBudget, days, false)[0];
                    month_dates = month_dates.map((month_date) => {
                        let current_budget_plan = false;
                        let latest_budget_plan = false;
                        let temp = JSON.parse(JSON.stringify(this.state.order.budgetPlans));
                        temp = temp.sort((x, y) => { return moment(x.date) - moment(y.date) });

                        let a = temp.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).length > 0 ? temp.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) })[0] : {};
                        let first_plan_id = a.id ? { id: a.id } : (a.createdAt ? { createdAt: a.createdAt } : false);
                        if (first_plan_id) {
                            temp.map((plan, index) => {
                                if (first_plan_id && index > 0) {
                                    if ((Object.keys(plan)).includes(Object.keys(first_plan_id)[0]) && plan[Object.keys(first_plan_id)[0]] === first_plan_id[Object.keys(first_plan_id)[0]]) {
                                        latest_budget_plan = temp[index - 1];
                                    }
                                }
                            });
                            this.state.order.budgetPlans.filter((plan) => { return moment(plan.date).month() === moment().month() }).sort((x, y) => { return moment(x.date) - moment(y.date) }).map((plan) => {
                                if (!current_budget_plan) {
                                    current_budget_plan = plan;
                                } else {
                                    if (moment(plan.date).isAfter(moment(current_budget_plan.date)) && moment(plan.date).isBefore(moment(month_date.day).add(2, 'h'))) {
                                        current_budget_plan = plan;
                                    }
                                }
                            });
                        } else {
                            current_budget_plan = temp[temp.length - 1];
                        }

                        let budget = current_budget_plan.budget;
                        if (current_budget_plan.sub_action.id === 1) {
                            if (first_plan_id) {
                                month = this.renders.budgetPlannerGraph(budget, days, current_budget_plan.date)[0];
                            } else {
                                month = this.renders.budgetPlannerGraph(budget, days, false)[0];
                            }
                        } else {
                            month = this.renders.budgetPlannerGraph(budget, days, false)[0];
                        }
                        if (moment(month_date.day).isBefore(moment(current_budget_plan.date))) {
                            if (latest_budget_plan && current_budget_plan[Object.keys(first_plan_id)[0]] === first_plan_id[Object.keys(first_plan_id)[0]]) {
                                let last_budget = latest_budget_plan.budget

                                let last_plan_month = this.renders.budgetPlannerGraph(last_budget, days, false)[0];
                                if (latest_budget_plan.action.id === 1) {
                                    month_date.daily += last_plan_month.daily_budget;
                                } else {
                                    month_date.daily += 0;
                                }
                            } else {
                                month_date.daily += 0;
                            }
                        } else {
                            month_date.daily += month.daily_budget;
                        }
                        //month_date.daily += monthly.daily_budget;


                        return month_date;
                    });

                    //console.log("MONTH DATES", month_dates);
                    let collect_data_campaigns = campaigns.concat(other_campaigns.filter((campaign) => { return campaign.fullfilled }).map((campaign) => { return campaign.campaignId }));
                    if (dates.length > 0) {
                        let response = await this.calls.getPerformanceData({
                            campaigns: collect_data_campaigns,
                            dates: dates,
                        });
                        response.data.map((item) => {
                            month_dates = month_dates.map((month_date) => {
                                if (month_date.day == item.createdAtString) {
                                    month_date.spend += +item.spend;
                                }
                                return month_date;
                            });
                        })
                    }
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        if (campaign.budgetPlanerActivated && campaign.scheduleActivated) {
                            campaign.fullfilled = true;
                        } else {
                            if (campaign.scheduleAdgroups) {
                                Object.keys(campaign.scheduleAdgroups).map((item) => {
                                    if (campaign.scheduleAdgroups[item].scheduleActivated && campaign.scheduleAdgroups[item].budgetPlanerActivated) {
                                        campaign.fullfilled = true;
                                    }
                                })
                            }
                        }
                        if (campaigns.includes(campaign.campaignId)) {
                            campaign.fullfilled = true;
                        }
                        return campaign
                    });

                    await this.promisedSetState({
                        campaigns: this.state.campaigns,
                        month_dates: month_dates,
                        loading: false
                    });
                }
            } catch (error) {
                console.log(error, "ERROR");
            }
            await this.promisedSetState({ loading: false });

            await this.functions.graph();
        },
        graph: async () => {
            return new Promise(async (resolve) => {
                let line = {
                    hide_title: true,
                    chart: {
                        value: "line",
                        tittle: "Line"
                    },
                    settings: {
                        title: "Pacing",
                    },
                    data: {
                        labels: [
                            "0"
                        ],
                        datasets: [
                            {
                                backgroundColor: "#33A103",
                                borderColor: "#33A103",
                                borderWidt: 2,
                                data: [0],
                                fill: false,
                                label: "Target"
                            },
                            {
                                backgroundColor: "#453FF1",
                                borderColor: "#453FF1",
                                borderWidth: 2,
                                data: [0],
                                fill: false,
                                label: "Order pacing"
                            },
                        ]
                    }

                }

                let daily = 0;
                let spend = 0;
                this.state.month_dates.map((item) => {
                    console.log(item, "ITEM");
                    line.data.labels.push(moment(item.day).format("D"));
                    daily += item.daily;
                    spend += item.spend;
                    line.data.datasets[0].data.push(daily);
                    if (moment().isAfter(moment(item.day)) && item.day !== moment().format("YYYY-MM-DD")) {
                        line.data.datasets[1].data.push(spend.toFixed(2));
                    }
                })

                await this.promisedSetState({
                    chart: line
                });

                resolve();
            })
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        calculation: async (campaigns) => {
            return new Promise(async (resolve) => {
                this.state.campaigns = campaigns.map((campaign) => {
                    if ((campaign.budgetPlanerActivated && campaign.scheduleActivated) || (campaign.scheduleAdgroups && Object.keys(campaign.scheduleAdgroups).map((item) => {
                        if (campaign.scheduleAdgroups[item].scheduleActivated && campaign.scheduleAdgroups[item].budgetPlanerActivated) {
                            return true;
                        } else return false;
                    }).filter((item) => { return item }).length > 0)) {
                        let buffer = {
                            campaign_id: campaign.campaignId,
                            1: 0,
                            2: 0,
                            3: 0,
                            4: 0,
                            5: 0,
                            6: 0,
                            7: 0,
                            daily_budget: 0,
                        }
                        let total_budget = this.state.order.maxBudget / this.state.campaigns.filter((item) => {
                            return ((item.budgetPlanerActivated && item.scheduleActivated) || (item.scheduleAdgroups && Object.keys(item.scheduleAdgroups).map((inner_item) => {
                                if (item.scheduleAdgroups[inner_item].scheduleActivated && item.scheduleAdgroups[inner_item].budgetPlanerActivated) {
                                    return true;
                                } else return false;
                            }).filter((item) => { return item }).length > 0))
                        }).length;

                        if (campaign.budgetPlanerActivated && campaign.scheduleActivated) {
                            let month = this.renders.budgetPlannerGraph(total_budget, campaign)[0];
                            buffer.daily_budget = month.daily_budget;
                            ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day, index) => {
                                if (campaign[day]) {
                                    buffer[index + 1] += month.daily_budget;
                                };
                            })
                            campaign.fullfilled = buffer;
                        } else {
                            Object.keys(campaign.scheduleAdgroups).map((item) => {
                                if (campaign.scheduleAdgroups[item].scheduleActivated && campaign.scheduleAdgroups[item].budgetPlanerActivated) {
                                    let month = this.renders.budgetPlannerGraph((total_budget / Object.keys(campaign.scheduleAdgroups).filter((item) => { return campaign.scheduleAdgroups[item].scheduleActivated && campaign.scheduleAdgroups[item].budgetPlanerActivated }).length), campaign.scheduleAdgroups[item])[0];
                                    buffer.daily_budget += month.daily_budget;
                                    ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day, index) => {
                                        if (campaign.scheduleAdgroups[item][day]) {
                                            buffer[index + 1] += month.daily_budget;
                                        };
                                    })
                                    campaign.fullfilled = buffer;
                                }

                            })

                        }

                    }
                    return campaign;
                })
                await this.promisedSetState({
                    campaigns: this.state.campaigns
                });
                console.log(this.state.campaigns, "CAMPAIGNS");

                resolve();
            })
        },
        getCampaigns: async () => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading: true,
                    });
                    let response = await this.calls.getCampaigns();
                    let channels = [{ id: "all", name: "All channels", value: "all" }];
                    response.data.campaigns.map((item) => {
                        if (channels.filter((channel) => { return channel.value === item.channel }).length < 1) {
                            channels.push({ id: item.channel, name: item.channel.charAt(0).toUpperCase() + item.channel.slice(1), value: item.channel });
                        }
                    });
                    await this.promisedSetState({
                        campaigns: response.data.campaigns,
                        client: response.data.client,
                        order: response.data.order,
                        channels: channels,
                        loading: false
                    });
                    if (this.state.order.maxBudget) { //&& this.state.order.maxBudgetEnabled) {
                        this.functions.calculate_performance();
                    } else {
                        await this.promisedSetState({
                            error_budget: true
                        });
                    }
                } catch (error) {
                    this.promisedSetState({
                        error: true,
                        loading: false
                    })
                }
                resolve();
            })
        },
        getCampaignsData: async (campaign) => {
            return new Promise(async (resolve) => {
                try {
                    this.promisedSetState({
                        loading_metrics: true
                    });
                    let campaigns_string = "";
                    if (!campaign) {
                        this.state.campaigns.map((campaign) => {
                            campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                        });
                    } else {
                        campaigns_string = ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                    }
                    let statistics = campaign ? false : true;

                    let response = await this.calls.getCampaignsData(campaigns_string, statistics);
                    this.state.campaigns = this.state.campaigns.map((campaign) => {
                        if (response.data[campaign.channel]) {
                            response.data[campaign.channel].map((item) => {
                                if (campaign.id === item.id) {
                                    campaign = { ...campaign, ...item };
                                }
                            })
                        }
                        return campaign;
                    });
                    this.promisedSetState({
                        campaigns: this.state.campaigns,
                        loading_metrics: false
                    })
                } catch (error) {
                    console.log(error, "ERROR");
                    this.promisedSetState({
                        error: true,
                        loading_metrics: false
                    })
                }
                resolve();
            })
        },
        getAdgroups: async (campaign) => {
            let self = this;
            return new Promise(function (resolve, reject) {
                let campaigns_string = "";
                campaigns_string = campaigns_string + ("&" + campaign.channel + "_campaigns[]=" + campaign.id + "," + self.state.client.id);

                self.calls.getAdgroups(campaigns_string).then((response) => {
                    let data = { channels: {} };
                    for (let channel in response.data) {
                        data.channels[channel] = {
                            adgroups: response.data[channel]
                        };
                    }
                    self.setState({
                        adgroups: self.state.adgroups.concat(Object.keys(data.channels).map((channel) => {
                            return data.channels[channel].adgroups.filter((adgroup) => {
                                return adgroup.status !== "REMOVED" && self.state.adgroups.filter((item) => { return item.id === adgroup.id }).length < 1;
                            }).map((item) => {
                                item.campaign = item.campaign_id;
                                return item;
                            });
                        }).flat()),
                    }, () => {
                        resolve();
                    });
                }, (error) => {
                    resolve();
                })
            })
        },
        getAdgroupsData: async (adgroup) => {
            try {
                this.promisedSetState({
                    loading_metrics: true
                });
                let adgroups_string = "";
                if (!adgroup) {
                    this.state.adgroups.map((adgroup) => {
                        adgroups_string = adgroups_string + ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                    });
                } else {
                    adgroups_string = ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                }
                let statistics = adgroup ? false : true;

                let response = await this.calls.getAdgroupsData(adgroups_string, statistics);
                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                    if (response.data[adgroup.channel]) {
                        response.data[adgroup.channel].map((item) => {
                            if (adgroup.id == item.id) {
                                adgroup = { ...adgroup, ...item };
                            }
                        })
                    }
                    return adgroup;
                });
                this.promisedSetState({
                    adgroups: this.state.adgroups,
                    loading_metrics: false
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_metrics: false
                })
            }
        },
    }




    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        getOrderBudgetPlaner: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderBudgetPlaner?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        getLineChart: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/reportABTest?&client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getPerformanceData: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/getPerformanceData?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getCampaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderCampaigns?id=" + this.functions.getIdFromPath() + "&information=true";
            return apiRegister.call(options, url);
        },
        getCampaignsData: (campaigns, statistics) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?" + "statistics=false&client=" + this.state.client.id + campaigns + "&information=true";
            return apiRegister.call(options, url);
        },
        getAdgroups: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "client=" + this.state.client.id + "&information=true" + campaigns;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        spend: (campaigns, breakdown) => {
            try {
                let spend = 0;
                campaigns.filter((campaign) => { return campaign.fullfilled }).map((campaign) => {
                    this.state.performance.data.filter((item) => { return item.id === campaign.id && item.breakdown_value === breakdown }).map((item) => {
                        spend += +item["Spend (default)"];
                    })
                })
                return spend.toFixed(2);
            } catch (error) {
                return 0;
            }

        },
        daily: (campaigns, breakdown) => {
            try {
                let spend = 0;
                campaigns.filter((campaign) => { return campaign.fullfilled }).map((campaign) => {
                    spend += +campaign.fullfilled[moment(breakdown).isoWeekday()];
                })
                return spend.toFixed(2);
            } catch (error) {
                return 0;
            }

        },
        budgetPlannerGraph: (budget, days, startDate = false) => {
            let graph_data = {
                labels: [],
                datasets: []
            }
            let months = [];
            let today = moment().startOf('month');
            if (startDate) {
                today = moment(startDate);
            }
            for (let i = 0; i < 1; i++) {
                let month = today.month();
                let data = { daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
                let next = false;
                while (!next) {
                    if (month !== today.month()) {
                        next = true;
                    } else {
                        data[today.isoWeekday()] += 1;
                        today.add(1, 'd');
                    }
                }
                if (next) {
                    if (days.scheduleMonday) {
                        data.total += data["1"];
                    }
                    if (days.scheduleTuesday) {
                        data.total += data["2"];
                    }
                    if (days.scheduleWednesday) {
                        data.total += data["3"];
                    }
                    if (days.scheduleThursday) {
                        data.total += data["4"];
                    }
                    if (days.scheduleFriday) {
                        data.total += data["5"];
                    }
                    if (days.scheduleSaturday) {
                        data.total += data["6"];
                    }
                    if (days.scheduleSunday) {
                        data.total += data["7"];
                    }
                    if (budget) {
                        console.log(budget, data.total, "BUDGET TOTAL");
                        data.daily_budget = parseFloat(parseFloat((budget) / data.total).toFixed(2));
                        data.left_over = budget - (data.daily_budget * data.total);
                    }
                    graph_data.labels.push([data.total + " days", data.month, data.year]);
                    months.push(data);
                    continue;
                }
            }

            return months;
        },
        color: (item) => {
            try {
                if (item !== "-") {
                    item = +item - 100;
                    if (+item < 0) {
                        item = +item * -1;
                    }
                    if (+item <= 5) {
                        return "text-green-600";
                    } else if (+item > 5 && +item < 11) {
                        return "text-yellow-500 ";
                    } else if (+item >= 11) {
                        return "text-red-500 ";
                    }
                } else return "";
            } catch (error) {
                return "";
            }
        },
        fullfilled: (spend, daily) => {
            try {

                if (+spend > 0 && +daily > 0) {
                    return (+spend * 100 / +daily).toFixed(2);
                } else if (+spend == 0 && +daily > 0) {
                    return 0;
                } else if (+spend > 0 && +daily == 0) {
                    return (Math.round(+spend * 100));
                } else {
                    return "-";
                }

            } catch (error) {
                return "-";
            }
        },
        sort: (list) => {

            let sortNumber = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : 0) : (x[this.state.sortBy] ? x[this.state.sortBy] : 0);
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : 0) : (y[this.state.sortBy] ? y[this.state.sortBy] : 0);
                    if (this.state.orderBy == 'DESC') {
                        return y_value - x_value;
                    } else {
                        return x_value - y_value;
                    }
                });
            }

            let sortCharacter = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : "") : (x[this.state.sortBy] ? x[this.state.sortBy] : "");
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : "") : (y[this.state.sortBy] ? y[this.state.sortBy] : "");
                    let a = x_value.toUpperCase(),
                        b = y_value.toUpperCase();
                    if (this.state.orderBy == 'ASC') {
                        return a == b ? 0 : a > b ? -1 : 1;
                    } else {
                        return a == b ? 0 : a > b ? 1 : -1;
                    }
                });
            }

            let sortDate = (list) => {
                return list.sort((x, y) => {
                    let x_value = x.row_values ? (x.row_values[this.state.sortBy] ? x.row_values[this.state.sortBy] : new Date()) : (x[this.state.sortBy] ? x[this.state.sortBy] : new Date());
                    let y_value = y.row_values ? (y.row_values[this.state.sortBy] ? y.row_values[this.state.sortBy] : new Date()) : (y[this.state.sortBy] ? y[this.state.sortBy] : new Date());
                    let a = new Date(x_value),
                        b = new Date(y_value);
                    if (this.state.orderBy == 'ASC') {
                        return a - b;
                    } else {
                        return b - a;
                    }
                });
            }

            if (this.state.sortBy === "name" || this.state.sortBy === "channel" || this.state.sortBy === "status") {
                return sortCharacter(list);
            } else if (this.state.sortBy === "startDate" || this.state.sortBy === "endDate") {
                return sortDate(list);
            } else {
                return sortNumber(list);
            }



        }
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {

                        }}
                        showDateSpan={false}
                        hideUserDropdown={true}
                        breadcrumb={""}
                        history={this.props.history}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        showClient={true}
                        lockClient={false}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                    />
                </div>
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }
                {
                    !this.state.loading && !this.state.error_budget &&
                    this.state.campaigns && this.state.campaigns.filter((item) => { return item.fullfilled }).length < 1 &&
                    <div className='p-4 flex flex-1 flex-col max-h-screen items-center justify-items-center'>
                        <span className='text-gray-600'>No Campaigns / Adgroups found with budget planer activated</span>
                    </div>
                }
                {
                    this.state.error_budget && !this.state.loading &&
                    <div className='p-4 flex flex-1 flex-col max-h-screen items-center justify-items-center'>
                        <span className='text-gray-600'>Cant display performance without setting Order total budget!</span>
                    </div>
                }
                {
                    !this.state.error_budget &&
                    !this.state.loading &&
                    this.state.campaigns && this.state.campaigns.filter((item) => { return item.fullfilled }).length > 0 &&
                    <div className='p-4 flex flex-1 flex-col max-h-screen'>
                        <div className='shadow bg-white rounded-lg flex flex-1 flex-col overflow-hidden'>
                            <div className='flex flex-1 flex-col relative'>
                                {
                                    this.state.chart &&
                                    !this.state.loading &&
                                    <AdvancedReportCellChart
                                        external={true}
                                        header={"HEADER"}
                                        loading={this.state.loading_data}
                                        source={this.state.chart}


                                    />
                                }
                            </div>
                            <div className='flex flex-1 flex-col relative overflow-hidden'>
                                <div className='w-full h-full overflow-scroll'>
                                    <table className="min-w-full divide-y divide-gray-300 border-gray-300">
                                        <thead className="bg-white sticky top-0 z-55">
                                            <tr>

                                                <th
                                                    style={true ? { minWidth: "150px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">Date</div>
                                                    </div>
                                                </th>

                                                <th
                                                    style={true ? { minWidth: "150px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">Channel</div>
                                                    </div>
                                                </th>


                                                <th
                                                    style={true ? { minWidth: "150px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">Spend</div>
                                                    </div>
                                                </th>
                                                <th
                                                    style={true ? { minWidth: "150px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">Spend target</div>
                                                    </div>
                                                </th>
                                                <th
                                                    style={true ? { minWidth: "150px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">% Fullfilled</div>
                                                    </div>
                                                </th>
                                                <th
                                                    style={true ? { minWidth: "250px", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                    scope="col"
                                                    className={(false ? "sticky left-0 z-10 " : "") + " px-6 py-3 border-gray-300 hover:bg-gray-100 cursor-pointer text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider"}>
                                                    <div className="flex flex-row items-center">
                                                        <div className="mr-4">Pacing</div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-gray-300">

                                            {
                                                this.state.month_dates.length > 0 &&
                                                this.state.month_dates.filter((item) => { return moment().isAfter(moment(item.day)) && item.day !== moment().format("YYYY-MM-DD") }).reverse().map((item) => {
                                                    return (
                                                        <>
                                                            <tr className="hover:bg-gray-50 border-b">
                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4">{moment(item.day).format("YYYY-MM-DD")}</div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className="flex flex-row items-center">
                                                                        {
                                                                            item.channels.map((channel) => {
                                                                                if (channel === "facebook") {
                                                                                    return (
                                                                                        <div
                                                                                            className="w-8 h-8 bg-facebook-500 rounded-full p-2 border-2 border-white">
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "google") {

                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-google-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "bing") {
                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "linkedin") {
                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-linkedin-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "tiktok") {
                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-black rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "snapchat") {
                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-snapchat-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                } else if (channel === "twitter") {
                                                                                    return (
                                                                                        <div
                                                                                            className={'w-8 h-8 bg-twitter-500 rounded-full p-2 border-2 border-white'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            })
                                                                        }

                                                                    </div>
                                                                </td>

                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4">
                                                                            {
                                                                                item.spend.toFixed(2) + (this.state.client.currency[this.state.campaigns[0].channel] ? " " + this.state.client.currency[this.state.campaigns[0].channel] : "")
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4">
                                                                            {
                                                                                item.daily + (this.state.client.currency[this.state.campaigns[0].channel] ? " " + this.state.client.currency[this.state.campaigns[0].channel] : "")
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className={"flex flex-row items-center " + (this.renders.color(this.renders.fullfilled(item.spend, item.daily)))}>
                                                                        <div className="mr-4">{this.renders.fullfilled(item.spend, item.daily) + (this.renders.fullfilled(item, this.state.campaigns) !== "-" ? "%" : "")}</div>
                                                                    </div>
                                                                </td>
                                                                <td style={{ whiteSpace: "nowrap" }} className={(false ? "bg-gray-50" : " bg-white") + " px-4 sm:px-6 py-4 whitespace-no-wrap items-center border-gray-300 "}>
                                                                    <div className={"flex flex-row items-center " + (this.renders.color(this.renders.fullfilled(item.spend, item.daily)))}>
                                                                        <span>{(this.renders.fullfilled(item.spend, item.daily) !== "-" ? (this.renders.fullfilled(item.spend, item.daily) > 100 ? "Overperforming | +" + +(+this.renders.fullfilled(item.spend, item.daily) - 100).toFixed(2) + "%" : "Underperforming | -" + +(100 - +this.renders.fullfilled(item.spend, item.daily)).toFixed(2) + "%") : "-")}</span>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }


            </div >
        )
    }

}

export default orderPerformance;