import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, FolderIcon, NewspaperIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';

class CreativesModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            input: "",
            indexZ: 0
        }
    };

    componentDidMount() {
        this.setState({
            open: this.props.open,
            input: this.props.input,
            indexZ: this.props.indexZ ? this.props.indexZ : 0
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            open: nextProps.open,
            input: nextProps.input,
            indexZ: nextProps.indexZ ? nextProps.indexZ : 0
        })
    }

    render() {
        return (
            <Transition.Root show={this.state.open} as={Fragment}>
                <Dialog as="div" className={"fixed inset-0 overflow-y-auto"} style={this.state.indexZ !== 0 ? {zIndex: this.state.indexZ} : {zIndex: 60}} onClose={() => {
                    if (this.props.onClose) {
                        this.props.onClose(true, false);
                    }
                }}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                                        <NewspaperIcon className="h-6 w-6 text-purple-500" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            {this.props.title}
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {this.props.text}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <div className="grid grid-cols-6 col-gap-4">
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (this.props.onClose) {
                                                        this.props.onClose();
                                                    }
                                                }}
                                            >
                                                {"Close"}
                                            </button>
                                        </div>
                                        <div className="col-span-3">
                                            <button
                                                type="button"
                                                className="inline-flex shadow-sm justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-500 text-base font-medium text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                                                onClick={() => {
                                                    if (this.props.onCreate) {
                                                        this.props.onCreate();
                                                    }
                                                }}
                                            >
                                                {this.props.button}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }
}

export default CreativesModal;
