import { Dialog } from '@headlessui/react';
import { EyeIcon, ArrowRightIcon, ExternalLinkIcon, OfficeBuildingIcon, SelectorIcon, MenuAlt3Icon, XIcon } from '@heroicons/react/outline';
import { HeartIcon, CursorClickIcon } from '@heroicons/react/solid';
import React, { Component, Fragment } from 'react';

class ToolboxHero extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mobileMenuOpen: false
        }
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    functions = {

    };

    calls = {

    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{
                backgroundColor: "#f1f0ee",
                backgroundImage: "radial-gradient(circle at 1px 1px, black 1px, transparent 0)",
                backgroundSize: "40px 40px"
            }}>
                <header className="absolute inset-x-0 top-0 z-50">
                    <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                        <div className="flex lg:flex-1">
                            <a href="#" className="-m-1.5 p-1.5 text-2xl font-medium">
                                <span><span style={{ fontSize: '30px' }}>[</span> Adcredo Platform <span style={{ fontSize: '30px' }}>]</span></span>
                            </a>
                        </div>
                        <div className="flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => {
                                    this.setState({
                                        mobileMenuOpen: true
                                    })
                                }}
                            >
                                <span className="sr-only">Open main menu</span>
                                <MenuAlt3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden lg:flex lg:gap-x-12">

                        </div>
                        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                            <div className="rounded-md cursor-pointer px-6 py-2 font-normal text-sm text-black">
                                Log In
                            </div>
                            <div className="rounded-md shadow cursor-pointer bg-black px-6 py-2 font-normal text-sm text-white">
                                Start for free
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="relative isolate px-6 pt-14 lg:px-8">
                    {
                        <div style={{ top: '170px', left: '170px' }} className="absolute">
                            <div className="flex flex-row">
                                <CursorClickIcon className="w-10 text-yellow-500" />
                                <div>
                                    <div className="px-3 ml-3 mt-2 font-medium rounded-full bg-yellow-500 py-1 text">
                                        Jessica
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        <div style={{ top: '570px', right: '170px' }} className="absolute">
                            <div className="flex flex-row">
                                <CursorClickIcon className="w-10 text-blue-500" />
                                <div>
                                    <div className="px-3 ml-3 mt-2 font-medium rounded-full bg-blue-500 py-1 text">
                                        Martin
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="text-center pt-40 relative">
                        <div style={{ top: "140px" }} className="absolute w-full justify-center items-center flex">
                            <div className="border bg-white flex flex-row font-medium rounded-full px-4 py-1">
                                <span className="mr-1 text-purple-500">100+</span> Agencies & Brands uses Toolbox
                                <div className="w-6 h-6 ml-2 border  border-white rounded-full bg-purple-200"></div>
                                <div className="w-6 h-6 border border-white rounded-full -ml-2 bg-purple-200"></div>
                                <div className="w-6 h-6 border border-white rounded-full -ml-2 bg-purple-200"></div>
                            </div>
                        </div>
                        <div className="text-center flex items-center flex-col justify-center">
                            <div id={"toolbox"} className="font-black text-gray-900">
                                A<span style={{fontSize: "126px"}}>i</span> TOOLBOX
                            </div>
                            <div style={{ fontSize: '156px', lineHeight: "100px" }} className="uppercase font-black ">
                                <span style={{ color: "#333333" }}>WITH</span> <span className="italic">ADCREDO</span>
                            </div>
                            <p className=" mt-20  text-center items-center text-lg max-w-4xl leading-8 text-gray-600">
                                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
                                fugiat veniam occaecat fugiat aliqua.
                            </p>
                        </div>
                    </div>
                    <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                </div>
                <div>
                    <div className="items-center flex justify-center mt-12 flex-col">
                        <div className="max-w-2xl w-full">
                            <div className="px-2 py-2 w-full shadow hover:scale-110 transform" style={{ transition: 'all .6s cubic-bezier(.68,-.55,.265,1.55)', borderRadius: '14px', backgroundImage: 'linear-gradient(132deg, #ff7448, #ff4848 54%, #6248ff 86%)' }}>
                                <div style={{ borderRadius: '14px' }} className="bg-white flex rounded-lg w-full px-4 py-4">
                                    <div className="flex-1 flex text-gray-600 items-center pl-2">
                                        <div>Type your website URL here ..</div>
                                    </div>
                                    <div>
                                        <div style={{ backgroundColor: '#6248ff' }} className="rounded-md relative flex cursor-pointer shadow pl-10 px-6 py-2 font-normal text-sm text-white">
                                            <div style={{ left: "16px", top: "9.5px" }} className="absolute">
                                                <img className="w-4" src={require("../assets/images/stars.svg")} />
                                            </div>
                                            Generate
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundImage: 'linear-gradient(132deg, #ff7448, #ff4848 54%, #6248ff 86%)' }} className='w-1 h-24'></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ToolboxHero;
