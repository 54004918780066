import React, { Component, Fragment } from 'react';

import { FolderIcon, UserCircleIcon, CalendarIcon, IdentificationIcon, ChartBarIcon, XIcon, MenuAlt2Icon, SearchIcon, BellIcon, OfficeBuildingIcon, CurrencyDollarIcon, AdjustmentsIcon, ChartPieIcon, VariableIcon, PlayIcon, PauseIcon, ExclamationIcon, CloudIcon, HandIcon, ClockIcon, LightBulbIcon, PlusIcon, PlusCircleIcon, DocumentIcon, UserIcon, ChevronDownIcon, ArchiveIcon, PhotographIcon, CubeIcon, UserGroupIcon, ChevronRightIcon, MenuIcon, ArrowNarrowLeftIcon, TrashIcon, CheckIcon, UploadIcon, SaveAsIcon, ExternalLinkIcon, ArrowLeftIcon, ArrowRightIcon, MenuAlt1Icon, DotsVerticalIcon, CogIcon } from '@heroicons/react/outline'
import { Transition, Menu } from '@headlessui/react'
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import { clientRegister } from "../services/clientRegister";
import DropdownTailwind from './dropdownTailwind';
import MultiDropdown from './multiDropdownTailwind';
import { apiRegister } from '../services/apiRegister';
import SwitchTailwind from './switchTailwind';
import cn from "classnames";
const fuzzysort = require('fuzzysort');

class TopNavigationAgent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            expand: false,
            advanced: false,
            breadcrumb: "",
            buttonTypes: {},
            buttonLoaders: {},
            orderId: "",
            dateSpan: {},
            searchInputValue: "",
            clients: [],
            search: "",
            fixed_client: null,
            client: { id: 0, name: "All clients (default)", icon: IdentificationIcon },
            page: 1,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            throttling: {},
            multi_client: 0,
            subtabs: [],
            subtab: {},
            budget_management: false,
            show_table_settings: false,
            custom_dates: false,
            showTagsDropdown: false,
            tags_dropdown: {
                type: "tags",
                search: "",
                searchInput: true,
                multiSelect: true,
                total: 0,
                placeholder: "No tags",
                defaultOptions: [],
                defaultSelected: { id: 1, name: "No tags (default)", value: "all" },
                options: [{ id: 1, name: "No tags (default)", value: "all" }],
                selected: [],
                showPaginationSearch: true,
                limit: 10,
                page: 1,
                loading: {
                    search: false,
                    pagination: false

                }
            },
            tagType: "",
            url_tags: []
        }
    };

    componentWillMount() {
        this.init.user();
        this.init.client();
        if (this.props.showTagsDropdown) {
            this.functions.getTags();
        }
    }

    componentDidMount() {
        this.setState({
            orderId: this.props.orderId,
            breadcrumb: this.props.breadcrumb,
            dateSpan: this.props.dateSpan,
            buttonTypes: this.props.buttonTypes,
            buttonLoaders: this.props.buttonLoaders ? this.props.buttonLoaders : {},
            buttons: this.props.buttons ? this.props.buttons : [],
            showPaginaton: this.props.showPaginaton,
            showDateSpan: this.props.showDateSpan,
            limit: this.props.limit,
            page: this.props.page,
            total: this.props.total,
            searchInput: this.props.searchInput,
            searchInputValue: this.props.searchInputValue,
            showPaginationSearch: this.props.showPaginationSearch,
            showFilters: this.props.showFilters,
            filters: this.props.filters,
            fixed_client: this.props.client,
            show_advanced: this.props.showAdvanced,
            advanced_filters: this.props.advancedFilters,
            multi_client: this.props.multiClient,
            advanced_filter: this.props.advancedFilter ? this.props.advancedFilter : this.state.advanced_filter,
            show_subtabs: this.props.show_subtabs,
            subtabs: Array.isArray(this.props.subtabs) ? this.props.subtabs : [],
            subtab: this.props.subtab ? this.props.subtab : {},
            show_table_settings: this.props.show_table_settings,
            custom_dates: this.props.custom_dates ? this.props.custom_dates : false,
            showTagsDropdown: this.props.showTagsDropdown,
            tagType: this.props.tagType,
            url_tags: this.props.url_tags,
            showMessage: this.props.showMessage,
            budget_management: this.props.budget_management ? this.props.budget_management : false,

        });

        if (!this.props.lockClient && !this.props.showClientLimited) {
            this.functions.getClients(true, false, false);
        }

        if (this.props.selectedClient && this.props.selectedClient.id !== 0 && this.props.selectedClient.id !== 1) {
            this.setState({
                client: this.props.selectedClient,
                clients: [this.props.selectedClient]
            })
        }

        if (this.props.miniMizeOnLoad && this.props.minimizeMenu) {
            this.props.minimizeMenu(true);
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            orderId: nextProps.orderId,
            breadcrumb: nextProps.breadcrumb,
            dateSpan: nextProps.dateSpan,
            buttonTypes: nextProps.buttonTypes,
            buttonLoaders: nextProps.buttonLoaders ? nextProps.buttonLoaders : {},
            buttons: nextProps.buttons ? nextProps.buttons : [],
            showPaginaton: nextProps.showPaginaton,
            showDateSpan: nextProps.showDateSpan,
            limit: nextProps.limit,
            page: nextProps.page,
            total: nextProps.total,
            searchInput: nextProps.searchInput,
            searchInputValue: nextProps.searchInputValue,
            showPaginationSearch: nextProps.showPaginationSearch,
            showFilters: nextProps.showFilters,
            filters: nextProps.filters,
            client: nextProps.client,
            fixed_client: nextProps.selectedClient ? nextProps.selectedClient : nextProps.client,
            // client: nextProps.client,
            // fixed_client: nextProps.client,
            multi_client: nextProps.multiClient,
            show_advanced: nextProps.showAdvanced,
            advanced_filters: nextProps.advancedFilters,
            show_subtabs: nextProps.show_subtabs,
            subtabs: Array.isArray(nextProps.subtabs) ? nextProps.subtabs : [],
            subtab: nextProps.subtab ? nextProps.subtab : {},
            show_table_settings: nextProps.show_table_settings,
            url_tags: nextProps.url_tags,
            budget_management: nextProps.budget_management ? nextProps.budget_management : false,
        })
    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get()
                })
            }
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        }
    };

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                //loading_clients_search: search,
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        setClient: (client) => {
            this.setState({
                client: client
            })
        },
        getTags: async (init, pagination, search, search_value) => {
            this.state.tags_dropdown.loading.search = search
            this.state.tags_dropdown.loading.pagination = pagination
            // this.state.tags_dropdown.loading = true
            await this.promisedSetState({ tags_dropdown: this.state.tags_dropdown });
            let all_tags = [];

            try {
                let response = await this.calls.getTags();
                if (!search_value || (search_value && search_value == this.state.tags_dropdown.search)) {
                    if (!pagination) {
                        if (search) {
                            response.data.all_tags.map((tag) => {
                                tag = { id: tag.id, name: tag.name, value: tag.name };
                                all_tags.push(tag);
                            })
                            this.state.tags_dropdown.options = all_tags;
                            this.state.tags_dropdown.total = response.meta.total;
                        } else {
                            response.data.all_tags.map((tag) => {
                                tag = { id: tag.id, name: tag.name, value: tag.name };
                                all_tags.push(tag);
                            })
                            this.state.tags_dropdown.options = this.state.tags_dropdown.options.concat(all_tags);
                            this.state.tags_dropdown.total = response.meta.total;
                            if (response.data && response.data.selected_tags && response.data.selected_tags.length !== 0) {
                                this.state.tags_dropdown.selected = response.data.selected_tags;
                            }
                        }
                        await this.promisedSetState({
                            tags_dropdown: this.state.tags_dropdown,
                        });
                    } else {
                        let buff = response.data.all_tags.map((tag) => {
                            return { id: tag.id, name: tag.name, value: tag.name };
                        });
                        this.state.tags_dropdown.options = this.state.tags_dropdown.options.concat(buff);
                        await this.promisedSetState({
                            tags_dropdown: this.state.tags_dropdown,
                        });
                    }
                }

            } catch (error) {
                console.log(error);
            }

            this.state.tags_dropdown.loading.search = false
            this.state.tags_dropdown.loading.pagination = false
            // this.state.tags_dropdown.loading = false
            await this.promisedSetState({
                tags_dropdown: this.state.tags_dropdown
            });

        }
    };

    renders = {
        firstLetter: () => {
            try {
                return this.state.user.name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        user: () => {
            try {
                if (userRegister.get()) {
                    return userRegister.get();
                } else {
                    return {}
                }
            } catch (e) {
                return {}
            }
        }
    };

    filter = (list, search) => {
        let options = list;
        try {
            if (search && search !== "") {
                let result = fuzzysort.go(search, list.map((item) => { return item.name }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(list));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "") + (this.props.includeAgents ? "&include_agents=true" : "");
            return apiRegister.call(options, url);
        },
        getTags: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url;
            if (this.state.tagType === "client") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsClients?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            } else if (this.state.tagType === "order") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsOrders?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            } else if (this.state.tagType === "component") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsReports?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            }
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{ minHeight: "4rem" }} className={(!this.props.hideMainTabs && (this.state.show_subtabs || (this.state.advanced_filter && this.state.show_advanced && this.state.advanced_filters.filter((item) => { return !item.showOnTop }).length > 0) || (this.state.advanced_filter && this.state.show_advanced && this.state.showTagsDropdown)) ? "h-32" : "h-16") + " bg-white shadow flex-col flex"}>
                {
                    !this.props.hideMainTabs &&
                    <>
                        <div className="pr-4 pl-4 flex flex-1 items-center">
                            <div className="flex flex-row flex-1">

                                {/* BREADCRUMB */}

                                <div className={(this.state.searchInput ? "" : "flex-1") + " flex items-center"}>
                                    {
                                        this.props.showClient &&
                                        <div className="hidden sm:w-68 sm:block">
                                            {
                                                <div className="w-full">
                                                    <DropdownTailwind
                                                        label={""}
                                                        small={true}
                                                        addPlusExtra={this.state.multi_client - 1}
                                                        locked={this.props.lockClient}
                                                        skipInternalSearch={true}
                                                        loader={this.state.loading_clients}
                                                        loadingPagination={this.state.loading_clients_pagination}
                                                        loadingSearch={this.state.loading_clients_search}
                                                        total={this.state.clients_total}
                                                        searchInput={true}
                                                        showStatus={true}
                                                        placeholder={"..."}
                                                        pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                                        selected={this.state.fixed_client ? this.state.fixed_client : (this.state.client ? this.state.client : { id: 0, name: "All clients (default)" })}
                                                        defaultOptions={[
                                                            { id: 0, name: "All clients (default)", icon: IdentificationIcon },
                                                            { id: 1, name: "My clients", icon: UserCircleIcon }
                                                        ]}
                                                        options={this.state.clients}
                                                        onChange={async (value) => {
                                                            await this.promisedSetState({
                                                                client: value
                                                            });
                                                            clientRegister.set(this.state.client);
                                                            if (this.props.onClient) {
                                                                this.props.onClient(this.state.client);
                                                            }
                                                            if (this.props.selectedClient) {
                                                                await this.promisedSetState({
                                                                    search: "",
                                                                });
                                                            }
                                                        }}
                                                        onPagination={async () => {
                                                            if (!this.state.loading) {
                                                                await this.promisedSetState({
                                                                    client_page: this.state.client_page + 1
                                                                });
                                                                this.functions.getClients(false, true, false);
                                                            }
                                                        }}
                                                        onSearch={async (value) => {
                                                            await this.promisedSetState({
                                                                loading_clients_search: true,
                                                                client_search: value
                                                            });
                                                            setTimeout(async () => {
                                                                if (value === this.state.client_search) {
                                                                    await this.promisedSetState({
                                                                        client_page: 1
                                                                    });
                                                                    this.functions.getClients(false, false, true, value);
                                                                }
                                                            }, 400);
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.show_advanced &&
                                        this.state.advanced_filter &&
                                        Array.isArray(this.state.advanced_filters) &&
                                        this.state.advanced_filters.filter((item) => { return item.showOnTop }).length > 0 &&
                                        <div className="h-16 w-full pr-4 pl-4 flex space-x-2 flex-row flex-1 items-center">
                                            {
                                                Array.isArray(this.state.advanced_filters) &&
                                                this.state.advanced_filters.filter((item) => { return item.showOnTop }).map((item) => {
                                                    if (item.multiSelect) {
                                                        return (
                                                            <div className={(item.maxWidth ? "max-w-68 " : "") + "flex flex-1 min-w-40"}>
                                                                <MultiDropdown
                                                                    label={""}
                                                                    fillOut={true}
                                                                    small={true}
                                                                    locked={item.locked}
                                                                    searchInput={item.search}
                                                                    placeholder={item.placeholder}
                                                                    selected={item.selected ? item.selected : []}
                                                                    defaultSelected={item.defaultSelected ? item.defaultSelected : {}}
                                                                    defaultOptions={item.defaultOptions}
                                                                    options={item.options}
                                                                    onChange={async (value) => {
                                                                        if (this.props.onAdvancedFilter) {
                                                                            this.props.onAdvancedFilter(value, item.type);
                                                                        }
                                                                    }}
                                                                    onSearch={async (value) => {
                                                                        if (this.props.onAdvancedFilterSearch) {
                                                                            this.props.onAdvancedFilterSearch(value, item.type);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className={(item.maxWidth ? "max-w-68 " : "") + "flex flex-1 min-w-40"}>
                                                                <DropdownTailwind
                                                                    label={""}
                                                                    fillOut={true}
                                                                    small={true}
                                                                    locked={item.locked}
                                                                    searchInput={item.search}
                                                                    search={item.search_value ? item.search_value : ""}
                                                                    placeholder={item.placeholder}
                                                                    selected={item.selected ? item.selected : {}}
                                                                    defaultOptions={item.defaultOptions}
                                                                    skipInternalSearch={item.skipInternalSearch ? true : false}
                                                                    total={item.total}
                                                                    pagination={item.total ? item.total > (item.page * 10) : false}
                                                                    loadingPagination={item.loading}
                                                                    options={item.options}
                                                                    onChange={async (value) => {
                                                                        if (this.props.onAdvancedFilter) {
                                                                            this.props.onAdvancedFilter(value, item.type);
                                                                        }
                                                                    }}
                                                                    onSearch={async (value) => {
                                                                        if (this.props.onAdvancedFilterSearch) {
                                                                            this.props.onAdvancedFilterSearch(value, item.type);
                                                                        }
                                                                    }}
                                                                    onPagination={async () => {
                                                                        if (this.props.onAdvancedFilterPagination) {
                                                                            this.props.onAdvancedFilterPagination(item.type);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    }
                                </div>



                                {/* WELCOME MESSAGE */}
                                {
                                    this.props.showUser &&
                                    <div className="text-sm pr-2 justify-end items-center font-semibold flex flex-1">
                                        Welcome <span className={"pl-1"}>{this.renders.user().email}</span>
                                    </div>
                                }

                                {/* PAGINATION */}
                                {
                                    this.state.showPaginaton &&
                                    <div className="flex flex-row">
                                        <div className="flex flex-1 items-center justify-center mr-4">
                                            {
                                                this.state.total !== 0 &&
                                                <p className="text-sm text-gray-700">
                                                    Showing <span className="font-medium">{this.state.page * this.state.limit - this.state.limit + 1}</span> to <span className="font-medium">{((this.state.page * this.state.limit) > this.state.total) ? this.state.total : this.state.page * this.state.limit}</span> of{' '}
                                                    <span className="font-medium">{this.state.total ? this.state.total : 0}</span> results
                                                </p>
                                            }
                                        </div>
                                        {
                                            this.state.showPaginationSearch &&
                                            <div className="flex justify-center items-center mr-2">
                                                <div className="rounded-md border-1.5 flex flex-row">
                                                    <div className="w-48 mr-2">
                                                        <input
                                                            //id={"test"}
                                                            type={"text"}
                                                            className={" bg-custom-input block font-normal w-full border-none pr-4 py-2 px-4 rounded-md rounded-r-none h-9 focus:outline-none text-sm"}
                                                            placeholder={"Search ..."}
                                                            value={this.state.searchInputValue !== "" ? this.state.searchInputValue : this.state.search}
                                                            onChange={async (event) => {
                                                                await this.promisedSetState({
                                                                    search: event.target.value
                                                                });
                                                                if (this.props.onSearch) {
                                                                    this.props.onSearch(this.state.search);
                                                                }
                                                            }}
                                                            onKeyDown={(value) => {

                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        false &&
                                                        <button
                                                            type="button"
                                                            onClick={async () => {
                                                                if (this.props.onSearch) {
                                                                    this.props.onSearch(this.state.search);
                                                                }
                                                            }}
                                                            className={"cursor-pointer inline-flex rounded-l-none items-center px-4 pl-2 h-10 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                        >
                                                            <SearchIcon className="mr-2 h-5 w-5" />
                                                            Search
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="flex justify-center items-center mr-2">
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    if (this.state.page !== 1 && this.props.onPrevious) {
                                                        this.props.onPrevious(this.state.page > 1 ? (this.state.page - 1) : 1);
                                                    }
                                                }}
                                                className={(this.state.page === 1 ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                            >
                                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                                Previous
                                            </button>
                                        </div>
                                        {
                                            Array.isArray(this.state.filters) &&
                                            this.state.showFilters &&
                                            <div className="flex flex-row">
                                                {
                                                    this.state.filters.map((filter) => {
                                                        return (
                                                            <Menu as="div" className="relative inline-block text-left mr-2">
                                                                <div>
                                                                    <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                        {filter.name}: {filter.selected ? filter.selected.name : "-"}
                                                                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="border max-h-40 absolute z-50 overflow-scroll mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-1 pt-0">
                                                                            {
                                                                                <div className={" bg-gray-100 justify-center items-center overflow-hidden flex flex-row"}>
                                                                                    <div className="px-3 text-gray-500 text-opacity-75">
                                                                                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    </div>
                                                                                    <input
                                                                                        id={"test"}
                                                                                        type={this.props.type}
                                                                                        disabled={this.state.disabled}
                                                                                        className={"bg-gray-100 block font-normal w-full border-none pr-4 py-2 h-12 focus:outline-none text-sm"}
                                                                                        placeholder={this.props.placeholder}
                                                                                        value={filter.search}
                                                                                        onChange={(event) => {
                                                                                            filter.search = event.target.value;
                                                                                            this.setState({
                                                                                                filters: this.state.filters
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                Array.isArray(filter.options) &&
                                                                                this.filter(filter.options, filter.search).map((item) => {
                                                                                    return (
                                                                                        <Menu.Item>
                                                                                            {({ active }) => (
                                                                                                <div
                                                                                                    onClick={async () => {
                                                                                                        if (this.props.onFilter) {
                                                                                                            this.props.onFilter(item, filter);
                                                                                                        }
                                                                                                    }}
                                                                                                    className={classNames(
                                                                                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                        'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                                        filter.selected && filter.selected.id == item.id ? "text-purple-500" : ""
                                                                                                    )}
                                                                                                >
                                                                                                    {item.name}
                                                                                                </div>
                                                                                            )}
                                                                                        </Menu.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                        <Menu as="div" className="flex items-center inline-block text-left mr-2">
                                            <div>
                                                <Menu.Button className={"cursor-pointer inline-flex relative justify-center items-center w-full h-10 rounded-md  border-1.5 px-4 bg-white text-sm font-medium text-gray-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                    Page limit: {this.state.limit}
                                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="border absolute z-50 mt-2 w-56 max-h-40 overflow-scroll rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        {
                                                            [5, 10, 20, 30, 50, 100, 500, 1000, 2000].map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={async () => {
                                                                                    if (this.props.onLimit) {
                                                                                        this.props.onLimit(item);
                                                                                    }
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                    'px-4 py-2 text-sm relative flex flex-row cursor-pointer',
                                                                                    this.state.limit == item ? "text-purple-500" : ""
                                                                                )}
                                                                            >
                                                                                {item}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                        <div className="flex justify-center items-center">
                                            <div className="">
                                                <button
                                                    onClick={async () => {
                                                        if (!((this.state.page * this.state.limit) >= +this.state.total) && this.props.onNext) {
                                                            this.props.onNext(this.state.page + 1);
                                                        }
                                                    }}
                                                    className={(((this.state.page * this.state.limit) >= +this.state.total) ? "cursor-not-allowed opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10  border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                                >
                                                    <span>Next</span>
                                                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        </div>
                                        {
                                            false &&
                                            this.state.show_advanced &&
                                            <div onClick={() => {
                                                this.setState({
                                                    advanced_filter: !this.state.advanced_filter
                                                })
                                            }} className={"ml-2 rounded-md inline-flex items-center justify-center w-10 h-10 border-1.5 text-sm font-medium cursor-pointer hover:border-gray-500"}>
                                                <MenuAlt1Icon className="h-5 w-5" />
                                            </div>
                                        }
                                    </div>
                                }

                                {/* USER BUTTON */}
                                {
                                    this.state.showDateSpan &&
                                    <div className="flex flex-row items-center">
                                        <div className="flex items-center">
                                            <Menu as="div" className="ml-3 relative">
                                                <div>
                                                    <Menu.Button className="max-w-xs py-2 px-4 mr-2 border-1.5 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 p-1 rounded-md bg-gray-100 bg-opacity-50">
                                                        <CalendarIcon className="w-5 h-5" />
                                                        <span className="ml-3 text-gray-700 text-sm font-semibold block">
                                                            {this.state.dateSpan && this.state.dateSpan.value ? this.state.dateSpan.name : "Last 7 days"}
                                                        </span>
                                                        <ChevronDownIcon
                                                            className="flex-shrink-0 ml-2 h-5 w-5 text-gray-700 block"
                                                            aria-hidden="true"
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="origin-top-right absolute overflow-hidden z-50 border-1.5 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {
                                                            [
                                                                { id: 3, name: "Last 7 days", value: "last_7" },
                                                                { id: 2, name: "Last 14 days", value: "last_14" },
                                                                { id: 1, name: "Last 30 days", value: "last_30" },
                                                                { id: 5, name: "Last 90 days", value: "last_90" }
                                                            ].concat([{ id: 4, name: "Custom dates", value: "custom" }].filter((item) => { if (this.state.custom_dates) { return true } else return false })).map((item) => {
                                                                return (
                                                                    <Menu.Item>
                                                                        {({ active }) => (
                                                                            <div
                                                                                onClick={(e) => {
                                                                                    if (item.id !== 4) {
                                                                                        if (this.props.onDateSpan) {
                                                                                            this.props.onDateSpan(item);
                                                                                        }
                                                                                    } else {
                                                                                        if (this.props.onCustomDates) {
                                                                                            this.props.onCustomDates();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                className={classNames(
                                                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                    'block px-4 py-3 text-sm cursor-pointer'
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        )}
                                                                    </Menu.Item>
                                                                )
                                                            })
                                                        }
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                }

                                {/* ACTIVATE ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.activate_order &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.activate_order) {
                                                        this.props.onButton('active');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md  bg-green-500 hover:bg-green-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Activate</span>
                                                <PlayIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.activate_order &&
                                                    <div className="w-full h-full absolute inset-0 bg-green-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* PAUSE ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.pause_order &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.pause_order) {
                                                        this.props.onButton('paused');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-orange-400 hover:bg-orange-500 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Pause</span>
                                                <PauseIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.pause_order &&
                                                    <div className="w-full h-full absolute inset-0 bg-orange-400 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* ARCHIVE ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.archive_order &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.archive_order) {
                                                        this.props.onButton('archived');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-gray-500 hover:bg-gray-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Archive</span>
                                                <ArchiveIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.archive_order &&
                                                    <div className="w-full h-full absolute inset-0 bg-gray-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* DELETE ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.delete_order &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.delete_order) {
                                                        this.props.onButton('delete');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-red-500 hover:bg-red-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Delete</span>
                                                <ArchiveIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.delete_order &&
                                                    <div className="w-full h-full absolute inset-0 bg-red-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* IMPORT ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.upload_campaign &&
                                    <div className="hidden col-span-2 sm:flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={() => {

                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md  bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Import campaign</span>
                                                <UploadIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* CREATE REPORT BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.create_report &&
                                    <div className="hidden col-span-2 sm:flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton) {
                                                        this.props.onButton('create');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>New Report</span>
                                                <CubeIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* CREATE ORDER BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.create_order &&
                                    <div className="hidden col-span-2 sm:flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    this.props.history.push("/v2/orders/new");
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>New Order</span>
                                                <CubeIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* CREATE METRIC BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.create_metric &&
                                    <div className="hidden col-span-2 sm:flex justify-center items-center">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton) {
                                                        this.props.onButton('create');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>New Metric</span>
                                                <CubeIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* CREATE CAMPAIGN BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.create_campaign &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton) {
                                                        this.props.onButton('create');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-purple-500 hover:bg-purple-600  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>New Campaign</span>
                                                <FolderIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.create_campaign &&
                                                    <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* CANCEL CAMPAIGN BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.cancel &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.cancel) {
                                                        this.props.onButton('cancel');
                                                    }
                                                }}
                                                className={"inline-flex shadow relative justify-center rounded-md  bg-red-500 hover:bg-red-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Cancel</span>
                                                <XIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.cancel &&
                                                    <div className="w-full h-full absolute inset-0 bg-red-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* SAVE CAMPAIGN BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.save &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.save) {
                                                        this.props.onButton('save');
                                                    }
                                                }}
                                                className={"inline-flex shadow relative justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Save</span>
                                                <SaveAsIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.save &&
                                                    <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* OPEN PREVIEW BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.preview &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.preview) {
                                                        this.props.onButton('preview');
                                                    }
                                                }}
                                                className={"inline-flex shadow relative justify-center rounded-md bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Preview</span>
                                                <ExternalLinkIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.preview &&
                                                    <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }
                                {/* ACTIVATE CLIENT BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.activate_client &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.activate_client) {
                                                        this.props.onButton('active');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md  bg-green-500 hover:bg-green-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Activate</span>
                                                <PlayIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.activate_client &&
                                                    <div className="w-full h-full absolute inset-0 bg-green-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* PAUSE CLIENT BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.pause_client &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.pause_client) {
                                                        this.props.onButton('paused');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-orange-400 hover:bg-orange-500 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Pause</span>
                                                <PauseIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.pause_client &&
                                                    <div className="w-full h-full absolute inset-0 bg-orange-400 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* ARCHIVE CLIENT BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.archive_client &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.archive_client) {
                                                        this.props.onButton('archived');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-gray-500 hover:bg-gray-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Archive</span>
                                                <ArchiveIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.archive_client &&
                                                    <div className="w-full h-full absolute inset-0 bg-gray-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                                {/* DELETE CLIENT BUTTON */}
                                {
                                    this.state.buttonTypes && this.state.buttonTypes.delete_client &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div className="">
                                            <button
                                                onClick={() => {
                                                    if (this.props.onButton && !this.state.buttonLoaders.delete_client) {
                                                        this.props.onButton('delete');
                                                    }
                                                }}
                                                className={"inline-flex relative shadow justify-center rounded-md bg-red-500 hover:bg-red-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Delete</span>
                                                <ArchiveIcon className="ml-2 h-5 w-5" />
                                                {
                                                    this.state.buttonLoaders.delete_client &&
                                                    <div className="w-full h-full absolute inset-0 bg-red-500 flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }





                                {
                                    this.state.budget_management &&
                                    <div className="hidden sm:flex justify-center items-center ml-2">
                                        <div id="budget_management" className="flex transition-all duration-1000 items-center relative">
                                            <div className='flex relative items-center justify-center mt-3 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.3rem", paddingBottom: "0.4rem" }}>
                                                {
                                                    this.state.budget_management.loading &&
                                                    <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                        <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div className="flex ml-5 text-xms font-semibold items-center">
                                                    Automated budget control
                                                    {
                                                        false &&
                                                        <div style={{ bottom: "2px", top: "6px", right: "2px" }} class="ml-1 px-2 z-20 bg-custom-input flex justify-center items-center">
                                                            <span
                                                                className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                                    {
                                                                        ["bg-green-100 text-green-500"]: this.state.budget_management.maxBudgetEnabled,
                                                                        ["bg-orange-100 text-orange-500 bg-opacity-50"]: !this.state.budget_management.maxBudgetEnabled
                                                                    })
                                                                }>
                                                                {this.state.budget_management.maxBudgetEnabled ? "enabled" : "disabled"}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={"relative overflow-hidden mr-5 ml-3"}>
                                                    <SwitchTailwind
                                                        value={this.state.budget_management.maxBudgetEnabled}
                                                        disabled={this.state.budget_management.locked && !this.state.budget_management.maxBudgetEnabled ? true : false}
                                                        onSwitch={async () => {
                                                            if (this.props.onBudgetManagement && !this.state.budget_management.loading) {
                                                                this.props.onBudgetManagement();
                                                            }
                                                            //this.functions.updateMaxBudgetEnabled();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                                {
                                    Array.isArray(this.state.buttons) &&
                                    this.state.buttons.filter((item) => {
                                        return item.show
                                    }).map((item) => {
                                        return (
                                            <div className="hidden sm:flex justify-center items-center ml-2">
                                                <div className="">
                                                    <button
                                                        onClick={() => {
                                                            if (!item.disabled) {
                                                                this.props.onButton(item.value);
                                                            }
                                                        }}
                                                        className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex shadow relative justify-center rounded-md py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                    >
                                                        <span>{item.text}</span>
                                                        {
                                                            item.icon &&
                                                            <item.icon className="ml-2 h-5 w-5" />
                                                        }
                                                        {
                                                            item.loading &&
                                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </>
                }
                {
                    this.state.show_advanced &&
                    this.state.advanced_filter &&
                    ((Array.isArray(this.state.advanced_filters) && this.state.advanced_filters.filter((item) => { return !item.showOnTop }).length > 0) || this.state.showTagsDropdown) &&
                    <div className="h-16 w-full border-t pr-4 pl-4 flex space-x-2 flex-row flex-1 items-center">
                        {
                            Array.isArray(this.state.advanced_filters) &&
                            this.state.advanced_filters.filter((item) => { return !item.showOnTop }).map((item) => {
                                if (item.multiSelect && item.type !== "tags") {
                                    return (
                                        <div className={(item.maxWidth ? "max-w-68 " : item.maxWidthFit ? "max-w-md " : "") + "flex flex-1 min-w-40"}>
                                            <MultiDropdown
                                                label={""}
                                                fillOut={true}
                                                small={true}
                                                locked={item.locked}
                                                placeholder={item.placeholder}
                                                selected={item.selected ? item.selected : []}
                                                defaultSelected={item.defaultSelected ? item.defaultSelected : {}}
                                                defaultOptions={item.defaultOptions}
                                                options={item.options}
                                                onChange={async (value) => {
                                                    if (this.props.onAdvancedFilter) {
                                                        this.props.onAdvancedFilter(value, item.type);
                                                    }
                                                }}
                                                onSearch={async (value) => {
                                                    if (this.props.onAdvancedFilterSearch) {
                                                        this.props.onAdvancedFilterSearch(value, item.type);
                                                    }
                                                }}
                                            />
                                        </div>
                                    )

                                } else {
                                    return (
                                        <div className={(item.maxWidth ? "max-w-68 " : "") + "flex flex-1 min-w-40"}>
                                            <DropdownTailwind
                                                label={""}
                                                fillOut={true}
                                                small={true}
                                                locked={item.locked}
                                                searchInput={item.search}
                                                placeholder={item.placeholder}
                                                selected={item.selected ? item.selected : {}}
                                                defaultOptions={item.defaultOptions}
                                                options={item.options}
                                                onChange={async (value) => {
                                                    if (this.props.onAdvancedFilter) {
                                                        this.props.onAdvancedFilter(value, item.type);
                                                    }
                                                }}
                                                onSearch={async (value) => {
                                                    if (this.props.onAdvancedFilterSearch) {
                                                        this.props.onAdvancedFilterSearch(value, item.type);
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            })
                        }
                        {
                            this.state.showTagsDropdown &&
                            <div className={"max-w-68 flex flex-1 min-w-40"}>
                                <MultiDropdown
                                    excludeFilter={true}
                                    label={""}
                                    fillOut={true}
                                    small={true}
                                    placeholder={this.state.tags_dropdown.placeholder}
                                    selected={this.state.tags_dropdown.selected ? this.state.tags_dropdown.selected : []}
                                    defaultSelected={this.state.tags_dropdown.defaultSelected ? this.state.tags_dropdown.defaultSelected : {}}
                                    defaultOptions={this.state.tags_dropdown.defaultOptions}
                                    options={this.state.tags_dropdown.options}
                                    searchInput={this.state.tags_dropdown.searchInput}
                                    search={this.state.tags_dropdown.search}
                                    showPagination={true}
                                    pagination={this.state.tags_dropdown.total > (this.state.tags_dropdown.page * this.state.tags_dropdown.limit)}
                                    total={this.state.tags_dropdown.total}
                                    loadingPagination={this.state.tags_dropdown.loading.pagination}
                                    loadingSearch={this.state.tags_dropdown.loading.search}
                                    onChange={async (value) => {
                                        this.state.tags_dropdown.selected = value;
                                        await this.promisedSetState({
                                            tags_dropdown: this.state.tags_dropdown
                                        });
                                        if (this.props.onSetTags) {
                                            this.props.onSetTags(this.state.tags_dropdown.selected);
                                        }
                                    }}
                                    onPagination={async () => {
                                        if (!this.state.loading) {
                                            this.state.tags_dropdown.page = this.state.tags_dropdown.page + 1;
                                            await this.promisedSetState({
                                                tags_dropdown: this.state.tags_dropdown
                                            });
                                            this.functions.getTags(false, true, false, false);
                                        }
                                    }}
                                    onSearch={async (value) => {
                                        this.state.tags_dropdown.search = value
                                        await this.promisedSetState({
                                            tags_dropdown: this.state.tags_dropdown
                                        });
                                        setTimeout(async () => {
                                            if (value === this.state.tags_dropdown.search) {
                                                this.state.tags_dropdown.page = 1;
                                                await this.promisedSetState({
                                                    tags_dropdown: this.state.tags_dropdown
                                                });
                                                this.functions.getTags(false, false, true, value);
                                            }
                                        }, 400);
                                    }}
                                />
                            </div>
                        }
                        {
                            this.state.show_table_settings &&
                            <div className='flex flex-1 justify-end'>
                                <div
                                    onClick={() => this.props.onOpenTableSettings()}
                                    className={` bg-purple-500 hover:bg-purple-600  items-center text-sm cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none`}
                                    style={{ width: "fit-content" }}
                                >
                                    <span>Edit table columns</span>
                                    <CogIcon class="w-5 ml-2" />
                                </div>
                            </div>
                        }
                        {
                            this.state.showMessage &&
                            <div className="pl-4 text-sm text-gray-700">
                                {this.props.message}
                            </div>
                        }
                    </div>
                }
                {
                    //!this.state.show_advanced &&
                    //!this.state.advanced_filter &&
                    this.state.show_subtabs &&
                    <div className={"w-full border-t pr-4 pl-4 flex space-x-2 transition-all duration-1000 items-center h-16 flex-1"}>
                        <div className={"overflow-scroll " + (this.state.budget_management ? "flex flex-1" : "block")}>
                            <nav className="flex space-x-4 flex-row " aria-label="Tabs">
                                {this.state.subtabs.map((tab) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                if (this.props.onSubtab) {
                                                    this.props.onSubtab(tab);
                                                }
                                            }}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={classNames(
                                                tab.id == this.state.subtab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                            )}
                                            aria-current={tab.id == this.state.subtab.id ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </div>
                                    );
                                })}
                            </nav>
                        </div>

                        {
                            this.state.budget_management &&
                            <div id="budget_management" className="flex transition-all duration-1000 items-center relative">
                                <div className='flex flex-1 justify-end whitespace-no-wrap mr-2'>
                                    <div
                                        onClick={() => this.props.onNewBudgetItem()}
                                        className={` bg-purple-500 hover:bg-purple-600  items-center text-sm cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none`}
                                        style={{ width: "fit-content" }}
                                    >
                                        <span>Add new budget</span>
                                        <PlusIcon class="w-5 ml-2" />
                                    </div>
                                </div>
                            </div>
                        }


                        {false &&
                            this.state.show_table_settings &&
                            <div className='flex justify-end'>
                                <div
                                    onClick={() => this.props.onOpenTableSettings()}
                                    className={` bg-purple-500 hover:bg-purple-600  items-center text-sm cursor-pointer focus:ring-purple-500 text-white inline-flex shadow relative justify-center rounded-md py-2 px-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none`}
                                    style={{ width: "fit-content" }}
                                >
                                    <span>Edit table columns</span>
                                    <CogIcon class="w-5 ml-2" />
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

}

export default TopNavigationAgent;