import React, { Component, Fragment } from 'react';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import { ArrowDownIcon, CogIcon } from '@heroicons/react/outline'
import SlideoutTailwind from './slideoutTailwind';
import TableColumnSorter from './tableColumnSorter';

class TableHeadTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            empty_space: false,
            sort: "name",
            direction: "descending",
            page: 1,
            column_sort_props: { page: 1, sort: "name", direction: "descending" },
            open: false,
            loading: false,
            view_name: "",
            disabled: [],
            enabled: [],
            channel: {},
            sticky_col: false,
            sticky: false,
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            columns: this.props.columns,
            sort: this.props.sort,
            direction: this.props.direction,
            has_button: this.props.has_button,
            show_settings: this.props.show_settings,
            empty_space: this.props.empty_space,
            column_sort_props: this.props.column_sort_props,
            open: this.props.open,
            loading: this.props.loading,
            view_name: this.props.view_name,
            disabled: this.props.disabled,
            enabled: this.props.enabled,
            channel: this.props.channel,
            sticky_col: this.props.sticky_col,
            sticky: this.props.sticky,
        })

        await this.functions.getColumns()
        //console.log(this.state, "COLUMNS=====")
    }

    functions = {
        getColumns: async () => {
            return new Promise(async (resolve) => {
                try {
                    let res = await this.calls.getColumns()
                    if (this.props.view_name in res.data) {
                        res.data[this.props.view_name].map(savedCol => {
                            this.state.columns = this.state.columns.map(col => {
                                if (savedCol.value === col.value) {
                                    col.disabled = savedCol.disabled
                                    col.index = savedCol.index
                                }
                                return col
                            })
                        })

                        this.state.columns.sort((a, b) => {
                            return a.index - b.index
                        })

                        await this.promisedSetState({
                            columns: this.state.columns
                        })
                    }

                    this.props.onColumnSave(this.state.columns)

                } catch (error) {
                    console.log(error)
                }
                resolve()
            })
        },
        saveColumnOrder: async () => {
            try {
                let data = { columns: this.state.columns, view_name: this.props.view_name }
                await this.promisedSetState({ loading: true })
                let res = await this.calls.saveColumnOrder(data)
                if (res.data[this.props.view_name].length > 0) {
                    await this.promisedSetState({
                        columns: res.data[this.props.view_name],
                        loading: false,
                        open: false,
                    })
                }
            } catch (error) {
                console.log(error)
            }
            this.props.onColumnSave(this.state.columns)

        },
        openSlideOut: async () => {
            this.setState({ open: true })
        },
        filterByChannel: async (channel) => {
            let buff;
            if (channel.value !== "google") {
                buff = this.state.columns.filter(col => {
                    return !('channel' in col);
                })
                buff.length > 0 && await this.promisedSetState({ columns: buff })
            } else {
                buff = this.props.columns
                buff.length > 0 && await this.promisedSetState({ columns: buff })
            }

        }
    };

    calls = {
        saveColumnOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateUserTableColumns";
            return apiRegister.call(options, url);
        },
        getColumns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getUserTableColumns";
            return apiRegister.call(options, url);
        },
    };

    renders = {

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve();
            });
        });
    }

    render() {

        return (
            <>
                <SlideoutTailwind
                    title={"Edit table columns"}
                    submitButtonText={"Save"}
                    large={false}
                    secondaryButton={false}
                    open={this.state.open ? true : false}
                    loader={this.state.loading ? true : false}
                    onCancel={() => {
                        this.promisedSetState({ open: false });
                    }}
                    onSubmit={async () => {
                        this.refs.TableColumnSorter.functions.saveColumnOrder()
                    }}
                >
                    <TableColumnSorter
                        ref={"TableColumnSorter"}
                        columns={this.state.columns}
                        saveColumnOrder={async (newColumns) => {
                            await this.promisedSetState({
                                columns: newColumns.map((item, index) => {
                                    return {
                                        ...item,
                                        index: index,
                                    }
                                }
                                ),
                            })
                            this.functions.saveColumnOrder()
                        }}

                    />

                </SlideoutTailwind>


                <thead className={"bg-white" + (this.state.sticky ? " sticky z-10 top-0" : "")}>
                    <tr className='select-none'>
                        {
                            this.state.empty_space &&
                            <th style={{ width: "100px", maxWidth: "100px" }} scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase hover:bg-gray-100 hover:bg-opacity-75 cursor-pointer"></th>
                        }
                        {
                            this.state.columns.length !== 0 &&
                            this.state.columns.map((item, index) => {
                                if (item.disabled) {
                                    return;
                                }
                                return (
                                    <>
                                        <th
                                            onClick={async () => {
                                                if (!item.noSort) {
                                                    await this.promisedSetState({
                                                        page: 1,
                                                        sort: item.value,
                                                        direction: this.state.direction === 'ascending' ? 'descending' : 'ascending',
                                                        column_sort_props: { page: 1, sort: item.value, direction: this.state.direction === 'ascending' ? 'descending' : 'ascending' },

                                                    })
                                                    if (this.props.onColumnSort) {
                                                        this.props.onColumnSort(this.state.column_sort_props)
                                                    }
                                                }
                                            }}
                                            style={item.min_width ? { minWidth: item.min_width, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                            scope="col"
                                            className={`${this.state.sticky_col && index === 0 ? "sticky left-0 z-20 " : ""} ${index === 0 ? "bg-white bg-opacity-100 " : "bg-white bg-opacity-50 "}} ${this.state.sort === item.value ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")} px-6 py-3 border-gray-300 text-left text-xs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider`}
                                        >
                                            <div className="flex justify-between">
                                                <div className="mr-4">{item.name}</div>
                                                {
                                                    !item.noSort &&
                                                    <div className="flex flex-col">
                                                        <ArrowDownIcon className={`h-4 w-4 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                    </div>
                                                }
                                            </div>
                                        </th>

                                    </>
                                )
                            }
                            )
                        }
                    </tr>
                </thead>
            </>
        )
    }
}

export default TableHeadTailwind;