import React, { Component, Fragment } from 'react';

import { FolderIcon, UserCircleIcon, CalendarIcon, IdentificationIcon, ChartBarIcon, XIcon, MenuAlt2Icon, SearchIcon, BellIcon, OfficeBuildingIcon, CurrencyDollarIcon, AdjustmentsIcon, ChartPieIcon, VariableIcon, PlayIcon, PauseIcon, ExclamationIcon, CloudIcon, HandIcon, ClockIcon, LightBulbIcon, PlusIcon, PlusCircleIcon, DocumentIcon, UserIcon, ChevronDownIcon, ArchiveIcon, PhotographIcon, CubeIcon, UserGroupIcon, ChevronRightIcon, MenuIcon, ArrowNarrowLeftIcon, TrashIcon, CheckIcon, UploadIcon, SaveAsIcon, ExternalLinkIcon, ArrowLeftIcon, ArrowRightIcon, MenuAlt1Icon, DotsVerticalIcon, CogIcon } from '@heroicons/react/outline'
import { Transition, Menu } from '@headlessui/react'
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from "../services/userRegister";
import { clientRegister } from "../services/clientRegister";
import DropdownTailwind from './dropdownTailwind';
import MultiDropdown from './multiDropdownTailwind';
import { apiRegister } from '../services/apiRegister';
import SwitchTailwind from './switchTailwind';
import cn from "classnames";
const fuzzysort = require('fuzzysort');

class TopNavigationAgentTiny extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            expand: false,
            advanced: false,
            breadcrumb: "",
            buttonTypes: {},
            buttonLoaders: {},
            orderId: "",
            dateSpan: {},
            searchInputValue: "",
            clients: [],
            search: "",
            fixed_client: null,
            client: { id: 0, name: "All clients (default)", icon: IdentificationIcon },
            page: 1,
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            throttling: {},
            multi_client: 0,
            subtabs: [],
            subtab: {},
            budget_management: false,
            show_table_settings: false,
            custom_dates: false,
            showTagsDropdown: false,
            tags_dropdown: {
                type: "tags",
                search: "",
                searchInput: true,
                multiSelect: true,
                total: 0,
                placeholder: "No tags",
                defaultOptions: [],
                defaultSelected: { id: 1, name: "No tags (default)", value: "all" },
                options: [{ id: 1, name: "No tags (default)", value: "all" }],
                selected: [],
                showPaginationSearch: true,
                limit: 10,
                page: 1,
                loading: {
                    search: false,
                    pagination: false

                }
            },
            tagType: "",
            url_tags: []
        }
    };

    componentWillMount() {
        this.init.user();
        this.init.client();
        if (this.props.showTagsDropdown) {
            this.functions.getTags();
        }
    }

    componentDidMount() {
        this.setState({
            orderId: this.props.orderId,
            breadcrumb: this.props.breadcrumb,
            dateSpan: this.props.dateSpan,
            buttonTypes: this.props.buttonTypes,
            buttonLoaders: this.props.buttonLoaders ? this.props.buttonLoaders : {},
            buttons: this.props.buttons ? this.props.buttons : [],
            showPaginaton: this.props.showPaginaton,
            showDateSpan: this.props.showDateSpan,
            limit: this.props.limit,
            page: this.props.page,
            total: this.props.total,
            searchInput: this.props.searchInput,
            searchInputValue: this.props.searchInputValue,
            showPaginationSearch: this.props.showPaginationSearch,
            showFilters: this.props.showFilters,
            filters: this.props.filters,
            fixed_client: this.props.client,
            show_advanced: this.props.showAdvanced,
            advanced_filters: this.props.advancedFilters,
            multi_client: this.props.multiClient,
            advanced_filter: this.props.advancedFilter ? this.props.advancedFilter : this.state.advanced_filter,
            show_subtabs: this.props.show_subtabs,
            subtabs: Array.isArray(this.props.subtabs) ? this.props.subtabs : [],
            subtab: this.props.subtab ? this.props.subtab : {},
            show_table_settings: this.props.show_table_settings,
            custom_dates: this.props.custom_dates ? this.props.custom_dates : false,
            showTagsDropdown: this.props.showTagsDropdown,
            tagType: this.props.tagType,
            url_tags: this.props.url_tags,
            showMessage: this.props.showMessage,
            budget_management: this.props.budget_management ? this.props.budget_management : false,

        });

        if (!this.props.lockClient && !this.props.showClientLimited) {
            this.functions.getClients(true, false, false);
        }

        if (this.props.selectedClient && this.props.selectedClient.id !== 0 && this.props.selectedClient.id !== 1) {
            this.setState({
                client: this.props.selectedClient,
                clients: [this.props.selectedClient]
            })
        }

        if (this.props.miniMizeOnLoad && this.props.minimizeMenu) {
            this.props.minimizeMenu(true);
        }

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            orderId: nextProps.orderId,
            breadcrumb: nextProps.breadcrumb,
            dateSpan: nextProps.dateSpan,
            buttonTypes: nextProps.buttonTypes,
            buttonLoaders: nextProps.buttonLoaders ? nextProps.buttonLoaders : {},
            buttons: nextProps.buttons ? nextProps.buttons : [],
            showPaginaton: nextProps.showPaginaton,
            showDateSpan: nextProps.showDateSpan,
            limit: nextProps.limit,
            page: nextProps.page,
            total: nextProps.total,
            searchInput: nextProps.searchInput,
            searchInputValue: nextProps.searchInputValue,
            showPaginationSearch: nextProps.showPaginationSearch,
            showFilters: nextProps.showFilters,
            filters: nextProps.filters,
            client: nextProps.client,
            fixed_client: nextProps.selectedClient ? nextProps.selectedClient : nextProps.client,
            // client: nextProps.client,
            // fixed_client: nextProps.client,
            multi_client: nextProps.multiClient,
            show_advanced: nextProps.showAdvanced,
            advanced_filters: nextProps.advancedFilters,
            show_subtabs: nextProps.show_subtabs,
            subtabs: Array.isArray(nextProps.subtabs) ? nextProps.subtabs : [],
            subtab: nextProps.subtab ? nextProps.subtab : {},
            show_table_settings: nextProps.show_table_settings,
            url_tags: nextProps.url_tags,
            budget_management: nextProps.budget_management ? nextProps.budget_management : false,
        })
    }

    init = {
        user: () => {
            if (userRegister.get()) {
                this.setState({
                    user: userRegister.get()
                })
            }
        },
        client: () => {
            this.setState({
                client: clientRegister.get()
            });
        }
    };

    functions = {
        logout: async () => {
            this.calls.logout();
            tokenRegister.remove();
            userRegister.remove();
            clientRegister.remove();
            this.props.history.push('/login');
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                //loading_clients_search: search,
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        setClient: (client) => {
            this.setState({
                client: client
            })
        },
        getTags: async (init, pagination, search, search_value) => {
            this.state.tags_dropdown.loading.search = search
            this.state.tags_dropdown.loading.pagination = pagination
            // this.state.tags_dropdown.loading = true
            await this.promisedSetState({ tags_dropdown: this.state.tags_dropdown });
            let all_tags = [];

            try {
                let response = await this.calls.getTags();
                if (!search_value || (search_value && search_value == this.state.tags_dropdown.search)) {
                    if (!pagination) {
                        if (search) {
                            response.data.all_tags.map((tag) => {
                                tag = { id: tag.id, name: tag.name, value: tag.name };
                                all_tags.push(tag);
                            })
                            this.state.tags_dropdown.options = all_tags;
                            this.state.tags_dropdown.total = response.meta.total;
                        } else {
                            response.data.all_tags.map((tag) => {
                                tag = { id: tag.id, name: tag.name, value: tag.name };
                                all_tags.push(tag);
                            })
                            this.state.tags_dropdown.options = this.state.tags_dropdown.options.concat(all_tags);
                            this.state.tags_dropdown.total = response.meta.total;
                            if (response.data && response.data.selected_tags && response.data.selected_tags.length !== 0) {
                                this.state.tags_dropdown.selected = response.data.selected_tags;
                            }
                        }
                        await this.promisedSetState({
                            tags_dropdown: this.state.tags_dropdown,
                        });
                    } else {
                        let buff = response.data.all_tags.map((tag) => {
                            return { id: tag.id, name: tag.name, value: tag.name };
                        });
                        this.state.tags_dropdown.options = this.state.tags_dropdown.options.concat(buff);
                        await this.promisedSetState({
                            tags_dropdown: this.state.tags_dropdown,
                        });
                    }
                }

            } catch (error) {
                console.log(error);
            }

            this.state.tags_dropdown.loading.search = false
            this.state.tags_dropdown.loading.pagination = false
            // this.state.tags_dropdown.loading = false
            await this.promisedSetState({
                tags_dropdown: this.state.tags_dropdown
            });

        }
    };

    renders = {
        firstLetter: () => {
            try {
                return this.state.user.name.charAt(0);
            } catch (e) {
                return ''
            }
        },
        user: () => {
            try {
                if (userRegister.get()) {
                    return userRegister.get();
                } else {
                    return {}
                }
            } catch (e) {
                return {}
            }
        },
        paginationString: () => {
            let string_pagination = "";
            string_pagination += (this.state.page * this.state.limit - this.state.limit + 1).toString();
            string_pagination += " to ";
            string_pagination += (((this.state.page * this.state.limit) > this.state.total) ? this.state.total : this.state.page * this.state.limit).toString();
            string_pagination += " of ";
            string_pagination += (this.state.total ? this.state.total : 0).toString();
            return string_pagination;
        }
    };

    filter = (list, search) => {
        let options = list;
        try {
            if (search && search !== "") {
                let result = fuzzysort.go(search, list.map((item) => { return item.name }));
                options = [];
                let old_options = JSON.parse(JSON.stringify(list));
                result.filter((item, index) => {
                    return index < 20
                }).map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    calls = {
        logout: () => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/logoutUser";
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "") + (this.props.includeAgents ? "&include_agents=true" : "");
            return apiRegister.call(options, url);
        },
        getTags: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url;
            if (this.state.tagType === "client") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsClients?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            } else if (this.state.tagType === "order") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsOrders?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            } else if (this.state.tagType === "component") {
                url = apiRegister.url.api + "/v3/adcredo/listTagsReports?limit=" + this.state.tags_dropdown.limit + "&page=" + this.state.tags_dropdown.page + (this.state.tags_dropdown.search !== "" ? ("&search=" + this.state.tags_dropdown.search) : "") + (this.state.client && this.state.client.id !== 0 && this.state.client.id !== undefined ? "&client=" + this.state.client.id : "") + (this.state.url_tags && this.state.url_tags.length !== 0 ? this.state.url_tags.map((tag) => { return "&url_tags[]=" + tag.name }).join('') : "");
            }
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div style={{ minHeight: "4rem" }} className={"h-16 bg-white shadow flex-col flex"}>
                {
                    <>
                        <div className="pr-4 pl-4 flex flex-1 items-center">
                            <div className="flex flex-row flex-1">

                                <div className={"flex-1 flex items-center"}>
                                    {
                                        ((this.state.show_advanced &&
                                            this.state.advanced_filter &&
                                            Array.isArray(this.state.advanced_filters) &&
                                            this.state.advanced_filters.length > 0) || this.state.showTagsDropdown) &&
                                        <div className="flex space-x-2 flex-row flex-1 items-center">
                                            {
                                                Array.isArray(this.state.advanced_filters) &&
                                                this.state.advanced_filters.map((item) => {
                                                    if (item.multiSelect) {
                                                        return (
                                                            <div className={"flex flex-1 max-w-48"}>
                                                                <MultiDropdown
                                                                    scale={true}
                                                                    label={""}
                                                                    fillOut={true}
                                                                    small={true}
                                                                    locked={item.locked}
                                                                    searchInput={item.search}
                                                                    placeholder={item.placeholder}
                                                                    selected={item.selected ? item.selected : []}
                                                                    defaultSelected={item.defaultSelected ? item.defaultSelected : {}}
                                                                    defaultOptions={item.defaultOptions}
                                                                    options={item.options}
                                                                    onChange={async (value) => {
                                                                        if (this.props.onAdvancedFilter) {
                                                                            this.props.onAdvancedFilter(value, item.type);
                                                                        }
                                                                    }}
                                                                    onSearch={async (value) => {
                                                                        if (this.props.onAdvancedFilterSearch) {
                                                                            this.props.onAdvancedFilterSearch(value, item.type);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div className={"flex flex-1 max-w-48"}>
                                                                <DropdownTailwind
                                                                    scale={true}
                                                                    label={""}
                                                                    fillOut={true}
                                                                    small={true}
                                                                    locked={item.locked}
                                                                    searchInput={item.search}
                                                                    search={item.search_value ? item.search_value : ""}
                                                                    placeholder={item.placeholder}
                                                                    selected={item.selected ? item.selected : {}}
                                                                    defaultOptions={item.defaultOptions}
                                                                    skipInternalSearch={item.skipInternalSearch ? true : false}
                                                                    total={item.total}
                                                                    pagination={item.total ? item.total > (item.page * 10) : false}
                                                                    loadingPagination={item.loading}
                                                                    options={item.options}
                                                                    onChange={async (value) => {
                                                                        if (this.props.onAdvancedFilter) {
                                                                            this.props.onAdvancedFilter(value, item.type);
                                                                        }
                                                                    }}
                                                                    onSearch={async (value) => {
                                                                        if (this.props.onAdvancedFilterSearch) {
                                                                            this.props.onAdvancedFilterSearch(value, item.type);
                                                                        }
                                                                    }}
                                                                    onPagination={async () => {
                                                                        if (this.props.onAdvancedFilterPagination) {
                                                                            this.props.onAdvancedFilterPagination(item.type);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                this.state.showTagsDropdown &&
                                                <div className={"max-w-48 flex flex-1 min-w-40"}>
                                                    <MultiDropdown
                                                        excludeFilter={true}
                                                        scale={true}
                                                        label={""}
                                                        fillOut={true}
                                                        small={true}
                                                        placeholder={this.state.tags_dropdown.placeholder}
                                                        selected={this.state.tags_dropdown.selected ? this.state.tags_dropdown.selected : []}
                                                        defaultSelected={this.state.tags_dropdown.defaultSelected ? this.state.tags_dropdown.defaultSelected : {}}
                                                        defaultOptions={this.state.tags_dropdown.defaultOptions}
                                                        options={this.state.tags_dropdown.options}
                                                        searchInput={this.state.tags_dropdown.searchInput}
                                                        search={this.state.tags_dropdown.search}
                                                        showPagination={true}
                                                        pagination={this.state.tags_dropdown.total > (this.state.tags_dropdown.page * this.state.tags_dropdown.limit)}
                                                        total={this.state.tags_dropdown.total}
                                                        loadingPagination={this.state.tags_dropdown.loading.pagination}
                                                        loadingSearch={this.state.tags_dropdown.loading.search}
                                                        onChange={async (value) => {
                                                            this.state.tags_dropdown.selected = value;
                                                            await this.promisedSetState({
                                                                tags_dropdown: this.state.tags_dropdown
                                                            });
                                                            if (this.props.onSetTags) {
                                                                this.props.onSetTags(this.state.tags_dropdown.selected);
                                                            }
                                                        }}
                                                        onPagination={async () => {
                                                            if (!this.state.loading) {
                                                                this.state.tags_dropdown.page = this.state.tags_dropdown.page + 1;
                                                                await this.promisedSetState({
                                                                    tags_dropdown: this.state.tags_dropdown
                                                                });
                                                                this.functions.getTags(false, true, false, false);
                                                            }
                                                        }}
                                                        onSearch={async (value) => {
                                                            this.state.tags_dropdown.search = value
                                                            await this.promisedSetState({
                                                                tags_dropdown: this.state.tags_dropdown
                                                            });
                                                            setTimeout(async () => {
                                                                if (value === this.state.tags_dropdown.search) {
                                                                    this.state.tags_dropdown.page = 1;
                                                                    await this.promisedSetState({
                                                                        tags_dropdown: this.state.tags_dropdown
                                                                    });
                                                                    this.functions.getTags(false, false, true, value);
                                                                }
                                                            }, 400);
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>

                                {/* PAGINATION */}
                                {
                                    this.state.showPaginaton &&
                                    <div className="flex flex-row">
                                        {
                                            this.state.showPaginationSearch &&
                                            <div className="flex justify-center items-center">
                                                <div className="rounded-md flex flex-row">
                                                    <div className="w-48 mr-2">
                                                        <input
                                                            type={"text"}
                                                            className={" bg-custom-input tiny-input block font-medium w-full border-1.5 pr-4 px-3 rounded-md h-10 focus:outline-none"}
                                                            placeholder={"Search ..."}
                                                            value={this.state.searchInputValue !== "" ? this.state.searchInputValue : this.state.search}
                                                            onChange={async (event) => {
                                                                await this.promisedSetState({
                                                                    search: event.target.value
                                                                });
                                                                if (this.props.onSearch) {
                                                                    this.props.onSearch(this.state.search);
                                                                }
                                                            }}
                                                            onKeyDown={(value) => {

                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <button
                                            type="button"
                                            onClick={async () => {
                                                if (this.state.page !== 1 && this.props.onPrevious) {
                                                    this.props.onPrevious(this.state.page > 1 ? (this.state.page - 1) : 1);
                                                }
                                            }}
                                            className={(this.state.page === 1 ? "cursor-not-allowed text-opacity-50" : "cursor-pointer") + " inline-flex rounded-r-none items-center px-4 h-10 border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowLeftIcon className="w-4" />
                                        </button>
                                        <DropdownTailwind
                                            subTitle={this.renders.paginationString()}
                                            noBorderSides={true}
                                            scale={true}
                                            label={""}
                                            selected={{ id: this.state.limit, name: this.state.limit, value: this.state.limit }}
                                            options={[5, 10, 20, 30, 50, 100, 500, 1000, 2000].map((item) => { return { id: item, name: item, value: item } })}
                                            onChange={async (value) => {
                                                if (this.props.onLimit) {
                                                    this.props.onLimit(value.value);
                                                }
                                            }}
                                        />
                                        <button
                                            onClick={async () => {
                                                if (!((this.state.page * this.state.limit) >= +this.state.total) && this.props.onNext) {
                                                    this.props.onNext(this.state.page + 1);
                                                }
                                            }}
                                            className={(((this.state.page * this.state.limit) >= +this.state.total) ? "cursor-not-allowed text-opacity-50" : "cursor-pointer") + " inline-flex items-center px-4 h-10 rounded-l-none border-1.5 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                                        >
                                            <ArrowRightIcon className="w-4" />
                                        </button>
                                        {
                                            this.state.show_table_settings &&
                                            <div
                                                onClick={() => this.props.onOpenTableSettings()}
                                                className={`inline-flex items-center ml-2 w-12 h-10 border-1.5 justify-center cursor-pointer text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                            >
                                                <CogIcon class="w-5" />
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    Array.isArray(this.state.buttons) &&
                                    this.state.buttons.filter((item) => {
                                        return item.show
                                    }).map((item) => {
                                        return (
                                            <div className="hidden sm:flex justify-center items-center ml-2">
                                                <div className="">
                                                    <button
                                                        onClick={() => {
                                                            if (!item.disabled) {
                                                                this.props.onButton(item.value);
                                                            }
                                                        }}
                                                        className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                    >
                                                        <span>{item.text}</span>
                                                        {
                                                            item.icon &&
                                                            <item.icon className="ml-2 h-5 w-5" />
                                                        }
                                                        {
                                                            item.loading &&
                                                            <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }

}

export default TopNavigationAgentTiny;