import React, { Component, Fragment } from 'react';
import { SlideDown } from "react-slidedown";
import cx from "classnames";
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, ChevronUpIcon, AdjustmentsIcon, TrashIcon, UserGroupIcon, CogIcon, CalculatorIcon, IdentificationIcon, UsersIcon, FolderIcon, BookOpenIcon, ReplyIcon, PlusIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import TagManager from './tagManager';
const fuzzysort = require('fuzzysort');

class CreateReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            open: {},
            report: {},
            report_type: {},
            report_options: [],
            search: {},
            sort: 'name',
            order: 'asc',
            selected_channels: {},
            templates: [],
            templates_search: '',
            templates_limit: 10,
            templates_page: 1,
            templates_total: 0,
            selected_template: {},
            clients: [],
            client_search: '',
            client_limit: 10,
            client_page: 1,
            clients_total: 0,
            selected_client: {},
            selected_clients: [],
            campaigns: [],
            campaign_search: '',
            search_campaigns: '',
            selected_campaigns: [],
            name: "",
            error: null,
            custom_datasource_info: {},
            tags: []
        };
    };

    async componentDidMount() {

        this.setState({
            type: this.props.type
        });

        //SET REPORT TYPE OPTIONS
        let options = [];
        if (this.props.type === "advanced") {
            options = [
                { id: 2, title: "New professional report", value: "professional", description: "Create a highly customizable report featuring multiple draggable options to display your data" },
                { id: 3, title: "From template", value: "template", description: "Use one of our premade templates or one of your previously saved templates to display your data" },
            ];
        } else if (this.props.type === "standard") {
            options = [
                { id: 1, title: "New standard report", value: "standard", description: "Create a quick report using campaigns, adgroups and ads to display your data" },
                { id: 3, title: "From template", value: "template", description: "Use one of our premade templates or one of your previously saved templates to display your data" },
            ];
        } else if (this.props.type === "template") {
            options = [
                { id: 1, title: "New standard template", value: "standard", description: "Create a quick report using campaigns, adgroups and ads to display your data", regular: true, disabled: true },
                { id: 2, title: "New professional template", value: "professional", description: "Create a highly customizable report featuring multiple draggable options to display your data", master: true, regular: true }
            ];
        }

        if (this.props.template) {
            if (this.props.template.freemium) {
                this.state.report_type = { id: 1, title: "New standard report", value: "standard", regular: true };
            } else {
                this.state.report_type = { id: 2, title: "New professional template", value: "professional", regular: true };
            }
            if (typeof this.props.template.name === "string" && this.props.template.name !== "") {
                this.state.name = this.props.template.name;
            }
            this.setState({
                open: {
                    clients: true
                }
            });
        } else {
            this.setState({
                open: {
                    type: true
                }
            });
        }

        this.setState({
            report_type: this.state.report_type,
            report_options: options
        });

        if (!this.props.report) {
            this.functions.getClients();
            if (this.props.template) {
                this.state.tags = Array.isArray(this.props.template.tags) ? this.props.template.tags : [];
                await this.promisedSetState({
                    tags: this.state.tags,
                })
            }
        } else {
            this.state.tags = Array.isArray(this.props.report.tags) ? this.props.report.tags : [];
            this.state.selected_client = this.props.report.client;
            if (this.props.report.freemium) {
                this.state.report_type = { id: 1, title: "Standard report", value: "standard" };
            } else {
                this.state.report_type = { id: 2, title: "Professional report", value: "professional" };
            }
            if (typeof this.props.report.name === "string" && this.props.report.name !== "") {
                this.state.name = this.props.report.name;
            }
            if (this.props.report.channels) {
                for (let channel in this.props.report.channels) {
                    this.props.report.channels[channel].map((item) => {
                        if (!this.state.selected_channels[item.client]) {
                            this.state.selected_channels[item.client] = {};
                        }
                        this.state.selected_channels[item.client][channel] = true;
                    });
                }
            }
            await this.promisedSetState({
                tags: this.state.tags,
                selected_channels: this.state.selected_channels,
                name: this.state.name,
                report_type: this.state.report_type,
                selected_client: this.state.selected_client,
            });
            if (!(this.props.report && !this.props.report.template && this.props.report.masterTemplate)) {
                this.functions.getCampaigns(this.state.selected_client.id, null, true);
                if (Array.isArray(this.props.report.clients)) {
                    for (let i = 0; i < this.props.report.clients.length; i++) {
                        this.state.selected_clients.push({ id: Math.floor((Math.random() * 9999999999) + 1), open_client: false, client: this.props.report.clients[i], loading_campaigns: true, client_campaigns: {}, campaigns: [], search_campaigns: "", search_channel: {} });
                    }
                    await this.promisedSetState({
                        selected_clients: this.state.selected_clients
                    });
                    for (let i = 0; i < this.state.selected_clients.length; i++) {
                        this.functions.getCampaigns(null, this.state.selected_clients[i], true);
                    }
                }
            }
        }
    }

    functions = {
        getTemplates: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_templates_pagination: paginaton,
                loading_templates: init
            });
            try {
                let response = await this.calls.getTemplates();
                if (!search_value || (search_value && search_value == this.state.templates_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            templates: response.data,
                            templates_total: response.meta.total
                        });
                    } else {
                        this.state.templates = this.state.templates.concat(response.data);
                        await this.promisedSetState({
                            templates: this.state.templates,
                            templates_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_templates_search: false,
                loading_templates_pagination: false,
                loading_templates: false
            });
        },
        getClients: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_clients_pagination: paginaton,
                loading_clients: init
            });
            try {
                let response = await this.calls.getClients();
                if (!search_value || (search_value && search_value == this.state.client_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            clients: response.data.map((item) => { item.image = item.logo; return item }),
                            clients_total: response.meta.total
                        });
                    } else {
                        this.state.clients = this.state.clients.concat(response.data.map((item) => { item.image = item.logo; return item }));
                        await this.promisedSetState({
                            clients: this.state.clients,
                            clients_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_clients_search: false,
                loading_clients_pagination: false,
                loading_clients: false
            });
        },
        getCampaigns: async (id, extra_client, init) => {
            if (!extra_client) {
                await this.promisedSetState({
                    loading_campaigns: true
                });
            } else {
                extra_client.loading_campaigns = true;
                await this.promisedSetState({
                    selected_clients: this.state.selected_clients
                });
            }
            try {
                let response = await this.calls.getCampaigns(extra_client ? extra_client.client.id : id);
                Object.keys(response.data).map((channel) => {
                    if (channel === "custom_platforms") {
                        this.state.custom_datasource_info = response.data[channel];
                        for (let custom in response.data[channel]) {
                            response.data[custom] = response.data[channel][custom].campaigns;
                        }
                        delete response.data["custom_platforms"];
                    } else {
                        if (Array.isArray(response.data[channel])) {
                            response.data[channel] = response.data[channel].map((item) => {
                                item.channel = channel;
                                return item;
                            });
                        }
                    }
                });
                if (!extra_client) {
                    if (this.props.report && init) {
                        Object.keys(response.data).map((channel) => {
                            if (channel === "custom_platforms") {

                            } else {
                                if (this.props.report && this.props.report.channels && this.props.report.channels[channel]) {
                                    this.props.report.channels[channel].filter((item) => {
                                        return item.client == this.props.report.client.id;
                                    }).map((item) => {
                                        response.data[channel].map((inner_item) => {
                                            if (inner_item.id == item.channel_id) {
                                                this.state.selected_campaigns.push(inner_item);
                                            }
                                        })
                                    });
                                }
                            }
                        });
                    }
                    delete response.data.custom_platforms;
                    await this.promisedSetState({
                        custom_datasource_info: this.state.custom_datasource_info,
                        selected_campaigns: this.state.selected_campaigns,
                        campaigns: response.data
                    });
                } else {
                    if (this.props.report) {
                        Object.keys(response.data).map((channel) => {
                            if (channel === "custom_platforms") {

                            } else {
                                if (this.props.report && this.props.report.channels && this.props.report.channels[channel]) {
                                    this.props.report.channels[channel].filter((item) => {
                                        return item.client == extra_client.client.id;
                                    }).map((item) => {
                                        response.data[channel].map((inner_item) => {
                                            if (inner_item.id == item.channel_id) {
                                                extra_client.campaigns.push(inner_item);
                                            }
                                        })
                                    });
                                }
                            }
                        });
                    }
                    extra_client.client_campaigns = response.data;
                    await this.promisedSetState({
                        selected_clients: this.state.selected_clients
                    });
                }
            } catch (error) {
                console.log(error)
            }
            if (!extra_client) {
                if (!init) {
                    this.state.open.campaigns = true;
                }
                await this.promisedSetState({
                    loading_campaigns: false,
                    open: this.state.open
                });
            } else {
                extra_client.loading_campaigns = false;
                await this.promisedSetState({
                    selected_clients: this.state.selected_clients
                });
            }
        },
        create: async () => {
            if (!this.state.loading_campaigns) {
                this.state.open.type = false;
                this.state.open.clients = false;
                this.state.open.campaigns = false;
                this.setState({
                    error: null,
                    open: this.state.open
                });
                let campaign_data = {
                    tags: this.state.tags,
                    freemium: this.props.type === "standard",
                    name: this.state.name,
                    template: this.state.report_type.value === 'template',
                    ...(this.state.report_type.value === 'template' && { template_id: this.state.selected_template.id }),
                    ...(this.state.report_type.value === 'template' && { master_template: this.state.selected_template.masterTemplate }),
                    ...(this.state.report_type.value !== 'template' && { master_template: this.state.report_type.master_template }),
                    new_template: this.props.type === "template" && !this.props.template,
                    clients: Object.keys(this.state.selected_channels).map((item) => { return { id: item, channels: this.state.selected_channels[item] } })
                }
                if (this.props.type === 'template') {
                    campaign_data.freemium = this.state.report_type.value === "standard";
                }
                try {
                    let response = await this.calls.createReport(campaign_data, this.state.selected_client.id, this.state.selected_clients.filter((item) => { return item.client && item.client.id }));
                    if (this.props.type === "template" && !this.props.template) {
                        if (response.data.freemium) {
                            this.props.history.push('/v2/reports/standard/' + response.data.campaign + "/" + response.data.id);
                        } else {
                            this.props.history.push('/v2/reports/advanced/' + response.data.campaign + "/" + response.data.id);
                        }
                    } else {
                        if (response.data.freemium) {
                            this.props.history.push("/v2/reports/standard/" + response.data.campaign + "/" + response.data.id);
                        } else {
                            this.props.history.push("/v2/reports/advanced/" + response.data.campaign + "/" + response.data.id);
                        }
                    }
                } catch (error) {
                    this.setState({
                        error: error.body ? error.body.message : "Something went wrong"
                    });
                    this.props.onError();
                }
            } else {
                this.props.onError();
            }
        },
        update: async () => {
            if (!this.state.loading_campaigns) {

                this.state.open.type = false;
                this.state.open.clients = false;
                this.state.open.campaigns = false;
                this.setState({
                    error: null,
                    open: this.state.open
                });
                let campaign_data = {
                    tags: this.state.tags,
                    name: this.state.name,
                    client: this.state.selected_client.id,
                    clients: Object.keys(this.state.selected_channels).map((item) => { return { id: item, channels: this.state.selected_channels[item] } })
                }

                //IF MASTER TEMPLATE AND CREATED
                if (this.props.report && !this.props.report.template && this.props.report.masterTemplate) {
                    campaign_data = {
                        tags: this.state.tags,
                        name: this.state.name
                    };
                }

                try {
                    if (this.props.template) {
                        await this.calls.updateReport(campaign_data, this.props.template.id);
                        this.props.onCreated();
                    } else {
                        await this.calls.updateReport(campaign_data, this.props.report.id);
                        this.props.onCreated();
                    }
                } catch (error) {
                    this.setState({
                        error: error.body ? error.body.message : "Something went wrong"
                    });
                    this.props.onError();
                }
            } else {
                this.props.onError();
            }
        }
    }

    calls = {
        getTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?template=true&limit=" + this.state.templates_limit + "&page=" + this.state.templates_page + "&status=" + this.state.type + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.templates_search !== "" ? ("&search=" + this.state.templates_search) : "");
            return apiRegister.call(options, url);
        },
        getClients: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClients?limit=" + this.state.client_limit + "&page=" + this.state.client_page + "&sortBy=" + "name" + "&orderBy=" + "ascending" + (this.state.client_search !== "" ? ("&search=" + this.state.client_search) : "") + "&tagSearchable=true";
            return apiRegister.call(options, url);
        },
        getCampaigns: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientCampaigns?client=" + id + (this.props.type !== "standard" ? "&skipCampaigns=true" : "");
            return apiRegister.call(options, url);
        },
        createReport(data, client, clients) {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + '/v3/adcredo/createReport?client=' + client + (clients.length > 0 ? "&clients=" + clients.map((item) => { return item.client.id }).join(",") : "");
            return apiRegister.call(options, url);
        },
        updateReport(data, report) {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + '/v3/adcredo/updateReport?report=' + report;
            return apiRegister.call(options, url);
        }
    }

    filterList = (options, channel, search_value) => {
        let old_options = JSON.parse(JSON.stringify(options));
        let search = "";
        if (typeof search_value === "string" && search_value !== "") {
            search = search_value;
        } else {
            if (typeof this.state.search[channel] === "string" && this.state.search[channel] !== "") {
                search = this.state.search[channel];
            } else if (typeof this.state.search_campaigns === "string" && this.state.search_campaigns !== "") {
                search = this.state.search_campaigns;
            }
        }
        try {
            if (typeof search === "string" && search !== "") {
                let result = fuzzysort.go(search, options.map((item) => { return item.name }));
                options = [];
                result.map((item) => {
                    for (let i = 0; i < old_options.length; i++) {
                        if (("name" in old_options[i]) && item.target && item.target.toLowerCase() === old_options[i].name.toLowerCase()) {
                            let html_string = "";
                            if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                                for (let m = 0; m < item.target.length; m++) {
                                    if (!item.indexes.includes(m)) {
                                        html_string = html_string + '<span class="opacity-50">' + item.target.charAt(m) + '</span>';
                                    } else {
                                        html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                    }
                                }
                            }
                            old_options[i].html = html_string;
                            options.push(old_options[i]);
                            old_options.splice(i, 1);
                            break;
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
        return options;
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="flex flex-col px-6">

                {/* SELECT TYPE */}
                <div className='flex justify-between mb-4'>
                    <div className='flex'>
                        <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                            <CogIcon className="w-5 h-5" />
                        </div>
                        <div className="flex flex-col ml-2">
                            <div className="font-semibold text-sm">Select {this.props.type !== "template" ? "report" : "template"} type</div>
                            {
                                this.state.report_type && this.state.report_type.value &&
                                <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected
                                    <div className='text-purple-500 ml-1'>
                                        {this.state.selected_template && this.state.selected_template.id ? this.state.report_type.title + " - " + this.state.selected_template.name : this.state.report_type.title}
                                    </div>
                                </div>
                            }
                            {
                                !(this.state.report_type && this.state.report_type.value) &&
                                <div className="font-medium text-xs text-gray-500">Select option</div>
                            }
                        </div>
                    </div>
                    <div className='flex'>
                        {
                            this.state.report_type && this.state.report_type.value &&
                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                <CheckIcon className="w-5 h-5 " />
                            </div>
                        }
                        <div className={((this.props.report || this.props.template) ? "bg-custom-input w-10 cursor-not-allowed" : "bg-gray-100 cursor-pointer ") + " h-10 w-10  border-1.5 flex justify-center items-center rounded-md"}
                            onClick={() => {
                                if (!this.props.template && !this.props.report) {
                                    this.state.open.type = !this.state.open.type;
                                    this.setState({ open: this.state.open })
                                }
                            }}
                        >
                            {
                                !this.props.template &&
                                !this.props.report &&
                                !this.state.open.type &&
                                <ChevronDownIcon className="w-5 h-5 " />
                            }
                            {
                                !this.props.template &&
                                !this.props.report &&
                                this.state.open.type &&
                                <ChevronUpIcon className="w-5 h-5 " />
                            }
                            {
                                (this.props.report || this.props.template) &&
                                <LockClosedIcon className="w-5 h-5 text-gray-500" />
                            }
                        </div>
                    </div>
                </div>
                <SlideDown
                    closed={!(this.state.open.type && !this.props.report && !this.props.template)}
                >
                    <div className='mb-4 space-y-4'>
                        <div className='flex justify-between space-x-4'>
                            {this.state.report_options.map((item) => {
                                return (
                                    <div className={`flex flex-col col-span-6 relative overflow-hidden rounded-md border-1.5 w-1/2 cursor-pointer ${this.state.report_type && this.state.report_type.value === item.value ? "border-purple-300 bg-purple-100" : "border-gray-300"}`}

                                    >
                                        {
                                            item.disabled &&
                                            <div className="absolute z-40 left-0 right-0 top-0 bottom-0 bg-white cursor-not-allowed bg-opacity-75"></div>
                                        }
                                        <div onClick={() => {
                                            if (!item.disabled) {
                                                if (item.value === "template") {
                                                    this.functions.getTemplates();
                                                    this.state.open.templates = true
                                                    this.state.open.clients = false;
                                                    this.state.open.type = false;
                                                } else {
                                                    this.state.selected_template = {};
                                                    this.state.open.templates = false;
                                                    this.state.open.type = false;
                                                    if (!item.master_template) {
                                                        this.state.open.clients = true;
                                                    }
                                                }
                                                this.setState({
                                                    report_type: item,
                                                    report: this.state.report,
                                                    open: this.state.open,
                                                    selected_template: this.state.selected_template
                                                })
                                            }
                                        }} className="p-4">
                                            <div className={`text-sm font-semibold mb-1 ${this.state.report_type && this.state.report_type.value === item.value && "text-purple-500"}`}>
                                                {item.title}
                                            </div>
                                            <div className={`text-sm font-medium ${this.state.report_type && this.state.report_type.value === item.value ? "" : "text-gray-500"}`}>
                                                {item.description}
                                            </div>
                                        </div>
                                        {
                                            this.props.type === "template" &&
                                            <div className="border-t bg-custom-input">
                                                <div className={(!item.master ? "opacity-0" : "") + " p-4 flex flex-row overflow-hidden items-center bg-custom-input relative"}>
                                                    {
                                                        !item.master &&
                                                        <div className="absolute z-20 left-0 cursor-not-allowed right-0 bottom-0 top-0 bg-white opacity-0"></div>
                                                    }
                                                    <div className="text-sm font-medium flex flex-1">
                                                        Master template
                                                    </div>
                                                    <div>
                                                        <SwitchTailwind
                                                            value={this.state.report_type.master_template && this.state.report_type && this.state.report_type.value === item.value ? true : false}
                                                            onSwitch={async () => {
                                                                this.state.report_type = item;
                                                                this.state.report_type.master_template = !this.state.report_type.master_template;
                                                                await this.promisedSetState({
                                                                    report_type: this.state.report_type
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>

                    {/* NEXT */}
                    {
                        this.state.report_type && this.state.report_type.value &&
                        <div className='flex justify-center mb-4'>
                            <div
                                onClick={() => {
                                    if (this.state.report_type && this.state.report_type.value) {
                                        this.state.open.type = !this.state.open.type;
                                        if (!this.state.report_type.master_template) {
                                            this.state.open.clients = true;
                                        } else {
                                            this.state.open.clients = true;
                                        }
                                        this.setState({ open: this.state.open })
                                    }
                                }}
                                className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 bg-purple-500 text-white cursor-pointer"}`}>
                                Next step
                            </div>
                        </div>
                    }

                </SlideDown>

                {/* FULL BORDER */}
                {
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME)
                    this.state.report_type && this.state.report_type.value === "template" &&
                    <div className="border-b w-full"></div>
                }

                {/* SELECT TEMPLATE */}
                {
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME)
                    this.state.report_type && this.state.report_type.value === "template" &&
                    <>
                        <div className={"flex justify-between my-4"}>
                            <div className='flex'>
                                <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                    <BookOpenIcon className="w-5 h-5" />
                                </div>
                                <div className="flex flex-col ml-2">
                                    <div className="font-semibold text-sm">Select template</div>
                                    {
                                        this.state.selected_template && this.state.selected_template.id &&
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.selected_template.name}</div></div>
                                    }
                                    {
                                        !(this.state.selected_template && this.state.selected_template.id) &&
                                        <div className="font-medium text-xs text-gray-500">No template selected</div>
                                    }
                                </div>
                            </div>
                            <div className='flex'>
                                {
                                    this.state.selected_template && this.state.selected_template.id &&
                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CheckIcon className="w-5 h-5 " />
                                    </div>
                                }
                                <div className={(this.props.report ? "bg-custom-input w-10 cursor-not-allowed" : "bg-gray-100 cursor-pointer ") + " h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                    onClick={() => {
                                        if (!this.props.report) {
                                            this.state.open.templates = !this.state.open.templates;
                                            this.setState({ open: this.state.open })
                                        }
                                    }}
                                >
                                    {
                                        !this.props.report &&
                                        !this.state.open.templates &&
                                        <ChevronDownIcon className="w-5 h-5 " />
                                    }
                                    {
                                        !this.props.report &&
                                        this.state.open.templates &&
                                        <ChevronUpIcon className="w-5 h-5 " />
                                    }
                                    {
                                        this.props.report &&
                                        <LockClosedIcon className="w-5 h-5 text-gray-500" />
                                    }
                                </div>
                            </div>
                        </div>
                        <SlideDown
                            closed={!(this.state.open.templates && !this.props.report)}
                        >
                            <div className='mb-4'>
                                <div className='mt-4'>
                                    <DropdownTailwind
                                        small={false}
                                        showType={true}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_templates}
                                        loadingPagination={this.state.loading_templates_pagination}
                                        loadingSearch={this.state.loading_templates_search}
                                        total={this.state.templates_total}
                                        searchInput={true}
                                        placeholder={"Search ..."}
                                        pagination={this.state.templates_total > (this.state.templates_page * this.state.templates_limit)}
                                        selected={this.state.selected_template.id !== 1 ? this.state.selected_template : { id: 1, name: "Select template" }}
                                        options={this.state.templates.length > 0 ? this.state.templates.map((item) => { item.type = (item.masterTemplate ? "scale" : "regular"); return item }) : []}
                                        onChange={async (value) => {
                                            this.state.selected_template = value;
                                            if (this.state.selected_campaigns.length > 0) {
                                                this.state.selected_campaigns = [];
                                            }
                                            this.state.open.templates = false;
                                            this.state.open.clients = true;
                                            await this.promisedSetState({
                                                selected_template: value,
                                                open: this.state.open
                                            });

                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_templates_pagination) {
                                                await this.promisedSetState({
                                                    templates_page: this.state.templates_page + 1
                                                });
                                                this.functions.getTemplates(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_templates_search: true,
                                                templates_search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.templates_search) {
                                                    await this.promisedSetState({
                                                        templates_page: 1
                                                    });
                                                    this.functions.getTemplates(false, false, true, value);
                                                }
                                            }, 400);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* NEXT */}
                            <div className='flex justify-center mb-4'>
                                <div
                                    onClick={() => {
                                        this.state.open.templates = false;
                                        this.state.open.clients = true;
                                        this.setState({ open: this.state.open })
                                    }}
                                    className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.selected_template.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                    Next step
                                </div>
                            </div>

                        </SlideDown>
                    </>
                }

                {/* FULL BORDER */}
                {
                    this.props.type !== "template" &&
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME)
                    ((this.state.report_type && this.state.report_type.value && this.state.report_type.value !== "template") || (this.state.report_type && this.state.report_type.value == "template" && this.state.selected_template.id)) &&
                    <div className="border-b w-full"></div>
                }

                {/* SELECT CLIENT */}
                {
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME
                    !this.state.report_type.master_template && !this.state.report_type.regular &&
                    ((this.state.report_type && this.state.report_type.value && this.state.report_type.value !== "template") || (this.state.report_type && this.state.report_type.value == "template" && this.state.selected_template.id)) &&
                    <>
                        <div className={`flex justify-between mt-4 ${!this.state.open.clients && "mb-4"}`}>
                            <div className='flex'>
                                <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                    <IdentificationIcon className="w-5 h-5" />
                                </div>
                                <div className="flex flex-col ml-2">
                                    <div className="font-semibold text-sm">Select client</div>
                                    {
                                        this.state.selected_client && this.state.selected_client.id &&
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.selected_client.name}</div></div>
                                    }
                                    {
                                        !(this.state.selected_client && this.state.selected_client.id) &&
                                        <div className="font-medium text-xs text-gray-500">No client selected</div>
                                    }
                                </div>
                            </div>
                            <div className='flex'>
                                {
                                    this.state.selected_client && this.state.selected_client.id &&
                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CheckIcon className="w-5 h-5 " />
                                    </div>
                                }
                                <div className={"bg-gray-100 cursor-pointer h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                    onClick={() => {
                                        //if (!this.props.report) {
                                        this.state.open.clients = !this.state.open.clients;
                                        this.setState({ open: this.state.open })
                                        //}
                                    }}
                                >
                                    {
                                        !this.state.open.clients &&
                                        <ChevronDownIcon className="w-5 h-5 " />
                                    }
                                    {
                                        this.state.open.clients &&
                                        <ChevronUpIcon className="w-5 h-5 " />
                                    }
                                </div>
                            </div>
                        </div>
                        <SlideDown
                            closed={!this.state.open.clients}
                        >
                            <div className='mb-4'>
                                <div className='mt-4'>
                                    <DropdownTailwind
                                        small={false}
                                        showTags={true}
                                        skipInternalSearch={true}
                                        loader={this.state.loading_clients}
                                        loadingPagination={this.state.loading_clients_pagination}
                                        loadingSearch={this.state.loading_clients_search}
                                        total={this.state.clients_total}
                                        searchInput={true}
                                        placeholder={"Search ..."}
                                        pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                        selected={this.state.selected_client.id !== 1 ? this.state.selected_client : { id: 1, name: "Select client" }}
                                        options={this.state.clients.length > 0 ? this.state.clients : []}
                                        onChange={async (value) => {
                                            this.state.open.clients = false;
                                            //this.state.open.campaigns = true;
                                            await this.promisedSetState({
                                                selected_client: value,
                                                selected_campaigns: [],
                                                open: this.state.open
                                            });
                                            if (!this.state.selected_template.masterTemplate) {
                                                this.functions.getCampaigns(value.id);
                                            }
                                        }}
                                        onPagination={async () => {
                                            if (!this.state.loading_clients_pagination) {
                                                await this.promisedSetState({
                                                    client_page: this.state.client_page + 1
                                                });
                                                this.functions.getClients(false, true, false);
                                            }
                                        }}
                                        onSearch={async (value) => {
                                            await this.promisedSetState({
                                                loading_clients_search: true,
                                                client_search: value
                                            });
                                            setTimeout(async () => {
                                                if (value === this.state.client_search) {
                                                    await this.promisedSetState({
                                                        client_page: 1
                                                    });
                                                    this.functions.getClients(false, false, true, value);
                                                }
                                            }, 400);
                                        }}
                                    />
                                </div>
                            </div>

                            {/* NEXT */}
                            {
                                <div className='flex justify-center mb-4'>
                                    <div
                                        onClick={() => {
                                            this.state.open.clients = false;
                                            if (!this.state.selected_template.masterTemplate) {
                                                this.state.open.campaigns = true;
                                            }
                                            this.setState({ open: this.state.open })
                                        }}
                                        className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${this.state.selected_client.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                        Next step
                                    </div>
                                </div>
                            }

                        </SlideDown>
                    </>
                }

                {/* SINGLE CLIENT CAMPAIGNS */}
                {
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME
                    !this.state.selected_template.masterTemplate && !this.state.report_type.regular &&
                    this.state.selected_client && this.state.selected_client.id &&
                    <div className={"col-span-12"}>
                        <div className="flex flex-row">
                            <div className="h-10 w-10 cursor-pointer mr-2 flex justify-center items-center rounded-md">
                                <ReplyIcon className="w-5 h-5" style={{ transform: 'rotate(180deg)' }} />
                            </div>
                            <div className="h-10 w-10 text-purple-500 relative cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                <FolderIcon className="w-5 h-5 " />
                            </div>
                            <div className="flex flex-1 flex-col justify-center ml-3">
                                <div className="font-semibold text-sm">Select channels</div>
                                {
                                    !this.state.loading_campaigns &&
                                    this.state.selected_channels[this.state.selected_client.id] &&
                                    Object.keys(this.state.selected_channels[this.state.selected_client.id]).length > 0 &&
                                    <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="ml-1 text-purple-500 capitalize">{Object.keys(this.state.selected_channels[this.state.selected_client.id]).join(", ")}</div></div>
                                }
                                {
                                    !this.state.loading_campaigns &&
                                    !(this.state.selected_channels[this.state.selected_client.id] && Object.keys(this.state.selected_channels[this.state.selected_client.id]).length > 0) &&
                                    <div className="font-medium text-xs text-gray-500">Select channels</div>
                                }
                                {
                                    this.state.loading_campaigns &&
                                    <div className="font-medium text-xs text-gray-500">Loading ...</div>
                                }
                            </div>
                            {
                                <div className="flex flex-row">
                                    {
                                        this.state.selected_campaigns.length > 0 &&
                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                            <CheckIcon className="w-5 h-5 " />
                                        </div>
                                    }
                                    <div onClick={() => {
                                        this.state.open.campaigns = !this.state.open.campaigns;
                                        this.setState({
                                            open: this.state.open
                                        });
                                    }} className={" bg-gray-100 w-10 h-10 cursor-pointer relative  border-1.5 flex justify-center items-center rounded-md"}>
                                        {
                                            !this.state.loading_campaigns &&
                                            !this.state.open.campaigns &&
                                            <ChevronDownIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.open.campaigns &&
                                            !this.state.loading_campaigns &&
                                            <ChevronUpIcon className="w-5 h-5 " />
                                        }
                                        {
                                            this.state.loading_campaigns &&
                                            <div className="text-center absolute top-0 bottom-0 z-50  right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <SlideDown closed={!(this.state.open.campaigns && !this.state.loading_campaigns)}>
                            <div className="mt-4 grid-cols-12 grid gap-2 pl-12">
                                {
                                    Object.keys(this.state.campaigns).filter((channel) => {
                                        if (this.state.search_campaigns !== "") {
                                            return this.filterList(this.state.campaigns[channel], channel).length > 0;
                                        } else {
                                            return true;
                                        }
                                    }).sort((a, b) => (a > b) ? 1 : -1).map((channel, index) => {
                                        return (
                                            <div className={"col-span-12"}>
                                                <div className={(index > 0 ? "border-t pt-2" : "") + " flex flex-row"}>
                                                    <div onClick={() => {
                                                        if (!this.state.selected_channels[this.state.selected_client.id]) {
                                                            this.state.selected_channels[this.state.selected_client.id] = {};
                                                        }
                                                        if (this.state.selected_channels[this.state.selected_client.id][channel]) {
                                                            delete this.state.selected_channels[this.state.selected_client.id][channel];
                                                        } else {
                                                            this.state.selected_channels[this.state.selected_client.id][channel] = true;
                                                        }
                                                        this.setState({
                                                            selected_channels: this.state.selected_channels
                                                        });
                                                    }} className="flex flex-row cursor-pointer">
                                                        <div className={(this.state.selected_channels[this.state.selected_client.id] && this.state.selected_channels[this.state.selected_client.id][channel] ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-2 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                            {
                                                                <CheckIcon className="h-5 w-5" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={this.state.custom_datasource_info[channel] ? { backgroundColor: "#" + this.state.custom_datasource_info[channel].parser.backgroundColor } : {}} className={(this.state.custom_datasource_info[channel] ? "" : ("bg-" + channel + "-500")) + " h-10 w-10 cursor-pointer p-3 flex justify-center items-center rounded-md"}>
                                                        {
                                                            this.state.custom_datasource_info[channel] && this.state.custom_datasource_info[channel].parser && this.state.custom_datasource_info[channel].parser.logo &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + this.state.custom_datasource_info[channel].parser.logo + ")" }}></div>
                                                        }
                                                        {
                                                            this.state.custom_datasource_info[channel] && !(this.state.custom_datasource_info[channel].parser && this.state.custom_datasource_info[channel].parser.logo) &&
                                                            <div className="w-full h-full flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                {this.state.custom_datasource_info[channel].parser.name[0]}{this.state.custom_datasource_info[channel].parser.name[1]}
                                                            </div>
                                                        }
                                                        {
                                                            channel === 'facebook' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'google' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'tiktok' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'adform' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'linkedin' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'twitter' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'google_analytics' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'google_analytics_4' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'bing' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'snapchat' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'bidtheatre' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'dv360' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === 'cm360' &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                        }
                                                        {
                                                            channel === "google_search_console" &&
                                                            <div
                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                                                        }
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div className="font-semibold text-sm capitalize">{channel.replace("_", " ").replace("_", " ")}</div>
                                                        <div className="flex flex-row">
                                                            {
                                                                (this.props.type !== "standard") &&
                                                                <div className="font-medium text-xs text-gray-500 flex">
                                                                    {this.state.selected_channels && this.state.selected_channels[this.state.selected_client.id] && this.state.selected_channels[this.state.selected_client.id][channel] ? "Channel select" : "Not selected"}
                                                                </div>
                                                                ||
                                                                <div className="font-medium text-xs text-gray-500 flex">
                                                                    {this.state.campaigns[channel].length} campaigns
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.state.selected_campaigns.length > 0 &&
                                    this.state.open.campaigns &&
                                    <div className="col-span-12 flex items-center justify-center">
                                        <div onClick={async () => {
                                            this.state.open.campaigns = false;
                                            this.state.open.settings = true;
                                            this.setState({
                                                open: this.state.open
                                            });
                                        }}
                                            className={(this.state.selected_campaigns.length > 0 ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                            <span style={{ whiteSpace: "nowrap" }}
                                                className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                Next step
                                            </span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </SlideDown>
                    </div>
                }

                {/* CLIENTS */}
                {
                    this.state.selected_clients.length > 0 &&
                    this.state.selected_clients.map((item) => {
                        return (
                            <Fragment>

                                {/* FULL BORDER */}
                                {
                                    <div className="border-b w-full mt-4"></div>
                                }

                                {/* SELECT CLIENT */}
                                {
                                    <>
                                        <div className={`flex justify-between mt-4`}>
                                            <div className='flex'>
                                                <div className="h-10 w-10 text-purple-500 border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <IdentificationIcon className="w-5 h-5" />
                                                </div>
                                                <div className="flex flex-col ml-2">
                                                    <div className="font-semibold text-sm">Extra client</div>
                                                    {
                                                        item.client && item.client.id &&
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{item.client.name}</div></div>
                                                    }
                                                    {
                                                        !(item.client && item.client.id) &&
                                                        <div className="font-medium text-xs text-gray-500">No client selected</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='flex items-center'>
                                                <div onClick={() => {
                                                    try {
                                                        this.state.selected_clients = this.state.selected_clients.filter((client) => {
                                                            return client.id !== item.id;
                                                        });
                                                        delete this.state.selected_channels[item.client.id];
                                                        this.setState({
                                                            selected_channels: this.state.selected_channels,
                                                            selected_clients: this.state.selected_clients
                                                        });
                                                    } catch (error) { }
                                                }} className="text-red-500 cursor-pointer hover:text-red-700 text-sm font-medium mr-4">Remove</div>
                                                {
                                                    item.client && item.client.id &&
                                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <CheckIcon className="w-5 h-5 " />
                                                    </div>
                                                }
                                                <div className={(this.props.report ? "bg-custom-input w-10 cursor-not-allowed" : "bg-gray-100 cursor-pointer ") + " h-10 w-10 border-1.5 flex justify-center items-center rounded-md"}
                                                    onClick={() => {
                                                        item.open_client = !item.open_client;
                                                        this.setState({ selected_clients: this.state.selected_clients })
                                                    }}
                                                >
                                                    {
                                                        !item.open_client &&
                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                    }
                                                    {
                                                        item.open_client &&
                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <SlideDown
                                            closed={!(item.open_client)}
                                        >
                                            <div className='mb-4'>
                                                <div className='mt-4'>
                                                    <DropdownTailwind
                                                        small={false}
                                                        showTags={true}
                                                        skipInternalSearch={true}
                                                        loader={this.state.loading_clients}
                                                        loadingPagination={this.state.loading_clients_pagination}
                                                        loadingSearch={this.state.loading_clients_search}
                                                        total={this.state.clients_total}
                                                        searchInput={true}
                                                        placeholder={"Search ..."}
                                                        pagination={this.state.clients_total > (this.state.client_page * this.state.client_limit)}
                                                        selected={item.client && item.client.id && item.client.id !== 1 ? item.client : { id: 1, name: "Select client" }}
                                                        options={this.state.clients.length > 0 ? this.state.clients : []}
                                                        onChange={async (value) => {
                                                            item.client = value;
                                                            if (!Array.isArray(item.campaigns)) {
                                                                item.campaigns = [];
                                                            }
                                                            item.open_client = false;
                                                            item.open_campaigns = true;
                                                            this.state.selected_clients = this.state.selected_clients.map((inner_item) => {
                                                                if (inner_item.id == item.id) {
                                                                    inner_item = item;
                                                                }
                                                                return inner_item;
                                                            });
                                                            await this.promisedSetState({
                                                                selected_clients: this.state.selected_clients
                                                            });
                                                            this.functions.getCampaigns(value.id, item);
                                                        }}
                                                        onPagination={async () => {
                                                            if (!this.state.loading_clients_pagination) {
                                                                await this.promisedSetState({
                                                                    client_page: this.state.client_page + 1
                                                                });
                                                                this.functions.getClients(false, true, false);
                                                            }
                                                        }}
                                                        onSearch={async (value) => {
                                                            await this.promisedSetState({
                                                                loading_clients_search: true,
                                                                client_search: value
                                                            });
                                                            setTimeout(async () => {
                                                                if (value === this.state.client_search) {
                                                                    await this.promisedSetState({
                                                                        client_page: 1
                                                                    });
                                                                    this.functions.getClients(false, false, true, value);
                                                                }
                                                            }, 400);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {/* NEXT */}
                                            <div className='flex justify-center mb-4'>
                                                <div
                                                    onClick={() => {
                                                        item.open_client = false;
                                                        item.open_campaigns = true;
                                                        this.setState({ selected_clients: this.state.selected_clients })
                                                    }}
                                                    className={`flex w-32 h-10 overflow-hidden rounded-md justify-center text-sm font-medium py-2 border-1.5 ${item.client && item.client.id ? "bg-purple-500 text-white cursor-pointer" : "bg-gray-100 text-gray-300 cursor-not-allowed"}`}>
                                                    Next step
                                                </div>
                                            </div>

                                        </SlideDown>
                                    </>
                                }

                                {/* CLIENT CAMPAIGNS */}
                                {
                                    item.client && item.client.id &&
                                    <div className={"col-span-12 mt-4"}>
                                        <div className="flex flex-row">
                                            <div className="h-10 w-10 cursor-pointer mr-2 flex justify-center items-center rounded-md">
                                                <ReplyIcon className="w-5 h-5" style={{ transform: 'rotate(180deg)' }} />
                                            </div>
                                            <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                <FolderIcon className="w-5 h-5 " />
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center ml-3">
                                                <div className="font-semibold text-sm">Select channels</div>
                                                {
                                                    !item.loading_campaigns &&
                                                    this.state.selected_channels[item.client.id] &&
                                                    Object.keys(this.state.selected_channels[item.client.id]).length > 0 &&
                                                    <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="ml-1 text-purple-500 capitalize">{Object.keys(this.state.selected_channels[item.client.id]).join(", ")}</div></div>
                                                }
                                                {
                                                    !item.loading_campaigns &&
                                                    !(this.state.selected_channels[item.client.id] && Object.keys(this.state.selected_channels[item.client.id]).length > 0) &&
                                                    <div className="font-medium text-xs text-gray-500">Select channels</div>
                                                }
                                                {
                                                    item.loading_campaigns &&
                                                    <div className="font-medium text-xs text-gray-500">Loading ...</div>
                                                }
                                            </div>
                                            <div className="flex flex-row">
                                                {
                                                    item.campaigns.length > 0 &&
                                                    <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <CheckIcon className="w-5 h-5 " />
                                                    </div>
                                                }
                                                <div onClick={() => {
                                                    item.open_campaigns = !item.open_campaigns;
                                                    this.setState({
                                                        selected_clients: this.state.selected_clients
                                                    });
                                                }} className={" bg-gray-100 w-10 h-10 cursor-pointer relative  border-1.5 flex justify-center items-center rounded-md"}>
                                                    {
                                                        !item.loading_campaigns &&
                                                        !item.open_campaigns &&
                                                        <ChevronDownIcon className="w-5 h-5 " />
                                                    }
                                                    {
                                                        item.open_campaigns &&
                                                        !item.loading_campaigns &&
                                                        <ChevronUpIcon className="w-5 h-5 " />
                                                    }
                                                    {
                                                        item.loading_campaigns &&
                                                        <div className="text-center absolute top-0 bottom-0 z-50  right-0 left-0 flex justify-center align-middle items-center">
                                                            <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            <SlideDown closed={!(item.open_campaigns && !item.loading_campaigns)}>
                                                <div className="mt-4 grid-cols-12 grid gap-2 pl-12">
                                                    {
                                                        item.client_campaigns &&
                                                        Object.keys(item.client_campaigns).filter((channel) => {
                                                            if (item.search_campaigns !== "") {
                                                                return this.filterList(item.client_campaigns[channel], channel, item.search_campaigns).length > 0;
                                                            } else {
                                                                return true;
                                                            }
                                                        }).sort((a, b) => (a > b) ? 1 : -1).map((channel, index) => {
                                                            return (
                                                                <div className={"col-span-12"}>
                                                                    <div className={(index > 0 ? "border-t pt-2" : "") + " flex flex-row"}>
                                                                        <div onClick={() => {
                                                                            if (!this.state.selected_channels[item.client.id]) {
                                                                                this.state.selected_channels[item.client.id] = {};
                                                                            }
                                                                            if (this.state.selected_channels[item.client.id][channel]) {
                                                                                delete this.state.selected_channels[item.client.id][channel];
                                                                            } else {
                                                                                this.state.selected_channels[item.client.id][channel] = true;
                                                                            }
                                                                            this.setState({
                                                                                selected_channels: this.state.selected_channels
                                                                            });
                                                                        }} className="flex flex-row cursor-pointer">
                                                                            <div className={(this.state.selected_channels[item.client.id] && this.state.selected_channels[item.client.id][channel] ? "text-purple-500  border-purple-500 bg-purple-100" : "text-gray-100 bg-gray-100") + " h-10 mr-2 w-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md"}>
                                                                                {
                                                                                    <CheckIcon className="h-5 w-5" />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={this.state.custom_datasource_info[channel] ? { backgroundColor: "#" + this.state.custom_datasource_info[channel].parser.backgroundColor } : {}} className={(this.state.custom_datasource_info[channel] ? "" : ("bg-" + channel + "-500")) + " h-10 w-10 cursor-pointer p-3 flex justify-center items-center rounded-md"}>
                                                                            {
                                                                                this.state.custom_datasource_info[channel] && this.state.custom_datasource_info[channel].parser && this.state.custom_datasource_info[channel].parser.logo &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + this.state.custom_datasource_info[channel].parser.logo + ")" }}></div>
                                                                            }
                                                                            {
                                                                                this.state.custom_datasource_info[channel] && !(this.state.custom_datasource_info[channel].parser && this.state.custom_datasource_info[channel].parser.logo) &&
                                                                                <div className="w-full h-full flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                                    {this.state.custom_datasource_info[channel].parser.name[0]}{this.state.custom_datasource_info[channel].parser.name[1]}
                                                                                </div>
                                                                            }
                                                                            {
                                                                                channel === 'facebook' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'google' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'tiktok' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'adform' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'linkedin' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'twitter' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'google_analytics' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'google_analytics_4' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'bing' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'snapchat' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'bidtheatre' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'dv360' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === 'cm360' &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                                            }
                                                                            {
                                                                                channel === "google_search_console" &&
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-1 flex-col justify-center ml-3">
                                                                            <div className="font-semibold text-sm capitalize">{channel.replace("_", " ").replace("_", " ")}</div>
                                                                            <div className="flex flex-row">
                                                                                {
                                                                                    (this.props.type !== "standard") &&
                                                                                    <div className="font-medium text-xs text-gray-500 flex">
                                                                                        {this.state.selected_channels && this.state.selected_channels[item.client.id] && this.state.selected_channels[item.client.id][channel] ? "Channel select" : "Not selected"}
                                                                                    </div>
                                                                                    ||
                                                                                    <div className="font-medium text-xs text-gray-500 flex">
                                                                                        {item.client_campaigns[channel].length} campaigns
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </SlideDown>
                                        }
                                    </div>
                                }

                            </Fragment>
                        )
                    })
                }

                {/* ADD MORE CLIENTS */}
                {
                    !(this.props.report && !this.props.report.template && this.props.report.masterTemplate) && //MASTER TEMPLATE (ONLY UPDATE TAGS AND NAME)
                    !this.state.selected_template.masterTemplate && !this.state.report_type.regular &&
                    this.state.selected_client && this.state.selected_client.id &&
                    <div onClick={async () => {
                        await this.promisedSetState({
                            client_limit: 10,
                            client_page: 1,
                            client_search: ""
                        });
                        this.functions.getClients(true);
                        this.state.selected_clients.push({ id: Math.floor((Math.random() * 9999999999) + 1), open_client: true, client: {}, loading_campaigns: true, client_campaigns: {}, campaigns: [], search_campaigns: "", search_channel: {} });
                        this.setState({
                            selected_clients: this.state.selected_clients
                        });
                    }} className="text-purple-500 hover:text-purple-700 cursor-pointer text-sm font-medium flex flex-row mt-5">
                        <PlusIcon className="w-5 mr-2" />
                        Add another client
                    </div>
                }

                {/* FULL BORDER */}
                {
                    (((this.state.selected_template.masterTemplate && this.state.selected_client && this.state.selected_client.id) || this.state.report_type.regular || this.state.report_type.master_template) || (Object.keys(this.state.selected_channels).length > 0)) &&
                    <div className={(!(this.props.report && !this.props.report.template && this.props.report.masterTemplate) ? "mt-4" : "") + " border-b w-full"}></div>
                }

                {/* TAGS */}
                {
                    (((this.state.selected_template.masterTemplate && this.state.selected_client && this.state.selected_client.id) || this.state.report_type.regular || this.state.report_type.master_template) || (Object.keys(this.state.selected_channels).length > 0)) &&
                    <div className='mt-4'>
                        <TagManager
                            report={{}}
                            label={"Add tags"}
                            tags={this.state.tags ? this.state.tags : []}
                            tagType={"component"}
                            onUpdateTags={(tags) => {
                                this.setState({
                                    tags: tags
                                });
                            }}
                        />
                    </div>
                }

                {/* NAME */}
                {
                    (((this.state.selected_template.masterTemplate && this.state.selected_client && this.state.selected_client.id) || this.state.report_type.regular || this.state.report_type.master_template) || (Object.keys(this.state.selected_channels).length > 0)) &&
                    <div className='mt-4'>
                        <InputTailwind
                            locked={false}
                            label={this.props.type !== "template" ? "Report name (required)" : "Template name (required)"}
                            placeholder={"Enter name"}
                            value={this.state.name}
                            onChange={(value) => {
                                this.setState({
                                    name: value
                                })
                            }}
                        />
                    </div>
                }

                {/* ERROR */}
                {
                    this.state.error &&
                    <div className="bg-red-100 rounded-md p-4 w-full mt-4 text-sm font-medium text-red-500">
                        {this.state.error}
                    </div>
                }

            </div>
        )
    }

};

export default CreateReport;
