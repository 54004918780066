import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { UserIcon, LockClosedIcon, CheckIcon, ArchiveIcon, ClipboardListIcon, ColorSwatchIcon, DocumentSearchIcon, BadgeCheckIcon, GlobeAltIcon, ChevronDownIcon, ArrowNarrowRightIcon, SaveAsIcon, CogIcon, ChevronUpIcon, LightningBoltIcon, IdentificationIcon, CubeIcon, ChartPieIcon, UserAddIcon, FolderIcon, StarIcon, ReplyIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import InputTailwind from './inputTailwind';
import WarningModalTailwind from './warningModalTailwind';
import SuccessModal from './successModal';
import TextAreaTailwind from './textAreaTailwind';
import phone_codes from '../assets/json/phone-countries.json';
import SlideDown from 'react-slidedown';
import TagManager from './tagManager';
import formated_timezones from '../assets/json/formated_timezones.json';
import SwitchTailwind from './switchTailwind';
import UploadImage from '../components/image-upload';
import { userRegister } from '../services/userRegister';
import InputTimepickerTailwind from './inputTimepickerTailwind';

import AccountGoogle from './accountGoogle';
import AccountFacebook from './accountFacebook';
import AccountLinkedin from './accountLinkedin';
import AccountTiktok from './accountTiktok';
import AccountTwitter from './accountTwitter';
import AccountAdform from './accountAdform';
import AccountGoogleAnalytics from './accountGoogleAnalytics';
import AccountGoogleAnalytics4 from './accountGoogleAnalytics4';
import AccountSnapchat from "./accountSnapchat";
import AccountBing from './accountBing';
import AccountDv360 from './accountDv360';
import AccountCm360 from './accountCm360';
import AccountGoogleSearchConsole from './accountGoogleSearchConsole';

/*
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
*/


class CreateClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: {
                name: "",
                startDate: moment(new Date()).format("YYYY-MM-DD"),
                endDate: null,
                documents: []
            },
            client: {
                name: "",
                email: "",
                website: "",
                company: "",
                org_number: "",
                internal_id: "",
                tags: [],
                emailSettings: {},
                language: { id: 1, name: "English", value: "en" },
                timezone: "Europe/Stockholm"
            },
            user: {},
            agents: [],
            loading_agents_search: "",
            throttling: {},
            timezones: formated_timezones,
            loading: false,
            languages: [
                { id: 1, name: "English", value: "en" },
                { id: 2, name: "Svenska", value: "sv" },
                { id: 3, name: "Dansk", value: "dk" },
                { id: 4, name: "Norsk", value: "no" }
            ],
            open: {
                client: true
            },
            templates: [],
            templates_search: '',
            templates_limit: 10,
            templates_page: 1,
            templates_total: 0,
            steps: [
                { name: 'Step 1', step: 'client', parents: [] },
                { name: 'Step 2', step: 'connections', parents: ["client"] },
                { name: 'Step 3', step: 'report', parents: ["client", "connections"] },
                { name: 'Step 4', step: 'order', parents: ["client", "connections", "report"] },
                { name: 'Step 5', step: 'agents', parents: ["client", "connections", "report", "order"] }
            ],
            loading_agents_per_channel: true,
            agents_amount_per_channel: {},
            channel_campaigns: {},
            campaigns: [],
            search_campaigns: "",
            search_agents: "",
            search_templates: ""
        };
    }

    componentDidMount() {

        this.functions.getUser();
        this.functions.getTemplates(true);
        this.functions.getAgents();
        this.functions.getAgencyAgentsPerChannelAmount();

        if (this.props.updateSteps) {
            this.props.updateSteps(this.state.steps);
        }
        if (this.props.updateStep) {
            this.props.updateStep(this.state.open);
        }

    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        createClient: async () => {
            try {
                await this.promisedSetState({
                    error: null,
                    loading_create_client: true
                });
                let response = await this.calls.createClient(this.state.client);
                this.state.client.id = response.data.id;
                this.state.client.agencyAutomatedEmails = response.data.agencyAutomatedEmails;
                await this.promisedSetState({
                    client: this.state.client
                });
                this.props.onClient(this.state.client);
                this.functions.stepForward("connections");
            } catch (error) {
                await this.promisedSetState({
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_create_client: false
            });
        },
        updateClient: async () => {
            try {
                await this.promisedSetState({
                    error: null,
                    loading_update_client: true
                });
                await this.calls.updateClient(this.state.client);
                if (this.state.open.client) {
                    this.functions.stepForward("connections");
                } else if (this.state.open.report) {
                    this.functions.stepForward("order");
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_update_client: false
            });
        },
        createOrder: async () => {
            try {
                await this.promisedSetState({
                    error: null,
                    loading_create_order: true
                });
                let response = await this.calls.createOrder({ ...this.state.order, ...{ client: this.state.client.id } });
                this.state.order.id = response.data.id;
                await this.promisedSetState({
                    order: this.state.order,
                    steps: [
                        { name: 'Step 1', step: 'client', parents: [] },
                        { name: 'Step 2', step: 'connections', parents: ["client"] },
                        { name: 'Step 3', step: 'report', parents: ["client", "connections"] },
                        { name: 'Step 4', step: 'order', parents: ["client", "connections", "report"] },
                        { name: 'Step 5', step: 'import_campaigns', parents: ["client", "connections", "report", "order"] },
                        { name: 'Step 6', step: 'agents', parents: ["client", "connections", "report", "order", "import_campaigns"] }
                    ]
                });
                this.props.onOrder(this.state.order);
                this.functions.stepForward("import_campaigns");
                if (this.props.updateSteps) {
                    this.props.updateSteps(this.state.steps);
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            if (this.state.order.id) {
                try {
                    await this.promisedSetState({
                        loading_campaigns: true
                    });
                    let response = await this.calls.listExternalCampaigns();
                    let campaigns = [];
                    for (let channel in response.data) {
                        if (Array.isArray(response.data[channel])) {
                            campaigns = campaigns.concat(response.data[channel].map((item) => { item.channel = channel; return item }));
                        }
                    }
                    await this.promisedSetState({
                        loading_campaigns: false,
                        campaigns: campaigns
                    });
                } catch (error) { }
            }
            await this.promisedSetState({
                loading_create_order: false
            });
        },
        updateOrder: async () => {
            try {
                await this.promisedSetState({
                    error: null,
                    loading_update_order: true
                });
                if (this.state.order.id) {
                    await this.calls.updateOrder({ name: this.state.order.name, startDate: this.state.order.startDate, endDate: this.state.order.endDate });
                    this.functions.stepForward("import_campaigns");
                } else {
                    this.functions.stepForward("agents");
                }
            } catch (error) {
                await this.promisedSetState({
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_update_order: false
            });
        },
        createReport: async () => {
            try {
                await this.promisedSetState({
                    error: null,
                    loading_create_report: true
                });
                let report_object = {
                    name: this.state.template.report_name,
                    tags: Array.isArray(this.state.template.report_tags) ? this.state.template.report_tags : [],
                    template_id: this.state.template.id,
                    freemium: false,
                    template: true,
                    new_template: false
                }
                if (this.state.template.masterTemplate) {
                    report_object.master_template = true;
                } else {
                    report_object.clients = [{ id: this.state.client.id, channels: this.state.template.channels }];
                }
                let response = await this.calls.createReport(report_object);
                this.state.templates = this.state.templates.map((item) => {
                    if (item.id == this.state.template.id) {
                        item.createdReport = true;
                        item.report_name = this.state.template.report_name;
                        item.report_tags = Array.isArray(this.state.template.report_tags) ? this.state.template.report_tags : [];
                        item.report_id = response.data.id;
                    }
                    return item;
                });
                await this.promisedSetState({
                    template: {},
                    templates: this.state.templates
                });
            } catch (error) {
                await this.promisedSetState({
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_create_report: false
            });
        },
        removeReport: async (report) => {
            report.loading = true;
            try {
                await this.promisedSetState({
                    error: null,
                    templates: this.state.templates
                });
                await this.calls.removeReport(report.report_id);
                this.state.templates = this.state.templates.map((item) => {
                    if (item.report_id == report.report_id) {
                        item.loading = false;
                        delete item.report_id;
                        delete item.report_name;
                        delete item.report_tags;
                        delete item.createdReport;
                    }
                    return item;
                });
                await this.promisedSetState({
                    templates: this.state.templates
                });
            } catch (error) {
                report.loading = false;
                await this.promisedSetState({
                    templates: this.state.templates,
                    error: error && error.body ? error.body.message : "Something went wrong"
                });
            }
        },
        assignAgents: async (agent, option) => {
            this.state.agents = this.state.agents.map((item) => {
                if (item.id == agent.id) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({
                agents: this.state.agents
            });
            try {
                let update_object = option == "assign" ? { claim_agent: { id: agent.id, email: agent.email } } : { remove_agent: { id: agent.id, email: agent.email } };
                if (this.state.client.id) {
                    await this.calls.updateClient(update_object);
                }
                if (this.state.order.id) {
                    await this.calls.updateOrder(update_object);
                }
                this.state.agents = this.state.agents.map((item) => {
                    if (item.id == agent.id) {
                        item.assigned = option == "assign";
                    }
                    return item;
                });
                await this.promisedSetState({
                    agents: this.state.agents
                });
            } catch (error) { }
            this.state.agents = this.state.agents.map((item) => {
                if (item.id == agent.id) {
                    item.loading = false;
                }
                return item;
            });
            await this.promisedSetState({
                agents: this.state.agents
            });
        },
        getAgencyAgentsPerChannelAmount: async () => {
            await this.promisedSetState({
                loading_agents_per_channel: true
            });
            try {
                let response = await this.calls.getAgencyAgentsPerChannelAmount();
                await this.promisedSetState({
                    agents_amount_per_channel: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_per_channel: false
            });
        },
        importCampaign: async (campaign) => {
            this.state.campaigns = this.state.campaigns.map((item) => {
                if (item.id == campaign.id && item.channel == campaign.channel) {
                    item.loading = true;
                }
                return item;
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns
            });
            try {
                await this.calls.importExternalCampaigns({
                    campaigns: [campaign]
                });
                this.state.campaigns = this.state.campaigns.map((item) => {
                    if (item.id == campaign.id && item.channel == campaign.channel) {
                        item.imported = true;
                    }
                    return item;
                });
                await this.promisedSetState({
                    campaigns: this.state.campaigns
                });
            } catch (error) { }
            this.state.campaigns = this.state.campaigns.map((item) => {
                if (item.id == campaign.id && item.channel == campaign.channel) {
                    item.loading = false;
                }
                return item;
            });
            await this.promisedSetState({
                campaigns: this.state.campaigns
            });
        },
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i] && files[i].name && files[i].size) {
                        this.functions.uploadDocument(files[i]);
                    }
                }
            }
            const randomString = Math.random().toString(36);
            this.promisedSetState({
                theInputKeyOne: randomString + 1
            });
        },
        uploadDocument: async (file) => {
            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name
            };
            this.state.order.documents.push(files_object);
            await this.promisedSetState({ order: this.state.order });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.uploadDocument(formData);
                this.state.order.documents = this.state.order.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                this.state.order.documents = this.state.order.documents.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                    }
                    return item;
                });
                await this.promisedSetState({ order: this.state.order });
            }
        },
        getTemplates: async (init, paginaton, search, search_value) => {
            await this.promisedSetState({
                loading_templates_pagination: paginaton,
                loading_templates: init
            });
            try {
                let response = await this.calls.getTemplates();
                if (!search_value || (search_value && search_value == this.state.templates_search)) {
                    if (!paginaton) {
                        await this.promisedSetState({
                            templates: response.data,
                            templates_total: response.meta.total
                        });
                    } else {
                        this.state.templates = this.state.templates.concat(response.data);
                        await this.promisedSetState({
                            templates: this.state.templates,
                            templates_total: response.meta.total
                        });
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await this.promisedSetState({
                loading_templates_search: false,
                loading_templates_pagination: false,
                loading_templates: false
            });
        },
        getAgents: async () => {
            await this.promisedSetState({
                loading_agents: true
            });
            try {
                let response = await this.calls.getAgents();
                await this.promisedSetState({
                    agents: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_agents: false
            });
        },
        stepBack: async (step) => {
            let open = {};
            if (step == "client") {
                open = { client: true, report: false, connections: false, order: false, import_campaigns: false, agents: false };
            } else if (step == "report") {
                open = { client: false, report: true, connections: false, order: false, import_campaigns: false, agents: false };
            } else if (step == "connections") {
                open = { client: false, report: false, connections: true, order: false, import_campaigns: false, agents: false };
            } else if (step == "order") {
                open = { client: false, report: false, connections: false, order: true, import_campaigns: false, agents: false };
            } else if (step == "import_campaigns") {
                open = { client: false, report: false, connections: false, order: false, import_campaigns: true, agents: false };
            } else if (step == "agents") {
                open = { client: false, report: false, connections: false, order: false, import_campaigns: false, agents: true };
            }
            this.props.onStep(step);
            await this.promisedSetState({
                open: open
            });
            if (this.props.updateStep) {
                this.props.updateStep(this.state.open);
            }
        },
        stepForward: async (step) => {
            let open = {};
            if (step == "report") {
                open = { client: false, report: true, connections: false, order: false, import_campaigns: false, agents: false };
            } else if (step == "connections") {
                open = { client: false, report: false, connections: true, order: false, import_campaigns: false, agents: false };
            } else if (step == "order") {
                open = { client: false, report: false, connections: false, order: true, import_campaigns: false, agents: false };
            } else if (step == "import_campaigns") {
                open = { client: false, report: false, connections: false, order: false, import_campaigns: true, agents: false };
            } else if (step == "agents") {
                open = { client: false, report: false, connections: false, order: false, import_campaigns: false, agents: true };
            }
            this.props.onStep(step);
            await this.promisedSetState({
                open: open
            });
            if (this.props.updateStep) {
                this.props.updateStep(this.state.open);
            }
        }
    };

    calls = {
        createClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createClient";
            return apiRegister.call(options, url);
        },
        updateClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateClient?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createOrder";
            return apiRegister.call(options, url);
        },
        createReport: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createReport?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?order=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        listExternalCampaigns: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listExternalCampaigns?order=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        importExternalCampaigns: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/importExternalCampaigns?order=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        getTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?template=true&limit=1000000&status=template&sortBy=name&orderBy=ascending";
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents";
            return apiRegister.call(options, url);
        },
        getAgencyAgentsPerChannelAmount: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyAgentsPerChannelAmount";
            return apiRegister.call(options, url);
        },
        removeReport: (report) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeReport?report=" + report;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        templateChannels: () => {
            let channels = [];
            if (this.state.client.google) {
                channels.push("google");
            }
            if (this.state.client.facebook) {
                channels.push("facebook");
            }
            if (this.state.client.linkedin) {
                channels.push("linkedin");
            }
            if (this.state.client.tiktok) {
                channels.push("tiktok");
            }
            if (this.state.client.twitter) {
                channels.push("twitter");
            }
            if (this.state.client.adform) {
                channels.push("adform");
            }
            if (this.state.client.google_analytics) {
                channels.push("google_analytics");
            }
            if (this.state.client.google_analytics_4) {
                channels.push("google_analytics_4");
            }
            if (this.state.client.snapchat) {
                channels.push("snapchat");
            }
            if (this.state.client.bing) {
                channels.push("bing");
            }
            if (this.state.client.dv360) {
                channels.push("dv360");
            }
            if (this.state.client.cm360) {
                channels.push("cm360");
            }
            if (this.state.client.google_search_console) {
                channels.push("google_search_console");
            }
            return channels;
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>

                <div className="w-full">

                    <div className="grid-cols-12 grid p-6">

                        {/* CLIENT INFORMATION */}
                        <div className={(!this.state.open.client ? "-mt-16" : "") + " col-span-12"}>
                            <div className="flex flex-row">
                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                    <IdentificationIcon className="w-5 h-5 " />
                                </div>
                                <div className="flex flex-1 flex-col justify-center ml-3">
                                    <div className="font-semibold text-sm">Client information</div>
                                    <div className="font-medium text-xs text-gray-500 flex flex-row">
                                        <div className="text-purple-500 ml-1">{this.state.client.name !== "" ? this.state.client.name : "..."}</div>
                                    </div>
                                </div>
                                <div className='flex'></div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.client}
                            >
                                <div className="mt-4 grid-cols-12 grid gap-4">
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Brand name*"}
                                            value={this.state.client.name}
                                            disabled={this.state.loading}
                                            error={this.state.error == "Name is missing"}
                                            onChange={(value) => {
                                                this.state.client.name = value;
                                                this.promisedSetState({
                                                    client: this.state.client
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={"col-span-4"}>
                                        <InputTailwind
                                            label={"Email"}
                                            value={this.state.client.email}
                                            disabled={this.state.loading}
                                            onChange={(value) => {
                                                this.state.client.email = value;
                                                this.promisedSetState({
                                                    client: this.state.client
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-4">
                                        <DropdownTailwind
                                            label={"Timezone"}
                                            small={false}
                                            skipInternalSearch={false}
                                            searchInput={true}
                                            placeholder={"..."}
                                            disabled={this.state.loading}
                                            selected={this.state.client.timezone ? { name: this.state.client.timezone, value: this.state.client.timezone } : { id: 0, name: "Select timezone" }}
                                            options={this.state.timezones.map((item) => {
                                                return item.utc.map((item) => {
                                                    return { name: item, value: item };
                                                });
                                            }).flat()}
                                            onChange={async (option) => {
                                                this.state.client.timezone = option.value;
                                                await this.promisedSetState({
                                                    client: this.state.client
                                                });
                                            }}
                                            onPagination={async () => {

                                            }}
                                            onSearch={async (value) => {

                                            }}
                                        />
                                    </div>
                                    <div className={`col-span-12 grid-cols-12 grid gap-4`}>
                                        <div className="col-span-3">
                                            <UploadImage
                                                height={"min-h-33"}
                                                title={"Upload logo (png)"}
                                                size={"full"}
                                                cover={false}
                                                imageOnly={true}
                                                disabled={this.state.loading}
                                                logo={true}
                                                value={this.state.client.logo}
                                                client={null}
                                                onChange={(url) => {
                                                    this.state.client.logo = url;
                                                    this.setState({
                                                        client: this.state.client
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-9 grid-cols-12 grid gap-4">
                                            <div className="col-span-4">
                                                <InputTailwind
                                                    label={"Reference ID"}
                                                    value={this.state.client.internal_id}
                                                    disabled={this.state.loading}
                                                    onChange={(value) => {
                                                        this.state.client.internal_id = value;
                                                        this.setState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={`col-span-4`}>
                                                <InputTailwind
                                                    label={"Website"}
                                                    value={this.state.client.website}
                                                    disabled={this.state.loading}
                                                    onChange={(value) => {
                                                        this.state.client.website = value;
                                                        this.promisedSetState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-4"}>
                                                <InputTailwind
                                                    label={"Company name"}
                                                    value={this.state.client.company}
                                                    disabled={this.state.loading}
                                                    onChange={(value) => {
                                                        this.state.client.company = value;
                                                        this.promisedSetState({
                                                            client: this.state.client
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className={"col-span-12 relative"}>
                                                <TagManager
                                                    report={{}}
                                                    label={"Add tags"}
                                                    disabled={this.state.loading}
                                                    tags={this.state.client.tags ? this.state.client.tags : []}
                                                    tagType={"component"}
                                                    onUpdateTags={(tags) => {
                                                        this.state.client.tags = tags;
                                                        this.setState({
                                                            client: this.state.client
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.error &&
                                        <div className={"col-span-12"}>
                                            <div className="bg-red-100 p-4 rounded-md">
                                                <div className="text-sm font-medium text-red-500">
                                                    {this.state.error}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SlideDown>

                            {
                                this.state.open.client &&
                                <div className="w-full border-t py-4 mt-4 flex">
                                    <div className="flex flex-1"></div>
                                    {
                                        this.state.client.id &&
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_update_client) {
                                                        this.functions.updateClient();
                                                    }
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Next
                                                {
                                                    this.state.loading_update_client &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    }
                                    {
                                        !this.state.client.id &&
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_create_client) {
                                                        this.functions.createClient();
                                                    }
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Create client
                                                {
                                                    this.state.loading_create_client &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                        </div>

                        {/* SET CONNECTIONS */}
                        <div className={(!this.state.open.client && !this.state.open.connections ? "-mt-16" : (this.state.open.connections ? "" : "hidden")) + " col-span-12"}>
                            <div className="flex flex-row">
                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                    <LightningBoltIcon className="w-5 h-5 " />
                                </div>
                                <div className="flex flex-1 flex-col justify-center ml-3">
                                    <div className="font-semibold text-sm">Connections (optional)</div>
                                    <div className="font-medium text-xs text-gray-500 flex flex-row">
                                        {"Fill in data below"}
                                    </div>
                                </div>
                                <div className='flex'></div>
                            </div>
                            {
                                this.state.open.connections &&
                                <div className="mt-4 grid-cols-12 grid gap-4 relative">
                                    {
                                        this.state.client && this.state.client.id &&
                                        [
                                            { component: AccountGoogle, channel: "google" },
                                            { component: AccountFacebook, channel: "facebook" },
                                            { component: AccountLinkedin, channel: "linkedin" },
                                            { component: AccountTiktok, channel: "tiktok" },
                                            { component: AccountTwitter, channel: "twitter" },
                                            { component: AccountAdform, channel: "adform" },
                                            { component: AccountGoogleAnalytics, channel: "google_analytics" },
                                            { component: AccountGoogleAnalytics4, channel: "google_analytics_4" },
                                            { component: AccountSnapchat, channel: "snapchat" },
                                            { component: AccountBing, channel: "bing" },
                                            { component: AccountDv360, channel: "dv360" },
                                            { component: AccountCm360, channel: "cm360" },
                                            { component: AccountGoogleSearchConsole, channel: "google_search_console" }
                                        ].filter((item) => {
                                            return this.state.agents_amount_per_channel && this.state.agents_amount_per_channel[item.channel] > 0;
                                        }).map(((item) => {
                                            return (
                                                <div className={(this.state.selected_channel && this.state.selected_channel !== item.channel ? "opacity-25 " : "") + (this.state.selected_channel == item.channel ? "absolute bg-white bg-opacity-75 w-full col-span-12 z-10" : "col-span-6") + ""}>
                                                    <item.component
                                                        createClient={true}
                                                        withBackgroundColor={true}
                                                        me={this.state.user}
                                                        isManager={this.state.user.manager || this.state.user.userRole === "admin" ? true : false}
                                                        client={this.state.client}
                                                        onChange={(connected_channel) => {
                                                            this.state.client[item.channel] = connected_channel;
                                                            this.setState({
                                                                client: this.state.client,
                                                                selected_channel: null
                                                            });
                                                        }}
                                                        onSelect={(select) => {
                                                            this.setState({
                                                                selected_channel: select ? item.channel : null
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            }
                            {
                                this.state.open.connections &&
                                <div className="w-full border-t py-4 mt-4 flex">
                                    <div onClick={() => {
                                        this.functions.stepBack("client");
                                    }} className="cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                                        Back
                                    </div>
                                    <div className="flex flex-1"></div>
                                    <div
                                        onClick={() => {
                                            this.functions.stepForward("report");
                                        }}
                                        className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        Next
                                    </div>
                                </div>
                            }
                        </div>

                        {/* REPORT AND EMAIL */}
                        <div className={(!this.state.open.client && !this.state.open.connections && !this.state.open.report ? "-mt-16" : (this.state.open.report ? "" : "hidden")) + " col-span-12"}>
                            <div className="flex flex-row">
                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                    <ChartPieIcon className="w-5 h-5 " />
                                </div>
                                <div className="flex flex-1 flex-col justify-center ml-3">
                                    <div className="font-semibold text-sm">Create reports (optional)</div>
                                    <div className="font-medium text-xs text-gray-500 flex flex-row">
                                        {"Fill in data below"}
                                    </div>
                                </div>
                                <div className='flex'></div>
                            </div>
                            <SlideDown
                                closed={!this.state.open.report}
                            >
                                <div className="mt-4 grid-cols-12 grid gap-4 relative">
                                    {
                                        this.state.client.agencyAutomatedEmails &&
                                        <div className='col-span-4 relative'>
                                            {
                                                this.state.loading &&
                                                <div className="absolute left-0 right-0 z-50 top-0 bg-white bottom-0 opacity-75"></div>
                                            }
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Automate report emails</div>
                                            <div className=' flex flex-1 transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.client.emailSettings.automatedEmails ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.client.emailSettings.automatedEmails}
                                                        onSwitch={async () => {
                                                            this.state.client.emailSettings.automatedEmails = !this.state.client.emailSettings.automatedEmails;
                                                            await this.promisedSetState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.client.agencyAutomatedEmails &&
                                        <div className={"col-span-4 relative"}>
                                            {
                                                (!this.state.client.emailSettings.automatedEmails || this.state.loading) &&
                                                <div className="absolute left-0 right-0 z-50 top-0 bg-white bottom-0 opacity-75"></div>
                                            }
                                            <DropdownTailwind
                                                label='Send report email (frequency)'
                                                selected={this.state.client.emailSettings.automatedEmailsFrequency ? this.state.client.emailSettings.automatedEmailsFrequency : { id: 0, name: 'No frequency', value: "no_frequency" }}
                                                options={[
                                                    { id: 0, name: 'No frequency', value: "no_frequency" },
                                                    { id: 1, name: 'Once a month (the 1st)', value: "once_month_start" },
                                                ]}
                                                onChange={async (value) => {
                                                    this.state.client.emailSettings.automatedEmailsFrequency = value;
                                                    await this.promisedSetState({
                                                        client: this.state.client
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.client.agencyAutomatedEmails &&
                                        <div className='col-span-4 relative'>
                                            {
                                                (!this.state.client.emailSettings.automatedEmails || this.state.loading) &&
                                                <div className="absolute left-0 right-0 z-50 top-0 bg-white  bottom-0 opacity-75"></div>
                                            }
                                            <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Send 14 days after new order is created</div>
                                            <div className=' flex flex-1  transform transition-all duration-500 ease-in-out  items-center justify-center mt-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                <div className="flex flex-1 ml-5 text-sm font-medium">
                                                    {this.state.client.emailSettings.sendAfter14Days ? "Active" : "Disabled"}
                                                </div>
                                                <div className="relative overflow-hidden mr-5">
                                                    <SwitchTailwind
                                                        value={this.state.client.emailSettings.sendAfter14Days}
                                                        onSwitch={async () => {
                                                            this.state.client.emailSettings.sendAfter14Days = !this.state.client.emailSettings.sendAfter14Days;
                                                            await this.promisedSetState({
                                                                client: this.state.client
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.templates.filter((item) => {
                                            return item.createdReport;
                                        }).length > 0 &&
                                        <div className="col-span-12">
                                            <div className={"w-full border-1.5 rounded-md overflow-hidden relative"}>
                                                <div className={"w-full"}>
                                                    {
                                                        this.state.templates.length > 0 &&
                                                        this.state.templates.filter((item) => {
                                                            return item.createdReport;
                                                        }).map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className={(index > 0 ? "border-t" : "") + " w-full relative"}>
                                                                        {
                                                                            item.loading &&
                                                                            <div className="z-70 absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-white bg-opacity-25">
                                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        <div className="w-full px-2 pr-4 py-2 items-center justify-center flex flex-row">
                                                                            <div className={(item.masterTemplate ? "bg-purple-100 text-purple-500 " : "bg-gray-100 text-gray-500 ") + " rounded-md flex items-center justify-center h-10 w-10 mr-2"}>
                                                                                {
                                                                                    item.masterTemplate &&
                                                                                    <StarIcon className="w-5"></StarIcon>
                                                                                }
                                                                                {
                                                                                    !item.masterTemplate &&
                                                                                    <ChartPieIcon className="w-5"></ChartPieIcon>
                                                                                }
                                                                            </div>
                                                                            <div className="truncate pr-4">
                                                                                <div className="text-sm font-medium truncate">
                                                                                    {item.report_name}
                                                                                </div>
                                                                                <div className="text-xs">
                                                                                    {Array.isArray(item.report_tags) && item.report_tags.length > 0 ? item.report_tags.map((item) => { return "#" + item.name }).join(" ") : "-"}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-1"></div>
                                                                            <div>
                                                                                <span className="text-sm text-green-700">Created</span>
                                                                                <span className="mx-1">/</span>
                                                                                <span onClick={() => {
                                                                                    if (!item.loading) {
                                                                                        this.functions.removeReport(item);
                                                                                    }
                                                                                }} className="text-sm cursor-pointer hover:text-red-700 text-red-500">Remove</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !(this.state.template && this.state.template.id) &&
                                        <div className={"col-span-12 relative grid grid-cols-12 gap-4"}>
                                            <div className="col-span-6">
                                                <InputTailwind
                                                    label={"Search by name: showing " + this.state.templates.filter((item) => {
                                                        if (this.state.template && this.state.template.id) {
                                                            return item.id == this.state.template.id;
                                                        } else {
                                                            return !item.createdReport;
                                                        }
                                                    }).filter((item) => {
                                                        if (this.state.template_type && this.state.template_type.value) {
                                                            if (item.masterTemplate) {
                                                                return this.state.template_type.value == "scale" || this.state.template_type.value == "all";
                                                            } else {
                                                                return this.state.template_type.value == "regular" || this.state.template_type.value == "all";
                                                            }
                                                        } else {
                                                            return true;
                                                        }
                                                    }).filter((item) => {
                                                        if (this.state.search_templates == "") {
                                                            return true;
                                                        } else {
                                                            try {
                                                                return item.name.toLowerCase().indexOf(this.state.search_templates.toLowerCase()) !== -1;
                                                            } catch (error) {
                                                                return true;
                                                            }
                                                        }
                                                    }).length + " of " + this.state.templates_total + " templates"}
                                                    placeholder={"Type template name"}
                                                    value={this.state.search_templates}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            search_templates: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label='Filter'
                                                    selected={this.state.template_type ? this.state.template_type : { id: 0, name: 'All templates', value: "all" }}
                                                    options={[
                                                        { id: 0, name: 'All templates', value: "all" },
                                                        { id: 1, name: 'Scale templates', value: "scale" },
                                                        { id: 2, name: 'Regular templates', value: "regular" }
                                                    ]}
                                                    onChange={async (value) => {
                                                        await this.promisedSetState({
                                                            template_type: value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="col-span-12">
                                        <div className={(this.state.template && this.state.template.id ? "" : "h-72") + " w-full border-1.5 rounded-md overflow-hidden relative"}>
                                            {
                                                this.state.loading_templates &&
                                                <div className="absolute z-70 bg-white bg-opacity-25 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            {
                                                !this.state.loading_templates &&
                                                <div className={(this.state.template && this.state.template.id ? "" : "absolute h-full flex flex-col") + " w-full overflow-y-scroll"}>
                                                    {
                                                        this.state.templates.length > 0 &&
                                                        this.state.templates.filter((item) => {
                                                            if (this.state.template && this.state.template.id) {
                                                                return item.id == this.state.template.id;
                                                            } else {
                                                                return !item.createdReport;
                                                            }
                                                        }).filter((item) => {
                                                            if (this.state.template_type && this.state.template_type.value) {
                                                                if (item.masterTemplate) {
                                                                    return this.state.template_type.value == "scale" || this.state.template_type.value == "all";
                                                                } else {
                                                                    return this.state.template_type.value == "regular" || this.state.template_type.value == "all";
                                                                }
                                                            } else {
                                                                return true;
                                                            }
                                                        }).filter((item) => {
                                                            if (this.state.search_templates == "") {
                                                                return true;
                                                            } else {
                                                                try {
                                                                    return item.name.toLowerCase().indexOf(this.state.search_templates.toLowerCase()) !== -1;
                                                                } catch (error) {
                                                                    return true;
                                                                }
                                                            }
                                                        }).map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <div className={(index > 0 ? "border-t" : "") + " w-full relative"}>
                                                                        {
                                                                            item.loading &&
                                                                            <div className="z-70 absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-white bg-opacity-25">
                                                                                <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        <div className="w-full px-2 pr-4 py-2 items-center justify-center flex flex-row">
                                                                            <div className={(item.masterTemplate ? "bg-purple-100 text-purple-500 " : "bg-gray-100 text-gray-500 ") + " rounded-md flex items-center justify-center h-10 w-10 mr-2"}>
                                                                                {
                                                                                    item.masterTemplate &&
                                                                                    <StarIcon className="w-5"></StarIcon>
                                                                                }
                                                                                {
                                                                                    !item.masterTemplate &&
                                                                                    <ChartPieIcon className="w-5"></ChartPieIcon>
                                                                                }
                                                                            </div>
                                                                            <div className="truncate pr-4">
                                                                                <div className="text-sm font-medium truncate">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xs">
                                                                                    {Array.isArray(item.tags) && item.tags.length > 0 ? item.tags.map((item) => { return "#" + item.name }).join(" ") : "-"}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-1"></div>
                                                                            {
                                                                                !item.masterTemplate && this.renders.templateChannels().length < 1 &&
                                                                                <div className="text-red-500 text-sm">
                                                                                    No platforms connections
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.masterTemplate || (!item.masterTemplate && this.renders.templateChannels().length > 0)) &&
                                                                                <div onClick={() => {
                                                                                    if (this.state.template && this.state.template.id) {
                                                                                        this.setState({
                                                                                            template: {}
                                                                                        })
                                                                                    } else {
                                                                                        this.setState({
                                                                                            template_type: null,
                                                                                            search_templates: "",
                                                                                            template: item
                                                                                        })
                                                                                    }
                                                                                }} className={(!(this.state.template && this.state.template.id == item.id) ? "text-purple-500" : "text-red-500") + " text-sm underline  cursor-pointer"}>
                                                                                    {
                                                                                        !(this.state.template && this.state.template.id == item.id) &&
                                                                                        <span>Select</span>
                                                                                    }
                                                                                    {
                                                                                        this.state.template && this.state.template.id == item.id &&
                                                                                        <span>Cancel</span>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            {
                                                this.state.template && this.state.template.id && !this.state.template.masterTemplate &&
                                                <div className="w-full px-2">
                                                    {
                                                        this.renders.templateChannels().map((channel, index) => {
                                                            let arrays = [{ component: AccountGoogle, channel: "google" },
                                                            { component: AccountFacebook, channel: "facebook" },
                                                            { component: AccountLinkedin, channel: "linkedin" },
                                                            { component: AccountTiktok, channel: "tiktok" },
                                                            { component: AccountTwitter, channel: "twitter" },
                                                            { component: AccountAdform, channel: "adform" },
                                                            { component: AccountGoogleAnalytics, channel: "google_analytics" },
                                                            { component: AccountGoogleAnalytics4, channel: "google_analytics_4" },
                                                            { component: AccountSnapchat, channel: "snapchat" },
                                                            { component: AccountBing, channel: "bing" },
                                                            { component: AccountDv360, channel: "dv360" },
                                                            { component: AccountCm360, channel: "cm360" },
                                                            { component: AccountGoogleSearchConsole, channel: "google_search_console" }];
                                                            return (
                                                                <div className={"mb-2 inline-flex items-center justify-center"}>
                                                                    <div onClick={() => {
                                                                        if (!this.state.template.channels) {
                                                                            this.state.template.channels = {};
                                                                        }
                                                                        if (this.state.template.channels[channel]) {
                                                                            delete this.state.template.channels[channel];
                                                                        } else {
                                                                            this.state.template.channels[channel] = true;
                                                                        }
                                                                        this.setState({
                                                                            template: this.state.template
                                                                        })
                                                                    }} className={(this.state.template.channels && this.state.template.channels[channel] ? "bg-purple-500 text-white border border-purple-500" : "bg-gray-100 border") + " cursor-pointer flex items-center justify-center rounded-md h-10 w-10 mr-2"}>
                                                                        <CheckIcon className={(this.state.template.channels && this.state.template.channels[channel] ? "" : "opacity-0") + " w-7"} />
                                                                    </div>
                                                                    <div className={"rounded-md bg-" + channel + "-500 h-10 w-10 mr-2 flex justify-center items-center"}>
                                                                        {
                                                                            channel == "facebook" &&
                                                                            <div className="w-5 h-5">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            channel == "google" &&
                                                                            <div className="w-4 h-4">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            channel == "linkedin" &&
                                                                            <div className="w-4 h-4">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            channel == "tiktok" &&
                                                                            <div className="w-4 h-4">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            channel == "twitter" &&
                                                                            <div className="w-4 h-4">
                                                                                <div
                                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div>
                                                                        <div className="text-sm font-medium capitalize">
                                                                            {channel}
                                                                        </div>
                                                                        <div className="text-xs">
                                                                            {this.state.template.channels && this.state.template.channels[channel] ? "Selected" : "Not selected"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.template && this.state.template.id && !this.state.template.createdReport &&
                                        <div className={"col-span-12"}>
                                            <div className="grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <InputTailwind
                                                        label={"Report name"}
                                                        placeholder={""}
                                                        value={this.state.template.report_name}
                                                        onChange={(value) => {
                                                            this.state.template.report_name = value;
                                                            this.setState({
                                                                template: this.state.template
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <TagManager
                                                        report={{}}
                                                        label={"Tags"}
                                                        tags={Array.isArray(this.state.template.report_tags) ? this.state.template.report_tags : []}
                                                        tagType={"component"}
                                                        onUpdateTags={(tags) => {
                                                            this.state.template.report_tags = tags;
                                                            this.setState({
                                                                template: this.state.template
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.error &&
                                        <div className={"col-span-12"}>
                                            <div className="bg-red-100 p-4 rounded-md">
                                                <div className="text-sm font-medium text-red-500">
                                                    {this.state.error}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </SlideDown>

                            {
                                this.state.open.report &&
                                < div className="w-full border-t py-4 mt-4 flex">
                                    <div>
                                        <button
                                            onClick={() => {
                                                this.functions.stepBack("connections");
                                            }}
                                            className={"cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex relative justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                        >
                                            Back
                                        </button>
                                    </div>
                                    <div className="flex flex-1"></div>
                                    {
                                        this.state.template && this.state.template.id && !this.state.template.createdReport &&
                                        <div className="col-span-12 items-center justify-center flex">
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_create_report) {
                                                            this.functions.createReport();
                                                        }
                                                    }}
                                                    className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    Create report
                                                    {
                                                        this.state.loading_create_report &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !(this.state.template && this.state.template.id && !this.state.template.createdReport) &&
                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.updateClient();
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Next
                                                {
                                                    this.state.loading_update_client &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                        </div>

                        {/* CREATE ORDER */}
                        {
                            <div className={(!this.state.open.client && !this.state.open.connections && !this.state.open.report && !this.state.open.order ? "-mt-16" : (this.state.open.order ? "" : "hidden")) + " col-span-12"}>
                                <div className="flex flex-row">
                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <CubeIcon className="w-5 h-5 " />
                                    </div>
                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                        <div className="font-semibold text-sm">Create order (optional)</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">{"Fill in data below"}
                                        </div>
                                    </div>
                                    <div className='flex'></div>
                                </div>

                                <SlideDown
                                    closed={!this.state.open.order}
                                >
                                    <div className="mt-4 grid-cols-12 grid gap-4 relative">
                                        <div className={"col-span-12"}>
                                            <InputTailwind
                                                label={"Name"}
                                                value={this.state.order.name}
                                                error={this.state.error == "Name is missing"}
                                                onChange={(value) => {
                                                    this.state.order.name = value;
                                                    this.promisedSetState({
                                                        order: this.state.order
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                            <div className="col-span-3">
                                                <InputDatepickerTailwind
                                                    label={"Start date"}
                                                    value={moment(this.state.order.startDate).format("YYYY-MM-DD")}
                                                    onChange={async (value) => {
                                                        let time = moment(this.state.order.startDate).format("HH:mm");
                                                        let new_date = value + " " + time;
                                                        this.state.order.startDate = moment(new_date);
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"Start time"}
                                                    value={moment(this.state.order.startDate).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.state.order.startDate).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.order.startDate = moment(new_date);
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={"col-span-12 md:col-span-6 grid grid-cols-6 col-gap-2"}>
                                            <div className={(this.state.order.endDate ? "col-span-3" : "col-span-6")}>
                                                <InputDatepickerTailwind
                                                    label={"End date"}
                                                    disabled={!this.state.order.endDate}
                                                    labelRight={this.state.order.endDate ? "Disable" : "Enable"}
                                                    placeholder={!this.state.order.endDate ? "No end date" : ""}
                                                    value={moment(this.state.order.endDate).format("YYYY-MM-DD")}
                                                    onChange={async (value) => {
                                                        let time = moment(this.state.order.endDate).format("HH:mm");
                                                        let new_date = value + " " + time;
                                                        this.state.order.endDate = moment(new_date);
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        })
                                                    }}
                                                    onLabelRightClick={async (value) => {
                                                        if (this.state.order.endDate) {
                                                            this.state.order.endDate = null;
                                                        } else {
                                                            let new_date = moment(this.state.order.startDate).add(1, 'days');
                                                            this.state.order.endDate = new_date;
                                                        }
                                                        await this.promisedSetState({
                                                            order: this.state.order
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {
                                                this.state.order.endDate &&
                                                <div className="col-span-3">
                                                    <InputTimepickerTailwind
                                                        label={"End time"}
                                                        placeholder={!this.state.order.endDate ? "No end time" : ""}
                                                        disabled={!this.state.order.endDate}
                                                        value={moment(this.state.order.endDate).format("HH:mm")}
                                                        onChange={async (value) => {
                                                            let date = moment(this.state.order.endDate).format("YYYY-MM-DD");
                                                            let new_date = date + " " + value;
                                                            this.state.order.endDate = moment(new_date);
                                                            await this.promisedSetState({
                                                                order: this.state.order
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {
                                            !(this.state.order && this.state.order.id) &&
                                            <div className={"col-span-12"}>
                                                <div className="mb-1 text-xs font-medium text-gray-700">
                                                    Upload documents
                                                </div>
                                                <div key={this.state.theInputKeyOne || ''} className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input">
                                                    {
                                                        !this.state.success &&
                                                        <input
                                                            onChange={(e) => this.functions.onSelectFile(e)}
                                                            type="file"
                                                            multiple
                                                            className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-50"
                                                        />
                                                    }
                                                    <div className="space-y-1 text-center">
                                                        <svg
                                                            className="mx-auto h-12 w-12 text-gray-400"
                                                            stroke="currentColor"
                                                            fill="none"
                                                            viewBox="0 0 48 48"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                strokeWidth={2}
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        <div className="flex text-sm text-gray-600">
                                                            <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a document</span>
                                                            <p className="pl-1">or drag and drop</p>
                                                        </div>
                                                        <p className="text-xs text-gray-500">PDF, TXT, ODT, DOC, PPT up to 100MB</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.error &&
                                            <div className={"col-span-12"}>
                                                <div className="bg-red-100 p-4 rounded-md">
                                                    <div className="text-sm font-medium text-red-500">
                                                        {this.state.error}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </SlideDown>

                                {
                                    this.state.open.order &&
                                    < div className="w-full border-t py-4 mt-4 flex">
                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.stepBack("report");
                                                }}
                                                className={"cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex relative justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div className="flex flex-1"></div>
                                        {
                                            !(this.state.order && this.state.order.id) &&
                                            <div>
                                                <button
                                                    onClick={() => {
                                                        if (!this.state.loading_create_order) {
                                                            this.functions.createOrder();
                                                        }
                                                    }}
                                                    className={"cursor-pointer mr-4 bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    Create order
                                                    {
                                                        this.state.loading_create_order &&
                                                        <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                            <div style={{ borderTopColor: "transparent" }}
                                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                        </div>
                                                    }
                                                </button>
                                            </div>
                                        }
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (!this.state.loading_create_order) {
                                                        if (this.state.order && this.state.order.id) {
                                                            this.functions.updateOrder();
                                                        } else {
                                                            this.functions.stepForward("agents");
                                                        }
                                                    }
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Next
                                                {
                                                    this.state.loading_update_client &&
                                                    <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        }

                        {/* IMPORT CAMPAIGNS */}
                        {
                            this.state.order.id &&
                            <div className={(!this.state.open.client && !this.state.open.connections && !this.state.open.report && !this.state.open.order && !this.state.open.import_campaigns ? "-mt-16" : (this.state.open.import_campaigns ? "" : "hidden")) + " col-span-12"}>
                                <div className="flex flex-row">
                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <FolderIcon className="w-5 h-5 " />
                                    </div>
                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                        <div className="font-semibold text-sm">Import campaigns (optional)</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">{"Fill in data below"}
                                        </div>
                                    </div>
                                    <div className='flex'>

                                    </div>
                                </div>

                                <SlideDown
                                    closed={!this.state.open.import_campaigns}
                                >
                                    <div className="mt-4 grid-cols-12 grid gap-4 relative">
                                        <div className="col-span-12">
                                            <InputTailwind
                                                label={"Search by name: showing " + this.state.campaigns.filter((item) => {
                                                    if (this.state.search_campaigns == "") {
                                                        return true;
                                                    } else {
                                                        return item.name.indexOf(this.state.search_campaigns) !== -1;
                                                    }
                                                }).length + " of " + this.state.campaigns.length + " campaigns"}
                                                placeholder={"Type campaign name"}
                                                value={this.state.search_campaigns}
                                                onChange={(value) => {
                                                    this.promisedSetState({
                                                        search_campaigns: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-12">
                                            <div className="w-full h-72 border-1.5 rounded-md overflow-hidden relative">
                                                {
                                                    this.state.loading_campaigns &&
                                                    <div className="absolute z-70 bg-white bg-opacity-25 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.loading_campaigns &&
                                                    <div className="absolute h-full w-full overflow-y-scroll">
                                                        {
                                                            this.state.campaigns.length > 0 &&
                                                            this.state.campaigns.filter((item) => {
                                                                if (this.state.search_campaigns == "") {
                                                                    return true;
                                                                } else {
                                                                    return item.name.indexOf(this.state.search_campaigns) !== -1;
                                                                }
                                                            }).map((item, index) => {
                                                                return (
                                                                    <div className={(index > 0 ? "border-t" : "") + " w-full relative"}>
                                                                        {
                                                                            item.loading &&
                                                                            <div className="z-70 absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-white bg-opacity-25">
                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                    class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        <div className="w-full px-2 py-2 items-center justify-center flex flex-row">
                                                                            <div className={"rounded-md bg-" + item.channel + "-500 h-10 w-10 mr-2"}></div>
                                                                            <div className="">
                                                                                <div className="text-sm font-medium">
                                                                                    {item.name}
                                                                                </div>
                                                                                <div className="text-xs">
                                                                                    <span className="capitalize">{item.channel}</span> - {item.status}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-1"></div>
                                                                            {
                                                                                !item.imported &&
                                                                                <div onClick={() => {
                                                                                    if (!item.loading) {
                                                                                        this.functions.importCampaign(item);
                                                                                    }
                                                                                }} className="text-sm underline text-purple-500 cursor-pointer">Import campaign</div>
                                                                            }
                                                                            {
                                                                                item.imported &&
                                                                                <div onClick={() => {
                                                                                    if (!item.loading) {

                                                                                    }
                                                                                }} className="text-sm text-green-700">Imported</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </SlideDown>

                                {
                                    this.state.open.import_campaigns &&
                                    < div className="w-full border-t py-4 mt-4 flex">
                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.stepBack("order");
                                                }}
                                                className={"cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex relative justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div className="flex flex-1"></div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    this.functions.stepForward("agents");
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        }

                        {/* ASSIGN AGENTS */}
                        {
                            <div className={(this.state.open.agents ? "" : "hidden") + " col-span-12"}>
                                <div className="flex flex-row">
                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                        <UserAddIcon className="w-5 h-5 " />
                                    </div>
                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                        <div className="font-semibold text-sm">Assign agents (optional)</div>
                                        <div className="font-medium text-xs text-gray-500 flex flex-row">
                                            {"Fill in data below"}
                                        </div>
                                    </div>
                                    <div className='flex'>

                                    </div>

                                </div>

                                <SlideDown
                                    closed={!this.state.open.agents}
                                >

                                    <div className="mt-4 grid-cols-12 grid gap-4 relative">
                                        <div className="col-span-12">
                                            <InputTailwind
                                                label={"Search by name/email: showing " + this.state.agents.filter((item) => {
                                                    if (this.state.search_agents == "") {
                                                        return true;
                                                    } else {
                                                        return item.email.indexOf(this.state.search_agents) !== -1 || item.name.indexOf(this.state.search_agents) !== -1;
                                                    }
                                                }).length + " of " + this.state.agents.length + " agents"}
                                                placeholder={"Type agents name/email"}
                                                value={this.state.search_agents}
                                                onChange={(value) => {
                                                    this.promisedSetState({
                                                        search_agents: value
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-12">
                                            <div className="w-full h-72 border-1.5 rounded-md overflow-hidden relative">
                                                {
                                                    this.state.loading_agents &&
                                                    <div className="absolute z-70 bg-white bg-opacity-25 left-0 right-0 top-0 bottom-0 flex items-center justify-center">
                                                        <div style={{ borderTopColor: "transparent" }}
                                                            class="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                {
                                                    !this.state.loading_agents &&
                                                    <div className="absolute h-full w-full overflow-y-scroll">
                                                        {
                                                            this.state.agents.length > 0 &&
                                                            this.state.agents.filter((item) => {
                                                                if (this.state.search_agents == "") {
                                                                    return true;
                                                                } else {
                                                                    return item.email.indexOf(this.state.search_agents) !== -1 || item.name.indexOf(this.state.search_agents) !== -1;
                                                                }
                                                            }).map((item, index) => {
                                                                return (
                                                                    <div className={(index > 0 ? "border-t" : "") + " w-full relative"}>
                                                                        {
                                                                            item.loading &&
                                                                            <div className="z-70 absolute flex items-center justify-center left-0 right-0 top-0 bottom-0 bg-white bg-opacity-25">
                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                    class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }
                                                                        <div className="w-full px-2 py-2 items-center justify-center flex flex-row">
                                                                            <div className={"rounded-md bg-gray-100 h-10 w-10 mr-2"}>

                                                                            </div>
                                                                            <div className="">
                                                                                <div className="text-sm font-medium">
                                                                                    {item.email}
                                                                                </div>
                                                                                <div className="text-xs">
                                                                                    {item.name}
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex flex-1"></div>
                                                                            {
                                                                                !item.assigned &&
                                                                                <div onClick={() => {
                                                                                    if (!item.loading) {
                                                                                        this.functions.assignAgents(item, "assign");
                                                                                    }
                                                                                }} className="text-sm underline text-purple-500 cursor-pointer">Assign</div>
                                                                            }
                                                                            {
                                                                                item.assigned &&
                                                                                <div onClick={() => {
                                                                                    if (!item.loading) {
                                                                                        this.functions.assignAgents(item, "remove");
                                                                                    }
                                                                                }} className="text-sm text-green-700">Assigned</div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </SlideDown>

                                {
                                    this.state.open.agents &&
                                    < div className="w-full border-t py-4 mt-4 flex">
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (this.state.order.id) {
                                                        this.functions.stepBack("import_campaigns");
                                                    } else {
                                                        this.functions.stepBack("order");
                                                    }
                                                }}
                                                className={"cursor-pointer bg-white border-1.5 text-gray-800 hover:bg-gray-200 inline-flex relative justify-center rounded-md w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Back
                                            </button>
                                        </div>
                                        <div className="flex flex-1"></div>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    if (this.props.onDone) {
                                                        this.props.onDone();
                                                    }
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 text-white shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }
}

export default CreateClient;