import React, { Component } from 'react';
import { ExclamationCircleIcon, LockClosedIcon, XIcon } from '@heroicons/react/outline'
import { BadgeCheckIcon } from '@heroicons/react/solid';

class InputTailwind extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            label: "",
            error: false,
            errorMessage: "",
            clearable: false,
            disabled: false,
            loading: false,
            readOnly: false,
            locked: false,
            progressBar: false,
            progressBarLowestValue: 0,
            progressBarTopValue: 0,
            progressBarPercentage: 0,
            progressBarValue: 0,
            phone_exists: false,
            keywordInsertion: false,
        }
    };

    componentDidMount() {
        this.setState({
            value: this.props.value,
            error: this.props.error,
            loading: this.props.loading,
            readOnly: this.props.readOnly,
            locked: this.props.locked,
            disabled: this.props.disabled,
            rightLabelText: this.props.rightLabelText,
            errorMessage: this.props.errorMessage,
            channel: this.props.channel,
            maxLength: this.props.maxLength ? this.props.maxLength : 1000000,
            progressBar: this.props.progressBar,
            progressBarLowestValue: this.props.progressBarLowestValue,
            progressBarTopValue: this.props.progressBarTopValue,
            progressBarPercentage: this.props.progressBarPercentage,
            progressBarValue: this.props.progressBarValue,
            phone_exists: this.props.phone_exists,
            keywordInsertion: this.props.keywordInsertion,
            clearable: this.props.clearable,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value,
            error: nextProps.error,
            loading: nextProps.loading,
            readOnly: nextProps.readOnly,
            locked: nextProps.locked,
            disabled: nextProps.disabled,
            rightLabelText: nextProps.rightLabelText,
            errorMessage: nextProps.errorMessage,
            channel: nextProps.channel,
            maxLength: nextProps.maxLength ? nextProps.maxLength : 1000000,
            progressBar: nextProps.progressBar,
            progressBarLowestValue: nextProps.progressBarLowestValue,
            progressBarTopValue: nextProps.progressBarTopValue,
            progressBarPercentage: nextProps.progressBarPercentage,
            progressBarValue: nextProps.progressBarValue,
            phone_exists: nextProps.phone_exists,
            keywordInsertion: nextProps.keywordInsertion,
            clearable: nextProps.clearable,
        })
    }

    render() {
        return (
            <div className={(this.props.full ? "w-full" : "") + " relative"}>
                <label className="text-xs flex flex-row font-medium text-gray-700 ">
                    {
                        this.state.channel &&
                        <div className="mr-1">
                            <div className={"bg-" + this.state.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center"}>
                                {
                                    this.state.channel === 'google' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/google_icon.png')} />
                                }
                                {
                                    this.state.channel === 'facebook' &&
                                    <img className="w-1"
                                        alt=""
                                        src={require('../assets/images/facebook_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'linkedin' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/linkedin_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'twitter' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/twitter_icon.svg')} />
                                }
                                {
                                    this.state.channel === 'tiktok' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/tiktok_icon.png')} />
                                }
                                {
                                    this.state.channel === 'snapchat' &&
                                    <img className="w-2"
                                        alt=""
                                        src={require('../assets/images/snapchat.svg')} />
                                }
                                {
                                    this.state.channel === 'bing' &&
                                    <img className="w-1.5"
                                        alt=""
                                        src={require('../assets/images/bing-logo.png')} />
                                }
                            </div>
                        </div>
                    }
                    <div className={(this.state.progressBar ? "" : "flex-1 ") + (this.state.error ? "text-red-600" : "") + " flex flex-row"}>
                        {this.props.label}
                        {
                            this.state.phone_exists &&
                            <div className='ml-2'>- Error: number already in use</div>
                        }
                        {
                            this.props.insertion &&
                            <div onClick={() => {
                                if (this.props.onKeywordInsertionClick) {
                                    this.props.onKeywordInsertionClick();
                                }
                            }} className={(this.props.keywordInsertion ? this.props.keywordInsertion : "hover:text-purple-500 text-gray-700") + " ml-2 flex items-center text-xxs cursor-pointer font-medium "}>
                                Keyword Insertion
                                {
                                    this.state.keywordInsertion &&
                                    <div className="ml-1 text-xs text-green-500">
                                        <BadgeCheckIcon className="h-4 w-4" aria-hidden="true" />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        this.state.progressBar &&
                        <div className="flex flex-1 ml-4">
                            <div className="mr-2">{this.state.progressBarLowestValue}</div>
                            <div style={{ marginTop: "6.5px" }} className="h-1 w-full bg-gray-100 flex flex-row rounded-md overflow-hidden">
                                <div style={{ width: this.state.progressBarPercentage + "%" }} className="bg-purple-500 h-full transform ease-in-out duration-200"></div>
                                <div className="flex flex-1 h-full bg-gray-100"></div>
                            </div>
                            <div className="ml-2">{this.state.progressBarValue + "/" + this.state.progressBarTopValue}</div>
                            <div className="ml-1">({this.state.progressBarPercentage.toFixed(2)}%)</div>
                        </div>
                    }

                    {
                        this.props.rightLabel &&
                        <div onClick={() => {
                            if (this.props.onRightLabelClick) {
                                this.props.onRightLabelClick();
                            }
                        }} className={(this.props.rightLabelColor ? this.props.rightLabelColor : "hover:text-purple-500 text-gray-700") + " flex justify-end text-xs cursor-pointer font-medium "}>
                            {this.state.rightLabelText}
                        </div>
                    }
                </label>
                <div className={(this.props.noMarginTop ? "" : "mt-1") + " relative flex flex-row rounded-md"}>
                    {
                        this.props.leftSection &&
                        <span className="inline-flex items-center whitespace-no-wrap px-3 rounded-l-md border-1.5 border-r-0 border-gray-300 sm:text-sm">
                            {this.props.leftSectionLabel}
                        </span>
                    }
                    <input
                        onBlur={() => {
                            if (this.props.onBlur) {
                                this.props.onBlur();
                            }
                        }}
                        onFocus={() => {
                            if (this.props.onFocus) {
                                this.props.onFocus();
                            }
                        }}
                        readOnly={this.state.readOnly}
                        type={this.props.type}
                        maxLength={this.state.maxLength}
                        disabled={this.state.disabled}
                        className={(this.state.error ? " border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 pr-10 " : " focus:ring-estatery-600 focus:border-purple-500 border-gray-300 ") + (this.props.leftSection ? " rounded-r-md " : (this.props.rightSection ? " rounded-l-md" : " rounded-md ")) + (this.props.noHeight ? "" : (this.props.scale ? " h-10 " : " h-12 ")) + " block w-full bg-custom-input border-1.5 px-2 py-2 focus:outline-none sm:text-sm"}
                        placeholder={this.props.placeholder}
                        value={this.state.value}
                        onChange={(event) => {
                            if (this.props.onChange && !this.state.disabled) {
                                this.props.onChange(event.target.value);
                            }
                        }}
                        onKeyDown={(event) => {
                            if (this.props.onKeyDown) {
                                this.props.onKeyDown(event.keyCode);
                            }
                        }}
                    />
                    {
                        this.state.clearable && !this.state.disabled &&
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                            <XIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                                onClick={() => {
                                    this.state.value = "";
                                    this.setState({
                                        value: ""
                                    }, () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.value);
                                        }
                                    })
                                }} />
                        </div>
                    }
                    {
                        this.state.locked &&
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <LockClosedIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className={"absolute inset-y-0 right-0 flex items-center pointer-events-none" + (this.state.locked ? " pr-10" : " pr-3") + (this.props.rightSection ? " mr-7" : "")}>
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                    }
                    {
                        this.state.loading &&
                        <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                            <div style={{ borderTopColor: "transparent" }} class="w-5 h-5 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    }
                    {
                        this.props.rightSection &&
                        <span className={"inline-flex items-center whitespace-no-wrap px-3 rounded-r-md border-1.5 border-l-0 border-gray-300 sm:text-sm " + (this.props.rightSectionClass ? this.props.rightSectionClass : "")}>
                            {this.props.rightSectionLabel}
                        </span>
                    }
                </div>
                {
                    this.state.error &&
                    <p className="mt-2 text-xs absolute text-right right-0 text-red-600 font-medium" id="email-error">
                        {this.state.errorMessage}
                    </p>
                }
            </div>
        )
    }
}

export default InputTailwind;
