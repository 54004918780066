import { KeyIcon, PlusIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import SuccessModal from './successModal';
import TopNavigationAgent from './topNavigationAgent';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountGoogleAnalytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            loading: false,
            loading_accounts: false,
            loading_properties: false,
            loading_profiles: false,
            account: {},
            accounts: [],
            property: {},
            properties: [],
            profile: {},
            profiles: [],
            healthcheck: {},
            owner: false,
            loading_account: false,
            loading_advertisers: false,
            advertiser: {},
            advertisers: [],
            me: {},
            throttling: {},
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            agents: [],
            agent: { id: 0, name: "Select agent" },
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me,
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.google_analytics) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me,
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "google_analytics",
                    token: token
                });

                //TEMP SOLUTION
                window.location.reload();
                //TEMP SOLUTION

                await self.promisedSetState({
                    user: { ...this.state.user, ...{ google_analytics: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "google_analytics"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ google_analytics: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listAccounts: async () => {
            await this.promisedSetState({
                loading_accounts: true
            });
            try {
                let response = await this.calls.listAccounts();
                await this.promisedSetState({
                    accounts: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_accounts: false
            });
        },
        listProperties: async () => {
            await this.promisedSetState({
                loading_properties: true
            });
            try {
                let response = await this.calls.listProperties();
                await this.promisedSetState({
                    properties: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_properties: false
            });
        },
        listProfiles: async () => {
            await this.promisedSetState({
                loading_profiles: true
            });
            try {
                let response = await this.calls.listProfiles();
                await this.promisedSetState({
                    profiles: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_profiles: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.account.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    account: this.state.account,
                    property: this.state.property,
                    profile: this.state.profile
                });
                this.state.client.google_analytics = {
                    defaultAgent: this.state.agent,
                    accountId: this.state.account.id,
                    accountName: this.state.account.name,
                    currency: this.state.currency.id,
                    propertyId: this.state.property.id,
                    propertyName: this.state.property.name,
                    ...(this.state.profile.id && { profileId: this.state.profile.id }),
                    ...(this.state.profile.id && { profileName: this.state.profile.name })
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    property: {},
                    account: {},
                    currency: {},
                    profile: {},
                    client: this.state.client
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.client.google_analytics);
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.google_analytics;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    property: {},
                    account: {},
                    profile: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google_analytics/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google_analytics/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listAccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google_analytics/listAccounts?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        listProperties: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google_analytics/listProperties?client=" + this.state.client.id + "&account=" + this.state.account.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        listProfiles: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google_analytics/listProfiles?client=" + this.state.client.id + "&account=" + this.state.account.id + "&property=" + this.state.property.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=google_analytics";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + ""}>


                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Google Analytics"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-googleanalytics-500 rounded-md flex justify-center items-center">
                        <div className="w-4 h-4">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Google Analytics {(this.state.client && this.state.client.google_analytics) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.google_analytics) &&
                            <div className="text-xs text-gray-500">
                                Connect Google Analytics to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.google_analytics) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.google_analytics) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                    if (this.state.is_manager && !this.state.client.google_analytics) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            (
                                !this.state.user.id &&
                                this.state.client.google_analytics &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.google_analytics && this.state.client.google_analytics.defaultAgent && this.state.client.google_analytics.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.google_analytics ? true : false}
                                onSwitch={async () => {
                                    if (!this.state.client.google_analytics) {
                                        this.state.client.google_analytics = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>

                    {
                        !this.state.user.id &&
                        <div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.google_analytics}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.google_analytics && this.state.me.google_analytics.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.google_analytics && this.state.client.google_analytics.defaultAgent ? this.state.client.google_analytics.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value,
                                        account: {},
                                        property: {},
                                        profile: {}
                                    });
                                    this.functions.listAccounts();
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (this.state.throttling.current) {
                                            //SKIP
                                        } else {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.client.google_analytics || this.state.agent.id !== 0) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={this.state.client.google_analytics ? "Account" : "Accounts"}
                                    loader={this.state.loading_accounts}
                                    searchInput={true}
                                    locked={this.state.client.google_analytics}
                                    selected={this.state.client.google_analytics && this.state.client.google_analytics.accountId ? { name: this.state.client.google_analytics.accountId } : (this.state.account.id ? this.state.account : { name: "Select account" })}
                                    options={this.state.accounts}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            account: value,
                                            property: {},
                                            profile: {}
                                        });
                                        this.functions.listProperties();
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.google_analytics && this.state.client.google_analytics.accountId) || this.state.account.id)}
                                copyText={(this.state.client.google_analytics && this.state.client.google_analytics.accountId) ? this.state.client.google_analytics.accountId : this.state.account.id}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.account.id || this.state.client.google_analytics) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={this.state.client.google_analytics ? "Property" : "Properties"}
                                    loader={this.state.loading_properties}
                                    locked={this.state.client.google_analytics}
                                    selected={this.state.client.google_analytics && this.state.client.google_analytics.propertyId ? { name: this.state.client.google_analytics.propertyName } : (this.state.property.id ? this.state.property : { name: "Select property" })}
                                    options={this.state.properties}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            property: value,
                                            profile: {}
                                        });
                                        this.functions.listProfiles();
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.google_analytics && this.state.client.google_analytics.propertyId) || this.state.property.id)}
                                copyText={(this.state.client.google_analytics && this.state.client.google_analytics.propertyId) ? this.state.client.google_analytics.propertyId : this.state.property.id}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.property.id || this.state.client.google_analytics) &&
                        <div className="mt-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Currency"}
                                locked={this.state.client.google_analytics}
                                selected={this.state.client.google_analytics ? { name: this.state.client.google_analytics.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                                options={this.state.currencies}
                                onChange={async (value) => {
                                    this.setState({
                                        currency: value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.property.id || this.state.client.google_analytics) &&
                        <div className="mt-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={this.state.client.google_analytics ? "Profile (optional)" : "Profiles (optional)"}
                                loader={this.state.loading_profiles}
                                locked={this.state.client.google_analytics}
                                selected={this.state.client.google_analytics && this.state.client.google_analytics.profileId ? { name: this.state.client.google_analytics.profileName } : (this.state.profile.id ? this.state.profile : { name: this.state.client.google_analytics ? "No profile selected" : "Select profile" })}
                                options={this.state.profiles}
                                onChange={async (value) => {
                                    this.setState({
                                        profile: value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        !this.state.client.google_analytics &&
                        this.state.property.id &&
                        this.state.account.id &&
                        <div className="flex w-full flex-row">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        this.functions.connectToClient();
                                    }
                                }}
                                className={(this.state.property.id ? "bg-googleanalytics-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save Google Analytics account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-googleanalytics-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        (!this.state.user.google_analytics || (this.state.user.google_analytics && !this.state.user.google_analytics.authenticated)) &&
                        <div className="mt-5">
                            <button onClick={() => {

                                let self = this;
                                const script = document.createElement('script');
                                script.setAttribute("id", "google_api");
                                script.src = "https://apis.google.com/js/platform.js";
                                script.async = true;
                                script.defer = true;
                                script.onload = () => window.gapi.load('auth2', initClient);
                                document.body.appendChild(script);

                                const initClient = () => {
                                    window['gapi'].auth2.init({
                                        client_id: '72023551936-ei41vqh9tbve3iotrl3e00ou5oku3fpr.apps.googleusercontent.com',
                                        cookiepolicy: 'single_host_origin',
                                        scope: 'https://www.googleapis.com/auth/analytics.readonly'
                                    }).then(() => {
                                        const auth = window['gapi'].auth2.getAuthInstance();
                                        auth.grantOfflineAccess().then(res => {
                                            self.functions.login(res.code);
                                            //document.body.removeChild(script);
                                        }).catch(function (error) {
                                            //document.body.removeChild(script);
                                        });
                                    });
                                };

                            }} className="mt-5">
                                <img className="w-48 cursor-pointer" src={require("../assets/images/google_signin_button_2.png")} />
                                {
                                    false &&
                                    this.state.loading &&
                                    <div className={(!(this.state.user.google_analytics && this.state.user.google_analytics.authenticated) ? "bg-googleanalytics-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        this.state.user.google_analytics && this.state.user.google_analytics.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                }}
                                className={(!(this.state.user.google_analytics && this.state.user.google_analytics.authenticated) ?
                                    "cursor-pointer bg-google-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.google_analytics && this.state.user.google_analytics.authenticated) ? "bg-googleanalytics-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        this.state.healthcheck && this.state.healthcheck.google_analytics && this.state.healthcheck.google_analytics.status != 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.google_analytics.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.client.google_analytics &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.google_analytics && this.state.client.google_analytics.defaultAgent && this.state.client.google_analytics.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.google_analytics &&
                        !(this.state.me.google_analytics && this.state.me.google_analytics.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated Google Analytics, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.google_analytics &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated Google Analytics
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default AccountGoogleAnalytics;
