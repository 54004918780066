import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { orderRegister } from '../services/orderRegister';


import { UserAddIcon, ThumbUpIcon, CheckIcon, ArrowSmRightIcon, XIcon, PencilAltIcon, DuplicateIcon, PlusCircleIcon, FolderIcon, ViewGridIcon, ArrowNarrowRightIcon, ArrowDownIcon, ArrowUpIcon, ArrowRightIcon, ArrowLeftIcon, PlusIcon, TrashIcon, DotsVerticalIcon, ExclamationCircleIcon, XCircleIcon, LockClosedIcon, SaveIcon, ClipboardListIcon, ViewGridAddIcon, LockOpenIcon, NewspaperIcon, InformationCircleIcon, ExclamationIcon, BeakerIcon, ReplyIcon } from '@heroicons/react/outline'
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import InputDatepickerTailwind from '../moduleFiles/inputDatepickerTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import EditFacebookCampaign from '../moduleFiles/editFacebookCampaign';
import EditFacebookAdset from '../moduleFiles/editFacebookAdset';
import EditTwitterCampaign from '../moduleFiles/editTwitterCampaign';
import { draftRegister } from '../services/draftRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import SuccessNotification from '../moduleFiles/successNotification';
import EditTikTokCampaign from '../moduleFiles/editTikTokCampaign';
import EditSnapchatCampaign from '../moduleFiles/editSnapchatCampaign';
import EditLinkedinCampaign from '../moduleFiles/editLinkedinCampaign';
import EditLinkedinAdgroup from '../moduleFiles/editLinkedinAdgroup';
import EditTikTokAdset from '../moduleFiles/editTikTokAdset';
import EditSnapchatAdset from '../moduleFiles/editSnapchatAdset';
import EditTwitterAdset from '../moduleFiles/editTwitterAdset';
import WizardModal from '../moduleFiles/wizardModal';
import EditGoogleCampaign from '../moduleFiles/editGoogleCampaign';
import EditGoogleAdgroup from '../moduleFiles/editGoogleAdgroup';
import SuccessModal from '../moduleFiles/successModal';
import EditLiveTikTokCampaign from '../moduleFiles/editLiveTikTokCampaign';
import EditLiveTikTokAdgroup from '../moduleFiles/editLiveTikTokAdgroup';
import EditLiveSnapchatCampaign from '../moduleFiles/editLiveSnapchatCampaign';
import EditLiveSnapchatAdgroup from '../moduleFiles/editLiveSnapchatAdgroup';
import EditLiveFacebookCampaign from '../moduleFiles/editLiveFacebookCampaign';
import EditLiveFacebookAdgroup from '../moduleFiles/editLiveFacebookAdgroup';
import EditLiveGoogleCampaign from '../moduleFiles/editLiveGoogleCampaign';

import EditLiveLinkedinCampaign from '../moduleFiles/editLiveLinkedinCampaign';
import EditLiveLinkedinAdgroup from '../moduleFiles/editLiveLinkedinAdgroup';
import EditLiveGoogleAdgroup from '../moduleFiles/editLiveGoogleAdgroup';

import SideNotification from '../moduleFiles/sideNotification';
import AddCampaignModal from '../moduleFiles/addCampaignModal';

import { adgroupTikTok } from '../validators/adgroupTiktok';
import { campaignTiktok } from '../validators/campaignTiktok';

import { adgroupSnapchat } from '../validators/adgroupSnapchat';
import { campaignSnapchat } from '../validators/campaignSnapchat';

import { campaignFacebook } from '../validators/campaignFacebook';
import { adgroupFacebook } from '../validators/adgroupFacebook';

import { adgroupLinkedin } from '../validators/adgroupLinkedin';
import { campaignLinkedin } from '../validators/campaignLinkedin';
import { campaignTwitter } from '../validators/campaignTwitter';
import { campaignGoogle } from '../validators/campaignGoogle';
import { adgroupTwitter } from '../validators/adgroupTwitter';
import ToolTip from '../moduleFiles/toolTip';
import { round } from '@turf/turf';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';
import { userRegister } from '../services/userRegister';
import internalChannels from '../assets/json/channels.json';


class CreateDraftChannels extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bing: true,
            disabled: false,
            option: null,
            options: [
                { id: 1, name: "Facebook Ads Manager", value: "facebook", color: "bg-facebook-500", available: true },
                { id: 3, name: "Linkedin Ads", value: "linkedin", color: "bg-linkedin-500", available: true },
                { id: 4, name: "Twitter Ads", value: "twitter", color: "bg-twitter-500", available: true },
                { id: 5, name: "TikTok Ads", value: "tiktok", color: "bg-black", available: true },
                { id: 2, name: "Google Ads", value: "google", color: "bg-google-500", available: true },
                { id: 6, name: "Snapchat Ads", value: "snapchat", color: "bg-snapchat-500", available: true },
            ],
            sub_options: {
                "google": [
                    { id: 1, name: "Search", value: "search", description: "Get in front of high-intent customers at the right time on Google Search", available: true },
                    { id: 2, name: "Performance max", value: "performance_max", description: "Reach audiences across all of Google with a single campaign", available: true },
                    { id: 4, name: "Display Network", value: "display", description: "Reach customers across 3 million sites and apps with engaging creative", available: true },
                    { id: 5, name: "Shopping", value: "shopping", description: "Showcase your products to shoppers as they explore what to buy", available: true },
                    { id: 6, name: "Video", value: "video", description: "Reach viewers on YouTube and get conversions (preview only)", available: true },
                    { id: 7, name: "App", value: "app", description: "Drive downloads and grow engagement for your app", available: false },
                    { id: 8, name: "Smart", value: "smart", description: "Reach customers with a one-stop solution built for small businesses", available: false },
                    { id: 9, name: "Discovery", value: "discovery", description: "Run ads on YouTube, Gmail, Discover, and more", available: false }
                ],
                "facebook": [
                    { id: 1, name: "Awareness", value: "awareness", description: "Show your ads to people who are most likely to remember them.", available: true },
                    { id: 2, name: "Traffic", value: "traffic", description: "Send people to a destination, like your website, app or Facebook event", available: true },
                    { id: 3, name: "Engagement", value: "engagement", description: "Get more messages, video views, post engagement, Page likes or event responses.", available: true },
                    { id: 4, name: "Leads", value: "leads", description: "Collect leads for your business or brand.", available: true },
                    { id: 5, name: "App promotion", value: "app_promotion", description: "Find new people to install your app and continue using it", available: true },
                    { id: 6, name: "Sales", value: "sales", description: "Find people likely to purchase your product or service.", available: true },
                ],
                "twitter": [
                    { id: 3, name: "Reach", value: "reach", description: "Maximize your ad's reach", available: true },
                    { id: 6, name: "Video views", value: "video_views", description: "Get people to watch your video", available: true },
                    //{ id: 6, name: "Preroll views", value: "preroll_views", description: "", available: true },
                    { id: 2, name: "App installs", value: "app_installs", description: "Get people to install your app", available: true },
                    { id: 8, name: "Website traffic", value: "website_clicks", description: "Drive traffic to your website", available: true },
                    { id: 5, name: "Engagements", value: "engagements", description: "Get people to engage with your Tweet", available: true },
                    { id: 4, name: "Followers", value: "followers", description: "Build an audience for your account", available: true },
                    { id: 1, name: "App re-engagements", value: "app_engagement", description: "Get people to take action in your app", available: true },
                ],
                "tiktok": [
                    { id: 1, name: "Reach", value: "reach", description: "Show your ad to the maximum number of people", available: true },
                    { id: 2, name: "Traffic", value: "traffic", description: "Send more people to a destination on your website or app", available: true },
                    //{ id: 3, name: "Video views", value: "video_views", description: "Get more views and engagement for your video ads", available: true },
                    //{ id: 4, name: "Lead generation", value: "video_views", description: "Collect leads for your business or brand", available: true },
                    //{ id: 5, name: "Community interaction", value: "community", description: "Get more followers or profile visits", available: true },

                    { id: 7, name: "Website conversion", value: "website_conversions", description: "Drive valuable actions on your website", available: true },
                    { id: 6, name: "App promotion (Coming soon)", value: "app_promotion", description: "Get more users to install and engage with your app", available: true }
                ],
                "snapchat": [
                    { id: 1, name: "Awareness & engagement", value: "AWARENESS_AND_ENGAGEMENT", description: "Reach Snapchatters and create interest in your brand", available: true },
                    // { id: 2, name: "Promote Places", value: "promote_places", description: "Promote your places to Snapchatters", available: true },
                    { id: 4, name: "Traffic", value: "TRAFFIC", description: "Send Snapchatters to a destination like a website or app", available: true },
                    // { id: 5, name: "Drive Traffic To App", value: "traffic_app", description: "Send Snapchatters to your app or a third-party app", available: true },
                    //{ id: 6, name: "Engagement", value: "engagement", description: "Get more Snapchatters to engage with your ad", available: true },
                    // { id: 7, name: "Video Views", value: "video_views", description: "Promote your brand or product to Snapchatters through video", available: true },
                    { id: 8, name: "Leads", value: "LEADS", description: "Get Snapchatters to share their contact details with your business", available: true },
                    // { id: 9, name: "App Conversions", value: "app_conversions", description: "Drive specific actions within your app", available: true },
                    { id: 10, name: "Sales (Comming soon)", value: "SALES", description: "Get Snapchatters to buy your product or service.", available: true },

                    { id: 3, name: "App Installs (Coming soon)", value: "app_installs", description: "Send Snapchatters to the app store to download your app", available: true },
                    // { id: 11, name: "Catalog Sales", value: "catalog_sales", description: "Choose which product catalog you want to use in this campaign", available: true }
                    // ?? MAYBE NEEDS PIXEL IDS ??
                ],
                "linkedin": [
                    { id: 1, name: "Brand awareness", value: "brand_awareness", description: "I want more people to learn about my business", available: true },
                    { id: 2, name: "Website visits", value: "website_visits", description: "I want more people to visit a website destination site on or off LinkedIn.", available: true },
                    { id: 3, name: "Engagement", value: "engagement", description: "I want more people to engage with my content posts.", available: true },
                    { id: 4, name: "Video views", value: "video_views", description: "I want more people to watch my videos", available: true },
                    { id: 5, name: "Lead generation (Comming soon)", value: "lead_generation", description: "I want more quality leads on LinkedIn.", available: true },
                    { id: 6, name: "Website conversions", value: "website_conversions", description: "I want more purchases, registrations, or downloads.", available: true },
                    { id: 7, name: "Job applications", value: "jobb_applications", description: "I want to promote job opportunities at my company.", available: true },
                ]
            },
            tabs: [
                { id: 1, name: 'Campaigns', value: 'campaigns', icon: FolderIcon },
                { id: 2, name: 'Adgroups', value: 'adgroups', icon: ViewGridIcon }
            ],
            agency: {},
            channels: {},
            update: {},
            edit: false,
            campaigns: [],
            adgroups: [],
            creatives: [],
            assetItems: [],
            ads: [],
            tree: {},
            client: {},
            order: {},
            wizard: {},
            loading: true,
            tab: { id: 1, name: 'Campaigns', value: 'campaigns', icon: FolderIcon },
            saved_success: false,
            success: null,
            create_template: false,
            template_name: "",
            add_adgroup: false,
            error: false,
            show: false,
            internalChannels: internalChannels
        };
    }

    componentWillMount() {

    }

    async componentDidMount() {
        await this.functions.query();
        await this.functions.validate();
        Object.keys(this.state.channels).forEach(async item => {
            if (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((inner_item) => { return inner_item.value === item }).length < 1) {
                await this.promisedSetState({
                    error: true
                })
            }
        });
    }

    form = {
        set: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("campaigns", self.state.campaigns);
                draftRegister.set("adgroups", self.state.adgroups);
                draftRegister.set("creatives", self.state.creatives);
                draftRegister.set("assetItems", self.state.assetItems);
                draftRegister.set("ads", self.state.ads);
                draftRegister.set("channels", self.state.channels);
                draftRegister.set("wizard", self.state.wizard);
                draftRegister.set("client", self.state.client);
                draftRegister.set("order", self.state.order);
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: Array.isArray(draftRegister.get()["creatives"]) ? draftRegister.get()["creatives"].map((item) => { item.selected = false; return item }) : self.state.creatives,
                        assetItems: Array.isArray(draftRegister.get()["assetItems"]) ? draftRegister.get()["assetItems"].map((item) => { item.selected = false; return item }) : self.state.assetItems,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        campaigns: Array.isArray(draftRegister.get()["campaigns"]) ? draftRegister.get()["campaigns"].map((item) => { item.selected = false; return item }) : self.state.campaigns,
                        adgroups: Array.isArray(draftRegister.get()["adgroups"]) ? draftRegister.get()["adgroups"].map((item) => { item.selected = false; return item }) : self.state.adgroups,
                        ads: Array.isArray(draftRegister.get()["ads"]) ? draftRegister.get()["ads"].map((item) => { item.selected = false; return item }) : self.state.ads,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels
                    })
                    await self.functions.getAdditionalInfo();
                }
                resolve();
            })
        }
    };

    functions = {
        validate: async () => {
            if ((this.state.adgroups.map((adgroup) => {
                if (adgroup.partial_parced) {
                    return false
                } else {
                    if (adgroup.channel === "facebook") {
                        if (Object.keys(adgroupFacebook.validate(adgroup)).map((item) => { return adgroupFacebook.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                            return true;
                        } else return false
                    } else if (adgroup.channel === "tiktok") {
                        if (Object.keys(adgroupTikTok.validate(adgroup)).map((item) => { return adgroupTikTok.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                            return true;
                        } else return false
                    } else if (adgroup.channel === "snapchat") {
                        if (Object.keys(adgroupSnapchat.validate(adgroup)).map((item) => { return adgroupSnapchat.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                            return true;
                        } else return false
                    } else if (adgroup.channel === "twitter") {
                        if (Object.keys(adgroupTwitter.validate(adgroup)).map((item) => { return adgroupTwitter.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                            return true;
                        } else return false
                    } else if (adgroup.channel === "linkedin") {
                        if (Object.keys(adgroupLinkedin.validate(adgroup)).map((item) => { return adgroupLinkedin.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                            return true;
                        } else return false
                    }
                }
            }).filter((item) => { return item }).length > 0) ||
                (this.state.campaigns.map((campaign) => {
                    if (campaign.partial_parced) {
                        return false
                    } else {
                        if (campaign.channel === "facebook") {
                            return campaignFacebook.validate(campaign)
                        } else if (campaign.channel === "tiktok") {
                            return campaignTiktok.validate(campaign)
                        } else if (campaign.channel === "snapchat") {
                            if (Object.keys(campaignSnapchat.validate(campaign)).map((item) => { return campaignSnapchat.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                return true;
                            } else return false
                        } else if (campaign.channel === "twitter") {
                            return campaignTwitter.validate(campaign)
                        } else if (campaign.channel === "linkedin") {
                            if (Object.keys(campaignLinkedin.validate(campaign)).map((item) => { return campaignLinkedin.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                return true;
                            } else return false

                        } else if (campaign.channel === "google") {
                            if (Object.keys(campaignGoogle.validate(campaign)).map((item) => { return campaignGoogle.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                return true;
                            } else return false
                        }
                    }
                }).filter((item) => { return item }).length > 0)
            ) {
                console.log("VALIDATE SUCCESS 1")
                await this.promisedSetState({
                    error: true
                })
            } else {
                console.log("VALIDATE SUCCESS 2")
                await this.promisedSetState({
                    error: false
                })
            }
            console.log("VALIDATE", this.state.error);
        },
        query: async () => {
            if ((window.location.search.match(new RegExp('[?&]' + "template" + '=([^&]+)')) || [null])[1]) {
                if (draftRegister.get() && draftRegister.get()["wizard"]) {
                    await this.form.get();
                    await this.promisedSetState({
                        loading: false
                    })
                    this.props.setSteps();
                } else {
                    let id = (window.location.search.match(new RegExp('[?&]' + "template" + '=([^&]+)')) || [null])[1];
                    this.functions.getWizard(id);
                }
            } else if ((window.location.search.match(new RegExp('[?&]' + "draft" + '=([^&]+)')) || [null])[1]) {
                if (draftRegister.get() && draftRegister.get()["wizard"]) {
                    await this.form.get();
                    await this.promisedSetState({
                        loading: false
                    })
                    this.props.setSteps();
                } else {
                    let id = (window.location.search.match(new RegExp('[?&]' + "draft" + '=([^&]+)')) || [null])[1];
                    this.functions.getWizard(id);
                }
            } else {
                await this.form.get();
                await this.promisedSetState({
                    loading: false
                });
                this.props.setSteps();
            }

            this.functions.checkChannels();

            /*
            //ADD ADS TAB IF ERROR
            if (this.state.wizard && this.state.wizard.status === "error") {
                this.state.tabs.push({ id: 3, name: 'Ads', value: 'ads', icon: NewspaperIcon });
                this.setState({
                    tabs: this.state.tabs
                });
            }
            */

        },
        getGoogleBudgets: async () => {
            try {
                let response = await this.calls.listGoogleBudgets();
                this.state.order.googleBudgets = this.state.order.googleBudgets.map((item) => {
                    if (response.data.filter((inner_item) => { return inner_item.id === item.id }).length > 0) {
                        item = response.data.filter((inner_item) => { return inner_item.id === item.id })[0];
                    }
                    return item;
                });
                await this.promisedSetState({
                    order: this.state.order
                });
            } catch (error) {
                console.log(error);
            }
        },
        getAdditionalInfo: async () => {
            try {
                let response = await this.calls.getAdditionalInfo();
                this.state.client.company = response.data.company;
                await this.promisedSetState({
                    agency: { name: response.data.agency, id: response.data.id },
                    client: this.state.client
                });
                if (response.data.googleDefaultSettings) {
                    this.state.agency.googleDefaultSettings = response.data.googleDefaultSettings;
                    await this.promisedSetState({
                        agency: this.state.agency
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        checkChannels: async () => {
            let channels = {};
            this.state.campaigns.map((campaign) => {
                channels[campaign.channel] = true;
            });
            await this.promisedSetState({
                channels: channels
            });
            await this.form.set();
            this.props.setSteps();
        },
        switch: async (item) => {
            try {
                if (!this.state.channels[item.value]) {
                    this.state.channels[item.value] = true;
                    this.functions.onNew(item);
                } else {
                    delete this.state.channels[item.value];
                    this.state.campaigns = this.state.campaigns.filter((campaign) => { return campaign.channel !== item.value });
                    this.state.adgroups = this.state.adgroups.filter((adgroup) => { return adgroup.channel !== item.value });
                    this.state.ads = this.state.ads.filter((ad) => { return ad.channel !== item.value });
                }
                await this.promisedSetState({
                    channels: this.state.channels,
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups,
                    ads: this.state.ads,
                });
                await this.form.set();
                this.props.setSteps();
            } catch (error) {
                console.log(error);
            }
        },
        onNew: async (channel) => {
            if (channel.value === "facebook") {
                this.functions.onNewFacebook();
            } else if (channel.value === "tiktok") {
                this.functions.onNewTikTok();
            } else if (channel.value === "linkedin") {
                this.functions.onNewLinkedin();
            } else if (channel.value === "twitter") {
                this.functions.onNewTwitter();
            } else if (channel.value === "google") {
                this.functions.onNewGoogle();
            } else if (channel.value === "snapchat") {
                this.functions.onNewSnapchat();
            } else {
                let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
                let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
                this.state.campaigns.push({
                    id: campaign_id,
                    level: 'campaign',
                    channel: channel.value,
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate
                });
                this.state.adgroups.push({
                    id: adgroup_id,
                    level: 'adgroup',
                    channel: channel.value,
                    campaign: campaign_id,
                    name: "Adset",
                    start_date: this.state.order.startDate,
                    end_date: this.state.order.endDate
                });
                await this.promisedSetState({
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups
                });
                this.form.set();
            }
        },
        onNewGoogle: async (existing_campaign, sub_option) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);
            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    //campaign_type: "Search",
                    level: 'campaign',
                    channel: "google",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                    //strategy_type: { id: 1, name: "Click", value: "TARGET_SPEND" },
                    allow_target_spend_ceiling: { id: 1, name: 'Disabled', value: false },
                    //advertising_type: { id: 1, name: 'Search', value: "SEARCH" },
                    //advertising_sub_type: { id: 0, name: "", value: null, adgroup: "SEARCH_STANDARD", ad: "RESPONSIVE_SEARCH_AD" },
                    days: [{ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }]
                };

                if (sub_option && sub_option.value === "search") {
                    campaign_object.strategy_type = { id: 1, name: "Click", value: "TARGET_SPEND" };
                    campaign_object.campaign_type = "Search";
                    campaign_object.campaign_type_property = "search";
                    campaign_object.advertising_type = { id: 1, name: 'Search', value: "SEARCH" };
                    campaign_object.google_search = { id: 1, name: "Enabled", value: true, locked: true };
                    campaign_object.search_network = { id: 1, name: "Enabled", value: true, locked: false };
                    campaign_object.content_network = { id: 1, name: "Enabled", value: true, locked: false };
                    campaign_object.partner_search_network = { id: 2, name: "Disabled", value: false, locked: false };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SEARCH_STANDARD", ad: "RESPONSIVE_SEARCH_AD" };
                } else if (sub_option && sub_option.value === "performance_max") {
                    campaign_object.campaign_type = "Performance Max";
                    campaign_object.campaign_type_property = "performance_max";
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.strategy_type = { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" };
                    campaign_object.advertising_type = { id: 6, name: "Performance Max", value: "PERFORMANCE_MAX" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "PERFORMANCE_MAX", ad: "PERFORMANCE_MAX" };
                } else if (sub_option && sub_option.value === "display") {
                    campaign_object.campaign_type = "Display Network";
                    campaign_object.campaign_type_property = "display";
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.strategy_type = { id: 2, name: "Conversions", value: "MAXIMIZE_CONVERSIONS" };
                    campaign_object.advertising_type = { id: 6, name: "Display", value: "DISPLAY" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "DISPLAY", ad: "DISPLAY" };
                    campaign_object.targeting = {
                        people: {
                            audiance: []
                        },
                        demographics: {
                            gender: [],
                            age: [],
                            parental_status: [],
                        },
                        optimized_targeting: false
                    };
                } else if (sub_option && sub_option.value === "shopping") {
                    campaign_object.campaign_type = "Shopping";
                    campaign_object.campaign_type_property = "shopping";
                    campaign_object.enable_local = { id: 1, name: "Disabled", value: false };
                    campaign_object.shopping_priority = { id: 1, name: "Low (default) – Recommended if you only have one Shopping campaign", value: 0 };
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                    campaign_object.google_search = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.search_network = { id: 2, name: "Disabled", value: false, locked: true };
                    campaign_object.content_network = { id: 2, name: "Disabled", value: false, locked: false };
                    campaign_object.partner_search_network = { id: 2, name: "Disabled", value: false, locked: false };
                    campaign_object.strategy_type = { id: 7, name: "Maximize clicks", value: "TARGET_SPEND" };
                    campaign_object.advertising_type = { id: 4, name: "Shopping Standard", value: "SHOPPING" };
                    campaign_object.advertising_sub_type = { id: 0, name: "", value: null, adgroup: "SHOPPING_PRODUCT_ADS", ad: "SHOPPING_PRODUCT_AD" };
                } else if (sub_option && sub_option.value === "video") {
                    campaign_object.campaign_type = "Video";
                    campaign_object.campaign_type_property = "video";
                    campaign_object.advertising_type = { id: 3, name: "Video (Youtube) - Preview only", value: "VIDEO" };
                    campaign_object.advertising_sub_type = { id: 1, name: "Video non skippable", value: "VIDEO_NON_SKIPPABLE", adgroup: "VIDEO_NON_SKIPPABLE_IN_STREAM", ad: "VIDEO_NON_SKIPPABLE_IN_STREAM_AD" };
                    campaign_object.dsa = { id: 2, name: "Disable", value: false };
                }

                if (campaign_object.search_network) {
                    if (this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.searchNetwork) {
                        if (!campaign_object.search_network.locked) {
                            campaign_object.search_network.value = this.state.agency.googleDefaultSettings.searchNetwork.value;
                            campaign_object.search_network.id = this.state.agency.googleDefaultSettings.searchNetwork.id;
                            campaign_object.search_network.name = this.state.agency.googleDefaultSettings.searchNetwork.name;
                        }
                    }
                    if (this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.contentNetwork) {
                        if (!campaign_object.content_network.locked) {
                            campaign_object.content_network.value = this.state.agency.googleDefaultSettings.contentNetwork.value;
                            campaign_object.content_network.id = this.state.agency.googleDefaultSettings.contentNetwork.id;
                            campaign_object.content_network.name = this.state.agency.googleDefaultSettings.contentNetwork.name;
                        }
                    }
                    if (this.state.agency.googleDefaultSettings && this.state.agency.googleDefaultSettings.partnerSearchNetwork) {
                        if (!campaign_object.partner_search_network.locked) {
                            campaign_object.partner_search_network.value = this.state.agency.googleDefaultSettings.partnerSearchNetwork.value;
                            campaign_object.partner_search_network.id = this.state.agency.googleDefaultSettings.partnerSearchNetwork.id;
                            campaign_object.partner_search_network.name = this.state.agency.googleDefaultSettings.partnerSearchNetwork.name;
                        }
                    }

                }

                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = JSON.parse(JSON.stringify(this.state.wizard.scheduler));
                    if (this.state.wizard.scheduler.schedule) {
                        campaign_object.start_date = this.state.wizard.scheduler.start_date;
                    }
                }
                if (this.state.order.budgetSplit && this.state.order.budgetSplit.value && this.state.order.budgetSplit.google_budget && sub_option && sub_option.value === "search" && this.state.wizard.bingSync) {
                    let a = JSON.parse(JSON.stringify(this.state.wizard.scheduler));
                    if (campaign_object.scheduler) {
                        campaign_object.scheduler.schedule = true;
                        campaign_object.scheduler.planer = true;
                        campaign_object.scheduler.schedule_budget = round(+a.schedule_budget * (+this.state.order.budgetSplit.google_budget / 100));
                    }
                    campaign_object.bingSync = {
                        budget: +a.schedule_budget,
                        google_budget: +this.state.order.budgetSplit.google_budget,
                        bing_budget: +this.state.order.budgetSplit.bing_budget,
                        total_budget: +a.schedule_budget * (+this.state.order.budgetSplit.bing_budget / 100),
                        schedule: { id: 3, name: "Daily", value: "Daily" },
                        notificationType: { id: 3, name: "Only if there are errors", value: "Errors" },
                        email: userRegister.get() ? userRegister.get().email : "",//this.state.client.email,
                        bing_percentage_from_google: budgetPlannerGraph.main(campaign_object.scheduler, +a.schedule_budget * (+this.state.order.budgetSplit.bing_budget / 100)).datasets[0].data[0] === Infinity ? 0 : budgetPlannerGraph.main(campaign_object.scheduler, +a.schedule_budget * (+this.state.order.budgetSplit.bing_budget / 100)).datasets[0].data[0] * 100 / +budgetPlannerGraph.main(campaign_object.scheduler).datasets[0].data[0],
                    };
                }
                if (this.state.order.googleBudgets && this.state.order.googleBudgets.length > 0) {
                    campaign_object.budget = 1;
                    campaign_object.googleBudgets = this.state.order.googleBudgets;
                    campaign_object.shared_budget = this.state.order.googleBudgets[0];
                }
                if (campaign_object.scheduler) {
                    campaign_object.scheduler.order_info = this.state.order;
                }

                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "google",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                gender: { id: 1, name: "All genders", value: "ALL" },
                ages: [
                    { id: 1, name: "18 - 24", value: "AGE_RANGE_18_24", included: true },
                    { id: 2, name: "25 - 34", value: "AGE_RANGE_25_34", included: true },
                    { id: 3, name: "35 - 44", value: "AGE_RANGE_35_44", included: true },
                    { id: 4, name: "45 - 54", value: "AGE_RANGE_45_54", included: true },
                    { id: 5, name: "55 - 64", value: "AGE_RANGE_55_64", included: true },
                    { id: 6, name: "65+", value: "AGE_RANGE_65_UP", included: true },
                    { id: 7, name: "Undetermined", value: "AGE_RANGE_UNDETERMINED", included: true }
                ],
            };
            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }
            if (!existing_campaign) {
                if (sub_option && sub_option.value === "search") {
                    adgroup_object.advertising_type = "SEARCH_STANDARD";
                    adgroup_object.advertising_type_ad = "RESPONSIVE_SEARCH_AD";
                } else if (sub_option && sub_option.value === "display") {
                    adgroup_object.advertising_type = "DISPLAY_STANDARD";
                    adgroup_object.advertising_type_ad = "RESPONSIVE_DISPLAY_AD";
                    adgroup_object.optimizedTargetingEnabled = true;
                    adgroup_object.targeting = {
                        userInterest: [],
                        parental_status: [
                            { id: 1, name: "Not a Parent", value: "NOT_A_PARENT", included: true },
                            { id: 2, name: "Parent", value: "PARENT", included: true },
                            { id: 3, name: "Undetermined", value: "UNDETERMINED", included: true }
                        ]
                    }
                } else if (sub_option && sub_option.value === "search") {
                    adgroup_object.advertising_type = "PERFORMANCE_MAX";
                    adgroup_object.advertising_type_ad = "PERFORMANCE_MAX";
                } else if (sub_option && sub_option.value === "shopping") {
                    adgroup_object.advertising_type = "SHOPPING_PRODUCT_ADS";
                    adgroup_object.advertising_type_ad = "SHOPPING_PRODUCT_AD";
                } else if (sub_option && sub_option.value === "video") {
                    adgroup_object.advertising_type = "VIDEO_NON_SKIPPABLE_IN_STREAM";
                    adgroup_object.advertising_type_ad = "VIDEO_NON_SKIPPABLE_IN_STREAM_AD";
                }
            } else {
                if (existing_campaign.advertising_sub_type) {
                    adgroup_object.advertising_type = existing_campaign.advertising_sub_type.adgroup;
                    adgroup_object.advertising_type_ad = existing_campaign.advertising_sub_type.ad;
                    if (existing_campaign.advertising_type === "DISPLAY_STANDARD") {
                        adgroup_object.optimizedTargetingEnabled = true;
                        adgroup_object.targeting = {
                            userInterest: [],
                            parental_status: [
                                { id: 1, name: "Not a Parent", value: "NOT_A_PARENT", included: true },
                                { id: 2, name: "Parent", value: "PARENT", included: true },
                                { id: 3, name: "Undetermined", value: "UNDETERMINED", included: true }
                            ]
                        }
                    }
                }
            }

            this.state.adgroups.push(adgroup_object);
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onNewLinkedin: async (existing_campaign, sub_option, creatives) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "linkedin",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                    campaign_budget: { id: 1, name: 'No', value: false }
                };
                this.state.campaigns.push(campaign_object);
            }

            for (let key in creatives) {

                let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

                let adgroup_object = {
                    id: adgroup_id,
                    level: 'adgroup',
                    channel: "linkedin",
                    campaign: existing_campaign ? existing_campaign.id : campaign_id,
                    audience_network: { id: 2, name: "No", value: false },
                    selected_iab_categories: [],
                    name: "Adset",
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                    adgroup_budget: { id: 1, name: 'Daily budget', value: "daily" },
                    daily_budget: 0,
                    total_budget: 0,
                    age: [
                        {
                            id: 1,
                            name: "All ages",
                            facetUrn: "urn:li:adTargetingFacet:ageRanges",
                            value: "ALL",
                            included: true
                        }
                    ]
                };
                if (this.state.wizard.scheduler) {
                    adgroup_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.schedule) {
                        adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                    }

                    adgroup_object.scheduler.order_info = this.state.order;
                }

                if (this.state.wizard.partial) {
                    adgroup_object.partial = true;
                }

                if (creatives[key].value === "image") {
                    adgroup_object.adgroup_type = { id: 1, name: creatives[key].name, value: "STANDARD_UPDATE" };
                } else if (creatives[key].value === "video") {
                    adgroup_object.adgroup_type = { id: 2, name: creatives[key].name, value: "SINGLE_VIDEO" };
                } else if (creatives[key].value === "carousel") {
                    adgroup_object.adgroup_type = { id: 3, name: creatives[key].name, value: "CAROUSEL" };
                }

                //BRAND AWARENESS
                if (sub_option && sub_option.value === "brand_awareness") {
                    let adgroup_objective = {
                        objective: {
                            name: "Brand awareness",
                            value: "BRAND_AWARENESS",
                            optimization_goals: [
                                {
                                    name: "Reach (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum Delivery (recommended)",
                                            value: "MAX_REACH",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Max Delivery (recommended)",
                                            value: "MAX_IMPRESSION",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_IMPRESSION",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Reach (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "MAX_REACH",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum Delivery (recommended)",
                            value: "MAX_REACH",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE VISITS
                if (sub_option && sub_option.value === "website_visits") {
                    let adgroup_objective = {
                        objective: {
                            name: "Website visits",
                            value: "WEBSITE_VISIT",
                            optimization_goals: [
                                {
                                    name: "Landing page click (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_CLICK",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_CLICK",
                                            cost_type: "CPM",
                                            set_cost: true
                                        }
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ],
                        },
                        optimization_goal: {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPM",
                                    set_cost: true
                                }
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //ENGAGEMENT
                if (sub_option && sub_option.value === "engagement") {
                    let adgroup_objective = {
                        objective: {
                            name: "Engagement",
                            value: "ENGAGEMENT",
                            optimization_goals: [
                                {
                                    name: "Engagement clicks (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_CLICK",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Cost cap",
                                            value: "TARGET_COST_PER_CLICK",
                                            cost_type: "CPC",
                                            set_cost: true
                                        }
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Engagement clicks (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_CLICK",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Cost cap",
                                    value: "TARGET_COST_PER_CLICK",
                                    cost_type: "CPC",
                                    set_cost: true
                                }
                            ],
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //VIDEO VIEWS
                if (sub_option && sub_option.value === "video_views") {
                    let adgroup_objective = {
                        objective: {
                            name: "Video views",
                            value: "VIDEO_VIEW",
                            optimization_goals: [
                                {
                                    name: "Video views",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum delivery (recommended)",
                                            value: "MAX_VIDEO_VIEW",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Target cost per video view",
                                            value: "TARGET_COST_PER_VIDEO_VIEW",
                                            cost_type: "CPV",
                                            set_cost: true
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ],
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Video views",
                            bidding_strategies: [
                                {
                                    name: "Maximum delivery (recommended)",
                                    value: "MAX_VIDEO_VIEW",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                                {
                                    name: "Target cost per video view",
                                    value: "TARGET_COST_PER_VIDEO_VIEW",
                                    cost_type: "CPV",
                                    set_cost: true
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Maximum delivery (recommended)",
                            value: "MAX_VIDEO_VIEW",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //LEAD GENERATION
                if (sub_option && sub_option.value === "lead_generation") {
                    let adgroup_objective = {
                        objective: {
                            name: "Lead generation",
                            value: "LEAD_GENERATION",
                            optimization_goals: [
                                {
                                    name: "Leads (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Max delivery (recommended)",
                                            value: "MAX_LEAD",
                                            cost_type: "CPM",
                                            set_cost: false
                                        }
                                    ]
                                },
                                {
                                    name: "Click",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPC",
                                            set_cost: true
                                        },
                                    ]
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Leads (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_LEAD",
                                    cost_type: "CPC",
                                    set_cost: false
                                }
                            ]
                        },
                        bidding_strategy: {
                            name: "Max delivery (recommended)",
                            value: "MAX_LEAD",
                            cost_type: "CPC",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE CONVERSIONS
                if (sub_option && sub_option.value === "website_conversions") {
                    let adgroup_objective = {
                        objective: {
                            name: "Website conversions",
                            value: "WEBSITE_CONVERSION",
                            optimization_goals: [
                                {
                                    name: "Website conversion (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Max delivery (recommended)",
                                            value: "MAX_CONVERSION",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                    ]
                                },
                                {
                                    name: "Landing page click",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPC",
                                            set_cost: true
                                        },
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding (recommended)",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Website conversion (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Max delivery (recommended)",
                                    value: "MAX_CONVERSION",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ]
                        },
                        bidding_strategy: {
                            name: "Max delivery (recommended)",
                            value: "MAX_CONVERSION",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                //WEBSITE CONVERSIONS
                if (sub_option && sub_option.value === "jobb_applications") {
                    let adgroup_objective = {
                        objective: {
                            name: "Job applicants",
                            value: "JOB_APPLICANT",
                            optimization_goals: [
                                {
                                    name: "Landing page click (recommended)",
                                    bidding_strategies: [
                                        {
                                            name: "Maximum Delivery (recommended)",
                                            value: "MAX_CLICK",
                                            cost_type: "CPM",
                                            set_cost: false
                                        },
                                        {
                                            name: "Manual bidding",
                                            value: "NONE",
                                            cost_type: "CPC",
                                            set_cost: true
                                        },
                                    ],
                                },
                                {
                                    name: "Impressions",
                                    bidding_strategies: [
                                        {
                                            name: "Manual bidding",
                                            value: "NONE",
                                            cost_type: "CPM",
                                            set_cost: true
                                        },
                                    ]
                                }
                            ]
                        },
                        optimization_goal: {
                            name: "Landing page click (recommended)",
                            bidding_strategies: [
                                {
                                    name: "Maximum Delivery (recommended)",
                                    value: "NONE",
                                    cost_type: "CPM",
                                    set_cost: false
                                },
                            ],
                        },
                        bidding_strategy: {
                            name: "Maximum Delivery (recommended)",
                            value: "MAX_CLICK",
                            cost_type: "CPM",
                            set_cost: false
                        }
                    };
                    adgroup_object = { ...adgroup_object, ...adgroup_objective };
                }

                this.state.adgroups.push(adgroup_object);

            }

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onNewFacebook: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "facebook",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                    budget_optimization: { id: 2, name: "Yes", value: true },
                    special_ad_category: { id: 1, name: 'None', value: "NONE" },
                    budget_optimization_type: { id: 1, name: "Daily budget", value: 'daily' },
                    daily_budget: 0,
                    add_spend_limit: { id: 1, name: "No", value: false },
                    ios: { id: 0, name: "No", value: false },
                    app_promotion_type: { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" }
                };
                if (sub_option && sub_option.value === "awareness") {
                    campaign_object.objective = { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" };
                } else if (sub_option && sub_option.value === "traffic") {
                    campaign_object.objective = { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" };
                } else if (sub_option && sub_option.value === "engagement") {
                    campaign_object.objective = { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" };
                } else if (sub_option && sub_option.value === "leads") {
                    campaign_object.objective = { id: 4, name: 'Leads', value: "OUTCOME_LEADS" };
                } else if (sub_option && sub_option.value === "app_promotion") {
                    campaign_object.objective = { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" };
                    campaign_object.budget_optimization = { id: 1, name: "No", value: false };
                } else if (sub_option && sub_option.value === "sales") {
                    campaign_object.objective = { id: 6, name: 'Sales', value: "OUTCOME_SALES" };
                }
                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.schedule) {
                        campaign_object.start_date = this.state.wizard.scheduler.start_date;
                    }
                    campaign_object.scheduler.order_info = this.state.order;
                }
                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "facebook",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                name: "Adset",
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                gender: { id: 1, name: "All genders", value: [], selected: true },
                age_min: { id: 18, name: "18", value: "18" },
                age_max: { id: 65, name: "65", value: "65" },
                adset_budget: { id: 1, name: "Yes", value: true },
                daily_budget: 0,
                lifetime_budget: 0,
                advantage_audience: { id: 1, name: "Select ...", value: false },
                budget_type: { id: 1, name: "Daily budget", value: 'daily' },
                spend_limit: { id: 2, name: "No", value: false },
                auto_placements: { id: 1, name: "Yes (recommended)", value: true },
                beneficiary: this.state.client.company,
                payor: this.state.agency.name
            };
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }

            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }

            //AWARENESS
            if ((sub_option && sub_option.value === "awareness") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_AWARENESS")) {
                adgroup_object.optimization_goal = { id: 13, name: "Reach", value: "REACH" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 1, name: 'Awareness', value: "OUTCOME_AWARENESS" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //TRAFFIC
            if ((sub_option && sub_option.value === "traffic") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_TRAFFIC")) {
                adgroup_object.optimization_goal = { id: 8, name: "Link Clicks", value: "LINK_CLICKS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 2, name: 'Traffic', value: "OUTCOME_TRAFFIC" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //ENGAGEMENT
            if ((sub_option && sub_option.value === "engagement") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_ENGAGEMENT")) {
                adgroup_object.optimization_goal = { id: 11, name: "Post Engagement", value: "POST_ENGAGEMENT" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.destination_type = { id: 6, name: "On your ad (Post engagement)", value: "ON_POST" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 3, name: 'Engagement', value: "OUTCOME_ENGAGEMENT" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //LEADS
            if ((sub_option && sub_option.value === "leads") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_LEADS")) {
                adgroup_object.optimization_goal = { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 4, name: 'Leads', value: "OUTCOME_LEADS" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            //APP PRMOTION
            if ((sub_option && sub_option.value === "app_promotion") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_APP_PROMOTION")) {
                adgroup_object.optimization_goal = { id: 1, name: "App Installs", value: "APP_INSTALLS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 5, name: 'App Promotion', value: "OUTCOME_APP_PROMOTION" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 1, name: "No", value: false };
            }

            //SALES
            if ((sub_option && sub_option.value === "sales") || (existing_campaign && existing_campaign.objective.value === "OUTCOME_SALES")) {
                adgroup_object.optimization_goal = { id: 9, name: "Conversions", value: "OFFSITE_CONVERSIONS" };
                adgroup_object.billing_event = { id: 2, name: "Impressions", value: "IMPRESSIONS" };
                adgroup_object.page = { id: 0, name: "Select page", value: null };
                adgroup_object.application = { id: 0, name: "No App", value: null };
                adgroup_object.campaign_objective = existing_campaign ? existing_campaign.objective : { id: 6, name: 'Sales', value: "OUTCOME_SALES" };
                adgroup_object.campaign_smart_promotion_type = existing_campaign ? existing_campaign.app_promotion_type : { id: 1, name: 'Advantage+ App Campaigns', value: "SMART_APP_PROMOTION" };
                adgroup_object.campaign_budget_optimization = existing_campaign ? existing_campaign.budget_optimization : { id: 2, name: "Yes", value: true };
            }

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onNewTikTok: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "tiktok",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    budget_optimization: { id: 1, name: "No", value: false },
                };

                if (sub_option && sub_option.value === "traffic") {
                    campaign_object.objective = { id: 2, name: 'Traffic', value: "TRAFFIC" };
                }
                if (sub_option && sub_option.value === "reach") {
                    campaign_object.objective = { id: 1, name: 'Reach', value: "REACH" };
                }
                if (sub_option && sub_option.value === "app_promotion") {
                    campaign_object.objective = { id: 7, name: 'App promotion', value: "APP_INSTALL" };
                }
                if (sub_option && sub_option.value === "website_conversions") {
                    campaign_object.objective = { id: 6, name: 'Website conversion', value: "CONVERSIONS" };
                }

                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    campaign_object.scheduler.schedule = false;
                    campaign_object.scheduler.planer = false;

                    campaign_object.scheduler.order_info = this.state.order;
                }
                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "tiktok",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                name: "Adset",
                gender: { id: 1, name: "All genders", value: "GENDER_UNLIMITED", selected: true },
                ages: [{ id: 1, name: "All ages", value: "ALL", selected: true }],
                budget_mode: { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" }
            };
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }

            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }
            //TRAFFIC
            if ((sub_option && sub_option.value === "traffic") || (existing_campaign && existing_campaign.objective.value === "TRAFFIC")) {
                adgroup_object.goal = { id: 8, name: 'Click', value: "CLICK" };
                adgroup_object.billing_event = { id: 1, name: 'CPC', value: "CPC" };
                adgroup_object.campaign_objective = { value: "TRAFFIC" };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
            }

            //REACH
            if ((sub_option && sub_option.value === "reach") || (existing_campaign && existing_campaign.objective.value === "REACH")) {
                adgroup_object.goal = { id: 10, name: 'Reach', value: "REACH" };
                adgroup_object.billing_event = { id: 2, name: 'CPM', value: "CPM" };
                adgroup_object.auto_placements = { id: 2, name: "No", value: false };
                adgroup_object.placements = { tiktok: true, pangle: false };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
                adgroup_object.campaign_objective = { value: "REACH" };
                adgroup_object.frequency_cap = { id: 1, name: "Show ads no more than 3 times every 7 days", value: "3_per_7" };
                adgroup_object.frequency = 3;
                adgroup_object.frequency_schedule = 7;


            }

            //APP PROMOTION
            if ((sub_option && sub_option.value === "app_promotion") || (existing_campaign && existing_campaign.objective.value === "APP_INSTALL")) {
                adgroup_object.goal = { id: 8, name: 'Click', value: "CLICK" };
                adgroup_object.billing_event = { id: 1, name: 'CPC', value: "CPC" };
            }

            //WEBSITE CONVERSION
            if ((sub_option && sub_option.value === "website_conversions") || (existing_campaign && existing_campaign.objective.value === "CONVERSIONS")) {
                adgroup_object.goal = { id: 5, name: 'Conversion', value: "CONVERT" };
                adgroup_object.billing_event = { id: 4, name: 'OCPM', value: "OCPM" };
                adgroup_object.campaign_objective = { value: "CONVERSIONS" };
                adgroup_object.targeting_mode = { id: 2, name: "Custom targeting", value: "custom" };
            }

            let buffer_campaign = this.state.campaigns.find(campaign => campaign.id === adgroup_object.campaign);
            if (buffer_campaign.budget_optimization && buffer_campaign.budget_optimization.value) {
                adgroup_object.campaign_budget_optimization = true;
                adgroup_object.campaign_budget = {
                    mode: (typeof buffer_campaign.budget_mode === "string") ? buffer_campaign.budget_mode : (buffer_campaign.budget_mode && buffer_campaign.budget_mode.value ? buffer_campaign.budget_mode.value : "---"),
                    value: (((typeof buffer_campaign.budget_mode === "string") && buffer_campaign.budget_mode === "BUDGET_MODE_DAY") || buffer_campaign.budget_mode.value === "BUDGET_MODE_DAY") ? buffer_campaign.daily_budget : ((((typeof buffer_campaign.budget_mode === "string") && buffer_campaign.budget_mode === "BUDGET_MODE_TOTAL") || buffer_campaign.budget_mode.value === "BUDGET_MODE_TOTAL" ) ? buffer_campaign.total_budget : "?")
                };
            }

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onNewSnapchat: async (existing_campaign, sub_option) => {
            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {

                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "snapchat",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                    status: { id: 2, name: "Paused", value: "PAUSED" },

                };

                if (sub_option && sub_option.value === "AWARENESS_AND_ENGAGEMENT") {
                    campaign_object.objective = { id: 1, name: 'Awareness & Engagement', value: "AWARENESS_AND_ENGAGEMENT" };
                }

                if (sub_option && sub_option.value === "TRAFFIC") {
                    campaign_object.objective = { id: 4, name: 'Traffic', value: "TRAFFIC" };
                }

                if (sub_option && sub_option.value === "LEADS") {
                    campaign_object.objective = { id: 8, name: 'Leads', value: "LEADS" };
                }


                /*
                if (sub_option && sub_option.value === "promote_places") {
                    campaign_object.objective = { id: 2, name: 'Promote places', value: "PROMOTE_PLACES" };
                }
                if (sub_option && sub_option.value === "app_installs") {
                    campaign_object.objective = { id: 3, name: 'App installs', value: "APP_INSTALL" };
                }
                if (sub_option && sub_option.value === "traffic_app") {
                    campaign_object.objective = { id: 5, name: 'Drive Traffic To App', value: "BRAND_AWARENESS" };
                }

                if (sub_option && sub_option.value === "engagement") {
                    campaign_object.objective = { id: 6, name: 'Engagement', value: "ENGAGEMENT" };
                }

                if (sub_option && sub_option.value === "video_views") {
                    campaign_object.objective = { id: 7, name: 'Video Views', value: "VIDEO_VIEW" };
                }
               
                if (sub_option && sub_option.value === "app_conversions") {
                    campaign_object.objective = { id: 9, name: 'App Conversions', value: "APP_CONVERSION" };
                }
                if (sub_option && sub_option.value === "web_conversion") {
                    campaign_object.objective = { id: 10, name: 'Website Conversions', value: "WEB_CONVERSION" };
                }
                if (sub_option && sub_option.value === "catalog_sales") {
                    campaign_object.objective = { id: 11, name: 'Catalog Sales', value: "CATALOG_SALES" };
                }
                */

                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "snapchat",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                status: { id: 1, name: "Active", value: "ACTIVE" },
                billing_event: { id: 1, name: "Impressions", value: "IMPRESSION" },
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.endDate).format("YYYY-MM-DD") : (this.state.order.endDate ? moment().format("YYYY-MM-DD") : null),
                name: "Adset",
                // placement_v2 
                targeting: {},
                type: { id: 1, name: "Snap Ads", value: "SNAP_ADS" },
                bid_strategy: { id: 1, name: "Auto-Bid (Recommended)", value: "AUTO_BID" },
                budget_mode: { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAILY" },
                min_age: { id: 18, name: "18", value: 18 },
                max_age: { id: 50, name: "50+", value: 50 },
                gender: { id: 1, name: "All genders", value: "ALL" },
                auto_placements: { id: 1, name: "Yes", value: true },

            };
            if (this.state.wizard.partial) {
                adgroup_object.partial = true;
            }
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }
            //AWARENESS
            if ((sub_option && sub_option.value === "AWARENESS_AND_ENGAGEMENT") || (existing_campaign && (existing_campaign.objective.value === "BRAND_AWARENESS" || existing_campaign.objective === "BRAND_AWARENESS" || existing_campaign.objective === "AWARENESS_AND_ENGAGEMENT" || existing_campaign.objective === "AWARENESS_AND_ENGAGEMENT"))) {
                adgroup_object.campaign_objective = { value: "AWARENESS_AND_ENGAGEMENT" };
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };



            }
            // TRAFFIC
            if ((sub_option && sub_option.value === "TRAFFIC") || (existing_campaign && (existing_campaign.objective.value === "WEB_VIEW" || existing_campaign.objective === "WEB_VIEW" || existing_campaign.objective.value === "TRAFFIC" || existing_campaign.objective === "TRAFFIC"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "TRAFFIC" };
            }

            // LEADS
            if ((sub_option && sub_option.value === "LEADS") || (existing_campaign && (existing_campaign.objective.value === "LEAD_GENERATION" || existing_campaign.objective === "LEAD_GENERATION" || existing_campaign.objective.value === "LEADS" || existing_campaign.objective === "LEADS"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "LEADS" };
            }


            //PROMOTE PLACES
            /*
            if ((sub_option && sub_option.value === "promote_places") || (existing_campaign && (existing_campaign.objective.value === "PROMOTE_PLACES" || existing_campaign.objective === "PROMOTE_PLACES"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Swipe Up', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "PROMOTE_PLACES" };
            }*/
            /////////////////////////
            /////////////////////////
            /////////////////////////
            // APP INSTALLS
            /*
            if ((sub_option && sub_option.value === "app_installs") || (existing_campaign && existing_campaign.objective.value === "APP_INSTALLS")) {
                adgroup_object.optimization_goal = { id: 3, name: 'Swipe Up', value: "SWIPES" };
                // adgroup_object.campaign_objective = { value: "APP_INSTALLS" };
            }*/


            // TRAFFIC APP
            /*
            if ((sub_option && sub_option.value === "traffic_app") || (existing_campaign && existing_campaign.objective.value === "TRAFFIC_APP")) {
                adgroup_object.optimization_goal = { id: 3, name: 'Swipe Up', value: "SWIPES" };
                // adgroup_object.campaign_objective = { value: "TRAFFIC_WEBSITE" };
            }*/

            // ENGAGEMENT
            /*
            if ((sub_option && sub_option.value === "engagement") || (existing_campaign && (existing_campaign.objective.value === "ENGAGEMENT" || existing_campaign.objective === "ENGAGEMENT"))) {
                adgroup_object.optimization_goal = { id: 2, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "ENGAGEMENT" };
            }*/

            // VIDEO VIEWS
            /*
            if ((sub_option && sub_option.value === "video_views") || (existing_campaign && (existing_campaign.objective.value === "VIDEO_VIEWS" || existing_campaign.objective === "VIDEO_VIEWS"))) {
                adgroup_object.optimization_goal = { id: 5, name: '15 second video view', value: "VIDEO_VIEWS_15_SEC" };
                adgroup_object.campaign_objective = { value: "VIDEO_VIEWS" };
            }
*/


            // APP CONVERSIONS
            /*if ((sub_option && sub_option.value === "app_conversion") || (existing_campaign && existing_campaign.objective.value === "APP_CONVERSION")) {
                adgroup_object.optimization_goal = { id: 3, name: 'Swipe Up', value: "SWIPES" };
                // adgroup_object.campaign_objective = { value: "TRAFFIC_WEBSITE" };
            }*/

            // WEBSITE CONVERSIONS
            /*
            if ((sub_option && sub_option.value === "web_conversion") || (existing_campaign && (existing_campaign.objective.value === "WEB_CONVERSION" || existing_campaign.objective === "WEB_CONVERSION"))) {
                adgroup_object.optimization_goal = { id: 3, name: 'Click', value: "SWIPES" };
                adgroup_object.campaign_objective = { value: "WEB_CONVERSION" };
                // let response = await this.functions.getSnapchatPixel();
                // adgroup_object.snapchat_pixel = response.data
                // adgroup_object.snapchat_pixel_string = snapchat_pixel_data[0].name;
            }
                */

            // CATALOG SALES
            /*
            if ((sub_option && sub_option.value === "catalog_sales") || (existing_campaign && existing_campaign.objective.value === "CATALOG_SALES")) {
                adgroup_object.optimization_goal = { id: 3, name: 'Swipe Up', value: "SWIPES" };
                // adgroup_object.campaign_objective = { value: "TRAFFIC_WEBSITE" };
            }*/

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onNewTwitter: async (existing_campaign, sub_option) => {

            let campaign_id = Math.floor((Math.random() * 99999999999999) + 1);
            let adgroup_id = Math.floor((Math.random() * 99999999999999) + 1);

            if (!existing_campaign) {
                let campaign_object = {
                    id: campaign_id,
                    campaign_type: sub_option ? sub_option.name : "",
                    campaign_type_property: "regular",
                    level: 'campaign',
                    channel: "twitter",
                    name: this.state.wizard.name,
                    start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    budget_optimization: { id: 1, name: "No", value: false }
                };
                if (sub_option && sub_option.value === "app_engagement") {
                    campaign_object.objective = { id: 1, name: "App re-engagements", value: "APP_ENGAGEMENTS" };
                } else if (sub_option && sub_option.value === "app_installs") {
                    campaign_object.objective = { id: 2, name: "App installs", value: "APP_INSTALLS" };
                } else if (sub_option && sub_option.value === "reach") {
                    campaign_object.objective = { id: 3, name: "Reach", value: "REACH" };
                } else if (sub_option && sub_option.value === "followers") {
                    campaign_object.objective = { id: 4, name: "Followers", value: "FOLLOWERS" };
                } else if (sub_option && sub_option.value === "engagements") {
                    campaign_object.objective = { id: 5, name: "Engagements", value: "ENGAGEMENTS" };
                } else if (sub_option && sub_option.value === "video_views") {
                    campaign_object.objective = { id: 6, name: "Video views", value: "VIDEO_VIEWS" };
                } else if (sub_option && sub_option.value === "website_clicks") {
                    campaign_object.objective = { id: 8, name: "Website traffic", value: "WEBSITE_CLICKS" };
                }

                if (this.state.wizard.scheduler) {
                    campaign_object.scheduler = this.state.wizard.scheduler;
                    if (this.state.wizard.scheduler.planer) {
                        campaign_object.budget_optimization = { id: 2, name: "Yes", value: true };
                    }
                    campaign_object.scheduler.order_info = this.state.order;
                }

                this.state.campaigns.push(campaign_object);
            }

            let adgroup_object = {
                id: adgroup_id,
                level: 'adgroup',
                channel: "twitter",
                campaign: existing_campaign ? existing_campaign.id : campaign_id,
                start_date: this.state.order.startDate && moment(this.state.order.startDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                end_date: this.state.order.endDate && moment(this.state.order.endDate).isAfter(moment()) ? moment(this.state.order.startDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                name: "Adset",
                adgroup_budget_optimization: { id: 2, name: "No", value: false },
                daily_budget: 0,
                total_budget: 0,
                gender: { id: 1, name: "All genders", value: null },
                age_min: { id: 1, name: "13", value: 13 },
                age_max: { id: 5, name: "And up", value: null }
            };
            if (this.state.wizard.scheduler) {
                adgroup_object.scheduler = this.state.wizard.scheduler;
                if (this.state.wizard.scheduler.schedule) {
                    adgroup_object.start_date = this.state.wizard.scheduler.start_date;
                }
                adgroup_object.scheduler.order_info = this.state.order;
            }

            //APP ENGAGEMENT
            if ((sub_option && sub_option.value === "app_engagement") || (existing_campaign && existing_campaign.objective.value === "APP_ENGAGEMENTS")) {
                adgroup_object.objective = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "App Clicks", value: "APP_CLICKS" };
                adgroup_object.goals = [
                    { id: 1, name: "App Clicks", value: "APP_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "App clicks", value: "APP_CLICK" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "App clicks", value: "APP_CLICK" }
                ];
            }

            //APP INSTALL
            if ((sub_option && sub_option.value === "app_installs") || (existing_campaign && existing_campaign.objective.value === "APP_INSTALLS")) {
                adgroup_object.objective = { id: 2, name: "App installs", value: "APP_INSTALLS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "App installs (recommended)", value: "APP_INSTALLS" };
                adgroup_object.goals = [
                    { id: 1, name: "App installs (recommended)", value: "APP_INSTALLS" },
                    { id: 2, name: "App Clicks", value: "APP_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" },
                    { id: 2, name: "App clicks", value: "APP_CLICK" }
                ];
            }

            //REACH
            if ((sub_option && sub_option.value === "reach") || (existing_campaign && existing_campaign.objective.value === "REACH")) {
                adgroup_object.objective = { id: 3, name: "Reach", value: "REACH" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Max reach (recommended)", value: "MAX_REACH" };
                adgroup_object.goals = [
                    { id: 1, name: "Max reach (recommended)", value: "MAX_REACH" },
                    { id: 2, name: "Reach with engagement", value: "REACH_WITH_ENGAGEMENT" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions", value: "IMPRESSION" },
                ];
            }

            //FOLLOWERS
            if ((sub_option && sub_option.value === "followers") || (existing_campaign && existing_campaign.objective.value === "FOLLOWERS")) {
                adgroup_object.objective = { id: 4, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.goals = [
                    { id: 1, name: "Followers", value: "FOLLOWERS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Followers", value: "FOLLOWERS" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Followers", value: "FOLLOWERS" },
                ];
            }

            //ENGAGEMENTS
            if ((sub_option && sub_option.value === "engagements") || (existing_campaign && existing_campaign.objective.value === "ENGAGEMENTS")) {
                adgroup_object.objective = { id: 5, name: "Engagements", value: "ENGAGEMENTS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Engagement", value: "ENGAGEMENT" };
                adgroup_object.goals = [
                    { id: 1, name: "Engagement", value: "ENGAGEMENT" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Engagement", value: "ENGAGEMENT" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Engagement", value: "ENGAGEMENT" },
                ];
            }

            //VIDEO VIEWS
            if ((sub_option && sub_option.value === "video_views") || (existing_campaign && existing_campaign.objective.value === "VIDEO_VIEWS")) {
                adgroup_object.objective = { id: 6, name: "Video views", value: "VIDEO_VIEWS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "15s video views (recommended)", value: "VIEW_15S" };
                adgroup_object.goals = [
                    { id: 1, name: "15s video views (recommended)", value: "VIEW_15S" },
                    { id: 2, name: "Video views", value: "VIDEO_VIEW" },
                    { id: 3, name: "3s/100% video views", value: "VIEW_3S_100PCT" },
                    { id: 4, name: "6s video views", value: "VIEW_6S" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions", value: "IMPRESSION" },
                ];
            }

            //WEBSITE CLICKS
            if ((sub_option && sub_option.value === "website_clicks") || (existing_campaign && existing_campaign.objective.value === "WEBSITE_CLICKS")) {
                adgroup_object.objective = { id: 8, name: "Website clicks", value: "WEBSITE_CLICKS" };
                adgroup_object.goal = { id: 1, name: "App engagements", value: "APP_ENGAGEMENTS" };
                adgroup_object.bid_strategy = { id: 1, name: "Autobid (recommended)", value: "AUTO" };
                adgroup_object.pacing = { id: 1, name: "Standard (recommended)", value: true };
                adgroup_object.goal = { id: 1, name: "Site visits (recommended)", value: "SITE_VISITS" };
                adgroup_object.goals = [
                    { id: 1, name: "Site visits (recommended)", value: "SITE_VISITS" },
                    { id: 2, name: "Link clicks", value: "LINK_CLICKS" }
                ];
                adgroup_object.pay_by = { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" };
                adgroup_object.pay_bys = [
                    { id: 1, name: "Impressions (recommended)", value: "IMPRESSION" },
                    { id: 2, name: "Link click", value: "LINK_CLICK" }
                ];
            }

            this.state.adgroups.push(adgroup_object);

            await this.promisedSetState({
                campaigns: this.state.campaigns,
                adgroups: this.state.adgroups
            });
            this.functions.checkChannels();
            await this.form.set();
            this.props.setSteps();
        },
        onClone: async (level) => {

            let self = this;

            if (level === "campaign") {
                this.state.campaigns.map((campaign) => {
                    if (campaign.selected) {
                        update(campaign);
                    }
                });
            } else {
                this.state.adgroups.map((adgroup) => {
                    if (adgroup.selected) {
                        update(adgroup);
                    }
                });
            }

            function update(item) {
                if (item) {
                    let new_object = JSON.parse(JSON.stringify(item));
                    new_object.id = Math.floor((Math.random() * 9999999999) + 1);
                    if (new_object.level === 'campaign') {
                        new_object.selected = false;
                        self.state.campaigns.push(new_object);
                        let adgroups = self.state.adgroups.filter((adgroup) => {
                            return adgroup.campaign === item.id;
                        });
                        adgroups = JSON.parse(JSON.stringify(adgroups));
                        adgroups = adgroups.map((item) => {
                            item.selected = false;
                            item.name = item.name;
                            let ads = self.state.ads.filter((ad) => {
                                return ad.adgroup === item.id;
                            });
                            ads = JSON.parse(JSON.stringify(ads));
                            item.campaign = new_object.id;
                            item.id = Math.floor((Math.random() * 9999999999) + 1);
                            ads = ads.map((inner_item) => {
                                inner_item.id = Math.floor((Math.random() * 9999999999) + 1);
                                inner_item.adgroup = item.id;
                                inner_item.campaign = item.campaign;
                                return inner_item
                            });
                            self.state.ads = self.state.ads.concat(ads);
                            return item
                        });
                        self.state.adgroups = self.state.adgroups.concat(adgroups);
                    } else {
                        new_object.selected = false;
                        self.state.adgroups.push(new_object);
                        let ads = self.state.ads.filter((ad) => {
                            return ad.adgroup === item.id;
                        });
                        ads = JSON.parse(JSON.stringify(ads));
                        ads = ads.map((item) => {
                            item.campaign = new_object.campaign;
                            item.adgroup = new_object.id;
                            item.id = Math.floor((Math.random() * 9999999999) + 1);
                            return item
                        });
                        self.state.ads = self.state.ads.concat(ads);
                    }
                    self.promisedSetState({
                        campaigns: self.state.campaigns,
                        adgroups: self.state.adgroups,
                        ads: self.state.ads
                    });
                    self.form.set();
                }
            }

        },
        onSubmit: () => {
            try {
                if (this.state.update.channel === "facebook" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderFacebookCampaign.functions.update();
                } else if (this.state.update.channel === "facebook" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderFacebookAdgroup.functions.update();
                } else if (this.state.update.channel === "twitter" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderTwitterCampaign.functions.update();
                } else if (this.state.update.channel === "twitter" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderTwitterAdgroup.functions.update();
                } else if (this.state.update.channel === "tiktok" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderTiktokCampaign.functions.update();
                } else if (this.state.update.channel === "tiktok" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderTiktokAdgroup.functions.update();
                } else if (this.state.update.channel === "snapchat" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderSnapchatCampaign.functions.update();
                } else if (this.state.update.channel === "snapchat" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderSnapchatAdgroup.functions.update();
                } else if (this.state.update.channel === "linkedin" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderLinkedinCampaign.functions.update();
                } else if (this.state.update.channel === "linkedin" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderLinkedinAdgroup.functions.update();
                } else if (this.state.update.channel === "google" && this.state.update.level === "campaign") {
                    this.refs.CreateOrderGoogleCampaign.functions.update();
                } else if (this.state.update.channel === "google" && this.state.update.level === "adgroup") {
                    this.refs.CreateOrderGoogleAdgroup.functions.update();
                }

                if (this.state.edit && this.state.edit.channel === "facebook") {
                    if (this.state.edit.level === "campaign") {
                        this.refs.EditLiveFacebookCampaign.functions.update();
                    } else if (this.state.edit.level === "adgroup") {
                        this.refs.EditLiveFacebookAdgroup.functions.update();
                    }
                } else if (this.state.edit && this.state.edit.channel === "tiktok") {
                    if (this.state.edit.level === "campaign") {
                        this.refs.EditLiveTikTokCampaign.functions.update();
                    } else if (this.state.edit.level === "adgroup") {
                        this.refs.EditLiveTikTokAdgroup.functions.update();
                    }
                } else if (this.state.edit && this.state.edit.channel === "snapchat") {
                    if (this.state.edit.level === "campaign") {
                        this.refs.EditLiveSnapchatCampaign.functions.update();
                    } else if (this.state.edit.level === "adgroup") {
                        this.refs.EditLiveSnapchatAdgroup.functions.update();
                    }
                }

            } catch (error) {
                console.log(error);
            }
        },

        information: (level) => {
            if ((this.state[level + 's'].filter((property) => { return property.selected }).length > 0)) {
                let update_value = {};
                if (this.state[level + 's'].filter((property) => { return property.selected }).length > 1) {
                    update_value = {
                        id: this.state[level + 's'].filter((property) => { return property.selected }).length > 1 ? "multiple" : this.state[level + 's'].filter((property) => { return property.selected })[0].id,
                        channel: this.state[level + 's'].filter((property) => { return property.selected })[0].channel,
                        level: level,
                        multiple: this.state[level + 's'].filter((property) => { return property.selected }).length > 1,
                        ids: this.state[level + 's'].filter((property) => { return property.selected }).map((item) => { return item.id })
                    }
                } else {
                    update_value = this.state[level + 's'].filter((property) => { return property.selected })[0];
                }
                console.log(update_value, "2");
                this.promisedSetState({
                    edit: update_value,
                    disabled: true
                })
            }
        },
        onEdit: (level) => {
            if ((this.state[level + 's'].filter((property) => { return property.selected }).length > 0)) {
                let update_value = {};
                if (this.state[level + 's'].filter((property) => { return property.selected }).length > 1) {
                    update_value = {
                        id: this.state[level + 's'].filter((property) => { return property.selected }).length > 1 ? "multiple" : this.state[level + 's'].filter((property) => { return property.selected })[0].id,
                        channel: this.state[level + 's'].filter((property) => { return property.selected })[0].channel,
                        level: level,
                        multiple: this.state[level + 's'].filter((property) => { return property.selected }).length > 1,
                        ids: this.state[level + 's'].filter((property) => { return property.selected }).map((item) => { return item.id })
                    }
                } else {
                    update_value = this.state[level + 's'].filter((property) => { return property.selected })[0];
                }
                this.promisedSetState({
                    update: update_value
                })
            }
        },
        onUpdateCampaign: async (updateItem) => {
            if (this.state.update.id && this.state.update.id !== "multiple") {
                this.state.campaigns = this.state.campaigns.map((inner_item) => {
                    if (inner_item.id === updateItem.id) {
                        inner_item = updateItem;
                    }
                    return inner_item;
                })
            } else {
                this.state.campaigns = this.state.campaigns.map((inner_item) => {
                    this.state.update.ids.map((id) => {
                        if (inner_item.id === id) {
                            delete updateItem.id;
                            inner_item = { ...inner_item, ...updateItem };
                        }
                    });
                    return inner_item;
                })
            }
            await this.promisedSetState({
                campaigns: this.state.campaigns,
                update: {}
            });
            this.form.set();
        },
        getCampaignsData: async (campaign) => {
            try {
                let campaigns_string = ("&" + campaign.channel + "_campaigns[]=" + campaign.id);
                let response = await this.calls.getCampaignsData(campaigns_string);
                this.state.campaigns = this.state.campaigns.map((campaign) => {
                    if (response.data[campaign.channel]) {
                        response.data[campaign.channel].map((item) => {
                            if (campaign.id === item.id) {
                                campaign = { ...campaign, ...item };
                            }
                        })
                    }
                    return campaign;
                });
                await this.promisedSetState({
                    campaigns: this.state.campaigns
                })
                this.functions.updateWizard();
            } catch (error) {
                this.promisedSetState({
                    error: true
                })
            }
        },
        getAdgroupsData: async (adgroup) => {
            try {
                let adgroups_string = ("&" + adgroup.channel + "_adgroups[]=" + adgroup.id);
                let response = await this.calls.getAdgroupsData(adgroups_string);
                this.state.adgroups = this.state.adgroups.map((adgroup) => {
                    if (response.data[adgroup.channel]) {
                        response.data[adgroup.channel].map((item) => {
                            if (adgroup.id === item.id) {
                                adgroup = { ...adgroup, ...item };
                            }
                        })
                    }
                    return adgroup;
                });
                await this.promisedSetState({
                    adgroups: this.state.adgroups
                });
                this.functions.updateWizard();
            } catch (error) {
                this.promisedSetState({
                    error: true
                })
            }
        },
        getAdgroups: async () => {
            try {
                let campaigns_string = "";
                campaigns_string = campaigns_string + ("&" + this.state.campaigns[0].channel + "_campaigns[]=" + this.state.campaigns[0].id);
                let response = await this.calls.getAdgroups(campaigns_string);
                let adgroups = [];
                for (let channel in response.data) {
                    adgroups = adgroups.concat(response.data[channel]);
                }
                await this.promisedSetState({
                    adgroups: adgroups,
                });
                //this.functions.getAdgroupsData();
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    loading_partial: false
                });
            }
        },
        onUpdateAdgroup: async (updateItem) => {
            if (this.state.update.id && this.state.update.id !== "multiple") {
                this.state.adgroups = this.state.adgroups.map((inner_item) => {
                    if (inner_item.id === updateItem.id) {
                        inner_item = updateItem;
                    }
                    return inner_item;
                })
            } else {
                this.state.adgroups = this.state.adgroups.map((inner_item) => {
                    this.state.update.ids.map((id) => {
                        if (inner_item.id === id) {
                            delete updateItem.id;
                            inner_item = { ...inner_item, ...updateItem };
                        }
                    });
                    return inner_item;
                })
            }
            await this.promisedSetState({
                adgroups: this.state.adgroups,
                update: {}
            });
            this.form.set();
        },
        createTemplate: async () => {
            await this.promisedSetState({ loading_template: true, saved_success: false, create_template: false });
            try {
                await this.calls.createWizard({
                    template: true,
                    name: this.state.template_name,
                    template_data: {
                        campaigns: this.state.campaigns,
                        adgroups: this.state.adgroups,
                        ads: this.state.ads,
                        channels: this.state.channels,
                        creatives: this.state.creatives,
                        assetItems: this.state.assetItems
                    }
                });
                this.props.history.push("/v2/templates");
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_template: false });
        },
        updateWizard: async () => {
            await this.promisedSetState({ loading_wizard: true });
            try {
                await this.calls.updateWizard({
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups,
                    ads: this.state.ads,
                    channels: this.state.channels,
                    creatives: this.state.creatives,
                    assetItems: this.state.assetItems,
                    ...(this.state.order.budgetSplit && this.state.order.budgetSplit.value && { bingSync: this.state.wizard.bingSync }),
                });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        getWizard: async (id) => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getWizard(id);
                await this.promisedSetState({
                    wizard: response.data.wizard ? response.data.wizard : this.state.wizard,
                    client: response.data.client ? response.data.client : this.state.client,
                    order: response.data.order ? response.data.order : this.state.order,
                    creatives: response.data.creatives ? response.data.creatives : this.state.creatives,
                    assetItems: response.data.assetItems ? response.data.assetItems : this.state.assetItems,
                    campaigns: response.data.campaigns ? response.data.campaigns : this.state.campaigns,
                    adgroups: response.data.adgroups ? response.data.adgroups : this.state.adgroups,
                    ads: response.data.ads ? response.data.ads : this.state.ads,
                    channels: response.data.channels ? response.data.channels : this.state.channels
                });
                if (this.state.wizard.partial) {
                    /*
                    this.functions.getCampaignsData(this.state.campaigns[0]);
                    this.functions.getAdgroups();
                    if (Array.isArray(this.state.campaigns) && this.state.campaigns.length > 0 && this.state.options.filter((item) => { return item.value === this.state.campaigns[0].channel }).length > 0) {
                        await this.promisedSetState({
                            option: this.state.options.filter((item) => { return item.value === this.state.campaigns[0].channel })[0]
                        });
                    }
                    */
                }
                if (this.state.order.googleBudgets && this.state.order.googleBudgets.length > 0) {
                    let additional = false
                    if (this.state.order.googleBudgets.map((item) => { if (!Object.keys(item).includes("resourceName")) { return true } else return false }).filter((item) => { return item }).length > 0) {
                        additional = true
                    }
                    if (additional) {
                        await this.functions.getGoogleBudgets();
                    }
                }
                await this.functions.getAdditionalInfo();
                //REMOVE DRAFT ID FROM QUERY
                //window.location.href =  window.location.href.split("?")[0];
            } catch (error) {
                console.log(error);
            }
            await this.form.set();
            await this.promisedSetState({ loading: false });
            this.props.setSteps();
            this.functions.checkChannels();

            /*
            //ADD ADS TAB IF ERROR
            if (this.state.wizard && this.state.wizard.status === "error") {
                this.state.tabs.push({ id: 3, name: 'Ads', value: 'ads', icon: NewspaperIcon });
                this.setState({
                    tabs: this.state.tabs
                });
            }
            */

        },
        getCreatives: async () => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.creatives();
                await this.promisedSetState({ creatives: response.data.creatives, meta: response.meta });
                await this.form.set();
                this.props.setSteps();
            } catch (error) { }
            await this.promisedSetState({ loading: false });
        },
        getSnapchatPixel: async () => {
            try {
                let response = await this.calls.getSnapchatPixel();
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },
    };

    renders = {
        name: (item) => {
            return item.name;
        },
        campaignName: (item) => {
            let name = "";
            this.state.campaigns.map((campaign) => {
                if (campaign.id == item.campaign || campaign.id == item.campaign_id) {
                    name = campaign.name;
                }
            })
            return name;
        },
        adgroupName: (item) => {
            let name = "";
            this.state.adgroups.map((adgroup) => {
                if (adgroup.id == item.adgroup) {
                    name = adgroup.name;
                }
            })
            return name;
        },
        channel: (item) => {
            return item.channel;
        },
        campaignType: (item) => {
            return item.campaign_type;
        },
        objectiveGoal: (item) => {
            if (item.channel === "facebook") {
                return item.objective ? item.objective.name : "-";
            } else if (item.channel === "tiktok") {
                return item.objective ? item.objective.name : "-";
            } else if (item.channel === "snapchat") {
                return "-";//item.objective ? item.objective.name : "-";
            } else if (item.channel === "twitter") {
                return item.objective ? item.objective.name : "-";
            } else if (item.channel === "google") {
                return item.strategy_type ? item.strategy_type.name : "-";
            }
        },
        optimization: (item) => {
            if (item.channel === "facebook") {
                return item.optimization_goal ? item.optimization_goal.name : "-";
            } else if (item.channel === "tiktok") {
                return item.goal ? item.goal.name : "-";
            } else if (item.channel === "twitter") {
                return item.goal ? item.goal.name : "-";
            } else if (item.channel === "google") {
                return "-";
            } else if (item.channel === "snapchat") {
                if (typeof item.optimization_goal === "string") {
                    return item.optimization_goal.replace(/_/g, " ");
                } else return item.optimization_goal ? item.optimization_goal.name : "-";
            } else if (item.channel === "linkedin") {
                return item.optimization_goal ? item.optimization_goal.name : "-";
            }
        },
        budget: (item, bing = false) => {
            let currency = [{ currency: "" }];
            if (this.state.client.channels.filter((inner_item) => { return inner_item.value === item.channel }).length > 0) {
                currency = this.state.client.channels.filter((inner_item) => { return inner_item.value === item.channel });
            }
            if (item.channel === "facebook") {
                if (item.level === 'campaign') {
                    if (item.budget_optimization && item.budget_optimization.value) {
                        if (!item.budget_optimization_type || (item.budget_optimization_type && item.budget_optimization_type.id == 1)) {
                            return item.daily_budget + " " + currency[0].currency + " / Daily";
                        } else if (item.budget_optimization_type && item.budget_optimization_type.id == 2) {
                            return item.lifetime_budget + " " + currency[0].currency + " / Lifetime";
                        } else {
                            return "-"
                        }
                    } else {
                        return "-"
                    }
                } else {
                    if (item.adset_budget && item.adset_budget.value) {
                        if (!item.budget_type || (item.budget_type && item.budget_type.id == 1)) {
                            return item.daily_budget + " " + currency[0].currency + " / Daily";
                        } else if (item.budget_type && item.budget_type.id == 2) {
                            return item.lifetime_budget + " " + currency[0].currency + " / Lifetime";
                        } else {
                            return "-"
                        }
                    } else {
                        return "-"
                    }
                }
            } else if (item.channel === "google") {
                if (item.level === 'campaign') {
                    if (bing && item.budget) {
                        return round(item.budget * +item.bingSync.bing_percentage_from_google / 100) + " " + currency[0].currency + " / Daily";
                    } else {
                        if (item.shared_budget) {
                            return item.shared_budget.amount + " " + currency[0].currency + " / Daily";
                        } else if (item.budget) {
                            return item.budget + " " + currency[0].currency + " / Daily";
                        } else {
                            return "-"
                        }
                    }
                } else {
                    return "-"
                }
            } else if (item.channel === "tiktok") {
                if (item.level === 'campaign') {
                    if ((item.budget_optimization && item.budget_optimization.value) || item.budget_limit && item.budget_limit.id == 2) {
                        if (item.budget_mode.id == 1) {
                            return item.daily_budget + " " + currency[0].currency + " / Daily";
                        } else return item.total_budget + " " + currency[0].currency + " / Lifetime";
                    } else {
                        return "-"
                    }
                } else {
                    if (!item.campaign_budget_optimization) {
                        if (item.budget_mode && item.budget_mode.value) {
                            if (!item.budget_mode || (item.budget_mode && item.budget_mode.id == 1)) {
                                return (item.daily_budget ? item.daily_budget : 0) + " " + currency[0].currency + " / Daily";
                            } else {
                                return (item.total_budget ? item.total_budget : 0) + " " + currency[0].currency + " / Lifetime";
                            }
                        } else {
                            return "-"
                        }
                    } else {
                        return "Uses Campaign Budget";
                    }
                }
            } else if (item.channel === "snapchat") {
                if (item.level === 'campaign') {
                    if ((item.has_lifetime_cap && item.has_lifetime_cap.value) || (item.has_daily_cap && item.has_daily_cap.value)) {
                        if ((item.has_lifetime_cap && item.has_lifetime_cap.value) && (item.has_daily_cap && item.has_daily_cap.value)) {
                            // return item.daily_spend_cap + " & " + item.lifetime_spend_cap + " " + currency[0].currency + "/Daily&Lifetime";
                            return "Multiple"
                        } else if (item.has_daily_cap && item.has_daily_cap.value) {
                            return item.daily_spend_cap + " " + currency[0].currency + " / Daily";
                        } else if (item.has_lifetime_cap && item.has_lifetime_cap.value) {
                            return item.lifetime_spend_cap + " " + currency[0].currency + " / Lifetime";
                        } else {
                            return "-"
                        }
                    } else if (item.lifetime_spend_cap_micro || item.daily_budget_micro) {
                        if (item.lifetime_spend_cap_micro && item.daily_budget_micro) {
                            return "Multiple"
                        } else if (item.daily_budget_micro) {
                            return (+item.daily_spend_cap / 1000000) + " " + currency[0].currency + " / Daily";
                        } else if (item.lifetime_spend_cap_micro) {
                            return (+item.lifetime_spend_cap_micro / 1000000) + " " + currency[0].currency + " / Lifetime";
                        } else {
                            return "-"
                        }
                    } else {
                        return "-"
                    }
                } else {
                    if (item.budget_mode && item.budget_mode.value) {
                        if (!item.budget_mode || (item.budget_mode && item.budget_mode.id == 1)) {
                            return (item.daily_budget ? item.daily_budget : 0) + " " + currency[0].currency + " / Daily";
                        } else {
                            return (item.lifetime_budget ? item.lifetime_budget : 0) + " " + currency[0].currency + " / Lifetime";
                        }
                    } else if (item.daily_budget_micro || item.lifetime_budget_micro) {
                        if (item.daily_budget_micro) {
                            return (+item.daily_budget_micro / 1000000) + " " + currency[0].currency + " / Daily";
                        } else if (item.lifetime_budget_micro) {
                            return (+item.lifetime_budget_micro / 1000000) + " " + currency[0].currency + " / Lifetime";
                        } else {
                            return "-"
                        }
                    } else {
                        return "-"
                    }
                }
            } else if (item.channel === "linkedin") {
                if (item.level === 'campaign') {
                    if (item.campaign_budget && item.campaign_budget.value) {
                        return item.total_budget + " " + currency[0].currency + " / Lifetime";
                    } else {
                        return "-"
                    }
                } else {
                    let budget_string = "";
                    if (item.adgroup_budget && item.adgroup_budget.id === 1) {
                        budget_string = item.daily_budget + " " + currency[0].currency + " / Daily";
                    } else if (item.adgroup_budget && item.adgroup_budget.id === 2) {
                        budget_string = item.total_budget + " " + currency[0].currency + " / Lifetime";
                    } else if (item.adgroup_budget && item.adgroup_budget.id === 3) {
                        budget_string = item.daily_budget + " & " + item.total_budget + " " + currency[0].currency + " / Daily & Lifetime";
                    }
                    return budget_string
                }
            } else if (item.channel === "twitter") {
                if (item.level === 'campaign') {
                    if (item.budget_optimization && item.budget_optimization.value) {
                        if (item.daily_budget || item.total_budget) {
                            if (item.daily_budget && item.total_budget && item.daily_budget !== "0" && item.total_budget !== "0") {
                                return item.daily_budget + " & " + item.total_budget + " " + currency[0].currency + " / Daily & Lifetime";
                            } else if (item.daily_budget && item.daily_budget !== "0") {
                                return item.daily_budget + " " + currency[0].currency + " / Daily";
                            } else if (item.total_budget && item.total_budget !== "0") {
                                return item.total_budget + " " + currency[0].currency + " / Lifetime";
                            } else {
                                return "-"
                            }
                        } else {
                            return "-"
                        }
                    } else {
                        return "-"
                    }
                } else {
                    let budget_string = "";
                    if (item.adgroup_budget_optimization && item.adgroup_budget_optimization.value) {
                        if (item.daily_budget || item.total_budget) {
                            if (item.daily_budget && item.total_budget && item.daily_budget !== "0" && item.total_budget !== "0") {
                                budget_string = item.daily_budget + " & " + item.total_budget + " " + currency[0].currency + " / Daily & Lifetime";
                            } else if (item.daily_budget && item.daily_budget !== "0") {
                                budget_string = item.daily_budget + " " + currency[0].currency + " / Daily";
                            } else if (item.total_budget && item.total_budget !== "0") {
                                budget_string = item.total_budget + " " + currency[0].currency + " / Lifetime";
                            } else {
                                budget_string = "-"
                            }
                        } else {
                            budget_string = "-"
                        }
                    }
                    return budget_string
                }
            } else {
                return "-"
            }
        },
        startDate: (item) => {
            if (item.channel === "facebook") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    return moment(item.start_date).format("YYYY-MM-DD");
                }
            } else if (item.channel === "tiktok") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    return moment(item.start_date).format("YYYY-MM-DD");
                }
            } else if (item.channel === "snapchat") {
                if (item.level === "campaign") {
                    if (item.start_date) {
                        return moment(item.start_date).format("YYYY-MM-DD");
                    } else if (item.start) {
                        return moment(item.start).format("YYYY-MM-DD");
                    }
                } else if (item.level === "adgroup") {
                    if (item.start_date) {
                        return moment(item.start_date).format("YYYY-MM-DD");
                    } else if (item.start) {
                        return moment(item.start).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "linkedin") {
                if (item.level === "campaign") {
                    if (item.start_date) {
                        return moment(item.start_date).format("YYYY-MM-DD");
                    } else if (item.start) {
                        return moment(item.start).format("YYYY-MM-DD");
                    }
                } else if (item.level === "adgroup") {
                    if (item.start_date) {
                        return moment(item.start_date).format("YYYY-MM-DD");
                    } else if (item.start) {
                        return moment(item.start).format("YYYY-MM-DD");
                    }
                }
            } else if (item.channel === "twitter") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    return moment(item.start_date).format("YYYY-MM-DD");
                }
            } else if (item.channel === "google") {
                if (item.level === "campaign") {
                    return item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : "-";
                } else if (item.level === "adgroup") {
                    return item.start_date ? moment(item.start_date).format("YYYY-MM-DD") : "-";
                }
            }
        },
        endDate: (item) => {
            if (item.channel === "facebook") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "tiktok") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "snapchat") {
                if (item.level === "campaign") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else if (item.end) {
                        return moment(item.end).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                } else if (item.level === "adgroup") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else if (item.end) {
                        return moment(item.end).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "linkedin") {
                if (item.level === "campaign") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else if (item.end) {
                        return moment(item.end).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                } else if (item.level === "adgroup") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else if (item.end) {
                        return moment(item.end).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "twitter") {
                if (item.level === "campaign") {
                    return "-";
                } else if (item.level === "adgroup") {
                    if (item.end_date) {
                        return moment(item.end_date).format("YYYY-MM-DD");
                    } else {
                        return "-";
                    }
                }
            } else if (item.channel === "google") {
                if (item.level === "campaign") {
                    return item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : "-";
                } else if (item.level === "adgroup") {
                    return item.end_date ? moment(item.end_date).format("YYYY-MM-DD") : "-";
                }
            }
        },
        gender: (item) => {
            if (item.gender) {
                return item.gender ? item.gender.name : "-";
            } else {
                return "-";
            }
        },
        age: (item) => {
            if (item.channel === "linkedin") {
                let age_span = "";
                if (Array.isArray(item.age)) {
                    if (item.age.length > 1) {
                        item.age.sort((a, b) => a.id - b.id).map((inner_item, index) => {
                            if (index === 0) {
                                age_span = age_span + inner_item.name.split(" to ")[0];
                            } else if (index === item.age.length - 1) {
                                if (inner_item.id === 4) {
                                    age_span = age_span + "-" + inner_item.name;
                                } else age_span = age_span + "-" + inner_item.name.split(" to ")[1];
                            }
                        });
                    } else {
                        if (typeof item.age[0] === 'object') {
                            age_span = item.age[0].name
                        } else {
                            age_span = "-";
                        }

                    }
                } else {
                    age_span = "-";
                }
                return age_span;
            } else if (item.channel === "facebook") {
                let age_span = "";
                if (item.age_min) {
                    age_span = item.age_min.value
                }
                if (item.age_max) {
                    age_span = age_span + " - " + item.age_max.value;
                }
                return age_span;
            } else if (item.channel === "twitter") {
                let age_span = "";
                if (item.age_min) {
                    age_span = item.age_min.value
                }
                if (item.age_max) {
                    if (item.age_max.id === 5) {
                        age_span = age_span + " - " + item.age_max.name;
                    } else age_span = age_span + " - " + item.age_max.value;
                }
                return age_span;
            } else if (item.channel === "tiktok") {
                let age_span = "";
                if (Array.isArray(item.ages)) {
                    if (item.ages.length > 1) {
                        item.ages.sort((a, b) => a.id - b.id).map((inner_item, index) => {
                            if (index === 0) {
                                age_span = age_span + inner_item.name.split("-")[0];
                            } else if (index === item.ages.length - 1) {
                                if (inner_item.id === 7) {
                                    age_span = age_span + "-" + inner_item.name;
                                } else age_span = age_span + "-" + inner_item.name.split("-")[1];
                            }
                        });
                    } else {
                        age_span = item.ages[0].name ? item.ages[0].name : "-";
                    }
                } else {
                    age_span = "-";
                }
                return age_span;
            } else if (item.channel === "snapchat") {
                let age_span = "";
                if (item.min_age) {
                    age_span = item.min_age.name
                }
                if (item.max_age) {
                    age_span = age_span + " - " + item.max_age.name;
                }
                if (!item.max_age && !item.min_age) {
                    age_span = "-";
                }
                return age_span;
            } else if (item.channel === "google") {
                let age_span = "";
                if (Array.isArray(item.ages)) {
                    if (item.ages.length > 1) {
                        let first = null;
                        let last = null;
                        item.ages.sort((a, b) => a.id - b.id).map((inner_item, index) => {
                            if (index === 0) {
                                first = inner_item.name.split("-")[0];
                            } else {
                                if (inner_item.id !== 7) {
                                    if (inner_item.id == 6) {
                                        last = inner_item.name;
                                    } else {
                                        last = inner_item.name.split("-")[1];
                                    }
                                }
                            }
                        });
                        age_span = first + " - " + last;
                    } else {
                        age_span = item.ages[0].name;
                    }
                } else {
                    age_span = "-";
                }
                return age_span;
            } else {
                return "-";
            }
        },
        locations: (item) => {
            if (Array.isArray(item.locations) && item.locations.length > 0) {
                if (item.locations.length === 1) {
                    return item.locations[0].name;
                } else {
                    return item.locations.length;
                }
            } else {
                return "-";
            }
        },
        errorMessage: (item, level) => {
            let string = "";
            this.state.wizard.errors.map((error) => {
                if (error[level] == item.id && error.level == level && error.channel == item.channel) {
                    string = error.message;
                }
            });
            return string;
        },
        errorExist: (item, level) => {
            let exist = false;
            this.state.wizard.errors.map((error) => {
                if (error[level] == item.id && error.level == level && error.channel == item.channel) {
                    exist = true;
                }
            });
            return exist;
        }
    };

    calls = {
        updateWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateWizard?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        getWizard: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizard?id=" + id;
            return apiRegister.call(options, url);
        },
        createWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createWizard";
            return apiRegister.call(options, url);
        },
        getCampaignsData: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalCampaignsData?information=true&client=" + this.state.client.id + campaigns;
            return apiRegister.call(options, url);
        },
        getAdgroupsData: (adgroups, statistics) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?information=true&client=" + this.state.client.id + adgroups;
            return apiRegister.call(options, url);
        },
        getAdgroups: (campaigns) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getExernalAdgroupsData?" + "client=" + this.state.client.id + "&information=true" + campaigns;
            return apiRegister.call(options, url);
        },
        getSnapchatPixel: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/getSnapchatPixel?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getAdditionalInfo: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAdditionalInfo?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listGoogleBudgets: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/listBudgets?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative h-full">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                if (this.state.wizard.template) {
                                    this.props.history.push("/v2/templates");
                                } else {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                }
                            } else if (type === "save") {
                                this.functions.updateWizard();
                            } else if (type === "template") {
                                this.setState({
                                    create_template: true
                                })
                            }
                        }}
                        hideUserDropdown={true}
                        history={this.props.history}
                        breadcrumb={"Channels"}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", disabled: this.state.loading_wizard || this.state.loading_template, loading: false, show: !this.state.loading, red: true },
                            { text: this.state.wizard.template ? "Save template" : "Save draft", icon: SaveIcon, value: "save", disabled: this.state.loading_template, loading: this.state.loading_wizard, show: !this.state.loading },
                            { text: "Create template", icon: ClipboardListIcon, value: "template", loading: this.state.loading_template, show: !this.state.loading && !this.state.wizard.template && !this.state.wizard.partial }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                {/*CREATE MODAL*/}
                <WizardModal
                    title={"Create new template"}
                    text={"Set name below"}
                    button={"Create"}
                    icon={ClipboardListIcon}
                    open={this.state.create_template}
                    input={this.state.template_name}
                    onLoading={false}
                    onInput={(value) => {
                        this.setState({
                            template_name: value
                        })
                    }}
                    onCreate={() => {
                        this.functions.createTemplate();
                    }}
                    onClose={() => {
                        this.setState({
                            create_template: false
                        })
                    }}
                />

                {/* SUCCESS MESSAGE */}
                <SuccessModal
                    open={this.state.success ? true : false}
                    title={"Created"}
                    text={"Click OK to continue"}
                    button={"Ok"}
                    showInput={false}
                    onClose={(value) => {
                        this.setState({
                            success: null
                        })
                    }}
                />

                {/* SUCCESS NOTIFICATION*/}
                <SideNotification
                    ref={"SideNotification"}
                    icon={CheckIcon}
                    title={"Success"}
                    text={"Wizard updated"}
                />

                {/* NEW CAMPAIGN MODAL*/}
                <AddCampaignModal
                    open={this.state.add_adgroup ? true : false}
                    campaign={this.state.campaigns.filter((item) => { return item.selected }).length > 0 ? this.state.campaigns.filter((item) => { return item.selected }) : (this.state.campaigns.length === 1 ? this.state.campaigns : false)}
                    channel={this.state.campaigns.filter((item) => { return item.selected }).length > 0 ? this.state.campaigns.filter((item) => { return item.selected })[0].channel : (this.state.campaigns.length === 1 ? this.state.campaigns[0].channel : "")}
                    onSubmit={(data) => {
                        if (Array.isArray(data.campaign)) {
                            data.campaign.map((item) => {
                                this.functions.onNewLinkedin(item, data.sub_option, data.creative_options);
                            })
                        }
                        this.setState({
                            add_adgroup: false,
                            success: true
                        })
                    }}
                    onClose={() => {
                        this.setState({
                            add_adgroup: false
                        })
                    }}
                />

                {/*ERROR HANDLER*/}
                {
                    this.state.wizard.status === "error" &&
                    <div className="bg-white border-b p-4">
                        <div className="">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">There were {this.state.wizard.errors.length} errors with your submission</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <ul role="list" className="list-disc pl-5 space-y-1">
                                            {
                                                this.state.wizard.errors.map((item) => {
                                                    return (
                                                        <li onClick={() => {
                                                            this.props.history.push("/v2/campaigns/new/" + item.channel + "?" + item.level + "=" + item[item.level]);
                                                        }}><span className="capitalize">{item.channel}</span> {item.level} <span className="font-medium cursor-pointer hover:underline">#{item[item.level]}</span>: {item.message}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/* SLIDEOUT UPDATE */}
                <SlideoutTailwind
                    title={"Edit " + this.state.tab.value}
                    disabled={this.state.disabled}
                    submitButtonText={"Save"}
                    secondaryButton={false}
                    large={true}
                    open={(this.state.update.id || this.state.edit ? true : false)}
                    reach={this.state.update.reach}
                    onCancel={async () => {
                        await this.promisedSetState({ update: {}, edit: false, disabled: false });
                    }}
                    onSubmit={async () => {
                        if (this.state.edit && this.state.edit.channel === "facebook") {
                            if (this.state.edit.level === "campaign") {
                                await this.refs.EditLiveFacebookCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                await this.refs.EditLiveFacebookAdgroup.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "tiktok") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveTikTokCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveTikTokAdgroup.functions.update();
                            }
                        } else if (this.state.edit && this.state.edit.channel === "snapchat") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveSnapchatCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveSnapchatAdgroup.functions.update();
                            }
                        }
                        else if (this.state.edit && this.state.edit.channel === "google") {
                            /*if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveGoogleCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveGoogleAdgroup.functions.update();
                            }*/
                        } else if (this.state.edit && this.state.edit.channel === "linkedin") {
                            if (this.state.edit.level === "campaign") {
                                this.refs.EditLiveLinkedinCampaign.functions.update();
                            } else if (this.state.edit.level === "adgroup") {
                                this.refs.EditLiveLinkedinAdgroup.functions.update();
                            }
                        }
                        await this.functions.validate();
                        this.functions.onSubmit();
                    }}
                    onSecondarySubmit={() => {
                        //this.functions.onClone();
                    }}
                >

                    {/*GOOGLE*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'google' &&
                        this.state.update.level === 'campaign' &&
                        <EditGoogleCampaign
                            ref="CreateOrderGoogleCampaign"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            client={this.state.client}
                            onUpdate={async (updatedCampaign, adgroups) => {
                                this.functions.onUpdateCampaign(updatedCampaign);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    await this.promisedSetState({ adgroups: this.state.adgroups });
                                    await this.functions.validate();
                                    this.form.set();
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'google' &&
                        this.state.update.level === 'adgroup' &&
                        <EditGoogleAdgroup
                            ref="CreateOrderGoogleAdgroup"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            campaign={{}}
                            client={this.state.client}
                            onUpdate={async (updatedAdgroup) => {
                                this.functions.onUpdateAdgroup(updatedAdgroup);
                            }}
                        />
                    }

                    {/* GOOGLE LIVE*/}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleCampaign
                            ref="EditLiveGoogleCampaign"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {

                            }}
                            onSuccess={async (value) => {

                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "google" &&
                        <EditLiveGoogleAdgroup
                            ref="EditLiveGoogleAdgroup"
                            wizard={false}
                            locked={true}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                            }}

                        />
                    }

                    {/*FACEBOOK*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'facebook' &&
                        this.state.update.level === 'campaign' &&
                        <EditFacebookCampaign
                            ref="CreateOrderFacebookCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={async (updatedCampaign, adgroups) => {
                                this.functions.onUpdateCampaign(updatedCampaign);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    await this.promisedSetState({ adgroups: this.state.adgroups });
                                    await this.functions.validate();
                                    this.form.set();
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'facebook' &&
                        this.state.update.level === 'adgroup' &&
                        <EditFacebookAdset
                            ref="CreateOrderFacebookAdgroup"
                            wizard={true}
                            adset={{}}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onUpdate={async (updatedAdgroup) => {
                                this.functions.onUpdateAdgroup(updatedAdgroup);
                                await this.functions.validate();
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }

                    {/* FACEBOOK LIVE*/}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "facebook" &&
                        <EditLiveFacebookCampaign
                            ref="EditLiveFacebookCampaign"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "facebook" &&
                        <EditLiveFacebookAdgroup
                            ref="EditLiveFacebookAdgroup"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }

                    {/*LINKEDIN*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'linkedin' &&
                        this.state.update.level === 'campaign' &&
                        <EditLinkedinCampaign
                            ref="CreateOrderLinkedinCampaign"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            campaign={{}}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateCampaign(updateItem);
                                await this.functions.validate();
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'linkedin' &&
                        this.state.update.level === 'adgroup' &&
                        <EditLinkedinAdgroup
                            ref="CreateOrderLinkedinAdgroup"
                            wizard={true}
                            adgroup={{}}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                                await this.functions.validate();
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }

                    {/* LINKEDIN LIVE*/}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "linkedin" &&
                        <EditLiveLinkedinCampaign
                            ref="EditLiveLinkedinCampaign"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "linkedin" &&
                        <EditLiveLinkedinAdgroup
                            ref="EditLiveLinkedinAdgroup"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }



                    {/*TWITTER*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'twitter' &&
                        this.state.update.level === 'campaign' &&
                        <EditTwitterCampaign
                            ref="CreateOrderTwitterCampaign"
                            wizard={true}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            client={this.state.client}
                            campaign={{}}
                            onUpdate={async (updatedCampaign, adgroups) => {
                                this.functions.onUpdateCampaign(updatedCampaign);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    await this.promisedSetState({ adgroups: this.state.adgroups });
                                    await this.functions.validate();
                                    this.form.set();
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'twitter' &&
                        this.state.update.level === 'adgroup' &&
                        <EditTwitterAdset
                            ref="CreateOrderTwitterAdgroup"
                            wizard={true}
                            adset={{}}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                                await this.functions.validate();
                            }}
                        />
                    }
                    {/*TIKTOK*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'tiktok' &&
                        this.state.update.level === 'campaign' &&
                        <EditTikTokCampaign
                            ref="CreateOrderTiktokCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={async (updatedCampaign, adgroups) => {
                                this.functions.onUpdateCampaign(updatedCampaign);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    await this.promisedSetState({ adgroups: this.state.adgroups });
                                    await this.functions.validate();
                                    this.form.set();
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'tiktok' &&
                        this.state.update.level === 'adgroup' &&
                        <EditTikTokAdset
                            ref="CreateOrderTiktokAdgroup"
                            wizard={true}
                            adset={{}}
                            campaign={JSON.parse(JSON.stringify(this.state.campaigns.filter((item) => { return item.id === this.state.update.campaign })))}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            client={this.state.client}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);

                                await this.functions.validate();
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }

                    {/* TIKTOK LIVE*/}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "tiktok" &&
                        <EditLiveTikTokCampaign
                            ref="EditLiveTikTokCampaign"
                            locked={this.state.wizard.partial ? true : false}
                            wizard={false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    //this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "tiktok" &&
                        <EditLiveTikTokAdgroup
                            ref="EditLiveTikTokAdgroup"
                            locked={this.state.wizard.partial ? true : false}
                            wizard={false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }
                    {/*SNAPCHAT*/}
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'snapchat' &&
                        this.state.update.level === 'campaign' &&
                        <EditSnapchatCampaign
                            ref="CreateOrderSnapchatCampaign"
                            wizard={true}
                            client={this.state.client}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups))}
                            campaign={{}}
                            onUpdate={async (updatedCampaign, adgroups) => {
                                this.functions.onUpdateCampaign(updatedCampaign);
                                if (adgroups && adgroups.length > 0) {
                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                        adgroups.map((inner_item) => {
                                            if (item.id === inner_item.id) {
                                                item = inner_item;
                                            }
                                        })
                                        return item;
                                    })
                                    await this.promisedSetState({ adgroups: this.state.adgroups });
                                    await this.functions.validate();
                                    this.form.set();
                                }
                            }}
                        />
                    }
                    {
                        this.state.update.id &&
                        this.state.update.channel === 'snapchat' &&
                        this.state.update.level === 'adgroup' &&
                        <EditSnapchatAdset
                            ref="CreateOrderSnapchatAdgroup"
                            wizard={true}
                            adset={{}}
                            updated={JSON.parse(JSON.stringify(this.state.update))}
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns))}
                            client={this.state.client}
                            onUpdate={async (updateItem) => {
                                this.functions.onUpdateAdgroup(updateItem);
                                await this.functions.validate();
                            }}
                            onReach={(reach) => {
                                this.state.update.reach = reach;
                                this.setState({
                                    updated: this.state.update
                                })
                            }}
                        />
                    }

                    {/* SNAPCHAT LIVE*/}
                    {
                        this.state.edit &&
                        this.state.edit.level === "campaign" &&
                        this.state.edit.channel === "snapchat" &&
                        <EditLiveSnapchatCampaign
                            ref="EditLiveSnapchatCampaign"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            campaign={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    //this.functions.getCampaignsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }
                    {
                        this.state.edit &&
                        this.state.edit.level === "adgroup" &&
                        this.state.edit.channel === "snapchat" &&
                        <EditLiveSnapchatAdgroup
                            ref="EditLiveSnapchatAdgroup"
                            wizard={false}
                            locked={this.state.wizard.partial ? true : false}
                            adgroup={JSON.parse(JSON.stringify(this.state.edit))}
                            client={this.state.client}
                            onLoader={(loading) => {
                                this.promisedSetState({ edit_loader: loading });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSuccess={async (value) => {
                                if (value) {
                                    this.functions.getAdgroupsData(this.state.edit);
                                    this.promisedSetState({ edit: false });
                                    await this.functions.validate();
                                }
                            }}
                        />
                    }

                </SlideoutTailwind>

                <div className="p-4">

                    {/* CHANNELS */}
                    <div className="shadow rounded-lg bg-white mb-4 relative">
                        <div className="py-5 px-4 sm:px-6 border-b font-semibold text-sm">
                            Add campaign
                        </div>
                        {
                            this.state.order.budgetSplit && this.state.order.budgetSplit.value &&
                            <div className='absolute top-0 right-0 mt-4 pr-6 inline-flex items-center'>
                                <div className={"bg-bing-500 h-4 w-4 rounded-full flex justify-center items-center"}>
                                    <img className="w-1.5"
                                        alt=""
                                        src={require('../assets/images/bing-logo.png')} />

                                </div>
                                <span className='pr-4 pl-2'>Bing Sync</span>
                                <SwitchTailwind
                                    value={this.state.wizard.bingSync ? true : false}
                                    onSwitch={async () => {
                                        this.state.wizard.bingSync = !this.state.wizard.bingSync;
                                        /*
                                        if (!this.state.wizard.bingSync && false) {
                                            this.state.campaigns = this.state.campaigns.map((item) => {
                                                if (item.bingSync) {
                                                    item.bingSync = false;
                                                }
                                                return item;
                                            })
                                        }*/
                                        await this.promisedSetState({
                                            campaigns: this.state.campaigns,
                                            wizard: this.state.wizard
                                        })
                                        await this.form.set();
                                    }}
                                />
                            </div>
                        }
                        <div className="grid-cols-5 gap-4 grid p-6 pt-4">
                            {
                                this.state.options.filter((channel) => {
                                    return channel.available && (this.state.wizard.template || (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((item) => { return channel.value === item.value }).length > 0))
                                }).map((channel, index) => {
                                    if (this.state.wizard.partial) {
                                        if (channel.value != this.state.campaigns[0].channel) {
                                            return false;
                                        }
                                    }
                                    return (
                                        <div onClick={async () => {
                                            if (!this.state.wizard.partial) {
                                                if (channel.available && (this.state.wizard.template || (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((item) => { return channel.value === item.value }).length > 0))) {
                                                    if (this.state.option && this.state.option.value === channel.value) {
                                                        if ((channel.value === "google" && !this.state.clicked) || channel.value !== "google") {
                                                            await this.promisedSetState({
                                                                option: null,
                                                                sub_option: null
                                                            })
                                                        }
                                                    } else {
                                                        if (channel.value === 'tiktok') {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null
                                                            });
                                                        } else if (channel.value === 'snapchat') {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null
                                                            });
                                                        } else if (channel.value === 'linkedin') {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null,
                                                                creative_options: {}
                                                            });
                                                        } else if (channel.value === 'facebook') {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null
                                                            });
                                                        } else if (channel.value === 'twitter') {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null
                                                            });
                                                        } else {
                                                            await this.promisedSetState({
                                                                option: channel,
                                                                sub_option: null
                                                            });
                                                        }
                                                    }
                                                }
                                            }

                                        }}
                                            className={classNames(
                                                (this.state.option && this.state.option.value === channel.value ? "bg-purple-100 border-purple-500" : "bg-custom-input hover:border-gray-400"),
                                                !this.state.wizard.template && (this.state.wizard.partial || this.state.wizard.status === "error" || !channel.available || (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((item) => { return channel.value === item.value }).length < 1)) ? "cursor-not-allowed" : "",
                                                'col-span-1 shadow-sm border-1.5 h-48 justify-center relative rounded-md flex items-center flex-col p-4 cursor-pointer'
                                            )}>
                                            {
                                                channel.value === 'google' && this.state.order.budgetSplit && this.state.order.budgetSplit.value &&
                                                <div className='absolute top-0 left-0 mt-4 ml-4'>
                                                    <div className={(this.state.wizard.bingSync ? "bg-bing-500" : "bg-gray-200") + " h-8 w-8 rounded-full flex justify-center items-center"}>
                                                        <img className="w-3"
                                                            alt=""
                                                            src={require('../assets/images/bing-logo.png')} />

                                                    </div>
                                                </div>
                                            }
                                            <div className={(this.state.option && this.state.option.value === channel.value ? channel.color : "bg-gray-200") + " h-16 w-16 rounded-full flex justify-center items-center mb-4"}>
                                                {
                                                    this.state.internalChannels[channel.value] &&
                                                    <img className="w-5"
                                                        alt=""
                                                        src={require(`../assets/images/${this.state.internalChannels[channel.value].icon}`)} />
                                                }
                                            </div>
                                            <div className="text-sm font-medium text-gray-900 text-center">
                                                {channel.name}
                                            </div>
                                            {
                                                !this.state.wizard.partial &&
                                                < div className="text-sm font-medium text-gray-500 text-center">
                                                    Click here to select channel
                                                </div>
                                            }
                                            {
                                                (this.state.channels[channel.value] && (this.state.wizard.status === "error") || this.state.wizard.partial && this.state.channels[channel.value]) &&
                                                <div style={{ right: "10px", top: "10px" }} className="absolute h-10 w-10 rounded-full bg-white flex items-center justify-center">
                                                    <LockClosedIcon className="w-5 h-5 text-gray-900" />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* SUB OPTIONS */}
                        {
                            this.state.option && !this.state.wizard.partial &&
                            <div className="">
                                <div className="py-5 px-4 pt-0 sm:px-6 border-b font-semibold text-sm">
                                    Select type for <span className="capitalize">{this.state.option.value}</span>
                                </div>
                                <div className={"gap-4 grid p-6 pt-4 grid-cols-3"}>
                                    {
                                        this.state.sub_options[this.state.option.value].filter((item) => { return item.available }).map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    if ((item.value === "app_promotion" && item.id === 6) || (item.value === "app_installs" && item.id === 3) || (item.value === "SALES" && item.id === 10) || (item.value === "lead_generation" && item.id === 5)) {//ONLY TIKTOK HAVE COMBINATION ID-6 AND APPPROMOTION

                                                    } else {
                                                        this.setState({
                                                            sub_option: item,
                                                            creative_options: {}
                                                        });
                                                    }

                                                }} className={(this.state.sub_option && this.state.sub_option.value === item.value ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + ' col-span-1 h-20 flex-row shadow-sm border-1.5 relative rounded-md items-center flex px-2 cursor-pointer relative '}>
                                                    <div className="flex flex-1 flex-col pl-4 text-left justify-start items-start">
                                                        <div className={(item.value === "app_promotion" && item.id === 6) || (item.value === "app_installs" && item.id === 3) || (item.value === "SALES" && item.id === 10) || (item.value === "lead_generation" && item.id === 5) ? "text-xs font-medium text-gray-500 justify-start items-start" : "text-xs font-medium text-gray-900 justify-start items-start"}>
                                                            {item.name}
                                                        </div>
                                                        <div className="text-xs font-medium text-gray-500 justify-start items-start">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                    {
                                                        item.value === "search" && this.state.wizard.bingSync &&
                                                        <div className="absolute top-0 right-0 mt-2 mr-2">
                                                            <div className={(this.state.wizard.bingSync ? "bg-bing-500" : "bg-gray-200") + " h-4 w-4 rounded-full flex justify-center items-center"}>
                                                                <img className="w-1.5"
                                                                    alt=""
                                                                    src={require('../assets/images/bing-logo.png')} />

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {/* LINKEDIN CREATIVE OPTIONS */}
                        {
                            this.state.option && this.state.sub_option && this.state.option.value === "linkedin" &&
                            < div className="">
                                <div className="text-md font-medium pl-6">
                                    Ad format
                                </div>
                                <div className={"gap-4 grid p-6 pt-4 grid-cols-3"}>
                                    {
                                        [
                                            { name: "Single image ad", description: "Create ads using a single image that will show up in the news feed.", value: "image" },
                                            { name: "Carousel image ad", description: "Create ads with 2 or more images that will show up in the news feed.", value: "carousel" },
                                            { name: "Video ad", description: "Create ads using a video that will show up in the news feed.", value: "video" }
                                        ].map((item) => {
                                            return (
                                                <div onClick={() => {
                                                    if (this.state.creative_options[item.value]) {
                                                        delete this.state.creative_options[item.value];
                                                    } else {
                                                        this.state.creative_options[item.value] = item;
                                                    }
                                                    this.setState({
                                                        creative_options: this.state.creative_options
                                                    })
                                                }} className={(this.state.creative_options[item.value] ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + ' col-span-1 flex-row h-20 shadow-sm border-1.5 relative rounded-md items-center flex px-2 cursor-pointer  '}>
                                                    <div className="flex flex-1 flex-col pl-4 text-left justify-start items-start">
                                                        <div className="text-xs font-medium text-gray-900 justify-start items-start">
                                                            {item.name}
                                                        </div>
                                                        <div className="text-xs font-medium text-gray-500 justify-start items-start">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            <div className="flex w-full items-center justify-center pb-6">
                                <button
                                    onClick={() => {
                                        if ((this.state.option && this.state.sub_option && this.state.option.value !== "linkedin") || (this.state.option && this.state.option.value === "linkedin" && this.state.sub_option && Object.keys(this.state.creative_options).length > 0)) {
                                            if (this.state.option.value === "google") {
                                                this.functions.onNewGoogle(null, this.state.sub_option);
                                            } else if (this.state.option.value === "facebook") {
                                                this.functions.onNewFacebook(null, this.state.sub_option);
                                            } else if (this.state.option.value === "twitter") {
                                                this.functions.onNewTwitter(null, this.state.sub_option);
                                            } else if (this.state.option.value === "tiktok") {
                                                this.functions.onNewTikTok(null, this.state.sub_option);
                                            } else if (this.state.option.value === "linkedin") {
                                                this.functions.onNewLinkedin(null, this.state.sub_option, this.state.creative_options);
                                            } else if (this.state.option.value === "snapchat") {
                                                this.functions.onNewSnapchat(null, this.state.sub_option);
                                            }
                                            this.setState({
                                                option: null,
                                                sub_option: null,
                                                creative_options: {}
                                            });
                                        }
                                    }}
                                    className={(((this.state.option && this.state.sub_option && this.state.option.value !== "linkedin") || (this.state.option && this.state.option.value === "linkedin" && this.state.sub_option && Object.keys(this.state.creative_options).length > 0)) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                >
                                    {
                                        this.state.sub_option &&
                                        (this.state.option.value !== "linkedin") &&
                                        <span>Add 1 campaign and 1 adgroup</span>
                                    }
                                    {
                                        this.state.sub_option &&
                                        (this.state.option.value === "linkedin" && Object.keys(this.state.creative_options).length > 0) &&
                                        <span>Add 1 campaign and {Object.keys(this.state.creative_options).length} adgroup{Object.keys(this.state.creative_options).length < 2 ? "" : "s"}</span>
                                    }
                                    {
                                        this.state.option &&
                                        this.state.sub_option &&
                                        (this.state.option.value === "linkedin" && Object.keys(this.state.creative_options).length < 1) &&
                                        <span>Select ad format</span>
                                    }
                                    {
                                        this.state.option &&
                                        !this.state.sub_option &&
                                        <span>Select campaign type</span>
                                    }
                                    {
                                        !this.state.option &&
                                        <span>Select channel</span>
                                    }
                                </button>
                            </div>
                        }
                    </div>

                    {/* TABS */}
                    {
                        this.state.campaigns.length > 0 &&
                        <div className={"flex flex-1 flex-row relative"}>
                            {
                                this.state.option && !this.state.wizard.partial &&
                                <div className="absolute top-0 bottom-0 cursor-not-allowed z-20 left-0 right-0 bg-white bg-opacity-75 rounded-lg"></div>
                            }
                            {
                                this.state.tabs.map((tab, index) => {
                                    return (
                                        <Fragment>
                                            <div onClick={() => {
                                                if (tab.id === 1 || (tab.id === 2 && this.state[this.state.tab.value].length > 0) || (tab.id === 3 && this.state[this.state.tab.value].length > 0)) {
                                                    this.promisedSetState({
                                                        tab: tab
                                                    })
                                                }
                                            }}
                                                style={tab.id === this.state.tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                className={(tab.id === this.state.tab.id ? "bg-white" : "text-gray-700 bg-gray-50") + " shadow py-3 flex flex-1 relative items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                <tab.icon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                <div className="flex flex-1 truncate">
                                                    <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                        {tab.name}
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        (tab.value === "campaigns" &&
                                                            (this.state.campaigns.map((campaign) => {
                                                                if (!campaign.partial_parced) {
                                                                    if (campaign.channel === "facebook") {
                                                                        return campaignFacebook.validate(campaign)
                                                                    } else if (campaign.channel === "tiktok") {
                                                                        return campaignTiktok.validate(campaign)
                                                                    } else if (campaign.channel === "snapchat") {
                                                                        if (Object.keys(campaignSnapchat.validate(campaign)).map((item) => { return campaignSnapchat.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false
                                                                    } else if (campaign.channel === "twitter") {
                                                                        return campaignTwitter.validate(campaign)
                                                                    } else if (campaign.channel === "linkedin") {
                                                                        if (Object.keys(campaignLinkedin.validate(campaign)).map((item) => { return campaignLinkedin.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false

                                                                    } else if (campaign.channel === "google") {
                                                                        if (Object.keys(campaignGoogle.validate(campaign)).map((item) => { return campaignGoogle.validate(campaign)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false
                                                                    }
                                                                } else {
                                                                    return false;
                                                                }
                                                            }).filter((item) => { return item }).length > 0)) &&
                                                        <div className='text-red-600 flex'>
                                                            Errors
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                        </div>

                                                    }
                                                    {
                                                        (tab.value === "adgroups" &&
                                                            (this.state.adgroups.map((adgroup) => {
                                                                if (!adgroup.partial_parced) {
                                                                    if (adgroup.channel === "facebook") {
                                                                        console.log(adgroupFacebook.validate(adgroup), "FACEBOOK VALIDATE");
                                                                        if (Object.keys(adgroupFacebook.validate(adgroup)).map((item) => { return adgroupFacebook.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false

                                                                    } else if (adgroup.channel === "tiktok") {
                                                                        console.log(adgroupTikTok.validate(adgroup), "TIKTOK VALIDATE");
                                                                        if (Object.keys(adgroupTikTok.validate(adgroup)).map((item) => { return adgroupTikTok.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false
                                                                    } else if (adgroup.channel === "snapchat") {
                                                                        console.log(adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && adgroup.partial ? true : false), "test"), "SNAPCHAT VALIDATE");
                                                                        if (Object.keys(adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && adgroup.partial ? true : false), "test")).map((item) => { return adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && adgroup.partial ? true : false), "test")[item] }).filter((item) => { return item }).length > 0) {
                                                                            //if (adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && !adgroup.partial ? true : false)).budget || adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && !adgroup.partial ? true : false)).location || adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && !adgroup.partial ? true : false)).start_date) {
                                                                            return true;
                                                                        } else return false
                                                                    } else if (adgroup.channel === "twitter") {
                                                                        if (Object.keys(adgroupTwitter.validate(adgroup)).map((item) => { return adgroupTwitter.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false
                                                                    } else if (adgroup.channel === "linkedin") {
                                                                        console.log(adgroupLinkedin.validate(adgroup), "LINKEDIN VALIDATE");
                                                                        if (Object.keys(adgroupLinkedin.validate(adgroup)).map((item) => { return adgroupLinkedin.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) {
                                                                            return true;
                                                                        } else return false
                                                                    } else {
                                                                        return false
                                                                    }
                                                                } else {
                                                                    return false;
                                                                }
                                                            }).filter((item) => { return item }).length > 0)) &&
                                                        <div className='text-red-600 flex'>
                                                            Errors
                                                            <ExclamationIcon className="h-5 w-5 text-red-600 mx-2" />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    tab.value === "campaigns" && this.state.campaigns.filter((item) => { return item.selected }).length > 0 &&
                                                    <div className={"bg-purple-500 rounded-full text-xxs text-white font-medium px-3 py-1"}>
                                                        {this.state.campaigns.filter((item) => { return item.selected }).length} selected
                                                    </div>
                                                }
                                                {
                                                    tab.value === "adgroups" && this.state.adgroups.filter((item) => { return item.selected }).length > 0 &&
                                                    <div className={"bg-purple-500 rounded-full text-xxs text-white font-medium px-3 py-1"}>
                                                        {this.state.adgroups.filter((item) => { return item.selected }).length} selected
                                                    </div>
                                                }
                                                {
                                                    this.state.wizard.status === "error" &&
                                                    tab.value === "ads" && this.state.ads.filter((item) => { return item.selected }).length > 0 &&
                                                    <div className={"bg-purple-500 rounded-full text-xxs text-white font-medium px-3 py-1"}>
                                                        {this.state.ads.filter((item) => { return item.selected }).length} selected
                                                    </div>
                                                }
                                            </div>
                                            {
                                                index === 0 &&
                                                <div className="w-4"></div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    }
                    {
                        this.state.campaigns.length > 0 &&
                        <div className=" bg-white flex flex-1 rounded-b-lg shadow flex-col relative">
                            {
                                this.state.option && !this.state.wizard.partial &&
                                <div className="absolute cursor-not-allowed z-2 rounded-lg top-0 bottom-0 left-0 right-0 bg-white bg-opacity-75"></div>
                            }
                            {
                                this.state[this.state.tab.value].length < 1 &&
                                <div className="h-48 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <ExclamationCircleIcon className="h-10 w-10 text-gray-300" />
                                        <h3 className="mt-2 text-sm font-medium text-gray-900">No {this.state.tab.value}</h3>
                                        <p className="mt-1 text-sm text-gray-500">Select channel to add {this.state.tab.value}</p>
                                    </div>
                                </div>
                            }
                            {
                                this.state[this.state.tab.value].length > 0 &&
                                <div className="p-6 w-full">
                                    <div className="flex flex-row">
                                        {
                                            this.state.wizard.partial &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked && !item.partial
                                                        }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) {
                                                            this.functions.information(this.state.tab.value === "campaigns" ? "campaign" : "adgroup");
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked && !item.partial
                                                    }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0]) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Information</span>
                                                    <InformationCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            !this.state.wizard.partial &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked
                                                        }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) {
                                                            this.functions.onEdit(this.state.tab.value === "campaigns" ? "campaign" : "adgroup");
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked
                                                    }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0]) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Edit</span>
                                                    <PencilAltIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            //(!this.state.wizard.partial || (this.state.wizard.partial && this.state.tab.value === "adgroups")) &&
                                            (!this.state.wizard.partial) && false &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked
                                                        }).length > 1 && this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked
                                                        }).length < 5 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) {


                                                            this.functions.checkChannels();
                                                            this.form.set();
                                                        }
                                                    }}
                                                    className={((this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked
                                                    }).length > 1 && this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked
                                                    }).length < 5 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>A/B Tesing</span>
                                                    <BeakerIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            !this.state.wizard.partial &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked
                                                        }).length > 0) {
                                                            this.functions.onClone(this.state.tab.value === "campaigns" ? "campaign" : "adgroup");
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked
                                                    }).length > 0 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Clone</span>
                                                    <DuplicateIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            (!this.state.wizard.partial) &&
                                            this.state.tab.value === "campaigns" &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked
                                                        }).length > 0 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) {
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "linkedin") {
                                                                await this.promisedSetState({
                                                                    add_adgroup: true
                                                                });
                                                            } else {
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "facebook") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewFacebook(item);
                                                                    })
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "twitter") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewTwitter(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "tiktok") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewTikTok(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "snapchat") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewSnapchat(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "google") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewGoogle(item);
                                                                    });
                                                                }
                                                                await this.promisedSetState({
                                                                    success: true
                                                                });
                                                            }

                                                            this.functions.checkChannels();
                                                            this.functions.validate();
                                                            this.form.set();
                                                        }
                                                    }}
                                                    className={((this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked
                                                    }).length > 0 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) ? "cursor-pointer bg-green-600 hover:bg-green-700 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Add adgroup</span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            //(!this.state.wizard.partial || (this.state.wizard.partial && this.state.tab.value === "adgroups")) &&
                                            (!this.state.wizard.partial) &&
                                            this.state.tab.value === "adgroups" &&
                                            this.state.campaigns.filter((item) => { return item.selected }).length === 1 &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "linkedin") {
                                                            await this.promisedSetState({
                                                                add_adgroup: true
                                                            });
                                                        } else {
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "facebook") {
                                                                this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                    this.functions.onNewFacebook(item);
                                                                })
                                                            }
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "twitter") {
                                                                this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                    this.functions.onNewTwitter(item);
                                                                });
                                                            }
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "tiktok") {
                                                                this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                    this.functions.onNewTikTok(item);
                                                                });
                                                            }
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "snapchat") {
                                                                this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                    this.functions.onNewSnapchat(item);
                                                                });
                                                            }
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "google") {
                                                                this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                    this.functions.onNewGoogle(item);
                                                                });
                                                            }

                                                            this.functions.validate();
                                                            await this.promisedSetState({
                                                                success: true
                                                            });
                                                        }
                                                    }}
                                                    className={(true ? "cursor-pointer bg-green-600 hover:bg-green-700 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Add adgroup</span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            //(!this.state.wizard.partial || (this.state.wizard.partial && this.state.tab.value === "adgroups")) &&
                                            (!this.state.wizard.partial) &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected
                                                        }).length > 0) {
                                                            if (this.state.tab.value === "campaigns") {
                                                                let removed_campaign_ids = this.state.campaigns.filter((item) => { return item.selected }).map((item) => { return item.id });
                                                                this.state.ads = this.state.ads.filter((item) => {
                                                                    return removed_campaign_ids.filter((id) => { return id === item.campaign }).length < 1;
                                                                });
                                                                this.state.adgroups = this.state.adgroups.filter((item) => {
                                                                    return removed_campaign_ids.filter((id) => { return id === item.campaign }).length < 1;
                                                                });
                                                                this.state.campaigns = this.state.campaigns.filter((item) => {
                                                                    return !item.selected;
                                                                });
                                                            } else {
                                                                let removed_adgroup_ids = this.state.adgroups.filter((item) => { return item.selected }).map((item) => { return item.id });
                                                                this.state.ads = this.state.ads.filter((item) => {
                                                                    return removed_adgroup_ids.filter((id) => { return id === item.adgroup }).length < 1;
                                                                });
                                                                this.state.adgroups = this.state.adgroups.filter((item) => {
                                                                    return !item.selected;
                                                                });
                                                            }
                                                            await this.promisedSetState({
                                                                campaigns: this.state.campaigns,
                                                                adgroups: this.state.adgroups,
                                                                ads: this.state.ads
                                                            });

                                                            this.functions.validate();
                                                            this.functions.checkChannels();
                                                            this.form.set();
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected
                                                    }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Remove</span>
                                                    <TrashIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.wizard.partial &&
                                            this.state.tab.value === "adgroups" &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected && !item.locked && item.partial
                                                        }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0])) {
                                                            this.functions.onEdit(this.state.tab.value === "campaigns" ? "campaign" : "adgroup");
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && !item.locked && item.partial
                                                    }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).length === 1 && this.state[this.state.tab.value].filter((item) => { return item.selected }).map((item) => { return item.channel }).every((val, i, arr) => val === arr[0]) ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Edit</span>
                                                    <PencilAltIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }

                                        {
                                            //(!this.state.wizard.partial || (this.state.wizard.partial && this.state.tab.value === "adgroups")) &&
                                            (this.state.wizard.partial) &&
                                            (this.state.tab.value === "adgroups" || this.state.tab.value === "campaigns") &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state.campaigns.filter((item) => { return item.selected }).length === 1) {
                                                            if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "linkedin") {
                                                                await this.promisedSetState({
                                                                    add_adgroup: true
                                                                });
                                                            } else {
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "facebook") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewFacebook(item);
                                                                    })
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "twitter") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewTwitter(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "tiktok") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewTikTok(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "snapchat") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewSnapchat(item);
                                                                    });
                                                                }
                                                                if (this.state.campaigns.filter((item) => { return item.selected })[0].channel === "google") {
                                                                    this.state.campaigns.filter((item) => { return item.selected }).map((item) => {
                                                                        this.functions.onNewGoogle(item);
                                                                    });
                                                                }
                                                                await this.promisedSetState({
                                                                    success: true
                                                                });
                                                            }
                                                        } else if (this.state.campaigns.length === 1) {

                                                            switch (this.state.campaigns[0].channel) {
                                                                case "linkedin":
                                                                    console.log("HERE WE GO")
                                                                    await this.promisedSetState({
                                                                        add_adgroup: true
                                                                    });
                                                                    break;
                                                                case "facebook":
                                                                    this.functions.onNewFacebook(this.state.campaigns[0]);
                                                                    break;
                                                                case "twitter":
                                                                    break;
                                                                case "tiktok":
                                                                    this.functions.onNewTikTok(this.state.campaigns[0]);
                                                                    break;
                                                                case "snapchat":
                                                                    this.functions.onNewSnapchat(this.state.campaigns[0]);
                                                                    break;
                                                                case "google":
                                                                    this.functions.onNewGoogle(this.state.campaigns[0]);
                                                                    break;

                                                            }
                                                        }

                                                        this.functions.validate();

                                                    }}
                                                    className={(this.state.campaigns.filter((item) => { return item.selected }).length === 1 || this.state.campaigns.length === 1 ? "cursor-pointer bg-green-600 hover:bg-green-700 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Add adgroup</span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            (this.state.wizard.partial) &&
                                            this.state.tab.value === "adgroups" &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state[this.state.tab.value].filter((item) => {
                                                            return item.selected
                                                        }).length > 0) {

                                                            let removed_adgroup_ids = this.state.adgroups.filter((item) => { return item.selected && item.partial }).map((item) => { return item.id });
                                                            this.state.ads = this.state.ads.filter((item) => {
                                                                return removed_adgroup_ids.filter((id) => { return id === item.adgroup }).length < 1;
                                                            });
                                                            console.log(this.state.adgroups);
                                                            this.state.adgroups = this.state.adgroups.filter((item) => {
                                                                return (!item.selected && item.partial) || !item.partial;
                                                            });

                                                            await this.promisedSetState({
                                                                adgroups: this.state.adgroups,
                                                                ads: this.state.ads
                                                            });

                                                            this.functions.validate();
                                                            this.functions.checkChannels();
                                                            this.form.set();
                                                        }
                                                    }}
                                                    className={(this.state[this.state.tab.value].filter((item) => {
                                                        return item.selected && item.partial
                                                    }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                                >
                                                    <span>Remove</span>
                                                    <TrashIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }

                                    </div>

                                    <div className={"mt-6 flex flex-row w-full items-center mb-2"}>
                                        <div className="mr-8">
                                            <div onClick={async () => {
                                                if (!this.state.wizard.partial) {
                                                    if (this.state[this.state.tab.value].filter((item) => { return item.selected }).length === this.state[this.state.tab.value].length) {
                                                        this.state[this.state.tab.value] = this.state[this.state.tab.value].map((item) => {

                                                            item.selected = false;

                                                            //SET ALL ADGROUPS TO FALSE
                                                            this.state.adgroups = this.state.adgroups.map((item) => { item.selected = false; return item });

                                                            return item;
                                                        });
                                                    } else {
                                                        this.state[this.state.tab.value] = this.state[this.state.tab.value].map((item) => {
                                                            if (!item.locked) {
                                                                item.selected = true;
                                                            }
                                                            return item;
                                                        });
                                                    }
                                                    await this.promisedSetState({
                                                        campaigns: this.state.campaigns,
                                                        adgroups: this.state.adgroups
                                                    });
                                                }
                                            }} className={(!this.state.wizard.partial ? "bg-gray-200 hover:bg-gray-300 cursor-pointer" : " cursor-not-allowed bg-gray-50") + " rounded-md flex items-center h-10 w-10 justify-center"}>
                                                {
                                                    !this.state.wizard.partial &&
                                                    this.state[this.state.tab.value].filter((item) => { return item.selected }).length === this.state[this.state.tab.value].length &&
                                                    <CheckIcon className="h-5 w-5" />
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.tab.id === 1 &&
                                            <div className="flex flex-1 items-center">
                                                <div className="grid grid-cols-8 w-full text-sm font-semibold">
                                                    <div className="col-span-1 truncate">
                                                        Name
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Channel
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Type
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Objective goal
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Budget
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Start
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        End
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Locations
                                                    </div>
                                                </div >
                                            </div >
                                        }
                                        {
                                            this.state.tab.id === 2 &&
                                            <div className="flex flex-1 items-center">
                                                <div className="grid grid-cols-10 w-full text-sm font-semibold">
                                                    <div className="col-span-1 truncate">
                                                        Name
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Channel
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Campaign
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Optimization
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Budget
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Start
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        End
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Gender
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Age
                                                    </div>
                                                    <div className="col-span-1 truncate">
                                                        Locations
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div >
                                    {
                                        this.state.tab.id === 1 &&
                                        this.state.campaigns.map((campaign) => {
                                            return (
                                                <>
                                                    <div className="flex flex-row py-2 w-full items-center border-t">
                                                        <div className="mr-8">
                                                            <div onClick={async () => {
                                                                campaign.selected = !campaign.selected;
                                                                if (!campaign.selected) {
                                                                    this.state.adgroups = this.state.adgroups.map((item) => {
                                                                        if (item.campaign === campaign.id && !item.locked) {
                                                                            item.selected = false;
                                                                        }
                                                                        return item;
                                                                    });
                                                                }
                                                                await this.promisedSetState({
                                                                    campaigns: this.state.campaigns,
                                                                    adgroups: this.state.adgroups
                                                                });
                                                                this.form.set();
                                                            }} className={"bg-gray-200 hover:bg-gray-300 cursor-pointer rounded-md flex items-center overflow-hidden h-10 w-10 justify-center "}>
                                                                {
                                                                    campaign.selected &&
                                                                    <CheckIcon className="h-5 w-5" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-1 items-center">
                                                            <div className="grid grid-cols-8 w-full text-sm font-medium">
                                                                <div className="col-span-1 truncate items-center flex pr-3">
                                                                    <div>
                                                                        {
                                                                            campaign.channel === "facebook" &&
                                                                                !campaign.partial_parced && campaignFacebook.validate(campaign) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                        {
                                                                            campaign.channel === "tiktok" &&
                                                                                !campaign.partial_parced && campaignTiktok.validate(campaign) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                        {
                                                                            (campaign.channel === "snapchat" &&
                                                                                !campaign.partial_parced && Object.keys(campaignSnapchat.validate(campaign)).map((item) => { return campaignSnapchat.validate(campaign)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                        {
                                                                            (campaign.channel === "linkedin" &&
                                                                                !campaign.partial_parced && Object.keys(campaignLinkedin.validate(campaign)).map((item) => { return campaignLinkedin.validate(campaign)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                        {
                                                                            campaign.channel === "twitter" &&
                                                                                !campaign.partial_parced && campaignTwitter.validate(campaign) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                        {
                                                                            (campaign.channel === "google" &&
                                                                                !campaign.partial_parced && Object.keys(campaignGoogle.validate(campaign)).map((item) => { return campaignGoogle.validate(campaign)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                        }
                                                                    </div>
                                                                    <div className="truncate">
                                                                        {this.renders.name(campaign)}
                                                                    </div>
                                                                </div>
                                                                <div className="col-span-1 truncate flex flex-row items-center">
                                                                    {this.state.internalChannels[campaign.channel] &&
                                                                        <div
                                                                            className={`w-8 h-8 bg-${campaign.channel}-500 rounded-full p-2 border-2 border-white`}>
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require(`../assets/images/${this.state.internalChannels[campaign.channel].icon}`) + ")" }}></div>
                                                                        </div>
                                                                    }
                                                                    <span className="ml-2 capitalize">
                                                                        {this.renders.channel(campaign)}
                                                                    </span>
                                                                </div>
                                                                <div className="col-span-1 truncate items-center flex">
                                                                    {this.renders.campaignType(campaign)}
                                                                </div>
                                                                <div className="col-span-1 truncate items-center flex">
                                                                    {this.renders.objectiveGoal(campaign)}
                                                                </div>
                                                                {
                                                                    campaign.channel !== "snapchat" &&
                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.budget(campaign)}
                                                                    </div>
                                                                }
                                                                {
                                                                    campaign.channel === "snapchat" &&
                                                                    <div
                                                                        onMouseEnter={() => { this.state.show = true; this.setState({ show: this.state.show }) }}
                                                                        onMouseLeave={() => { this.state.show = false; this.setState({ show: this.state.show }) }}
                                                                        className="col-span-1 truncate items-center flex">
                                                                        {this.renders.budget(campaign)}
                                                                        {
                                                                            (campaign.channel === "snapchat" && campaign.has_daily_cap && campaign.has_daily_cap.value) && (campaign.channel === "snapchat" && campaign.has_lifetime_cap && campaign.has_lifetime_cap.value) &&
                                                                            <div className='absolute right-50'>
                                                                                <ToolTip show={this.state.show}>
                                                                                    <div>
                                                                                        {"Daily: " + campaign.daily_spend_cap + " " + this.state.client.channels.filter(channel => channel.name === "Snapchat")[0].currency}
                                                                                    </div>
                                                                                    <div>
                                                                                        {"Lifetime: " + campaign.lifetime_spend_cap + " " + this.state.client.channels.filter(channel => channel.name === "Snapchat")[0].currency}
                                                                                    </div>
                                                                                </ToolTip>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }

                                                                <div className="col-span-1 truncate items-center flex">
                                                                    {this.renders.startDate(campaign)}
                                                                </div>
                                                                <div className="col-span-1 truncate items-center flex">
                                                                    {this.renders.endDate(campaign)}
                                                                </div>
                                                                <div className="col-span-1 truncate items-center flex pr-3">
                                                                    <div className="truncate">
                                                                        {this.renders.locations(campaign)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        campaign.bingSync &&
                                                        <div className="flex flex-row py-2 w-full items-center border-t">
                                                            <div className="mr-8 h-10 w-10 flex items-center justify-center">
                                                                <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                                            </div>
                                                            <div className="flex flex-1 items-center">
                                                                <div className="grid grid-cols-8 w-full text-sm font-medium">
                                                                    <div className="col-span-1 truncate items-center flex pr-3">
                                                                        <div className="truncate">
                                                                            {this.renders.name(campaign)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-span-1 truncate flex flex-row items-center">
                                                                        <div
                                                                            className="w-8 h-8 bg-bing-500 rounded-full p-2 border-2 border-white">
                                                                            <div
                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                style={{ backgroundImage: "url(" + require(`../assets/images/${this.state.internalChannels.bing.icon}`) + ")" }}></div>
                                                                        </div>
                                                                        <span className="ml-2 capitalize">
                                                                            Bing
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.campaignType(campaign)}
                                                                    </div>
                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.objectiveGoal(campaign)}
                                                                    </div>
                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.budget(campaign, true)}
                                                                    </div>

                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.startDate(campaign)}
                                                                    </div>
                                                                    <div className="col-span-1 truncate items-center flex">
                                                                        {this.renders.endDate(campaign)}
                                                                    </div>
                                                                    <div className="col-span-1 truncate items-center flex pr-3">
                                                                        <div className="truncate">
                                                                            {this.renders.locations(campaign)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        this.state.tab.id === 2 &&
                                        this.state.adgroups.filter((adgroup) => {
                                            if (this.state.campaigns.filter((item) => { return item.selected }).length > 0) {
                                                return this.state.campaigns.filter((item) => { return item.selected && (item.id == adgroup.campaign || item.id == adgroup.campaign_id) }).length > 0
                                            } else {
                                                return true;
                                            }
                                        }).map((adgroup) => {
                                            //if (this.renders.channel(adgroup) === "facebook") console.log("adgroupFacebook.validate(adgroup)", adgroupFacebook.validate(adgroup))
                                            return (
                                                <div className="flex flex-row py-2 w-full items-center border-t">
                                                    <div className="mr-8">
                                                        <div onClick={async () => {
                                                            adgroup.selected = !adgroup.selected;
                                                            await this.promisedSetState({
                                                                adgroups: this.state.adgroups
                                                            });
                                                            this.form.set();
                                                        }} className={(true ? "bg-gray-200 hover:bg-gray-300 cursor-pointer" : "bg-gray-50 cursor-not-allowed") + " rounded-md flex items-center overflow-hidden h-10 w-10 justify-center"}>
                                                            {
                                                                adgroup.selected &&
                                                                <CheckIcon className="h-5 w-5" />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-1 items-center">
                                                        <div className="grid grid-cols-10 w-full text-sm font-medium">
                                                            <div className="col-span-1 truncate items-center flex pr-3">
                                                                <div>
                                                                    {
                                                                        (this.renders.channel(adgroup) === "facebook" &&
                                                                            !adgroup.partial_parced && Object.keys(adgroupFacebook.validate(adgroup)).map((item) => { return adgroupFacebook.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    }
                                                                    {
                                                                        (this.renders.channel(adgroup) === "linkedin" &&
                                                                            !adgroup.partial_parced && Object.keys(adgroupLinkedin.validate(adgroup)).map((item) => { return adgroupLinkedin.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    }
                                                                    {
                                                                        (this.renders.channel(adgroup) === "tiktok" &&
                                                                            !adgroup.partial_parced && Object.keys(adgroupTikTok.validate(adgroup)).map((item) => { return adgroupTikTok.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    }
                                                                    {

                                                                        (this.renders.channel(adgroup) === "snapchat" &&
                                                                            !adgroup.partial_parced && Object.keys(adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && adgroup.partial ? true : false), "test")).map((item) => { return adgroupSnapchat.validate(adgroup, this.state.campaigns, (this.state.wizard.partial && adgroup.partial ? true : false), "test")[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""

                                                                        /*(this.renders.channel(adgroup) === "snapchat" && adgroup.partial &&
                                                                            (adgroupSnapchat.validate(adgroup).budget || adgroupSnapchat.validate(adgroup).location || adgroupSnapchat.validate(adgroup).start_date)) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    }
                                                                    {
                                                                        (this.renders.channel(adgroup) === "snapchat" && !adgroup.partial &&
                                                                            (adgroupSnapchat.validate(adgroup, false, true).budget || adgroupSnapchat.validate(adgroup, false, true).location || adgroupSnapchat.validate(adgroup, false, true).start_date)) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    */}
                                                                    {
                                                                        (this.renders.channel(adgroup) === "twitter" &&
                                                                            !adgroup.partial_parced && Object.keys(adgroupTwitter.validate(adgroup)).map((item) => { return adgroupTwitter.validate(adgroup)[item] }).filter((item) => { return item }).length > 0) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                                    }
                                                                </div>
                                                                <div className="truncate">
                                                                    {this.renders.name(adgroup)}
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 truncate flex flex-row items-center">
                                                                {this.state.internalChannels[adgroup.channel] &&
                                                                    <div
                                                                        className={`w-8 h-8 bg-${adgroup.channel}-500 rounded-full p-2 border-2 border-white`}>
                                                                        <div
                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                            style={{ backgroundImage: "url(" + require(`../assets/images/${this.state.internalChannels[adgroup.channel].icon}`) + ")" }}></div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex pr-3">
                                                                <div className="truncate">
                                                                    {this.renders.campaignName(adgroup)}
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex pr-3">
                                                                <div className="truncate">
                                                                    {this.renders.optimization(adgroup)}
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex pr-3">
                                                                <div className="truncate">
                                                                    {this.renders.budget(adgroup)}
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex">
                                                                {this.renders.startDate(adgroup)}
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex">
                                                                {this.renders.endDate(adgroup)}
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex">
                                                                {this.renders.gender(adgroup)}
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex">
                                                                {this.renders.age(adgroup)}
                                                            </div>
                                                            <div className="col-span-1 truncate items-center flex pr-3">
                                                                <div className="truncate">
                                                                    {this.renders.locations(adgroup)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div >
                            }
                        </div >
                    }

                    <div className="pt-4 flex flex-row">
                        <div className="flex flex-1">
                            <button
                                type="button"
                                onClick={async () => {
                                    if (!this.state.wizard.template) {
                                        this.props.history.push("/v2/campaigns/new/order");
                                    }
                                }}
                                className={(!this.state.wizard.template ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                            >
                                <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                Back
                            </button>
                        </div>
                        <div className="flex flex-1"></div>
                        <div className="flex justify-center items-center">
                            <div className="">
                                <button
                                    onClick={() => {
                                        if (!this.state.error) {
                                            if (this.state.options.filter((channel) => {
                                                return this.state.channels[channel.value]
                                            }).length > 0) {
                                                this.props.history.push("/v2/campaigns/new/creatives");
                                            }
                                        }
                                    }}
                                    className={(this.state.options.filter((channel) => {
                                        return this.state.channels[channel.value]
                                    }).length > 0 && !this.state.error ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                >
                                    <span>Next: Creatives</span>
                                    <ArrowRightIcon className="ml-2 h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default CreateDraftChannels;