import React, { Fragment } from 'react';
import AdvancedReportCellCreative from '../../moduleFiles/advancedReportCellCreative';
import AdvancedReportCellPreview from '../../moduleFiles/advancedReportCellPreview';
import AdvancedReportCellChart from '../../moduleFiles/advancedReportCellChart';
import AdvancedReportCellText from '../../moduleFiles/advancedReportCellText';
import AdvancedReportCellNumber from '../../moduleFiles/advancedReportCellNumber';
import AdvancedReportCellTable from '../../moduleFiles/advancedReportCellTable';

const ReportHtml = ({ tabs, renders, transparent_cells, background_color }) => {
    return (
        <>
            {
                Array.isArray(tabs) &&
                tabs.filter(tab => tab.disabled !== true).map((tab, tabIndex) => {
                    return (
                        <div style={{ backgroundColor: `#${background_color}` }} key={`tabIndex-${tabIndex}`}>
                            {
                                Array.isArray(tab.groups) &&
                                tab.groups.map((group, groupIndex) => {
                                    return (
                                        <Fragment key={`groupIndex-${groupIndex}`}>
                                            <div
                                                style={{
                                                    ...(group.enable_image && group.enable_background_full && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                    ...(!group.enable_image && group.enable_background_full && { backgroundColor: "#" + group.background_color })
                                                }}
                                                className={(!group.enable_background_full ? " px-4 " : "") + (group.enable_margin ? " mt-4 mb-4 " : "") + " w-full"}
                                            //style={group.enable_background && group.enable_background_full ? { ...{ backgroundColor: "#" + group.background_color } } : {}} className={(!group.enable_background_full ? " px-4 " : "") + (group.enable_margin ? " mt-4 mb-4 " : "") + " w-full"}
                                            >
                                                <div className="mx-auto" style={{ maxWidth: "1200px" }}>
                                                    <div
                                                        style={{
                                                            ...(!group.enable_image && group.enable_background && { backgroundColor: "#" + group.background_color }),
                                                            ...(group.enable_image && !group.enable_background_full && { backgroundImage: "url(" + group.pageImage + ")", backgroundSize: "cover" }),
                                                            ...({ height: (100 * +group.rows) + "px", gridTemplateRows: renders.rowHeight(group) })
                                                        }}
                                                        //style={group.enable_background ? { ...{ backgroundColor: "#" + group.background_color }, ...{ height: (100 * +group.rows) + "px" }, ...{ gridTemplateRows: renders.rowHeight(group) } } : { height: (100 * +group.rows) + "px", gridTemplateRows: renders.rowHeight(group) }}
                                                        className={(group.rows ? ("grid-rows-" + group.rows) : "row-auto") + (group.enable_round_corners ? " rounded-md " : "") + " w-full overflow-hidden grid-cols-8 grid"}
                                                    >
                                                        {
                                                            Array.isArray(tab.grid_data) &&
                                                            tab.grid_data.filter((cell) => {
                                                                return !cell.abstract
                                                            }).filter((cell) => {
                                                                return cell.group === group.id;
                                                            }).map((cell, cellIndex) => {
                                                                return (
                                                                    <div
                                                                        key={`cellIndex-${cellIndex}`}
                                                                        style={renders.translateCellCoordinatesToGrid(cell)}
                                                                        className={(!(cell.settings && cell.settings.disable_margin_top) ? renders.translateCellPadding('top', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_bottom) ? renders.translateCellPadding('bottom', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_right) ? renders.translateCellPadding('right', cell, group) : "") + (!(cell.settings && cell.settings.disable_margin_left) ? renders.translateCellPadding('left', cell, group) : "")}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                ...(cell.settings && cell.settings.background_color && !cell.settings.transparent && { backgroundColor: cell.settings.background_color }),
                                                                                ...(cell.settings && !cell.settings.background_color && !cell.settings.transparent && { backgroundColor: "#FFFFFF" }),
                                                                                ...(!cell.settings && { backgroundColor: "#FFFFFF" })
                                                                            }}
                                                                            className={(!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_left)) ? " rounded-tl-md " : "") + (!(cell.settings && (cell.settings.disable_margin_top || cell.settings.disable_margin_right)) ? " rounded-tr-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_right)) ? " rounded-br-md " : "") + (!(cell.settings && (cell.settings.disable_margin_bottom || cell.settings.disable_margin_left)) ? " rounded-bl-md " : "") + (cell.settings && cell.settings.shadow ? " shadow-xl " : "") + " w-full h-full relative overflow-hidden"}
                                                                        >
                                                                            <div id={cell.i + "_preview"} className="absolute w-full h-full">
                                                                                {
                                                                                    cell.typeOfBlock === "img" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellCreative
                                                                                        header={cell.header}
                                                                                        data={cell.data}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        source={cell}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "preview" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellPreview
                                                                                        header={cell.header}
                                                                                        source={cell}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "chart" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellChart
                                                                                        source={cell}
                                                                                        data={cell.data}
                                                                                        items={tab.grid_data.filter((cell) => {
                                                                                            return cell.group === group.id;
                                                                                        })}
                                                                                        metric={cell.metric}
                                                                                        header={cell.header}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "input" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellText
                                                                                        header={cell.header}
                                                                                        data={cell.data}
                                                                                        format={cell.format}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        onInput={() => { }}
                                                                                        source={cell}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "number" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellNumber
                                                                                        source={cell}
                                                                                        items={tab.grid_data.filter((cell) => {
                                                                                            return cell.group === group.id;
                                                                                        })}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    cell.typeOfBlock === "table" && !(Array.isArray(cell.errors) && cell.errors.length > 0) &&
                                                                                    <AdvancedReportCellTable
                                                                                        source={cell}
                                                                                        items={tab.grid_data.filter((cell) => {
                                                                                            return cell.group === group.id;
                                                                                        })}
                                                                                        typeOfBlock={cell.typeOfBlock}
                                                                                        static={true}
                                                                                        external={true}
                                                                                        transparent_cells={transparent_cells}
                                                                                        report_background_color={background_color}
                                                                                        show_component={true}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </>
    );
};

export default ReportHtml;
