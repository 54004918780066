import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";

class SelectCellType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            selected: {}
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            open: this.props.open,
            tab: this.props.tab ? this.props.tab : {},
            selected: this.props.selected
        });
        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            open: nextProps.open,
            tab: nextProps.tab ? nextProps.tab : {},
            selected: nextProps.selected
        });
        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }
    }

    functions = {
        getCellTypes: () => {
            let celltypes = [
                {
                    id: "preview",
                    title: "Preview",
                    subtitle: "Select ad to preview its creative",
                    icon: "image",
                    value: "preview"
                },
                {
                    id: "image",
                    title: "Image/Video",
                    subtitle: "Upload an image/video to the cell",
                    icon: "image",
                    value: "image"
                },
                {
                    id: "text",
                    title: "Text",
                    subtitle: "Write text in the cell",
                    icon: "file-text",
                    value: "text"
                },
                {
                    id: "number",
                    title: "Aggregated number",
                    subtitle: "Summarize data from datasources",
                    icon: "tag",
                    value: "number"
                },
                {
                    id: "performance",
                    title: "Performance tracker",
                    subtitle: "Set future goals",
                    icon: "flag",
                    value: "number"
                },
                {
                    id: "chart",
                    title: "Chart",
                    subtitle: "Show data in a chart",
                    icon: "pie-chart",
                    value: "chart"
                },
                {
                    id: "table",
                    title: "Table",
                    subtitle: "Show data in a table",
                    icon: "align-justify",
                    value: "table"
                }
            ];
            return celltypes;
        },

    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 font-semibold text-sm">
                                Select cell type
                            </h3>
                            {
                                !this.state.tab.celltype &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    There are <span
                                        className="font-bold ml-1 mr-1">{this.functions.getCellTypes().length}</span> cell
                                    types
                                    available
                                </p>
                            }
                            {
                                this.state.selected && this.state.selected.value &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected <span
                                        className="font-bold ml-1 mr-1">{this.state.selected ? this.state.selected.title : "..."}</span>
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.state.selected && this.state.selected.value &&
                                <div
                                    className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={16}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        <div className="w-full h-6" />
                        <div className="">
                            {
                                this.functions.getCellTypes().map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={("cursor-pointer") + (i !== (this.functions.getCellTypes().length - 1) ? " mb-2" : "") + " flex flex-1 flex-row justify-center"}>
                                                <div
                                                    className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={16}
                                                        icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (this.props.onSelect) {
                                                        this.props.onSelect(item);
                                                    }
                                                }}
                                                    className={((this.state.selected && (this.state.selected.id ? this.state.selected.id == item.id : this.state.selected.value === item.value)) ? "border-purple-500" : "") + " h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.selected && (this.state.selected.id ? this.state.selected.id == item.id : this.state.selected.value === item.value) &&
                                                        <div className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col">
                                                    <div className="font-medium text-sm">
                                                        {item.title}
                                                    </div>
                                                    <div className="text-xxs">
                                                        {item.subtitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.selected && this.state.selected.value &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} className="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabIndex="0">Next</button>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }
}

export default SelectCellType