import { KeyIcon, PlusIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import SuccessModal from './successModal';
import TopNavigationAgent from './topNavigationAgent';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import SwitchTailwind from './switchTailwind';
import LinkedIn from 'react-linkedin-login-oauth2/lib/LinkedIn';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountLinkedin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            loading: false,
            loading_account: false,
            loading_accounts: false,
            account: {},
            accounts: [],
            healthcheck: {},
            owner: false,
            me: {},
            throttling: {},
            loading_managers: false,
            loading_customers: false,
            is_manager: false,
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agency: {},
            agency_user: {},
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me,
            agency: this.props.agency ? this.props.agency : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.linkedin && !this.state.agency.linkedin) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me,
            agency: nextprops.agency ? nextprops.agency : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "linkedin",
                    token: token
                });
                if (this.props.onLogin) {
                    this.props.onLogin()
                }
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ linkedin: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "linkedin"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ linkedin: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listAccounts: async () => {
            await this.promisedSetState({
                loading_accounts: true,
                error: false,
                message: ""
            });
            try {
                let response = await this.calls.listAccounts();
                await this.promisedSetState({
                    accounts: response.data
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message
                })
            }
            await this.promisedSetState({
                loading_accounts: false
            });
        },
        getAccount: async () => {
            await this.promisedSetState({
                loading_accounts: true,
                error: false,
                message: ""
            });
            try {
                let org_id = this.state.account.reference.replace("urn:li:organization:", "");
                let response = await this.calls.getAccount(org_id);
                if (response.data) {
                    try {
                        let image_url = response.data.logoV2["original~"].elements[0].identifiers[0].identifier;
                        if (image_url) {
                            this.state.account.logo = image_url;
                        }
                        this.state.account.page_name = response.data.localizedName;
                    } catch (e) { }
                }
                await this.promisedSetState({
                    account: this.state.account
                });
            } catch (error) {
                this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message
                })
            }
            await this.promisedSetState({
                loading_accounts: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.account.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    account: this.state.account
                });
                this.state.client.linkedin = {
                    defaultAgent: this.state.agent,
                    adaccountId: this.state.account.id,
                    adaccountName: this.state.account.name,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    account: {},
                    currency: {},
                    client: this.state.client
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.client.linkedin);
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_budget: true,
                error: false
            });
            try {
                let response = await this.calls.updateCientPlatformBudget({
                    channel: "linkedin",
                    enabled: this.state.client.linkedin.maxBudgetEnabled,
                    budget: this.state.client.linkedin.maxBudget
                });
                this.state.client.linkedin.campaignsWithSchedule = response.data.campaignsWithSchedule;
                await this.promisedSetState({
                    client: this.state.client
                });
            } catch (error) {
                await this.promisedSetState({
                    error: true,
                    message: error && error.body && error.body.message ? error.body.message : "Something went wrong"
                });
            }
            await this.promisedSetState({
                loading_budget: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.linkedin;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    account: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'linkedin'
                });
                this.state.agency.linkedin = {
                    linkedin_connected: response.data.linkedin_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'linkedin'
                });
                this.state.agency.linkedin = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/linkedin/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        updateCientPlatformBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCientPlatformBudget?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/linkedin/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        listAccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/listAccounts?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAccount: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/linkedin/getOrganization?client=" + this.state.client.id + "&account=" + id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=linkedin";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + ""}>

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Linkedin"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-linkedin-500 rounded-md flex justify-center items-center">
                        <div className="w-4 h-4">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Linkedin {(this.state.client && this.state.client.linkedin) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.linkedin) &&
                            <div className="text-xs text-gray-500">
                                Connect Linkedin to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.linkedin) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.linkedin) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.state.is_manager && !this.state.client.linkedin) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            this.state.agency.linkedin ||
                            (
                                !this.state.user.id &&
                                this.state.client.linkedin &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.linkedin && this.state.client.linkedin.defaultAgent && this.state.client.linkedin.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={!this.state.agency.linkedin ? (this.state.client.linkedin ? true : false) : this.state.agency.linkedin.authenticated}
                                onSwitch={async () => {
                                    if (this.state.agency.linkedin) {
                                        if (!this.state.agency.linkedin.authenticated) {
                                            this.functions.connectToAgency();
                                        } else {
                                            this.functions.disconnectFromAgency();
                                        }
                                    } else {
                                        if (!this.state.client.linkedin) {
                                            this.state.client.linkedin = {};
                                            await this.promisedSetState({
                                                client: this.state.client
                                            });
                                        } else {
                                            this.functions.disconnectFromClient();
                                        }
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>

                    {
                        !this.state.agency.linkedin &&
                        !this.state.user.id &&
                        < div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.linkedin}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.linkedin && this.state.me.linkedin.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.linkedin && this.state.client.linkedin.defaultAgent ? this.state.client.linkedin.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value,
                                        account: {}
                                    });
                                    this.functions.listAccounts();
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (this.state.throttling.current) {
                                            //SKIP
                                        } else {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        this.state.client.id &&
                        (this.state.client.linkedin || this.state.agent.id !== 0) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={this.state.client.linkedin ? "Ad account" : "Ad accounts"}
                                    loader={this.state.loading_accounts}
                                    searchInput={true}
                                    locked={this.state.client.linkedin}
                                    selected={this.state.client.linkedin && this.state.client.linkedin.adaccountId ? { name: this.state.client.linkedin.adaccountName } : (this.state.account.id ? this.state.account : { name: "Select account" })}
                                    options={this.state.accounts}
                                    onChange={async (value) => {

                                        //PRESET CURRENCY
                                        let currency = null;
                                        this.state.currencies.map((item) => {
                                            if (item.id == value.currency) {
                                                currency = item;
                                            }
                                        });
                                        if (currency) {
                                            this.state.currency = currency;
                                        }

                                        await this.promisedSetState({
                                            account: value
                                        });
                                        this.functions.getAccount();
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.linkedin && this.state.client.linkedin.adaccountId) || this.state.account.id)}
                                copyText={(this.state.client.linkedin && this.state.client.linkedin.adaccountId) ? this.state.client.linkedin.adaccountId : this.state.account.id}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        this.state.client.id &&
                        (this.state.account.id || (this.state.client.linkedin && this.state.client.linkedin.adaccountId)) &&
                        <div className="mt-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Currency"}
                                locked={this.state.client.linkedin}
                                selected={this.state.client.linkedin && this.state.client.linkedin.adaccountId ? { name: this.state.client.linkedin.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                                options={this.state.currencies}
                                onChange={async (value) => {
                                    this.setState({
                                        currency: value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        false &&
                        !this.state.agency.linkedin &&
                        this.state.client.id &&
                        this.state.client.linkedin && this.state.client.linkedin.adaccountId &&
                        <div className="mt-5 border-t flex items-center flex-row pt-5 w-full">
                            <div className="flex flex-2">
                                <InputTailwind
                                    progressBar={this.state.client.linkedin && this.state.client.linkedin.maxBudgetEnabled && +this.state.client.linkedin.maxBudget > 0}
                                    progressBarValue={this.state.client.linkedin.totalBudgetThisMonth ? +this.state.client.linkedin.totalBudgetThisMonth : 0}
                                    progressBarLowestValue={0}
                                    progressBarTopValue={this.state.client.linkedin.maxBudget}
                                    progressBarPercentage={(+this.state.client.linkedin.totalBudgetThisMonth / +this.state.client.linkedin.maxBudget) * 100}
                                    leftSection={true}
                                    leftSectionLabel={this.state.client.linkedin.currency ? this.state.client.linkedin.currency : "-"}
                                    onBlur={() => {
                                        this.functions.updateMaxBudget();
                                    }}
                                    onFocus={() => {

                                    }}
                                    loading={this.state.loading_budget}
                                    full={true}
                                    label={"Monthly max budget"}
                                    disabled={!this.state.client.linkedin.maxBudgetEnabled || this.state.loading_budget}
                                    value={this.state.client.linkedin && this.state.client.linkedin.maxBudgetEnabled ? this.state.client.linkedin.maxBudget : ""}
                                    onChange={async (value) => {
                                        this.state.client.linkedin.maxBudget = value;
                                        this.setState({
                                            client: this.state.client
                                        });
                                    }}
                                />
                            </div>
                            <div className="flex justify-end pt-5 pl-10">
                                <SwitchTailwind
                                    value={this.state.client.linkedin.maxBudgetEnabled}
                                    onSwitch={async () => {
                                        if (!this.state.loading_budget) {
                                            this.state.client.linkedin.maxBudgetEnabled = !this.state.client.linkedin.maxBudgetEnabled;
                                            if (!this.state.client.linkedin.maxBudgetEnabled) {
                                                this.state.client.linkedin.maxBudget = 0;
                                                this.functions.updateMaxBudget();
                                            }
                                            this.setState({
                                                client: this.state.client
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        this.state.client.id &&
                        this.state.client.linkedin && this.state.client.linkedin.adaccountId &&
                        this.state.client.linkedin.maxBudgetEnabled &&
                        this.state.client.linkedin.campaignsWithSchedule < 1 &&
                        <div className="mt-5 border-t pt-5 w-full">
                            <div className="bg-orange-100 p-4 rounded-md text-sm text-orange-500">
                                <div>No connected campaigns with Schedule activated</div>
                            </div>
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        this.state.client.id &&
                        !this.state.client.linkedin &&
                        this.state.account.id &&
                        <div className="flex w-full flex-row">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        this.functions.connectToClient();
                                    }
                                }}
                                className={(this.state.account.id ? "bg-linkedin-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save Linkedin account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-linkedin-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        !this.state.client.id &&
                        (!this.state.user.linkedin || (this.state.user.linkedin && !this.state.user.linkedin.authenticated)) &&
                        <div className="mt-5">
                            {/* ,r_compliance,w_compliance */}
                            <LinkedIn
                                scope={"rw_ads,r_ads,w_organization_social,r_organization_social,w_member_social,rw_organization_admin,r_ads_reporting,r_liteprofile"}
                                clientId="78v2iaew9m2uw6"
                                onSuccess={(event) => {
                                    if (event.code) {
                                        this.functions.login(event.code);
                                    }
                                }}
                                onFailure={() => {

                                }}
                                redirectUri={window.location.hostname.indexOf("dev.") !== -1 ? "https://dev.adcredo.io/callback/linkedin" : "https://app.adcredo.io/callback/linkedin"}
                                onClick={() => {

                                }}
                                renderElement={({ onClick, disabled }) => (
                                    <button onClick={onClick} className={(!(this.state.user.linkedin && this.state.user.linkedin.authenticated) ?
                                        "cursor-pointer bg-linkedin-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                    }
                                    >
                                        <span>Login with Linkedin</span>
                                        {
                                            this.state.loading &&
                                            <div className={(!(this.state.user.linkedin && this.state.user.linkedin.authenticated) ? "bg-linkedin-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                                <div style={{ borderTopColor: "transparent" }}
                                                    class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                    </button>
                                )}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        !this.state.client.id &&
                        this.state.user.linkedin && this.state.user.linkedin.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                }}
                                className={(!(this.state.user.linkedin && this.state.user.linkedin.authenticated) ?
                                    "cursor-pointer bg-linkedin-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.linkedin && this.state.user.linkedin.authenticated) ? "bg-linkedin-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        false &&
                        this.state.agency.linkedin &&
                        this.state.agency.linkedin.authenticated &&
                        <div className="mt-5 w-full">
                            <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4"}>
                                {[
                                    { name: "Clients", stat: this.state.agency.linkedin.linkedin_connected },
                                    { name: "Managers", stat: this.state.agency.linkedin.matching_managers },
                                    { name: "Ad accounts", stat: this.state.agency.linkedin.matching_accounts },
                                    { name: "Campaigns", stat: this.state.agency.linkedin.campaigns }
                                ].map((item) => (
                                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-5">
                                        <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    }
                    {
                        this.state.agency.linkedin &&
                        this.state.agency.linkedin.authenticated &&
                        this.state.syncManagers &&
                        <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                            {this.state.syncMessage}
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        this.state.healthcheck && this.state.healthcheck.linkedin && this.state.healthcheck.linkedin.status != 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.linkedin.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        !this.state.user.id &&
                        this.state.client.linkedin &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.linkedin && this.state.client.linkedin.defaultAgent && this.state.client.linkedin.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.linkedin &&
                        !(this.state.me.linkedin && this.state.me.linkedin.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated Linkedin, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.agency.linkedin &&
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.linkedin &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated Linkedin
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default AccountLinkedin;
