import React, { Component, Fragment } from 'react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { Menu, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChartPieIcon, ChevronDownIcon, ChevronUpIcon, BadgeCheckIcon, CubeIcon, ExclamationCircleIcon, PlusIcon, TrashIcon, XIcon, ArrowDownIcon, StarIcon, ClipboardCheckIcon, ClipboardIcon, PencilAltIcon, DuplicateIcon, ExternalLinkIcon, CheckCircleIcon, ShareIcon } from '@heroicons/react/outline';
import moment from 'moment';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import classNames from 'classnames';
import { AdjustmentsIcon } from '@heroicons/react/solid';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import CreateOrder from '../moduleFiles/createOrder';
import SideNavigationAgent from '../moduleFiles/sideNavigationAgent';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WizardModal from '../moduleFiles/wizardModal';
import { clientRegister } from '../services/clientRegister';
import CreateNewReport from '../modules/createNewReport';
import CreateReport from "../moduleFiles/createReport";
import { userRegister } from "../services/userRegister";
import CreateReportStandard from '../moduleFiles/createReportStandard';
import ShareReport from '../moduleFiles/shareReport';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';
import TableFilters from '../moduleFiles/tableFilters';

class ReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            closed: true,
            loading_partial: false,
            meta: {},
            limit: 10,
            page: 1,
            search: "",
            sort: "created",
            direction: "descending",
            create_wizard: false,
            wizard_name: "",
            orders: [],
            columns: [],
            status: {},
            client: {},
            clients: [],
            stats: [],
            filters: [],
            throttling: {},
            create_report: false,
            name_error: true,
            user: {},
            dropdownMenu: ["Open", "Share template", "Open external", "Set as Primary", "Set as Secondary", "Edit", "Clone", "Delete"],
            selected_tags: []
        }
    };

    async componentDidMount() {

        let params = new URLSearchParams(window.location.search);
        let page = params.get("page") || 1;
        let limit = params.get("limit") || this.state.limit;
        let sort = params.get("sortBy") || this.state.sort;
        let direction = params.get("orderBy") || this.state.direction;
        let search = params.get("search") || this.state.search;

        await this.promisedSetState({
            page: +page,
            limit: +limit,
            sort: sort,
            direction: direction,
            ...(search && { search: search }),
        })

        this.functions.client();
        this.functions.user();
    }

    // ON BACK BUTTON
    async componentWillReceiveProps(nextProps, nextContext) {
        let params = new URLSearchParams(window.location.search);
        let page = params.get("page")
        let limit = params.get("limit")
        let sort = params.get("sortBy")
        let direction = params.get("orderBy")
        let search = params.get("search");

        if (page && page !== this.state.page) {
            await this.promisedSetState({
                page: +page,
                limit: +limit,
                sort: sort,
                direction: direction,
                ...(search && { search: search }),
            })
            this.functions.orders();
        } else if (!window.location.pathname.includes("?limit=")) {
            await this.promisedSetState({
                page: 1,
                limit: 10,
                sort: this.state.sort,
                direction: this.state.direction,
                ...(search && { search: search }),
            })
            this.functions.orders();
        }
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
            if (this.state.user && this.state.user.userRole && this.state.user.userRole === "sales") {
                await this.promisedSetState({
                    dropdownMenu: ["Open", "Open external"]
                })
            }
        },
        query: async () => {
            if (window.location.pathname.indexOf("advanced") !== -1) {
                await this.promisedSetState({
                    status: { id: 1, name: "professional", value: "advanced" },
                    columns: [
                        { name: 'name', value: 'name', min_width: '350px' },
                        { name: 'primary', value: 'primary', min_width: '150px', noSort: true },
                        { name: 'type', value: 'type', min_width: '150px', noSort: true },
                        { name: 'category', value: 'category', min_width: '150px', noSort: true },
                        { name: 'client', value: 'client', min_width: '350px', noSort: true },
                        { name: 'channels', value: 'channels', noSort: true },
                        { name: 'created', value: 'created', min_width: '150px' }
                    ]
                })
            } else if (window.location.pathname.indexOf("standard") !== -1) {
                await this.promisedSetState({
                    status: { id: 2, name: "standard", value: "standard" },
                    columns: [
                        { name: 'name', value: 'name', min_width: '350px' },
                        { name: 'primary', value: 'primary', min_width: '150px', noSort: true },
                        { name: 'type', value: 'type', min_width: '150px', noSort: true },
                        { name: 'client', value: 'client', min_width: '350px', noSort: true },
                        { name: 'channels', value: 'channels', noSort: true },
                        { name: 'created', value: 'created', min_width: '150px' }
                    ]
                })
            } else if (window.location.pathname.indexOf("template") !== -1) {
                await this.promisedSetState({
                    status: { id: 2, name: "template", value: "template" },
                    columns: [
                        { name: 'name', value: 'name', min_width: '350px' },
                        { name: 'type', value: 'type', min_width: '150px', noSort: true },
                        { name: 'category', value: 'category', min_width: '150px', noSort: true },
                        { name: 'created', value: 'created', min_width: '150px' }
                    ]
                })
            }
            this.functions.orders(true);
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.query();
        },
        orders: async (init, search) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                (Array.isArray(this.state.selected_tags) ? this.state.selected_tags : []).map((tag) => {
                    params = params + "&tags[]=" + tag.name + "___" + (tag.exclude ? "exclude" : "include");
                });
                let response = await this.calls.orders(params);
                if (!search || (search && search == this.state.search)) {
                    await this.promisedSetState({
                        orders: response.data,
                        meta: response.meta
                    });
                }
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        remove: async () => {
            let temp = JSON.parse(JSON.stringify(this.state.remove));
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.remove(temp.id);
                await this.promisedSetState({ orders: this.state.orders.filter((item) => { return item.id !== temp.id }) });
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === temp.id) { item.loading = false } return item });
                await this.promisedSetState({ orders: this.state.orders, error: error && error.body ? error.body.message : "Something went wrong" });
            }
            this.props.updateStatistics();
        },
        updatePrimary: async (row) => {
            this.state.orders.map((item) => { if (item.id === row.id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.updatePrimary(row.id, { primary: !row.primary });
                this.state.orders.map((item) => { if (item.id === row.id) { item.primary = !row.primary } return item });
                await this.promisedSetState({ orders: this.state.orders });
            } catch (error) {
                await this.promisedSetState({ orders: this.state.orders, error: error && error.body ? error.body.message : "Something went wrong" });
            }
            this.state.orders.map((item) => { if (item.id === row.id) { item.loading = false } return item });
            await this.promisedSetState({ orders: this.state.orders });
        },
        clone: async (id) => {
            this.state.orders.map((item) => { if (item.id === id) { item.loading = true } return item });
            await this.promisedSetState({ orders: this.state.orders });
            try {
                await this.calls.clone(id);
                await this.promisedSetState({
                    report: null,
                    template: null,
                    closed: true,
                    loading_create: false,
                    limit: 10,
                    page: 1,
                    search: "",
                    sort: "created",
                    direction: "descending"
                });
                this.functions.orders();
            } catch (error) {
                this.state.orders.map((item) => { if (item.id === id) { item.loading = false } return item });
            }
            this.props.updateStatistics();
        },
        menu: async (option, row) => {
            if (option === "Open") {
                if (!row.template) {
                    if (row.freemium) {
                        if (window.location.hostname === 'localhost') {
                            window.open("http://localhost:5001/v2/reports/standard/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'app.adcredo.io') {
                            window.open("https://app.adcredo.io/v2/reports/standard/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'dev.adcredo.io') {
                            window.open("https://dev.adcredo.io/v2/reports/standard/" + row.campaign + "/" + row.id);
                        }
                        //this.props.history.push("/v2/reports/standard/" + row.campaign + "/" + row.id);
                    } else {
                        if (window.location.hostname === 'localhost') {
                            window.open("http://localhost:5001/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'app.adcredo.io') {
                            window.open("https://app.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'dev.adcredo.io') {
                            window.open("https://dev.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        }
                        //this.props.history.push("/v2/reports/advanced/" + row.campaign + "/" + row.id);
                    }
                } else {
                    if (row.masterTemplate) {
                        if (window.location.hostname === 'localhost') {
                            window.open("http://localhost:5001/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'app.adcredo.io') {
                            window.open("https://app.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'dev.adcredo.io') {
                            window.open("https://dev.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        }
                        //this.props.history.push("/v2/reports/advanced/" + row.campaign + "/" + row.id);
                    } else if (row.freemium) {
                        if (window.location.hostname === 'localhost') {
                            window.open("http://localhost:5001/v2/reports/standard/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'app.adcredo.io') {
                            window.open("https://app.adcredo.io/v2/reports/standard/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'dev.adcredo.io') {
                            window.open("https://dev.adcredo.io/v2/reports/standard/" + row.campaign + "/" + row.id);
                        }
                    } else {
                        if (window.location.hostname === 'localhost') {
                            window.open("http://localhost:5001/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'app.adcredo.io') {
                            window.open("https://app.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        } else if (window.location.hostname === 'dev.adcredo.io') {
                            window.open("https://dev.adcredo.io/v2/reports/advanced/" + row.campaign + "/" + row.id);
                        }
                        //this.props.history.push("/v2/reports/advanced/" + row.campaign + "/" + row.id);
                    }
                }
            } else if (option === "Delete") {
                await this.promisedSetState({
                    remove: row
                });
            } else if (option === "Open external") {
                if (window.location.hostname === 'localhost') {
                    window.open("http://localhost:5001/" + "advanced" + "/report/" + row.id);
                } else if (window.location.hostname === 'app.adcredo.io') {
                    window.open("https://app.adcredo.io/" + "advanced" + "/report/" + row.id);
                } else if (window.location.hostname === 'dev.adcredo.io') {
                    window.open("https://dev.adcredo.io/" + "advanced" + "/report/" + row.id);
                }
            } else if (option === "Edit") {
                await this.promisedSetState({
                    closed: row.freemium ? "new_standard" : "new_prof",
                    report: row.template ? null : row,
                    template: row.template ? row : null
                });
            } else if (option === "Share template") {
                await this.promisedSetState({
                    shared_template: row
                });
            } else if (option === "Clone") {
                this.functions.clone(row.id);
            } else if (option === "Set as Primary" || option === "Set as Secondary") {
                this.functions.updatePrimary(row);
            }
        },
        updateNameError: async (newValue) => {
            this.setState({ name_error: newValue });
        },
        history: async () => {
            try {
                window.history.pushState({
                    page: this.state.page,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    direction: this.state.direction,
                    ...(this.state.search !== "" && { search: this.state.search }),
                }, "",
                    ("?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" ? ("&search=" + this.state.search) : ""))
                );
            } catch (error) {
                console.log(error)
            }
        },
        handleSetTags: async (value) => {
            await this.promisedSetState({
                selected_tags: value
            });
            this.functions.orders()
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                page: 1,
                client: client
            });
            this.functions.orders();
        }
    };

    calls = {
        orders: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listReports?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.status.value === "template" ? "&template=true" : "") + "&status=" + this.state.status.value + (window.location.pathname.indexOf("template") === -1 && this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + params;
            return apiRegister.call(options, url);
        },
        updatePrimary: (id, data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateReportPrimary?report=" + id;
            return apiRegister.call(options, url);
        },
        clone: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', {});
            let url = apiRegister.url.api + "/v3/adcredo/cloneReport?report=" + id;
            return apiRegister.call(options, url);
        },
        remove: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeReport?report_id=" + id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        buttons: () => {
            let buttons = [];
            if (window.location.pathname.indexOf("advanced") !== -1) {
                buttons = [{ text: "Create new", icon: ClipboardCheckIcon, value: "new_prof", loading: false, show: !this.state.loading }];
            } else if (window.location.pathname.indexOf("standard") !== -1) {
                buttons = [{ text: "Create new", icon: ClipboardIcon, value: "new_standard", loading: false, show: !this.state.loading }];
            } else {
                buttons = [
                    { text: "New Professional", icon: ClipboardCheckIcon, value: "new_prof", loading: false, show: !this.state.loading },
                    { text: "New Standard", icon: ClipboardIcon, value: "new_standard", loading: false, show: !this.state.loading }
                ];
            }
            return buttons;
        },
        reportTypes: () => {
            let string = null;
            try {
                if (window.location.pathname.indexOf("reports/advanced") !== -1) {
                    string = "Professional Reports";
                }
                if (window.location.pathname.indexOf("reports/standard") !== -1) {
                    string = "Standard Reports";
                }
                if (window.location.pathname.indexOf("reports/template") !== -1) {
                    string = "Template Reports";
                }
            } catch (error) { }
            return string;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative">
                            <div className="text-2xl font-bold">
                                {this.renders.reportTypes() && <span className="mr-2">{this.renders.reportTypes()}</span>}
                            </div>
                        </div>
                        <div className="flex">
                            {
                                (this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? [] : this.renders.buttons()).map((item) => {
                                    return (
                                        <button
                                            onClick={async () => {
                                                await this.promisedSetState({
                                                    closed: item.value
                                                });
                                            }}
                                            className={(!item.disabled ? (item.red ? "bg-red-500 hover:bg-red-600 focus:ring-red-500 text-white" : "bg-purple-500 hover:bg-purple-600 focus:ring-purple-500 text-white") : " cursor-not-allowed bg-gray-100 text-gray-400") + " inline-flex items-center justify-center shadow relative ml-4 rounded-md h-10 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2"}
                                        >
                                            <span>{item.text}</span>
                                            {
                                                item.icon &&
                                                <item.icon className="ml-2 h-5 w-5" />
                                            }
                                            {
                                                item.loading &&
                                                <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading reports ...</div>
                    </div>
                }

                <SlideoutTailwind
                    open={this.state.closed == "new_prof" || this.state.closed == "new_standard"}
                    title={this.state.status.value !== "template" ? (this.state.report && this.state.report.id ? "Edit report" : "Create report") : (this.state.template && this.state.template.id ? "Edit template" : "Create template")}
                    submitButtonText={this.state.status.value !== "template" ? (this.state.report && this.state.report.id ? "Save" : "Create") : (this.state.template && this.state.template.id ? "Save" : "Create")}
                    large={false}
                    medium={true}
                    noPadding={true}
                    secondaryButton={false}
                    loader={this.state.loading_create}
                    onCancel={() => {
                        this.promisedSetState({ closed: true, report: null, template: null });
                    }}
                    onSubmit={async () => {
                        this.setState({
                            loading_create: true
                        });
                        if ((this.state.report && this.state.report.id) || (this.state.template && this.state.template.id)) {
                            this.refs.CreateNewReport.functions.update();
                        } else {
                            this.refs.CreateNewReport.functions.create();
                        }
                    }}
                >
                    {
                        this.state.closed == "new_prof" &&
                        <CreateReport
                            type={this.state.status.value}
                            report={this.state.report}
                            template={this.state.template}
                            ref="CreateNewReport"
                            history={this.props.history}
                            onNameChange={this.functions.updateNameError}
                            onCreated={async () => {
                                await this.promisedSetState({
                                    report: null,
                                    template: null,
                                    closed: true,
                                    loading_create: false,
                                    limit: 10,
                                    page: 1,
                                    search: "",
                                    sort: "created",
                                    direction: "descending"
                                });
                                this.functions.orders();
                            }}
                            onError={() => {
                                this.setState({
                                    loading_create: false
                                });
                            }}
                        />
                    }
                    {
                        this.state.closed == "new_standard" &&
                        <CreateReportStandard
                            type={this.state.status.value}
                            report={this.state.report}
                            template={this.state.template}
                            ref="CreateNewReport"
                            history={this.props.history}
                            onNameChange={this.functions.updateNameError}
                            onCreated={async () => {
                                await this.promisedSetState({
                                    report: null,
                                    template: null,
                                    closed: true,
                                    loading_create: false,
                                    limit: 10,
                                    page: 1,
                                    search: "",
                                    sort: "created",
                                    direction: "descending"
                                });
                                this.functions.orders();
                            }}
                            onError={() => {
                                this.setState({
                                    loading_create: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*SHARE REPORT*/}
                <SlideoutTailwind
                    open={!!this.state.shared_template}
                    title="Share template"
                    submitButtonText={"Share"}
                    secondaryButton={false}
                    loader={this.state.loading_share}
                    onCancel={() => {
                        this.promisedSetState({ shared_template: null });
                    }}
                    onSubmit={async () => {
                        this.setState({
                            loading_share: true
                        });
                        this.refs.ShareReport.functions.share();
                    }}
                >
                    <ShareReport
                        template={this.state.shared_template}
                        ref="ShareReport"
                        onCreated={async () => {
                            await this.promisedSetState({
                                loading_share: false,
                                shared_template: null,
                            });
                        }}
                        onError={() => {
                            this.setState({
                                loading_share: false
                            });
                        }}
                    >

                    </ShareReport>
                </SlideoutTailwind>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.remove();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*ERROR MODAL*/}
                <WarningModalTailwind
                    open={this.state.error ? true : false}
                    title={"Error"}
                    description={this.state.error}
                    noCloseButton={true}
                    submitButtonText={"Ok"}
                    onClose={async (value) => {
                        await this.promisedSetState({ error: null });
                    }}
                    onSubmit={async (value) => {
                        await this.promisedSetState({ error: null });
                    }}
                    onInput={(value) => {

                    }}
                />

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                            <TableFilters
                                onLimit={async (value) => {
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: value
                                    })
                                    this.functions.orders();
                                }}
                                onNext={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.orders();
                                    this.functions.history();
                                }}
                                onPrevious={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.orders();
                                    this.functions.history();
                                }}
                                onSearch={async (value) => {
                                    await this.promisedSetState({
                                        loading_partial: true,
                                        search: value
                                    });
                                    setTimeout(async () => {
                                        if (value === this.state.search) {
                                            await this.promisedSetState({
                                                page: 1
                                            });
                                            this.functions.orders(false, value);
                                        }
                                    }, 400);
                                }}
                                limit={this.state.limit}
                                page={this.state.page}
                                total={this.state.meta ? this.state.meta.total : 0}
                                showPaginaton={!this.state.loading}
                                showPaginationSearch={!this.state.loading}
                                showAdvanced={!this.state.loading}
                                advancedFilter={true}
                                advancedFilters={this.state.advancedFilters}
                                filters={[]}
                                hideUserDropdown={true}
                                breadcrumb={"Report"}
                                buttons={[]}
                                buttonTypes={{}}
                                buttonLoaders={{}}
                                showClient={true}
                                lockClient={false}
                                onClient={async (client) => {

                                }}
                                show_table_settings={false}
                                onOpenTableSettings={async () => {
                                    await this.promisedSetState({ show_table_settings: true });
                                }}
                                onSetTags={async (value) => {
                                    this.functions.handleSetTags(value)
                                }}
                                url_tags={this.state.selected_tags}
                                showTagsDropdown={true}
                                tagType={"component"}
                                searchInputValue={this.state.search}
                                search={this.state.search}
                            />

                            {/*NO ORDERS*/}
                            {
                                this.state.orders.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-sm font-medium">No reports available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.orders.length > 0 &&
                                <div className="relative h-full flex flex-1 border-t">
                                    <div className="overflow-auto absolute table-overflow w-full h-full">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                            <thead className="sticky z-60 bg-white top-0">
                                                <tr>
                                                    <th style={{ maxWidth: "50px", width: "50px" }} scope="col" className='bg-gray-100 border-r bg-opacity-100'></th>
                                                    {this.state.columns.filter(col => { return !col.disabled }).map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    if (!item.noSort) {
                                                                        await this.promisedSetState({
                                                                            page: 1,
                                                                            sort: item.value,
                                                                            direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                        });
                                                                        this.functions.orders();
                                                                    }
                                                                }}
                                                                style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 text-left text-xxs bg-gray-100 font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                            >
                                                                <div className="flex flex-row items-center justify-between">
                                                                    <div className="mr-4">{item.name}</div>
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex justify-start flex-col">
                                                                            <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white tiny_table_row divide-gray-300 relative">
                                                {
                                                    this.state.orders.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        item.hover = true;
                                                                        this.setState({
                                                                            orders: this.state.orders
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        item.hover = false;
                                                                        this.setState({
                                                                            orders: this.state.orders
                                                                        })
                                                                    }}
                                                                    className="border-b" key={item.id}>
                                                                    <td className={(item.hover ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " px-4 border-r border-gray-300 overflow-visible"}>
                                                                        <div className="">
                                                                            <Menu as="div" className="">
                                                                                <div>
                                                                                    <Menu.Button className={"cursor-pointer bg-gray-50 flex relative w-6 h-6 justify-center items-center rounded border border-gray-300 text-xms font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"}>
                                                                                        <ChevronDownIcon className="w-4" aria-hidden="true" />
                                                                                        {
                                                                                            item.loading &&
                                                                                            <div className="w-full h-full absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                                <div style={{ borderTopColor: "transparent" }}
                                                                                                    class="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                                                                            </div>
                                                                                        }
                                                                                    </Menu.Button>
                                                                                </div>
                                                                                <Transition
                                                                                    as={Fragment}
                                                                                    enter="transition ease-out duration-100"
                                                                                    enterFrom="transform opacity-0 scale-95"
                                                                                    enterTo="transform opacity-100 scale-100"
                                                                                    leave="transition ease-in duration-75"
                                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                                    leaveTo="transform opacity-0 scale-95"
                                                                                >
                                                                                    <Menu.Items className={`border absolute z-60 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                                                        {/* <Menu.Items className={`${this.state.orders.length - (index + 1) < 5 && this.state.orders.length > 5 ? "bottom-100 left-100" : ""} border absolute z-50 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}> */}

                                                                                        <div className="py-1">
                                                                                            {
                                                                                                this.state.dropdownMenu.filter((option) => {
                                                                                                    if (option == "Edit") {
                                                                                                        /*
                                                                                                        if (!item.template && item.masterTemplate) {
                                                                                                            return false;
                                                                                                        } else {
                                                                                                            return true;
                                                                                                        }
                                                                                                        */
                                                                                                        return true;
                                                                                                    } else {
                                                                                                        return ((item.template && option !== "Set as Primary") && (item.template && option !== "Set as Secondary")) || !item.template
                                                                                                    }
                                                                                                }).filter((option) => {
                                                                                                    if (option == "Set as Primary") {
                                                                                                        return !item.primary
                                                                                                    } else if (option == "Set as Secondary") {
                                                                                                        return item.primary
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).filter((option) => {
                                                                                                    if (option === "Open external") {
                                                                                                        return item.public && !item.template;
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).filter((option) => {
                                                                                                    if (option === "Share template") {
                                                                                                        return item.template && this.state.user.agency_admin_access && item.premium;
                                                                                                    } else {
                                                                                                        return true;
                                                                                                    }
                                                                                                }).map((option) => {
                                                                                                    return (
                                                                                                        <Menu.Item>
                                                                                                            {({ active }) => (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        this.functions.history();
                                                                                                                        this.functions.menu(option, item);
                                                                                                                    }}
                                                                                                                    className={cn(
                                                                                                                        active ? 'bg-gray-50 text-gray-900' : 'text-gray-700',
                                                                                                                        'px-4 py-2 text-sm relative font-medium flex flex-row cursor-pointer',
                                                                                                                        (option == "Delete") ? 'bg-red-100 text-red-500' : ''
                                                                                                                    )}
                                                                                                                >
                                                                                                                    {option}
                                                                                                                    <div className="flex flex-1"></div>
                                                                                                                    {
                                                                                                                        (option == "Set as Primary") &&
                                                                                                                        <CheckCircleIcon className="w-5" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (option == "Open" || option == "Open external") &&
                                                                                                                        <ExternalLinkIcon className="w-5" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (option == "Share template") &&
                                                                                                                        <ShareIcon className="w-5" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (option == "Edit") &&
                                                                                                                        <PencilAltIcon className="w-5" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (option == "Clone") &&
                                                                                                                        <DuplicateIcon className="w-5" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (option == "Delete") &&
                                                                                                                        <TrashIcon className="w-5" />
                                                                                                                    }
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Menu.Item>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xms border-gray-300"}>
                                                                        <div className="flex flex-row items-center justify-start">
                                                                            <div onClick={() => {
                                                                                this.functions.history();
                                                                                this.functions.menu("Open", item);
                                                                            }}>   {item.name ? item.name : "-"}</div>
                                                                            {
                                                                                Array.isArray(item.tags) && item.tags.length > 0 &&
                                                                                <div class="flex flex-row whitespace-no-wrap ml-2">
                                                                                    {
                                                                                        item.tags.map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <div className="text-xxs text-gray-900 font-medium mr-1 py-0.5 rounded-full bg-gray-100 border px-2">{item.name}</div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        window.location.pathname.indexOf("template") === -1 &&
                                                                        <td className={((item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xxs border-gray-300"}>
                                                                            <span
                                                                                className={classNames("px-2 py-1 inline-flex capitalize text-xxms font-semibold rounded-md",
                                                                                    {
                                                                                        ["bg-purple-100 text-purple-500"]: item.primary
                                                                                    })
                                                                                }>
                                                                                {item.primary ? "Primary" : "-"}
                                                                                {
                                                                                    item.primary &&
                                                                                    <CheckCircleIcon className="w-3 ml-1"></CheckCircleIcon>
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    }
                                                                    <td className={((item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xxs border-gray-300"}>
                                                                        <span
                                                                            className={classNames("px-2 py-1 inline-flex capitalize text-xxms font-semibold rounded-md",
                                                                                {
                                                                                    ["bg-gray-100 text-gray-500"]: !item.premium,
                                                                                    ["bg-indigo-100 text-indigo-500"]: item.premium
                                                                                })
                                                                            }>
                                                                            {item.premium ? "Professional" : (item.freemium ? "Standard" : "Template")}
                                                                            {
                                                                                item.premium &&
                                                                                <ClipboardCheckIcon className="w-3 ml-1"></ClipboardCheckIcon>
                                                                            }
                                                                            {
                                                                                !item.premium &&
                                                                                <ClipboardIcon className="w-3 ml-1"></ClipboardIcon>
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                    {
                                                                        window.location.pathname.indexOf("standard") === -1 &&
                                                                        <td className={((item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 items-center text-xxs border-gray-300"}>
                                                                            <span
                                                                                className={classNames("px-2 py-1 inline-flex capitalize text-xxms font-semibold rounded-md",
                                                                                    {
                                                                                        ["bg-gray-100 text-gray-500"]: !item.masterTemplate,
                                                                                        ["bg-purple-100 text-purple-500"]: item.masterTemplate
                                                                                    })
                                                                                }>
                                                                                {item.masterTemplate ? "Scale" : "Regular"}
                                                                                {
                                                                                    item.masterTemplate &&
                                                                                    <StarIcon className="w-3 ml-1"></StarIcon>
                                                                                }
                                                                                {
                                                                                    !item.masterTemplate &&
                                                                                    <ChartPieIcon className="w-3 ml-1"></ChartPieIcon>
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                    }
                                                                    {
                                                                        window.location.pathname.indexOf("template") === -1 &&
                                                                        <td className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50" : "bg-white") + " border-r px-3 py-2 font-medium whitespace-no-wrap  items-center text-xms   border-gray-300"}>
                                                                            <div className="flex flex-row items-center">
                                                                                <div className="h-6 w-6 border rounded-md bg-gray-50 overflow-hidden flex justify-center items-center">
                                                                                    {
                                                                                        item.client &&
                                                                                        <img src={item.client.logo} className="bg-cover" />
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-2 whitespace-no-wrap">
                                                                                    {item.client ? item.client.name : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    {
                                                                        window.location.pathname.indexOf("template") === -1 &&
                                                                        <td className={((this.state.sort === 'channels' || item.hover) ? "bg-gray-50" : "") + " border-r px-4 sm:px-6 py-4 items-center text-sm  border-gray-300"}>
                                                                            <div className="flex flex-row pl-2">
                                                                                {
                                                                                    item.channels && item.custom_platform_information && Object.keys(item.channels).filter((channel) => {
                                                                                        return item.custom_platform_information[channel]
                                                                                    }).map((channel) => {
                                                                                        return (
                                                                                            <div
                                                                                                style={{ backgroundColor: "#" + item.custom_platform_information[channel].color }}
                                                                                                className={"w-7 h-7 rounded-full p-1.5 border-2 border-white -ml-2"}>
                                                                                                {
                                                                                                    item.custom_platform_information[channel].logo &&
                                                                                                    <div
                                                                                                        className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                        style={{ backgroundImage: "url(" + item.custom_platform_information[channel].logo + ")" }}></div>
                                                                                                }
                                                                                                {
                                                                                                    !item.custom_platform_information[channel].logo &&
                                                                                                    <div className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                                                                        {channel[0]}{channel[1]}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {item.channels && item.channels.adform &&
                                                                                    <div
                                                                                        className="w-7 h-7 bg-adform-500 rounded-full p-1.5 border-2 border-white -ml-2">
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.facebook &&
                                                                                    <div
                                                                                        className="w-7 h-7 bg-facebook-500 rounded-full p-1.5 border-2 border-white -ml-2">
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.linkedin &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-linkedin-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.google &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-google-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.tiktok &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-black rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.twitter &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-twitter-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.google_analytics &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.google_analytics_4 &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.snapchat &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-snapchat-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.bing &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-bing-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.bidtheatre &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-bidtheatre-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.dv360 &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-dv360-500 rounded-full p-1.5  border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.cm360 &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-cm360-500 rounded-full p-1.5  border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                                {item.channels && item.channels.google_search_console &&
                                                                                    <div
                                                                                        className={'w-7 h-7 bg-google_search_console-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                        <div
                                                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                            style={{ backgroundImage: "url(" + require('../assets/images/google_search_console_icon.png') + ")" }}></div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td className={((this.state.sort === 'created' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xms   border-gray-300"}>
                                                                        {item.created ? moment(item.created).format('YYYY-MM-DD') : '-'}
                                                                    </td>
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                }

            </div>
        )
    }
}

export default ReportList;
