import React, { Component, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import cn from "classnames";
import { CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClockIcon, DownloadIcon, ExternalLinkIcon, FolderIcon, PencilAltIcon, LightBulbIcon, NewspaperIcon, SearchIcon, ThumbDownIcon, TrashIcon, ViewGridIcon, PlusCircleIcon, BeakerIcon, StopIcon, LockClosedIcon, PlusIcon, SaveAsIcon, ExclamationIcon, PresentationChartLineIcon, ReplyIcon } from '@heroicons/react/outline';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import WizardModal from '../moduleFiles/wizardModal';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SuccessModal from '../moduleFiles/successModal';
import moment from 'moment';
import { random, round } from '@turf/turf';
import SyncWithBing from '../moduleFiles/syncWithBing';
import FeatherIcon from 'feather-icons-react';
import SweetAlert from 'sweetalert-react'
import 'sweetalert/dist/sweetalert.css';
import Loader from '../components/loader';
import InputTailwind from '../moduleFiles/inputTailwind';
import SwitchTailwind from '../moduleFiles/switchTailwind';
import CreateBudgetPlanItem from '../moduleFiles/createBudgetPlanItem';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import { PauseIcon, PlayIcon } from '@heroicons/react/solid';
import ToolTip from '../moduleFiles/toolTip';
import ReactTooltip from 'react-tooltip';

class OrderBudgetPlanerTemp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            allow_updates: {},
            order: {},
            client: {},
            errors: {},
            loading_channels: {},
            tab: { id: 1, name: "Max budget" },
            tabs: [
                { id: 1, name: "Current Budget" },
                { id: 2, name: "Upcoming" },
                { id: 3, name: "History" }
            ],
            currency: "",
            // today: moment().add(2, "days").format("YYYY-MM-DD"),
            today: moment().format("YYYY-MM-DD"),
            advancedFilters: [
                {
                    showOnTop: true,
                    multiSelect: false,
                    maxWidth: true,
                    type: "orders",
                    placeholder: "Orders",
                    search: true,
                    search_value: "",
                    skipInternalSearch: true,
                    total: 0,
                    page: 1,
                    loading: false,
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All status (default)", value: "all" },
                    options: [],
                    selected: {}
                }
            ],
            client_change: false,
            order_search: "",
            order_page: 1,
            orders: [],
            show: {}
        }
    }

    async componentDidMount() {
        if (this.props.setOrder) {
            this.props.setOrder({ name: "..." });
        }
        await this.functions.getOrder();
        this.functions.animate();
        await this.functions.getOrders(true, false, false);
        this.state.advancedFilters[0].defaultSelected = {
            id: this.state.order.id,
            name: this.state.order.name,
        }
        this.state.advancedFilters[0].selected = this.state.advancedFilters[0].defaultSelected;
        await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    functions = {
        days: async (day, campaign, channel, type, adgroup = false) => {
            if (type === "adgroup") {
                if (adgroup.scheduleActivated) {
                    await this.promisedSetState({ errors: {} });
                    await this.functions.animate("error_" + adgroup.id, { remove: ["h-12"], add: ["h-0"] });
                    //await this.functions.animate("info_" + adgroup.id, { remove: ["h-40"], add: ["h-24"] });
                    if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => { return adgroup[item] }).length === 1 && adgroup[day]) {
                        this.state.errors[adgroup.id] = "You need to have at least one day selected";
                        await this.promisedSetState({ errors: this.state.errors });
                        await this.functions.animate("error_" + adgroup.id, { remove: ["h-0"], add: ["h-12"] });
                        //await this.functions.animate("info_" + adgroup.id, { remove: ["h-24"], add: ["h-40"] });
                    } else {
                        adgroup[day] = !adgroup[day];
                        adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                        this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                            if (item.id == campaign.id) {
                                if (Array.isArray(item.adgroups) && item.adgroups.length > 0) {
                                    item.adgroups = item.adgroups.map((inner_item) => {
                                        if (inner_item.id == adgroup.id) {
                                            inner_item = adgroup;
                                        }
                                        return inner_item;
                                    });
                                }
                                //item = campaign;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            budget_planer: this.state.budget_planer
                        });
                        this.functions.updateAdgroupSchedule(campaign, channel, adgroup);
                    }
                }
            } else {
                if (campaign.scheduleActivated) {
                    await this.promisedSetState({ errors: {} });
                    await this.functions.animate("error_" + campaign.id, { remove: ["h-12"], add: ["h-0"] });
                    //await this.functions.animate("info_" + campaign.id, { remove: ["h-40"], add: ["h-24"] });
                    if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => { return campaign[item] }).length === 1 && campaign[day]) {
                        this.state.errors[campaign.id] = "You need to have at least one day selected";
                        await this.promisedSetState({ errors: this.state.errors });
                        await this.functions.animate("error_" + campaign.id, { remove: ["h-0"], add: ["h-12"] });
                        //await this.functions.animate("info_" + campaign.id, { remove: ["h-24"], add: ["h-40"] });
                    } else {
                        campaign[day] = !campaign[day];
                        campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                        this.state.budget_planer[channel] = this.state.budget_planer[channel].map((item) => {
                            if (item.id == campaign.id) {
                                item = campaign;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            budget_planer: this.state.budget_planer
                        });
                        this.functions.updateCampaignSchedule(campaign, channel);
                    }
                }
            }
        },
        updateCampaignSchedule: async (campaign, channel) => {
            campaign.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            let buff = this.state.initial_data[channel].filter((item) => { return item.id == campaign.id })[0];

            let changelog = {
                initial_data: buff,
                updated_data: {
                    ...campaign
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "update",
                type: "campaign_schedule"
            }
            let body = {
                budgetplaner_activated: true,
                schedule_activated: true,
                schedule_monday: campaign.scheduleMonday,
                schedule_tuesday: campaign.scheduleTuesday,
                schedule_wednesday: campaign.scheduleWednesday,
                schedule_thursday: campaign.scheduleThursday,
                schedule_friday: campaign.scheduleFriday,
                schedule_saturday: campaign.scheduleSaturday,
                schedule_sunday: campaign.scheduleSunday,
                budgetplaner_total: campaign.budgetPlanerTotal,
                skip_trigger: true,
                changelog
            };
            try {
                let response = await this.calls.updateCampaignSchedule(body, campaign.internal_id);
            } catch (error) {
                this.state.errors[campaign.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            campaign.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });

            this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
        },
        updateAdgroupSchedule: async (campaign, channel, adgroup) => {
            adgroup.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            let buff = this.state.initial_data[channel].filter((item) => { return item.id == campaign.id })[0];

            let changelog = {
                initial_data: buff,
                updated_data: {
                    ...campaign
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "update",
                type: "adgroup_schedule"
            }
            let body = {
                budgetplaner_activated: true,
                schedule_activated: true,
                schedule_monday: adgroup.scheduleMonday,
                schedule_tuesday: adgroup.scheduleTuesday,
                schedule_wednesday: adgroup.scheduleWednesday,
                schedule_thursday: adgroup.scheduleThursday,
                schedule_friday: adgroup.scheduleFriday,
                schedule_saturday: adgroup.scheduleSaturday,
                schedule_sunday: adgroup.scheduleSunday,
                budgetplaner_total: adgroup.budgetPlanerTotal,
                skip_trigger: true,
                changelog
            };
            try {
                let response = await this.calls.updateAdgroupSchedule(body, campaign.internal_id, adgroup.id);
            } catch (error) {
                this.state.errors[campaign.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            adgroup.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
            this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
        },

        maxBudgetReached: async (item) => {
            await this.functions.animate("max_error_" + item.id, { remove: ['h-0'], add: ['h-12'] });

            //await this.functions.animate("max_error_" + item.id, { remove: ['h-12'], add: ['h-0'] });
        },
        animate: async (id, property, style = false) => {
            return new Promise(async (resolve) => {
                try {
                    if (!id) {
                        if (this.state.order.maxBudgetEnabled) {
                            document.getElementById('hide_3').classList.replace('w-0', 'w-40');
                            document.getElementById('manual').classList.replace('flex-1', 'w-0');
                            document.getElementById('automatic').classList.replace('w-0', 'flex-1');

                            document.getElementById('manual').classList.add('text-white');
                            document.getElementById('automatic').classList.replace('text-white', 'text-purple-500');
                        } else {
                            if (!document.getElementById('hide_3').classList.contains('w-0') && document.getElementById('hide_3').classList.contains('w-40')) {
                                document.getElementById('hide_3').classList.replace('w-40', 'w-0');
                            }
                            document.getElementById('automatic').classList.replace('flex-1', 'w-0');
                            document.getElementById('manual').classList.replace('w-0', 'flex-1');

                            document.getElementById('automatic').classList.replace('text-purple-500', 'text-white');
                            document.getElementById('manual').classList.remove('text-white');


                        }
                    } else {
                        if (style) {
                            Object.keys(style).map((item) => {
                                document.getElementById(id).style[item] = style[item];
                            });
                            //document.getElementById(id).style.height = style;
                        } else {
                            document.getElementById(id).classList.remove(...property.remove);
                            document.getElementById(id).classList.add(...property.add);
                        }
                    }
                } catch (error) {

                }
                resolve();
            });
        },
        getOrders: async (init, search, pagination) => {
            this.state.advancedFilters[0].loading = true;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
            try {
                let response = await this.calls.getOrders();
                if (pagination) {
                    this.state.advancedFilters[0].options = this.state.advancedFilters[0].options.concat(response.data);
                } else if (!search || (search && search == this.state.order_search)) {
                    this.state.advancedFilters[0].options = response.data;
                }
                await this.promisedSetState({
                    advancedFilters: this.state.advancedFilters,
                });
                this.state.advancedFilters[0].total = response.meta.total;
            } catch (error) {
                console.log(error);
            }

            this.state.advancedFilters[0].loading = false;
            await this.promisedSetState({ advancedFilters: this.state.advancedFilters });
        },
        getIdFromPath: () => {
            try {
                return window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
            } catch (error) {
                return null
            }
        },
        updateMaxBudget: async () => {
            await this.promisedSetState({
                loading_maxbudget: true
            });
            let changelog = {
                initial_data: this.state.temp_order,
                updated_data: {
                    maxBudget: this.state.order.maxBudget,
                    maxBudgetAction: this.state.order.maxBudgetAction,
                    maxBudgetSubAction: this.state.order.maxBudgetSubAction,
                    crmId: this.state.order.crmId
                },
                level: "order",
                action: "update",
                order: this.functions.getIdFromPath(),
            }
            try {
                await this.calls.updateOrder({
                    maxBudgetEnabled: this.state.order.maxBudgetEnabled,
                    maxBudget: this.state.order.maxBudget,
                    maxBudgetSubAction: this.state.order.maxBudgetSubAction,
                    crmId: this.state.order.crmId,
                    changelog
                });
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_maxbudget: false, allow_update: false, temp_order: null });
        },
        updateExternalBudget: async (item, channel, type, daily = false) => {
            item.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            try {
                let changelog = {
                    initial_data: item,
                    updated_data: item,
                    level: type,
                    action: "updateLifeTimeBudget",
                    order: this.functions.getIdFromPath(),
                    type: "budgetplaner"
                }

                await this.calls.updateExternalBudget({
                    item: item,
                    channel: channel,
                    type: type,
                    daily: daily,
                    changelog
                });
            } catch (error) {
                if (type === "campaign") {
                    this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                    await this.promisedSetState({ errors: this.state.errors });
                    await this.functions.animate("error_" + item.id, { remove: ["h-0"], add: ["h-12"] });
                } else {
                    this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                    await this.promisedSetState({ errors: this.state.errors });
                }
            }
            item.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        unblockMaxBudgetReached: async () => {
            await this.promisedSetState({
                loading_unblock: true
            });
            try {
                await this.calls.updateOrder({
                    unblockMaxBudgetReached: true
                });
                this.functions.getOrder(true);
            } catch (error) {
                await this.promisedSetState({ error: true, error_message: error.body.message });
            }
            await this.promisedSetState({ loading_unblock: false });
        },
        updateMaxBudgetEnabled: async () => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({
                    loading_max_budget: true
                });
                let changelog = {
                    initial_data: { maxBudgetEnabled: this.state.order.maxBudgetEnabled },
                    updated_data: { maxBudgetEnabled: !this.state.order.maxBudgetEnabled },
                    level: "order",
                    action: "update",
                    order: this.functions.getIdFromPath(),
                    type: "max_budget"
                }
                try {
                    await this.calls.updateOrder({
                        maxBudgetEnabled: !this.state.order.maxBudgetEnabled,
                        changelog
                    });
                    this.state.order.maxBudgetEnabled = !this.state.order.maxBudgetEnabled
                    await this.promisedSetState({ loading_max_budget: false, order: this.state.order });
                    if (this.state.order.maxBudgetEnabled) {
                        this.functions.getOrderBudgetPlaner();
                    }
                } catch (error) {
                    await this.promisedSetState({ error: true, error_message: error.body.message });
                }
                await this.functions.animate();
                if (this.state.order.maxBudgetEnabled) {
                    for (let channel in this.state.budget_planer) {
                        for (let campaign of this.state.budget_planer[channel]) {
                            if (campaign.scheduleActivated) {
                                if (+this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal) {
                                    await this.functions.maxBudgetReached(campaign);
                                }
                            }
                            if (Array.isArray(campaign.adgroups)) {
                                for (let adgroup of campaign.adgroups) {
                                    if (adgroup.scheduleActivated) {
                                        if (+this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal) {
                                            await this.functions.maxBudgetReached(adgroup);
                                        }
                                    }
                                }
                            }
                        }

                    }
                }


                resolve();
            });
        },
        removeBudgetItems: async () => {
            this.state.order.budgetPlans = this.state.order.budgetPlans.map((item) => { if (item.selected) { item.loading = true }; return item })
            await this.promisedSetState({ remove: null });
            await this.promisedSetState({ remove_name: "" });
            await this.promisedSetState({ order: this.state.order });
            let buff = this.state.order.budgetPlans.filter((item) => { return item.selected }).map((item) => { return { ...item, status: "removed" } });
            let changelog = {
                initial_data: this.state.order.budgetPlans,
                updated_data: buff,
                level: "order",
                action: "remove",
                order: this.functions.getIdFromPath(),
                type: "budget_plan_item"
            }
            try {
                await this.calls.removeBudgetItems({ ids: this.state.order.budgetPlans.filter((item) => { return item.selected }).map((item) => { return item.id }), changelog });
                this.state.order.budgetPlans = this.state.order.budgetPlans.filter((item) => { return !item.selected });
                await this.promisedSetState({ order: this.state.order });
            } catch (error) {
                console.log(error)
                this.state.order.budgetPlans = this.state.order.budgetPlans.map((item) => { item.loading = false; return item })
                await this.promisedSetState({ order: this.state.order });
            }
        },
        getOrder: async (get_order_planer) => {
            return new Promise(async (resolve) => {
                await this.promisedSetState({ loading: true });
                try {
                    let response = await this.calls.order();
                    this.functions.getOrderBudgetPlaner();
                    if (get_order_planer) {
                        this.functions.getOrderBudgetPlaner();
                    }
                    await this.promisedSetState({ order: response.data, meta: response.meta, temp_order: response.data, ...(response.data.client && { client: response.data.client }) });
                    if (this.props.setOrder) {
                        this.props.setOrder(this.state.order);
                    }
                } catch (error) { }
                await this.promisedSetState({ loading: false });
                resolve();
            });
        },
        getOrderBudgetPlaner: async () => {
            await this.promisedSetState({ loading_planer: true });
            try {
                let response = await this.calls.getOrderBudgetPlaner();
                let currency = "";
                for (let channel in response.data) {
                    if (Array.isArray(response.data[channel])) {
                        response.data[channel].map((item) => {
                            if (item.currency) {
                                if (currency == "") {
                                    currency = item.currency;
                                } else if (currency != item.currency) {
                                    currency = "Multiple";
                                }
                            }
                        });
                    }
                }
                await this.promisedSetState({ budget_planer: response.data, initial_data: response.data ? JSON.parse(JSON.stringify(response.data)) : response.data, currency: currency });
            } catch (error) { }
            await this.promisedSetState({ loading_planer: false });
        },
        updateOrderAdgroupBudgetPlan: async (campaign, adgroup, channel) => {
            adgroup.loading = true;
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            let buff = this.state.initial_data[channel].filter((item) => { return item.id == campaign.id })[0];
            let changelog = {
                initial_data: buff,
                updated_data: {
                    ...adgroup,
                    budgetPlanerTotal: adgroup.budgetPlanerTotal
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "update",
                type: "budget_plan_adgroup"
            }
            try {
                let response = await this.calls.updateOrderAdgroupBudgetPlan({
                    ...(this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" && { schedule_start_date: moment().format("YYYY-MM-DD") }),
                    budgetplaner_total: adgroup.budgetPlanerTotal,
                    campaign: campaign.id,
                    adgroup: adgroup.id,
                    changelog
                });
                adgroup.external_daily_budget = response.data;
                await this.promisedSetState({ budget_planer: this.state.budget_planer });
            } catch (error) {
                this.state.errors[adgroup.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
            }
            adgroup.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        updateOrderCampaignBudgetPlan: async (campaign, channel, fix = false) => {
            campaign.loading = true;
            if (fix && campaign.budgetPlanerTotal === 0 && campaign.external_daily_budget > 0) {
                campaign.budgetPlanerTotal = this.renders.calculateTotalByDaily(campaign, false).total_budget;
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            await this.functions.animate("error_" + campaign.id, { remove: ["h-12"], add: ["h-0"] });
            //await this.functions.animate("info_" + campaign.id, { remove: ["h-40"], add: ["h-24"] });
            let buff = this.state.initial_data[channel].filter((item) => { return item.id == campaign.id })[0];
            let changelog = {
                initial_data: buff,
                updated_data: {
                    ...campaign,
                    budgetPlanerTotal: campaign.budgetPlanerTotal
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "update",
                type: "budget_plan_campaign"
            }
            try {
                let response = await this.calls.updateOrderCampaignBudgetPlan({
                    ...(this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" && { schedule_start_date: moment().format("YYYY-MM-DD") }),
                    budgetplaner_total: campaign.budgetPlanerTotal,
                    campaign: campaign.id,
                    channel: campaign.channel,
                    budget_id: campaign.shared_budget ? (campaign.channel === "google" ? campaign.resourceName : campaign.id) : null,
                    name: campaign.name,
                    changelog
                });
                campaign.external_daily_budget = response.data;
                await this.promisedSetState({ budget_planer: this.state.budget_planer });
            } catch (error) {
                this.state.errors[campaign.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
                await this.functions.animate("error_" + campaign.id, { remove: ["h-0"], add: ["h-12"] });
                //await this.functions.animate("info_" + campaign.id, { remove: ["h-24"], add: ["h-40"] });
            }
            campaign.loading = false;
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        },
        updateScheduler: async (item, value, not_pause = false, channel = false) => {
            let changelog = {
                initial_data: item,
                updated_data: {
                    ...item,
                    budgetPlanerActivated: value,
                    scheduleActivated: value,
                    scheduleMonday: value,
                    scheduleTuesday: value,
                    scheduleWednesday: value,
                    scheduleThursday: value,
                    scheduleFriday: value,
                    scheduleSaturday: value,
                    scheduleSunday: value
                },
                level: "order",
                order: this.functions.getIdFromPath(),
                action: "update",
                type: "scheduler"
            }
            item.loading = true;
            if (channel) {
                if (item.campaign) {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.campaign && inner_item.adgroups) {
                            inner_item.adgroups = inner_item.adgroups.map((inner_inner_item) => {
                                if (inner_inner_item.id == item.id) {
                                    inner_inner_item = item;
                                }
                                return inner_inner_item;
                            });
                        }
                        return inner_item;
                    });
                } else {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.id) {
                            inner_item = item;
                        }
                        return inner_item;
                    });

                }
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer, errors: {} });
            await this.functions.animate("error_" + item.id, { remove: ["h-12"], add: ["h-0"] });
            try {
                await this.calls.updateScheduler({
                    ...item,
                    value: value,
                    ...(not_pause && { not_pause: true }),
                    changelog
                });
                item.budgetPlanerActivated = value;
                item.scheduleActivated = value;
                if (!value) {
                    item.budgetPlanerTotal = 0;
                    if (item.shared_budget) {
                        item.campaigns = item.campaigns.map((item) => {
                            item.scheduleActivated = false;
                            item.budgetPlanerActivated = false;
                            return item;
                        });
                    }
                } else {
                    item.scheduleActivated = true;
                    item.budgetPlanerActivated = true;
                    ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                        if (!Object.keys(item).includes(day)) {
                            item[day] = true;
                        }
                    });
                }
            } catch (error) {
                this.state.errors[item.id] = error.body && error.body ? error.body.message : "Something went wrong";
                await this.promisedSetState({ errors: this.state.errors });
                await this.functions.animate("error_" + item.id, { remove: ["h-0"], add: ["h-12"] });
            }
            item.loading = false;
            if (!value && !this.state.errors[item.id]) {
                if (!not_pause) {
                    item.status = "paused"
                }
            } else if (value && !this.state.errors[item.id]) {
                item.status = "active"
            }
            if (channel) {
                if (item.campaign) {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.campaign && inner_item.adgroups) {
                            inner_item.adgroups = inner_item.adgroups.map((inner_inner_item) => {
                                if (inner_inner_item.id == item.id) {
                                    inner_inner_item = item;
                                }
                                return inner_inner_item;
                            });
                        }
                        return inner_item;
                    });
                } else {
                    this.state.budget_planer[channel] = this.state.budget_planer[channel].map((inner_item) => {
                        if (inner_item.id == item.id) {
                            inner_item = item;
                        }
                        return inner_item;
                    });

                }
            }
            await this.promisedSetState({ budget_planer: this.state.budget_planer });
        }
    }

    calls = {
        updateAdgroupSchedule: (data, campaign_id, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAdgroupSchedule?id=" + id + "&campaign=" + campaign_id;
            return apiRegister.call(options, url);
        },
        updateCampaignSchedule: (data, id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCampaignSchedule?id=" + id;
            return apiRegister.call(options, url);
        },
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?limit=10&page=" + this.state.order_page + "&sortBy=name&orderBy=descending&client=" + (this.state.client_change ? this.state.client_change.id : this.state.client.id) + (this.state.order_search !== "" ? ("&search=" + this.state.order_search) : "")
            return apiRegister.call(options, url);
        },
        order: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateOrder: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrder?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateExternalBudget: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateExternalBudget?order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        removeBudgetItems: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeOrderBudgetPlanItems?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        getOrderBudgetPlaner: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrderBudgetPlaner?id=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateOrderCampaignBudgetPlan: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderCampaignBudgetPlan?campaign=" + data.campaign + "&order=" + this.functions.getIdFromPath() + "&name=" + data.name;
            return apiRegister.call(options, url);
        },
        updateOrderAdgroupBudgetPlan: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateOrderAdgroupBudgetPlan?campaign=" + data.campaign + "&id=" + data.adgroup + "&order=" + this.functions.getIdFromPath();
            return apiRegister.call(options, url);
        },
        updateScheduler: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateScheduler?order=" + this.functions.getIdFromPath() + "&name=" + data.name;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        checkBudget2: (campaign) => {
            let check  = false;
            try {
                if (this.state.budget_planer) {
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((item) => {
                                if (item.id == campaign.id) {
                                    if ('external_lifetime_budget' in item) {
                                        check = true;
                                    }
                                    if (Array.isArray(item.adgroups)) {
                                        item.adgroups.map((adgroup) => {
                                            if ('external_lifetime_budget' in adgroup) {
                                                check = true;
                                            }
                                        });
                                    }
                                }
                                
                            });
                        }
                    }
                }
            } catch (error) { }
            return check;
        },
        checkBudget: () => {
            let check = {
                contain: false,
                locked: false
            };
            let contain = false;
            try {
                if (this.state.budget_planer) {
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((campaign) => {
                                if ('external_lifetime_budget' in campaign) {
                                    check.contain = true;
                                    if (campaign.scheduleActivated) {
                                        check.locked = true;
                                    }
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.map((adgroup) => {
                                        if ('external_lifetime_budget' in adgroup) {
                                            check.contain = true;
                                            if (adgroup.scheduleActivated) {
                                                check.locked = true;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            } catch (error) { }
            return check;
        },
        calculation: (campaign, adgroup = false) => {
            if (campaign && campaign.scheduleActivated && !campaign.external_lifetime_budget) {
                let daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                //console.log(daily_budget, "------DIFFF_------");
                let difference = Math.abs(((parseFloat(campaign.external_daily_budget).toFixed(2) - +daily_budget.toFixed(2)) / +daily_budget.toFixed(2)) * 100);
                //console.log(difference, "------DIFFF_------");
                if (difference > 2) {
                    return true
                } else {
                    return false
                }
            } else if (adgroup && adgroup.scheduleActivated && !adgroup.external_lifetime_budget) {
                let daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                let difference = Math.abs(((parseFloat(adgroup.external_daily_budget).toFixed(2) - +daily_budget.toFixed(2)) / +daily_budget.toFixed(2)) * 100);
                //console.log(difference, "------DIFFF_------");
                if (difference > 2) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
            //if (campaign.external_daily_budget)
        },
        calculateTotalByDaily: (campaign, daily = false) => {
            let today = moment().startOf('month');
            let spend_total = false;
            let spend_rest = false;
            try {
                if (campaign.scheduleStartDate) {
                    if (moment(campaign.scheduleStartDate).format("MM") == moment().format("MM")) {
                        today = moment(campaign.scheduleStartDate);
                        spend_total = true;
                    }
                } else if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_rest") {
                    today = moment().startOf('day');
                    spend_rest = true;
                }
            } catch (error) {
                console.log(error);
            }
            if (!spend_total && !spend_rest && campaign.scheduleMonday && campaign.scheduleTuesday && campaign.scheduleWednesday && campaign.scheduleThursday && campaign.scheduleFriday && campaign.scheduleSaturday && campaign.scheduleSunday) {
                let data = { daily_budget: 0, total_budget: 0, left_over: 0, total: 30, month: today.format('MMMM'), year: today.format('YYYY') };
                if (campaign.external_daily_budget > 0 && !daily) {
                    data.total_budget = Math.round((campaign.external_daily_budget * data.total) * 100) / 100;
                    try {
                        data.total_budget = Math.round(data.total_budget * 10) / 10;
                    } catch (error) { }
                } else if (daily && campaign.budgetPlanerTotal > 0) {
                    data.daily_budget = Math.round((campaign.budgetPlanerTotal / data.total) * 100) / 100;
                    try {
                        data.daily_budget = Math.round(data.daily_budget * 10) / 10;
                    } catch (error) { }
                }
                return data;
            } else {
                let month = today.month();
                let data = { last_day: null, daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
                let next = false;
                while (!next) {
                    if (month !== today.month()) {
                        next = true;
                    } else {
                        if (campaign.scheduleMonday && today.isoWeekday() == 1) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleTuesday && today.isoWeekday() == 2) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleWednesday && today.isoWeekday() == 3) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleThursday && today.isoWeekday() == 4) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleFriday && today.isoWeekday() == 5) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleSaturday && today.isoWeekday() == 6) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        if (campaign.scheduleSunday && today.isoWeekday() == 7) {
                            data.last_day = today.format("YYYY-MM-DD");
                        }
                        data[today.isoWeekday()] += 1;
                        today.add(1, 'd');
                    }
                }
                if (next) {
                    if (campaign.scheduleMonday) {
                        data.total += data["1"];
                    }
                    if (campaign.scheduleTuesday) {
                        data.total += data["2"];
                    }
                    if (campaign.scheduleWednesday) {
                        data.total += data["3"];
                    }
                    if (campaign.scheduleThursday) {
                        data.total += data["4"];
                    }
                    if (campaign.scheduleFriday) {
                        data.total += data["5"];
                    }
                    if (campaign.scheduleSaturday) {
                        data.total += data["6"];
                    }
                    if (campaign.scheduleSunday) {
                        data.total += data["7"];
                    }
                    if (campaign.external_daily_budget > 0 && !daily) {
                        data.total_budget = Math.round((campaign.external_daily_budget * data.total) * 100) / 100;
                        try {
                            data.total_budget = Math.round(data.total_budget * 10) / 10;
                        } catch (error) { }
                        //data.left_over = campaign.budgetPlanerTotal - (data.daily_budget * data.total);
                    } else if (daily && campaign.budgetPlanerTotal > 0) {
                        let total_budget = campaign.budgetPlanerTotal;
                        if (spend_rest) {
                            total_budget = campaign.budgetPlanerTotal - campaign.monthly_spend;
                        }
                        data.daily_budget = Math.round((total_budget / data.total) * 100) / 100;
                        try {
                            data.daily_budget = Math.round(data.daily_budget * 10) / 10;
                        } catch (error) { }
                    }
                    return data;
                }
            }
        },
        maxBudgetHigher: () => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((campaign) => {
                                if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                    total += +campaign.budgetPlanerTotal;
                                }
                                if (campaign.scheduleActivated && campaign.external_lifetime_budget) {
                                    total += +campaign.external_lifetime_budget;
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.map((adgroup) => {
                                        if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                            total += +adgroup.budgetPlanerTotal;
                                        }
                                        if (!campaign.scheduleActivated && adgroup.scheduleActivated && adgroup.external_lifetime_budget) {
                                            total += +adgroup.external_lifetime_budget;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if (+total < +this.state.order.maxBudget) {
                        return true;
                    }
                }
            } catch (error) {
                return false;
            }
        },
        maxBudgetLower: () => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((campaign) => {
                                if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                    total += +campaign.budgetPlanerTotal;
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.map((adgroup) => {
                                        if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                            total += +adgroup.budgetPlanerTotal;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if (+total > +this.state.order.maxBudget) {
                        return true;
                    }
                }
            } catch (error) {
                return false;
            }
        },
        percentageOfMaxbudget: (item) => {
            try {
                if (+this.state.order.maxBudget > 0 && +item.budgetPlanerTotal) {
                    return ((+item.budgetPlanerTotal / +this.state.order.maxBudget) * 100).toFixed(2);
                }
            } catch (error) {
                return "-";
            }
        },
        amountBasedOnPercentage: (value) => {
            try {
                if (+this.state.order.maxBudget > 0) {
                    return (+this.state.order.maxBudget / 100) * +value;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        channelTotal: (channel) => {
            let total = 0;
            try {
                if (this.state.budget_planer) {
                    if (channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].map((campaign) => {
                                if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                    total += +campaign.budgetPlanerTotal;
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.map((adgroup) => {
                                        if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                            total += +adgroup.budgetPlanerTotal;
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            } catch (error) { }
            return total;
        },
        total_left: (item) => {
            try {
                if (this.state.budget_planer) {
                    let total = 0;
                    for (let channel in this.state.budget_planer) {
                        if (Array.isArray(this.state.budget_planer[channel])) {
                            this.state.budget_planer[channel].filter((campaign) => { return campaign.id !== item.id }).map((campaign) => {
                                if (campaign.scheduleActivated && +campaign.budgetPlanerTotal > 0) {
                                    total += +campaign.budgetPlanerTotal;
                                }
                                if (Array.isArray(campaign.adgroups)) {
                                    campaign.adgroups.filter((adgroup) => { return adgroup.id !== item.id }).map((adgroup) => {
                                        if (adgroup.scheduleActivated && +adgroup.budgetPlanerTotal > 0) {
                                            total += +adgroup.budgetPlanerTotal;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    if (+total < +this.state.order.maxBudget) {
                        return +this.state.order.maxBudget - +total;
                    } else {
                        return 0;
                    }
                }
            } catch (error) {
                return 0;
            }
        },
    };

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex-1 flex flex-col">

                <div className="sticky z-51 top-0">
                    <TopNavigationAgent
                        onButton={async (type) => {

                        }}
                        showDateSpan={false}
                        hideUserDropdown={true}
                        breadcrumb={""}
                        history={this.props.history}
                        buttons={[]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={false}
                        showAdvanced={!this.state.loading}
                        advancedFilter={true}
                        advancedFilters={this.state.advancedFilters}
                        onAdvancedFilter={async (value, type) => {
                            this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                                if (item.type === type) {
                                    item.selected = value;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                advancedFilters: this.state.advancedFilters,
                            });
                            if (type === "orders") {
                                let id = window.location.pathname.match(/[0-9a-z]{20,}/g)[0];
                                let path = window.location.pathname.replace(id, value.id);
                                window.open(path, "_self");
                            }

                        }}
                        onClient={async (client) => {
                            await this.promisedSetState({
                                client_change: client
                            });
                            this.functions.getOrders(true, false, false)
                        }}
                        onAdvancedFilterPagination={async (value) => {
                            if (value === "orders") {
                                this.state.advancedFilters[0].page = this.state.advancedFilters[0].page + 1;
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: this.state.advancedFilters[0].page
                                });
                                this.functions.getOrders(false, false, true);
                            }
                        }}
                        onAdvancedFilterSearch={async (value, type) => {
                            if (type === "orders") {
                                this.state.advancedFilters[0].page = 1;
                                this.state.advancedFilters[0].search_value = value ? value : "";
                                await this.promisedSetState({
                                    advancedFilters: this.state.advancedFilters,
                                    order_page: 1,
                                    order_search: value ? value : ""
                                });
                                this.functions.getOrders(false, this.state.order_search);
                            }
                        }}
                        client={this.state.client_change ? this.state.client_change : this.state.client}
                        onSubtab={(tab) => {
                            this.setState({
                                tab: tab
                            })
                        }}
                        subtab={this.state.tab}
                        show_subtabs={true}
                        subtabs={this.state.tabs}
                        budget_management={{
                            id: 1,
                            maxBudgetReached: this.state.order.maxBudgetReached,
                            maxBudget: this.state.order.maxBudget,
                            maxBudgetAction: this.state.order.maxBudgetAction,
                            maxBudgetSubAction: this.state.order.maxBudgetSubAction,
                            crmId: this.state.order.crmId,
                            maxBudgetEnabled: this.state.order.maxBudgetEnabled,
                            totalSpendThisMonth: this.state.order.totalSpendThisMonth,
                            loading: this.state.loading_max_budget,
                            locked: this.renders.checkBudget().locked,
                        }}
                        onBudgetManagement={async () => {
                            await this.functions.updateMaxBudgetEnabled();
                        }}
                        onNewBudgetItem={async () => {
                            await this.promisedSetState({
                                create_item: true
                            });
                        }}
                    />
                </div>

                {/*REMOVE MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone.'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.remove || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.remove ? this.state.remove.name : ""}
                    inputError={(!this.state.remove || (this.state.remove_name != this.state.remove.name)) ? "Name is not matching" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={(value) => {
                        if (this.state.remove && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeBudgetItems();
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {/*PAUSE WARNING MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove_schedule ? true : false}
                    title={"Notification"}
                    description={'Do you want to pause' + (this.state.remove_schedule && this.state.remove_schedule.campaign ? " campaign" : (this.state.remove_schedule && this.state.remove_schedule.shared_budget ? " campaigns belong to shared budget" : " adgroup")) + ' too ?'}
                    cancelButtonText={"No"}
                    submitButtonText={"Yes"}
                    onClose={async (value) => {
                        let buff = this.state.remove_schedule.campaign ? JSON.parse(JSON.stringify(this.state.remove_schedule.campaign)) : JSON.parse(JSON.stringify(this.state.remove_schedule.adgroup));
                        await this.promisedSetState({ remove_schedule: null });
                        this.functions.updateScheduler(buff, false, true, buff.channel);

                    }}
                    onSubmit={async (value) => {
                        let buff = this.state.remove_schedule.campaign ? JSON.parse(JSON.stringify(this.state.remove_schedule.campaign)) : JSON.parse(JSON.stringify(this.state.remove_schedule.adgroup));
                        await this.promisedSetState({ remove_schedule: null });
                        this.functions.updateScheduler(buff, false, false, buff.channel);

                    }}
                />

                {/*CREATE CLIENT*/}
                <SlideoutTailwind
                    title={"Create budget plan item"}
                    submitButtonText={"Create"}
                    small={true}
                    secondaryButton={false}
                    open={this.state.create_item ? true : false}
                    loader={this.state.create_loading}
                    onCancel={() => {
                        this.promisedSetState({ create_item: false });
                    }}
                    onSubmit={async () => {
                        await this.promisedSetState({
                            create_loading: true
                        });
                        this.refs.CreateBudgetPlanItem.functions.createItem();
                    }}
                >
                    {
                        this.state.create_item &&
                        <CreateBudgetPlanItem
                            ref="CreateBudgetPlanItem"
                            admin={true}
                            order={this.state.order}
                            crmId={this.state.order.crmId}
                            onCreated={async (client) => {
                                await this.promisedSetState({
                                    create_loading: false,
                                    create_item: false
                                });
                                this.functions.getOrder();
                            }}
                            onError={async (message) => {
                                await this.promisedSetState({
                                    create_loading: false
                                });
                            }}
                        />
                    }
                </SlideoutTailwind>

                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/* MAXB BUDGET ---------OLD CODE-------- */}
                {
                    this.state.tab.id == 1 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow relative flex flex-col transition-all duration-1000">
                            {
                                this.state.loading_maxbudget &&
                                <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                    <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                            <div className='flex flex-1 items-center transition-all duration-1000'>
                                <div className='flex-1 flex items-center justify-center h-16 px-3 border-r border-gray-300'>
                                    <span className='font-semibold text-sm flex items-center'>
                                        Order Budget
                                    </span>
                                    <div style={{ bottom: "2px", top: "6px", right: "2px" }} class="ml-1 px-2 z-20 bg-custom-input flex justify-center items-center">
                                        <span
                                            className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase ",
                                                {
                                                    ["bg-green-100 text-green-500"]: this.state.order.maxBudgetAction && this.state.order.maxBudgetAction.value == "active",
                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: this.state.order.maxBudgetAction && this.state.order.maxBudgetAction.value == "paused"
                                                })
                                            }>
                                            {this.state.order.maxBudgetAction ? this.state.order.maxBudgetAction.name : "-"}
                                        </span>
                                    </div>
                                </div>
                                <div id="hide_1" className='items-center justify-center border-r flex flex-1 px-3 border-gray-300 h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap'>
                                    <span className=' font-semibold text-sm flex items-center'>
                                        Monthly Target
                                    </span>
                                    <span className='ml-5 text-sm font-medium items-center'>
                                        {this.state.order.maxBudget + " " + (this.state.currency !== "" && this.state.currency !== "Multiple" ? this.state.currency : "")}
                                    </span>
                                </div>
                                <div id="hide_2" className='items-center justify-center flex flex-1 px-3 h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap border-gray-300 border-r'>
                                    <span className='font-semibold text-sm flex items-center'>
                                        CRM ID
                                    </span>
                                    <span className='ml-5 text-sm font-medium items-center'>
                                        {this.state.order.crmId ? this.state.order.crmId : ""}
                                    </span>
                                </div>
                                <div className='items-center justify-center flex flex-1 px-3 h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap'>
                                    <div id="manual" className={'items-center justify-center flex h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap ' + (this.state.order.maxBudgetEnabled ? "w-0 text-white" : "flex-1")}>
                                        <span className='font-semibold text-sm flex items-center'>
                                            Manual mode
                                        </span>

                                    </div>
                                    <div id="automatic" className={'items-center justify-center flex h-16 overflow-hidden transition-all duration-1000 whitespace-no-wrap ' + (this.state.order.maxBudgetEnabled ? "flex-1 text-purple-500" : "w-0 text-white")}>
                                        <span className='font-semibold text-sm flex items-center'>
                                            Automatic mode
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={'border-t flex border-gray-300 overflow-hidden transition-all duration-1000 h-16'}>
                                <div className="w-full flex items-center flex-1 py-4 px-4">
                                    <div id="hide_3" className={"overflow-hidden transition-all duration-1000 whitespace-no-wrap text-sm font-medium " + (this.state.order.maxBudgetEnabled ? "w-40" : "w-0")}>{this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.name ? this.state.order.maxBudgetSubAction.name : "-"}</div>
                                    <div className="ml-4 h-2 w-full bg-gray-100 overflow-hidden flex flex-row rounded-md relative">
                                        <div style={{ width: ((+this.state.order.totalSpendThisMonth / +this.state.order.maxBudget) * 100) + "%" }} className="bg-purple-500 rounded-l-md h-full transform ease-in-out duration-200"></div>
                                        <div className="flex flex-1 h-full bg-gray-100 rounded-r-md"></div>
                                    </div>
                                    <div className="ml-4 text-sm font-medium">{(this.state.order.totalSpendThisMonth ? +this.state.order.totalSpendThisMonth : 0) + "/" + this.state.order.maxBudget}</div>
                                    {
                                        +this.state.order.totalSpendThisMonth > 0 && +this.state.order.maxBudget > 0 &&
                                        <div className="ml-1 text-sm font-medium">({((+this.state.order.totalSpendThisMonth / +this.state.order.maxBudget) * 100).toFixed(2)}%)</div>
                                        ||
                                        <div className="ml-1">(0%)</div>
                                    }
                                </div>
                                {
                                    this.state.order.maxBudgetReached &&
                                    <div className="border-l px-4 relative flex items-center">
                                        {
                                            this.state.loading_unblock &&
                                            <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                <div style={{ borderTopColor: "transparent" }} className="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }
                                        <div className="flex flex-row justify-center items-center">
                                            <ExclamationIcon className="w-5 mr-2 text-red-500" />
                                            <div className="text-sm">
                                                Max budget reached
                                            </div>
                                        </div>
                                        {
                                            //MATIN ASKED TO KEEP UNBLOCK REACH MAX BUDGET BUTTON
                                            false &&
                                            <div className="ml-2">
                                                <div onClick={() => {
                                                    if (!this.state.loading_unblock) {
                                                        this.functions.unblockMaxBudgetReached();
                                                    }
                                                }} className="text-sm cursor-pointer text-red-500 underline">
                                                    Unblock and Continue
                                                </div>
                                                {
                                                    false &&
                                                    <SwitchTailwind
                                                        color={"bg-red-500"}
                                                        value={this.state.order.maxBudgetReached}
                                                        onSwitch={async () => {
                                                            //this.state.order.maxBudgetReached
                                                            /*
                                                            this.setState({
                                                                allow_update: !this.state.allow_update
                                                            })
                                                            */
                                                        }}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>

                            {
                                this.renders.checkBudget().contain && false &&
                                <div className='col-span-10'>
                                    <div className="text-orange-500 items-center inline-flex rounded-md p-4 font-medium text-sm">
                                        <ExclamationIcon className="h-4 w-4 text-orange-500 mr-1" />
                                        Warning: Automatic mode will be disabled if Campaigns or Adgroups without a<b className='mx-1'>Daily budget</b>are activated with budget control.
                                    </div>
                                </div>
                            }


                            {
                                this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.value == "spend_total" &&
                                <div className='col-span-10  text-blue-500 rounded-md'>
                                    <div className="p-4 font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-blue-500 mr-1" />
                                        OBS! <b className='mx-1'>Spend total</b> will insure that daily budget is total / days left in this month when total budget is updated.
                                    </div>
                                </div>
                            }

                            {
                                this.renders.maxBudgetHigher() &&
                                <div className='col-span-10  text-red-500 rounded-md '>
                                    <div className="p-4 font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        OBS! The <b className='mx-1'>Monthly order budget</b> is higher than sum of all total budgets.
                                    </div>
                                </div>
                            }

                            {
                                this.renders.maxBudgetLower() &&
                                <div className='col-span-10  text-red-500 rounded-md '>
                                    <div className="p-4 font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        OBS! The <b className='mx-1'>Monthly order budget</b> is lower than sum of all total budgets.
                                    </div>
                                </div>
                            }

                            {
                                this.state.error && this.state.error_message &&
                                <div className='col-span-10  text-red-500 rounded-md '>
                                    <div className="p-4 font-medium text-sm inline-flex items-center">
                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                        {this.state.error_message}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    this.state.tab.id == 1 &&
                    this.state.loading_planer &&
                    <div className="p-4 pt-0">
                        <div className="bg-white rounded-lg shadow w-full h-76">
                            <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                                <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.tab.id == 1 &&
                    !this.state.loading &&
                    !this.state.loading_planer &&
                    this.state.budget_planer && typeof this.state.budget_planer == "object" && this.state.budget_planer !== null &&
                    //this.state.order.maxBudgetEnabled &&
                    Object.keys(this.state.budget_planer).map((channel) => {
                        return (
                            <div className="p-4 pt-0">
                                <div className="bg-white overflow-hidden rounded-lg shadow relative">
                                    <div class="py-4 px-4 border-b font-medium text-sm flex flex-row justify-center items-center">
                                        <div className="w-full flex flex-1 items-center">
                                            <div className={"bg-" + (channel) + "-500" + " h-10 w-10 rounded-md flex justify-center items-center mr-3"}>
                                                {
                                                    channel === 'google' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/google_icon.png')} />
                                                }
                                                {
                                                    channel === 'facebook' &&
                                                    <img className="w-2"
                                                        alt=""
                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                }
                                                {
                                                    channel === 'linkedin' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                }
                                                {
                                                    channel === 'twitter' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                }
                                                {
                                                    channel === 'tiktok' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                }
                                                {
                                                    channel === 'snapchat' &&
                                                    <img className="w-4"
                                                        alt=""
                                                        src={require('../assets/images/snapchat.svg')} />
                                                }
                                                {
                                                    channel === 'bing' &&
                                                    <img className="w-3"
                                                        alt=""
                                                        src={require('../assets/images/bing-logo.png')} />
                                                }
                                            </div>
                                            <div className="mr-4 whitespace-no-wrap text-sm">{channel.charAt(0).toUpperCase() + channel.slice(1)}</div>
                                            <div className={'flex items-center transition-all duration-1000 overflow-hidden flex-1'}>
                                                {
                                                    typeof channel == "string" &&
                                                    this.state.order.totalSpendThisMonthPerChannel && this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] &&
                                                    <>
                                                        <div className="h-2 flex-1 bg-gray-100 flex flex-row rounded-md overflow-hidden">
                                                            <div style={{ width: ((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100) + "%" }} className={"bg-" + channel.toLowerCase() + "-500 h-full transform ease-in-out duration-200"}></div>
                                                            <div className="flex flex-1 h-full bg-gray-100"></div>
                                                        </div>
                                                        <div className="ml-4">{(this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] ? +this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] : 0) + "/" + this.renders.channelTotal(channel)}</div>
                                                        {
                                                            +this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] > 0 &&
                                                            +this.renders.channelTotal(channel) > 0 &&
                                                            <div className="ml-1">({((+this.state.order.totalSpendThisMonthPerChannel[channel.toLowerCase()] / +this.renders.channelTotal(channel)) * 100).toFixed(2)}%)</div>
                                                            ||
                                                            <div className="ml-1">(0%)</div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        {
                                            this.state.budget_planer[channel].filter((campaign) => {
                                                if (campaign.shared_budget) {
                                                    if (campaign.campaigns.length < 1 && !campaign.scheduleActivated) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                } else {
                                                    return true;
                                                }
                                            }).map((campaign, index) => {
                                                return (
                                                    <div className={(index > 0 ? "border-t" : "") + " grid-cols-12 grid relative"}>
                                                        {
                                                            campaign.loading &&
                                                            <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                            </div>
                                                        }
                                                        {
                                                            //campaign.scheduleActivated && campaign.budgetPlanerActivated &&
                                                            <Fragment>
                                                                {/*
                                                                <div className="col-span-12 bg-custom-input border-b flex flex-row font-medium px-4 py-3 text-xs">
                                                                    
                                                                    <div className="flex flex-1 items-center">

                                                                        <div className="flex items-center ml-4 ">
                                                                            {(campaign.shared_budget && <span><span className="font-semibold text-sm">Shared budget - </span>{campaign.campaigns.length} campaigns connected</span>) || <span className="font-semibold  text-sm">{campaign.name}</span>}
                                                                            {
                                                                                //campaign.scheduleActivated &&
                                                                                campaign.shared_budget &&
                                                                                <div onClick={async () => {
                                                                                    campaign.show_related = !campaign.show_related;
                                                                                    await this.promisedSetState({
                                                                                        budget_planer: this.state.budget_planer
                                                                                    })
                                                                                    if (campaign.show_related) {
                                                                                        await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: (campaign.campaigns.length * 66 + 35) + "px" });
                                                                                    } else {
                                                                                        await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: "0px" });
                                                                                    }

                                                                                }} className="flex ml-2 flex-1 justify-end underline cursor-pointer">{campaign.show_related ? "Hide" : "List"} campaigns</div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex items-center justify-center">
                                                                            <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">
                                                                                <span className="px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase bg-purple-100 text-purple-500" >
                                                                                    {"campaign"}
                                                                                </span>
                                                                            </div>

                                                                            {
                                                                                this.renders.calculation(campaign) &&

                                                                                <div
                                                                                    onClick={async () => {
                                                                                        this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                    }}
                                                                                    data-tip='' data-for={campaign.id + "_remove"} key={campaign.id + "_remove"}
                                                                                    style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center  cursor-pointer">
                                                                                    <span className="px-4 py-1 inline-flex text-xs items-center font-bold rounded-full  bg-red-100 text-red-600 relative" >
                                                                                        <ExclamationIcon className="h-4 w-4 text-red-600 mr-2" />
                                                                                        {"WARNING"}
                                                                                        <ReactTooltip effect='solid' id={campaign.id + "_remove"}>
                                                                                            Daily budget and total budget is not matching due to manual edits to budget.
                                                                                        </ReactTooltip>
                                                                                       
                                                                                        <div className="ml-2">
                                                                                            <div onClick={() => {

                                                                                            }} className="text-xs cursor-pointer underline">
                                                                                                Click to fix
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>

                                                                                </div>
                                                                            }
                                                                            {
                                                                                'external_lifetime_budget' in campaign &&
                                                                                <div
                                                                                    data-tip='' data-for={campaign.id + "_remove"} key={campaign.id + "_remove"}
                                                                                    style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">
                                                                                    <span className="px-4 py-1 inline-flex text-xs items-center font-bold rounded-full bg-red-100 text-red-600 relative" >
                                                                                        <ExclamationIcon className="h-4 w-4 text-red-600 mr-2" />
                                                                                        {"WARNING"}
                                                                                        <ReactTooltip effect='solid' id={campaign.id + "_remove"}>
                                                                                            Campaign has lifetime budget. It will cause pacing data show incorrect.
                                                                                        </ReactTooltip>

                                                                                    </span>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    
                                                                    {
                                                                        true &&
                                                                        <div className="flex items-center justify-end text-xs text-purple-500">
                                                                            <div className="ml-1">{(campaign.monthly_spend ? +campaign.monthly_spend.toFixed(2) : "-")}{("budgetPlanerTotal" in campaign && parseInt(campaign.budgetPlanerTotal) > 0 ? "/" + campaign.budgetPlanerTotal : "")}</div>
                                                                            {
                                                                                "budgetPlanerTotal" in campaign && parseInt(campaign.budgetPlanerTotal) > 0 &&
                                                                                <div className="ml-1">({((+campaign.monthly_spend / +campaign.budgetPlanerTotal) * 100).toFixed(2)}%)</div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                    */}
                                                                {/*pb-4*/}
                                                                <div id={"info_" + campaign.id} className={(campaign.shared_budget && !campaign.show_related ? "" : "") + " pb-1 col-span-12 transition-all duration-1000 overflow-hidden"}>
                                                                    <div className="grid-cols-12 grid gap-2 relative pl-4 py-2">
                                                                        {
                                                                            campaign.error &&
                                                                            <div className="absolute left-0 right-0 top-0 bottom-0 z-51 bg-white bg-opacity-75"></div>
                                                                        }
                                                                        <div className="col-span-12 flex flex-row items-center w-full">
                                                                            <div className="flex items-center mt-2">
                                                                                <SwitchTailwind
                                                                                    value={campaign.scheduleActivated}
                                                                                    //disabled={'external_lifetime_budget' in campaign ? true : false}
                                                                                    onSwitch={async () => {
                                                                                        if (campaign.scheduleActivated) {
                                                                                            await this.promisedSetState({
                                                                                                remove_schedule: {
                                                                                                    ...(campaign.shared_budget && { shared_budget: true }),
                                                                                                    campaign: campaign,
                                                                                                }
                                                                                            });
                                                                                        } else {
                                                                                            this.functions.updateScheduler(campaign, !campaign.scheduleActivated);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={(campaign.scheduleActivated ? "w-56" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 mt-2 ml-4"}>

                                                                                <div data-tip='' data-for={campaign.id + "_name"} key={campaign.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                    <div className="truncate w-full">{campaign.name}</div>
                                                                                    {
                                                                                        campaign.scheduleActivated &&
                                                                                        <ReactTooltip effect='solid' id={campaign.id + "_name"}>
                                                                                            {campaign.name}
                                                                                        </ReactTooltip>
                                                                                    }
                                                                                </div>
                                                                                <div className='flex font-medium text-xs'>
                                                                                    {
                                                                                        this.renders.checkBudget2(campaign) &&
                                                                                        <div data-tip='' data-for={campaign.id + "_check_budget"} key={campaign.id + "_check_budget"} className=''>
                                                                                            <ExclamationIcon className="h-4 w-4 text-orange-500 mr-1" />
                                                                                            <ReactTooltip effect='solid' id={campaign.id + "_check_budget"}>
                                                                                                Warning: Automatic mode will be disabled if Campaigns or Adgroups without a<b className='mx-1'>Daily budget</b>are activated with budget control.
                                                                                            </ReactTooltip>
                                                                                        </div>

                                                                                    }
                                                                                    {campaign.shared_budget ? "Shared budget" : "Campaign • "}
                                                                                    {
                                                                                        "status" in campaign &&
                                                                                        <span className={cn("ml-1 uppercase",
                                                                                            {
                                                                                                [" text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                [" text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                [" text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                [" text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                [" text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                [" text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                [" text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                            })
                                                                                        }>{campaign.status}
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    "status" in campaign && false &&
                                                                                    <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" mt-5.5 px-2 bg-custom-input flex justify-center items-center">

                                                                                        <span
                                                                                            className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase items-center ",
                                                                                                {

                                                                                                    ["bg-green-100 text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                    ["bg-purple-100 text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                    ["bg-gray-100 text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                    [" bg-gray-200 text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                    ["bg-gray-100 text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                    ["bg-red-100 text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                                })
                                                                                            }>
                                                                                            {
                                                                                                (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled") &&
                                                                                                <PlayIcon className="h-4 w-4 text-green-500 mr-2" />
                                                                                            }
                                                                                            {
                                                                                                (campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable') &&
                                                                                                <PauseIcon className="h-4 w-4 text-orange-500 mr-2" />
                                                                                            }
                                                                                            {campaign.status ? campaign.status : "-"}
                                                                                        </span>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {
                                                                                campaign.scheduleActivated &&
                                                                                <div className={"flex flex-1 flex-row mr-2" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                    {
                                                                                        ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="flex flex-col items-center justify-center mr-2">
                                                                                                        <div className="text-xs font-medium mb-1">
                                                                                                            {
                                                                                                                day.replace("schedule", "").slice(0, 2)
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div onClick={async () => {
                                                                                                            this.functions.days(day, campaign, channel, "campaign", false);
                                                                                                        }} className={(campaign.scheduleActivated && campaign[day] ? "border-purple-500 bg-purple-500" : "border-purple-100") + " w-5 h-5 border-1.5 rounded-md flex items-center justify-center " + (campaign.scheduleActivated ? "cursor-pointer" : "cursor-not-allowed")}>
                                                                                                            {
                                                                                                                campaign.scheduleActivated && campaign[day] &&
                                                                                                                <CheckIcon className="w-4 text-white"></CheckIcon>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>

                                                                            }
                                                                            {
                                                                                false &&
                                                                                <div className=" bg-purple-500 mt-5.5 mr-3 hover:bg-purple-600 items-center text-sm cursor-pointer focus:ring-purple-500 text-white inline-flex shadow  relative justify-center rounded-md py-2 px-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none" style={{ width: "fit-content" }}>
                                                                                    <div className="flex items-center overflow-hidden w-0 lg:w-auto transition-all duration-1000">
                                                                                        <span className="mr-2">Pacing</span>
                                                                                    </div>
                                                                                    <PresentationChartLineIcon className="w-6 h-6" />
                                                                                </div>

                                                                            }
                                                                            {
                                                                                !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                !("no_budget" in campaign) &&
                                                                                <div className={"mr-4 w-56 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                    <InputTailwind
                                                                                        value={campaign.external_daily_budget}
                                                                                        label={"Daily budget"}
                                                                                        error={this.renders.calculation(campaign) ? true : false}
                                                                                        locked={this.state.order.maxBudgetEnabled || !campaign.scheduleActivated ? true : false}
                                                                                        readOnly={this.state.order.maxBudgetEnabled || !campaign.scheduleActivated ? true : false}
                                                                                        leftSection={campaign.currency ? true : false}
                                                                                        leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                        onBlur={async () => {
                                                                                            delete campaign.tempPercentage;
                                                                                            await this.promisedSetState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            });
                                                                                            if (campaign.budgetPlanerTotal) {
                                                                                                this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                            }
                                                                                        }}
                                                                                        onChange={async (value) => {
                                                                                            campaign.external_daily_budget = value;
                                                                                            campaign.budgetPlanerTotal = this.renders.calculateTotalByDaily(campaign, false).total_budget;
                                                                                            campaign.tempPercentage = this.renders.percentageOfMaxbudget(campaign);
                                                                                            await this.promisedSetState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                !("no_budget" in campaign) &&
                                                                                <div className={"mr-4 w-56 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                    <InputTailwind
                                                                                        label={"Monthly budget"}
                                                                                        error={this.state.order.maxBudgetEnabled && +this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal ? true : false}
                                                                                        onBlur={async () => {
                                                                                            if (campaign.budgetPlanerTotal) {
                                                                                                if (this.state.order.maxBudgetEnabled) {
                                                                                                    if (+this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal) {
                                                                                                        //this.functions.maxBudgetReached(campaign);
                                                                                                    } else {
                                                                                                        this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                        await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                    }
                                                                                                } else {
                                                                                                    this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        locked={campaign.error || !campaign.scheduleActivated}
                                                                                        value={campaign.scheduleActivated ? campaign.budgetPlanerTotal : "-"}
                                                                                        //locked={!campaign.budgetPlanerActivated}
                                                                                        readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                        leftSection={campaign.currency ? true : false}
                                                                                        leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                        onChange={async (value) => {
                                                                                            if (this.state.order.maxBudgetEnabled) {
                                                                                                if (+this.renders.total_left(campaign).toFixed(2) < value) {
                                                                                                    //value = this.renders.total_left(campaign);
                                                                                                    this.functions.maxBudgetReached(campaign);
                                                                                                } else {
                                                                                                    await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                }
                                                                                            }
                                                                                            campaign.budgetPlanerTotal = value;
                                                                                            campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                                                                                            this.setState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !('external_lifetime_budget' in campaign) && campaign.scheduleActivated &&
                                                                                !("no_budget" in campaign) &&
                                                                                <div className={"w-56 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                    <InputTailwind
                                                                                        label={"% of Order budget"}
                                                                                        error={this.state.order.maxBudgetEnabled && +this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal ? true : false}
                                                                                        value={campaign.scheduleActivated ? ("tempPercentage" in campaign ? campaign.tempPercentage : this.renders.percentageOfMaxbudget(campaign)) : "-"}
                                                                                        rightSection={campaign.scheduleActivated ? true : false}
                                                                                        rightSectionLabel={"%"}
                                                                                        //rightSectionClass={"text-purple-500"}
                                                                                        readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                        locked={campaign.error || !campaign.scheduleActivated}
                                                                                        onFocus={() => {
                                                                                            campaign.tempPercentage = this.renders.percentageOfMaxbudget(campaign);
                                                                                            this.setState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            })
                                                                                        }}
                                                                                        onBlur={async () => {
                                                                                            if (this.state.order.maxBudgetEnabled) {
                                                                                                if (+this.renders.total_left(campaign).toFixed(2) < campaign.budgetPlanerTotal) {

                                                                                                } else {
                                                                                                    delete campaign.tempPercentage;
                                                                                                    await this.promisedSetState({
                                                                                                        budget_planer: this.state.budget_planer
                                                                                                    });
                                                                                                    if (campaign.budgetPlanerTotal) {
                                                                                                        this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                    }
                                                                                                    await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                }
                                                                                            } else {
                                                                                                delete campaign.tempPercentage;
                                                                                                await this.promisedSetState({
                                                                                                    budget_planer: this.state.budget_planer
                                                                                                });
                                                                                                if (campaign.budgetPlanerTotal) {
                                                                                                    this.functions.updateOrderCampaignBudgetPlan(campaign, channel);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        onChange={async (value) => {
                                                                                            if (this.state.order.maxBudgetEnabled) {
                                                                                                if (+this.renders.total_left(campaign).toFixed(2) < +this.renders.amountBasedOnPercentage(value).toFixed(2)) {
                                                                                                    //value = ((+this.renders.total_left(campaign) / +this.state.order.maxBudget) * 100).toFixed(2);
                                                                                                    this.functions.maxBudgetReached(campaign);
                                                                                                } else {
                                                                                                    await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                }
                                                                                            }
                                                                                            campaign.tempPercentage = value;
                                                                                            campaign.budgetPlanerTotal = this.renders.amountBasedOnPercentage(value);
                                                                                            campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                                                                                            await this.promisedSetState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            })
                                                                                            /*
                                                                                            if (+this.renders.channelTotal(channel) > 0) {
                                                                                                this.functions.animate(campaign.channel + "_bar", { remove: ['w-0'], add: ['flex-1'] });
                                                                                            } else {
                                                                                                this.functions.animate(campaign.channel + "_bar", { remove: ['flex-1'], add: ['w-0'] });
                                                                                            }
                                                                                            */
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            {
                                                                                //LIFE TIME BUDGET CAMPAIGN
                                                                                'external_lifetime_budget' in campaign && campaign.scheduleActivated &&
                                                                                !("no_budget" in campaign) &&
                                                                                <div className={"mr-4 w-56 transition-all duration-1000" + (campaign.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                    <InputTailwind
                                                                                        label={"Lifetime budget"}
                                                                                        onBlur={() => {
                                                                                            this.functions.updateExternalBudget(campaign, channel, "campaign")
                                                                                        }}
                                                                                        locked={campaign.error || !campaign.scheduleActivated}
                                                                                        value={campaign.scheduleActivated ? campaign.external_lifetime_budget : "-"}
                                                                                        //locked={!campaign.budgetPlanerActivated}
                                                                                        readOnly={campaign.error || !campaign.scheduleActivated}
                                                                                        leftSection={campaign.currency ? true : false}
                                                                                        leftSectionLabel={campaign.currency ? campaign.currency : ""}
                                                                                        onChange={async (value) => {
                                                                                            campaign.external_lifetime_budget = value;
                                                                                            //campaign.external_daily_budget = this.renders.calculateTotalByDaily(campaign, true).daily_budget;
                                                                                            await this.promisedSetState({
                                                                                                budget_planer: this.state.budget_planer
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        campaign.error &&
                                                                        <div className='w-full mt-4'>
                                                                            <div className="bg-red-100 text-red-500 rounded-md p-4 font-medium text-sm mx-3">
                                                                                {campaign.message}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {
                                                                    this.renders.calculation(campaign) &&
                                                                    !("no_budget" in campaign) &&
                                                                    <div id={"error_budget_" + campaign.id} className={"bg-red-100 mb-3 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden inline-flex h-12 mx-3"}>
                                                                        <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Daily budget and total budget is not matching due to manual edits to budget. <span onClick={async () => {
                                                                            this.functions.updateOrderCampaignBudgetPlan(campaign, channel, true);
                                                                        }} className="text-sm cursor-pointer underline ml-1">
                                                                            Click to fix
                                                                        </span></div>
                                                                    </div>
                                                                }
                                                                <div id={"error_" + campaign.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3" + (this.state.errors[campaign.id] ? " h-12" : " h-0")}>
                                                                    <span className='p-4 inline-flex items-center'>
                                                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                        {this.state.errors[campaign.id] ? this.state.errors[campaign.id] : ""}
                                                                    </span>
                                                                </div>
                                                                <div id={"max_error_" + campaign.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3 h-0"}>
                                                                    <span className='p-4 inline-flex items-center'>
                                                                        <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                        {"Warning: Budget exceeded - the new budget you are trying to allocate exceeds the available Order Monthly Target of " + this.state.order.maxBudget + ". Your remaining Monthly Budget is " + +this.renders.total_left(campaign).toFixed(2) + " or " + ((+this.renders.total_left(campaign).toFixed(2) / +this.state.order.maxBudget) * 100).toFixed(2) + " percentage. Please revise and try again."}
                                                                    </span>
                                                                </div>
                                                                {
                                                                    campaign.shared_budget &&
                                                                    <div onClick={async () => {
                                                                        campaign.show_related = !campaign.show_related;
                                                                        await this.promisedSetState({
                                                                            budget_planer: this.state.budget_planer
                                                                        })
                                                                        if (campaign.show_related) {
                                                                            await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: (campaign.campaigns.length * 67) + "px" });
                                                                        } else {
                                                                            await this.functions.animate("campaigns_info_" + campaign.id, {}, { height: "0px" });
                                                                        }
                                                                    }} className={(campaign.show_related ? "border-b" : "") + " bg-gray-50 col-span-12 flex-row flex justify-center cursor-pointer border-t items-center"}>
                                                                        {
                                                                            !campaign.show_related &&
                                                                            <div className="text-sm font-medium items-center hover:underline flex py-2">
                                                                                Show related campaigns
                                                                                <ChevronDownIcon className="h-4 ml-1 w-4" />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            campaign.show_related &&
                                                                            <div className="flex font-medium items-center hover:underline text-sm py-2">
                                                                                Hide related campaigns
                                                                                <ChevronUpIcon className="h-4 ml-1 w-4" />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
                                                                {
                                                                    /*########## NEW SHARED BUDGET CONTENT  #############*/
                                                                }
                                                                {
                                                                    Array.isArray(campaign.campaigns) &&
                                                                    campaign.campaigns.length > 0 &&
                                                                    <>
                                                                        <div id={"campaigns_info_" + campaign.id} className={'col-span-12 grid grid-cols-12 relative transition-all duration-1000 overflow-hidden'} style={{ height: (campaign.show_related ? (campaign.campaigns.length * 67) + "px" : "0px") }}>
                                                                            {
                                                                                false &&
                                                                                <div className={"border-b flex flex-row col-span-12 font-medium px-4 py-2 text-xs"}>
                                                                                    Campaigns belongs to this <span className="ml-1 font-semibold">shared budget</span>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (Array.isArray(campaign.campaigns) ? campaign.campaigns : []).map((campaign, index) => {
                                                                                    return (
                                                                                        <div className={(index > 0 ? "border-t" : "") + " col-span-12 relative"}>
                                                                                            {
                                                                                                campaign.loading &&
                                                                                                <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                                                    <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                                </div>
                                                                                            }
                                                                                            <div className="w-full h-full flex items-center">
                                                                                                <div className='mx-4 self-center'>
                                                                                                    <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                                </div>
                                                                                                <div className="flex items-center">
                                                                                                    <SwitchTailwind
                                                                                                        value={campaign.scheduleActivated}
                                                                                                        onSwitch={async () => {
                                                                                                            this.functions.updateScheduler(campaign, !campaign.scheduleActivated);
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                {
                                                                                                    <div className={(campaign.scheduleActivated ? "" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 ml-4"}>
                                                                                                        <div data-tip='' data-for={campaign.id + "_name"} key={campaign.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                                            <div className="truncate w-full">{campaign.name}</div>
                                                                                                            {
                                                                                                                false &&
                                                                                                                campaign.scheduleActivated &&
                                                                                                                <ReactTooltip effect='solid' id={campaign.id + "_name"}>
                                                                                                                    {campaign.name}
                                                                                                                </ReactTooltip>
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className='flex font-medium text-xs'>{campaign.shared_budget ? "Shared budget" : "Campaign • "}
                                                                                                            {
                                                                                                                "status" in campaign &&
                                                                                                                <span className={cn("ml-1 uppercase",
                                                                                                                    {
                                                                                                                        [" text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                                        [" text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                                        [" text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                                        [" text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                                        [" text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                                        [" text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                                        [" text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                                                    })
                                                                                                                }>{campaign.status}
                                                                                                                </span>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            "status" in campaign && false &&
                                                                                                            <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" mt-5.5 px-2 bg-custom-input flex justify-center items-center">

                                                                                                                <span
                                                                                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase items-center ",
                                                                                                                        {

                                                                                                                            ["bg-green-100 text-green-500"]: campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled",
                                                                                                                            ["bg-purple-100 text-purple-500"]: campaign.status.toLowerCase() === "upcoming" || campaign.status.toLowerCase() === "syncing",
                                                                                                                            ["bg-gray-100 text-gray-500"]: (campaign.status.toLowerCase() === "archived" || campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "done" || campaign.status.toLowerCase() === "balance_exceed"),
                                                                                                                            [" bg-gray-200 text-gray-500"]: (campaign.status.toLowerCase() === "expired" || campaign.status.toLowerCase() === "completed" || campaign.status.toLowerCase() === "time_done"),
                                                                                                                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable',
                                                                                                                            ["bg-gray-100 text-gray-500"]: campaign.status.toLowerCase() === "draft" || campaign.status.toLowerCase() === "pending",
                                                                                                                            ["bg-red-100 text-red-600"]: campaign.status.toLowerCase() === "error" || campaign.status.toLowerCase() === "removed"
                                                                                                                        })
                                                                                                                    }>
                                                                                                                    {
                                                                                                                        (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enable" || campaign.status.toLowerCase() === "enabled" || campaign.status.toLowerCase() === "scheduled") &&
                                                                                                                        <PlayIcon className="h-4 w-4 text-green-500 mr-2" />
                                                                                                                    }
                                                                                                                    {
                                                                                                                        (campaign.status.toLowerCase() === "paused" || campaign.status.toLowerCase() === "disable" || campaign.status.toLowerCase() === 'campaign_disable' || campaign.status.toLowerCase() === 'adgroup_disable') &&
                                                                                                                        <PauseIcon className="h-4 w-4 text-orange-500 mr-2" />
                                                                                                                    }
                                                                                                                    {campaign.status ? campaign.status : "-"}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="flex items-center flex-1 pr-4 justify-end text-xs text-purple-500">
                                                                                                    <div className="ml-1">{(campaign.monthly_spend ? +campaign.monthly_spend.toFixed(2) : "-")}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                /*
                                                                                                <div className="w-full h-full flex items-center">
                                                                                                    <div className="w-full flex flex-row font-medium px-4 py-3 text-xs">
                                                                                                        <div className="flex flex-1 items-center">
                                                                                                            <div className='mx-4 self-center'>
                                                                                                                <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                                            </div>
                                                                                                            <div className="flex items-center">
                                                                                                                <SwitchTailwind
                                                                                                                    value={adgroup.scheduleActivated}
                                                                                                                    onSwitch={async () => {
                                                                                                                        this.functions.updateScheduler(adgroup, !adgroup.scheduleActivated);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="flex items-center ml-4 mr-2">
                                                                                                                <span className="font-medium text-sm">{adgroup.name}</span>
                                                                                                            </div>
                                                                                                            <div className="flex items-center justify-center">
                                                                                                                <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">
                                                                                                                    <span className="px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase bg-purple-100 text-purple-500" >
                                                                                                                        {campaign.shared_budget ? "campaign" : "adgroup"}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    "status" in adgroup &&
                                                                                                                    <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">

                                                                                                                        <span
                                                                                                                            className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase items-center ",
                                                                                                                                {
                                                                                                                                    ["bg-green-100 text-green-500"]: adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled",
                                                                                                                                    ["bg-purple-100 text-purple-500"]: adgroup.status.toLowerCase() === "upcoming" || adgroup.status.toLowerCase() === "syncing",
                                                                                                                                    ["bg-gray-100 text-gray-500"]: (adgroup.status.toLowerCase() === "archived" || adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "done" || adgroup.status.toLowerCase() === "balance_exceed"),
                                                                                                                                    [" bg-gray-200 text-gray-500"]: (adgroup.status.toLowerCase() === "expired" || adgroup.status.toLowerCase() === "completed" || adgroup.status.toLowerCase() === "time_done"),
                                                                                                                                    ["bg-orange-100 text-orange-500 bg-opacity-50"]: adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable',
                                                                                                                                    ["bg-gray-100 text-gray-500"]: adgroup.status.toLowerCase() === "draft" || adgroup.status.toLowerCase() === "pending",
                                                                                                                                    ["bg-red-100 text-red-600"]: adgroup.status.toLowerCase() === "error" || adgroup.status.toLowerCase() === "removed"
                                                                                                                                })
                                                                                                                            }>
                                                                                                                            {
                                                                                                                                (adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled") &&
                                                                                                                                <PlayIcon className="h-4 w-4 text-green-500 mr-2" />
                                                                                                                            }
                                                                                                                            {
                                                                                                                                (adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable') &&
                                                                                                                                <PauseIcon className="h-4 w-4 text-orange-500 mr-2" />
                                                                                                                            }
                                                                                                                            {adgroup.status ? adgroup.status : "-"}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="flex items-center justify-end text-xs text-purple-500">
                                                                                                            <div className="ml-1">{(campaign.monthly_spend ? +campaign.monthly_spend.toFixed(2) : "-")}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                */
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            Array.isArray(campaign.campaigns) &&
                                                            campaign.campaigns.length > 0 &&
                                                            campaign.show_related && false &&
                                                            <div className={(campaign.show_related ? "border-b" : "") + "  flex flex-row col-span-12 font-medium px-4 py-2 text-xs h-"}>
                                                                Campaigns belongs to this <span className="ml-1 font-semibold">shared budget</span>
                                                            </div>
                                                        }
                                                        {
                                                            Array.isArray(campaign.adgroups) &&
                                                            campaign.adgroups.length > 0 &&
                                                            <div className={"col-span-12 border-b font-medium px-4 text-xs"}>
                                                                {/*Adgroups belongs to campaign <span className="font-semibold">{campaign.name}</span>*/}
                                                            </div>
                                                        }
                                                        {
                                                            !((Array.isArray(campaign.campaigns) && campaign.campaigns.length > 0) || (Array.isArray(campaign.adgroups) && campaign.adgroups.length > 0)) && false &&
                                                            <div className="w-full h-4"></div>
                                                        }
                                                        {
                                                            (Array.isArray(campaign.adgroups) ? campaign.adgroups : []).filter((item) => {
                                                                return !campaign.shared_budget
                                                            }).map((adgroup, index) => {
                                                                return (
                                                                    <div className={(index > 0 ? "border-t" : "") + " col-span-12 relative"}>
                                                                        {
                                                                            adgroup.loading &&
                                                                            <div className="h-full absolute z-51 bg-white opacity-50 flex justify-center items-center flex-col flex-1 w-full">
                                                                                <div style={{ borderTopColor: "transparent" }} className="w-8 h-8 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                            </div>
                                                                        }

                                                                        <div className="grid-cols-12 grid">
                                                                            {/*
                                                                            <div className="col-span-12 border-b flex flex-row font-medium px-4 pb-3 text-xs">
                                                                                <div className="flex flex-1 items-center">
                                                                                    <div className='mx-4 self-center'>
                                                                                        <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                    </div>
                                                                                    {
                                                                                        true &&
                                                                                        <div className="flex items-center">
                                                                                            <SwitchTailwind
                                                                                                value={adgroup.scheduleActivated}
                                                                                                onSwitch={async () => {
                                                                                                    if (adgroup.scheduleActivated) {
                                                                                                        await this.promisedSetState({
                                                                                                            remove_schedule: {
                                                                                                                adgroup: adgroup,
                                                                                                            }
                                                                                                        });
                                                                                                    } else {
                                                                                                        this.functions.updateScheduler(adgroup, !adgroup.scheduleActivated);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    }
                                                                                    <div className="flex items-center ml-4 ">
                                                                                        <span className="font-semibold  text-sm">{adgroup.name}</span>
                                                                                    </div>
                                                                                    <div className="flex flex-1 items-center justify-end">
                                                                                        {
                                                                                            this.renders.calculation(false, adgroup) &&

                                                                                            <div
                                                                                                onClick={async () => {
                                                                                                    this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                }}
                                                                                                data-tip='' data-for={adgroup.id + "_remove"} key={adgroup.id + "_remove"}
                                                                                                style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center  cursor-pointer">
                                                                                                <span className="px-4 py-1 inline-flex text-xs items-center font-bold rounded-full bg-red-100 text-red-600 relative" >
                                                                                                    <ExclamationIcon className="h-4 w-4 text-red-600 mr-2" />
                                                                                                    {"WARNING"}
                                                                                                    <ReactTooltip effect='solid' id={adgroup.id + "_remove"}>
                                                                                                        Daily budget and total budget is not matching due to manual edits to budget.
                                                                                                    </ReactTooltip>
                                                                                                    <div className="ml-2">
                                                                                                        <div onClick={() => {

                                                                                                        }} className="text-xs cursor-pointer underline">
                                                                                                            Click to fix
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>

                                                                                        }

                                                                                        {
                                                                                            'external_lifetime_budget' in adgroup &&
                                                                                            <div
                                                                                                data-tip='' data-for={adgroup.id + "_remove"} key={adgroup.id + "_remove"}
                                                                                                style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">
                                                                                                <span className="px-4 py-1 inline-flex text-xs items-center font-bold rounded-full bg-red-100 text-red-600 relative" >
                                                                                                    <ExclamationIcon className="h-4 w-4 text-red-600 mr-2" />
                                                                                                    {"WARNING"}
                                                                                                    <ReactTooltip effect='solid' id={adgroup.id + "_remove"}>
                                                                                                        Adgroup has lifetime budget. It will cause pacing data show incorrect.
                                                                                                    </ReactTooltip>

                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                        <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">
                                                                                            <span className="px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase bg-purple-100 text-purple-500" >
                                                                                                {campaign.shared_budget ? "campaign" : "adgroup"}
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            "status" in adgroup &&
                                                                                            <div style={{ bottom: "2px", top: "6px", right: "2px" }} class=" px-2 bg-custom-input flex justify-center items-center">

                                                                                                <span
                                                                                                    className={cn("px-4 py-1 inline-flex text-xs font-bold rounded-full uppercase items-center ",
                                                                                                        {
                                                                                                            ["bg-green-100 text-green-500"]: adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled",
                                                                                                            ["bg-purple-100 text-purple-500"]: adgroup.status.toLowerCase() === "upcoming" || adgroup.status.toLowerCase() === "syncing",
                                                                                                            ["bg-gray-100 text-gray-500"]: (adgroup.status.toLowerCase() === "archived" || adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "done" || adgroup.status.toLowerCase() === "balance_exceed"),
                                                                                                            [" bg-gray-200 text-gray-500"]: (adgroup.status.toLowerCase() === "expired" || adgroup.status.toLowerCase() === "completed" || adgroup.status.toLowerCase() === "time_done"),
                                                                                                            ["bg-orange-100 text-orange-500 bg-opacity-50"]: adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable',
                                                                                                            ["bg-gray-100 text-gray-500"]: adgroup.status.toLowerCase() === "draft" || adgroup.status.toLowerCase() === "pending",
                                                                                                            ["bg-red-100 text-red-600"]: adgroup.status.toLowerCase() === "error" || adgroup.status.toLowerCase() === "removed"
                                                                                                        })
                                                                                                    }>
                                                                                                    {
                                                                                                        (adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled") &&
                                                                                                        <PlayIcon className="h-4 w-4 text-green-500 mr-2" />
                                                                                                    }
                                                                                                    {
                                                                                                        (adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable') &&
                                                                                                        <PauseIcon className="h-4 w-4 text-orange-500 mr-2" />
                                                                                                    }
                                                                                                    {adgroup.status ? adgroup.status : "-"}
                                                                                                </span>
                                                                                            </div>
                                                                                        }


                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                        */}
                                                                            <div id={"info_" + adgroup.id} className={"col-span-12 transition-all duration-1000 overflow-hidden"}>
                                                                                <div className="grid-cols-12 grid gap-2 relative py-3">
                                                                                    {
                                                                                        adgroup.error &&
                                                                                        <div className="absolute left-0 right-0 top-0 bottom-0 z-51 bg-white bg-opacity-75"></div>
                                                                                    }
                                                                                    <div className="col-span-12 flex flex-row items-center w-full">
                                                                                        <div className='mx-4 self-center'>
                                                                                            <ReplyIcon className='h-5 w-5' style={{ transform: 'rotate(180deg)' }} />
                                                                                        </div>
                                                                                        <div className="flex items-center">
                                                                                            <SwitchTailwind
                                                                                                value={adgroup.scheduleActivated}
                                                                                                //disabled={'external_lifetime_budget' in adgroup ? true : false}
                                                                                                onSwitch={async () => {
                                                                                                    if (adgroup.scheduleActivated) {
                                                                                                        await this.promisedSetState({
                                                                                                            remove_schedule: {
                                                                                                                adgroup: adgroup,
                                                                                                            }
                                                                                                        });
                                                                                                    } else {
                                                                                                        this.functions.updateScheduler(adgroup, !adgroup.scheduleActivated);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className={(adgroup.scheduleActivated ? "w-56" : "opacity-50") + " mx-2 truncate transition-all duration-1000 pr-1 ml-4"}>
                                                                                            <div data-tip='' data-for={adgroup.id + "_name"} key={adgroup.id + "_name"} className='text-sm w-full font-medium truncate'>
                                                                                                <div className="truncate w-full">{adgroup.name}</div>
                                                                                                {
                                                                                                    adgroup.scheduleActivated &&
                                                                                                    <ReactTooltip effect='solid' id={adgroup.id + "_name"}>
                                                                                                        {adgroup.name}
                                                                                                    </ReactTooltip>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='flex text-xs'>Adgroup &#x2022;
                                                                                                <span className={cn("ml-1 uppercase",
                                                                                                    {
                                                                                                        [" text-green-500"]: adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enable" || adgroup.status.toLowerCase() === "enabled" || adgroup.status.toLowerCase() === "scheduled",
                                                                                                        [" text-purple-500"]: adgroup.status.toLowerCase() === "upcoming" || adgroup.status.toLowerCase() === "syncing",
                                                                                                        [" text-gray-500"]: (adgroup.status.toLowerCase() === "archived" || adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "done" || adgroup.status.toLowerCase() === "balance_exceed"),
                                                                                                        ["  text-gray-500"]: (adgroup.status.toLowerCase() === "expired" || adgroup.status.toLowerCase() === "completed" || adgroup.status.toLowerCase() === "time_done"),
                                                                                                        [" text-orange-500 bg-opacity-50"]: adgroup.status.toLowerCase() === "paused" || adgroup.status.toLowerCase() === "disable" || adgroup.status.toLowerCase() === 'campaign_disable' || adgroup.status.toLowerCase() === 'adgroup_disable',
                                                                                                        [" text-gray-500"]: adgroup.status.toLowerCase() === "draft" || adgroup.status.toLowerCase() === "pending",
                                                                                                        [" text-red-600"]: adgroup.status.toLowerCase() === "error" || adgroup.status.toLowerCase() === "removed"
                                                                                                    })
                                                                                                }>{adgroup.status}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            adgroup.scheduleActivated && !campaign.shared_budget &&
                                                                                            <div className={"flex flex-1 flex-row mr-2" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                {
                                                                                                    ["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].map((day) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div className="flex flex-col items-center justify-center mr-2">
                                                                                                                    <div className="text-xs font-medium mb-1">
                                                                                                                        {
                                                                                                                            day.replace("schedule", "").slice(0, 2)
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div onClick={async () => {
                                                                                                                        this.functions.days(day, campaign, channel, "adgroup", adgroup);
                                                                                                                    }} className={(adgroup.scheduleActivated && adgroup[day] ? "border-purple-500 bg-purple-500" : "border-purple-100") + " w-5 h-5 border-1.5 rounded-md flex items-center justify-center " + (adgroup.scheduleActivated ? "cursor-pointer" : "cursor-not-allowed")}>
                                                                                                                        {
                                                                                                                            adgroup.scheduleActivated && adgroup[day] &&
                                                                                                                            <CheckIcon className="w-4 text-white"></CheckIcon>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>

                                                                                        }
                                                                                        {
                                                                                            false &&
                                                                                            <div className=" bg-purple-500 mt-5.5 mr-3 hover:bg-purple-600 items-center text-sm cursor-pointer focus:ring-purple-500 text-white inline-flex shadow  relative justify-center rounded-md py-2 px-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 select-none" style={{ width: "fit-content" }}>
                                                                                                <div className="flex items-center overflow-hidden w-0 lg:w-auto transition-all duration-1000">
                                                                                                    <span className="mr-2">Pacing</span>
                                                                                                </div>
                                                                                                <PresentationChartLineIcon className="w-6 h-6" />
                                                                                            </div>

                                                                                        }
                                                                                        {
                                                                                            adgroup.scheduleActivated &&
                                                                                            !campaign.shared_budget &&
                                                                                            !('external_lifetime_budget' in adgroup) &&
                                                                                            !("no_budget" in adgroup) &&
                                                                                            <div className={"mr-4 w-56 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                <InputTailwind
                                                                                                    value={adgroup.external_daily_budget}
                                                                                                    label={"Daily budget"}
                                                                                                    error={this.renders.calculation(adgroup) ? true : false}
                                                                                                    locked={this.state.order.maxBudgetEnabled || !adgroup.scheduleActivated ? true : false}
                                                                                                    readOnly={this.state.order.maxBudgetEnabled || !adgroup.scheduleActivated ? true : false}
                                                                                                    leftSection={adgroup.currency ? true : false}
                                                                                                    leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                    onBlur={async () => {
                                                                                                        delete adgroup.tempPercentage;
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        });
                                                                                                        if (adgroup.budgetPlanerTotal) {
                                                                                                            this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                        }
                                                                                                    }}
                                                                                                    onChange={async (value) => {
                                                                                                        adgroup.external_daily_budget = value;
                                                                                                        adgroup.budgetPlanerTotal = this.renders.calculateTotalByDaily(adgroup, false).total_budget;
                                                                                                        adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup);
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            adgroup.scheduleActivated &&
                                                                                            !campaign.shared_budget &&
                                                                                            !('external_lifetime_budget' in adgroup) &&
                                                                                            !("no_budget" in adgroup) &&
                                                                                            <div className={"mr-4 w-56 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                <InputTailwind
                                                                                                    label={"Montly budget"}
                                                                                                    error={this.state.order.maxBudgetEnabled && +this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal ? true : false}
                                                                                                    onBlur={async () => {
                                                                                                        if (adgroup.budgetPlanerTotal) {
                                                                                                            if (this.state.order.maxBudgetEnabled) {
                                                                                                                if (+this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal) {
                                                                                                                    //this.functions.maxBudgetReached(campaign);
                                                                                                                } else {
                                                                                                                    this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                    await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                                }
                                                                                                            } else {
                                                                                                                this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    value={adgroup.scheduleActivated ? adgroup.budgetPlanerTotal : "-"}
                                                                                                    //locked={!campaign.budgetPlanerActivated}
                                                                                                    readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    leftSection={adgroup.currency ? true : false}
                                                                                                    leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                    onChange={async (value) => {
                                                                                                        if (this.state.order.maxBudgetEnabled) {
                                                                                                            if (+this.renders.total_left(adgroup).toFixed(2) < value) {
                                                                                                                //value = this.renders.total_left(adgroup);
                                                                                                                this.functions.maxBudgetReached(adgroup);
                                                                                                            } else {
                                                                                                                await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                            }
                                                                                                        }
                                                                                                        adgroup.budgetPlanerTotal = value;
                                                                                                        adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            adgroup.scheduleActivated &&
                                                                                            !campaign.shared_budget &&
                                                                                            !('external_lifetime_budget' in adgroup) &&
                                                                                            !("no_budget" in adgroup) &&
                                                                                            <div className={"w-56 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                <InputTailwind
                                                                                                    label={"% of Order budget"}
                                                                                                    error={this.state.order.maxBudgetEnabled && +this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal ? true : false}
                                                                                                    value={adgroup.scheduleActivated ? ("tempPercentage" in adgroup ? adgroup.tempPercentage : this.renders.percentageOfMaxbudget(adgroup)) : "-"}
                                                                                                    rightSection={adgroup.scheduleActivated ? true : false}
                                                                                                    rightSectionLabel={"%"}
                                                                                                    //rightSectionClass={"text-purple-500"}
                                                                                                    readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    onFocus={() => {
                                                                                                        adgroup.tempPercentage = this.renders.percentageOfMaxbudget(adgroup);
                                                                                                        this.setState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        })
                                                                                                    }}
                                                                                                    onBlur={async () => {
                                                                                                        if (this.state.order.maxBudgetEnabled) {
                                                                                                            if (+this.renders.total_left(adgroup).toFixed(2) < adgroup.budgetPlanerTotal) {

                                                                                                            } else {
                                                                                                                delete adgroup.tempPercentage;
                                                                                                                await this.promisedSetState({
                                                                                                                    budget_planer: this.state.budget_planer
                                                                                                                });
                                                                                                                if (adgroup.budgetPlanerTotal) {
                                                                                                                    this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                                }
                                                                                                                await this.functions.animate("max_error_" + adgroup.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                            }
                                                                                                        } else {
                                                                                                            delete adgroup.tempPercentage;
                                                                                                            await this.promisedSetState({
                                                                                                                budget_planer: this.state.budget_planer
                                                                                                            });
                                                                                                            if (adgroup.budgetPlanerTotal) {
                                                                                                                this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel);
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    onChange={async (value) => {
                                                                                                        if (this.state.order.maxBudgetEnabled) {
                                                                                                            if (+this.renders.total_left(adgroup).toFixed(2) < this.renders.amountBasedOnPercentage(value)) {
                                                                                                                //value = ((+this.renders.total_left(adgroup) / +this.state.order.maxBudget) * 100).toFixed(2);
                                                                                                                this.functions.maxBudgetReached(adgroup);
                                                                                                            } else {
                                                                                                                await this.functions.animate("max_error_" + campaign.id, { remove: ['h-12'], add: ['h-0'] });
                                                                                                            }
                                                                                                        }
                                                                                                        adgroup.tempPercentage = value;
                                                                                                        adgroup.budgetPlanerTotal = this.renders.amountBasedOnPercentage(value);
                                                                                                        adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        })
                                                                                                        /*
                                                                                                        if (+this.renders.channelTotal(channel) > 0) {
                                                                                                            this.functions.animate(campaign.channel + "_bar", { remove: ['w-0'], add: ['flex-1'] });
                                                                                                        } else {
                                                                                                            this.functions.animate(campaign.channel + "_bar", { remove: ['flex-1'], add: ['w-0'] });
                                                                                                        }
                                                                                                        */
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            //DAILY FOR LINKEDIN ADGROUP
                                                                                            adgroup.scheduleActivated &&
                                                                                            !campaign.shared_budget &&
                                                                                            adgroup.channel === "linkedin" &&
                                                                                            'external_lifetime_budget' in adgroup &&
                                                                                            !("no_budget" in adgroup) &&
                                                                                            !("no_daily" in adgroup) &&
                                                                                            <div className={"mr-4 w-56 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                <InputTailwind
                                                                                                    value={adgroup.external_daily_budget}
                                                                                                    label={"Daily budget"}
                                                                                                    error={this.renders.calculation(adgroup) ? true : false}
                                                                                                    leftSection={adgroup.currency ? true : false}
                                                                                                    leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                    onBlur={async () => {
                                                                                                        this.functions.updateExternalBudget(adgroup, channel, "adgroup", true);
                                                                                                    }}
                                                                                                    onChange={async (value) => {
                                                                                                        adgroup.external_daily_budget = value;
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            //LIFE TIME BUDGET ADGROUP
                                                                                            adgroup.scheduleActivated &&
                                                                                            !campaign.shared_budget &&
                                                                                            'external_lifetime_budget' in adgroup &&
                                                                                            !("no_budget" in adgroup) &&
                                                                                            <div className={"mr-4 w-56 transition-all duration-1000" + (adgroup.scheduleActivated ? "" : " opacity-50 cursor-not-allowed")}>
                                                                                                <InputTailwind
                                                                                                    label={"Lifetime budget"}
                                                                                                    onBlur={() => {
                                                                                                        this.functions.updateExternalBudget(adgroup, channel, "adgroup");
                                                                                                    }}
                                                                                                    locked={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    value={adgroup.scheduleActivated ? adgroup.external_lifetime_budget : "-"}
                                                                                                    //locked={!campaign.budgetPlanerActivated}
                                                                                                    readOnly={adgroup.error || !adgroup.scheduleActivated}
                                                                                                    leftSection={adgroup.currency ? true : false}
                                                                                                    leftSectionLabel={adgroup.currency ? adgroup.currency : ""}
                                                                                                    onChange={async (value) => {
                                                                                                        adgroup.external_lifetime_budget = value;
                                                                                                        //adgroup.external_daily_budget = this.renders.calculateTotalByDaily(adgroup, true).daily_budget;
                                                                                                        await this.promisedSetState({
                                                                                                            budget_planer: this.state.budget_planer
                                                                                                        });
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        }
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                adgroup.scheduleActivated &&
                                                                                'external_lifetime_budget' in adgroup &&
                                                                                !("no_budget" in adgroup) && false &&
                                                                                <div id={"error_budget_" + adgroup.id} className={"bg-red-100 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex h-12 mx-3"}>

                                                                                    <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Adgroup has lifetime budget. It will cause pacing data show incorrect.</div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.renders.calculation(false, adgroup) &&
                                                                                !("no_budget" in adgroup) &&
                                                                                <div id={"error_budget_" + adgroup.id} className={"bg-red-100 mb-3 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden inline-flex h-12 mx-3"}>

                                                                                    <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />Daily budget and total budget is not matching due to manual edits to budget. <span onClick={async () => {
                                                                                        this.functions.updateOrderAdgroupBudgetPlan(campaign, adgroup, channel, true);
                                                                                    }} className="text-sm cursor-pointer underline ml-1">
                                                                                        Click to fix
                                                                                    </span></div>
                                                                                </div>
                                                                            }
                                                                            <div id={"error_" + adgroup.id} className={"bg-red-100 text-red-500 col-span-12 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden mx-3 flex" + (this.state.errors[adgroup.id] ? " h-12" : " h-0")}>
                                                                                <div className='p-4 inline-flex items-center'><ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />{this.state.errors[adgroup.id] ? this.state.errors[adgroup.id] : ""}</div>
                                                                            </div>
                                                                            <div id={"max_error_" + adgroup.id} className={"bg-red-100 col-span-12 text-red-500 rounded-md font-medium text-sm transition-all duration-1000 overflow-hidden flex mx-3 h-0"}>
                                                                                <span className='p-4 inline-flex items-center'>
                                                                                    <ExclamationIcon className="h-4 w-4 text-red-500 mr-1" />
                                                                                    {"Warning: Budget exceeded - the new budget you are trying to allocate exceeds the available Order Monthly Target of " + this.state.order.maxBudget + ". Your remaining Monthly Budget is " + +this.renders.total_left(adgroup).toFixed(2) + " or " + ((+this.renders.total_left(adgroup).toFixed(2) / +this.state.order.maxBudget) * 100).toFixed(2) + " percentage. Please revise and try again."}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                {/* UPCOMING */}
                {
                    this.state.tab.id == 2 &&
                    !this.state.loading &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Budget planner
                                </div>
                                <div className="flex flex-1">

                                </div>
                                {
                                    false &&
                                    <div className="flex flex-row justify-center items-center">
                                        <div className="text-sm mr-4">
                                            Edit sensitive data
                                        </div>
                                        <SwitchTailwind
                                            value={this.state.allow_update}
                                            onSwitch={async () => {
                                                this.setState({
                                                    allow_update: !this.state.allow_update
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    {
                                        Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.length > 0 &&
                                        <div className="col-span-12 py-5 px-4">
                                            <div className="w-full">
                                                {
                                                    this.state.order.budgetPlans.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((item, index) => {
                                                        if (item.date < this.state.today) return;
                                                        return (
                                                            <Fragment>
                                                                <div className={(index > 0 ? "mt-4" : "") + " flex flex-row w-full"}>
                                                                    <div className={"mr-4 pt-5.5"}>
                                                                        <div onClick={() => {
                                                                            item.selected = !item.selected;
                                                                            this.setState({
                                                                                order: this.state.order
                                                                            });
                                                                        }} className={(true ? " cursor-pointer " : " cursor-not-allowed ") + (item.selected ? " bg-purple-500" : "") + " rounded-md flex items-center justify-center relative border h-12 w-12 bg-custom-input"}>
                                                                            {
                                                                                item.selected &&
                                                                                <CheckIcon className="w-5 text-white" />
                                                                            }
                                                                            {
                                                                                item.loading &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1">
                                                                        <div className="grid-cols-10 gap-4 grid w-full">
                                                                            {
                                                                                false &&
                                                                                <div className={"col-span-2 relative"}>
                                                                                    {
                                                                                        item.action && item.action.value == "paused" &&
                                                                                        <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                    }
                                                                                    <DropdownTailwind
                                                                                        label={"Max budget"}
                                                                                        small={false}
                                                                                        locked={true}
                                                                                        searchInput={true}
                                                                                        selected={item.max_budget ? item.max_budget : { id: 1, name: "Active", value: "active" }}
                                                                                        options={[]}
                                                                                        onChange={async (value) => {

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            <div className={"col-span-2"}>
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Date"}
                                                                                    value={item.date}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2 relative"}>
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                }
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Budget"}
                                                                                    value={item.action && item.action.value == "paused" ? "" : item.budget}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2"}>
                                                                                <DropdownTailwind
                                                                                    label={"Action"}
                                                                                    small={false}
                                                                                    locked={true}
                                                                                    searchInput={true}
                                                                                    selected={item.action ? item.action : {}}
                                                                                    options={[]}
                                                                                    onChange={async (value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2 relative"}>
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                }
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <InputTailwind
                                                                                        locked={true}
                                                                                        onFocus={() => { }}
                                                                                        readOnly={true}
                                                                                        label={"Sub-action"}
                                                                                        value={""}
                                                                                        onChange={(value) => {

                                                                                        }}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    item.action && item.action.value !== "paused" &&
                                                                                    <DropdownTailwind
                                                                                        label={"Sub-action"}
                                                                                        small={false}
                                                                                        locked={true}
                                                                                        searchInput={true}
                                                                                        selected={item.sub_action ? (item.action && item.action.value == "paused" ? {} : item.sub_action) : {}}
                                                                                        options={[]}
                                                                                        onChange={async (value) => {

                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div className={"col-span-2"}>
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Reference ID"}
                                                                                    value={item.reference}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {
                                                                                false &&
                                                                                <div className={"col-span-2 relative"}>
                                                                                    {
                                                                                        item.action && item.action.value == "paused" &&
                                                                                        <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                    }
                                                                                    <div className="flex justify-between w-full flex-row pt-5">
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Mo</div>
                                                                                            <div className={(item.scheduleMonday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Tu</div>
                                                                                            <div className={(item.scheduleTuesday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">We</div>
                                                                                            <div className={(item.scheduleWednesday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Th</div>
                                                                                            <div className={(item.scheduleThursday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Fr</div>
                                                                                            <div className={(item.scheduleFriday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Sa</div>
                                                                                            <div className={(item.scheduleSaturday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                        <div className="flex flex-col cursor-pointer items-center justify-center">
                                                                                            <div className="text-xxs font-medium mb-1">Su</div>
                                                                                            <div className={(item.scheduleSunday ? "bg-purple-500" : "bg-purple-100") + " w-3 h-6 rounded-md"}></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-span-12 border-t">
                                        <div className="flex flex-1 items-center pl-1 py-5 px-6">
                                            {
                                                <div onClick={() => {
                                                    this.setState({
                                                        remove: { name: this.state.order.name }
                                                    });
                                                }} className={(Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer hover:text-red-600 text-red-500 " : " cursor-not-allowed text-gray2-600") + " text-sm ml-5 cursor-pointer font-medium mr-4 inline-flex flex-row"}>
                                                    <TrashIcon className="w-5 mr-2" />
                                                    Remove
                                                </div>
                                            }
                                            <div onClick={() => {
                                                this.setState({
                                                    create_item: true
                                                });
                                            }} className={(true ? "cursor-pointer hover:text-purple-600 text-purple-500 " : " cursor-not-allowed text-gray2-600") + " text-sm cursor-pointer font-medium  inline-flex flex-row"}>
                                                <PlusIcon className="w-5 mr-2" />
                                                Add new item
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.tab.id == 3 &&
                    !this.state.loading &&
                    this.state.order.budgetPlans.filter(item => item.date < this.state.today).length == 0 &&
                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Budget history
                                </div>
                                <div className="flex flex-1"></div>
                            </div>
                            <div className="w-full">
                                <div className="flex flex-col items-center justify-center text-sm text-gray-500 font-medium text-center py-4 px-4">
                                    <div className=''>No budget history</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* HISTORY */}
                {
                    this.state.tab.id == 3 &&
                    !this.state.loading &&
                    this.state.order.budgetPlans.filter(item => item.date < this.state.today).length > 0 &&

                    <div className="p-4">
                        <div className="bg-white rounded-lg shadow">
                            <div class="py-4 px-4 border-b font-semibold text-sm flex flex-row justify-center items-center">
                                <div>
                                    Budget history
                                </div>
                                <div className="flex flex-1"></div>
                            </div>
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">
                                    {
                                        Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.length > 0 &&
                                        <div className="col-span-12 py-5 px-4">
                                            <div className="w-full space-y-4">
                                                {
                                                    this.state.order.budgetPlans.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((item, index) => {
                                                        if (item.date >= this.state.today) return;
                                                        return (
                                                            <Fragment>
                                                                <div className={"flex flex-row w-full"}>
                                                                    <div className={"mr-4 pt-5.5"}>
                                                                        <div onClick={() => {
                                                                            item.selected = !item.selected;
                                                                            this.setState({
                                                                                order: this.state.order
                                                                            });
                                                                        }} className={(true ? " cursor-pointer " : " cursor-not-allowed ") + (item.selected ? " bg-purple-500" : "") + " rounded-md flex items-center justify-center relative border h-12 w-12 bg-custom-input"}>
                                                                            {
                                                                                item.selected &&
                                                                                <CheckIcon className="w-5 text-white" />
                                                                            }
                                                                            {
                                                                                item.loading &&
                                                                                <div className="w-full h-full absolute bg-purple-500 top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                                                                    <div style={{ borderTopColor: "transparent" }}
                                                                                        class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex flex-1">
                                                                        <div className="grid-cols-10 gap-4 grid w-full">
                                                                            {
                                                                                false &&
                                                                                <div className={"col-span-2 relative"}>
                                                                                    {
                                                                                        item.action && item.action.value == "paused" &&
                                                                                        <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                    }
                                                                                    <DropdownTailwind
                                                                                        label={"Max budget"}
                                                                                        small={false}
                                                                                        locked={true}
                                                                                        searchInput={true}
                                                                                        selected={item.max_budget ? item.max_budget : { id: 1, name: "Active", value: "active" }}
                                                                                        options={[]}
                                                                                        onChange={async (value) => {

                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            <div className={"col-span-2"}>
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Date"}
                                                                                    value={item.date}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2 relative"}>
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                }
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Budget"}
                                                                                    value={item.action && item.action.value == "paused" ? "" : item.budget}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2"}>
                                                                                <DropdownTailwind
                                                                                    label={"Action"}
                                                                                    small={false}
                                                                                    locked={true}
                                                                                    searchInput={true}
                                                                                    selected={item.action ? item.action : {}}
                                                                                    options={[]}
                                                                                    onChange={async (value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"col-span-2 relative"}>
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <div className="absolute left-0 right-0 top-0 bottom-0 z-20 bg-white bg-opacity-50"></div>
                                                                                }
                                                                                {
                                                                                    item.action && item.action.value == "paused" &&
                                                                                    <InputTailwind
                                                                                        locked={true}
                                                                                        onFocus={() => { }}
                                                                                        readOnly={true}
                                                                                        label={"Sub-action"}
                                                                                        value={""}
                                                                                        onChange={(value) => {

                                                                                        }}
                                                                                    />
                                                                                }
                                                                                {
                                                                                    item.action && item.action.value !== "paused" &&
                                                                                    <DropdownTailwind
                                                                                        label={"Sub-action"}
                                                                                        small={false}
                                                                                        locked={true}
                                                                                        searchInput={true}
                                                                                        selected={item.sub_action ? (item.action && item.action.value == "paused" ? {} : item.sub_action) : {}}
                                                                                        options={[]}
                                                                                        onChange={async (value) => {

                                                                                        }}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                            <div className={"col-span-2"}>
                                                                                <InputTailwind
                                                                                    locked={true}
                                                                                    onFocus={() => { }}
                                                                                    readOnly={true}
                                                                                    label={"Reference ID"}
                                                                                    value={item.reference}
                                                                                    onChange={(value) => {

                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-span-12 border-t">
                                        <div className="flex flex-1 items-center pl-1 py-5 px-6">
                                            <div onClick={() => {
                                                this.setState({
                                                    remove: { name: this.state.order.name }
                                                });
                                            }} className={(this.state.order.budgetPlans.filter((item) => { return item.selected }).length > 0 ? "cursor-pointer hover:text-red-600 text-red-500 " : " cursor-not-allowed text-gray2-600") + " text-sm ml-5 cursor-pointer font-medium mr-4 inline-flex flex-row"}>
                                                <TrashIcon className="w-5 mr-2" />
                                                Remove
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >
        )
    }

}

export default OrderBudgetPlanerTemp;