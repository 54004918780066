import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { orderRegister } from '../services/orderRegister';
import Pusher from 'pusher-js';
import { AnnotationIcon, CursorClickIcon, ArchiveIcon, ArrowLeftIcon, ArrowNarrowRightIcon, ArrowRightIcon, CheckIcon, CollectionIcon, ExclamationCircleIcon, FolderIcon, GiftIcon, NewspaperIcon, PencilAltIcon, PhotographIcon, PlusIcon, SearchCircleIcon, TemplateIcon, TrashIcon, UploadIcon, ViewGridAddIcon, XIcon, SaveAsIcon, SaveIcon, PlusCircleIcon, DuplicateIcon, VideoCameraIcon, FilmIcon, ExclamationIcon, ClipboardListIcon, PuzzleIcon, BanIcon, ChartBarIcon, ArrowsExpandIcon, ChevronDownIcon, ChevronUpIcon, MenuIcon, ViewGridIcon, SearchIcon, ScissorsIcon, SparklesIcon } from '@heroicons/react/outline';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import SlideoutTailwindAsset from '../moduleFiles/slideoutTailwindAsset';
import CreateOrderCreativeEdit from '../moduleFiles/createOrderCreativeEdit';
import ToolTip from '../moduleFiles/toolTip';
import CreateOrderCreativePush from '../moduleFiles/createOrderCreativePush';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { draftRegister } from '../services/draftRegister';
import SuccessNotification from '../moduleFiles/successNotification';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';
import PreviewGoogleSearch from '../previews/google/search';
import CreateOrderCreativeCropper from '../moduleFiles/createOrderCreativeCropper';
import CreateOrderCreativeKeywords from '../moduleFiles/createOrderCreativeKeywords';
import CreateOrderCreativeSearchAd from '../moduleFiles/createOrderCreativeSearchAd';
import CreateOrderCreado from '../moduleFiles/createOrderCreado';
import { merge } from 'immutable';
import classNames from 'classnames';
import InputTailwind from '../moduleFiles/inputTailwind';
import CreateOrderCreativeSearchExtension from '../moduleFiles/createOrderCreativeSearchExtension';
import { userRegister } from '../services/userRegister';
import CreateOrderCreativePerformanceMax from '../moduleFiles/createOrderCreativePerformanceMax';
import CreateOrderCreativeDisplay from '../moduleFiles/createOrderCreativeDisplay';
import CreativesModal from '../moduleFiles/creativesModal';
import CreativesChannelsModal from '../moduleFiles/creativeChannelsModal';
import SideNotification from '../moduleFiles/sideNotification';
import CreateOrderCreativeCollectionEdit from '../moduleFiles/createOrderCreativeCollectionEdit';
import { SlideDown } from "react-slidedown";
import { searchGoogle } from '../validators/searchGoogle';
import { performanceMax } from '../validators/performanceMax';
import { extensionGoogle } from '../validators/extensionGoogle';
import FacebookCTA from '../assets/json/facebook_cta.json';

var pusherService = null;

class CreateDraftCreatives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            tabs: [],
            tab: {},
            subtab: {},
            client: {},
            wizard: {},
            creatives: [],
            assetItems: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            add_post_error: false,
            tiktok_new_post: false,
            next: false,
            post_channels: [
                { id: 1, name: 'Facebook', value: 'facebook', page: { id: 0, name: "Select page ...", value: null } },
                { id: 2, name: 'Twitter', value: 'twitter', type: { id: 0, name: "Select type ...", value: null } },
                { id: 3, name: 'TikTok', value: 'tiktok' },
                // { id: 6, name: 'Snapchat', value: 'snapchat' },
                { id: 4, name: 'Linkedin', value: 'linkedin' },
                { id: 5, name: 'Instagram', value: 'instagram', page: { id: 0, name: "Select page ...", value: null }, instagram: { id: 0, name: "Select page ...", value: null } }
            ],
            post_channel: { id: 0, name: 'Select channel ...', value: null },
            pages: [],
            posts: [],
            channels: {},
            order: {},
            alert_options: [{ id: 1, title: "Multiple asset warning", message: "You are about to push multiple ads or keywords to one or multiple adgroups" }, { id: 2, title: "Multiple adgroup warning", message: "You are about to push one ad or keyword group to multiple adgroups" }],
            alert_massege: { title: "", message: "" },
            catalogs: {
                facebook: []
            },
            catalog: {
                facebook: { id: 0, name: "Select catalog" }
            },
            product_sets: {
                facebook: []
            },
            product_set: {
                facebook: { id: 0, name: "Select product set" }
            },
            products: {
                facebook: []
            },
            temp_creatives: [],
            selected_channels: [],
            select_channel: false,
            open: false,
            open_push: false,
            open_cropper: false,
            open_keywords: false,
            open_extension: false,
            open_searchad: false,
            open_performancemax: false,
            open_display: false,
            saved_success: false,
            select_all: false,
            ads_warning: false,
            google_warning: false,
            collection: false,
            youtube_url: "",
            assets: {
                google: []
            },
            error: false,
            size_error: false,
            group: {},
            keywordLists: false,
            ai_result: false,
            open_creatives: {
                facebook: true,
                linkedin: true,
                twitter: true,
                tiktok: true,
                snapchat: true,
            },
            asset_view: "grid",
            asset_seach_value: "",
            tab_view: "list",
            seach_value: "",
            ads_edit_warning: false,
            temp_files: false,
            creado: false,
        };
    }

    async componentDidMount() {

        pusherService = new Pusher('a07698cf59a1788c44d4', {
            cluster: 'eu'
        });

        await this.form.get();
        this.props.setSteps();
        Object.keys(this.state.channels).forEach(async item => {
            if (Array.isArray(this.state.client.channels) && this.state.client.channels.filter((inner_item) => { return inner_item.value === item }).length < 1) {
                await this.promisedSetState({
                    error: true
                })
            }
        });
        //INITIATE TABS
        if (this.state.channels) {

            let tabs = [];

            //SOCIAL 
            if (this.state.channels.facebook || this.state.channels.tiktok || this.state.channels.linkedin || this.state.channels.twitter || this.state.channels.snapchat) {
                tabs.push({ id: 1, name: 'Uploaded', value: 'uploaded', icon: PhotographIcon, subtabs: [{ name: "Creatives", value: "creatives" }] });
            }
            if (this.state.channels.facebook) {
                // || this.state.channels.tiktok) {
                let subtabs = [];
                if (this.state.channels.facebook) {
                    subtabs.push({ name: "Facebook", value: "facebook" });
                }
                if (this.state.channels.tiktok) {
                    //subtabs.push({ name: "Tik Tok", value: "tiktok" });
                }
                tabs.push({ id: 2, name: 'Catalogs', value: 'catalogs', icon: GiftIcon, subtabs: subtabs });
            }

            //SOCIAL POSTS
            if (this.state.channels.facebook || this.state.channels.tiktok || this.state.channels.linkedin || this.state.channels.twitter) { //|| this.state.channels.snapchat) {
                let subtabs = [];
                if (this.state.channels.facebook) {
                    subtabs.push({ name: "Facebook", value: "facebook" }); // WAIT WITH THIS ONE
                    subtabs.push({ name: "Instagram", value: "instagram" });
                }
                if (this.state.channels.tiktok) {
                    subtabs.push({ name: "Tik Tok", value: "tiktok" });
                }
                if (this.state.channels.linkedin) {
                    subtabs.push({ name: "Linkedin", value: "linkedin" });
                }
                if (this.state.channels.twitter) {
                    subtabs.push({ name: "Twitter", value: "twitter" });
                }
                // if (this.state.channels.snapchat) {
                //     subtabs.push({ name: "Snapchat", value: "snapchat" });
                // }
                tabs.push({ id: 5, name: 'Posts', value: 'posts', icon: AnnotationIcon, subtabs: subtabs });
            }

            //GOOGLE
            if (this.state.channels.google) {

                /*
                let campaign_types = {};
                this.state.campaigns.map((item) => {
                    campaign_types[item.campaign_type] = true;
                });
                let tab = { id: 3, name: 'Search', value: 'search', icon: SearchCircleIcon, subtabs: [] };
                let subtabs = [];
                if ("Search" in campaign_types) {
                    subtabs.push({ name: "Keywords", value: "keywords" });
                    subtabs.push({ name: "Responsive text ads", value: "ads" });
                    subtabs.push({ name: "Extensions", value: "extension" });
                }
                if ("Performance Max" in campaign_types) {
                    subtabs.push({ name: "Performance max", value: "performance_max" });
                }
                if ("Shopping" in campaign_types) {
                    subtabs.push({ name: "Shopping", value: "shopping" });
                }
                if ("Video" in campaign_types) {
                    subtabs.push({ name: "Youtube", value: "youtube" });
                }
                tab.subtabs = subtabs;
                tabs.push(tab);
                */

                let subtabs = [];

                //SEARCH RELATED
                if (this.state.campaigns.filter((campaign) => {
                    return campaign.channel == 'google' && campaign.advertising_type && campaign.advertising_type.value === 'SEARCH'
                }).length > 0) {
                    subtabs = subtabs.concat([{ name: "Keywords", value: "keywords" }, { name: "Text ads", value: "ads" }, { name: "Extensions", value: "extension" }]);
                }

                //PERFORMANCE MAX
                if (this.state.campaigns.filter((campaign) => {
                    return campaign.channel == 'google' && campaign.advertising_type && campaign.advertising_type.value === 'PERFORMANCE_MAX'
                }).length > 0) {
                    subtabs = subtabs.concat([{ name: "Performance max", value: "performance_max" }]);
                }

                //DISPLAY NETWORK
                if (this.state.campaigns.filter((campaign) => {
                    return campaign.channel == 'google' && campaign.advertising_type && campaign.advertising_type.value === 'DISPLAY'
                }).length > 0) {
                    subtabs = subtabs.concat([{ name: "Keywords", value: "keywords" }, { name: "Display Network", value: "display" }]);
                }

                //SHOPPING
                if (this.state.campaigns.filter((campaign) => {
                    return campaign.channel == 'google' && campaign.advertising_type && campaign.advertising_type.value === 'SHOPPING'
                }).length > 0) {
                    subtabs = subtabs.concat([{ name: "Shopping", value: "shopping" }]);
                }

                //YOUTUBE
                if (this.state.campaigns.filter((campaign) => {
                    return campaign.channel == 'google' && campaign.advertising_type && campaign.advertising_type.value === 'VIDEO'
                }).length > 0) {
                    subtabs = subtabs.concat([{ name: "Youtube", value: "youtube" }]);
                }

                await tabs.push({ id: 3, name: 'Search', value: 'search', icon: SearchCircleIcon, subtabs: subtabs });

                /*
                //tabs.push({ id: 3, name: 'Search', value: 'search', icon: SearchCircleIcon, subtabs: [{ name: "Keywords", value: "keywords" }, { name: "Text ads", value: "ads" }, { name: "Extensions", value: "extension" }, { name: "Shopping", value: "shopping" }, { name: "Performance max", value: "performance_max" }, { name: "Youtube", value: "youtube" }] });
                let uploaded_exist = false;
                tabs = tabs.map((item) => {
                    if (item.value === "uploaded") {
                        //uploaded_exist = true;
                        //item.subtabs.push({ name: "Youtube", value: "youtube" });
                    }
                    return item;
                });
                if (!uploaded_exist) {
                    //tabs.push({ id: 1, name: 'Uploaded', value: 'uploaded', icon: PhotographIcon, subtabs: [{ name: "Youtube", value: "youtube" }] });
                }
                */

            }

            await this.promisedSetState({
                tabs: tabs,
                tab: tabs[0],
                subtab: Array.isArray(tabs[0].subtabs) ? tabs[0].subtabs[0] : {},
                creatives: this.state.creatives.map((item) => { item.selected = false; return item })
            });

            //PRE LOAD CATALOGS
            if (this.state.tab.value === "catalogs") {
                if (this.state.subtab.value === "facebook") {
                    this.functions.getFacebookCatalogs();
                }
            }

            //PRE LOAD POSTS
            if (this.state.tab.value === "posts") {
                if (this.state.subtab.value === "linkedin" || this.state.subtab.value === "tiktok") {
                    this.functions.getPosts(this.state.subtab);
                } else if (this.state.subtab.value === "facebook" || this.state.subtab.value === "instagram") {
                    this.functions.listFacebookPages();
                }
            }
            if (this.state.creatives.filter((item) => { return item.ad_type && item.ad_type === "collection_ad" }).length > 0) {
                this.functions.getInstant();
            }

        }
    }

    form = {
        set: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                draftRegister.set("creatives", self.state.creatives);
                draftRegister.set("assetItems", self.state.assetItems);
                draftRegister.set("client", self.state.client);
                draftRegister.set("order", self.state.order);
                draftRegister.set("campaigns", self.state.campaigns);
                draftRegister.set("adgroups", self.state.adgroups);
                draftRegister.set("ads", self.state.ads);
                draftRegister.set("channels", self.state.channels);
                resolve();
            })
        },
        get: () => {
            let self = this;
            return new Promise(async function (resolve, reject) {
                if (draftRegister.get()) {
                    await self.promisedSetState({
                        creatives: draftRegister.get()["creatives"] ? draftRegister.get()["creatives"] : self.state.creatives,
                        assetItems: draftRegister.get()["assetItems"] ? draftRegister.get()["assetItems"] : self.state.assetItems,
                        client: draftRegister.get()["client"] ? draftRegister.get()["client"] : self.state.client,
                        wizard: draftRegister.get()["wizard"] ? draftRegister.get()["wizard"] : self.state.wizard,
                        order: draftRegister.get()["order"] ? draftRegister.get()["order"] : self.state.order,
                        campaigns: draftRegister.get()["campaigns"] ? draftRegister.get()["campaigns"] : self.state.campaigns,
                        adgroups: draftRegister.get()["adgroups"] ? draftRegister.get()["adgroups"] : self.state.adgroups,
                        ads: draftRegister.get()["ads"] ? draftRegister.get()["ads"] : self.state.ads,
                        channels: draftRegister.get()["channels"] ? draftRegister.get()["channels"] : self.state.channels
                    })
                }
                resolve();
            })
        }
    };

    functions = {
        onSelectFile: async (e) => {
            let files = e.target.files;
            if (files) {
                for (let i in files) {
                    if (files[i].type === 'video/mp4' || files[i].type === 'image/gif' || files[i].type === 'video/quicktime' || files[i].type === 'video/mov') {
                        this.functions.uploadVideo(files[i]);
                    } else if (files[i].type === 'image/jpg' || files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
                        this.functions.uploadImage(files[i]);
                    }
                    const randomString = Math.random().toString(36);
                    this.promisedSetState({
                        theInputKeyOne: randomString + 1
                    });
                }
            }
        },
        uploadImage: async (file) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'image'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            await this.promisedSetState({ assetItems: this.state.assetItems });
            try {
                const formData = new FormData();
                formData.append('file', file);
                let response = await this.calls.image(formData);
                this.functions.checkImageSize(response.data)
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems });
                await this.form.set();
                this.props.setSteps();
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems });
            }
        },
        uploadVideo: async (file) => {

            let files_object = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                loading: true,
                name: file.name,
                name_by_channels: {
                    facebook: file.name,
                    linkedin: file.name,
                    tiktok: file.name,
                    twitter: file.name,
                    snapchat: file.name
                },
                type: 'video'
            };

            files_object.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
            files_object.allowed_types = { regular: true };
            files_object.allowed_multiple = true;

            this.state.assetItems.push(files_object);
            await this.promisedSetState({ assetItems: this.state.assetItems });

            let channel_id = Math.floor((Math.random() * 9999999999) + 1) + "_videoUpload";
            let self = this;
            var channel = pusherService.subscribe(channel_id);
            channel.bind('videoUpload', async function (response) {
                console.log("response", response);
                if (response && response.status === 200) {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item = { ...item, ...response.data };
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                    await self.form.set();
                    self.props.setSteps();
                    await self.functions.checkVideoSize(response.data)
                } else {
                    self.state.assetItems = self.state.assetItems.map((item) => {
                        if (item.id === files_object.id) {
                            item.loading = false;
                            item.error = true;
                        }
                        return item;
                    });
                    await self.promisedSetState({ assetItems: self.state.assetItems });
                }
                try {
                    pusherService.unsubscribe(channel_id);
                } catch (error) {
                    console.log(error);
                }
            });

            try {
                const formData = new FormData();
                formData.append('file', file);
                await this.calls.video(formData, channel_id);
            } catch (error) {
                this.state.assetItems = this.state.assetItems.map((item) => {
                    if (item.id === files_object.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ assetItems: this.state.assetItems });
            }
        },
        updateWizard: async () => {
            await this.promisedSetState({ loading_wizard: true });
            try {
                await this.calls.updateWizard({
                    campaigns: this.state.campaigns,
                    adgroups: this.state.adgroups,
                    ads: this.state.ads,
                    channels: this.state.channels,
                    creatives: this.state.creatives,
                    assetItems: this.state.assetItems
                });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({ loading_wizard: false });
        },
        createCreative: async (data) => {

            let media = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: data.type,
                loading: true
            };

            media.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            media.allowed_types = { regular: true };
            media.allowed_multiple = true;

            if (data.type === "video") {
                media.allowed_channels.tiktok = true;
                media.allowed_channels.snapchat = true;
            }

            this.state.creatives.push(media);
            try {
                let response = await this.calls.creative({
                    name: data.name,
                    size: data.size,
                    type: data.type,
                    format: data.format,
                    url: data.url
                });
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === media.id) {
                        item.loading = false;
                        item = { ...item, ...response.data };
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
                this.form.set();
            } catch (error) {
                this.state.creatives = this.state.creatives.map((item) => {
                    if (item.id === media.id) {
                        item.loading = false;
                        item.error = true;
                    }
                    return item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
            }
        },
        createCarousel: async () => {
            let carousel = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name_by_channels: {
                    facebook: this.state.assetItems.filter((item) => { return item.selected })[0].name,
                    linkedin: this.state.assetItems.filter((item) => { return item.selected })[0].name,
                    tiktok: "",
                    snapchat: "",
                    twitter: this.state.assetItems.filter((item) => { return item.selected })[0].name
                },
                type: "carousel",
                files: JSON.parse(JSON.stringify(this.state.assetItems.filter((item) => { return item.selected })))
            };

            carousel.allowed_channels = { facebook: true, linkedin: true, twitter: true };
            carousel.allowed_types = { regular: true };
            carousel.allowed_multiple = true;

            this.state.temp_creatives.push(carousel);
            //this.state.creatives.push(carousel);
            this.state.assetItems = this.state.assetItems.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ temp_creatives: this.state.temp_creatives, select_channel: true });
            await this.form.set();
            this.props.setSteps();
        },
        createMerge: async () => {

            let merged = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name_by_channels: { facebook: this.state.assetItems.filter((item) => { return item.selected })[0].name },
                type: "merge",
                video: this.state.assetItems.filter((item) => { return item.selected })[0].type === "video",
                image: this.state.assetItems.filter((item) => { return item.selected })[0].type === "image",
                files: JSON.parse(JSON.stringify(this.state.assetItems.filter((item) => { return item.selected }))),
                headlines: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                bodies: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                descriptions: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                links: [{ id: Math.floor((Math.random() * 9999999999) + 1), value: "" }],
                caption: "",
                call_to_actions: [{
                    id: Math.floor((Math.random() * 9999999999) + 1), value: {
                        "name": "No Button",
                        "id": "NO_BUTTON",
                        "type": "NO_BUTTON"
                    }
                }],
                objective: { id: 0, name: "Click here ..." }
            };

            //CHECK RATIOS
            let vertical = merged.files.filter((item) => { return item.ratio === "9:16" });
            let horizontal = merged.files.filter((item) => { return item.ratio === "1.91:1" });
            let regular = merged.files.filter((item) => { return item.ratio !== "1.91:1" && item.ratio !== "9:16" });

            if (vertical.length > 1) {
                merged.error = true;
                merged.message = "Only one 9:16 format is allowed";
            } else if (horizontal.length > 1) {
                merged.error = true;
                merged.message = "Only one 1.91:1 format is allowed";
            } else if (regular.length > 1) {
                merged.error = true;
                merged.message = "Only one other format other than 9:16 and 1.91:1 is allowed";
            }

            if (merged.files.length > 3) {
                merged.error = true;
                merged.message = "Only 3 creatives allowed";
            }

            merged.allowed_channels = { facebook: true };
            merged.allowed_types = { regular: true };
            merged.allowed_multiple = true;

            this.state.temp_creatives.push(merged);

            this.state.assetItems = this.state.assetItems.map((item) => { item.selected = false; return item });

            //this.state.creatives.push(merged);
            //this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ temp_creatives: this.state.temp_creatives, select_channel: true });
            await this.form.set();
            this.props.setSteps();
        },
        createKeywordList: async (data) => {
            let keyword_list = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: "keywords",
                negative: data.negative,
                keywords: data.keywords
            };

            keyword_list.allowed_channels = { google: true };
            keyword_list.allowed_types = { search: true };
            keyword_list.allowed_multiple = true;

            this.state.creatives.push(keyword_list);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_keywords: false });
            await this.form.set();
            this.props.setSteps();
        },
        createExtension: async (data) => {
            let extension = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: "extension"
            };
            extension = { ...extension, ...data };

            extension.allowed_channels = { google: true };
            extension.allowed_types = { search: true };
            extension.allowed_multiple = true;

            this.state.creatives.push(extension);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_extension: false });
            await this.form.set();
            this.props.setSteps();
        },
        createPerformanceMax: async (data) => {
            let performance_max = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: "performance_max"
            };
            performance_max = { ...performance_max, ...data };

            performance_max.allowed_channels = { google: true };
            performance_max.allowed_types = { performance_max: true };
            performance_max.allowed_multiple = false;

            this.state.creatives.push(performance_max);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_performancemax: false });
            await this.form.set();
            this.props.setSteps();
        },
        createDisplay: async (data) => {
            console.log(data, "YOXEEEEE")
            let display = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: "display",
                ad_type: data.type
            };
            display = { ...display, ...data };

            display.allowed_channels = { google: true };
            display.allowed_types = { display: true };
            display.allowed_multiple = false;

            this.state.creatives.push(display);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_display: false });
            await this.form.set();
            this.props.setSteps();
        },
        createSearchAd: async (data) => {
            let search_ad = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: data.name,
                type: "search_ad",
                format: data.format,
                headlines: data.headlines ? data.headlines : [],
                bodies: data.bodies ? data.bodies : [],
                link: data.link,
                path1: data.path1,
                path2: data.path2,
                combinations: data.combinations,
                OpenAiResult: data.OpenAiResult ? data.OpenAiResult : []
            };

            search_ad.allowed_channels = { google: true };
            search_ad.allowed_types = { search: true };
            search_ad.allowed_multiple = true;

            this.state.creatives.push(search_ad);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_searchad: false });
            await this.form.set();
            this.props.setSteps();
        },
        createShopping: async (data) => {
            let shopping_ad = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: "All products",
                type: "shopping",
                filter: { id: 1, name: "All products", value: "all" }
            };

            shopping_ad.allowed_channels = { google: true };
            shopping_ad.allowed_types = { shopping: true };
            shopping_ad.allowed_multiple = true;

            this.state.creatives.push(shopping_ad);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, open_searchad: false });
            await this.form.set();
            this.props.setSteps();
        },
        createYoutube: async (data) => {
            let youtube_ad = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name: "",
                type: "youtube",
                url: this.state.youtube_url,
                //loading: true,
                loading: false,
                ad_type: {},
                //youtube_id: this.state.youtube_url.replace("https://youtube.com/shorts/", "")
            };
            if (this.state.youtube_url.indexOf("https://www.youtube.com/watch") !== -1) {
                try {
                    var video_id = this.state.youtube_url.split('v=')[1];
                    var ampersandPosition = video_id.indexOf('&');
                    if (ampersandPosition != -1) {
                        video_id = video_id.substring(0, ampersandPosition);
                    }
                    youtube_ad.youtube_id = video_id;
                } catch (error) {
                    youtube_ad.error = true;
                }
            } else if (this.state.youtube_url.indexOf("https://www.youtube.com/shorts") !== -1) {
                youtube_ad.youtube_id = this.state.youtube_url.replace("https://www.youtube.com/shorts/", "");
            } else {
                youtube_ad.loading = false;
                youtube_ad.error = true;
            }

            youtube_ad.allowed_channels = { google: true };
            youtube_ad.allowed_types = { video: true };
            youtube_ad.allowed_multiple = true;

            this.state.creatives.push(youtube_ad);
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            await this.promisedSetState({ creatives: this.state.creatives, youtube_url: "" });
            await this.form.set();
            this.props.setSteps();
            //await this.functions.createYoutubeAsset(youtube_ad);
            await this.functions.getYoutubeData(youtube_ad);
        },
        createCatalog: async (channel) => {
            let catalog = {
                id: Math.floor((Math.random() * 9999999999) + 1),
                name_by_channels: { [channel]: this.state.catalog[channel].name + " - " + this.state.product_set[channel].name },
                loading: true,
                type: "catalog",
                channel: channel,
                catalog: this.state.catalog[channel],
                product_set: this.state.product_set[channel],
                preview_products: [],
                product_count: this.state.product_set[channel].product_count
            };

            //catalog.channel_specifik = channel;
            catalog.allowed_channels = {};
            catalog.allowed_channels[channel] = true;
            catalog.allowed_types = { regular: true };
            catalog.allowed_multiple = true;

            this.state.creatives.push(catalog);
            if (channel === "facebook") {
                this.functions.getFacebookProducts(this.state.product_set.facebook.id, catalog.id);
            }
            this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
            this.state.product_set.facebook = { id: 0, name: "Select product set" };
            await this.promisedSetState({ creatives: this.state.creatives, product_set: this.state.product_set });
            await this.form.set();
            this.props.setSteps();
        },
        getInstant: async () => {
            try {
                for (let i = 0; i < this.state.creatives.filter((item) => { return item.ad_type && item.ad_type === "collection_ad" }).length; i++) {
                    let instant = this.state.creatives.filter((item) => { return item.ad_type && item.ad_type === "collection_ad" })[i].instantExperience;
                    let page = this.state.creatives.filter((item) => { return item.ad_type && item.ad_type === "collection_ad" })[i].page;
                    if (instant && instant.id && instant.id !== 0 && page.facebook && page.facebook.id && page.facebook.id !== 0) {
                        let response = await this.calls.getInstant(instant.id, page.facebook.id);
                        if (response.data && ((response.data.photo || response.data.video) && (response.data.carousel || response.data.product_set))) {
                            this.state.creatives = this.state.creatives.map((item) => {
                                if (item.id === this.state.creatives.filter((item) => { return item.ad_type && item.ad_type === "collection_ad" })[i].id) {
                                    item.instantExperience.elements = response.data;
                                }
                                return item;
                            });
                            await this.promisedSetState({
                                creatives: this.state.creatives
                            });
                        }
                    }
                }
                this.form.set();

            } catch (error) {
                //console.log(error);
            }
        },
        getPosts: async (channel) => {
            await this.promisedSetState({ loading_posts: true });
            try {
                let response = await this.calls.posts(channel);
                if (response.pagination && response.data.length > 0) {
                    await this.promisedSetState({ next: response.pagination.next });
                } else {
                    await this.promisedSetState({ next: false });
                }
                this.state.posts = this.state.posts.concat(response.data.map((item) => {
                    if (item.website) {
                        item.loading = true
                        item.url = ""
                    }
                    let inner_item = {};
                    if (this.state.subtab.value === "tiktok") {
                        inner_item = {
                            type: "video",
                            channel: this.state.subtab.value,
                            id: item.id,
                            body: item.body,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            url: item.video_info.preview_url,
                            user: item.user_info,
                            // page: item.page_info
                            disabled: false,
                            thumbnail: item.video_info && item.video_info.preview_url ? item.video_info.preview_url : ""
                        }
                        if (inner_item.url === "") {
                            inner_item.no_content = true;
                            inner_item.disabled = true;
                        }
                    }
                    if (this.state.subtab.value === "snapchat") {
                        inner_item = {
                            type: "video",
                            channel: this.state.subtab.value,
                            id: item.id,
                            body: item.body,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            url: item.video_info.preview_url,
                            user: item.user_info
                        }
                        if (inner_item.url === "") {
                            inner_item.no_content = true;
                            inner_item.disabled = true;
                        }
                    } else if (this.state.subtab.value === "twitter") {
                        inner_item = {
                            type: item.type,
                            url: item.url,
                            id: item.external_id,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            body: item.body,
                            channel: this.state.subtab.value,
                            link: item.link,
                            thumbnail: item.thumbnail
                        }
                        if (inner_item.url === "") {
                            inner_item.no_content = true;
                            inner_item.disabled = true;
                        }
                        if (item.carousel.length > 0) inner_item.carousel = item.carousel
                    } else if (this.state.subtab.value === "linkedin") {
                        inner_item = {
                            type: !item.type && item.content && item.content.carousel && item.content.carousel.length !== 0 ? "carousel" : !item.type && item.content && item.content.reference && item.content.reference.id.length !== 0 ? "article" : item.type,
                            id: item.id,
                            channel: this.state.subtab.value,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            url: item.url,
                            body: item.commentary.replaceAll(/([\[\]])/ig, "").replaceAll(/(\(urn:li:\w+:[\d\w]+\))/ig, "").replaceAll(/([\\\|\{\}])/ig, "").replaceAll(/(hashtag)/ig, ""),
                            title: item.content && item.content.article && item.content.article.title ? item.content.article.title : "",
                            disabled: false,
                        }
                        if (inner_item.type === "carousel") inner_item.carousel = item.content && item.content.carousel && Array.isArray(item.content.carousel.cards) ? item.content.carousel.cards.map(item => {
                            return {
                                url: item.image,
                                type: "image",
                            }
                        }) : []
                        if (item.document && item.document !== "") {
                            inner_item.document = item.document;
                            inner_item.ad_type = "document";
                            inner_item.type = "document"
                        }
                        if (item.content && item.content.poll) {
                            inner_item.ad_type = "poll";
                            inner_item.type = "poll"
                        }
                        if (inner_item.type === "article" || inner_item.type === "document" || inner_item.type === "poll") {
                            inner_item.disabled = true;
                        }
                        if (!item.content) {
                            inner_item.disabled = true;
                            inner_item.no_content = true;
                        };
                        if (item.website) inner_item.website = item.website
                        if (item.loading) inner_item.loading = item.loading
                    } else if (this.state.subtab.value === "facebook") {
                        let message = item.message ? item.message : "";
                        let type = item.media_type === "VIDEO" ? "video" : "image";
                        let url = item.media_type === "VIDEO" ? item.media_url : item.full_picture;
                        let carousel = [];
                        if (item.attachments && item.attachments.data) {
                            message = (item.attachments.data[0].title ? item.attachments.data[0].title : (item.attachments.data[0].description ? item.attachments.data[0].description : message))
                        }
                        if (item.status_type === "added_video") {
                            type = "video";
                            if (item.attachments && item.attachments.data && item.attachments.data[0].media && item.attachments.data[0].media.source) {
                                url = item.attachments.data[0].media.source
                            }
                        }

                        if (item.attachments && item.attachments.data && item.attachments.data[0].subattachments && item.attachments.data[0].subattachments.data) {
                            let buffer = item.attachments.data[0].subattachments.data;
                            for (let m = 0; m < buffer.length; m++) {
                                carousel.push({ url: buffer[m].media.image.src, type: "image", link: "" })
                            }

                        }

                        inner_item = {
                            type: type,
                            id: item.id,
                            channel: this.state.subtab.value,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            url: url,
                            body: message,
                            thumbnail: item.thumbnail_url ? item.thumbnail_url : item.media_url,

                        }
                        if (inner_item.url === "") {
                            inner_item.no_content = true;
                            inner_item.disabled = true;
                        }
                        if (carousel.length > 0) inner_item.carousel = carousel;
                    } else if (this.state.subtab.value === "instagram") {
                        inner_item = {
                            type: item.media_type === "VIDEO" ? "video" : "image",
                            id: item.id,
                            channel: this.state.subtab.value,
                            name: "Sponsored " + Math.floor((Math.random() * 9999999999) + 1),
                            body: item.caption,
                            url: item.media_type === "VIDEO" ? item.media_url : item.thumbnail_url ? item.thumbnail_url : item.media_url,
                            thumbnail: item.thumbnail_url ? item.thumbnail_url : item.media_url,
                        }
                        if (item.carousel.length > 0) inner_item.carousel = item.carousel;
                    }
                    return inner_item;
                }));

                await this.promisedSetState({ posts: this.state.posts });
            } catch (error) {
                console.log("POST IR error", error)
            }
            await this.promisedSetState({ loading_posts: false });
            for (let i = 0; i < this.state.posts.length; i++) {
                if (this.state.posts[i].website) {
                    this.state.posts[i].url = await this.functions.loadImageUrl(this.state.posts[i].website)
                    this.state.posts[i].loading = false
                }
            }
            await this.promisedSetState({ posts: this.state.posts });

        },
        loadImageUrl: async (data) => {
            try {
                let response = await this.calls.loadImageUrl({ url: data });
                return response.data.image;
            } catch (error) { }
        },
        createPost: async (data) => {
            if (!data.disabled) {
                let post = {
                    id: data.id,
                    name: data.name,
                    loading: false,
                    type: "posts",
                    channel: data.channel,
                    external_id: data.id,
                    url: data.url,
                    picture: data.url,
                    body: data.body,
                    ad_type: data.type,
                    ...data.document && { document: data.document },
                    ...data.title && { title: data.title },
                    ...data.thumbnail && { thumbnail: data.thumbnail },
                    // ...data.page && { page: data.page },
                    //link: data.link ? data.link : ""
                };
                if (data.link) {
                    post.website = data.link;
                }
                if (data.carousel) {
                    post.files = data.carousel;
                }
                if (data.channel === "instagram") {
                    post.channel = "facebook";
                    post.instagram = this.state.subtab.instagram
                    post.instagram_post = true
                } else if (data.channel === "tiktok") {
                    post.user = data.user
                } else if (data.channel === "snapchat") {
                    post.user = data.user
                }

                post.allowed_channels = {};
                post.allowed_channels[post.channel] = true;
                post.allowed_types = { regular: true };
                post.allowed_multiple = true;

                this.state.creatives.push(post);
                this.state.creatives = this.state.creatives.map((item) => { item.selected = false; return item });
                await this.promisedSetState({ creatives: this.state.creatives });
                await this.form.set();
                this.props.setSteps();
            }
        },
        create_tiktok_post: async () => {
            if (!this.state.tiktok_new_ad_code || this.state.tiktok_new_ad_code === "") {
                await this.promisedSetState({ loading_tiktok_create: false, add_post_error: "Enter TikTok post code" });
            } else {
                let data = {
                    code: this.state.tiktok_new_ad_code,
                    duet_code: this.state.tiktok_new_ad_code_duet
                }
                await this.promisedSetState({ loading_tiktok_create: true });
                try {
                    let response = await this.calls.createTikTokSparkPost(data);
                    this.promisedSetState({
                        tiktok_new_ad_code: "",
                        tiktok_new_ad_code_duet: "",
                        loading_tiktok_create: false,
                        tiktok_new_post: false,
                        add_post_error: false,
                        posts: []
                    });
                    this.functions.getPosts(this.state.subtab);
                } catch (error) {
                    await this.promisedSetState({ loading_tiktok_create: false, add_post_error: error.body.message });
                }
            }
        },
        getFacebookCatalogs: async () => {
            await this.promisedSetState({ loading_fb_catalogs: true });
            try {
                let response = await this.calls.facebookCatalogs();
                this.state.catalogs.facebook = response.data;
                await this.promisedSetState({ catalogs: this.state.catalogs });
            } catch (error) { }
            await this.promisedSetState({ loading_fb_catalogs: false });
        },
        getFacebookProductSets: async () => {
            await this.promisedSetState({ loading_fb_productsets: true });
            try {
                let response = await this.calls.facebookProductSets(this.state.catalog.facebook.id);
                this.state.product_sets.facebook = response.data;
                await this.promisedSetState({ product_sets: this.state.product_sets });
            } catch (error) { }
            await this.promisedSetState({ loading_fb_productsets: false });
        },
        getFacebookProducts: async (product_set, creative) => {
            try {
                let response = await this.calls.facebookProducts(product_set);
                this.state.creatives = this.state.creatives.map((item) => {
                    if (creative === item.id) {
                        item.preview_products = response.data;
                        item.loading = false;
                    }
                    return item
                });
                await this.promisedSetState({ creatives: this.state.creatives });
                await this.form.set();
                this.props.setSteps();
            } catch (error) { }
        },
        listFacebookPages: async () => {
            try {
                await this.promisedSetState({ loading_facebook: true });
                let response = await this.calls.facebookPages();
                this.state.pages.facebook = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.picture.data.url } }) : []
                await this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_facebook: false });
        },
        listInstagramAccounts: async () => {
            await this.promisedSetState({ loading_instagram: true });
            try {
                let response = await this.calls.instagramAccounts();
                this.state.pages.instagram = Array.isArray(response.data) ? response.data.filter((item) => { return item.instagram_business_account }).map((item) => { return { name: item.instagram_business_account.name, id: item.instagram_business_account.id, image: item.instagram_business_account.profile_picture_url, ig_id: item.id } }) : [];
                this.promisedSetState({ pages: this.state.pages })
            } catch (error) { }
            await this.promisedSetState({ loading_instagram: false });
        },
        listGoogleAssets: async () => {
            await this.promisedSetState({ loading_google: true });
            try {
                let response = await this.calls.googleAssets();
                this.state.assets.google = Array.isArray(response.data) ? response.data.map((item) => { return { name: item.name, id: item.id, image: item.profile_picture_url, ig_id: item.ig_id } }) : [];
                this.promisedSetState({ assets: this.state.assets })
            } catch (error) { }
            await this.promisedSetState({ loading_google: false });
        },
        createYoutubeAsset: async (item) => {
            try {
                let response = await this.calls.createYoutubeAsset({ id: item.youtube_id });
                if (response) {
                    this.state.creatives = this.state.creatives.map((inner_item) => {
                        if (inner_item.id === item.id) {
                            inner_item.loading = false;
                            inner_item.error = false;
                            inner_item.resource_name = response.data.resourceName;
                        }
                        return inner_item;
                    });
                    await this.promisedSetState({ creatives: this.state.creatives });
                    await this.form.set();
                    this.props.setSteps();
                }
            } catch (error) {
                this.state.creatives = this.state.creatives.map((inner_item) => {
                    if (inner_item.id === item.id) {
                        inner_item.loading = false;
                        inner_item.error = true;
                        inner_item.message = "Something went wrong";
                    }
                    return inner_item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
                await this.form.set();
                this.props.setSteps();
            }
        },
        getYoutubeData: async (item) => {
            try {
                let response = await this.calls.getYoutubeData(item.youtube_id);
                if (response) {
                    this.state.creatives = this.state.creatives.map((inner_item) => {
                        if (inner_item.id === item.id) {
                            inner_item.loading = false;
                            inner_item.error = false;
                            inner_item.data = response.data;
                            inner_item.name = response.data.title;
                            inner_item.description = response.data.description;
                        }
                        return inner_item;
                    });
                    await this.promisedSetState({ creatives: this.state.creatives });
                    await this.form.set();
                    this.props.setSteps();
                }
            } catch (error) {
                this.state.creatives = this.state.creatives.map((inner_item) => {
                    if (inner_item.id === item.id) {
                        inner_item.loading = false;
                        inner_item.error = true;
                        inner_item.message = "Something went wrong";
                    }
                    return inner_item;
                });
                await this.promisedSetState({ creatives: this.state.creatives });
                await this.form.set();
                this.props.setSteps();
            }
        },
        clone: async () => {
            if (this.state.creatives.filter((item) => { return item.selected }).length === 1) {
                let item = JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected })[0]));
                item.id = Math.floor((Math.random() * 9999999999) + 1);
                item.selected = false;
                this.state.creatives.push(item);
                await this.promisedSetState({ creatives: this.state.creatives });
                await this.form.set();
                this.props.setSteps();
            }
        },
        checkImageSize: async (data) => {
            if (data.size > 5000000) {
                await this.promisedSetState({ size_error: true });
            } else {
                await this.promisedSetState({ size_error: false });
            }
        },
        checkVideoSize: async (data) => {
            if (data.size > 1000000000) {
                // if (data.size > 100) {
                await this.promisedSetState({ size_error: true });
            } else {
                await this.promisedSetState({ size_error: false });
            }
        },
        updateAdPreview: async (data) => {
            await this.promisedSetState({ ad_preview_data: data });
        },

        //PUSHFUNCTIONS
        push: async (skip, adgroups, files, update = false, campaigns = []) => {


            let google_error = false;
            if (!update && !skip && adgroups.filter((item) => { return item.selected }).length > 0) {
                let buffer = [0, 0, 0];
                files.filter((item) => { return item.selected }).map((file) => {
                    if (file.type === "search_ad") {
                        buffer[0]++;
                    } else if (file.type === "keywords") {
                        buffer[1]++;
                    } else if (file.type === "extension") {
                        if (files.filter((item) => { return item.selected && item.type === "extension" && item.subtype.value === file.subtype.value }).length > 1) buffer[2] = 2;
                    }
                })

                if (buffer.filter((item) => { return item > 1 }).length > 0 || adgroups.filter((item) => { return item.selected && item.channel === "google" }).length > 1) {
                    google_error = true;
                    if (buffer.filter((item) => { return item > 1 }).length > 0) {
                        await this.promisedSetState({
                            google_warning: true,
                            alert_massege: this.state.alert_options.filter((item) => { return item.id === 1 })[0]
                        });
                        //this.props.onAlert(1);
                    } else {
                        await this.promisedSetState({
                            google_warning: true,
                            alert_massege: this.state.alert_options.filter((item) => { return item.id === 2 })[0]
                        });
                        //this.props.onAlert(2);
                    }
                }
            }

            if (update) {
                adgroups = JSON.parse(JSON.stringify(this.state.adgroups));
                files = JSON.parse(JSON.stringify(this.state.temp_files)).map((item) => { item.selected = true; return item });
                let adgroup_ads = this.state.ads.filter((item) => {
                    return item.creative_id && item.creative_id === files[0].id
                }).map((item) => { return item.adgroup });
                campaigns = JSON.parse(JSON.stringify(this.state.campaigns));
                this.state.ads = this.state.ads.filter((item) => { return !item.creative_id || (item.creative_id && item.creative_id !== files[0].id) });
                campaigns = campaigns.map((item) => {
                    if (item.asset) {
                        if (item.asset.filter((inner_item) => { return inner_item.creative_id && inner_item.creative_id === files[0].id }).length > 0) {
                            item.selected = true;
                        }
                    }
                    return item;
                });
                adgroups = adgroups.map((item) => {
                    if (adgroup_ads.includes(item.id)) {
                        item.selected = true;
                    }
                    return item;
                });
            }
            if (google_error) {
                await this.promisedSetState({
                    temp_creatives: files,
                });
            } else if (!google_error) {
                if (files.filter((item) => { return item.selected }).length > 0) {
                    campaigns.filter((item) => { return item.selected }).map((campaign) => {
                        if (campaign.selected) {
                            files.filter((item) => { return item.selected }).map((file) => {
                                let ad = {};
                                ad.creative_id = file.id;
                                ad.id = Math.floor((Math.random() * 99999999999999) + 1);
                                ad.type = file.type;
                                ad.level = "ad";
                                ad.name = file.name;
                                ad.channel = "google";
                                ad.campaign = campaign.id;
                                ad.caption = file.caption;
                                ad.link = file.link;
                                ad.title = file.headline;
                                ad.body = file.body;
                                ad.description = file.description;
                                ad.tracking = file.tracking;
                                ad.ad_type = {};
                                ad.colors = file.colors;

                                if (file.type === "keywords") {
                                    ad.ad_type = { id: 5, name: 'Keywords', value: "keywords" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                } else if (file.type === "extension") {
                                    ad.ad_type = { id: 5, name: 'Extension', value: "extension" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                }

                                ad.selected = false;
                                if (!Array.isArray(campaign.asset)) {
                                    campaign.asset = [];
                                }

                                if (campaign.partial_parced) {
                                    if (ad.type === "extension") {
                                        if (ad.subtype && ["call", "callout", "sitelink"].includes(ad.subtype.value)) {
                                            let buffer = [];
                                            ad.items.map((item, index) => { 
                                                let temp = JSON.parse(JSON.stringify(ad));
                                                temp.id = Math.floor((Math.random() * 99999999999999) + 1);
                                                temp.items = [item];
                                                temp.only_item = true;
                                                buffer.push(temp);
                                            });
                                            campaign.asset = campaign.asset.concat(buffer);
                                        } else {
                                            campaign.asset.push(ad);
                                        }
                                    }
                                } else {
                                    campaign.asset.push(ad);
                                }
                                this.state.campaigns = this.state.campaigns.map((item) => {
                                    if (item.id === campaign.id) {
                                        item.asset = campaign.asset;
                                    }
                                    return item;
                                });
                                this.setState({ campaigns: this.state.campaigns });

                                this.form.set();
                            });
                        }
                    });
                    adgroups.filter((item) => { return item.selected }).map((adgroup) => {
                        if (adgroup.selected) {
                            let ads = [];
                            files.filter((item) => { return item.selected }).map((file) => {

                                file = JSON.parse(JSON.stringify(file));
                                //COMMON SETTINGS
                                let ad = {};
                                ad.creative_id = file.id;
                                ad.id = Math.floor((Math.random() * 99999999999999) + 1);
                                ad.type = file.type;
                                ad.level = "ad";
                                ad.name = file.name;
                                ad.channel = adgroup.channel;
                                ad.campaign = adgroup.campaign;
                                ad.adgroup = adgroup.id;
                                ad.caption = file.caption;
                                ad.link = file.link;
                                ad.title = file.headline;
                                ad.body = file.body;
                                ad.description = file.description;
                                ad.tracking = file.tracking;
                                ad.ad_type = {};
                                ad.colors = file.colors;
                                if (adgroup.campaign_objective && adgroup.campaign_objective.id === 5) {
                                    ad.app = true;
                                    if (adgroup.application && adgroup.application.id !== 0 && adgroup.store && adgroup.store.id !== 0) {
                                        ad.link = adgroup.application.object_store_urls[adgroup.store.value];
                                    } else ad.link = "";
                                }
                                if (adgroup.channel === "facebook") {
                                    ad.campaign_objective = adgroup.campaign_objective;
                                }

                                if (typeof file.website === "string" && file.website !== "") {
                                    ad.link = file.website
                                }
                                //NEW PARTS
                                if (file.body_by_channels && file.body_by_channels[ad.channel] !== "") {
                                    ad.body = file.body_by_channels[ad.channel]
                                }
                                if (file.website_by_channels && file.website_by_channels[ad.channel] !== "") {
                                    ad.link = file.website_by_channels[ad.channel]
                                }
                                if (file.headline_by_channels && file.headline_by_channels[ad.channel] !== "") {
                                    ad.title = file.headline_by_channels[ad.channel]
                                }
                                if (file.name_by_channels && file.name_by_channels[ad.channel] !== "") {
                                    ad.name = file.name_by_channels[ad.channel]
                                } else {
                                    ad.name = ""
                                }

                                if (file.selected_lead && file.selected_lead.id && file.selected_lead.id !== 0) {
                                    ad.selected_lead = file.selected_lead;
                                }

                                if (file.utm_values && file.utm_values[adgroup.channel] && file.utm_values[adgroup.channel] !== "" && ad.link) {
                                    if (ad.link.includes("?")) {
                                        ad.link = ad.link + "&" + file.utm_values[adgroup.channel]
                                        //file.utm_values[adgroup.channel] = (file.utm_values[adgroup.channel].includes("?") ? : file.utm_values[adgroup.channel])
                                    } else {
                                        ad.link = ad.link + "?" + file.utm_values[adgroup.channel]
                                    }
                                } else if ((!ad.link || ad.link === "") && file.utm_values && file.utm_values[adgroup.channel] && file.utm_values[adgroup.channel] !== "") {
                                    ad.link = file.utm_values[adgroup.channel]
                                }

                                //AD TYPE SPECIFIC SETTINGS
                                if (file.type === "posts") {
                                    ad.ad_type = { id: 6, name: 'Posts', value: "posts", type: file.ad_type ? file.ad_type : "carousel_feed" };
                                    // delete file.ad_type;
                                    if (file.channel === "linkedin") {
                                        delete file.ad_type;
                                    }
                                    if (file.channel === "linkedin") {
                                        ad.preview = { id: 1, name: "Feed", value: "feed" };
                                    }
                                    if (file.channel === "facebook") {
                                        if (file.instagram_post) {
                                            ad.instagram_post = true
                                        }
                                    }
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                    if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                        ad.cta = file.cta[adgroup.channel];
                                    }
                                    if (file.page && file.page[adgroup.channel] && file.page[adgroup.channel].id !== 0) {
                                        ad.page = file.page[adgroup.channel];
                                    }
                                    if (file.page && adgroup.channel === "facebook" && file.page.instagram && file.page.instagram.id !== 0 && !ad.instagram_post) {
                                        ad.instagram = file.page.instagram;
                                    }
                                    if (ad.ad_type.type === "video") {
                                        ad.video = file.url;
                                    }
                                } else if (file.type === "keywords") {
                                    ad.ad_type = { id: 5, name: 'Keywords', value: "keywords" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                } else if (file.type === "performance_max") {
                                    ad.ad_type = { id: 5, name: 'Performance max', value: "performance_max" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                    //ad.preview = { id: 2, name: "Youtube", value: "youtube" };
                                    //ad.previews = [{ id: 1, name: "Search", value: "search" }, { id: 2, name: "Youtube", value: "youtube" }, { id: 3, name: "Display", value: "display" }, { id: 4, name: "Gmail", value: "gmail" }, { id: 4, name: "Discover", value: "discover" }];
                                    //ad.previews = [{ id: 2, name: "Youtube", value: "youtube" }, { id: 3, name: "Display", value: "display" }, { id: 4, name: "Gmail", value: "gmail" }, { id: 4, name: "Discover", value: "discover" }];
                                } else if (file.type === "shopping") {
                                    ad.ad_type = { id: 5, name: 'Shopping', value: "shopping" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                    ad.advertising_type = adgroup.advertising_type_ad;
                                    //ad.preview = { id: 1, name: "Search result - Desktop" };
                                    if (Array.isArray(adgroup.preview_products)) {
                                        ad.preview_products = adgroup.preview_products;
                                    }
                                    if (adgroup.feed_label) {
                                        ad.feed_label = adgroup.feed_label;
                                    }
                                } else if (file.type === "extension") {
                                    ad.ad_type = { id: 5, name: 'Extension', value: "extension" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                } else if (file.type === "display") {
                                    ad.ad_type = { id: 5, name: 'Display Network', value: "display" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                } else if (file.type === "youtube") {
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                    //ad.preview = { id: 1, name: "Video - Desktop" };
                                    ad.ad_type = { id: 6, name: 'Youtube', value: "youtube" };
                                    ad.advertising_type = adgroup.advertising_type_ad;
                                } else if (file.type === "search_ad") {
                                    delete file.id;
                                    //Search result - Desktop
                                    ad = { ...ad, ...file };
                                    //ad.preview = { id: 1, name: "Search result - Desktop" };
                                    ad.ad_type = { id: 4, name: 'Search ad', value: "search_ad" };
                                    ad.advertising_type = adgroup.advertising_type_ad;
                                } else if (file.type === "catalog") {
                                    ad.ad_type = { id: 2, name: 'Catalog ad', value: "catalog_ad" };
                                    delete file.id;
                                    ad = { ...ad, ...file };
                                    ad.preview_products = ad.preview_products.map((product) => {
                                        if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                            product.cta = file.cta[adgroup.channel];
                                        }
                                        return product;
                                    });
                                    if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                        ad.cta = file.cta[adgroup.channel];
                                    }
                                    if (file.page && file.page[adgroup.channel] && file.page[adgroup.channel].id !== 0) {
                                        ad.page = file.page[adgroup.channel];
                                    }
                                    if (file.page && adgroup.channel === "facebook" && file.page.instagram && file.page.instagram.id !== 0) {
                                        ad.instagram = file.page.instagram;
                                    }
                                } else if (file.type === "carousel") {
                                    ad.slides = [];
                                    ad.ad_type = { id: 3, name: 'Carousel ad', value: "carousel_ad" };
                                    if (adgroup.channel === "facebook") {
                                        if (file.auto_cards) {
                                            ad.auto_cards = file.auto_cards;
                                        }
                                        if (file.last_card) {
                                            ad.last_card = file.last_card;
                                        }
                                    }
                                    file.files.map((inner_file) => {
                                        let slide = {};
                                        slide = inner_file;
                                        if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                            if (adgroup.channel === "facebook") {
                                                let buffer = JSON.parse(JSON.stringify(FacebookCTA.filter((item) => { return item.id === file.cta[adgroup.channel].id })[0]));
                                                if (buffer && buffer.objective) {
                                                    if (buffer.objective.includes(adgroup.campaign_objective.id)) {
                                                        slide.cta = file.cta[adgroup.channel];
                                                    } else {
                                                        slide.cta = { id: 0, name: "Select ..." };
                                                    }
                                                }

                                            } else {
                                                slide.cta = file.cta[adgroup.channel];
                                            }
                                        }
                                        slide.headline = inner_file.headline;
                                        slide.description = inner_file.body;
                                        slide.image = (inner_file.type === "image");
                                        slide.video = (inner_file.type === "video");
                                        if (inner_file.type === "image") {
                                            slide.picture = inner_file.url;
                                        }
                                        if (inner_file.type === "video") {
                                            //slide.video_data = inner_file;
                                        }
                                        ad.slides.push(slide);
                                    });
                                    if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                        if (adgroup.channel === "facebook") {
                                            let buffer = JSON.parse(JSON.stringify(FacebookCTA.filter((item) => { return item.id === file.cta[adgroup.channel].id })[0]));
                                            if (buffer && buffer.objective) {
                                                if (buffer.objective.includes(adgroup.campaign_objective.id)) {
                                                    ad.cta = file.cta[adgroup.channel];
                                                } else {
                                                    ad.cta = { id: 0, name: "Select ..." };
                                                }
                                            }

                                        } else {
                                            ad.cta = file.cta[adgroup.channel];
                                        }
                                    }
                                    if (file.page && file.page[adgroup.channel] && file.page[adgroup.channel].id !== 0) {
                                        ad.page = file.page[adgroup.channel];
                                    }
                                    if (file.page && adgroup.channel === "facebook" && file.page.instagram && file.page.instagram.id !== 0) {
                                        ad.instagram = file.page.instagram;
                                    }
                                } else if (file.type === "merge") {
                                    ad.slides = [];
                                    ad.ad_type = { id: 3, name: 'Merge ad', value: "merge_ad", type: file.image ? "image" : "video" };
                                    ad.headlines = Array.isArray(file.headlines) ? file.headlines : [];
                                    ad.bodies = Array.isArray(file.bodies) ? file.bodies : [];
                                    ad.descriptions = Array.isArray(file.descriptions) ? file.descriptions : [];
                                    ad.links = Array.isArray(file.links) ? file.links : [];
                                    ad.call_to_actions = Array.isArray(file.call_to_actions) ? file.call_to_actions : [];
                                    ad.call_to_actions = ad.call_to_actions.map((item) => {
                                        let buffer = JSON.parse(JSON.stringify(FacebookCTA.filter((inner_item) => { return inner_item.id === item.value.id })[0]));
                                        if (buffer && buffer.objective && adgroup.campaign_objective && adgroup.campaign_objective.id) {
                                            if (buffer.objective.includes(adgroup.campaign_objective.id)) {
                                                item.value = buffer;
                                            } else {
                                                item.value = { id: 0, name: "Select ..." };
                                            }
                                        }
                                        return item;
                                    });
                                    file.files.map((inner_file) => {
                                        let slide = {};
                                        slide = inner_file;
                                        slide.image = (inner_file.type === "image");
                                        slide.video = (inner_file.type === "video");
                                        if (inner_file.type === "image") {
                                            slide.picture = inner_file.url;
                                        }
                                        if (inner_file.type === "video") {
                                            //slide.video_data = inner_file;
                                        }
                                        ad.slides.push(slide);
                                    });
                                    if (file.page && file.page[adgroup.channel] && file.page[adgroup.channel].id !== 0) {
                                        ad.page = file.page[adgroup.channel];
                                    }
                                    if (file.page && adgroup.channel === "facebook" && file.page.instagram && file.page.instagram.id !== 0) {
                                        ad.instagram = file.page.instagram;
                                    }
                                } else {
                                    ad.file = file;
                                    if (file.type === "image") {
                                        ad.picture = file.url;
                                        ad.ad_type = { id: 1, name: 'Image ad', value: "image_ad" };
                                    }
                                    if (file.type === "video") {
                                        ad.video = file.url;
                                        ad.thumbnail = file.thumbnail;
                                        ad.ad_type = { id: 2, name: 'Video ad', value: "video_ad" };
                                    }
                                    if (file.cta && file.cta[adgroup.channel] && file.cta[adgroup.channel].id !== 0) {
                                        if (adgroup.channel === "facebook") {
                                            let buffer = JSON.parse(JSON.stringify(FacebookCTA.filter((item) => { return item.id === file.cta[adgroup.channel].id })[0]));
                                            if (buffer && buffer.objective) {
                                                if (adgroup.campaign_objective && buffer.objective.includes(adgroup.campaign_objective.id)) {
                                                    ad.cta = file.cta[adgroup.channel];
                                                } else {
                                                    ad.cta = { id: 0, name: "Select ..." };
                                                }
                                            }

                                        } else {
                                            ad.cta = file.cta[adgroup.channel];
                                        }
                                    }
                                    if (file.page && file.page[adgroup.channel] && file.page[adgroup.channel].id !== 0) {
                                        ad.page = file.page[adgroup.channel];
                                    }
                                    if (file.page && adgroup.channel === "facebook" && file.page.instagram && file.page.instagram.id !== 0) {
                                        ad.instagram = file.page.instagram;
                                    }
                                }


                                //COLLECTION ADS
                                if (file.ad_type && file.ad_type === 'collection_ad') {
                                    ad.instantExperience = file.instantExperience;

                                    /*ad.button_label = file.button_label;
                                    ad.header_title = file.header_title;
                                    ad.collection_catalog = file.collection_catalog;
                                    ad.product_set = file.product_set;
                                    ad.preview_products = file.preview_products;*/
                                    ad.collection_ad = true;
                                    ad.ad_type = { id: 2, name: 'Catalog ad', value: "catalog_ad" };
                                }
                                ad.selected = false;
                                ad.OpenAiResult = file.OpenAiResult ? file.OpenAiResult : [];
                                console.log("AD", ad);
                                //SET PREVIEW OPTIONS AND DEFAULT
                                ad = this.functions.previewOptions(ad);

                                if (adgroup.resourceName) {
                                    if (ad.subtype && ["call", "callout", "sitelink"].includes(ad.subtype.value)) {
                                        let buffer = [];
                                        ad.items.map((item, index) => { 
                                            let temp = JSON.parse(JSON.stringify(ad));
                                            temp.id = Math.floor((Math.random() * 99999999999999) + 1);
                                            temp.items = [item];
                                            temp.only_item = true;
                                            buffer.push(temp);
                                        });
                                        ads = ads.concat(buffer);
                                    } else {
                                        ads.push(ad);
                                    }
                                } else {
                                    ads.push(ad);
                                }
                            })


                            if (!Array.isArray(this.state.ads)) {
                                this.state.ads = [];
                            }
                            this.state.ads = this.state.ads.concat(ads);
                            this.setState({
                                creatives: this.state.creatives.map((item) => { item.selected = false; return item }),
                                ads: this.state.ads,
                                temp_creatives: []
                            });
                            this.form.set();
                            //this.state.ads = this.state.ads.concat(ads);


                            //this.setState({
                            //ads: this.state.ads
                            //});
                            //this.props.onPush(ads);

                        }
                    });
                }
            }
        },
        previewOptions: (ad) => {
            if (ad.channel === 'facebook') {
                if (ad && ad.ad_type && (ad.ad_type.value === 'video_ad' || (ad.ad_type.type === 'video' && ad.ad_type.value === 'posts'))) {
                    if (ad.file && ad.file.ratio === '9:16') {
                        ad.preview = { value: 'video_story', name: "Story" };
                    } else {
                        ad.preview = { value: 'video_feed', name: "Feed" };
                    }
                    ad.previews = [{ value: 'video_feed', name: "Feed" }, { value: 'video_story', name: "Story" }];
                } else if (ad && ad.ad_type && (ad.ad_type.value === 'image_ad' || (ad.ad_type.type === 'image' && ad.ad_type.value === 'posts'))) {
                    if (ad.file && ad.file.ratio === '9:16') {
                        ad.preview = { value: 'image_story', name: "Story" };
                    } else {
                        ad.preview = { value: 'image_feed', name: "Feed" };
                    }
                    ad.previews = [{ value: 'image_feed', name: "Feed" }, { value: 'image_story', name: "Story" }];
                } else if (ad && ad.ad_type && (ad.ad_type.value === 'carousel_ad' || ad.ad_type.value === 'catalog_ad')) {
                    ad.preview = { value: 'carousel_feed', name: "Feed" };
                    ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'merge_ad') {
                    if (ad.ad_type.type === 'video') {
                        ad.preview = { value: 'video_feed', name: "Feed (square)", ratio: "1:1" };
                        ad.previews = [{ value: 'video_feed', name: "Feed (square)", ratio: "1:1" }, { value: 'video_feed', name: "Feed (horizontal)", ratio: "1.91:1" }, { value: 'video_story', name: "Story", ratio: "9:16" }];
                    } else {
                        ad.preview = { value: 'image_feed', name: "Feed (square)", ratio: "1:1" };
                        ad.previews = [{ value: 'image_feed', name: "Feed (square)", ratio: "1:1" }, { value: 'image_feed', name: "Feed (horizontal)", ratio: "1.91:1" }, { value: 'image_story', name: "Story", ratio: "9:16" }];
                    }
                } else if (ad && !ad.file && (ad.ad_type === "image" || ad.ad_type === "video") && ad.type === "posts") {
                    if (ad.ad_type === "image" && !ad.files) {
                        ad.preview = { value: 'image_feed', name: "Feed" }
                        ad.previews = [{ value: 'image_feed', name: "Feed" }, { value: 'image_story', name: "Story" }];
                    } else if (ad.ad_type === "video" && !ad.files) {
                        ad.preview = { value: 'video_feed', name: "Feed" }
                        ad.previews = [{ value: 'video_feed', name: "Feed" }, { value: 'video_story', name: "Story" }];
                    } else if (ad && !ad.file && ad.files && ad.files.length > 0 && ad.type === "posts") {
                        ad.preview = { value: 'carousel_feed', name: "Feed" };
                        ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                    }
                }
            } else if (ad.channel === 'linkedin') {
                if (ad && ad.ad_type && (ad.ad_type.value === 'video_ad' || (ad.ad_type.type === 'video' && ad.ad_type.value === 'posts'))) {
                    ad.preview = { value: 'video_feed', name: "Feed" };
                    ad.previews = [{ value: 'video_feed', name: "Feed" }];
                } else if (ad && ad.ad_type && (ad.ad_type.value === 'image_ad' || (ad.ad_type.type === 'image' && ad.ad_type.value === 'posts'))) {
                    ad.preview = { value: 'image_feed', name: "Feed" };
                    ad.previews = [{ value: 'image_feed', name: "Feed" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'carousel_ad') {
                    ad.preview = { value: 'carousel_feed', name: "Feed" };
                    ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                } else if (ad && !ad.file && (ad.ad_type === "image" || ad.ad_type === "video" || ad.ad_type === "carousel") && ad.type === "posts") {
                    if (ad.ad_type === "image" && !ad.files) {
                        ad.preview = { value: 'image_feed', name: "Feed" }
                        ad.previews = [{ value: 'image_feed', name: "Feed" }];
                    } else if (ad.ad_type === "video" && !ad.files) {
                        ad.preview = { value: 'video_feed', name: "Feed" }
                        ad.previews = [{ value: 'video_feed', name: "Feed" }];
                    } else if (ad.ad_type === "carousel" && !ad.file && ad.files && ad.files.length > 0 && ad.type === "posts") {
                        ad.preview = { value: 'carousel_feed', name: "Feed" };
                        ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                    }
                }
            } else if (ad.channel === 'twitter') {
                if (ad && ad.ad_type && (ad.ad_type.value === 'video_ad' || (ad.ad_type.type === 'video' && ad.ad_type.value === 'posts'))) {
                    ad.preview = { value: 'video_feed', name: "Feed" };
                    ad.previews = [{ value: 'video_feed', name: "Feed" }];
                } else if (ad && ad.ad_type && (ad.ad_type.value === 'image_ad' || (ad.ad_type.type === 'image' && ad.ad_type.value === 'posts'))) {
                    ad.preview = { value: 'image_feed', name: "Feed" };
                    ad.previews = [{ value: 'image_feed', name: "Feed" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'carousel_ad') {
                    ad.preview = { value: 'carousel_feed', name: "Feed" };
                    ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                } else if (ad && !ad.file && (ad.ad_type === "image" || ad.ad_type === "video") && ad.type === "posts") {
                    if (ad.ad_type === "image" && !ad.files) {
                        ad.preview = { value: 'image_feed', name: "Feed" }
                        ad.previews = [{ value: 'image_feed', name: "Feed" }, { value: 'image_story', name: "Story" }];
                    } else if (ad.ad_type === "video" && !ad.files) {
                        ad.preview = { value: 'video_feed', name: "Feed" }
                        ad.previews = [{ value: 'video_feed', name: "Feed" }, { value: 'video_story', name: "Story" }];
                    } else if (ad && !ad.file && ad.files && ad.files.length > 0 && ad.type === "posts") {
                        ad.preview = { value: 'carousel_feed', name: "Feed" };
                        ad.previews = [{ value: 'carousel_feed', name: "Feed" }];
                    }
                }
            } else if (ad.channel === 'tiktok') {
                ad.preview = { value: 'video_feed', name: "Feed" };
                ad.previews = [{ value: 'video_feed', name: "Feed" }];
            } else if (ad.channel === 'snapchat') {
                if (ad && ad.ad_type && (ad.ad_type.value === 'video_ad')) {
                    ad.preview = { value: 'video_feed', name: "Stories" };
                    ad.previews = [{ value: 'video_feed', name: "Stories" }];
                } else if (ad && ad.ad_type && (ad.ad_type.value === 'image_ad')) {
                    ad.preview = { value: 'image_feed', name: "Stories" };
                    ad.previews = [{ value: 'image_feed', name: "Stories" }];
                }
            } else if (ad.channel === 'google') {
                if (ad && ad.ad_type && ad.ad_type.value === 'youtube') {
                    ad.preview = { value: 'youtube_desktop', name: "Video - Desktop" };
                    ad.previews = [{ value: 'youtube_desktop', name: "Video - Desktop" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'search_ad') {
                    ad.preview = { value: 'google_search_desktop', name: "Search result - Desktop" };
                    ad.previews = [{ value: 'google_search_desktop', name: "Search result - Desktop" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'performance_max') {
                    ad.preview = { name: "Youtube", value: "performance_max_youtube" };
                    ad.previews = [{ name: "Youtube", value: "performance_max_youtube" }, { name: "Display", value: "performance_max_display" }, { name: "Gmail", value: "performance_max_gmail" }, { name: "Discover", value: "performance_max_discover" }];
                } else if (ad && ad.ad_type && ad.ad_type.value === 'shopping') {
                    ad.preview = { name: "Shopping - Desktop", value: "google_shopping_desktop" };
                    ad.previews = [{ name: "Shopping - Desktop", value: "google_shopping_desktop" }];
                }
            }

            ad.new_preview = true;
            return ad;
        },
    };

    calls = {
        loadImageUrl: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/scrapeUrl?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateCreative?id=" + this.state.order.id;
            return apiRegister.call(options, url);
        },
        creative: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/createCreative?wizard=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        image: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadImage?client=" + this.state.client.id + "&wizard=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        video: (data, channel_id) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data, true);
            let url = apiRegister.url.api + "/v3/adcredo/uploadVideo?client=" + this.state.client.id + "&wizard=" + this.state.wizard.id + "&large=true" + "&channel=" + channel_id;
            return apiRegister.call(options, url);
        },
        updateWizard: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateWizard?id=" + this.state.wizard.id;
            return apiRegister.call(options, url);
        },
        getInstant: (id, page) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/getInstant?client=" + this.state.client.id + "&id=" + id + "&page=" + page;
            return apiRegister.call(options, url);
        },
        facebookPages: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listPages?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        createYoutubeAsset: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google/createYoutubeAsset?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getYoutubeData: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/google/getYoutubeVideoData?id=" + id;
            return apiRegister.call(options, url);
        },
        instagramAccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listBusinessInstagramAccounts?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        facebookCatalogs: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listCatalogs?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        facebookProductSets: (catalog) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listProductSets?client=" + this.state.client.id + "&id=" + catalog;
            return apiRegister.call(options, url);
        },
        facebookProducts: (product_set) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/facebook/listProducts?client=" + this.state.client.id + "&id=" + product_set;
            return apiRegister.call(options, url);
        },
        posts: (channel) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/" + channel.value + "/listPosts?client=" + this.state.client.id;
            if (channel.value === "facebook") {
                url = url + "&page=" + channel.page.id;
            } else if (channel.value === "instagram") {
                url = url + "&instagram_account=" + channel.instagram.id;
            }
            if (channel.value === "twitter") {
                if (channel.type) {
                    url = url + "&type=" + channel.type.value;
                }
            }
            if (this.state.next) {
                url = url + "&next=" + this.state.next;
            }

            return apiRegister.call(options, url);
        },
        createTikTokSparkPost: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/createTikTokSparkPost?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        prettySize: (bytes) => {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        },
        nextButtonLabel: () => {
            let channel = "";
            if (this.state.channels.facebook) {
                channel = "facebook";
            } else if (this.state.channels.linkedin) {
                channel = "linkedin";
            } else if (this.state.channels.google) {
                channel = "google";
            } else if (this.state.channels.twitter) {
                channel = "twitter";
            } else if (this.state.channels.tiktok) {
                channel = "tiktok";
            } else if (this.state.channels.snapchat) {
                channel = "snapchat";
            }
            return channel;
        }
    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    promisedSetState = (newState, dontSave, sektion) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <div className="relative h-full">

                <div className="sticky top-0 z-50">
                    <TopNavigationAgent
                        onButton={(type) => {
                            if (type === "cancel") {
                                draftRegister.remove();
                                if (this.state.wizard.template) {
                                    this.props.history.push("/v2/templates");
                                } else {
                                    this.props.history.push("/v2/orders/" + this.state.order.id + "/drafts");
                                }
                            } else if (type === "save") {
                                this.functions.updateWizard();
                            } else if (type === "push") {
                                this.setState({
                                    open_push: true
                                })
                            }
                        }}
                        hideUserDropdown={true}
                        history={this.props.history}
                        breadcrumb={"Assets"}
                        buttons={[
                            { text: "Cancel", icon: XIcon, value: "cancel", disabled: this.state.loading_wizard || this.state.loading_template, loading: false, show: !this.state.loading, red: true },
                            { text: this.state.wizard.template ? "Save template" : "Save draft", icon: SaveIcon, value: "save", disabled: this.state.loading_template, loading: this.state.loading_wizard, show: !this.state.loading },
                            { text: "Push assets", icon: ViewGridAddIcon, value: "push", loading: this.state.loading_template, show: !this.state.loading }
                        ]}
                        buttonTypes={{}}
                        buttonLoaders={{}}
                        showClient={true}
                        lockClient={true}
                        client={this.state.client}
                    />
                </div>

                {/* SUCCESS NOTIFICATION*/}
                <SideNotification
                    ref={"SideNotification"}
                    icon={CheckIcon}
                    title={"Success"}
                    text={"Wizard updated"}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading creatives ...</div>
                    </div>
                }

                {/*NO ADS WARNING*/}
                <CreativesModal
                    open={this.state.ads_warning}
                    title={"Ads are missing"}
                    text={"You must have at least one Ad before moving forward"}
                    button={"Push assets"}
                    onClose={async () => {
                        this.promisedSetState({
                            ads_warning: false
                        });
                    }}
                    onCreate={async () => {
                        await this.promisedSetState({
                            ads_warning: false,
                        });
                        this.promisedSetState({
                            open_push: true
                        });
                    }}
                />

                {/*NO ADS WARNING*/}
                <CreativesModal
                    open={this.state.ads_edit_warning}
                    title={"Ads are related"}
                    text={"Are you sure you want to update? This will update all related ads"}
                    button={"Yes"}
                    onClose={async () => {
                        this.promisedSetState({
                            ads_edit_warning: false,
                            temp_creatives: []
                        });
                    }}
                    onCreate={async () => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            this.state.temp_files.map((inner_item) => {
                                if (inner_item.id === item.id) {
                                    item = inner_item;
                                }
                            });
                            item.selected = false;
                            return item;
                        });

                        this.functions.push(true, false, false, true);
                        await this.promisedSetState({
                            saved_success: true,
                            creatives: this.state.creatives,
                            ads_edit_warning: false,
                            temp_files: false,
                            temp_creatives: []
                        });
                        this.form.set();
                    }}
                />

                {/*SELECT CHANNELS POP UP*/}
                <CreativesChannelsModal
                    open={this.state.select_channel}
                    title={"Select channels"}
                    channels={Object.keys(this.state.channels)}
                    carousel={this.state.temp_creatives.filter((item) => { return item.type === "carousel" }).length > 0 ? true : false}
                    merge={this.state.temp_creatives.filter((item) => { return item.type === "merge" }).length > 0 ? true : false}
                    collection={this.state.collection}
                    button={"Next"}
                    size_error={this.state.size_error}
                    onClose={async () => {
                        await this.promisedSetState({
                            select_channel: false,
                            temp_creatives: [],
                            collection: false
                        });

                    }}
                    onCreate={async (channels) => {
                        await this.promisedSetState({
                            selected_channels: channels,
                            select_channel: false,
                            open: true
                        });

                    }}
                />

                {/* SLIDEOUT UPDATE */}
                <SlideoutTailwindAsset
                    title={this.state.creatives.filter((item) => { return item.selected && item.type === "carousel" }).length > 0 ? "Carousel" : "Media"}
                    full={true}
                    preview_data={this.state.preview_data ? this.state.preview_data : false}
                    disabled={this.state.disabled}
                    submitButtonText={"Save"}
                    client={this.state.client}
                    open={this.state.open}
                    noPadding={true}
                    onChannel={(channel) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.swithChannel(channel);
                        } else this.refs.CreateOrderCreativeEdit.functions.swithChannel(channel);
                    }}
                    onPreviewChannel={(channel) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.previewChannel(channel);
                        } else this.refs.CreateOrderCreativeEdit.functions.previewChannel(channel);
                    }}
                    onUpdateChat={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.updateOpenAi(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.updateOpenAi(data);
                    }}
                    onAddHeadlines={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.addHeadlines(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.addHeadlines(data);
                    }}
                    onAddDescriptions={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.addDescriptions(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.addDescriptions(data);
                    }}
                    onRemoveDescription={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.removeDescription(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.removeDescription(data);
                    }}
                    onRemoveHeadline={(data) => {
                        if (this.state.collection) {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.removeHeadline(data);
                        } else this.refs.CreateOrderCreativeEdit.functions.removeHeadline(data);
                    }}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.state.assetItems = this.state.assetItems.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.promisedSetState({
                            assetItems: this.state.assetItems,
                            creatives: this.state.creatives,
                            open: false,
                            disabled: false,
                            temp_creatives: [],
                            collection: false,
                            selected_channels: [],
                            preview_data: false,
                            ai_result: false
                        });
                    }}
                    onSubmit={() => {
                        //this.refs.CreateOrderCreativeEdit.functions.push();
                        if (!this.state.collection) {
                            this.refs.CreateOrderCreativeEdit.functions.save();
                        } else {
                            this.refs.CreateOrderCreativeCollectionEdit.functions.save();
                        }
                    }}
                    onSecondarySubmit={() => {
                        //this.refs.CreateOrderCreativeEdit.functions.pushCarousel();
                    }}
                >
                    {
                        this.state.open &&
                        !this.state.collection &&
                        <CreateOrderCreativeEdit
                            ref="CreateOrderCreativeEdit"
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns ? this.state.campaigns.map((item) => { item.selected = false; return item }) : []))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups ? this.state.adgroups.map((item) => { item.selected = false; return item }) : []))}
                            ads={JSON.parse(JSON.stringify(this.state.ads ? this.state.ads : []))}
                            files={this.state.temp_creatives.length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives)) : JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected })))}
                            carousel={this.state.temp_creatives.filter((item) => { return item.type === "carousel" }).length > 0 ? this.state.temp_creatives[0] : this.state.creatives.filter((item) => { return item.selected && item.type === "carousel" }).length > 0 ? this.state.creatives.filter((item) => { return item.selected && item.type === "carousel" })[0] : null}
                            merge={this.state.temp_creatives.filter((item) => { return item.type === "merge" }).length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives[0])) : this.state.creatives.filter((item) => { return item.selected && item.type === "merge" }).length > 0 ? JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "merge" })[0])) : null}
                            catalog={this.state.creatives.filter((item) => { return item.selected && item.type === "catalog" }).length > 0 ? this.state.creatives.filter((item) => { return item.selected && item.type === "catalog" })[0] : null}
                            channels={this.state.channels}
                            assetItems={this.state.assetItems.filter((item) => { return item.selected }).length > 0 && this.state.creatives.filter((item) => { return item.selected }).length < 1 ? JSON.parse(JSON.stringify(this.state.assetItems.filter((item) => { return item.selected }))) : []}
                            client={this.state.client}
                            selected_channels={this.state.selected_channels}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                                console.log("prev_data page", this.state.preview_data)
                            }}
                            onUpdateChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                });
                            }}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onSave={async (files) => {
                                console.log("FILES", files);
                                if (this.state.ads.filter((item) => { return item.creative_id && files.map((inner_item) => { return inner_item.id }).includes(item.creative_id) }).length > 0) {
                                    this.promisedSetState({
                                        open: false,
                                        temp_creatives: [],
                                        ads_edit_warning: true,
                                        temp_files: files
                                    });
                                } else {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        files.map((inner_item) => {
                                            if (inner_item.id === item.id) {
                                                item = inner_item;
                                                item.ad_preview_data = this.state.ad_preview_data;
                                            }
                                        });
                                        item.selected = false;
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        saved_success: true,
                                        creatives: this.state.creatives,
                                        open: false,
                                        temp_creatives: []
                                    });
                                    this.form.set();
                                }
                            }}
                            onAddCreative={async (files) => {
                                this.state.assetItems = this.state.assetItems.map((item) => {
                                    item.selected = false;
                                    return item;
                                });
                                //this.functions.createCreative(files);
                                if (this.state.creatives.length < 1) {
                                    this.state.creatives = files
                                } else {
                                    this.state.creatives = this.state.creatives.concat(files);
                                }
                                await this.promisedSetState({
                                    selected_channels: [],
                                    assetItems: this.state.assetItems,
                                    saved_success: true,
                                    creatives: this.state.creatives,
                                    open: false,
                                    temp_creatives: []
                                });
                                this.form.set();
                            }}
                        />
                    }
                    {
                        this.state.open &&
                        this.state.collection &&
                        <CreateOrderCreativeCollectionEdit
                            ref="CreateOrderCreativeCollectionEdit"
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns ? this.state.campaigns.map((item) => { item.selected = false; return item }) : []))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups ? this.state.adgroups.map((item) => { item.selected = false; return item }) : []))}
                            ads={JSON.parse(JSON.stringify(this.state.ads ? this.state.ads : []))}
                            files={this.state.temp_creatives.length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives)) : JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected })))}
                            carousel={null}
                            merge={null}
                            catalog={this.state.creatives.filter((item) => { return item.selected && item.type === "catalog" }).length > 0 ? this.state.creatives.filter((item) => { return item.selected && item.type === "catalog" })[0] : null}
                            channels={this.state.channels}
                            assetItems={this.state.assetItems.filter((item) => { return item.selected }).length > 0 && this.state.creatives.filter((item) => { return item.selected }).length < 1 ? JSON.parse(JSON.stringify(this.state.assetItems.filter((item) => { return item.selected }))) : []}
                            client={this.state.client}
                            selected_channels={this.state.selected_channels}
                            onDisable={async (value) => {
                                await this.promisedSetState({
                                    disabled: value
                                });
                            }}
                            onUpdateChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                });
                            }}
                            updatePreview={async (data) => {
                                await this.promisedSetState({
                                    preview_data: data
                                });
                            }}
                            onSave={async (files) => {
                                this.state.creatives = this.state.creatives.map((item) => {
                                    files.map((inner_item) => {
                                        if (inner_item.id === item.id) {
                                            item = inner_item;
                                        }
                                    });
                                    item.selected = false;
                                    return item;
                                });
                                await this.promisedSetState({
                                    saved_success: true,
                                    creatives: this.state.creatives,
                                    open: false,
                                    temp_creatives: []

                                });
                                this.form.set();
                            }}
                            onAddCreative={async (files) => {
                                this.state.assetItems = this.state.assetItems.map((item) => {
                                    item.selected = false;
                                    return item;
                                });
                                //this.functions.createCreative(files);

                                if (this.state.creatives.length < 1) {
                                    this.state.creatives = files
                                } else {
                                    this.state.creatives = this.state.creatives.concat(files);
                                }
                                await this.promisedSetState({
                                    selected_channels: [],
                                    assetItems: this.state.assetItems,
                                    saved_success: true,
                                    creatives: this.state.creatives,
                                    open: false,
                                    temp_creatives: []
                                });
                                this.form.set();

                            }}
                        />
                    }
                </SlideoutTailwindAsset>

                {/* SLIDEOUT EXTENSION */}
                <SlideoutTailwind
                    title={"Extension"}
                    full={true}
                    submitButtonText={"Save"}
                    open={this.state.open_extension}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.promisedSetState({
                            creatives: this.state.creatives,
                            open_extension: false
                        });
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeExtension.functions.save();
                    }}
                    onSecondarySubmit={() => {

                    }}
                >
                    {
                        this.state.open_extension &&
                        <CreateOrderCreativeSearchExtension
                            ref="CreateOrderCreativeExtension"
                            groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "extension" })))}
                            client={this.state.client}
                            onSave={async (data) => {
                                if (data.id) {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        if (item.id === data.id) {
                                            item = data;
                                        }
                                        item.selected = false;
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        creatives: this.state.creatives,
                                        open_extension: false
                                    });
                                    this.form.set();
                                } else {
                                    this.functions.createExtension(data);
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* SLIDEOUT EXTENSION */}
                <SlideoutTailwind
                    title={"Creado"}
                    large={true}
                    noSubmit={true}
                    open={this.state.creado}
                    onCancel={() => {
                        this.promisedSetState({
                            creado: false
                        });
                    }}
                >
                    {
                        this.state.creado &&
                        <CreateOrderCreado
                            ref="CreateOrderCreado"
                            client={this.state.client}
                            onSave={async (data) => {

                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* SLIDEOUT PERFORMANCE MAX */}
                <SlideoutTailwind
                    title={"Performance max"}
                    full={true}
                    submitButtonText={"Save"}
                    open={this.state.open_performancemax}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.promisedSetState({
                            creatives: this.state.creatives,
                            open_performancemax: false
                        });
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativePerformanceMax.functions.save();
                    }}
                    onSecondarySubmit={() => {

                    }}
                >
                    {
                        this.state.open_performancemax &&
                        <CreateOrderCreativePerformanceMax
                            ref="CreateOrderCreativePerformanceMax"
                            groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "performance_max" })))}
                            client={this.state.client}
                            wizard={this.state.wizard}
                            onSave={async (data) => {
                                if (data.id) {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        if (item.id === data.id) {
                                            item = data;
                                        }
                                        item.selected = false;
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        creatives: this.state.creatives,
                                        open_performancemax: false
                                    });
                                    this.form.set();
                                } else {
                                    this.functions.createPerformanceMax(data);
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* GOOGLE DISPLAY NETWORK */}
                <SlideoutTailwind
                    title={"Display network"}
                    full={true}
                    submitButtonText={"Save"}
                    open={this.state.open_display}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.promisedSetState({
                            creatives: this.state.creatives,
                            open_display: false
                        });
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeDisplay.functions.save();
                    }}
                >
                    {
                        this.state.open_display &&
                        <CreateOrderCreativeDisplay
                            ref="CreateOrderCreativeDisplay"
                            groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "display" })))}
                            client={this.state.client}
                            wizard={this.state.wizard}
                            onSave={async (data) => {
                                console.log(data, "zart======")
                                if (data.id) {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        if (item.id === data.id) {
                                            item = data;
                                        }
                                        item.selected = false;
                                        return item;
                                    });
                                    this.state.ads = this.state.ads.map((item) => {
                                        if (item.id === data.id) {
                                            item.name = data.name || item.name;
                                            item.bodies = data.bodies || item.bodies;
                                            item.link = data.link || item.link;
                                            item.images = data.images || item.images;
                                            item.headlines = data.headlines || item.headlines;
                                            item.long_headlines = data.long_headlines || item.long_headlines;
                                            item.videos = data.videos || item.videos;
                                            item.business = data.business || item.business;
                                        }
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        creatives: this.state.creatives,
                                        ads: this.state.ads,
                                        open_display: false
                                    });
                                    this.form.set();
                                } else {
                                    await this.functions.createDisplay(data);
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* SLIDEOUT KEYWORDS */}
                <SlideoutTailwind
                    title={"Keywords"}
                    full={true}
                    submitButtonText={"Save"}
                    open={this.state.open_keywords}
                    onCancel={() => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        this.promisedSetState({
                            creatives: this.state.creatives,
                            open_keywords: false
                        });
                    }}
                    thirdButton={true}
                    thirdButtonText={"Search recommendations"}
                    thirdButtonLoader={this.state.loading_keywords}
                    onThirdButton={() => {
                        this.refs.CreateOrderCreativeKeywords.functions.listKeywordIdeas();
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeKeywords.functions.save();
                    }}
                    onSecondarySubmit={() => {

                    }}
                >
                    {
                        this.state.open_keywords &&
                        <CreateOrderCreativeKeywords
                            ref="CreateOrderCreativeKeywords"
                            groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "keywords" })))}
                            client={this.state.client}
                            onLoadingKeywords={(value) => {
                                this.setState({
                                    loading_keywords: value
                                })
                            }}
                            onSave={async (data) => {
                                if (data.id) {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        if (item.id === data.id) {
                                            item = data;
                                        }
                                        item.selected = false;
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        creatives: this.state.creatives,
                                        open_keywords: false
                                    });
                                    this.form.set();
                                } else {
                                    this.functions.createKeywordList(data);
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* SLIDEOUT SEARCH ADS */}
                <SlideoutTailwind
                    title={"Search ad"}
                    full={true}
                    search_ad={true}
                    group={this.state.group}
                    keywordList={this.state.keywordLists}
                    submitButtonText={"Save"}
                    open={this.state.open_searchad}
                    client={this.state.client}
                    onAddKeyword={(person) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addKeyword(person);
                    }}
                    onAddHeadlines={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addHeadlines(data);
                    }}
                    onAddDescriptions={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.addDescriptions(data);
                    }}
                    onUpdateChat={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.updateOpenAi(data);
                    }}
                    onRemoveDescription={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.removeDescription(data);
                    }}
                    onRemoveHeadline={(data) => {
                        this.refs.CreateOrderCreativeSearchAd.functions.removeHeadline(data);
                    }}
                    open_chat={false}
                    chat={this.state.ai_result ? this.state.ai_result : []}
                    onCancel={async () => {
                        this.state.creatives = this.state.creatives.map((item) => {
                            item.selected = false;
                            return item;
                        });
                        await this.promisedSetState({
                            creatives: this.state.creatives,
                            open_searchad: false,
                            group: false,
                            keywordLists: false,
                            ai_result: false
                        });
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeSearchAd.functions.save();
                    }}
                    onSecondarySubmit={() => {

                    }}
                >
                    {
                        this.state.open_searchad &&
                        <CreateOrderCreativeSearchAd
                            ref="CreateOrderCreativeSearchAd"
                            groups={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.selected && item.type === "search_ad" })))}
                            keywordLists={JSON.parse(JSON.stringify(this.state.creatives.filter((item) => { return item.type === "keywords" })))}
                            client={this.state.client}
                            onUpdatePreview={async (data) => {
                                await this.promisedSetState({
                                    group: data
                                });
                            }}
                            onUpdateChat={async (data) => {
                                await this.promisedSetState({
                                    ai_result: data
                                });
                            }}
                            updateKeywordList={async (data) => {
                                await this.promisedSetState({
                                    keywordLists: data
                                });
                            }}
                            onSave={async (data) => {
                                if (data.id) {
                                    this.state.creatives = this.state.creatives.map((item) => {
                                        if (item.id === data.id) {
                                            item = data;
                                        }
                                        item.selected = false;
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        creatives: this.state.creatives,
                                        open_searchad: false
                                    });
                                    this.form.set();
                                } else {
                                    this.functions.createSearchAd(data);
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/*NO ADS WARNING*/}
                <CreativesModal
                    open={this.state.google_warning}
                    title={this.state.alert_massege.title}
                    text={this.state.alert_massege.message}
                    button={"Push assets"}
                    indexZ={999}
                    onClose={async () => {
                        this.promisedSetState({
                            google_warning: false
                        });
                    }}
                    onCreate={async () => {
                        await this.promisedSetState({
                            google_warning: false,
                        });
                        this.refs.CreateOrderCreativePush.functions.push(true);
                    }}
                />

                {/* SLIDEOUT PUSH */}
                <SlideoutTailwind
                    title={"Push assets"}
                    ref="SlideOutPushCreatives"
                    large={true}
                    submitButtonText={this.state.select_all ? "Push and go Next" : "Push"}
                    open={this.state.open_push}
                    onCancel={() => {
                        this.promisedSetState({
                            open_push: false,
                            select_all: false,
                            temp_creatives: []
                        });
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativePush.functions.push();
                    }}
                >
                    {
                        this.state.open_push &&
                        <CreateOrderCreativePush
                            ref="CreateOrderCreativePush"
                            campaigns={JSON.parse(JSON.stringify(this.state.campaigns ? this.state.campaigns : []))}
                            adgroups={JSON.parse(JSON.stringify(this.state.adgroups ? this.state.adgroups : []))}
                            ads={JSON.parse(JSON.stringify(this.state.ads ? this.state.ads : []))}
                            files={this.state.temp_creatives.length > 0 ? JSON.parse(JSON.stringify(this.state.temp_creatives)) : JSON.parse(JSON.stringify(this.state.creatives)).filter((item) => { return !item.error }).map((item) => { item.selected = this.state.select_all ? true : item.selected; return item })}
                            onUpdateAdgroup={async (adgroup) => {
                                this.state.adgroups = this.state.adgroups.map((item) => {
                                    if (item.id === adgroup.id) {
                                        item = adgroup;
                                    }
                                    return item;
                                });
                                await this.promisedSetState({
                                    adgroups: this.state.adgroups
                                });
                                await this.form.set();
                            }}
                            onAlert={async (option) => {
                                await this.promisedSetState({
                                    google_warning: true,
                                    alert_massege: this.state.alert_options.filter((item) => { return item.id === option })[0]
                                });
                            }}
                            onPush={async (skip, adgroups, files, campaigns) => {
                                this.functions.push(skip, adgroups, files, false, campaigns);
                                /*
                                if (!Array.isArray(this.state.ads)) {
                                    this.state.ads = [];
                                }
                                this.state.ads = this.state.ads.concat(ads);
                                await this.promisedSetState({
                                    creatives: this.state.creatives.map((item) => { item.selected = false; return item }),
                                    ads: this.state.ads
                                });
                                await this.form.set();
                                if (this.state.select_all) {
                                    if (this.state.channels.facebook) {
                                        this.props.history.push("/v2/campaigns/new/facebook");
                                    } else if (this.state.channels.linkedin) {
                                        this.props.history.push("/v2/campaigns/new/linkedin");
                                    } else if (this.state.channels.google) {
                                        this.props.history.push("/v2/campaigns/new/google");
                                    } else if (this.state.channels.twitter) {
                                        this.props.history.push("/v2/campaigns/new/twitter");
                                    } else if (this.state.channels.tiktok) {
                                        this.props.history.push("/v2/campaigns/new/tiktok");
                                    } else if (this.state.channels.snapchat) {
                                        this.props.history.push("/v2/campaigns/new/snapchat");
                                    }
                                } else {
                                    await this.promisedSetState({
                                        select_all: false
                                    });
                                    try {
                                        this.refs.SlideOutPushCreatives.functions.loader(true);
                                        await new Promise(r => setTimeout(r, 700));
                                        this.refs.SlideOutPushCreatives.functions.loader(false);
                                        this.refs.SlideOutPushCreatives.functions.success("Assets pushed");
                                        this.refs.CreateOrderCreativePush.functions.select(false);
                                        await new Promise(r => setTimeout(r, 1500));
                                        this.refs.SlideOutPushCreatives.functions.success(false);
                                    } catch (error) {

                                    }
                                }*/
                            }}
                            onRemove={async (id, campaign = false) => {
                                if (campaign) {
                                    this.state.campaigns = this.state.campaigns.map((item) => {
                                        if (item.id === campaign.id) {
                                            item.asset = item.asset.filter((asset) => asset.id !== id);
                                        }
                                        return item;
                                    });
                                    await this.promisedSetState({
                                        campaigns: this.state.campaigns
                                    });
                                    this.form.set();
                                } else {
                                    if (Array.isArray(this.state.ads)) {
                                        this.state.ads = this.state.ads.filter((item) => {
                                            return item.id !== id;
                                        });
                                        await this.promisedSetState({
                                            ads: this.state.ads
                                        });
                                        this.form.set();
                                    }
                                }
                            }}
                        />
                    }
                </SlideoutTailwind>

                {/* CROPP CREATIVE */}
                <SlideoutTailwind
                    title={"Crop creatives"}
                    large={true}
                    submitButtonText={"Save"}
                    loader={this.state.cropper_loader}
                    open={this.state.open_cropper}
                    onCancel={() => {
                        this.setState({
                            open_cropper: false
                        })
                    }}
                    onSubmit={() => {
                        this.refs.CreateOrderCreativeCropper.functions.crop();
                    }}
                >
                    {
                        this.state.open_cropper &&
                        <CreateOrderCreativeCropper
                            ref="CreateOrderCreativeCropper"
                            wizard={this.state.wizard.id}
                            files={JSON.parse(JSON.stringify(this.state.assetItems.filter((item) => { return item.selected })))}
                            onNew={async (media) => {
                                if (media.type === "image") {
                                    media.allowed_channels = { facebook: true, linkedin: true, twitter: true, snapchat: true };
                                    media.allowed_types = { regular: true };
                                    media.allowed_multiple = true;
                                    media.cropped = true;
                                } else if (media.type === "video") {
                                    media.allowed_channels = { facebook: true, linkedin: true, twitter: true, tiktok: true, snapchat: true };
                                    media.allowed_types = { regular: true };
                                    media.allowed_multiple = true;
                                    media.cropped = true;
                                }
                                this.state.assetItems.push(media);
                                await this.promisedSetState({
                                    assetItems: this.state.assetItems,
                                    open_cropper: false
                                });
                                await this.form.set();
                            }}
                            onLoader={(value) => {
                                this.setState({
                                    cropper_loader: value
                                })
                            }}
                        />
                    }
                </SlideoutTailwind>

                {
                    !this.state.loading &&
                    <div className="p-4">

                        {/* TABS */}
                        <div className="flex flex-1 flex-row relative">
                            {
                                this.state.tabs.map((tab, index) => {
                                    return (
                                        <Fragment>
                                            {
                                                index !== 0 &&
                                                <div className="w-2"></div>
                                            }
                                            <div onClick={async () => {
                                                await this.promisedSetState({
                                                    tab: tab,
                                                    subtab: Array.isArray(tab.subtabs) ? tab.subtabs[0] : {},
                                                    creatives: this.state.creatives.map((item) => { item.selected = false; return item }),
                                                    posts: [],
                                                    next: false
                                                });
                                                if (this.state.tab.value === "catalogs") {
                                                    if (this.state.subtab.value === "facebook") {
                                                        this.functions.getFacebookCatalogs();
                                                    }
                                                }
                                                if (this.state.tab.value === "posts") {
                                                    await this.promisedSetState({
                                                        next: false
                                                    });
                                                    if (this.state.subtab.value === "linkedin" || this.state.subtab.value === "tiktok") {
                                                        //this.functions.getPosts(this.state.subtab);
                                                    } else if (this.state.subtab.value === "facebook" || this.state.subtab.value === "instagram") {
                                                        this.functions.listFacebookPages();
                                                    }
                                                }
                                            }}
                                                style={tab.id === this.state.tab.id ? { borderBottomColor: "rgb(249, 250, 251)" } : {}}
                                                className={(tab.id === this.state.tab.id ? "bg-white shadow" : "text-gray-700 bg-gray-50 shadow") + " py-3 flex flex-1 items-center flex-row truncate px-5 text-sm font-medium rounded-t-lg cursor-pointer"}>
                                                <tab.icon className={(tab.id === this.state.tab.id ? "text-purple-500" : "text-gray-300") + " h-6 w-6 -ml-1 mr-2 "} />
                                                <div className="flex flex-1 truncate">
                                                    <div style={{ paddingTop: "1px" }} className="truncate mr-3 ">
                                                        {tab.name}
                                                    </div>
                                                </div>
                                                {
                                                    this.state.creatives.filter((item) => {
                                                        const validTypes = {
                                                            uploaded: ["image", "video", "merge", "carousel"],
                                                            catalogs: ["catalog"],
                                                            posts: ["posts"],
                                                            search: ["keywords", "search_ad", "youtube", "extension", "shopping", "performance_max", "display"],
                                                        };
                                                        return tab.value in validTypes && validTypes[tab.value].includes(item.type);
                                                    }).length > 0 &&
                                                    <div className="ml-3 text-white bg-purple-500 text-xxs rounded-full py-0.5 px-2">
                                                        {this.state.creatives.filter((item) => {
                                                            const validTypes = {
                                                                uploaded: ["image", "video", "merge", "carousel"],
                                                                catalogs: ["catalog"],
                                                                posts: ["posts"],
                                                                search: ["keywords", "search_ad", "youtube", "extension", "shopping", "performance_max", "display"],
                                                            };
                                                            return tab.value in validTypes && validTypes[tab.value].includes(item.type);
                                                        }).length}
                                                    </div>
                                                }
                                            </div>
                                            {
                                                this.state.tabs.length === 1 &&
                                                <div className="flex flex-1"></div>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className="bg-white flex flex-1 rounded-b-lg shadow flex-col relative">
                            {
                                this.state.tab && Array.isArray(this.state.tab.subtabs) &&
                                <div className="block overflow-scroll w-ful p-6">
                                    <nav className="flex space-x-4" aria-label="Tabs">
                                        {this.state.tab.subtabs.map((subtab) => (
                                            <div
                                                onClick={async () => {
                                                    await this.promisedSetState({
                                                        subtab: subtab,
                                                        posts: [],
                                                        creatives: this.state.creatives.map((item) => { item.selected = false; return item }),
                                                        next: false
                                                    });
                                                    if (this.state.tab.value === "catalogs") {
                                                        if (this.state.subtab.value === "facebook") {
                                                            this.functions.getFacebookCatalogs();
                                                        }
                                                    }
                                                    if (this.state.tab.value === "posts") {
                                                        if (this.state.subtab.value === "linkedin" || this.state.subtab.value === "tiktok") {
                                                            //this.functions.getPosts(this.state.subtab);
                                                        } else if (this.state.subtab.value === "facebook") {
                                                            this.functions.listFacebookPages();
                                                        } else if (this.state.subtab.value === "instagram") {
                                                            this.functions.listInstagramAccounts();
                                                        }
                                                    }
                                                }}
                                                key={subtab.name}
                                                style={{ whiteSpace: "nowrap" }}
                                                className={classNames(
                                                    subtab.value == this.state.subtab.value ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                    'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer inline-flex flex-row items-center'
                                                )}
                                                aria-current={subtab.value == this.state.subtab.value ? 'page' : undefined}
                                            >
                                                {subtab.name}
                                                {
                                                    this.state.creatives.filter((item) => {
                                                        if (this.state.tab.value === "uploaded" && subtab.value === "creatives") {
                                                            return (item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel")
                                                        } else if (this.state.tab.value === "catalogs" && subtab.value === "facebook") {
                                                            return (item.type === "catalog")
                                                        } else if (this.state.tab.value === "posts" && (subtab.value === "instagram" || subtab.value === "facebook" || subtab.value === "twitter" || subtab.value === "linkedin" || subtab.value === "tiktok" || subtab.value === "snapchat")) {
                                                            return (item.type === "posts" && (item.channel !== "facebook" ? (item.channel === subtab.value) : (subtab.value === "instagram" || subtab.value === "facebook")))
                                                        } else if (this.state.tab.value === "search" && subtab.value === "keywords") {
                                                            return (item.type === "keywords")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "ads") {
                                                            return (item.type === "search_ad")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "youtube") {
                                                            return (item.type === "youtube")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "extension") {
                                                            return (item.type === "extension")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "shopping") {
                                                            return (item.type === "shopping")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "performance_max") {
                                                            return (item.type === "performance_max")
                                                        } else if (this.state.tab.value === "search" && subtab.value === "display") {
                                                            return (item.type === "display")
                                                        } else {
                                                            return false
                                                        }
                                                    }).length > 0 &&
                                                    <div className={(subtab.value == this.state.subtab.value ? "text-white bg-purple-500" : "text-white bg-gray-500") + " ml-3 text-xxs rounded-full py-0.5 px-2"}>
                                                        {this.state.creatives.filter((item) => {
                                                            if (this.state.tab.value === "uploaded" && subtab.value === "creatives") {
                                                                return (item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel")
                                                            } else if (this.state.tab.value === "catalogs" && subtab.value === "facebook") {
                                                                return (item.type === "catalog")
                                                            } else if (this.state.tab.value === "posts" && (subtab.value === "instagram" || subtab.value === "facebook" || subtab.value === "twitter" || subtab.value === "linkedin" || subtab.value === "tiktok" || subtab.value === "snapchat")) {
                                                                return (item.type === "posts" && (item.channel !== "facebook" ? (item.channel === subtab.value) : (subtab.value === "instagram" || subtab.value === "facebook")))
                                                            } else if (this.state.tab.value === "search" && subtab.value === "keywords") {
                                                                return (item.type === "keywords")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "ads") {
                                                                return (item.type === "search_ad")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "youtube") {
                                                                return (item.type === "youtube")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "extension") {
                                                                return (item.type === "extension")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "shopping") {
                                                                return (item.type === "shopping")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "performance_max") {
                                                                return (item.type === "performance_max")
                                                            } else if (this.state.tab.value === "search" && subtab.value === "display") {
                                                                return (item.type === "display")
                                                            } else {
                                                                return false
                                                            }
                                                        }).length}
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </nav>
                                </div>
                            }
                            {
                                this.state.tab && Array.isArray(this.state.tab.subtabs) &&
                                <div className="w-full border-b"></div>
                            }
                            <div className="p-6">
                                {
                                    this.state.tab.value === "search" && this.state.subtab && this.state.subtab.value === "youtube" &&
                                    <div className="col-span-12">
                                        <div className={"w-full"}>
                                            <InputTailwind
                                                label={"Youtube URL"}
                                                value={this.state.youtube_url}
                                                onChange={(value) => {
                                                    this.setState({
                                                        youtube_url: value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.size_error && this.state.assetItems[0].type === "video" &&
                                    <div className='col-span-6 pb-6'>
                                        <div className="bg-orange-100 rounded-md col-span-3 p-4 text-sm font-medium text-orange-500">
                                            OBS! Video size can't exceed 1GB for Snapchat, current size: <b>{this.state.assetItems[0] && (this.state.assetItems[0].size / 1000000000).toFixed(3)}GB</b>.
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.size_error && this.state.assetItems[0].type === "image" &&
                                    <div className='col-span-6 pb-6'>
                                        <div className="bg-orange-100 rounded-md col-span-3 p-4 text-sm font-medium text-orange-500">
                                            OBS! Image size can't exceed 5MB for Snapchat, current size: <b>{this.state.assetItems[0] && (this.state.assetItems[0].size / 1000000).toFixed(2)}MB</b>.
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives" &&
                                    <div className="grid grid-cols-8 gap-4">
                                        <div className={"col-span-8"}>
                                            <div key={this.state.theInputKeyOne || ''} className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative bg-custom-input h-full">
                                                <input
                                                    onChange={(e) => this.functions.onSelectFile(e)}
                                                    type="file"
                                                    multiple
                                                    accept={"image/png, image/jpg, image/jpeg, image/gif, video/*"}
                                                    className="absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 z-10"
                                                />
                                                <div className="space-y-1 text-center justify-center flex flex-col">
                                                    <svg
                                                        className="mx-auto h-6 w-6 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    <div className="flex text-sm text-gray-600">
                                                        <span className="relative cursor-pointer whitespace-no-wrap bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">Upload a file</span>
                                                        <p className="pl-1">or drag and drop</p>
                                                    </div>
                                                    <p className="text-xs text-gray-500">PNG, JPG, GIF, MP4, MOV up to 100MB</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={"col-span-8"}>
                                            <div className='flex w-full mb-4'>
                                                <div className='flex flex-1 items-center'>
                                                    <p className="text-xs font-bold">Uploaded content</p>
                                                </div>
                                                <div id="asset_seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                                    <input
                                                        onBlur={() => {
                                                            document.getElementById('asset_seach').classList.remove('w-120');
                                                            document.getElementById('asset_seach').classList.add('w-24');
                                                        }}
                                                        onFocus={() => {
                                                            document.getElementById('asset_seach').classList.remove('w-24');
                                                            document.getElementById('asset_seach').classList.add('w-120');
                                                        }}

                                                        className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                                        placeholder={"Search"}
                                                        value={this.state.asset_seach_value}
                                                        onChange={async (event) => {
                                                            await this.promisedSetState({
                                                                asset_seach_value: event.target.value
                                                            })

                                                        }}
                                                    />
                                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                        <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                                    </div>
                                                </div>
                                                <div className='flex'>
                                                    <div onClick={async () => {
                                                        await this.promisedSetState({
                                                            asset_view: "grid"
                                                        })
                                                    }} className={(this.state.asset_view !== "grid" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5  mr-2 px-2 "}>
                                                        <ViewGridIcon className=" h-5 w-5 " />
                                                    </div>
                                                    <div onClick={async () => {
                                                        await this.promisedSetState({
                                                            asset_view: "list"
                                                        })
                                                    }} className={(this.state.asset_view !== "list" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5 px-2 "}>
                                                        <MenuIcon className=" h-5 w-5 " />
                                                    </div>
                                                </div>
                                                {
                                                    this.state.order && this.state.order.id && (this.state.order.id === "6671777053ca456726a650b1" || this.state.order.id === "6672d20ef4cdf9000758d12c") &&
                                                    <div className='flex ml-2'>
                                                        <div onClick={async () => {
                                                            await this.promisedSetState({
                                                                creado: true
                                                            })
                                                        }} className={"bg-purple-500 text-white relative cursor-pointer w-full flex justify-center items-center rounded-md  mr-2 px-2 "}>
                                                            <SparklesIcon className=" h-5 w-5 mr-2" />
                                                            <span>Creado AI</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='w-full'>
                                                <div className={"max-h-60 max overflow-hidden overflow-y-auto relative" + (this.state.asset_view === "list" ? " w-full" : " grid grid-cols-6 gap-4 ")}>
                                                    {
                                                        this.state.asset_view === "list" &&
                                                        <div className='flex flex-row w-full items-center sticky top-0 z-55 bg-white'>
                                                            <div className='mr-8'>
                                                                <div className='h-10 w-20'>
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-1'>
                                                                <div className='grid grid-cols-8 w-full text-sm font-semibold'>
                                                                    <div className='col-span-5'>
                                                                        Name
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Type
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Ratio
                                                                    </div>
                                                                    <div className='col-span-1'>
                                                                        Cropped
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.assetItems.length > 0 &&
                                                        this.state.assetItems.filter((item) => {
                                                            if (this.state.asset_seach_value !== "") {
                                                                if (item.name.toLowerCase().includes(this.state.asset_seach_value.toLowerCase())) {
                                                                    return true;
                                                                } else return false;
                                                            } else return true;
                                                        }).map((file) => {
                                                            if (this.state.asset_view === "list") {
                                                                return (
                                                                    <Fragment>
                                                                        <div className='flex flex-row w-full items-center py-2 border-t'>
                                                                            <div className='mr-8'>
                                                                                <div className='h-10 w-20 flex flex-row'>
                                                                                    <div onClick={() => {
                                                                                        if (!file.loading) {
                                                                                            if (file.selected) {
                                                                                                file.selected = false;
                                                                                            } else if (file.type === "carousel" && this.state.assetItems.filter((item) => { return item.selected }).length < 1) {
                                                                                                file.selected = true;
                                                                                            } else if (file.type === "catalog" && this.state.assetItems.filter((item) => { return item.selected }).length < 1) {
                                                                                                file.selected = true;
                                                                                            } else if (file.type !== "catalog" && file.type !== "carousel" && this.state.assetItems.filter((item) => { return item.selected && (item.type === "carousel" || item.type === "catalog") }).length < 1) {
                                                                                                file.selected = !file.selected;
                                                                                            }
                                                                                            this.promisedSetState({
                                                                                                assetItems: this.state.assetItems
                                                                                            });
                                                                                        }
                                                                                    }} key={file.source} className={(file.selected ? "border-purple-500 bg-purple-500 text-white" : "border-gray-300 bg-white hover:bg-gray-50 text-gray-700") + " cursor-pointer mr-2 flex relative w-10 h-10 justify-center items-center rounded-md border text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"}>
                                                                                        {
                                                                                            file.selected &&
                                                                                            <CheckIcon className="h-5 w-5" />
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        file.type === "image" && !file.loading &&
                                                                                        <div className="object-cover w-10 h-10 rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        file.type === "video" && !file.loading &&
                                                                                        <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-10 h-10 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                            <source src={file.url} type="video/mp4" />
                                                                                        </video>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex flex-1'>
                                                                                <div className='grid grid-cols-8 w-full text-sm items-center mt-2'>
                                                                                    <div className='col-span-5 truncate pr-1'>
                                                                                        {(file.name && file.name !== "" ? file.name : "-")}
                                                                                    </div>
                                                                                    <div className='col-span-1 truncate px-1'>
                                                                                        {file.type}
                                                                                    </div>
                                                                                    <div className='col-span-1 truncate px-1'>
                                                                                        {file.ratio}
                                                                                    </div>
                                                                                    <div className='col-span-1 px-1'>

                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className="text-sm ">
                                                                                                <div className="flex justify-items-start items-center">
                                                                                                    <ScissorsIcon className="h-4 w-4 mr-1" />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div onClick={() => {
                                                                        if (!file.loading) {
                                                                            if (file.selected) {
                                                                                file.selected = false;
                                                                            } else if (file.type === "carousel" && this.state.assetItems.filter((item) => { return item.selected }).length < 1) {
                                                                                file.selected = true;
                                                                            } else if (file.type === "catalog" && this.state.assetItems.filter((item) => { return item.selected }).length < 1) {
                                                                                file.selected = true;
                                                                            } else if (file.type !== "catalog" && file.type !== "carousel" && this.state.assetItems.filter((item) => { return item.selected && (item.type === "carousel" || item.type === "catalog") }).length < 1) {
                                                                                file.selected = !file.selected;
                                                                            }
                                                                            this.promisedSetState({
                                                                                assetItems: this.state.assetItems
                                                                            });
                                                                        }
                                                                    }} key={file.source} className={(file.error ? "border-red-500 bg-red-100 hover:border-red-500 " : "") + (file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " rounded-lg overflow-hidden border-1.5 shadow-sm relative cursor-pointer items-center col-span-2 flex h-16"}>

                                                                        <div className='flex flex-1 h-full w-full items-center'>
                                                                            <div className="flex">
                                                                                {
                                                                                    file.loading &&
                                                                                    <div className="bg-white z-50 rounded-md border-1.5 bg-opacity-75 absolute right-0 left-0 top-0 bottom-0 flex flex-row justify-center items-center">
                                                                                        <div style={{ borderTopColor: "transparent" }}
                                                                                            class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                        <div className="ml-2 text-xs">
                                                                                            Loading ...
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "image" && !file.loading &&
                                                                                    <div className="object-cover w-16 h-16 inset-0 rounded-md pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "video" && !file.loading &&
                                                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className="w-16 h-16 rounded-md inset-0 pointer-events-none group-hover:opacity-75">
                                                                                        <source src={file.url} type="video/mp4" />
                                                                                    </video>
                                                                                }

                                                                            </div>
                                                                            <div className="flex flex-1 flex-col text-sm overflow-hidden w-40 ml-2">
                                                                                <div className="relative truncate whitespace-no-wrap">{file.name}</div>
                                                                                {
                                                                                    file.type === "image" &&
                                                                                    <div className="inline-flex text-xs relative text-gray-700">
                                                                                        <PhotographIcon className="mr-0.5 h-4 w-4" />
                                                                                        <span>Image</span>
                                                                                        {
                                                                                            file.ratio &&
                                                                                            <>
                                                                                                <div className="inline-flex ml-2">
                                                                                                    <ArrowsExpandIcon className="mr-0.5 h-4 w-4" />
                                                                                                    <span className="">Ratio:({file.ratio})</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className='inline-flex ml-2 '>
                                                                                                <ScissorsIcon className="mr-0.5 h-4 w-4" />
                                                                                                <span>Cropped</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    file.type === "video" &&
                                                                                    <div className="inline-flex text-xs relative text-gray-700">
                                                                                        <FilmIcon className="mr-0.5 h-4 w-4" />
                                                                                        <span>Video</span>
                                                                                        {
                                                                                            file.ratio &&
                                                                                            <>
                                                                                                <div className="inline-flex rml-2 ">
                                                                                                    <ArrowsExpandIcon className="mr-0.5 h-4 w-4" />
                                                                                                    <span className="">Ratio:({file.ratio})</span>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            file.cropped &&
                                                                                            <div className='inline-flex ml-2 '>
                                                                                                <ScissorsIcon className="mr-0.5 h-4 w-4" />
                                                                                                <span>Cropped</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            false &&
                                                                            <div className='pr-2 flex flex-1'>
                                                                                <div className={"text-center rounded-md py-1.5 px-5 bg-gray-300 text-sm" + (file.selected ? " text-purple-300" : " hover:text-purple-500")}>
                                                                                    {file.selected ? "Selected" : "Select"}
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "catalogs" &&
                                    this.state.subtab.value === "facebook" &&
                                    <>
                                        <div className="mb-6">
                                            <DropdownTailwind
                                                channel="facebook"
                                                label={"Facebook catalogs"}
                                                searchInput={true}
                                                placeholder={"Click to search ..."}
                                                loader={this.state.loading_fb_catalogs}
                                                selected={this.state.catalog.facebook}
                                                options={this.state.catalogs.facebook}
                                                onChange={async (catalog) => {
                                                    this.state.catalog.facebook = catalog;
                                                    await this.promisedSetState({
                                                        catalog: this.state.catalog
                                                    });
                                                    this.functions.getFacebookProductSets();
                                                }}
                                            />
                                        </div>
                                        {
                                            this.state.catalog.facebook && this.state.catalog.facebook.id !== 0 &&
                                            <div className="">
                                                <DropdownTailwind
                                                    channel="facebook"
                                                    label={"Facebook product sets"}
                                                    searchInput={true}
                                                    placeholder={"Click to search ..."}
                                                    loader={this.state.loading_fb_productsets}
                                                    selected={this.state.product_set.facebook}
                                                    options={this.state.product_sets.facebook}
                                                    onChange={async (product_set) => {
                                                        this.state.product_set.facebook = product_set;
                                                        await this.promisedSetState({
                                                            product_set: this.state.product_set
                                                        });
                                                        this.functions.createCatalog("facebook");
                                                    }}
                                                />
                                            </div>
                                        }
                                    </>
                                }
                                {
                                    this.state.tab.value === "posts" &&
                                    <>
                                        {
                                            this.state.subtab.value === "twitter" &&
                                            <div className="mb-6">
                                                <DropdownTailwind
                                                    label={"Type"}
                                                    placeholder={"Select type ..."}
                                                    selected={this.state.subtab.type ? this.state.subtab.type : { id: 0, name: "Select type" }}
                                                    options={[{ id: 1, name: "Organic tweets", value: "PUBLISHED" }, { id: 2, name: "Scheduled tweets", value: "SCHEDULED" }]}
                                                    loader={this.state.loading_posts}
                                                    onChange={async (type) => {
                                                        this.state.subtab.type = type;
                                                        await this.promisedSetState({
                                                            subtab: this.state.subtab,
                                                            next: false,
                                                            posts: []
                                                        });
                                                        this.functions.getPosts(this.state.subtab);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            (this.state.subtab.value === "linkedin") &&
                                            <div className="mb-6">
                                                <DropdownTailwind
                                                    label={"Type"}
                                                    placeholder={"Select type ..."}
                                                    selected={this.state.subtab.type ? this.state.subtab.type : { id: 0, name: "Select type" }}
                                                    options={[{ id: 1, name: "Organic posts" }]}
                                                    loader={this.state.loading_posts}
                                                    onChange={async (type) => {
                                                        this.state.subtab.type = type;
                                                        await this.promisedSetState({
                                                            subtab: this.state.subtab,
                                                            next: false,
                                                            posts: []
                                                        });
                                                        this.functions.getPosts(this.state.subtab);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            (this.state.subtab.value === "tiktok") &&
                                            <div className="mb-6">
                                                <div className="mb-6">
                                                    <DropdownTailwind
                                                        label={"Type"}
                                                        placeholder={"Select type ..."}
                                                        selected={this.state.subtab.type ? this.state.subtab.type : { id: 0, name: "Select type" }}
                                                        options={[{ id: 1, name: "Organic posts" }]}
                                                        loader={this.state.loading_posts}
                                                        onChange={async (type) => {
                                                            this.state.subtab.type = type;
                                                            await this.promisedSetState({
                                                                subtab: this.state.subtab,
                                                                next: false,
                                                                posts: []
                                                            });
                                                            this.functions.getPosts(this.state.subtab);
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    !this.state.tiktok_new_post &&
                                                    <div onClick={async () => {
                                                        await this.promisedSetState({
                                                            tiktok_new_post: true
                                                        });
                                                    }} className="mt-2 text-sm font-medium text-purple-500 hover:text-purple-600 cursor-pointer">
                                                        Add new Spark ad post
                                                    </div>
                                                }
                                                {
                                                    this.state.tiktok_new_post &&
                                                    <div className="col-span-3 grid grid-cols-6 gap-4">
                                                        <div className="col-span-3">
                                                            <InputTailwind
                                                                label={"Enter TikTok post code"}
                                                                value={this.state.tiktok_new_ad_code}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        tiktok_new_ad_code: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <InputTailwind
                                                                label={"Code for the original post (Duet)"}
                                                                value={this.state.tiktok_new_ad_code_duet}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        tiktok_new_ad_code_duet: value
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <button
                                                                onClick={() => {
                                                                    if (!this.state.loading_tiktok_create) {
                                                                        this.functions.create_tiktok_post();
                                                                    }
                                                                }}
                                                                className={"inline-flex shadow relative justify-center rounded-md border border-transparent bg-purple-500 hover:bg-purple-600 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                            >
                                                                {
                                                                    this.state.loading_tiktok_create &&
                                                                    <div className="w-full h-full absolute inset-0 bg-purple-500 flex justify-center items-center z-20 rounded-lg">
                                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                    </div>
                                                                }
                                                                {
                                                                    !this.state.loading_posts &&
                                                                    <span>Add post</span>
                                                                }
                                                            </button>
                                                        </div>
                                                        {
                                                            this.state.add_post_error &&
                                                            <div className="mt-2 text-sm font-medium text-red-500">
                                                                {this.state.add_post_error}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            (this.state.subtab.value === "facebook") &&
                                            <div className="mb-6">
                                                <DropdownTailwind
                                                    searchInput={true}
                                                    label={"Facebook page"}
                                                    placeholder={"Select facebook page ..."}
                                                    selected={this.state.subtab.page ? this.state.subtab.page : { id: 0, name: "Select page" }}
                                                    options={this.state.pages.facebook ? this.state.pages.facebook : []}
                                                    loader={this.state.loading_posts}
                                                    onChange={async (page) => {
                                                        this.state.subtab.page = page;
                                                        delete this.state.subtab.instagram;
                                                        await this.promisedSetState({
                                                            subtab: this.state.subtab,
                                                            next: false,
                                                            posts: []
                                                        });
                                                        //if (this.state.subtab.value === "instagram") {
                                                        //this.functions.listInstagramAccounts(page.id);
                                                        //} else {
                                                        this.functions.getPosts(this.state.subtab);
                                                        //}
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            //this.state.subtab.page && this.state.subtab.page.d !== 0 &&
                                            this.state.subtab.value === "instagram" &&
                                            <div className="mb-6">
                                                <DropdownTailwind
                                                    searchInput={true}
                                                    label={"Instagram page"}
                                                    placeholder={"Select instagram page ..."}
                                                    selected={this.state.subtab.instagram ? this.state.subtab.instagram : { id: 0, name: "Select account" }}
                                                    options={(this.state.pages.instagram ? this.state.pages.instagram : [])}
                                                    loader={this.state.loading_posts || this.state.loading_instagram}
                                                    onChange={async (page) => {
                                                        this.state.subtab.instagram = page;
                                                        await this.promisedSetState({
                                                            subtab: this.state.subtab,
                                                            next: false,
                                                            posts: []
                                                        });
                                                        this.functions.getPosts(this.state.subtab);
                                                    }}
                                                />
                                            </div>
                                        }
                                        {
                                            this.state.posts.length > 0 &&
                                            (this.state.subtab.value === "twitter" || this.state.subtab.value === "facebook" || this.state.subtab.value === "instagram" || this.state.subtab.value === "linkedin" || this.state.subtab.value === "tiktok" || this.state.subtab.value === "snapchat") &&
                                            <div className="mb-6 flex flex-row overflow-auto bg-gray-50 rounded-lg p-6 w-full">
                                                {
                                                    this.state.loading_posts &&
                                                    <div className="h-64 flex justify-center items-center flex-col flex-1 w-full">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                        <div className="font-semibold mt-3">Loading posts ...</div>
                                                    </div>
                                                }
                                                {
                                                    this.state.posts.map((item) => {
                                                        if (item) {
                                                            return (
                                                                <div>
                                                                    <div className="border overflow-hidden rounded-md w-64 mr-10">
                                                                        <div className={`relative w-full h-40 ${item.disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
                                                                            onClick={() => {
                                                                                this.functions.createPost(item);
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.type === "video" &&
                                                                                !item.carousel &&
                                                                                <video autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                    <source src={item.url} type="video/mp4" />
                                                                                </video>
                                                                            }
                                                                            {
                                                                                item.type === "image" &&
                                                                                !item.website &&
                                                                                !item.carousel &&
                                                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (item.type === "document" || item.type === "article" || item.type === "poll" || item.no_content) && (
                                                                                    <div className="flex justify-center items-center relative w-full h-full rounded-t-md overflow-hidden">
                                                                                        <div className="absolute inset-0 bg-black opacity-25"></div>
                                                                                        {item.type === "document" && (
                                                                                            <iframe src={item.document} className='h-full object-cover'></iframe>
                                                                                        )}
                                                                                        {item.type === "article" && (
                                                                                            <NewspaperIcon className='w-1/3 text-indigo-500 stroke-1' />
                                                                                        )}
                                                                                        {item.type === "poll" && (
                                                                                            <ChartBarIcon className='w-1/3 text-indigo-500 stroke-1' />
                                                                                        )}
                                                                                        {item.no_content && (
                                                                                            <BanIcon className='w-1/3 text-indigo-500 stroke-1' />
                                                                                        )}
                                                                                        <div className="absolute inset-0 flex items-center justify-center text-white pointer-events-none">
                                                                                            Format not supported
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                item.carousel &&
                                                                                <div className="w-full h-full absolute inset-0 pointer-events-none">
                                                                                    <div className="grid grid-cols-3 gap-2 p-2">
                                                                                        {
                                                                                            item.carousel.map((item) => {
                                                                                                if (item.type === "IMAGE" || item.type === "image") {
                                                                                                    return (
                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                            <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                } else if (item.type === "VIDEO" || item.type === "video") {
                                                                                                    return (
                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                            <video autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                <source src={item.url} type="video/mp4" />
                                                                                                            </video>
                                                                                                        </div>
                                                                                                    )
                                                                                                } else {
                                                                                                    return <div></div>
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                item.type === "image" &&
                                                                                item.website &&
                                                                                <div className="w-full h-full absolute flex items-center justify-center text-xs font-medium hover:text-purple-500 cursor-pointer text-center">
                                                                                    {
                                                                                        item.loading &&
                                                                                        <div className="h-full flex justify-center items-center flex-col flex-1 w-full">
                                                                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                                                            <div className="font-semibold mt-3">Loading ...</div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        !item.loading &&
                                                                                        item.url !== "" &&
                                                                                        <div>
                                                                                            <div>
                                                                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>
                                                                                                </div>
                                                                                                <div>
                                                                                                    {item.website}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                {item.website}
                                                                                            </div>
                                                                                        </div>

                                                                                    }
                                                                                    {
                                                                                        !item.loading &&
                                                                                        item.url === "" &&
                                                                                        <div>
                                                                                            <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>
                                                                                            </div>
                                                                                            <div
                                                                                                onClick={(event) => {
                                                                                                    event.stopPropagation();
                                                                                                    window.open(item.website, '_blank').focus();
                                                                                                }}
                                                                                            >
                                                                                                {item.website}
                                                                                            </div>
                                                                                        </div>

                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className={" text-sm flex flex-row items-center font-medium text-gray-500 border-t pt-3 px-2"}>
                                                                            <div className={"bg-" + item.channel + "-500" + " h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                                                                {
                                                                                    item.channel === 'google' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/google_icon.png')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'facebook' &&
                                                                                    <img className="w-1"
                                                                                        alt=""
                                                                                        src={require('../assets/images/facebook_icon.svg')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'linkedin' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/linkedin_icon.svg')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'twitter' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/twitter_icon.svg')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'tiktok' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/tiktok_icon.png')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'snapchat' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/snapchat.svg')} />
                                                                                }
                                                                                {
                                                                                    item.channel === 'instagram' &&
                                                                                    <img className="w-2"
                                                                                        alt=""
                                                                                        src={require('../assets/images/instagram_icon.svg')} />
                                                                                }
                                                                            </div>
                                                                            <div className=" text-sm truncate font-medium text-gray-900">{item.name}</div>
                                                                        </div>
                                                                        <div className="px-2 pb-3">
                                                                            <div className=" text-sm truncate font-medium text-gray-500">{item.body}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (<div></div>)
                                                        }
                                                    })
                                                }
                                                <div className="mr-2 justify-center flex items-center">
                                                    {
                                                        this.state.next &&
                                                        <button
                                                            onClick={() => {
                                                                if (!this.state.loading_posts) {
                                                                    this.functions.getPosts(this.state.subtab)
                                                                }
                                                            }}
                                                            style={{ width: "150px" }}
                                                            className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                        >

                                                            {
                                                                this.state.loading_posts &&
                                                                <div className="h-full flex justify-center items-center flex-col flex-1 w-full bg-purple-500">
                                                                    <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                </div>
                                                            }
                                                            {
                                                                !this.state.loading_posts &&
                                                                <span>Load More</span>
                                                            }
                                                        </button>
                                                    }

                                                </div>
                                            </div>

                                        }
                                    </>
                                }
                                {
                                    this.state.tab.value === "posts" &&
                                    <div className="flex flex-row mt-6">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "catalogs" &&
                                    <div className="flex flex-row mt-6">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" && this.state.subtab.value === "performance_max" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        open_performancemax: true
                                                    })
                                                }}
                                                className={(true ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open_performancemax: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" && this.state.subtab.value === "display" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        open_display: true
                                                    })
                                                }}
                                                className={(true ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open_display: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" && this.state.subtab.value === "shopping" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.functions.createShopping();
                                                }}
                                                className={(true ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" && this.state.subtab.value === "youtube" &&
                                    <div className="flex flex-row mt-6">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.youtube_url !== "") {
                                                        this.functions.createYoutube();
                                                    }
                                                }}
                                                className={(this.state.youtube_url !== "" ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives" &&
                                    <div className="flex flex-row mt-6">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.assetItems.filter((item) => {
                                                        return item.selected && (item.type == "image" || item.type == "video")
                                                    }).length > 1) {
                                                        this.functions.createCarousel();
                                                    }
                                                }}
                                                className={(this.state.assetItems.filter((item) => {
                                                    return item.selected && (item.type == "image" || item.type == "video")
                                                }).length > 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Carousel</span>
                                                <CollectionIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        {
                                            this.state.channels.facebook &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 1 && this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length < 4 && this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).map((item) => { return item.type }).every((val, i, arr) => val === arr[0]) &&

                                                            // HERE

                                                            (((this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1) &&
                                                                this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) ||

                                                                (this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 &&
                                                                    (this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1 ||
                                                                        this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1)) ||

                                                                ((this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                    this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) &&
                                                                    this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1)
                                                            )

                                                        ) {
                                                            this.functions.createMerge();
                                                        }
                                                    }}
                                                    className={
                                                        (this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 1 && this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length < 4 && this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).map((item) => { return item.type }).every((val, i, arr) => val === arr[0]) &&

                                                            (((this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1) &&
                                                                this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) ||

                                                                (this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 &&
                                                                    (this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1 ||
                                                                        this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1)) ||

                                                                ((this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1:1" }).length === 1 ||
                                                                    this.state.assetItems.filter((item) => { return item.selected && item.ratio !== "1:1" && item.ratio !== "1.91:1" }).length === 1) &&
                                                                    this.state.assetItems.filter((item) => { return item.selected && item.ratio === "1.91:1" }).length === 1)
                                                            )
                                                            ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                >
                                                    <span>Combine</span>
                                                    <TemplateIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.assetItems.filter((item) => {
                                                        return item.selected && (item.type == "image" || item.type == "video")
                                                    }).length === 1) {
                                                        this.setState({
                                                            open_cropper: true
                                                        })
                                                    }
                                                }}
                                                className={(this.state.assetItems.filter((item) => {
                                                    return item.selected && (item.type == "image" || item.type == "video")
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Crop</span>
                                                <CursorClickIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        {
                                            this.state.assetItems.filter((item) => {
                                                return item.selected && (item.type == "image" || item.type == "video")
                                            }).length > 0 &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={() => {
                                                        if (this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 0) {
                                                            this.promisedSetState({
                                                                select_channel: true
                                                            });
                                                        } else if (this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type === "carousel" || item.type === "merge" || item.type == "posts")
                                                        }).length === 1) {
                                                            this.promisedSetState({
                                                                select_channel: true
                                                            });
                                                        }
                                                    }}
                                                    className="cursor-pointer bg-green-600 hover:bg-green-700 shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                >
                                                    <span>Create</span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        {
                                            this.state.assetItems.filter((item) => {
                                                return item.selected && (item.type == "image" || item.type == "video")
                                            }).length === 1 && this.state.channels.facebook &&
                                            <div className="mr-2">
                                                <button
                                                    onClick={async () => {
                                                        if (this.state.assetItems.filter((item) => {
                                                            return item.selected && (item.type == "image" || item.type == "video")
                                                        }).length > 0) {
                                                            await this.promisedSetState({
                                                                select_channel: true,
                                                                collection: true
                                                            });
                                                        }
                                                    }}
                                                    className="cursor-pointer bg-green-600 hover:bg-green-700 shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                                >
                                                    <span>Collection </span>
                                                    <PlusCircleIcon className="ml-2 h-5 w-5" />
                                                </button>
                                            </div>
                                        }
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.assetItems.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.assetItems = this.state.assetItems.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            assetItems: this.state.assetItems,
                                                            size_error: false
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.assetItems.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" &&
                                    this.state.subtab.value === "keywords" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        open_keywords: true
                                                    })
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open_keywords: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.functions.clone();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Clone</span>
                                                <DuplicateIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" &&
                                    this.state.subtab.value === "extension" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        open_extension: true
                                                    })
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open_extension: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.functions.clone();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Clone</span>
                                                <DuplicateIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.tab.value === "search" &&
                                    this.state.subtab.value === "ads" &&
                                    <div className="flex flex-row">
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    this.setState({
                                                        open_searchad: true
                                                    })
                                                }}
                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Create</span>
                                                <PlusCircleIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.promisedSetState({
                                                            open_searchad: true
                                                        });
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Edit</span>
                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={() => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length === 1) {
                                                        this.functions.clone();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length === 1 ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Clone</span>
                                                <DuplicateIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                        <div className="mr-2">
                                            <button
                                                onClick={async () => {
                                                    if (this.state.creatives.filter((item) => {
                                                        return item.selected
                                                    }).length > 0) {
                                                        this.state.creatives = this.state.creatives.filter((item) => {
                                                            return !item.selected;
                                                        });
                                                        await this.promisedSetState({
                                                            creatives: this.state.creatives
                                                        });
                                                        await this.form.set();
                                                        this.props.setSteps();
                                                    }
                                                }}
                                                className={(this.state.creatives.filter((item) => {
                                                    return item.selected
                                                }).length > 0 ? "cursor-pointer bg-red-500 hover:bg-red-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                <span>Remove</span>
                                                <TrashIcon className="ml-2 h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>



                        <div className='flex w-ful mt-4'>
                            <div className='flex flex-1'>
                            </div>
                            <div id="seach" className='w-24 flex transition-all duration-700 rounded-md mr-2 relative'>
                                <input
                                    onBlur={() => {
                                        document.getElementById('seach').classList.remove('w-120');
                                        document.getElementById('seach').classList.add('w-24');
                                    }}
                                    onFocus={() => {
                                        document.getElementById('seach').classList.remove('w-24');
                                        document.getElementById('seach').classList.add('w-120');
                                    }}

                                    className={" block w-full bg-custom-input rounded-md border-1.5 px-2 pr-8 py-2 focus:outline-none sm:text-sm"}
                                    placeholder={"Search"}
                                    value={this.state.seach_value}
                                    onChange={async (event) => {
                                        await this.promisedSetState({
                                            seach_value: event.target.value
                                        })

                                    }}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </div>
                            </div>
                            <div className='flex'>

                                <div onClick={async () => {
                                    await this.promisedSetState({
                                        tab_view: "grid"
                                    })
                                }} className={(this.state.tab_view !== "grid" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5  mr-2 px-2 "}>
                                    <ViewGridIcon className=" h-5 w-5 " />
                                </div>
                                <div onClick={async () => {
                                    await this.promisedSetState({
                                        tab_view: "list"
                                    })
                                }} className={(this.state.tab_view !== "list" ? "bg-white border-gray-500 text-purple-500" : "bg-purple-500 text-white border-purple-700") + " relative cursor-pointer w-full flex justify-center items-center rounded-md border-1.5 px-2 "}>
                                    <MenuIcon className=" h-5 w-5 " />
                                </div>
                            </div>
                        </div>








                        {/* CONTENT */}
                        {/* CREATIVES */}

                        {/* NEW PART */}
                        {
                            (this.state.tab.value === "posts" || this.state.tab.value === "catalogs" || this.state.tab.value === "uploaded") &&
                            this.state.creatives.filter((item) => {
                                if (this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives") {
                                    return (item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel")
                                } else if (this.state.tab.value === "catalogs") {
                                    return (item.type === "catalog")
                                } else if (this.state.tab.value === "posts") {
                                    return (item.type === "posts")
                                }
                            }).length > 0 &&
                            <div className=" grid grid-cols-6 w-full pb-6 mt-2 gap-0 bg-white rounded-md shadow">
                                {
                                    ["facebook", "linkedin", "tiktok", "twitter", "snapchat"].filter((item) => { return this.state.channels[item] }).map((channel) => {
                                        return (
                                            <Fragment>
                                                {
                                                    this.state.creatives.filter((item) => {
                                                        if (this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives") {
                                                            return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && item.allowed_channels && item.allowed_channels[channel])
                                                        } else if (this.state.tab.value === "catalogs") {
                                                            return (item.type === "catalog" && Object.keys(item.allowed_channels).includes(channel))
                                                        } else if (this.state.tab.value === "posts") {
                                                            return (item.type === "posts" && Object.keys(item.allowed_channels).includes(channel))
                                                        }
                                                    }).filter((item) => {
                                                        if (this.state.seach_value !== "" && item.name_by_channels) {
                                                            if (item.name_by_channels[channel] && item.name_by_channels[channel] !== "") {
                                                                //return true
                                                                if (item.name_by_channels[channel].toLowerCase().includes(this.state.seach_value.toLowerCase())) {
                                                                    return true
                                                                } else return false
                                                            } else return false

                                                            //return item.name_by_channels[channel].includes(this.state.seach_value);
                                                        } else {
                                                            return true
                                                        }
                                                    }).length > 0 &&
                                                    <>
                                                        <div onClick={async () => {
                                                            this.state.open_creatives[channel] = !this.state.open_creatives[channel];
                                                            await this.promisedSetState({
                                                                open_creatives: this.state.open_creatives
                                                            })
                                                        }} className={" text-xl  pt-5 px-4 pb-5 flex flex-row items-center font-medium col-span-6 border-b-2 "}>
                                                            <div className='flex flex-1'>
                                                                <div className={"bg-" + (channel) + "-500" + " h-8 w-8 rounded-full flex justify-center items-center mr-3"}>
                                                                    {
                                                                        channel === 'google' &&
                                                                        <img className="w-4"
                                                                            alt=""
                                                                            src={require('../assets/images/google_icon.png')} />
                                                                    }
                                                                    {
                                                                        channel === 'facebook' &&
                                                                        <img className="w-2"
                                                                            alt=""
                                                                            src={require('../assets/images/facebook_icon.svg')} />
                                                                    }
                                                                    {
                                                                        channel === 'linkedin' &&
                                                                        <img className="w-4"
                                                                            alt=""
                                                                            src={require('../assets/images/linkedin_icon.svg')} />
                                                                    }
                                                                    {
                                                                        channel === 'twitter' &&
                                                                        <img className="w-4"
                                                                            alt=""
                                                                            src={require('../assets/images/twitter_icon.svg')} />
                                                                    }
                                                                    {
                                                                        channel === 'tiktok' &&
                                                                        <img className="w-4"
                                                                            alt=""
                                                                            src={require('../assets/images/tiktok_icon.png')} />
                                                                    }
                                                                    {
                                                                        channel === 'snapchat' &&
                                                                        <img className="w-4"
                                                                            alt=""
                                                                            src={require('../assets/images/snapchat.svg')} />
                                                                    }
                                                                </div>
                                                                {channel}
                                                            </div>
                                                            <div className='flex justify-center items-center'>
                                                                {
                                                                    !this.state.open_creatives[channel] &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open_creatives[channel] &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }
                                                            </div>
                                                        </div>


                                                        <SlideDown

                                                            className={'col-span-6 w-full transform max-h-60 duration-500 overflow-y-scroll px-4 overflow-hidden border-b-2 pb-2 relative ' + (this.state.open_creatives[channel] ? "h-full " : "h-0 ") + (this.state.tab_view === "list" ? "bg-white rounded-md" : " grid grid-cols-12 gap-4")}
                                                            closed={!this.state.open_creatives[channel]}
                                                        >
                                                            {
                                                                this.state.tab_view === "list" &&
                                                                <div className='flex flex-row w-full items-center bg-white sticky top-0 z-55'>
                                                                    <div className='mr-8'>
                                                                        <div className='h-10 w-10'>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-1'>
                                                                        <div className='grid grid-cols-8 w-full text-sm font-semibold'>
                                                                            <div className='col-span-2'>
                                                                                Name
                                                                            </div>
                                                                            <div className='col-span-2'>
                                                                                Headline
                                                                            </div>
                                                                            <div className='col-span-2'>
                                                                                Body
                                                                            </div>
                                                                            <div className='col-span-1'>
                                                                                Type
                                                                            </div>
                                                                            <div className='col-span-1'>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }


                                                            {
                                                                this.state.creatives.filter((item) => {
                                                                    if (this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives") {
                                                                        return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && item.allowed_channels && item.allowed_channels[channel])
                                                                    } else if (this.state.tab.value === "catalogs") {
                                                                        return (item.type === "catalog" && Object.keys(item.allowed_channels).includes(channel))
                                                                    } else if (this.state.tab.value === "posts") {
                                                                        return (item.type === "posts" && Object.keys(item.allowed_channels).includes(channel))
                                                                    }
                                                                }).filter((item) => {
                                                                    if (this.state.seach_value !== "" && item.name_by_channels) {
                                                                        if (item.name_by_channels[channel] && item.name_by_channels[channel] !== "") {
                                                                            //return true
                                                                            if (item.name_by_channels[channel].toLowerCase().includes(this.state.seach_value.toLowerCase())) {
                                                                                return true
                                                                            } else return false
                                                                        } else return false

                                                                        //return item.name_by_channels[channel].includes(this.state.seach_value);
                                                                    } else {
                                                                        return true
                                                                    }
                                                                }).filter((item) => { return item }).map((file) => {
                                                                    if (this.state.tab_view === "list") {
                                                                        return (
                                                                            <Fragment>
                                                                                <div className='flex flex-row w-full items-center mt-2 pt-1 border-t'>
                                                                                    <div className='mr-8'>
                                                                                        <div className='h-10 w-10'>
                                                                                            {
                                                                                                file.type === "image" && !file.ad_type &&
                                                                                                <div className="object-cover w-full h-full rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                file.ad_type && file.ad_type === "collection_ad" &&
                                                                                                //<div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                    <PuzzleIcon className=" h-5 w-5" />
                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                file.type === "video" &&
                                                                                                <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-full h-full inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                    <source src={file.url} type="video/mp4" />
                                                                                                </video>
                                                                                            }
                                                                                            {
                                                                                                file.type === "carousel" &&
                                                                                                //<div className="relative cursor-pointer w-full overflow-hidden rounded-md flex justify-center items-center ">
                                                                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                    <CollectionIcon className=" h-5 w-5" />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                file.type === "merge" &&
                                                                                                //<div className="relative cursor-pointer w-full overflow-hidden rounded-md flex justify-center items-center ">
                                                                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                                                                    <TemplateIcon className="h-5 w-5 " />
                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                file.type === "catalog" &&
                                                                                                Array.isArray(file.preview_products) && file.preview_products.filter((inner_item) => { return !inner_item.image_url }).length > 0 &&
                                                                                                <div className=" w-full h-full inset-0 pointer-events-none">
                                                                                                    <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                        {
                                                                                                            file.preview_products.map((item, index) => {
                                                                                                                return (
                                                                                                                    <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                        <video key={item.url} poster={item.thumbnail} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                            <source src={item.url} type="video/mp4" />
                                                                                                                        </video>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                file.type === "catalog" &&
                                                                                                Array.isArray(file.preview_products) && file.preview_products.filter((inner_item) => { return inner_item.image_url }).length > 0 &&
                                                                                                <div className=" w-full h-full inset-0 pointer-events-none">
                                                                                                    <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                        {
                                                                                                            file.preview_products.map((item, index) => {
                                                                                                                return (
                                                                                                                    <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                        <img key={item.image_url} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                // LIST SELECTED CREATIVES HERE
                                                                                                file.type === "posts" &&
                                                                                                file.ad_type === "image" &&
                                                                                                !file.files &&
                                                                                                <>
                                                                                                    <div className="object-cover  w-full h-full inset-0 group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                            {
                                                                                                file.type === "posts" &&
                                                                                                file.ad_type === "video" &&
                                                                                                !file.files &&
                                                                                                <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-full h-full inset-0 group-hover:opacity-75">
                                                                                                    <source src={file.url} type="video/mp4" />
                                                                                                </video>
                                                                                            }
                                                                                            {
                                                                                                // LIST SELECTED CREATIVE CARO HERE
                                                                                                file.type === "posts" &&
                                                                                                file.files &&
                                                                                                <div className=" w-full h-full inset-0 ">
                                                                                                    <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                        {
                                                                                                            file.files.map((item) => {
                                                                                                                if (item.type === "IMAGE" || item.type === "image") {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <div className="object-cover w-full h-full absolute inset-0  group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else if (item.type === "VIDEO" || item.type === "video") {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                                <source src={item.url} type="video/mp4" />
                                                                                                                            </video>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                } else {
                                                                                                                    return <div></div>
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                // file.document &&
                                                                                                // file.document.length > 0 &&
                                                                                                file.ad_type === "document" &&
                                                                                                <div className="object-cover w-full h-full inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                    <iframe src={file.document} className='object-cover'></iframe>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                file.ad_type === "article" &&
                                                                                                <div className="flex justify-center items-center object-cover  w-full h-full inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                    <NewspaperIcon className='w-1/2 text-indigo-500 stroke-1' />
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                file.ad_type === "poll" &&
                                                                                                <div className="flex justify-center items-center object-cover  w-full h-full inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                    <ChartBarIcon className='w-1/3 text-indigo-500 stroke-1' />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='flex flex-1'>
                                                                                        <div className='grid grid-cols-8 w-full text-sm items-center mt-2'>
                                                                                            <div className='col-span-2 truncate pr-1'>
                                                                                                {(file.name_by_channels && file.name_by_channels[channel] && file.name_by_channels[channel] !== "" ? file.name_by_channels[channel] : "-")}
                                                                                            </div>
                                                                                            <div className='col-span-2 truncate px-1'>
                                                                                                {file.headline_by_channels && file.headline_by_channels[channel] && file.headline_by_channels[channel] !== "" ? (file.headline_by_channels[channel].length > 34 ? file.headline_by_channels[channel].substring(0, 34) + "..." : file.headline_by_channels[channel]) : (file.headline ? (file.headline.length > 34 ? file.headline.substring(0, 34) + "..." : file.headline) : (file.type === "merge" ? (file.headlines[0].value !== "" ? file.headlines[0].value : "-") : "-"))}
                                                                                            </div>
                                                                                            <div className='col-span-2 truncate px-1'>
                                                                                                {file.body_by_channels && file.body_by_channels[channel] && file.body_by_channels[channel] !== "" ? (file.body_by_channels[channel].length > 125 ? file.body_by_channels[channel].substring(0, 125) + "..." : file.body_by_channels[channel]) : (file.body ? (file.body.length > 125 ? file.body.substring(0, 125) + "..." : file.body) : (file.type === "merge" ? (file.bodies[0].value !== "" ? file.bodies[0].value : "-") : "-"))}
                                                                                            </div>
                                                                                            <div className='col-span-1 px-1'>
                                                                                                {
                                                                                                    ((file.selected_lead && file.selected_lead.id === 0) || (!file.selected_lead)) && !file.instantExperience &&
                                                                                                    <div className="text-sm">
                                                                                                        {
                                                                                                            //channel === "snapchat" && file.snapchat_adType && file.snapchat_adType.value ? file.snapchat_adType.name : (file.type.charAt(0).toUpperCase() + file.type.slice(1))}
                                                                                                        }
                                                                                                        {(file.type.charAt(0).toUpperCase() + file.type.slice(1))}
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.selected_lead && file.selected_lead.id !== 0 &&
                                                                                                    <div className="text-sm ">
                                                                                                        <div className="flex justify-items-start items-center">
                                                                                                            <ClipboardListIcon className="h-4 w-4 mr-1" />{this.truncText(file.selected_lead.name, 12)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.instantExperience &&
                                                                                                    <div className="text-sm ">
                                                                                                        <div className="flex justify-items-start items-center">
                                                                                                            <PuzzleIcon className="h-4 w-4 mr-1" /> Collection Ad
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className='col-span-1 pl-1'>
                                                                                                <div className='flex'>
                                                                                                    {
                                                                                                        file.allowed_channels &&
                                                                                                        <div className="flex">
                                                                                                            <button
                                                                                                                onClick={() => {
                                                                                                                    file.selected = true;
                                                                                                                    file.is_post = file.type === "posts" ? true : false;
                                                                                                                    this.promisedSetState({
                                                                                                                        creatives: this.state.creatives
                                                                                                                    });
                                                                                                                    if (channel === "linkedin" && file.type === "carousel") {
                                                                                                                        this.promisedSetState({
                                                                                                                            //selected_channels: ["linkedin"]
                                                                                                                        });
                                                                                                                    }
                                                                                                                    this.promisedSetState({
                                                                                                                        open: true,
                                                                                                                        collection: (file.ad_type === "collection_ad" ? true : false),
                                                                                                                    });
                                                                                                                }}
                                                                                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow w-full rounded-md flex relative justify-center py-2 px-3 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                            >
                                                                                                                <PencilAltIcon className=" h-5 w-5" />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div className="flex ml-2">
                                                                                                        <button
                                                                                                            onClick={async () => {
                                                                                                                file.selected = true;
                                                                                                                await this.promisedSetState({
                                                                                                                    creatives: this.state.creatives
                                                                                                                });
                                                                                                                this.state.creatives = this.state.creatives.filter((item) => {
                                                                                                                    return !item.selected;
                                                                                                                });
                                                                                                                await this.promisedSetState({
                                                                                                                    creatives: this.state.creatives
                                                                                                                });
                                                                                                                await this.form.set();
                                                                                                                this.props.setSteps();

                                                                                                            }}
                                                                                                            className={"cursor-pointer bg-red-500 hover:bg-red-600 text-white shadow w-full flex relative justify-center py-2 px-3  rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                        >
                                                                                                            <TrashIcon className="h-5 w-5" />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                    if (this.state.tab_view !== "list") {
                                                                        return (
                                                                            <Fragment>
                                                                                {
                                                                                    <div
                                                                                        onMouseLeave={async () => {
                                                                                            file.show_remove = false;
                                                                                            await this.promisedSetState({
                                                                                                creatives: this.state.creatives
                                                                                            })
                                                                                        }}
                                                                                        onMouseOver={async () => {
                                                                                            file.show_remove = true;
                                                                                            await this.promisedSetState({
                                                                                                creatives: this.state.creatives
                                                                                            })
                                                                                        }}
                                                                                        className={(file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " col-span-4 rounded-md px-2 mt-2 flex flex-col relative"}>
                                                                                        <div className='flex flex-1'>
                                                                                            <div className={"relative cursor-pointer flex rounded-md " + ((file.type === "video" || file.type === "image") ? "" : " border-1.5  border-gray-700")}>
                                                                                                {
                                                                                                    file.type === "image" && !file.ad_type &&
                                                                                                    <div className="object-cover w-24 h-24 rounded-md inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.ad_type && file.ad_type === "collection_ad" &&
                                                                                                    <div className=" w-24 h-24 inset-0 pointer-events-none rounded-md border-1.5 border-gray-700">
                                                                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                            {
                                                                                                                file.instantExperience.id !== 0 && file.instantExperience.elements && file.instantExperience.elements.product_set &&
                                                                                                                file.instantExperience.elements.product_set.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <img key={index} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            {
                                                                                                                file.instantExperience.id !== 0 && file.instantExperience.elements && file.instantExperience.elements.carousel &&
                                                                                                                file.instantExperience.elements.carousel.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <img key={index} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            {
                                                                                                                file.instantExperience.id === 0 && !file.instantExperience.elements &&
                                                                                                                <Fragment>
                                                                                                                    <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                        <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                    </div>
                                                                                                                    <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                        <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                    </div>
                                                                                                                    <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                        <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                    </div>
                                                                                                                    <div className="relative cursor-pointer w-5 h-8 overflow-hidden rounded-md flex justify-center items-center  border-1.5 border-gray-600 ">
                                                                                                                        <BanIcon className="h-10 w-10 text-gray-400" />
                                                                                                                    </div>
                                                                                                                </Fragment>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.type === "video" &&
                                                                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-24 h-24 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                        <source src={file.url} type="video/mp4" />
                                                                                                    </video>
                                                                                                }
                                                                                                {
                                                                                                    (file.type === "carousel" || file.type === "merge") &&
                                                                                                    Array.isArray(file.files) &&
                                                                                                    <div className=" w-24 h-24 inset-0 pointer-events-none">
                                                                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                            {
                                                                                                                file.files.map((item) => {
                                                                                                                    if (item.type === "image") {
                                                                                                                        return (
                                                                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                <div className="object-cover w-full h-full p-2 rounded-md absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } else if (item.type === "video") {
                                                                                                                        return (
                                                                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full p-2 rounded-md absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                                    <source src={item.url} type="video/mp4" />
                                                                                                                                </video>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return <div></div>
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.type === "catalog" &&
                                                                                                    Array.isArray(file.preview_products) && file.preview_products.filter((inner_item) => { return !inner_item.image_url }).length > 0 &&
                                                                                                    <div className=" w-24 h-24 inset-0 pointer-events-none">
                                                                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                            {
                                                                                                                file.preview_products.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <video key={item.url} poster={item.thumbnail} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                                <source src={item.url} type="video/mp4" />
                                                                                                                            </video>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.type === "catalog" &&
                                                                                                    Array.isArray(file.preview_products) && file.preview_products.filter((inner_item) => { return inner_item.image_url }).length > 0 &&
                                                                                                    <div className=" w-24 h-24 inset-0 pointer-events-none">
                                                                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                            {
                                                                                                                file.preview_products.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                            <img key={item.image_url} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    // LIST SELECTED CREATIVES HERE
                                                                                                    file.type === "posts" &&
                                                                                                    file.ad_type === "image" &&
                                                                                                    !file.files &&
                                                                                                    <>
                                                                                                        <div className="object-cover  w-24 h-24 inset-0 group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    file.type === "posts" &&
                                                                                                    file.ad_type === "video" &&
                                                                                                    !file.files &&
                                                                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className=" w-24 h-24 inset-0 group-hover:opacity-75">
                                                                                                        <source src={file.url} type="video/mp4" />
                                                                                                    </video>
                                                                                                }
                                                                                                {
                                                                                                    // LIST SELECTED CREATIVE CARO HERE
                                                                                                    file.type === "posts" &&
                                                                                                    file.files &&
                                                                                                    <div className=" w-24 h-24 inset-0 ">
                                                                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                                                                            {
                                                                                                                file.files.map((item) => {
                                                                                                                    if (item.type === "IMAGE" || item.type === "image") {
                                                                                                                        return (
                                                                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                <div className="object-cover w-full h-full absolute inset-0  group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } else if (item.type === "VIDEO" || item.type === "video") {
                                                                                                                        return (
                                                                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                                                                <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                                                    <source src={item.url} type="video/mp4" />
                                                                                                                                </video>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    } else {
                                                                                                                        return <div></div>
                                                                                                                    }
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    // file.document &&
                                                                                                    // file.document.length > 0 &&
                                                                                                    file.ad_type === "document" &&
                                                                                                    <div className="object-cover w-24 h-24 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                        <iframe src={file.document} className='object-cover'></iframe>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.ad_type === "article" &&
                                                                                                    <div className="flex justify-center items-center object-cover  w-24 h-24 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                        <NewspaperIcon className='w-1/2 text-indigo-500 stroke-1' />
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.ad_type === "poll" &&
                                                                                                    <div className="flex justify-center items-center object-cover  w-24 h-24 inset-0 pointer-events-none group-hover:opacity-75">
                                                                                                        <ChartBarIcon className='w-1/3 text-indigo-500 stroke-1' />
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="ml-2 flex flex-col truncate">

                                                                                                <div className="text-sm font-bold truncate ">
                                                                                                    Name: {(file.name_by_channels && file.name_by_channels[channel] && file.name_by_channels[channel] !== "" ? file.name_by_channels[channel] : "")}
                                                                                                </div>
                                                                                                {
                                                                                                    file.body_by_channels && file.body_by_channels[channel] && channel !== "snapchat" &&
                                                                                                    <div className="text-sm truncate">
                                                                                                        Body: {file.body_by_channels && file.body_by_channels[channel] && file.body_by_channels[channel] !== "" ? (file.body_by_channels[channel].length > 125 ? file.body_by_channels[channel].substring(0, 125) + "..." : file.body_by_channels[channel]) : (file.body ? (file.body.length > 125 ? file.body.substring(0, 125) + "..." : file.body) : (file.type === "merge" ? file.bodies[0].value : ""))}
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.headline_by_channels && file.headline_by_channels[channel] &&
                                                                                                    <>
                                                                                                        <div className="text-sm truncate">
                                                                                                            Headline: {file.headline_by_channels && file.headline_by_channels[channel] && file.headline_by_channels[channel] !== "" ? (file.headline_by_channels[channel].length > 34 ? file.headline_by_channels[channel].substring(0, 34) + "..." : file.headline_by_channels[channel]) : (file.headline ? (file.headline.length > 34 ? file.headline.substring(0, 34) + "..." : file.headline) : (file.type === "merge" ? file.headlines[0].value : ""))}
                                                                                                        </div>

                                                                                                    </>

                                                                                                }
                                                                                                {
                                                                                                    ((file.selected_lead && file.selected_lead.id === 0) || (!file.selected_lead)) && !file.instantExperience &&
                                                                                                    <div className="text-sm">
                                                                                                        Type: {file.snapchat_adType && file.snapchat_adType.value ? file.snapchat_adType.name : "Single Image or Video"}
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.selected_lead && file.selected_lead.id !== 0 &&
                                                                                                    <div className="text-sm ">
                                                                                                        <div className="flex justify-items-start items-center">
                                                                                                            Lead Form: <ClipboardListIcon className="h-4 w-4 mr-1" /> {file.selected_lead.name}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                {
                                                                                                    file.instantExperience &&
                                                                                                    <div className="text-sm ">
                                                                                                        <div className="flex justify-items-start items-center">
                                                                                                            Type: <PuzzleIcon className="h-4 w-4 mr-1" /> Collection Ad
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            file.show_remove &&
                                                                                            <div className="flex flex-col absolute bottom-0 w-full h-full pr-2 mt-2 rounded-md">
                                                                                                <div className='flex flex-1 bg-purple-900 opacity-25 rounded-md rounded-b-none'></div>
                                                                                                <div className='flex'>
                                                                                                    {
                                                                                                        file.allowed_channels &&
                                                                                                        <div className="flex flex-1">
                                                                                                            <button
                                                                                                                onClick={() => {
                                                                                                                    file.selected = true;
                                                                                                                    file.is_post = file.type === "posts" ? true : false;
                                                                                                                    this.promisedSetState({
                                                                                                                        creatives: this.state.creatives
                                                                                                                    });
                                                                                                                    if (channel === "linkedin" && file.type === "carousel") {
                                                                                                                        this.promisedSetState({
                                                                                                                            //selected_channels: ["linkedin"]
                                                                                                                        });
                                                                                                                    }
                                                                                                                    this.promisedSetState({
                                                                                                                        open: true,
                                                                                                                        collection: (file.ad_type === "collection_ad" ? true : false),
                                                                                                                    });
                                                                                                                }}
                                                                                                                className={"cursor-pointer bg-purple-500 hover:bg-purple-600 shadow w-full rounded-md rounded-t-none rounded-r-none flex relative justify-center py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                            >
                                                                                                                <span>Edit</span>
                                                                                                                <PencilAltIcon className="ml-2 h-5 w-5" />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    }
                                                                                                    <div className="flex flex-1 ">
                                                                                                        <button
                                                                                                            onClick={async () => {
                                                                                                                file.selected = true;
                                                                                                                await this.promisedSetState({
                                                                                                                    creatives: this.state.creatives
                                                                                                                });
                                                                                                                this.state.creatives = this.state.creatives.filter((item) => {
                                                                                                                    return !item.selected;
                                                                                                                });
                                                                                                                await this.promisedSetState({
                                                                                                                    creatives: this.state.creatives
                                                                                                                });
                                                                                                                await this.form.set();
                                                                                                                this.props.setSteps();

                                                                                                            }}
                                                                                                            className={"cursor-pointer bg-red-500 hover:bg-red-600 text-white shadow w-full flex relative justify-center py-2 px-4  rounded-md rounded-t-none rounded-l-none text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                                                                                        >
                                                                                                            <span>Remove</span>
                                                                                                            <TrashIcon className="ml-2 h-5 w-5" />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {
                                                                                            !file.show_remove &&
                                                                                            <div className="flex flex-row">
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                })

                                                            }
                                                        </SlideDown>
                                                    </>
                                                }
                                            </Fragment>
                                        )
                                    })

                                }
                            </div>

                        }



                        {
                            this.state.creatives.filter((item) => {
                                if (this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives") {
                                    return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && false)
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "youtube") {
                                    return (item.type === "youtube")
                                } else if (this.state.tab.value === "catalogs") {
                                    return (item.type === "catalog" && false)
                                } else if (this.state.tab.value === "posts") {
                                    return (item.type === "posts" && false)
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "keywords") {
                                    return (item.type === "keywords")
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "extension") {
                                    return (item.type === "extension")
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "ads") {
                                    return (item.type === "search_ad")
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "shopping") {
                                    return (item.type === "shopping")
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "performance_max") {
                                    return (item.type === "performance_max")
                                } else if (this.state.tab.value === "search" && this.state.subtab.value === "display") {
                                    return (item.type === "display")
                                } else {
                                    return false
                                }
                            }).length > 0 &&
                            <ul role="list" className={(this.state.subtab.value === "ads" ? "grid-cols-2" : "grid-cols-5") + " grid gap-4 mt-6"}>
                                {
                                    this.state.creatives.filter((item) => {
                                        if (this.state.tab.value === "uploaded" && this.state.subtab.value === "creatives") {
                                            return ((item.type === "image" || item.type === "video" || item.type === "merge" || item.type === "carousel") && false)
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "youtube") {
                                            return (item.type === "youtube")
                                        } else if (this.state.tab.value === "catalogs") {
                                            return (item.type === "catalog")
                                        } else if (this.state.tab.value === "posts") {
                                            return (item.type === "posts")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "keywords") {
                                            return (item.type === "keywords")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "extension") {
                                            return (item.type === "extension")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "ads") {
                                            return (item.type === "search_ad")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "shopping") {
                                            return (item.type === "shopping")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "performance_max") {
                                            return (item.type === "performance_max")
                                        } else if (this.state.tab.value === "search" && this.state.subtab.value === "display") {
                                            return (item.type === "display")
                                        } else {
                                            return false
                                        }
                                    }).map((file, index) => (
                                        <li onClick={() => {
                                            if (file.selected) {
                                                file.selected = false;
                                            } else if (file.type === "carousel" && this.state.creatives.filter((item) => { return item.selected }).length < 1) {
                                                file.selected = true;
                                            } else if (file.type === "catalog" && this.state.creatives.filter((item) => { return item.selected }).length < 1) {
                                                file.selected = true;
                                            } else if (file.type !== "catalog" && file.type !== "carousel" && this.state.creatives.filter((item) => { return item.selected && (item.type === "carousel" || item.type === "catalog") }).length < 1) {
                                                file.selected = !file.selected;
                                            }
                                            this.promisedSetState({
                                                creatives: this.state.creatives
                                            });
                                        }} key={file.source} className={(file.error ? "border-red-500 bg-red-100 hover:border-red-500 " : "") + (file.selected ? "border-purple-500 bg-purple-100" : "hover:border-gray-400 bg-custom-input") + " rounded-lg overflow-hidden border-1.5 shadow-sm relative cursor-pointer"}>
                                            {
                                                file.type === "keywords" && (file.name == "" || (Array.isArray(file.keywords) && file.keywords.length < 1)) &&
                                                <div style={{ top: '10px', right: '10px' }} className={'flex flex-row absolute'} >
                                                    <div className="flex flex-1">
                                                    </div>
                                                    <div className='text-red-600 flex'>
                                                        <ExclamationIcon className="h-6 w-6 text-red-600 mx-2" />
                                                    </div>

                                                </div>
                                            }

                                            <div style={this.state.subtab.value !== "ads" ? { paddingTop: '100%' } : {}} className={'relative cursor-pointer block w-full overflow-hidden'}>
                                                {
                                                    file.error &&
                                                    <div className="absolute bottom-0 left-0 right-0 p-4">
                                                        <div className="text-sm font-medium text-red-500 text-center">
                                                            {file.message}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "search_ad" &&
                                                    <div className="w-full">
                                                        {
                                                            Array.isArray(file.combinations) &&
                                                            file.combinations.filter((item, index) => {
                                                                return index === 0
                                                            }).map((item) => {
                                                                return (
                                                                    <div>
                                                                        <div className="mb-4">
                                                                            <PreviewGoogleSearch
                                                                                error={Object.keys(searchGoogle.validate(file)).filter((item) => { return searchGoogle.validate(file)[item] }).length > 0 ? true : false}
                                                                                noBorder={true}
                                                                                headlineFirst={item.headlineFirst.text}
                                                                                headlineSecond={item.headlineSecond.text}
                                                                                headlineThird={item.headlineThird.text}
                                                                                bodyOne={item.bodyOne.text}
                                                                                bodySecond={item.bodySecond.text}
                                                                                link={file.link}
                                                                                path1={file.path1}
                                                                                path2={file.path2}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                {
                                                    file.type === "keywords" &&
                                                    <div className="w-full h-full absolute flex justify-center items-center inset-0 text-center">
                                                        <div>
                                                            <div className="text-5xl font-semibold">
                                                                {Array.isArray(file.keywords) ? file.keywords.length : 0}
                                                            </div>
                                                            <div className="text-xs font-medium">
                                                                keywords
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "shopping" &&
                                                    <div className="w-full h-full absolute flex justify-center items-center inset-0 text-center">
                                                        <div>
                                                            <div className="text-5xl font-semibold">
                                                                {file.products ? file.products : "-"}
                                                            </div>
                                                            <div className="text-xs font-medium">
                                                                products
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "extension" &&
                                                    <>
                                                        <div className="w-full h-full absolute flex justify-center items-center inset-0 text-center">
                                                            <div>
                                                                <div className="text-5xl font-semibold">
                                                                    {
                                                                        file.picture &&
                                                                        <img src={file.picture} />
                                                                    }
                                                                    {
                                                                        !file.picture &&
                                                                        <div>
                                                                            {
                                                                                Array.isArray(file.items) ? file.items.length : 0
                                                                            }
                                                                        </div>

                                                                    }
                                                                </div>
                                                                <div className="text-xs font-medium">
                                                                    {
                                                                        file.subtype && file.subtype.value === "call" &&
                                                                        <span>Phone numbers</span>
                                                                    }
                                                                    {
                                                                        file.subtype && file.subtype.value === "sitelink" &&
                                                                        <span>Links</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            Object.keys(extensionGoogle.validate(file)).map((item) => { return extensionGoogle.validate(file)[item] }).filter((item) => { return item }).length > 0 &&
                                                            <div className='text-red-600 absolute top-0 right-0'>
                                                                <ExclamationIcon className="h-6 w-6 text-red-600 mx-2 mt-2" />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {
                                                    file.type === "performance_max" &&
                                                    <div className="w-full h-full absolute inset-0 ">
                                                        <div className="grid grid-cols-3 grid-rows-3 w-full h-full">
                                                            <div className="col-span-3 row-span-1 text-center flex truncate">
                                                                <div className='text-center flex justify-center flex-col items-center flex-1'>
                                                                    <div className="text-sm font-semibold truncate">{file.business}</div>
                                                                    <div className="text-xs">Business</div>
                                                                </div>
                                                                {
                                                                    Object.keys(performanceMax.validate(file)).map((item) => { return performanceMax.validate(file)[item] }).filter((item) => { return item }).length > 0 &&
                                                                    <div className='text-red-600 absolute top-0 right-0'>
                                                                        <ExclamationIcon className="h-6 w-6 text-red-600 mx-2 mt-2" />
                                                                    </div>
                                                                }


                                                            </div>

                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.images) ? file.images.length : 0}</div>
                                                                <div className="text-xs">Images</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.videos) ? file.videos.length : 0}</div>
                                                                <div className="text-xs">Videos</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.headlines) ? file.headlines.length : 0}</div>
                                                                <div className="text-xs">Headlines</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.long_headlines) ? file.long_headlines.length : 0}</div>
                                                                <div className="text-xs">Long</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.descriptions) ? file.descriptions.length : 0}</div>
                                                                <div className="text-xs">Descriptions</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{file.cta ? file.cta.name : "Auto"}</div>
                                                                <div className="text-xs">CTA</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "display" &&
                                                    <div className="w-full h-full absolute inset-0 ">
                                                        <div className="grid grid-cols-3 grid-rows-3 w-full h-full">
                                                            <div className="col-span-3 row-span-1 text-center flex truncate">
                                                                <div className='text-center flex justify-center flex-col items-center flex-1'>
                                                                    <div className="text-sm font-semibold truncate">{file.business}</div>
                                                                    <div className="text-xs">Business</div>
                                                                </div>
                                                                {
                                                                    Object.keys(performanceMax.validate(file)).map((item) => { return performanceMax.validate(file)[item] }).filter((item) => { return item }).length > 0 &&
                                                                    <div className='text-red-600 absolute top-0 right-0'>
                                                                        <ExclamationIcon className="h-6 w-6 text-red-600 mx-2 mt-2" />
                                                                    </div>
                                                                }


                                                            </div>

                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.images) ? file.images.length : 0}</div>
                                                                <div className="text-xs">Images</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.videos) ? file.videos.length : 0}</div>
                                                                <div className="text-xs">Videos</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.headlines) ? file.headlines.length : 0}</div>
                                                                <div className="text-xs">Headlines</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.long_headlines) ? file.long_headlines.length : 0}</div>
                                                                <div className="text-xs">Long</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{Array.isArray(file.descriptions) ? file.descriptions.length : 0}</div>
                                                                <div className="text-xs">Descriptions</div>
                                                            </div>
                                                            <div className="col-span-1 row-span-1 text-center flex justify-center flex-col items-center">
                                                                <div className="text-sm font-semibold">{file.cta ? file.cta.name : "Auto"}</div>
                                                                <div className="text-xs">CTA</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "image" &&
                                                    <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                    </div>
                                                }
                                                {
                                                    file.type === "video" &&
                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                        <source src={file.url} type="video/mp4" />
                                                    </video>
                                                }
                                                {
                                                    (file.type === "carousel" || file.type === "merge") &&
                                                    Array.isArray(file.files) &&
                                                    <div className="w-full h-full absolute inset-0 pointer-events-none">
                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                            {
                                                                file.files.map((item) => {
                                                                    if (item.type === "image") {
                                                                        return (
                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else if (item.type === "video") {
                                                                        return (
                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                    <source src={item.url} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return <div></div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "posts" &&
                                                    file.ad_type === "image" &&
                                                    !file.files &&
                                                    <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" style={{ background: 'url(' + file.url + ') center center / contain no-repeat' }}>

                                                    </div>
                                                }
                                                {
                                                    file.type === "posts" &&
                                                    file.files &&
                                                    <div className="w-full h-full absolute inset-0 pointer-events-none">
                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                            {
                                                                file.files.map((item) => {
                                                                    if (item.type === "IMAGE" || item.type === "image") {
                                                                        return (
                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                <div className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75 " style={{ background: 'url(' + item.url + ') center center / contain no-repeat' }}>

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    } else if (item.type === "VIDEO" || item.type === "video") {
                                                                        return (
                                                                            <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                                <video key={item.url} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                    <source src={item.url} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                        )
                                                                    } else {
                                                                        return <div></div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "posts" &&
                                                    file.ad_type === "video" &&
                                                    !file.files &&
                                                    <video key={file.url} poster={file.thumbnail} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                        <source src={file.url} type="video/mp4" />
                                                    </video>
                                                }
                                                {
                                                    file.type === "catalog" &&
                                                    Array.isArray(file.preview_products) &&
                                                    <div className="w-full h-full absolute inset-0 pointer-events-none">
                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                            {
                                                                file.preview_products.map((item, index) => {
                                                                    return (
                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                            <video key={item.url} poster={item.thumbnail} autoPlay={false} muted={true} controls={true} className="w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75">
                                                                                <source src={item.url} type="video/mp4" />
                                                                            </video>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.type === "youtube" &&
                                                    <div className="w-full h-full absolute top-0">
                                                        <iframe src={"https://www.youtube.com/embed/" + file.youtube_id} frameborder={"0"} styles={{ "overflow": "hidden", "height": "100%", "width": "100%" }} height={"100%"} width={"100%"}></iframe>
                                                    </div>
                                                }
                                                {
                                                    file.type === "catalog" &&
                                                    Array.isArray(file.preview_products) &&
                                                    <div className="w-full h-full absolute inset-0 pointer-events-none">
                                                        <div className="grid grid-cols-3 gap-2 p-2">
                                                            {
                                                                file.preview_products.map((item, index) => {
                                                                    return (
                                                                        <div style={{ paddingTop: '100%' }} className="relative cursor-pointer block w-full overflow-hidden rounded-md">
                                                                            <img key={item.image_url} src={item.image_url} alt="" className="object-cover w-full h-full absolute inset-0 pointer-events-none group-hover:opacity-75" />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    file.loading &&
                                                    <div className="w-full h-full absolute inset-0 bg-white flex justify-center items-center z-20 rounded-lg">
                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="p-2 truncate border-t">
                                                {
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm truncate font-medium text-gray-900"}>{file.name && file.name !== "" ? file.name : "..."}</div>
                                                }
                                                {
                                                    (file.type == "video" || file.type == "image") &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>{file.type}, {file.ratio}</div>
                                                }
                                                {
                                                    (file.type == "youtube") &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>Youtube</div>
                                                }
                                                {
                                                    (file.type == "shopping") &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>Shopping</div>
                                                }
                                                {
                                                    file.type === "carousel" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>Carousel, {file.files.length} slides</div>
                                                }
                                                {
                                                    file.type === "merge" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm flex flex-row items-center focus font-medium text-gray-500"}>
                                                        <div className={"bg-facebook-500" + " h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                                            <img className="w-1"
                                                                alt=""
                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                        </div>
                                                        Merged, {file.files.length} assets
                                                    </div>

                                                }
                                                {
                                                    file.type === "posts" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm flex flex-row items-center font-medium text-gray-500"}>
                                                        <div className={"bg-" + ((file.channel && file.instagram_post) ? "instagram" : file.channel) + "-500" + " h-4 w-4 rounded-full flex justify-center items-center mr-1"}>
                                                            {
                                                                file.channel === 'google' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/google_icon.png')} />
                                                            }
                                                            {
                                                                (file.channel === 'facebook' && !file.instagram_post) &&
                                                                <img className="w-1"
                                                                    alt=""
                                                                    src={require('../assets/images/facebook_icon.svg')} />
                                                            }
                                                            {
                                                                (file.channel === 'facebook' && file.instagram_post) &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/instagram_icon.svg')} />
                                                            }
                                                            {
                                                                file.channel === 'linkedin' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/linkedin_icon.svg')} />
                                                            }
                                                            {
                                                                file.channel === 'twitter' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/twitter_icon.svg')} />
                                                            }
                                                            {
                                                                file.channel === 'tiktok' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/tiktok_icon.png')} />
                                                            }
                                                            {
                                                                file.channel === 'snapchat' &&
                                                                <img className="w-2"
                                                                    alt=""
                                                                    src={require('../assets/images/snapchat.svg')} />
                                                            }
                                                        </div>
                                                        Sponsored post
                                                    </div>
                                                }
                                                {
                                                    file.type === "catalog" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>Catalog, {file.product_count} products</div>
                                                }
                                                {
                                                    file.type === "keywords" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>{file.negative && file.negative.value ? "Negative" : "Positive"} keywords</div>
                                                }
                                                {
                                                    file.type === "extension" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>{file.subtype.name} Extension</div>
                                                }
                                                {
                                                    file.type === "performance_max" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>Performance max</div>
                                                }
                                                {
                                                    file.type === "search_ad" &&
                                                    <div className={(file.loading ? "opacity-0" : "") + " text-sm font-medium text-gray-500"}>{file.format ? file.format.name : "Search"}</div>
                                                }
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        }



                        {/* BOTTOM SECTION */}
                        <div className="pt-4 flex flex-row">
                            <div className="flex flex-1">
                                <button
                                    type="button"
                                    onClick={async () => {
                                        this.props.history.push("/v2/campaigns/new/channels");
                                    }}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none shadow focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <ArrowLeftIcon className="mr-2 h-5 w-5" />
                                    Back
                                </button>
                            </div>
                            <div className="flex flex-1"></div>
                            <div className="flex justify-center items-center">
                                <div className="">
                                    <button
                                        onClick={() => {
                                            if (!this.state.error) {
                                                if (this.state.ads.length > 0) {
                                                    if (this.state.channels.facebook) {
                                                        this.props.history.push("/v2/campaigns/new/facebook");
                                                    } else if (this.state.channels.linkedin) {
                                                        this.props.history.push("/v2/campaigns/new/linkedin");
                                                    } else if (this.state.channels.google) {
                                                        this.props.history.push("/v2/campaigns/new/google");
                                                    } else if (this.state.channels.twitter) {
                                                        this.props.history.push("/v2/campaigns/new/twitter");
                                                    } else if (this.state.channels.tiktok) {
                                                        this.props.history.push("/v2/campaigns/new/tiktok");
                                                    } else if (this.state.channels.snapchat) {
                                                        this.props.history.push("/v2/campaigns/new/snapchat");
                                                    }
                                                } else {
                                                    this.promisedSetState({
                                                        //select_all: true,
                                                        //open_push: true
                                                        ads_warning: true
                                                    });
                                                }
                                            }
                                        }}
                                        className={(!this.state.error ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                    >
                                        <span>Next: <span className="capitalize">{this.renders.nextButtonLabel()}</span></span>
                                        <ArrowRightIcon className="ml-2 h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                }

            </div>
        )
    }

}

export default CreateDraftCreatives;