import React, { Component, Fragment } from 'react';
import FeatherIcon from "feather-icons-react";
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/outline'
import moment from 'moment';
import SingleDatepicker from "../modules/singleDatepicker";
import SlideDown from 'react-slidedown';
import DropdownTailwind from './dropdownTailwind';

class AdvancedReportSlideInDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: true,
            tab: {},
            daterange: {},
            update_dates_page: null,
        };
    };

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            update_dates_page: nextProps.updateDatesPage,
            closed: nextProps.closed,
            tab: nextProps.tab,
            daterange_warning: false
        }, () => {
            if (!nextProps.closed) {
                this.setState({
                    daterange: {}
                }, () => {
                    if (this.state.tab && this.state.tab.grid_data) {
                        this.functions.setDaterange();
                    }
                })
            }
        });
    }

    functions = {
        valid: () => {
            return Object.keys(this.state.daterange).length > 0;
        },
        setDaterange: () => {
            const data = this.state.update_dates_page
                ? this.state.tab.grid_data.filter((item) => { return item.group == this.state.update_dates_page.id })
                : this.state.tab.grid_data
            if (data.length) {
                const items_with_daterange = data.filter(item => item.celltype.value === "table" || item.celltype.value === "number" || item.celltype.value === "chart")
                if (items_with_daterange.length) {
                    const first_range_subtitle = items_with_daterange[0].daterange ? items_with_daterange[0].daterange.subtitle : '';
                    const data_with_same_daterange = items_with_daterange.filter(item => item.daterange && item.daterange.subtitle === first_range_subtitle);
                    if (data_with_same_daterange.length === items_with_daterange.length) {
                        this.setState({
                            daterange: items_with_daterange[0].daterange
                        });
                    } else {
                        this.setState({
                            daterange_warning: true
                        });
                    }
                }
            }
        }
    };

    render() {
        return (
            <Transition.Root show={!this.state.closed} as={Fragment}>
                <Dialog style={{ zIndex: 151 }} as="div" className="fixed inset-0 overflow-hidden" onClose={() => { }}>
                    <div className="absolute inset-0 overflow-hidde">
                        <Dialog.Overlay className="absolute inset-0 bg-purple-900 bg-opacity-50" />
                        <div className="pointer-events-none fixed inset-y-0 p-4 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className={"max-w-xl pointer-events-auto rounded-md overflow-hidden w-screen flex"}>
                                    <div className="h-full w-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                                        <div className="flex-1 h-0 overflow-y-auto">
                                            <div className="py-4 px-4 sm:px-6 bg-purple-900 ">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="flex flex-1 flex-row pr-14">
                                                        <div className="text-xl flex-1 flex leading-6 font-semibold text-white">
                                                            Update date
                                                        </div>
                                                    </Dialog.Title>
                                                    <div onClick={() => {
                                                        this.props.toggle();
                                                    }} className="ml-3 cursor-pointer h-12 w-12 flex justify-center transition-colors ease-in duration-75 hover:bg-red-700 text-white items-center rounded-full bg-red-500 ">
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between">
                                                <SelectCellDaterange
                                                    daterangeWarning={this.state.daterange_warning}
                                                    tab={{ daterange: this.state.daterange }}
                                                    onTab={(tab) => {
                                                        this.setState({
                                                            daterange: tab.daterange
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                                            <button
                                                onClick={() => {
                                                    if (this.functions.valid()) {
                                                        if (this.state.daterange && this.state.daterange.value === "7_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "10_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(10, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "14_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(14, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "20_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(20, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "30_days") {
                                                            this.state.daterange.start_date = moment(new Date()).subtract(30, 'days').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment(new Date()).subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "last_week") {
                                                            this.state.daterange.start_date = moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "last_month") {
                                                            this.state.daterange.start_date = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "this_month") {
                                                            this.state.daterange.start_date = moment().startOf('month').format("YYYY-MM-DD");
                                                            this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                        } else if (this.state.daterange && this.state.daterange.value === "custom_dates") {

                                                        } else if (this.state.daterange && this.state.daterange.value === "x_days") {
                                                            if (this.state.daterange.days && this.state.daterange.days.value) {
                                                                this.state.daterange.start_date = moment().subtract(this.state.daterange.days.value, 'days').format("YYYY-MM-DD");
                                                                this.state.daterange.end_date = moment().subtract(1, 'days').format("YYYY-MM-DD");
                                                            }
                                                        } else {
                                                            this.state.daterange = {};
                                                        }
                                                        this.state.tab.daterange = this.state.daterange;
                                                        this.props.onTab(this.state.tab);
                                                        this.props.toggle();
                                                    }
                                                }}
                                                className={(this.functions.valid() ? "cursor-pointer bg-purple-500 hover:bg-purple-600 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

}

class SelectCellDaterange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            channels: {},
            accounts: {},
            campaigns: {},
            adgroups: {},
            ads: {},
            dateranges: [],
            x_days: []
        };
    };

    componentDidMount() {
        let dateranges = [
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last X days",
                subtitle: "Select X amounts of days",
                icon: "calendar",
                value: "x_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + (moment().subtract(1, 'days').format("DD") == "01" ? moment().format("YYYY-MM-DD") : moment().subtract(1, 'days').format("YYYY-MM-DD"))),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            x_days: Array.from({ length: 364 }, (_, i) => i + 2).map((item) => { return { id: item, title: item, name: item + " days", value: item } }),
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {},
            daterange_warning: this.props.daterangeWarning,
            channels: this.props.selectedChannels ? this.props.selectedChannels : {},
            accounts: this.props.selectedAccounts ? this.props.selectedAccounts : {},
            campaigns: this.props.selectedCampaigns ? this.props.selectedCampaigns : {},
            adgroups: this.props.selectedAdgroups ? this.props.selectedAdgroups : {},
            ads: this.props.selectedAds ? this.props.selectedAds : {},
            open: this.props.open
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {},
            channels: nextProps.selectedChannels ? nextProps.selectedChannels : {},
            accounts: nextProps.selectedAccounts ? nextProps.selectedAccounts : {},
            campaigns: nextProps.selectedCampaigns ? nextProps.selectedCampaigns : {},
            adgroups: nextProps.selectedAdgroups ? nextProps.selectedAdgroups : {},
            ads: nextProps.selectedAds ? nextProps.selectedAds : {},
            daterange_warning: nextProps.daterangeWarning,
            open: nextProps.open
        })
    }

    functions = {
        valid: () => {
            return true;
        },
        dailySelected: () => {
            let daily_selected = false;
            if (this.state.tab.channel_breakdowns) {
                for (let channel in this.state.tab.channel_breakdowns) {
                    for (let breakdown in this.state.tab.channel_breakdowns[channel]) {
                        if (breakdown == "date" || breakdown == "ga:date") {
                            daily_selected = true;
                        }
                    }
                }
            }
            return daily_selected;
        },
        compareFormValidation: () => {
            let amount_of_datasources = 0;
            amount_of_datasources += Object.keys(this.state.channels).length;
            amount_of_datasources += Object.keys(this.state.accounts).length;
            amount_of_datasources += Object.keys(this.state.campaigns).length;
            amount_of_datasources += Object.keys(this.state.adgroups).length;
            amount_of_datasources += Object.keys(this.state.ads).length;
            if ((amount_of_datasources > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart") || (this.state.tab.metrics && Object.keys(this.state.tab.metrics).length > 1 && this.state.tab.celltype && this.state.tab.celltype.value === "chart")) {
                return false;
            } else if (this.state.tab.celltype && this.state.tab.celltype.value === "chart" && this.state.tab.chart && this.state.tab.chart.value === "donut") {
                return false;
            } else {
                return true;
            }
        }
    };

    render() {
        return (
            <div className="px-6">
                <div className="w-full h-6" />
                {
                    this.state.daterange_warning &&
                    <div className="mb-5" style={{ height: '100%' }}>
                        <div
                            className="relative text-sm font-medium py-3 items-center px-4  text-yellow-500 bg-yellow-100 rounded-md">
                            <div className="flex justify-start">
                                <span className="">
                                    Not all cells have same date range
                                </span>
                            </div>
                        </div>
                    </div>
                }
                <div className="">
                    {
                        this.state.dateranges.filter((item) => {
                            if (this.state.tab.celltype && this.state.tab.celltype.id === "performance") {
                                return item.value == "custom_dates";
                            } else {
                                return true;
                            }
                        }).map((item, i) => {
                            return (
                                <Fragment key={i}>
                                    <div
                                        className={((this.functions.dailySelected() && item.value == "lifetime") ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                        <div onClick={() => {

                                        }}
                                            className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                            <FeatherIcon className={'stroke-current'}
                                                size={17} icon={item.icon} />
                                        </div>
                                        <div onClick={() => {
                                            if (!(this.functions.dailySelected() && item.value == "lifetime")) {
                                                if (this.state.tab.daterange && this.state.tab.daterange.compare_dates) {
                                                    item.compare_dates = this.state.tab.daterange.compare_dates;
                                                }
                                                if (this.state.tab.daterange && this.state.tab.daterange.compare_last_year) {
                                                    item.compare_last_year = this.state.tab.daterange.compare_last_year;
                                                }
                                                this.state.tab.daterange = item;
                                                if (item.value === "x_days") {
                                                    this.state.tab.daterange.days = { id: 1, title: "1", name: "1 day", value: 1 };
                                                } else {
                                                    delete this.state.tab.daterange.days;
                                                }
                                                this.setState({
                                                    tab: this.state.tab
                                                }, () => {
                                                    this.props.onTab(this.state.tab);
                                                });
                                            }
                                        }}
                                            className={`cursor-pointer h-10 w-10 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center ${this.functions.dailySelected() && item.value == "lifetime" && "cursor-not-allowed"} ${this.state.tab.daterange && this.state.tab.daterange.value === item.value && "border-purple-500"}`}
                                        >
                                            {
                                                this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                <div
                                                    className="w-full h-full rounded-full bg-purple-500" />
                                            }
                                        </div>
                                        <div className="flex flex-1 flex-col">
                                            <div className="font-medium text-sm">
                                                {item.title}
                                            </div>
                                            {
                                                (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                    (<div className="text-xxs">
                                                        From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                    </div>) :
                                                    (<div className="text-xxs">
                                                        {item.subtitle}
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                    {
                                        item.value === "x_days" &&
                                        this.state.tab.daterange && this.state.tab.daterange.value === "x_days" &&
                                        <div className="w-full relative mb-2">
                                            <DropdownTailwind
                                                searchInput={true}
                                                selected={this.state.tab.daterange && this.state.tab.daterange.days ? this.state.tab.daterange.days : { id: 0, name: "Select X days" }}
                                                options={this.state.x_days}
                                                onChange={(value) => {
                                                    if (!this.state.tab.daterange) {
                                                        this.state.tab.daterange = {};
                                                    }
                                                    this.state.tab.daterange.days = value;
                                                    this.setState({
                                                        tab: this.state.tab
                                                    }, () => {
                                                        this.props.onTab(this.state.tab);
                                                    });
                                                }}
                                            />
                                        </div>
                                    }
                                </Fragment>
                            )
                        })
                    }
                </div>
                {
                    this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                    <div className="w-full border-1.5 rounded-md mt-4 p-4 pb-0 pt-2 bg-custom-input">
                        <SingleDatepicker
                            large={true}
                            maxDate={(this.state.tab.celltype && this.state.tab.celltype.id === "performance") ? null : new Date()}
                            from={this.state.tab.daterange.start_date}
                            to={this.state.tab.daterange.end_date}
                            onChange={(data) => {
                                if (!this.state.tab.daterange) {
                                    this.state.tab.daterange = {};
                                }
                                this.state.tab.daterange.start_date = data.start_date;
                                this.state.tab.daterange.end_date = data.end_date;
                                this.setState({
                                    tab: this.state.tab
                                }, () => {
                                    this.props.onTab(this.state.tab);
                                });
                            }}
                        />
                    </div>
                }
                {
                    !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                    <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 ") + " font-medium text-xs truncate mb-2 mt-5"}>
                        Comparison settings
                    </div>
                }
                {
                    !(this.state.tab.celltype && this.state.tab.celltype.id === "performance") &&
                    <div className="mb-2">
                        <div className={(this.functions.compareFormValidation() ? "" : "opacity-50 cursor-not-allowed") + " flex flex-row"}>
                            <div
                                className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                <FeatherIcon className={'stroke-current'}
                                    size={17} icon={"trending-up"} />
                            </div>
                            <div onClick={() => {
                                if (this.functions.compareFormValidation()) {
                                    if (!this.state.tab.daterange) {
                                        this.state.tab.daterange = {};
                                        this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" };
                                    } else if (!this.state.tab.daterange.compare_dates) {
                                        this.state.tab.daterange.compare_dates = { title: "Enabled", value: "enabled" }
                                    } else {
                                        delete this.state.tab.daterange.compare_last_year;
                                        delete this.state.tab.daterange.compare_dates;
                                    }
                                    this.setState({
                                        tab: this.state.tab
                                    }, () => {
                                        this.props.onTab(this.state.tab);
                                    });
                                }
                            }}
                                className={(this.functions.compareFormValidation() ? "cursor-pointer " : "cursor-not-allowed ") + (this.functions.compareFormValidation() && this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                {
                                    this.functions.compareFormValidation() &&
                                    this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                                    <FeatherIcon
                                        className={'stroke-current'}
                                        size={16}
                                        icon="check" />
                                }
                            </div>
                            <div className="flex flex-1 flex-col truncate">
                                <div className="font-medium text-sm truncate">
                                    Compare with preceding period
                                </div>
                                <div className="text-xxs">
                                    Evaluate selected time period with previous period
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.functions.compareFormValidation() &&
                    <div className="col-span-6 p-3 rounded-md font-medium text-xs text-orange-500 bg-orange-100 mb-2">
                        OBS! Selection is not supported for comparing with preceding period
                    </div>
                }
                {
                    this.functions.compareFormValidation() &&
                    this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                    <div className="relative">
                        {
                            this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                            <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                        }
                        <div className="flex flex-row">
                            <div
                                className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                <FeatherIcon className={'stroke-current'}
                                    size={17} icon={"clock"} />
                            </div>
                            <div onClick={() => {
                                if (!this.state.tab.daterange.compare_last_year) {
                                    this.state.tab.daterange.compare_last_year = { title: "Enabled", value: "enabled" }
                                } else {
                                    delete this.state.tab.daterange.compare_last_year;
                                }
                                this.setState({
                                    tab: this.state.tab
                                }, () => {
                                    this.props.onTab(this.state.tab);
                                });
                            }}
                                className={(this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                                    <FeatherIcon
                                        className={'stroke-current'}
                                        size={16}
                                        icon="check" />
                                }
                            </div>
                            <div className="flex flex-1 flex-col">
                                <div className="font-medium text-sm">
                                    Same daterange one year ago
                                </div>
                                <div className="text-xxs">
                                    Selected period one year ago
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.functions.compareFormValidation() &&
                    this.state.tab.daterange && this.state.tab.daterange.compare_dates && this.state.tab.daterange.compare_dates.value === "enabled" &&
                    <div className="mt-2 relative">
                        {
                            this.state.tab.daterange && this.state.tab.daterange.compare_last_year && this.state.tab.daterange.compare_last_year.value === "enabled" &&
                            <div className="bg-white bg-opacity-50 absolute left-0 right-0 bottom-0 top-0"></div>
                        }
                        <div className="flex flex-row">
                            <div
                                className="text-purple-500 bg-purple-100 h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                <FeatherIcon className={'stroke-current'}
                                    size={17} icon={"calendar"} />
                            </div>
                            <div onClick={() => {
                                if (!this.state.tab.daterange.compare_custom_date) {
                                    this.state.tab.daterange.compare_custom_date = { title: "Enabled", value: "enabled" }
                                } else {
                                    delete this.state.tab.daterange.compare_custom_date;
                                }
                                this.setState({
                                    tab: this.state.tab
                                }, () => {
                                    this.props.onTab(this.state.tab);
                                });
                            }}
                                className={(this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                {
                                    this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                    <FeatherIcon
                                        className={'stroke-current'}
                                        size={16}
                                        icon="check" />
                                }
                            </div>
                            <div className="flex flex-1 flex-col truncate">
                                <div className="font-medium text-sm">
                                    Custom daterange
                                </div>
                                <div className="text-xxs">
                                    {
                                        this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                                        this.state.tab.daterange.compare_custom_date.start_date &&
                                        this.state.tab.daterange.compare_custom_date.end_date &&
                                        <span> From {this.state.tab.daterange.compare_custom_date.start_date} to {this.state.tab.daterange.compare_custom_date.end_date}</span>
                                        ||
                                        <span>Select daterange</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.tab.daterange && this.state.tab.daterange.compare_custom_date && this.state.tab.daterange.compare_custom_date.value === "enabled" &&
                    <div className="w-full border-1.5 rounded-md mt-4 p-4 pt-2 pb-0 bg-custom-input">
                        <SingleDatepicker
                            large={true}
                            maxDate={new Date()}
                            from={this.state.tab.daterange.compare_custom_date.start_date}
                            to={this.state.tab.daterange.compare_custom_date.end_date}
                            onChange={(data) => {
                                this.state.tab.daterange.compare_custom_date.start_date = data.start_date;
                                this.state.tab.daterange.compare_custom_date.end_date = data.end_date;
                                this.setState({
                                    tab: this.state.tab
                                }, () => {
                                    this.props.onTab(this.state.tab);
                                });
                            }}
                        />
                    </div>
                }
                <div className="w-full h-6" />
            </div>
        )
    }
}

class SelectCellDaterangeOld extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            dateranges: []
        };
    };

    componentDidMount() {
        let dateranges = [
            /*
            {
                title: "Lifetime",
                subtitle: "From start date until today",
                icon: "calendar",
                value: "lifetime",
                disabled: true
            },
            */
            {
                title: "Last 7 days",
                subtitle: ("From " + moment().subtract(7, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "7_days",
                disabled: false
            },
            {
                title: "Last 10 days",
                subtitle: ("From " + moment().subtract(10, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "10_days",
                disabled: false
            },
            {
                title: "Last 14 days",
                subtitle: ("From " + moment().subtract(14, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "14_days",
                disabled: false
            },
            {
                title: "last 20 days",
                subtitle: ("From " + moment().subtract(20, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "20_days",
                disabled: false
            },
            {
                title: "Last 30 days",
                subtitle: ("From " + moment().subtract(30, 'days').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "30_days",
                disabled: false
            },
            {
                title: "Last week",
                subtitle: ("From " + moment().subtract(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'weeks').endOf('isoWeek').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_week",
                disabled: false
            },
            {
                title: "Last month",
                subtitle: ("From " + moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "last_month",
                disabled: false
            },
            {
                title: "This month",
                subtitle: ("From " + moment().startOf('month').format("YYYY-MM-DD") + " To " + moment().subtract(1, 'days').format("YYYY-MM-DD")),
                icon: "calendar",
                value: "this_month",
                disabled: false
            },
            {
                title: "Custom dates",
                subtitle: "Add custom start date and end date",
                icon: "calendar",
                value: "custom_dates",
                disabled: false
            }
        ];
        this.setState({
            dateranges: dateranges,
            tab: this.props.tab ? this.props.tab : {}
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            tab: nextProps.tab ? nextProps.tab : {}
        })
    }

    functions = {
        dailySelected: () => {
            return false;
        }
    };

    render() {
        return (
            <>
                <div
                    className={"px-4 sm:px-6 pb-6 border-b pt-6"}>
                    <div className="">
                        <div className="">
                            {
                                this.state.dateranges.map((item, i) => {
                                    return (
                                        <Fragment key={i}>
                                            <div
                                                className={(item.disabled ? "opacity-50 cursor-not-allowed" : "") + " mb-2 flex flex-1 flex-row justify-center"}>
                                                <div onClick={() => {

                                                }}
                                                    className="text-purple-500 bg-purple-100 h-8 w-8 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14} icon={item.icon} />
                                                </div>
                                                <div onClick={() => {
                                                    if (!item.disabled) {
                                                        this.state.tab.daterange = item;
                                                        this.props.onTab(this.state.tab);
                                                    }
                                                }}
                                                    className={(item.disabled ? "cursor-not-allowed" : "cursor-pointer") + ((this.state.tab.daterange && this.state.tab.daterange.value === item.value) ? "border-purple-500" : "") + " h-8 w-8 p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                    {
                                                        this.state.tab.daterange && this.state.tab.daterange.value === item.value &&
                                                        <div
                                                            className="w-full h-full rounded-full bg-purple-500" />
                                                    }
                                                </div>
                                                <div className="flex flex-1 flex-col truncate">
                                                    <div className="font-bold text-xs truncate">
                                                        {item.title}
                                                    </div>
                                                    {
                                                        (item.value === "custom_dates" && this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" && this.state.tab.daterange.start_date && this.state.tab.daterange.end_date) ?
                                                            (<div className="text-xxs">
                                                                From {this.state.tab.daterange.start_date} to {this.state.tab.daterange.end_date}
                                                            </div>) :
                                                            (<div className="text-xxs">
                                                                {item.subtitle}
                                                            </div>)
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )
                                })
                            }
                        </div>
                        {
                            this.state.tab.daterange && this.state.tab.daterange.value === "custom_dates" &&
                            <div className="w-full">
                                <SingleDatepicker
                                    maxDate={new Date()}
                                    from={this.state.tab.daterange.start_date}
                                    to={this.state.tab.daterange.end_date}
                                    onChange={(data) => {
                                        if (!this.state.tab.daterange) {
                                            this.state.tab.daterange = {};
                                        }
                                        this.state.tab.daterange.start_date = data.start_date;
                                        this.state.tab.daterange.end_date = data.end_date;
                                        this.props.onTab(this.state.tab);
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default AdvancedReportSlideInDate;