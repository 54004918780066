import React, { Component } from 'react';
import { apiRegister } from '../services/apiRegister';
import { userRegister } from '../services/userRegister';
import { tokenRegister } from '../services/tokenRegister';
import ClientTopNavigation from '../moduleFiles/clientTopNavigation';
import translations from '../assets/json/onboarding_translation.json'
import { ChartPieIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon, CubeIcon } from '@heroicons/react/outline';
import SlideDown from 'react-slidedown';
import { Doughnut, Line } from 'react-chartjs-2';
import DropdownTailwind from '../moduleFiles/dropdownTailwind';

class ClientDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            loading: true,
            closed: true,
            page: 1,
            limit: 3,
            sort: "created_at",
            direction: "desc",
            search: "",
            dateSpan: { id: 1, name: "Last 7 days", value: "last_7_days" },
            view: { id: 1, name: "Clients", value: "clients" },
            cards: [
                { id: 1, name: "Impressions", value: "impressions", total: 0 },
                { id: 2, name: "Clicks", value: "clicks", total: 0 },
                { id: 3, name: "Spend", value: "spend", total: 0 },
                { id: 4, name: "CTR", value: "ctr", total: 0 },
                { id: 5, name: "CPC", value: "cpc", total: 0 },
            ],
            total_impressions: 0,
            total_clicks: 0,
            total_ctr: 0,
            total_spend: 0,
            total_cpc: 0,
            whitelabel_settings: {},
            orders: [],
            campaigns: [],
            show_graph: true,
            selected_metric: { id: 1, name: "Impressions", value: "impressions", total: 0 },
            time_spans: [
                { id: 1, name: "This month (default)", value: "this_month", lang_key: "datespan_1" },
                { id: 2, name: "Last week", value: "last_week", lang_key: "datespan_2" },
                { id: 3, name: "Last month", value: "last_month", lang_key: "datespan_3" },
                { id: 4, name: "Last 60 days", value: "last_60", lang_key: "datespan_4" },
                { id: 5, name: "Last 90 days", value: "last_90", lang_key: "datespan_5" }
            ],
            selected_time_span: { id: 1, name: "This month (default)", value: "this_month", lang_key: "datespan_1" },
            doughnuts: {},
            doughnut: {
                legend: {
                    display: false
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                tooltips: {
                    enabled: false,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            tooltipEl.style.backgroundColor = "#FFFFFF";
                            tooltipEl.style.borderRadius = "5px";
                            tooltipEl.style.boxShadow = "0 3px 30px -5px rgba(0,0,0,.1)";
                            tooltipEl.style.fontWeight = "bold";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);

                            var innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                },
                cutoutPercentage: 55,
            },
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.init.user();

    }

    init = {
        user: async () => {
            if (userRegister.get()) {
                await this.promisedSetState({
                    user: userRegister.get(),
                    whitelabel_settings: { ...userRegister.get().whitelabel },
                })
            }
            if (Object.keys(this.state.user).length > 0 && this.state.user.id) {
                this.functions.populateDashboard(this.state.selected_time_span.value);
            }
        },
    };

    functions = {
        populateDashboard: async (timeSpan, isMobile) => {
            try {
                // CHECK WHAT CARDS CAN BE SHOWN
                let allowed_cards = [];
                const keys = ["show_impressions", "show_clicks", "show_ctr", "show_spend", "show_cpc"];

                if (Object.keys(this.state.whitelabel_settings).length > 0) {
                    keys.forEach(key => {
                        if (this.state.whitelabel_settings[key]) {
                            allowed_cards.push(key.slice(5).toLowerCase());
                        }
                    });
                }

                // FILTER STATE CARDS
                let filtered_cards = this.state.cards.filter(card => {
                    return allowed_cards.includes(card.value)
                })

                // GET METRICS
                let metrics = await this.calls.getMetrics(timeSpan);
                if (metrics && metrics.data && metrics.data.status === 200) {

                    let populated_cards = [];

                    // IF THERE ARE LESS THAN 5 CARDS, POPULATE ONLY THE CARDS THAT ARE AVAILABLE ELSE POPULATE ALL CARDS
                    populated_cards = filtered_cards.length !== 5 ? filtered_cards.map((card, index) => {
                        return {
                            ...card,
                            total: metrics && metrics.data && metrics.data.meta && metrics.data.meta.total[card.value].toString()
                        }
                    }) : this.state.cards.map((card, index) => {
                        return {
                            ...card,
                            total: metrics && metrics.data && metrics.data.meta && metrics.data.meta.total[card.value].toString()
                        }
                    })
                    await this.promisedSetState({
                        cards: populated_cards,
                        doughnuts: metrics.data.meta,
                        chart: metrics.data.data,
                    })

                    if (!isMobile) {
                        await this.promisedSetState({
                            show_graph: window.innerWidth < 500 ? false : true
                        })
                    }
                }

                // GET ORDERS
                let orders = await this.calls.getOrders();
                if (orders.meta !== 0) {
                    await this.promisedSetState({
                        orders: orders.data,
                        loading: false
                    })
                }

            } catch (error) {
                console.log(error)
            }
        },

    };

    calls = {
        getOrders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/listOrders?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" && ("&search=" + this.state.search))
            return apiRegister.call(options, url);
        },
        getReports: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/listReports?page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search !== "" && ("&search=" + this.state.search));
            return apiRegister.call(options, url);
        },
        getMetrics: (timeSpan) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/client/getMetrics?timeSpan=" + timeSpan;
            return apiRegister.call(options, url);
        },
    }

    renders = {
        selectedTimeSpan: () => {
            try {
                if (this.state.selected_time_span) {
                    console.log("first", this.state.selected_time_span)
                    return { ...this.state.selected_time_span, name: this.translate(this.state.selected_time_span.lang_key) }
                } else {
                    return { id: 0, name: "Select ...", value: "" }
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: "" }
            }
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    translate = (text) => {
        let lang;
        let key = "dashboard"

        if (this.state.user && this.state.user.language) {
            lang = this.state.user.language.value
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            lang = this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage ? this.state.whitelabel_settings.defaultLanguage.value : { id: 1, name: "English", value: "en" }
        }

        if (!this.state.whitelabel_settings.defaultLanguage) {
            return translations.en[key][text]
            // IF THERE IS A USER SELECTED LANGUAGE 
        } else if (this.state.user && this.state.user.language) {
            return translations[lang][key][text]
            // IF THERE NO USER SELECTED LANGUAGE, BUT THERE IS DEFAULT LANGUAGE THEN USE DEFAULT LANGUAGE
        } else if (this.state.user && !this.state.user.language && this.state.whitelabel_settings && this.state.whitelabel_settings.defaultLanguage) {
            return translations[lang][key][text]
        }

    }

    truncText(string, maxLength) {
        if (string.length > maxLength) {
            return string.substring(0, maxLength) + "..."
        }
        else {
            return string
        }
    }

    render() {
        let t = this.translate;
        return (
            <>

                {
                    this.state.loading &&
                    <div className="h-screen flex flex-col justify-center items-center">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">{t("loading")} ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <>
                        <ClientTopNavigation
                            breadcrumb={"Dashboard"}
                            showUser={true}
                            onTimeSpanChange={async (timeSpan) => {
                                await this.promisedSetState({
                                    selected_time_span: timeSpan
                                })
                                await this.functions.populateDashboard(timeSpan.value);
                            }
                            }
                        />
                        <div className='flex w-full text-sm bg-white shadow px-4 pb-4 pt-2 md:hidden'>
                            <div className='w-full'>
                                <DropdownTailwind
                                    options={this.state.time_spans.map((item) => {
                                        return { ...item, name: t(item.lang_key) }
                                    })
                                    }
                                    selected={this.renders.selectedTimeSpan()}
                                    small={true}
                                    smallText={true}
                                    onChange={async (timeSpan) => {
                                        await this.promisedSetState({
                                            selected_time_span: timeSpan
                                        })
                                        await this.functions.populateDashboard(timeSpan.value, true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col space-y-4 px-4 py-4 md:py-0 h-screen'>

                            {/* MOBILE VIEW  */}
                            <div className='grid grid-cols-2 gap-4 md:hidden' >
                                {
                                    this.state.cards.map((card, index) => {
                                        return (
                                            <div
                                                key={index}
                                                onClick={() => this.promisedSetState({ selected_metric: card })}
                                                className={`${this.state.cards.length % 2 !== 0 && index === this.state.cards.length - 1 ?
                                                    "col-span-2" : "col-span-1"} flex flex-col justify-start items-center bg-white rounded-md shadow p-2 border border-transparent ${this.state.selected_metric.id === card.id && "border-blue-600 duration-200 ease-in-out"}`}>
                                                <div className='font-bold text-sm'>
                                                    {card.total}
                                                </div>
                                                <div className='text-sm'>
                                                    {card.name}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/* NON MOBILE VIEW  */}
                            {
                                <div className={`hidden ${this.state.cards.length === 5 ? "md:grid grid-cols-5 gap-4" : "md:flex space-x-4 justify-between"}`}>
                                    {
                                        this.state.cards.map((card, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => this.promisedSetState({ selected_metric: card })}
                                                    className={`col-span-1 w-full flex md:flex-col xl:flex-row xl:justify-between justify-start items-center bg-white rounded-md shadow cursor-pointer p-2 xl:p-4 border border-transparent hover:border-blue-600 duration-200 ease-in-out ${this.state.selected_metric.id === card.id && "border-blue-600 hover:border-blue-600"}`}>
                                                    <div className='font-bold text-sm xl:text-xl xl:flex xl:flex-col'>
                                                        {card.total}
                                                        <span className="hidden xl:block text-xl font-medium">{card.name}</span>
                                                    </div>
                                                    <div className='text-sm xl:hidden'>
                                                        <span>{card.name}</span>
                                                    </div>
                                                    <div className='pt-2'>
                                                        {
                                                            (card.total === "0" || card.total === "0.00") &&
                                                            <div>
                                                                <ChartPieIcon className="h-20 w-20 stroke-1"
                                                                    style={this.state.whitelabel_settings.color_accent !== "" && { color: "#" + this.state.whitelabel_settings.color_accent }}
                                                                />
                                                            </div>
                                                        }
                                                        {
                                                            (card.total !== "0" && card.total !== "0.00") &&
                                                            <Doughnut
                                                                width={100}
                                                                height={100}
                                                                options={this.state.doughnut}
                                                                data={this.state.doughnuts[card.value]}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            }

                            {console.log("total", this.state.cards[0].total, typeof this.state.cards[0].total)}
                            {
                                this.state.cards[0].total === "0" &&
                                <div className='text-sm shadow bg-white rounded-lg p-2 md:p-4 select-none'>
                                    {t("no_graph")}
                                </div>
                            }

                            {
                                this.state.cards[0].total !== "0" &&
                                <div>

                                    <div className={`text-sm shadow bg-white rounded-lg p-2 md:p-4 cursor-pointer select-none`}
                                        onClick={async () => {
                                            await this.promisedSetState({
                                                show_graph: !this.state.show_graph
                                            })
                                        }}
                                    >
                                        <div className={`flex justify-between items-center select-none ${this.state.show_graph && "mb-2"}`}>
                                            <div className='flex justify-between items-center w-full'>
                                                <div className={`text-sm duration-200 ease-in-out}`}>
                                                    {this.state.selected_metric && this.state.selected_metric.name} {t("graph")}
                                                </div>
                                            </div>
                                            <span>
                                                {
                                                    !this.state.show_graph &&
                                                    <ChevronDownIcon className="h-5 w-5" />
                                                }
                                                {
                                                    this.state.show_graph &&
                                                    <ChevronUpIcon className="h-5 w-5" />
                                                }
                                            </span>
                                        </div>
                                        {
                                            <SlideDown closed={!this.state.show_graph}>
                                                <div className={`flex flex-col duration-500 ease-in-out ${!this.state.show_graph ? "opacity-0" : "opacity-100"}`}>
                                                    <div className='w-full'>
                                                        <Line
                                                            options={{
                                                                tooltips: {
                                                                    mode: 'index'
                                                                },
                                                                maintainAspectRatio: false,
                                                                legend: {
                                                                    display: false
                                                                },
                                                                layout: {
                                                                    padding: {
                                                                        left: 0,
                                                                        right: 0,
                                                                        top: 0,
                                                                        bottom: 0
                                                                    }
                                                                },
                                                                scales: {
                                                                    yAxes: [{
                                                                        ticks: {
                                                                            fontSize: 8,
                                                                            beginAtZero: true,
                                                                            precision: 0,
                                                                        }
                                                                    }],
                                                                    xAxes: [{
                                                                        ticks: {
                                                                            fontSize: 8
                                                                        }
                                                                    }]
                                                                }
                                                            }}
                                                            data={this.state.chart ? this.state.chart[this.state.selected_metric.value] : {}}
                                                        />
                                                    </div>
                                                </div>
                                            </SlideDown>
                                        }
                                    </div>

                                </div>
                            }

                            {/* ORDERS */}
                            {
                                this.state.orders.length > 0 &&
                                <div className='text-left text-sm'>
                                    {t("text_1")}
                                </div>
                            }

                            {
                                this.state.orders.length === 0 &&
                                <>
                                    <div className=" flex flex-col justify-center items-center h-full">
                                        <CubeIcon className="w-10 h-10 text-gray-500" />
                                        <div className="font-semibold mt-3">{t("no_orders")}</div>
                                    </div>
                                </>
                            }

                            <div className='flex flex-col space-y-4 md:grid md:space-y-0 grid-cols-3 md:gap-4'>
                                {
                                    this.state.orders.length > 0 && this.state.orders.map((order, index) => {
                                        return (
                                            <div
                                                index={index}
                                                onClick={() => {
                                                    window.open("/client/order/" + order.id, "_blank");
                                                }}
                                                className='flex flex-col bg-white rounded-md shadow py-2 px-4 justify-between items-center md:py-4'
                                            >
                                                <div className='flex justify-between items-center w-full'>
                                                    <div className="text-sm font-medium">
                                                        {this.truncText(order.name, 40)}
                                                    </div>
                                                    <div className='bg-gray-100 rounded-full p-2 ml-2'
                                                        style={this.state.whitelabel_settings.color_accent !== "" && { backgroundColor: "#" + this.state.whitelabel_settings.color_accent }}
                                                    >
                                                        <div>
                                                            <ChevronRightIcon className="h-4 w-4 text-gray-700" aria-hidden="true"
                                                                style={this.state.whitelabel_settings.color_font !== "" && { color: "#" + this.state.whitelabel_settings.color_font }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex w-full mt-1 md:mt-2'>
                                                    {/* STATUS PILLS */}
                                                    <div className='flex w-full'>
                                                        {
                                                            (order.clientStatus.id === 1 || order.clientStatus.id === 4) &&
                                                            <div className='py-1 px-2 rounded-full bg-gray-100 border border-gray-400 text-xs text-gray-400 font-semibold'>
                                                                {order.clientStatus ? order.clientStatus.name : "Error"}
                                                            </div>
                                                        }
                                                        {
                                                            order.clientStatus.id === 5 &&
                                                            <div className='py-1 px-2 rounded-full bg-orange-100 border border-orange-400 text-xs text-orange-400 font-semibold'>
                                                                {order.clientStatus ? order.clientStatus.name : "Error"}
                                                            </div>
                                                        }
                                                        {
                                                            order.clientStatus.id === 2 &&
                                                            <div className='py-1 px-2 rounded-full bg-red-100 border border-red-400 text-xs text-red-400 font-semibold'>
                                                                {order.clientStatus ? order.clientStatus.name : "Error"}
                                                            </div>
                                                        }
                                                        {
                                                            order.clientStatus.id === 3 &&
                                                            <div className='py-1 px-2 rounded-full bg-green-100 border border-green-400 text-xs text-green-400 font-semibold'>
                                                                {order.clientStatus ? order.clientStatus.name : "Error"}
                                                            </div>
                                                        }
                                                    </div>
                                                    {/* CHANNEL ICONS */}
                                                    <div className='flex'>
                                                        {
                                                            order.channels && order.channels.adform &&
                                                            <div
                                                                className="w-7 h-7 bg-adform-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.facebook &&
                                                            <div
                                                                className="w-7 h-7 bg-facebook-500 rounded-full p-2 border-2 border-white -ml-2">
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.instagram &&
                                                            <div
                                                                className={'w-7 h-7 bg-instagram-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/instagram_icon.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.linkedin &&
                                                            <div
                                                                className={'w-7 h-7 bg-linkedin-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.google &&
                                                            <div
                                                                className={'w-7 h-7 bg-google-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.google_shopping &&
                                                            <div
                                                                className={'w-7 h-7 bg-googleshopping-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google-shopping.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.tiktok &&
                                                            <div
                                                                className={'w-7 h-7 bg-black rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.twitter &&
                                                            <div
                                                                className={'w-7 h-7 bg-twitter-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.google_analytics &&
                                                            <div
                                                                className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/google_analytics_icon.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.google_analytics_4 &&
                                                            <div
                                                                className={'w-7 h-7 bg-googleanalytics-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/ga_icon.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.snapchat &&
                                                            <div
                                                                className={'w-7 h-7 bg-snapchat-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/snapchat.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.bing &&
                                                            <div
                                                                className={'w-7 h-7 bg-bing-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.bidtheatre &&
                                                            <div
                                                                className={'w-7 h-7 bg-bidtheatre-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/bidtheatre_icon.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.dv360 &&
                                                            <div
                                                                className={'w-7 h-7 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/dv360-logo.svg') + ")" }}></div>
                                                            </div>
                                                        }
                                                        {
                                                            order.channels && order.channels.cm360 &&
                                                            <div
                                                                className={'w-7 h-7 bg-dv360-500 rounded-full p-2 border-2 border-white -ml-2'}>
                                                                <div
                                                                    className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                    style={{ backgroundImage: "url(" + require('../assets/images/cm360-logo.png') + ")" }}></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                }
                            </div>





                        </div >

                    </>
                }

            </>
        )
    }
}

export default ClientDashboard;