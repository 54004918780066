import React, { Component, Fragment } from 'react';
import { ArrowDownIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import { clientRegister } from '../services/clientRegister';
import { userRegister } from '../services/userRegister';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';
import TableFilters from '../moduleFiles/tableFilters';

class OrderBudgetItems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            // columns: ["client", "start date", "type", "reference id", "created by", "budget"],
            columns: [
                { name: 'client', value: 'client', min_width: '150px' },
                { name: 'order', value: 'order', min_width: '150px' },
                { name: 'date', value: 'date', min_width: '150px' },
                { name: 'action', value: 'action', min_width: '150px' },
                //{ name: 'created by', value: 'created_by', min_width: '150px' },
                { name: 'reference id', value: 'reference', min_width: '150px' },
                { name: 'new budget', value: 'budget', min_width: '150px' },
            ],
            meta: {},
            user: {},
            limit: 10,
            page: 1,
            sort: "date",
            direction: "descending",
            client: {},
            updated: false,
        };
    }

    componentDidMount() {
        this.functions.getUser();
        this.functions.client();
        this.functions.getBudgetItems(true);
    }

    functions = {
        getUser: async () => {
            await this.promisedSetState({
                user: userRegister.get() ? userRegister.get() : {}
            });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        getBudgetItems: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let response = await this.calls.getBudgetItems();
                await this.promisedSetState({
                    loading: false,
                    loading_partial: false,
                    items: response.data,
                    meta: response.meta
                });
            } catch (error) {
                await this.promisedSetState({
                    loading: false,
                    loading_partial: false
                });
            }
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                page: 1,
                client: client
            });
            this.functions.getBudgetItems();
        }
    };

    calls = {
        getBudgetItems: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getOrdersBudgetPlan?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "");
            return apiRegister.call(options, url);
        },
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="relative flex flex-1 flex-col">

                {/*HEADER*/}
                {
                    !this.state.loading &&
                    <div className="w-full flex items-center p-4 pb-0 justify-center">
                        <div className="flex flex-1 flex-col relative">
                            <div className="text-2xl font-bold">
                                Budget items
                            </div>
                        </div>
                        <div className="flex">

                        </div>
                    </div>
                }

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading budgets ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                            <TableFilters
                                onLimit={async (value) => {
                                    await this.promisedSetState({
                                        page: 1,
                                        limit: value
                                    })
                                    this.functions.getBudgetItems();
                                }}
                                onNext={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.getBudgetItems();
                                }}
                                onPrevious={async (value) => {
                                    await this.promisedSetState({
                                        page: value
                                    })
                                    this.functions.getBudgetItems();
                                }}
                                limit={this.state.limit}
                                page={this.state.page}
                                total={this.state.meta ? this.state.meta.total : 0}
                                showPaginaton={!this.state.loading}
                                filters={[]}
                                hideUserDropdown={true}
                                breadcrumb={"Orders budget plans"}
                                buttonTypes={{}}
                                buttonLoaders={{}}
                                client={{}}
                            />

                            {/*NO ORDERS*/}
                            {
                                this.state.items.length < 1 &&
                                <div className="border-t border-gray-200 h-120 flex justify-center items-center relative">
                                    <div className="text-center flex justity-center items-center flex-col">
                                        <div className="mt-2 text-ls font-medium text-gray-500">No budget items available</div>
                                    </div>
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </div>
                            }

                            {/*TABLE*/}
                            {
                                this.state.items.length > 0 &&
                                <div className="relative h-full flex flex-1 border-t">
                                    <div className="overflow-auto absolute table-overflow w-full h-full">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_partial &&
                                            <div className="text-center absolute top-0 bottom-0 z-55 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                            <thead className="sticky z-60 bg-white top-0">
                                                <tr>
                                                    {Array.isArray(this.state.columns) &&
                                                        this.state.columns.map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.getBudgetItems();
                                                                        }
                                                                    }}
                                                                    style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100  hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 bg-gray-100 text-left text-xxs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                                >
                                                                    <div className="flex flex-row items-center">
                                                                        <div className="mr-4 flex-1 flex">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="">
                                                                                <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white tiny_table_row divide-gray-300 relative">
                                                {
                                                    Array.isArray(this.state.items) &&
                                                    this.state.items.map((item, index) => {
                                                        return (
                                                            <Fragment>
                                                                <tr
                                                                    onMouseEnter={() => {
                                                                        item.hover = true;
                                                                        this.setState({
                                                                            items: this.state.items
                                                                        })
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        item.hover = false;
                                                                        this.setState({
                                                                            items: this.state.items
                                                                        })
                                                                    }}
                                                                    className="border-b" key={item.id}>
                                                                    {
                                                                        this.state.columns.map((column, index) => {
                                                                            if (column.value === "client") {
                                                                                return (
                                                                                    <td style={{ zIndex: 0 }} className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer items-center text-xms border-gray-300"}>
                                                                                        <div className="flex flex-row items-center">
                                                                                            <div>
                                                                                                <div className="h-6 w-6 overflow-hidden rounded-md flex justify-center bg-gray-100 items-center">
                                                                                                    {
                                                                                                        item.client && item.client.logo &&
                                                                                                        <img src={item.client.logo} className="bg-cover" />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="ml-4">
                                                                                                <div className="flex flex-row items-center">
                                                                                                    {item.client ? item.client.name : "-"}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                )
                                                                            } else if (column.value === "action") {
                                                                                return (
                                                                                    <td className={((this.state.sort === column.value || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3  font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                        {item[column.value] ? item[column.value].name : ''}
                                                                                    </td>
                                                                                )
                                                                            } else if (column.value === "order") {
                                                                                return (
                                                                                    <td onClick={() => {
                                                                                        if (window.location.hostname === 'localhost') {
                                                                                            window.open("http://localhost:5001/v2/orders/" + item.order.id + "/budgetplaner");
                                                                                        } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                            window.open("https://app.adcredo.io/v2/orders/" + item.order.id + "/budgetplaner");
                                                                                        } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                            window.open("https://dev.adcredo.io/v2/orders/" + item.order.id + "/budgetplaner");
                                                                                        }
                                                                                    }} className={((this.state.sort === column.value || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap hover:text-purple-500 cursor-pointer px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                        {item[column.value] ? item[column.value].name : ''}
                                                                                    </td>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <td className={((this.state.sort === column.value || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xms border-gray-300"}>
                                                                                        {item[column.value]}
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default OrderBudgetItems;