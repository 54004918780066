import React, { Component } from 'react';
import { ArrowSmDownIcon, ArrowSmLeftIcon, ArrowSmRightIcon, ArrowSmUpIcon, CameraIcon, CheckCircleIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, GiftIcon, PlusCircleIcon, SwitchVerticalIcon, VideoCameraIcon, XCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import languages from '../assets/json/languages.json';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import DropdownTailwind from './dropdownTailwind';
import SwitchTailwind from './switchTailwind';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import PreviewGoogleSearch from '../previews/google/search';
import TextAreaTailwind from './textAreaTailwind';
import PhoneCodeWithCountry from '../assets/json/phone-countries.json';
import snippetHeaderLangueages from '../assets/json/snippet_languages.json';
import occasions from '../assets/json/occasions.json';
import UploadImageTailwind from './uploadImageTailwind';
import moment from 'moment';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/react/solid';
import { extensionGoogle } from '../validators/extensionGoogle';
const fuzzysort = require('fuzzysort');



class CreateOrderCreativeSearchExtension extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promotion_types: [
                { id: 1, name: "Monetary discount" },
                { id: 2, name: "Percent discount" },
                { id: 3, name: "Up to monetary discount" },
                { id: 4, name: "Up to percent discount" }
            ],
            price_types: [
                { id: 1, name: "Brands", value: "BRANDS" },
                { id: 2, name: "Events", value: "EVENTS" },
                { id: 3, name: "Locations", value: "LOCATIONS" },
                { id: 4, name: "Neighborhoods", value: "NEIGHBORHOODS" },
                { id: 1, name: "Product categories", value: "PRODUCT_CATEGORIES" },
                { id: 2, name: "Product tiers", value: "PRODUCT_TIERS" },
                { id: 3, name: "Services", value: "SERVICES" },
                { id: 4, name: "Services categories", value: "SERVICE_CATEGORIES" },
                { id: 4, name: "Services tiers", value: "SERVICE_TIERS" },
            ],
            price_qualifier_options: [
                { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" },
                { id: 2, name: "From", value: "FROM", preview: "From " },
                { id: 3, name: "Up to", value: "UP_TO", preview: "Up to " },
                { id: 4, name: "Average", value: "AVERAGE", preview: "Avg " }
            ],
            unit_types: [
                { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" },
                { id: 2, name: "Per hour", value: "PER_HOUR", preview: "/hr" },
                { id: 3, name: "Per day", value: "PER_DAY", preview: "/day" },
                { id: 4, name: "Per week", value: "PER_WEEK", preview: "/wk" },
                { id: 5, name: "Per month", value: "PER_MONTH", preview: "/mo" },
                { id: 6, name: "Per year", value: "PER_YEAR", preview: "/yr" },
                { id: 7, name: "Per night", value: "PER_NIGHT", preview: "/night" }
            ],

            headers: [],
            currencies: ["AED", "ARS", "AUD", "BGN", "BND", "BOB", "BRL", "CAD", "CHF", "CLP", "CNY", "COP", "CZK", "DKK", "EGP", "EUR", "FJD", "GBP", "HKD", "HRK", "HUF", "IDR", "ILS", "INR", "JPY", "KES", "KRW", "MAD", "MXN", "MYR", "NOK", "NZD", "PEN", "PHP", "PKR", "PLN", "RON", "RSD", "RUB", "SAR", "SEK", "SGD", "THB", "TRY", "TWD", "UAH", "USD", "VND", "ZAR"],
            codes: PhoneCodeWithCountry,
            languages: snippetHeaderLangueages,
            occasions: occasions,
            groups: [],
            group: {
                channel: "google",
                currency: { id: 47, name: "USD", value: "USD" },
                promotion_value: "",
                promotion_description: "",
                promotion_url: "",
                occasion: { id: 0, name: "None" },
                promotion_detail: { id: 0, name: "None", value: "" },
                promotion_detail_value: "",
                promotion: { id: 1, name: "Monetary discount" },
                promotion_start_date: null,
                promotion_end_date: null,
                tracking: false,
                tracking_options: {
                    template: "",
                    suffix: "",
                    mobile_url: ""
                },
                business_logo: "",
                business_name: "",
                price_qualifier: { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" },
                price_type: { id: 1, name: "Brands", value: "BRANDS" },
                mobile: false,
                schedule: false,
                start_date: null,
                end_date: null,
                language: { id: 0, name: "Select language" },
                header: { id: 0, name: "Select header" },
                days: [
                    { id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }
                ],
                items: [{ id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }],
                tracking_items: [{ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" }]
            },
            client: {},
            hours_options: [
                { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, { id: 2, name: "00:15", hourValue: 0, minuteValue: "FIFTEEN" }, { id: 3, name: "00:30", hourValue: 0, minuteValue: "THIRTY" }, { id: 4, name: "00:45", hourValue: 0, minuteValue: "FORTY_FIVE" },
                { id: 5, name: "01:00", hourValue: 1, minuteValue: "ZERO" }, { id: 6, name: "01:15", hourValue: 1, minuteValue: "FIFTEEN" }, { id: 7, name: "01:30", hourValue: 1, minuteValue: "THIRTY" }, { id: 8, name: "01:45", hourValue: 1, minuteValue: "FORTY_FIVE" },
                { id: 9, name: "02:00", hourValue: 2, minuteValue: "ZERO" }, { id: 10, name: "02:15", hourValue: 2, minuteValue: "FIFTEEN" }, { id: 11, name: "02:30", hourValue: 2, minuteValue: "THIRTY" }, { id: 12, name: "02:45", hourValue: 2, minuteValue: "FORTY_FIVE" },
                { id: 13, name: "03:00", hourValue: 3, minuteValue: "ZERO" }, { id: 14, name: "03:15", hourValue: 3, minuteValue: "FIFTEEN" }, { id: 15, name: "03:30", hourValue: 3, minuteValue: "THIRTY" }, { id: 16, name: "03:45", hourValue: 3, minuteValue: "FORTY_FIVE" },
                { id: 17, name: "04:00", hourValue: 4, minuteValue: "ZERO" }, { id: 18, name: "04:15", hourValue: 4, minuteValue: "FIFTEEN" }, { id: 19, name: "04:30", hourValue: 4, minuteValue: "THIRTY" }, { id: 20, name: "04:45", hourValue: 4, minuteValue: "FORTY_FIVE" },
                { id: 21, name: "05:00", hourValue: 5, minuteValue: "ZERO" }, { id: 22, name: "05:15", hourValue: 5, minuteValue: "FIFTEEN" }, { id: 23, name: "05:30", hourValue: 5, minuteValue: "THIRTY" }, { id: 24, name: "05:45", hourValue: 5, minuteValue: "FORTY_FIVE" },
                { id: 25, name: "06:00", hourValue: 6, minuteValue: "ZERO" }, { id: 26, name: "06:15", hourValue: 6, minuteValue: "FIFTEEN" }, { id: 27, name: "06:30", hourValue: 6, minuteValue: "THIRTY" }, { id: 28, name: "06:45", hourValue: 6, minuteValue: "FORTY_FIVE" },
                { id: 29, name: "07:00", hourValue: 7, minuteValue: "ZERO" }, { id: 30, name: "07:15", hourValue: 7, minuteValue: "FIFTEEN" }, { id: 31, name: "07:30", hourValue: 7, minuteValue: "THIRTY" }, { id: 32, name: "07:45", hourValue: 7, minuteValue: "FORTY_FIVE" },
                { id: 33, name: "08:00", hourValue: 8, minuteValue: "ZERO" }, { id: 34, name: "08:15", hourValue: 8, minuteValue: "FIFTEEN" }, { id: 35, name: "08:30", hourValue: 8, minuteValue: "THIRTY" }, { id: 36, name: "08:45", hourValue: 8, minuteValue: "FORTY_FIVE" },
                { id: 37, name: "09:00", hourValue: 9, minuteValue: "ZERO" }, { id: 38, name: "09:15", hourValue: 9, minuteValue: "FIFTEEN" }, { id: 39, name: "09:30", hourValue: 9, minuteValue: "THIRTY" }, { id: 40, name: "09:45", hourValue: 9, minuteValue: "FORTY_FIVE" },
                { id: 41, name: "10:00", hourValue: 10, minuteValue: "ZERO" }, { id: 42, name: "10:15", hourValue: 10, minuteValue: "FIFTEEN" }, { id: 43, name: "10:30", hourValue: 10, minuteValue: "THIRTY" }, { id: 44, name: "10:45", hourValue: 10, minuteValue: "FORTY_FIVE" },
                { id: 45, name: "11:00", hourValue: 11, minuteValue: "ZERO" }, { id: 46, name: "11:15", hourValue: 11, minuteValue: "FIFTEEN" }, { id: 47, name: "11:30", hourValue: 11, minuteValue: "THIRTY" }, { id: 48, name: "11:45", hourValue: 11, minuteValue: "FORTY_FIVE" },
                { id: 49, name: "12:00", hourValue: 12, minuteValue: "ZERO" }, { id: 50, name: "12:15", hourValue: 12, minuteValue: "FIFTEEN" }, { id: 51, name: "12:30", hourValue: 12, minuteValue: "THIRTY" }, { id: 52, name: "12:45", hourValue: 12, minuteValue: "FORTY_FIVE" },
                { id: 53, name: "13:00", hourValue: 13, minuteValue: "ZERO" }, { id: 54, name: "13:15", hourValue: 13, minuteValue: "FIFTEEN" }, { id: 55, name: "13:30", hourValue: 13, minuteValue: "THIRTY" }, { id: 56, name: "13:45", hourValue: 13, minuteValue: "FORTY_FIVE" },
                { id: 57, name: "14:00", hourValue: 14, minuteValue: "ZERO" }, { id: 58, name: "14:15", hourValue: 14, minuteValue: "FIFTEEN" }, { id: 59, name: "14:30", hourValue: 14, minuteValue: "THIRTY" }, { id: 60, name: "14:45", hourValue: 14, minuteValue: "FORTY_FIVE" },
                { id: 61, name: "15:00", hourValue: 15, minuteValue: "ZERO" }, { id: 62, name: "15:15", hourValue: 15, minuteValue: "FIFTEEN" }, { id: 63, name: "15:30", hourValue: 15, minuteValue: "THIRTY" }, { id: 64, name: "15:45", hourValue: 15, minuteValue: "FORTY_FIVE" },
                { id: 65, name: "16:00", hourValue: 16, minuteValue: "ZERO" }, { id: 66, name: "16:15", hourValue: 16, minuteValue: "FIFTEEN" }, { id: 67, name: "16:30", hourValue: 16, minuteValue: "THIRTY" }, { id: 68, name: "16:45", hourValue: 16, minuteValue: "FORTY_FIVE" },
                { id: 69, name: "17:00", hourValue: 17, minuteValue: "ZERO" }, { id: 70, name: "17:15", hourValue: 17, minuteValue: "FIFTEEN" }, { id: 71, name: "17:30", hourValue: 17, minuteValue: "THIRTY" }, { id: 72, name: "17:45", hourValue: 17, minuteValue: "FORTY_FIVE" },
                { id: 73, name: "18:00", hourValue: 18, minuteValue: "ZERO" }, { id: 74, name: "18:15", hourValue: 18, minuteValue: "FIFTEEN" }, { id: 75, name: "18:30", hourValue: 18, minuteValue: "THIRTY" }, { id: 76, name: "18:45", hourValue: 18, minuteValue: "FORTY_FIVE" },
                { id: 77, name: "19:00", hourValue: 19, minuteValue: "ZERO" }, { id: 78, name: "19:15", hourValue: 19, minuteValue: "FIFTEEN" }, { id: 79, name: "19:30", hourValue: 19, minuteValue: "THIRTY" }, { id: 80, name: "19:45", hourValue: 19, minuteValue: "FORTY_FIVE" },
                { id: 81, name: "20:00", hourValue: 20, minuteValue: "ZERO" }, { id: 82, name: "20:15", hourValue: 20, minuteValue: "FIFTEEN" }, { id: 83, name: "20:30", hourValue: 20, minuteValue: "THIRTY" }, { id: 84, name: "20:45", hourValue: 20, minuteValue: "FORTY_FIVE" },
                { id: 85, name: "21:00", hourValue: 21, minuteValue: "ZERO" }, { id: 86, name: "21:15", hourValue: 21, minuteValue: "FIFTEEN" }, { id: 87, name: "21:30", hourValue: 21, minuteValue: "THIRTY" }, { id: 88, name: "21:45", hourValue: 21, minuteValue: "FORTY_FIVE" },
                { id: 89, name: "22:00", hourValue: 22, minuteValue: "ZERO" }, { id: 90, name: "22:15", hourValue: 22, minuteValue: "FIFTEEN" }, { id: 91, name: "22:30", hourValue: 22, minuteValue: "THIRTY" }, { id: 92, name: "22:45", hourValue: 22, minuteValue: "FORTY_FIVE" },
                { id: 93, name: "23:00", hourValue: 23, minuteValue: "ZERO" }, { id: 94, name: "23:15", hourValue: 23, minuteValue: "FIFTEEN" }, { id: 95, name: "23:30", hourValue: 23, minuteValue: "THIRTY" }, { id: 96, name: "23:45", hourValue: 23, minuteValue: "FORTY_FIVE" },
                { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" }
            ],
            days: [
                { id: 1, name: "Monday", value: "MONDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 2, name: "Tuesday", value: "TUESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 3, name: "Wednesday", value: "WEDNESDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 4, name: "Thursday", value: "THURSDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 5, name: "Friday", value: "FRIDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 6, name: "Saturday", value: "SATURDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } },
                { id: 7, name: "Sunday", value: "SUNDAY", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } }
            ]
        }
    };

    async componentDidMount() {

        await this.promisedSetState({
            groups: this.props.groups,
            client: this.props.client
        });



        //IF ONLY ONE GROUP SELETED
        if (Array.isArray(this.state.groups) && this.state.groups.length === 1) {
            await this.promisedSetState({
                group: this.state.groups[0]
            });
            if (this.state.group.language.id !== 0) {
                this.functions.loadHeaders(this.state.group.language.Locale)
            }
        }
        console.log("GROUPS", this.state.group);


    }

    functions = {
        save: async () => {
            if (this.props.onSave) {
                this.props.onSave(this.state.group);
            }
        },
        loadHeaders: async (language) => {
            try {
                await this.promisedSetState({ loading_headers: true });
                let headers = [];
                snippetHeaderLangueages.map((item) => {
                    if (item.Locale === language) {
                        Object.keys(item).map((inner_item, index) => {
                            if (inner_item !== "Locale" && inner_item !== "Language" && inner_item !== "name") {
                                headers.push({ id: index, name: item[inner_item], value: item[inner_item] })
                            }
                        })
                    }
                });
                this.promisedSetState({ loading_headers: false, headers: headers })
            } catch (error) { }
        }
    };

    calls = {

    };

    renders = {
        startHour: (index) => {
            try {
                if ('days' in this.state.group) {
                    return this.state.group.days[index].start;
                } else {
                    return this.state.days[index].start;
                }
            } catch (error) {
                return 0;
            }
        },
        endHour: (index) => {
            try {
                if ('days' in this.state) {
                    return this.state.group.days[index].end;
                } else {
                    return this.state.days[index].end;
                }
            } catch (error) {
                return 0;
            }
        },
        start_end: (index) => {
            try {
                if ('days' in this.state.group) {
                    if (this.state.group.days[index].time.id === 2) {
                        return false
                    } else return true
                } else {
                    if (this.state.days[index].time.id === 2) {
                        return false
                    } else return true
                }
            } catch (error) {
                return 0;
            }
        },
        dayTime: (index) => {
            try {
                if ('days' in this.state.group) {
                    return this.state.group.days[index].time;
                } else {
                    return this.state.days[index].time;
                }
            } catch (error) {
                return 0;
            }
        },
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className="flex flex-1 h-full">
                <div className="flex flex-row flex-1">
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="text-sm font-medium mb-4 bg-white">
                            Preview extension
                        </div>
                        <div className="flex flex-1 overflow-scroll">
                            <div className="w-full relative">
                                <div className="mb-4">
                                    <PreviewGoogleSearch
                                        headlineFirst={"First headline"}
                                        headlineSecond={"Second headline"}
                                        headlineThird={"Third headline"}
                                        bodyOne={"Description 1"}
                                        bodySecond={"Description 2"}
                                        link={"https://your-website.com"}
                                        sitelinks={Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "sitelink" ? this.state.group.items : []}
                                        path1={""}
                                        path2={""}
                                        businessName={this.state.group.subtype && this.state.group.subtype.value === "business_name" ? this.state.group.business_name : false}
                                        promotion={this.state.group.subtype && this.state.group.subtype.value === "promotion" ? this.state.group : false}
                                        price={this.state.group.subtype && this.state.group.subtype.value === "price" ? this.state.group : false}
                                        header={this.state.group.header.id !== 0 ? this.state.group.header.value : "Header"}
                                        callout={Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "callout" ? this.state.group.items : []}
                                        snippet={Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "snippet" ? this.state.group.items : []}
                                        phone={Array.isArray(this.state.group.items) && this.state.group.subtype && this.state.group.subtype.value === "call" ? (this.state.group.items[(this.state.group.combi_index ? this.state.group.combi_index : 0)]) : {}}
                                        image={this.state.group.picture && this.state.group.subtype && this.state.group.subtype.value === "image" ? this.state.group.picture : false}
                                        businessLogo={this.state.group.business_logo !== "" && this.state.group.subtype && this.state.group.subtype.value === "business_logo" ? this.state.group.business_logo : false} 
                                    />
                                </div>
                                {
                                    this.state.group.subtype && this.state.group.subtype.value === "call" &&
                                    Array.isArray(this.state.group.items) &&
                                    this.state.group.items.length > 1 &&
                                    <div className="flex flex-row">
                                        <div onClick={() => {
                                            if (this.state.group.combi_index > 0) {
                                                this.state.group.combi_index = this.state.group.combi_index - 1;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }
                                        }} className={(!("combi_index" in this.state.group) || this.state.group.combi_index === 0 ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 mr-4 rounded-full flex items-center justify-center"}>
                                            <ArrowSmLeftIcon className="w-5 h-5" />
                                        </div>
                                        <div onClick={() => {
                                            if (!this.state.group.combi_index) {
                                                this.state.group.combi_index = 0;
                                            }
                                            if (this.state.group.combi_index < (this.state.group.items.length - 1)) {
                                                this.state.group.combi_index = this.state.group.combi_index + 1;
                                                this.setState({
                                                    group: this.state.group
                                                })
                                            }
                                        }} className={(this.state.group.combi_index === (this.state.group.items.length - 1) ? "bg-purple-100 cursor-not-allowed text-purple-200" : "bg-purple-100 cursor-pointer text-purple-500 hover:bg-purple-500 hover:text-white") + " h-12 w-12 rounded-full flex items-center justify-center"}>
                                            <ArrowSmRightIcon className="w-5 h-5" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="px-4">
                        <div style={{ minHeight: "100%" }} className="border-r h-full"></div>
                    </div>
                    <div className="flex flex-1 flex-col h-full pb-4">
                        <div className="text-sm font-medium mb-4">
                            Settings
                        </div>
                        <div className="flex flex-1 overflow-scroll">
                            <div className="w-full">
                                <div className="grid grid-cols-12 gap-4 mb-4 w-full">
                                    <div className="col-span-12">
                                        <DropdownTailwind
                                            label={"Extension type"}
                                            searchInput={true}
                                            locked={this.state.group.id}
                                            selected={this.state.group.subtype ? this.state.group.subtype : { id: 0, name: "Select extension" }}
                                            options={[
                                                { id: 1, name: "Call extension", value: "call" },
                                                { id: 2, name: "Sitelinks", value: "sitelink" },
                                                { id: 3, name: "Image", value: "image" },
                                                { id: 4, name: "Callout", value: "callout" },
                                                { id: 5, name: "Price", value: "price" },
                                                { id: 6, name: "Promotion", value: "promotion" },
                                                //{ id: 7, name: "Message", value: "message" },
                                                { id: 8, name: "Structured snippet", value: "snippet" },
                                                { id: 9, name: "Business name", value: "business_name" },
                                                { id: 10, name: "Business logo", value: "business_logo" }
                                            ]}
                                            onChange={async (value) => {
                                                if (value.id === 9 || value.id === 10) {
                                                    this.state.group.adgroup_not_allowed = true;
                                                } else {
                                                    this.state.group.adgroup_not_allowed = false;
                                                }
                                                if (value.id === 8 || value.id === 5) {
                                                    this.state.group.items = [
                                                        { id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } },
                                                        { id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } },
                                                        { id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }
                                                    ];
                                                } else this.state.group.items = [{ id: Math.floor((Math.random() * 9999999999) + 1), callout: "", country_code: { id: 0, name: "Select code" }, number: "", text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }];
                                                this.state.group.subtype = value;
                                                await this.promisedSetState({
                                                    group: this.state.group
                                                });
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.group.subtype && this.state.group.subtype.id !== 0 &&
                                        <div className="col-span-12">
                                            <InputTailwind
                                                error={extensionGoogle.validate(this.state.group).name}
                                                value={this.state.group.name}
                                                label={"Name"}
                                                onChange={async (value) => {
                                                    this.state.group.name = value;
                                                    this.setState({
                                                        group: this.state.group
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*NUMBERS*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "call" &&
                                        (Array.isArray(this.state.group.items) ? this.state.group.items : [{ id: Math.floor((Math.random() * 9999999999) + 1), country_code: { id: 0, name: "Select code" }, number: "" }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            error={item.country_code.id === 0 && extensionGoogle.validate(this.state.group).phone ? true : false}
                                                            label={"Number " + (index + 1)}
                                                            searchInput={true}
                                                            selected={item.country_code ? item.country_code : { id: 0, name: "Select code" }}
                                                            options={this.state.codes.map((item) => { item.name = item.country + " (+" + item.code + ")"; return item })}
                                                            onChange={async (value) => {
                                                                item.country_code = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                await this.promisedSetState({
                                                                    group: this.state.group
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-8">
                                                        <InputTailwind
                                                            error={item.number.length < 1 && extensionGoogle.validate(this.state.group).phone ? true : false}
                                                            value={item.number}
                                                            label={"Number"}
                                                            onChange={async (value) => {
                                                                item.number = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            rightLabel={Array.isArray(this.state.group.items) && this.state.group.items.length > 1}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.combi_index = 0;
                                                                this.state.group.items = this.state.group.items.filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "call" && !this.state.group.only_item &&
                                        <div onClick={() => {
                                            this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), country_code: { id: 0, name: "Select code" }, number: "" });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new number
                                        </div>
                                    }

                                    {/*SITELINKS*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "sitelink" &&
                                        (Array.isArray(this.state.group.items) ? this.state.group.items : [{ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "" }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            error={(item.url == "" || !item.url.includes("https://")) && extensionGoogle.validate(this.state.group).sitelink ? true : false}
                                                            value={item.url}
                                                            label={"Link " + (index + 1)}
                                                            placeholder={"Website (ex www.your-website.com)"}
                                                            onChange={async (value) => {
                                                                item.url = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            rightLabel={Array.isArray(this.state.group.items) && this.state.group.items.length > 1}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.combi_index = 0;
                                                                this.state.group.items = this.state.group.items.filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            error={item.text == "" && extensionGoogle.validate(this.state.group).sitelink ? true : false}
                                                            value={item.text}
                                                            placeholder={"Title (ex Contact us)"}
                                                            maxLength={25}
                                                            onChange={async (value) => {
                                                                item.text = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            value={item.description1}
                                                            maxLength={35}
                                                            placeholder={"Description 1"}
                                                            onChange={async (value) => {
                                                                item.description1 = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            value={item.description2}
                                                            maxLength={35}
                                                            placeholder={"Description 2"}
                                                            onChange={async (value) => {
                                                                item.description2 = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "sitelink" && !this.state.group.only_item &&
                                        <div onClick={() => {
                                            this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "", url: "" });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new link
                                        </div>
                                    }




                                    {/*PRICE*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "price" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Language"}
                                                    searchInput={true}
                                                    selected={this.state.group.language ? this.state.group.language : { id: 0, name: "Select language" }}
                                                    options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                    onChange={async (value) => {
                                                        this.state.group.language = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                        this.functions.loadHeaders(value.Locale);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Type"}
                                                    selected={this.state.group.price_type ? this.state.group.price_type : { id: 1, name: "Brands", value: "BRANDS" }}
                                                    options={this.state.price_types}
                                                    onChange={async (value) => {
                                                        this.state.group.price_type = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Currency"}
                                                    searchInput={true}
                                                    selected={this.state.group.currency ? this.state.group.currency : { id: 47, name: "USD", value: "USD" }}
                                                    options={this.state.currencies.map((item, index) => { return { id: index + 1, name: item, value: item } })}
                                                    onChange={async (value) => {
                                                        this.state.group.currency = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    label={"Price quilifier"}
                                                    selected={this.state.group.price_qualifier ? this.state.group.price_qualifier : { id: 1, name: "No qualifier", value: "UNSPECIFIED", preview: "" }}
                                                    options={this.state.price_qualifier_options}
                                                    onChange={async (value) => {
                                                        this.state.group.price_qualifier = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    }


                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "price" &&
                                        (Array.isArray(this.state.group.items) ? this.state.group.items : [{ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } }]).map((item, index) => {
                                            return (
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12 border-t pt-2">
                                                        <InputTailwind
                                                            error={item.text == "" && extensionGoogle.validate(this.state.group).price ? true : false}
                                                            value={item.text}
                                                            maxLength={25}
                                                            label={"Price asset"}
                                                            placeholder={"Header"}
                                                            onChange={async (value) => {
                                                                item.text = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            rightLabel={Array.isArray(this.state.group.items) && this.state.group.items.length > 3}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.items = this.state.group.items.filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            error={item.description1 == "" && extensionGoogle.validate(this.state.group).price ? true : false}
                                                            value={item.description1}
                                                            placeholder={"Discription"}
                                                            maxLength={25}
                                                            onChange={async (value) => {
                                                                item.description1 = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            value={item.number}
                                                            label={"Amount"}
                                                            type={"number"}
                                                            maxLength={35}
                                                            placeholder={"0 " + this.state.group.currency ? this.state.group.currency.name : ""}
                                                            onChange={async (value) => {
                                                                if (value < 0) value = 0;
                                                                item.number = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <DropdownTailwind
                                                            label={"Unit"}
                                                            selected={item.unit ? item.unit : { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" }}
                                                            options={this.state.unit_types}
                                                            onChange={async (value) => {
                                                                item.unit = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            error={(item.url == "" || !item.url.includes("https://")) && extensionGoogle.validate(this.state.group).price ? true : false}
                                                            value={item.url}
                                                            placeholder={"Final URL"}
                                                            onChange={async (value) => {
                                                                item.url = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={item.mobile_url}
                                                            placeholder={"Mobile final URL (optional)"}
                                                            onChange={async (value) => {
                                                                item.mobile_url = value;
                                                                if (!Array.isArray(this.state.group.items)) {
                                                                    this.state.group.items = [item];
                                                                }
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "price" &&
                                        <div onClick={() => {
                                            this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "", description1: "", description2: "", key: "", value: "", url: "", mobile_url: "", unit: { id: 1, name: "No units", value: "UNSPECIFIED", preview: "" } });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add a price asset
                                        </div>
                                    }



                                    {/*IMAGE*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "image" &&
                                        <div className="col-span-12">
                                            <UploadImageTailwind
                                                error={extensionGoogle.validate(this.state.group).picture}
                                                label={"Upload image"}
                                                client={this.state.client}
                                                onChange={(value) => {
                                                    this.state.group.picture = value.url;
                                                    this.setState({
                                                        group: this.state.group
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*BUISNESS LOGO*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "business_logo" &&
                                        <div className="col-span-12">
                                            <UploadImageTailwind
                                                error={extensionGoogle.validate(this.state.group).business_logo}
                                                label={"Upload image"}
                                                client={this.state.client}
                                                onChange={(value) => {
                                                    this.state.group.business_logo = value.url;
                                                    this.setState({
                                                        group: this.state.group
                                                    })
                                                }}
                                            />
                                        </div>
                                    }

                                    {/*CALLOUT*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "callout" &&
                                        <div className="col-span-12">
                                            {
                                                (Array.isArray(this.state.group.items) ? this.state.group.items : [{ id: Math.floor((Math.random() * 9999999999) + 1), callout: "" }]).map((item, index) => {
                                                    return (
                                                        <div className="col-span-12 mt-2 grid grid-cols-12 gap-4">
                                                            <div className="col-span-12">
                                                                <InputTailwind
                                                                    error={item.callout == "" && extensionGoogle.validate(this.state.group).callout ? true : false}
                                                                    value={item.callout}
                                                                    label={"Callout"}
                                                                    maxLength={25}
                                                                    onChange={async (value) => {
                                                                        item.callout = value;
                                                                        if (!Array.isArray(this.state.group.items)) {
                                                                            this.state.group.items = [item];
                                                                        }
                                                                        this.setState({
                                                                            group: this.state.group
                                                                        })
                                                                    }}
                                                                    rightLabel={Array.isArray(this.state.group.items) && this.state.group.items.length > 1}
                                                                    rightLabelColor={"hover:text-red-700 text-red-500"}
                                                                    rightLabelText={"Remove"}
                                                                    onRightLabelClick={() => {
                                                                        this.state.group.items = this.state.group.items.filter((callout) => { return callout.id !== item.id });
                                                                        this.setState({
                                                                            group: this.state.group
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                            {
                                                this.state.group.subtype && this.state.group.subtype.value === "callout" && this.state.group.items.length < 20 && !this.state.group.only_item &&
                                                <div onClick={() => {
                                                    this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), callout: "" });
                                                    this.setState({
                                                        group: this.state.group
                                                    });
                                                }} className="col-span-12 mt-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                    Add new callout
                                                </div>
                                            }
                                        </div>
                                    }

                                    {/*SNIPPET*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "snippet" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    error={this.state.group.language.id === 0 && extensionGoogle.validate(this.state.group).language ? true : false}
                                                    label={"Language"}
                                                    searchInput={true}
                                                    selected={this.state.group.language ? this.state.group.language : { id: 0, name: "Select language" }}
                                                    options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                    onChange={async (value) => {
                                                        this.state.group.language = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                        this.functions.loadHeaders(value.Locale);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-6">
                                                <DropdownTailwind
                                                    error={this.state.group.header.id === 0 && extensionGoogle.validate(this.state.group).header ? true : false}
                                                    label={"Header"}
                                                    selected={this.state.group.header ? this.state.group.header : { id: 0, name: "Select header" }}
                                                    options={this.state.headers}
                                                    onChange={async (value) => {
                                                        this.state.group.header = value
                                                        await this.promisedSetState({
                                                            group: this.state.group
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {
                                                (Array.isArray(this.state.group.items) ? this.state.group.items : [{ id: Math.floor((Math.random() * 9999999999) + 1), text: "" }]).map((item, index) => {
                                                    return (
                                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                                            <div className="col-span-12">
                                                                <InputTailwind
                                                                    error={item.text == "" && extensionGoogle.validate(this.state.group).snippet ? true : false}
                                                                    value={item.text}
                                                                    maxLength={25}
                                                                    label={"Structured snippet value"}
                                                                    onChange={async (value) => {
                                                                        item.text = value;
                                                                        if (!Array.isArray(this.state.group.items)) {
                                                                            this.state.group.items = [item];
                                                                        }
                                                                        this.setState({
                                                                            group: this.state.group
                                                                        })
                                                                    }}
                                                                    rightLabel={Array.isArray(this.state.group.items) && this.state.group.items.length > 3}
                                                                    rightLabelColor={"hover:text-red-700 text-red-500"}
                                                                    rightLabelText={"Remove"}
                                                                    onRightLabelClick={() => {
                                                                        this.state.group.items = this.state.group.items.filter((snippet) => { return snippet.id !== item.id });
                                                                        this.setState({
                                                                            group: this.state.group
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "snippet" && this.state.group.items.length < 10 &&
                                        <div onClick={() => {
                                            this.state.group.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), text: "" });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new values
                                        </div>
                                    }
                                    {/*PROMOTION*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "promotion" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-4">
                                                    <DropdownTailwind
                                                        label={"Promotion type"}
                                                        selected={this.state.group.promotion}
                                                        options={this.state.promotion_types}
                                                        onChange={async (value) => {
                                                            this.state.group.promotion = value
                                                            await this.promisedSetState({
                                                                group: this.state.group
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-4">
                                                    <InputTailwind
                                                        error={(this.state.group.promotion_value === "" || this.state.group.promotion_value < 1) && extensionGoogle.validate(this.state.group).promotion ? true : false}
                                                        maxLength={(this.state.group.promotion.id === 2 || this.state.group.promotion.id === 4 ? 2 : 20)}
                                                        value={this.state.group.promotion_value}
                                                        type={"number"}
                                                        label={"Value"}
                                                        onChange={async (value) => {
                                                            if (value < 0) value = 0;
                                                            this.state.group.promotion_value = value
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    (this.state.group.promotion.id === 1 || this.state.group.promotion.id === 3) &&
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Currency"}
                                                            searchInput={true}
                                                            selected={this.state.group.currency ? this.state.group.currency : { id: 47, name: "USD", value: "USD" }}
                                                            options={this.state.currencies.map((item, index) => { return { id: index + 1, name: item, value: item } })}
                                                            onChange={async (value) => {
                                                                this.state.group.currency = value
                                                                await this.promisedSetState({
                                                                    group: this.state.group
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        error={this.state.group.language.id === 0 && extensionGoogle.validate(this.state.group).language ? true : false}
                                                        label={"Language"}
                                                        searchInput={true}
                                                        selected={this.state.group.language ? this.state.group.language : { id: 0, name: "Select language" }}
                                                        options={this.state.languages.map((item, index) => { item.id = index + 1; item.name = item.Language; return item })}
                                                        onChange={async (value) => {
                                                            this.state.group.language = value
                                                            await this.promisedSetState({
                                                                group: this.state.group
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Occasion"}
                                                        selected={this.state.group.occasion ? this.state.group.occasion : { id: 0, name: "None", value: "" }}
                                                        options={this.state.occasions.map((item, index) => { return { id: index, name: item.name, value: item.value, start: item.start, end: item.end } })}
                                                        onChange={async (value) => {
                                                            this.state.group.occasion = value
                                                            await this.promisedSetState({
                                                                group: this.state.group
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            {
                                                this.state.group.occasion && this.state.group.occasion.id > 0 && this.state.group.occasion.start !== "" &&
                                                <div className="col-span-12">
                                                    <div className="text-gray-600 text-xs">
                                                        {this.state.group.occasion.name} promotions are only eligible to show from {this.state.group.occasion.start} - {this.state.group.occasion.end}.
                                                        You can narrow this date range in "Advanced option" below.
                                                    </div>
                                                </div>
                                            }

                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        error={this.state.group.promotion_description === "" && extensionGoogle.validate(this.state.group).description ? true : false}
                                                        label={"Item"}
                                                        value={this.state.group.promotion_description}
                                                        maxLength={20}
                                                        onChange={async (value) => {
                                                            this.state.group.promotion_description = value
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-12">
                                                    <InputTailwind
                                                        error={(this.state.group.promotion_url === "" || !this.state.group.promotion_url.includes("https://")) && extensionGoogle.validate(this.state.group).link ? true : false}
                                                        value={this.state.group.promotion_url}
                                                        label={"Final url"}
                                                        onChange={async (value) => {
                                                            this.state.group.promotion_url = value
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-span-12 grid grid-cols-12 gap-4">
                                                <div className="col-span-6">
                                                    <DropdownTailwind
                                                        label={"Promotion details"}
                                                        selected={this.state.group.promotion_detail}
                                                        options={[{ id: 0, name: "None", value: "" }, { id: 1, name: "On orders over", value: "On orders over" }, { id: 2, name: "Promo code", value: "Code" }]}
                                                        onChange={async (value) => {
                                                            this.state.group.promotion_detail = value
                                                            await this.promisedSetState({
                                                                group: this.state.group
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    this.state.group.promotion_detail.id !== 0 &&
                                                    <div className="col-span-6">
                                                        <InputTailwind
                                                            maxLength={20}
                                                            value={this.state.group.promotion_detail_value}
                                                            label={this.state.group.promotion_detail.name}
                                                            onChange={async (value) => {
                                                                this.state.group.promotion_detail_value = value
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-span-12">
                                                <div className="col-span-6">
                                                    <div className="grid grid-cols-6 gap-4">
                                                        <div className="col-span-3">
                                                            <InputDatepickerTailwind
                                                                label={"Displayed start date"}
                                                                labelRight={this.state.group.promotion_start_date ? "Enabled" : "Disabled"}
                                                                placeholder={!this.state.group.promotion_start_date ? "No start date" : ""}
                                                                disabled={!this.state.group.promotion_start_date}
                                                                value={this.state.group.promotion_start_date ? moment(this.state.group.promotion_start_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                                                onChange={(value) => {
                                                                    this.state.group.promotion_start_date = value;
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                                onLabelRightClick={(value) => {
                                                                    if (this.state.group.promotion_start_date) {
                                                                        this.state.group.promotion_start_date = null;
                                                                    } else {
                                                                        this.state.group.promotion_start_date = moment().format("YYYY-MM-DD");
                                                                    }
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-span-3">
                                                            <InputDatepickerTailwind
                                                                label={"Displayed end date"}
                                                                labelRight={this.state.group.promotion_end_date ? "Enabled" : "Disabled"}
                                                                placeholder={!this.state.group.promotion_end_date ? "No end date" : ""}
                                                                disabled={!this.state.group.promotion_end_date}
                                                                value={moment(this.state.group.promotion_end_date).format("YYYY-MM-DD")}
                                                                onChange={(value) => {
                                                                    this.state.group.promotion_end_date = value;
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                                onLabelRightClick={(value) => {
                                                                    if (this.state.group.promotion_end_date) {
                                                                        this.state.group.promotion_end_date = null;
                                                                    } else {
                                                                        let new_date = moment(this.state.group.promotion_start_date).add(1, 'days');
                                                                        this.state.group.promotion_end_date = moment(new_date).format("YYYY-MM-DD");
                                                                    }
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/*BUISNESSNAME*/}
                                    {
                                        this.state.group.subtype && this.state.group.subtype.value === "business_name" &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div className="col-span-12">
                                                <InputTailwind
                                                    error={this.state.group.business_name === "" && extensionGoogle.validate(this.state.group).business_name ? true : false}
                                                    label={"Business name"}
                                                    value={this.state.group.business_name}
                                                    maxLength={20}
                                                    onChange={async (value) => {
                                                        this.state.group.business_name = value
                                                        this.setState({
                                                            group: this.state.group
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.group.subtype && (this.state.group.subtype.value === "sitelink" || this.state.group.subtype.value === "promotion" || this.state.group.subtype.value === "price") &&
                                        <div className="col-span-12 grid grid-cols-12 gap-4">
                                            <div onClick={() => {
                                                if (this.state.group.tracking === true) {
                                                    this.state.group.tracking = false;
                                                } else {
                                                    this.state.group.tracking = true;
                                                }

                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }} className="col-span-12 flex flex-row items-center font-medium text-xs cursor-pointer text-purple-500 hover:text-purple-700">
                                                Url options
                                                {
                                                    this.state.group.tracking === true &&
                                                    <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                                }
                                                {
                                                    this.state.group.tracking === false &&
                                                    <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                                }
                                            </div>

                                            {
                                                this.state.group.tracking &&
                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={this.state.group.tracking_options.template}
                                                            label={"Tracking template"}
                                                            onChange={async (value) => {
                                                                this.state.group.tracking_options.template = value
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            value={this.state.group.tracking_options.suffix}
                                                            label={"Final URL suffix"}
                                                            onChange={async (value) => {
                                                                this.state.group.tracking_options.suffix = value
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        (Array.isArray(this.state.group.tracking_items) ? this.state.group.tracking_items : [{ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" }]).map((item, index) => {
                                                            return (
                                                                <div className="col-span-12 grid grid-cols-12 gap-4">
                                                                    <div className="col-span-3">
                                                                        <InputTailwind
                                                                            value={item.key}
                                                                            label={"Key"}
                                                                            onChange={async (value) => {
                                                                                item.key = value;
                                                                                if (!Array.isArray(this.state.group.tracking_items)) {
                                                                                    this.state.group.tracking_items = [item];
                                                                                }
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-9">
                                                                        <InputTailwind
                                                                            value={item.value}
                                                                            label={"Value"}
                                                                            onChange={async (value) => {
                                                                                item.value = value;
                                                                                if (!Array.isArray(this.state.group.tracking_items)) {
                                                                                    this.state.group.tracking_items = [item];
                                                                                }
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                            rightLabel={Array.isArray(this.state.group.tracking_items) && this.state.group.tracking_items.length > 1}
                                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                                            rightLabelText={"Remove"}
                                                                            onRightLabelClick={() => {
                                                                                this.state.group.tracking_items = this.state.group.tracking_items.filter((url) => { return url.id !== item.id });
                                                                                this.setState({
                                                                                    group: this.state.group
                                                                                })
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                    <div className="col-span-12">
                                                        <div className="inline-flex">
                                                            <div onClick={() => {
                                                                this.state.group.tracking_items.push({ id: Math.floor((Math.random() * 9999999999) + 1), key: "", value: "" });
                                                                this.setState({
                                                                    group: this.state.group
                                                                });
                                                            }} className="bg-purple-100 flex items-center flex-row rounded-full px-4 py-2 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                                                Add new parameter
                                                                <PlusCircleIcon className="w-3 h-3 ml-1" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {

                                                        this.state.group.subtype.value !== "price" &&
                                                        <div onClick={() => {
                                                            if (this.state.group.mobile === true) {
                                                                this.state.group.mobile = false;
                                                            } else {
                                                                this.state.group.mobile = true;
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            });
                                                        }} className="col-span-12 flex flex-row items-center font-medium text-xxs cursor-pointer text-purple-500 hover:text-purple-700">

                                                            {
                                                                this.state.group.mobile === true &&
                                                                <CheckCircleIcon className="w-3 h-3 mr-1" />
                                                            }
                                                            {
                                                                this.state.group.mobile === false &&
                                                                <XCircleIcon className="w-3 h-3 mr-1" />
                                                            }
                                                            Use a different final URL for mobile
                                                        </div>
                                                    }
                                                    {
                                                        this.state.group.mobile &&
                                                        <div className="col-span-12">
                                                            <InputTailwind
                                                                value={this.state.group.tracking_options.mobile_url}
                                                                label={"Mobile URL"}
                                                                onChange={async (value) => {
                                                                    this.state.group.tracking_options.mobile_url = value
                                                                    this.setState({
                                                                        group: this.state.group
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.group.subtype && this.state.group.subtype.value !== "snippet" && this.state.group.subtype.value !== "price" && this.state.group.subtype.value !== "business_logo" && this.state.group.subtype.value !== "business_name" &&
                                        <div className="col-span-12 mt-2">
                                            <div onClick={() => {
                                                if (this.state.group.schedule === true) {
                                                    this.state.group.schedule = false;
                                                } else {
                                                    this.state.group.schedule = true;
                                                }

                                                this.setState({
                                                    group: this.state.group
                                                });
                                            }} className="col-span-12 flex flex-row items-center font-medium text-xs cursor-pointer text-purple-500 hover:text-purple-700">
                                                Advanced options
                                                {
                                                    this.state.group.schedule === true &&
                                                    <ChevronDoubleUpIcon className="w-3 h-3 ml-2" />
                                                }
                                                {
                                                    this.state.group.schedule === false &&
                                                    <ChevronDoubleDownIcon className="w-3 h-3 ml-2" />
                                                }
                                            </div>
                                        </div>
                                    }



                                    {
                                        this.state.group.schedule && this.state.group.subtype && this.state.group.subtype.value !== "snippet" &&
                                        <div className="col-span-12 mt-2">
                                            <div className="grid grid-cols-6 gap-4">
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"Start date"}
                                                        labelRight={this.state.group.start_date ? "Enabled" : "Disabled"}
                                                        placeholder={!this.state.group.start_date ? "No start date" : ""}
                                                        disabled={!this.state.group.start_date}
                                                        value={this.state.group.start_date ? moment(this.state.group.start_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                                                        onChange={(value) => {
                                                            this.state.group.start_date = value;
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        onLabelRightClick={(value) => {
                                                            if (this.state.group.start_date) {
                                                                this.state.group.start_date = null;
                                                            } else {
                                                                this.state.group.start_date = moment().format("YYYY-MM-DD");
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-span-3">
                                                    <InputDatepickerTailwind
                                                        label={"End date"}
                                                        labelRight={this.state.group.end_date ? "Enabled" : "Disabled"}
                                                        placeholder={!this.state.group.end_date ? "No end date" : ""}
                                                        disabled={!this.state.group.end_date}
                                                        value={moment(this.state.group.end_date).format("YYYY-MM-DD")}
                                                        onChange={(value) => {
                                                            this.state.group.end_date = value;
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                        onLabelRightClick={(value) => {
                                                            if (this.state.group.end_date) {
                                                                this.state.group.end_date = null;
                                                            } else {
                                                                let new_date = moment(this.state.group.start_date).add(1, 'days');
                                                                this.state.group.end_date = moment(new_date);
                                                            }
                                                            this.setState({
                                                                group: this.state.group
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    }




                                    {
                                        this.state.group.schedule && this.state.group.subtype && this.state.group.subtype.value !== "snippet" &&
                                        this.state.group.days.map((item, index) => {
                                            return (
                                                <div className="col-span-12 mt-2 grid grid-cols-12 gap-4">

                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Day"}
                                                            selected={item}
                                                            options={this.state.days.filter((inner_item) => {
                                                                let buffer = true;
                                                                this.state.group.days.map((inner_inner_item) => {
                                                                    if (inner_inner_item.value === inner_item.value) {
                                                                        buffer = false
                                                                    }
                                                                })
                                                                return buffer
                                                            })}
                                                            onChange={(value) => {
                                                                this.state.group.days[index] = value;
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                            rightLabel={Array.isArray(this.state.group.days) && this.state.group.days.length > 1}
                                                            rightLabelColor={"hover:text-red-700 text-red-500"}
                                                            rightLabelText={"Remove"}
                                                            onRightLabelClick={() => {
                                                                this.state.group.days = this.state.group.days.filter((number) => { return number.id !== item.id });
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Start time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.startHour(index)}
                                                            options={this.state.hours_options.slice(0, this.state.group.days ? this.state.group.days[index].end.id : -1)}
                                                            onChange={(value) => {
                                                                this.state.group.days[index].start = value;
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"End time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.endHour(index)}
                                                            options={this.state.hours_options.slice(this.state.group.days ? this.state.group.days[index].start.id : 1, this.state.hours_options.length)}
                                                            onChange={(value) => {
                                                                this.state.group.days[index].end = value;
                                                                this.setState({
                                                                    group: this.state.group
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })


                                    }

                                    {
                                        this.state.group.subtype && this.state.group.subtype.value !== "snippet" && this.state.group.schedule && this.state.group.days.length < 7 &&
                                        <div onClick={() => {
                                            this.state.group.days.push({ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } });
                                            this.setState({
                                                group: this.state.group
                                            });
                                        }} className="col-span-12 mt-4 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                            Add new schedule
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateOrderCreativeSearchExtension;
