import React, { Component } from 'react';
import InputTailwind from "./inputTailwind";
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from "../services/tokenRegister";
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import currencies from "../assets/json/currencies.json";
import DropdownTailwind from "./dropdownTailwind";
import WarningModalTailwind from "./warningModalTailwind";
import SwitchTailwind from "./switchTailwind";
import CopyButton from './copyButton';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
//import OauthPopup from "react-oauth-popup";

class AccountSnapchat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            me: {},
            agency: {},
            agency_user: {},
            is_manager: false,
            loading: false,
            loading_managers: false,
            loading_adaccounts: false,
            loading_account: false,
            manager: {},
            managers: [],
            adaccount: {},
            adaccounts: [],
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            healthcheck: {},
            owner: false,
            disabled: false,
            throttling: {},
            syncMessage: "",
            syncManagers: false,
            currency: {},
            currencies: currencies
        }
    };

    snap = {
        "client_id": "6f161c8c-dc30-4d02-9555-23d29435fb1a",
        "redirect_url": "https://app.adcredo.io/callback/snapchat"
    }

    url = `https://accounts.snapchat.com/login/oauth2/authorize?` +
        `response_type=code&client_id=${this.snap.client_id}` +
        `&redirect_uri=${this.snap.redirect_url}` +
        `&scope=snapchat-marketing-api snapchat-profile-api&state=wmKkg0TWgppW8PTBZ20sldUmF7hwvU`;

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me,
            agency: this.props.agency ? this.props.agency : {},
            agency_user: this.props.agencyUser ? this.props.agencyUser : {},
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.snapchat && !this.state.agency.snapchat) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    async componentWillReceiveProps(nextprops) {
        await this.promisedSetState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me,
            agency: nextprops.agency ? nextprops.agency : {},
            agency_user: nextprops.agencyUser ? nextprops.agencyUser : {},
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    componentWillUnmount() {

    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true
                });
                await self.calls.connect({
                    channel: "snapchat",
                    token: token
                });
                if (this.props.onLogin) {
                    this.props.onLogin()
                }
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ snapchat: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "snapchat"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ snapchat: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.adaccount.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    manager: this.state.manager,
                    adaccount: this.state.adaccount
                });
                this.state.client.snapchat = {
                    defaultAgent: this.state.agent,
                    snapchatBusinessManagerId: this.state.manager.id,
                    snapchatBusinessManagerName: this.state.manager.name,
                    snapchatAdAccountId: this.state.adaccount.id,
                    snapchatAdAccountName: this.state.adaccount.name,
                    currency: this.state.currency.id
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    currency: {},
                    adaccount: {},
                    manager: {},
                    client: this.state.client
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.client.snapchat);
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.snapchat;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    currency: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        connectToAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                let response = await this.calls.connectToAgency({
                    channel: 'snapchat'
                });
                this.state.agency.snapchat = {
                    snapchat_connected: response.data.snapchat_connected,
                    matching_managers: response.data.matching_managers,
                    matching_accounts: response.data.matching_accounts,
                    campaigns: response.data.campaigns,
                    authenticated: true
                };
                this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromAgency: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromAgency({
                    channel: 'snapchat'
                });
                this.state.agency.snapchat = { authenticated: false }
                await this.promisedSetState({
                    manager: {},
                    agency: this.state.agency
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        },
        listManagers: async () => {
            await this.promisedSetState({
                loading_managers: true
            });
            try {
                let response = await this.calls.listManagers();
                await this.promisedSetState({
                    managers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_managers: false
            });
        },
        listAdaccounts: async () => {
            await this.promisedSetState({
                loading_adaccounts: true
            });
            try {
                let response = await this.calls.listAdaccounts();
                await this.promisedSetState({
                    adaccounts: response.data
                });

            } catch (error) { }
            await this.promisedSetState({
                loading_adaccounts: false
            });
        },
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/snapchat/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/snapchat/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        connectToAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adcredo/connectAgencyAccount";
            return apiRegister.call(options, url);
        },
        disconnectFromAgency: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyAccount";
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=snapchat";
            return apiRegister.call(options, url);
        },
        listManagers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listBusinessManagers?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        listAdaccounts: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/snapchat/listAdaccounts?client=" + this.state.client.id + "&manager=" + this.state.manager.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }



    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + ""}>

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={!!this.state.remove}
                    title={"Sign out Snapchat"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-snapchat-500 rounded-md flex justify-center items-center">
                        <div className="w-4 h-4">
                            <div className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(https://s3.amazonaws.com/bitmoji-sdk-images/logo-snapchat.svg)" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Snapchat {(this.state.client && this.state.client.snapchat) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.snapchat) &&
                            <div className="text-xs text-gray-500">
                                Connect Snapchat to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.snapchat) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.snapchat) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                    if (this.state.is_manager && !this.state.client.snapchat) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            (
                                !this.state.user.id &&
                                this.state.client.snapchat &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.snapchat && this.state.client.snapchat.defaultAgent && this.state.client.snapchat.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.snapchat}
                                onSwitch={async () => {
                                    if (!this.state.client.snapchat) {
                                        this.state.client.snapchat = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>
                    {
                        !this.state.agency.snapchat &&
                        !this.state.client.id &&
                        (!this.state.user.snapchat || (this.state.user.snapchat && !this.state.user.snapchat.authenticated)) &&
                        (window.location.hostname.indexOf("localhost") !== -1 || window.location.hostname.indexOf("dev.") !== -1) &&
                        <div className="mt-5">
                            <InputTailwind
                                label={"Auth code (only visible on local and dev)"}
                                rightLabel={true}
                                rightLabelText={"Get access snapchat"}
                                onRightLabelClick={() => {
                                    this.functions.login(this.state.code);
                                }}
                                value={this.state.code}
                                onChange={(value) => {
                                    this.setState({
                                        code: value
                                    })
                                }}
                            />
                        </div>
                    }

                    {
                        !this.state.agency.snapchat &&
                        !this.state.client.id &&
                        (!this.state.user.snapchat || (this.state.user.snapchat && !this.state.user.snapchat.authenticated)) &&
                        <OauthPopup
                            url={this.url}
                            onCode={(code) => {
                                if (code) {
                                    this.functions.login(code);
                                }
                            }}
                            onClose={() => {
                                console.log('closed', this.state)
                            }}
                        >
                            <button className={(!(this.state.user.snapchat && this.state.user.snapchat.authenticated) ?
                                "cursor-pointer bg-snapchat-500 text-black" : " bg-red-500 cursor-pointer text-white") + " mt-5 shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                            }
                            >
                                <span>Login with Snapchat</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.snapchat && this.state.user.snapchat.authenticated) ? "bg-snapchat-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            className="w-4 h-4 border-2 border-black absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </OauthPopup>
                    }
                    {
                        !this.state.agency.snapchat &&
                        !this.state.client.id &&
                        this.state.user.snapchat && this.state.user.snapchat.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                    this.functions.logout();
                                }}
                                className={(!(this.state.user.snapchat && this.state.user.snapchat.authenticated) ?
                                    "cursor-pointer bg-snapchat-500 text-black" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.snapchat && this.state.user.snapchat.authenticated) ? "bg-snapchat-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            className="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        !this.state.user.id &&
                        <div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.snapchat}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.snapchat && this.state.me.snapchat.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.snapchat && this.state.client.snapchat.defaultAgent ? this.state.client.snapchat.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value,
                                        manager: {},
                                        adaccount: {}
                                    });
                                    this.functions.listManagers();
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (this.state.throttling.current) {
                                            //SKIP
                                        } else {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }

                    {
                        !this.state.agency.snapchat &&
                        this.state.client.id &&
                        (this.state.client.snapchat || this.state.agent.id !== 0) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={this.state.client.snapchat && this.state.client.snapchat.snapchatBusinessManagerId ? "Organization" : "Organizations"}
                                    loader={this.state.loading_managers}
                                    searchInput={true}
                                    locked={this.state.client.snapchat}
                                    selected={this.state.client.snapchat && this.state.client.snapchat.snapchatBusinessManagerId ? { name: this.state.client.snapchat.snapchatBusinessManagerName } : (this.state.manager.id ? this.state.manager : { name: "Select manager" })}
                                    options={this.state.managers}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            manager: value
                                        });
                                        this.functions.listAdaccounts();
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.snapchat && this.state.client.snapchat.snapchatBusinessManagerId) || this.state.manager.id)}
                                copyText={(this.state.client.snapchat && this.state.client.snapchat.snapchatBusinessManagerId) ? this.state.client.snapchat.snapchatBusinessManagerId : this.state.manager.id}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        this.state.client.id &&
                        (this.state.manager.id || (this.state.client.snapchat && this.state.client.snapchat.snapchatBusinessManagerId)) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    searchInput={true}
                                    label={this.state.client.snapchat && this.state.client.snapchat.snapchatAdAccountId ? "Ad account" : "Ad accounts"}
                                    loader={this.state.loading_adaccounts}
                                    locked={this.state.client.snapchat}
                                    selected={this.state.client.snapchat && this.state.client.snapchat.snapchatAdAccountId ? { name: this.state.client.snapchat.snapchatAdAccountName } : (this.state.adaccount.id ? this.state.adaccount : { name: "Select adaccount" })}
                                    options={this.state.adaccounts}
                                    onChange={async (value) => {

                                        //PRESET CURRENCY
                                        let currency = null;
                                        this.state.currencies.map((item) => {
                                            if (item.id == value.currency) {
                                                currency = item;
                                            }
                                        });
                                        if (currency) {
                                            this.state.currency = currency;
                                        }

                                        this.setState({
                                            currency: this.state.currency,
                                            adaccount: value
                                        });
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.snapchat && this.state.client.snapchat.snapchatAdAccountId) || this.state.adaccount.id)}
                                copyText={(this.state.client.snapchat && this.state.client.snapchat.snapchatAdAccountId) ? this.state.client.snapchat.snapchatAdAccountId : this.state.adaccount.id}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        this.state.client.id &&
                        (this.state.adaccount.id || (this.state.client.snapchat && this.state.client.snapchat.snapchatAdAccountId)) &&
                        <div className="mt-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Currency"}
                                locked={this.state.client.snapchat}
                                selected={this.state.client.snapchat && this.state.client.snapchat.currency ? { name: this.state.client.snapchat.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                                options={this.state.currencies}
                                onChange={async (value) => {
                                    this.setState({
                                        currency: value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        this.state.client.id &&
                        !this.state.client.snapchat &&
                        this.state.manager.id &&
                        this.state.adaccount.id &&
                        <div className="flex w-full flex-row">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        this.functions.connectToClient();
                                    }
                                }}
                                className={(this.state.adaccount.id ? "bg-snapchat-500 text-black" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save snapchat account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-snapchat-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            className="w-4 h-4 border-2 border-black absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        false &&
                        this.state.agency.snapchat &&
                        this.state.agency.snapchat.authenticated &&
                        <div className="mt-5 w-full">
                            <dl className={"mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4"}>
                                {[
                                    { name: "Clients", stat: this.state.agency.snapchat.snapchat_connected },
                                    { name: "Managers", stat: this.state.agency.snapchat.matching_managers },
                                    { name: "Ad accounts", stat: this.state.agency.snapchat.matching_accounts },
                                    { name: "Campaigns", stat: this.state.agency.snapchat.campaigns }
                                ].map((item) => (
                                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-5">
                                        <dt className="text-sm font-medium text-gray-500">{item.name}</dt>
                                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    }
                    {
                        this.state.agency.snapchat &&
                        this.state.agency.snapchat.authenticated &&
                        this.state.syncManagers &&
                        <div className="bg-yellow-100 p-4 mt-4 text-yellow-500 text-sm font-medium rounded-md">
                            {this.state.syncMessage}
                        </div>
                    }
                    {
                        (this.state.error) &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        this.state.healthcheck && this.state.healthcheck.snapchat && this.state.healthcheck.snapchat.status !== 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.snapchat.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        !this.state.user.id &&
                        this.state.client.snapchat &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.snapchat && this.state.client.snapchat.defaultAgent && this.state.client.snapchat.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.snapchat &&
                        !(this.state.me.snapchat && this.state.me.snapchat.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated snapchat, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.agency.snapchat &&
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.snapchat &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated snapchat
                        </div>
                    }
                </div>

            </div>
        )
    }

}

export default AccountSnapchat;
