import React, { Component } from 'react';
import AccountAdform from '../moduleFiles/accountAdform';
import AccountSnapchat from '../moduleFiles/accountSnapchat';
import AccountLinkedin from '../moduleFiles/accountLinkedin';
import AccountFacebook from '../moduleFiles/accountFacebook';
import AccountGoogle from '../moduleFiles/accountGoogle';
import AccountTwitter from '../moduleFiles/accountTwitter';
import AccountTiktok from '../moduleFiles/accountTiktok';
import AccountGoogleAnalytics from '../moduleFiles/accountGoogleAnalytics';
import AccountGoogleAnalytics4 from '../moduleFiles/accountGoogleAnalytics4';
import AccountDv360 from '../moduleFiles/accountDv360';
import AccountCm360 from '../moduleFiles/accountCm360';
import AccountBing from '../moduleFiles/accountBing';
import AccountBidtheatre from '../moduleFiles/accountBidtheatre';
import { userRegister } from '../services/userRegister';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SideNotification from '../moduleFiles/sideNotification';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import formated_timezones from '../assets/json/formated_timezones.json';
import AccountGoogleSearchConsole from '../moduleFiles/accountGoogleSearchConsole';

class Connections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: {
                user: false,
                page: false,
                healthcheck: false,
                remove_cache: false,
            },
            open: {
                remove_modal: false,
            },
            errors: {
                healthcheck: false,
                agent: false,
                channel: null,
            },
            agent: {},
            healthcheck: {},
            timezone: {},
            timezones: formated_timezones,
            remove: null,
            remove_name: "",
        }
    }

    componentDidMount() {
        this.functions.getAgent();
        const dataReceived = this.props.location.state ? this.props.location.state.error : null;
        if (dataReceived) {
            this.state.errors.channel = dataReceived
            this.promisedSetState({
                errors: this.state.errors
            });
        }
    }

    functions = {
        onLoginAccount: (channel) => {
            if (channel === this.state.errors.channel) {
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: null,
                });
            }
        },
        getUser: async () => {
            await this.promisedSetState({
                agent: userRegister.get() ? userRegister.get() : {}
            })
        },
        healthcheck: async () => {
            this.state.loading.healthcheck = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.healthcheck();
                await this.promisedSetState({ healthcheck: response.data });
                if (Object.keys(response.data).length === 0) {
                    this.state.errors.healthcheck = false
                } else {
                    this.state.errors.healthcheck = true
                }
                await this.promisedSetState({ errors: this.state.errors });
                this.refs.SideNotification.functions.trigger();
            } catch (error) {
                console.log(error);
            }
            this.state.loading.healthcheck = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        getAgent: async () => {
            this.state.loading.user = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.getAgent();
                await this.promisedSetState({ agent: response.data });
                if (Object.keys(response.data).length === 0) {
                    this.state.errors.user = false
                } else {
                    this.state.errors.user = true
                }
                await this.promisedSetState({ errors: this.state.errors });
            } catch (error) {
                if (error && error.body && error.body.data) {
                    this.state.errors.user = error.body.data;
                    await this.promisedSetState({ errors: this.state.errors });
                }
                console.log(error)
            }
            this.state.loading.user = false
            await this.promisedSetState({ loading: this.state.loading });
        },
        removeCachedData: async () => {
            this.state.loading.remove_cache = true
            await this.promisedSetState({ loading: this.state.loading });
            try {
                let response = await this.calls.removeCachedData();
            } catch (error) { }
            this.state.loading.remove_cache = false
            await this.promisedSetState({ loading: this.state.loading });
        },
    }

    calls = {
        healthcheck: async () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/platformsHealthCheck";
            return apiRegister.call(options, url);
        },
        getAgent: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgent";
            return apiRegister.call(options, url);
        },
        removeCachedData: () => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeCachedData";
            return apiRegister.call(options, url);
        }
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className='w-full'>

                {
                    this.state.loading.user &&
                    <div className='min-h-screen flex flex-col justify-center items-center w-full'>
                        <div style={{ borderTopColor: "transparent" }} className='w-10 h-10  border-2 border-purple-500 border-solid rounded-full animate-spin'></div>
                        <div className='font-semibold mt-3'>Loading connections...</div>
                    </div>
                }

                <WarningModalTailwind
                    open={this.state.open.remove_modal}
                    title={"Delete"}
                    description={'Are you sure you want to delete ? This action cannot be undone'}
                    cancelButtonText={"Cancel"}
                    submitButtonText={"Remove"}
                    disableSubmitButton={!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)}
                    showInput={true}
                    inputTitle={"Type Name to remove"}
                    copyInput={this.state.open.remove_modal ? this.state.remove.name : ""}
                    inputError={(!this.state.open.remove_modal || (this.state.remove_name != this.state.remove.name)) ? "Name does not match" : false}
                    onClose={async (value) => {
                        await this.promisedSetState({ open: { remove_modal: false } });
                        await this.promisedSetState({ remove: null });
                        await this.promisedSetState({ remove_name: "" });
                    }}
                    onSubmit={async (value) => {
                        if (this.state.open.remove_modal && this.state.remove_name == this.state.remove.name) {
                            this.functions.removeCachedData();
                            await this.promisedSetState({ open: { remove_modal: false } });
                        }
                    }}
                    onInput={(value) => {
                        this.setState({
                            remove_name: value
                        })
                    }}
                />

                {
                    !this.state.loading.user &&
                    <div className='p-4'>
                        <div className=''>
                            <div className=''>

                                {/* GRID */}
                                <div className='grid grid-cols-4 gap-4'>
                                    <div className={"col-span-6 p-4 sm:col-span-2 rounded-lg bg-white shadow-sm" + (this.state.errors.channel === "Tiktok" ? " border-2 border-red-500" : '')}>
                                        <AccountTiktok
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Tiktok")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 rounded-lg bg-white shadow-sm" + (this.state.errors.channel === "Twitter" ? " border-2 border-red-500" : '')}>
                                        <AccountTwitter
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Twitter")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 rounded-lg bg-white shadow-sm" + (this.state.errors.channel === "Google" ? " border-2 border-red-500" : '')}>
                                        <AccountGoogle
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Google")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Facebook" ? " border-2 border-red-500" : '')}>
                                        <AccountFacebook
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Facebook")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Linkedin" ? " border-2 border-red-500" : '')}>
                                        <AccountLinkedin
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Linkedin")}

                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Snapchat" ? " border-2 border-red-500" : '')}>
                                        <AccountSnapchat
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Snapchat")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "GoogleSearchConsole" ? " border-2 border-red-500" : '')}>
                                        <AccountGoogleSearchConsole
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("GoogleSearchConsole")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "GoogleAnalytics" ? " border-2 border-red-500" : '')}>
                                        <AccountGoogleAnalytics
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("GoogleAnalytics")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "GoogleAnalytics4" ? " border-2 border-red-500" : '')}>
                                        <AccountGoogleAnalytics4
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("GoogleAnalytics4")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Dv360" ? " border-2 border-red-500" : '')}>
                                        <AccountDv360
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Dv360")}
                                        />
                                    </div>
                                    {
                                        //false &&
                                        <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Cm360" ? " border-2 border-red-500" : '')}>
                                            <AccountCm360
                                                healthcheck={this.state.healthcheck}
                                                user={this.state.agent}
                                                onlogin={() => this.functions.onLoginAccount("Cm360")}
                                            />
                                        </div>
                                    }
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Adform" ? " border-2 border-red-500" : '')}>
                                        <AccountAdform
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Adform")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Bing" ? " border-2 border-red-500" : '')}>
                                        <AccountBing
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Bing")}
                                        />
                                    </div>
                                    <div className={"col-span-6 p-4 sm:col-span-2 bg-white shadow-sm rounded-lg" + (this.state.errors.channel === "Bidtheatre" ? " border-2 border-red-500" : '')}>
                                        <AccountBidtheatre
                                            healthcheck={this.state.healthcheck}
                                            user={this.state.agent}
                                            onlogin={() => this.functions.onLoginAccount("Bidtheatre")}
                                        />
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                }

            </div >
        )

    }

}

export default Connections;

