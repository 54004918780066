import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, ClipboardListIcon, CogIcon, CursorClickIcon, ExclamationIcon, LocationMarkerIcon, LockClosedIcon, MenuAlt1Icon, MinusIcon, PlusIcon } from '@heroicons/react/outline'

import { SlideDown } from "react-slidedown";
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import DropdownTailwind from './dropdownTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import languages from '../assets/json/languages.json';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import moment from 'moment';
import cn from "classnames";
import SwitchTailwind from './switchTailwind';
import { Bar } from 'react-chartjs-2';

class NewCampaignWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: {
                type: false,
                templates: false,
                name: true,
                dates: false,
                campaigns: false,
                facebook: false,
                google: false,
                linkedin: false,
                snapchat: false,
                twitter: false,
                scheduler: false
            },
            client: {},
            type: {},
            loader: false,
            templates: [],
            selected_template: {},
            dates: {},
            name: "",
            no_end: true,
            template_campaigns: [],
            channels: {},
            order: {},
            scheduler: false,
            schedule: true,
            weekdays: {
                scheduleMonday: true,
                scheduleTuesday: true,
                scheduleWednesday: true,
                scheduleThursday: true,
                scheduleFriday: true,
                scheduleSaturday: true,
                scheduleSunday: true,
            },
            schedule_budget: 0,
            planer: true,
            start_date: moment().format("YYYY-MM-DD"),
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            name: this.props.input ? this.props.input : "",
            client: this.props.client,
            order: this.props.order,
        })
        console.log(this.state.order, "+")
        if (this.state.order.start_date) {
            if (moment(this.state.order.start_date).isAfter(moment())) {
                await this.promisedSetState({
                    start_date: this.state.order.start_date
                })
            }
        }
        if (this.state.client.scheduler) {
            await this.promisedSetState({
                scheduler: true
            })
        }
        if (this.state.order.googleBudgets && this.state.order.googleBudgets.length > 0) {
            await this.promisedSetState({
                planer: false
            })
        }
        if (this.state.order.budgetSplit && this.state.order.budgetSplit.value) {
            await this.promisedSetState({
                schedule_budget: this.state.order.budgetSplit.budget
            })

        } else {
            if (this.state.order.maxBudgetEnabled && +this.state.order.maxBudget >0) {
                await this.promisedSetState({
                    schedule_budget: this.state.order.maxBudget
                })
            }
        }
        this.props.onDisable(true);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            input: nextProps.input,
            client: nextProps.client,
            order: nextProps.order,
        })
    }

    functions = {
        validate: async () => {
            if ((this.state.type.value === "new" || (this.state.type.value === "template" && (this.state.dates.start && (!this.state.dates.end || (moment(this.state.dates.end).isAfter(this.state.dates.start)))))) && (!this.state.schedule || (this.state.schedule && Object.keys(this.state.weekdays).length > 0 && (!this.state.planer || (this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity))))) {
                this.props.onDisable(false);
            } else this.props.onDisable(true);
        },
        getAllTemplates: async () => {
            try {
                if (this.state.templates.length === 0) {
                    await this.promisedSetState({
                        loading_templates: true
                    })
                    let response = await this.calls.getAllTemplates()
                    console.log(response)
                    if (response.data && response.data.length > 0) {
                        this.setState({
                            templates: response.data
                        })
                    }
                    await this.promisedSetState({
                        loading_templates: false
                    })
                } else {
                    console.log("Something went wrong")
                }
            } catch (error) {
                console.log(error)
            }
        },
        getWizard: async (id) => {
            await this.promisedSetState({ loading: true });
            try {
                let response = await this.calls.getWizard(id);
                await this.promisedSetState({
                    template_campaigns: response.data.campaigns ? response.data.campaigns : this.state.campaigns,
                    channels: response.data.channels ? response.data.channels : this.state.channels,
                });
                Object.keys(this.state.channels).sort().map((channel, index) => {
                    if (index === 0) {
                        this.state.open[channel] = true;
                    }
                });
                await this.promisedSetState({
                    open: this.state.open
                });


            } catch (error) {
                console.log(error);
            }
        },
        create: async () => {
            try {
                let data = false;
                if (this.state.scheduler) {
                    data = {
                        schedule: this.state.schedule,
                        weekdays: this.state.weekdays,
                        planer: this.state.planer,
                        schedule_budget: this.state.schedule_budget,
                        start_date: this.state.start_date,
                    }
                }
                if (this.state.type.value === "new") {
                    this.props.onNewDraft(this.state.name, data);
                } else {
                    this.props.onNewDraftFromTemplate({
                        name: this.state.name,
                        template: this.state.selected_template,
                        dates: this.state.dates,
                        campaigns: this.state.template_campaigns,
                    }, data);
                }
            } catch (error) {
            }
        }
    };

    calls = {
        getAllTemplates: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listWizards?status=template"
            return apiRegister.call(options, url);
        },
        getWizard: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizard?id=" + id;
            return apiRegister.call(options, url);
        },
    };

    renders = {
        budgetPlannerGraph: () => {
            let graph_data = {
                labels: [],
                datasets: []
            }
            let months = [];
            let today = moment(this.state.start_date).startOf('month');
            if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.id === 1) {
                today = moment(this.state.start_date);
            }
            for (let i = 0; i < 12; i++) {
                let month = today.month();
                let data = { daily_budget: 0, left_over: 0, total: 0, month: today.format('MMMM'), year: today.format('YYYY'), "1": 0, "2": 0, '3': 0, "4": 0, "5": 0, "6": 0, "7": 0 };
                let next = false;
                while (!next) {
                    if (month !== today.month()) {
                        next = true;
                    } else {
                        data[today.isoWeekday()] += 1;
                        today.add(1, 'd');
                    }
                }
                if (next) {
                    if (this.state.weekdays.scheduleMonday) {
                        data.total += data["1"];
                    }
                    if (this.state.weekdays.scheduleTuesday) {
                        data.total += data["2"];
                    }
                    if (this.state.weekdays.scheduleWednesday) {
                        data.total += data["3"];
                    }
                    if (this.state.weekdays.scheduleThursday) {
                        data.total += data["4"];
                    }
                    if (this.state.weekdays.scheduleFriday) {
                        data.total += data["5"];
                    }
                    if (this.state.weekdays.scheduleSaturday) {
                        data.total += data["6"];
                    }
                    if (this.state.weekdays.scheduleSunday) {
                        data.total += data["7"];
                    }
                    if (this.state.schedule_budget > 0) {
                        if (["scheduleMonday", "scheduleTuesday", "scheduleWednesday", "scheduleThursday", "scheduleFriday", "scheduleSaturday", "scheduleSunday"].filter((item) => this.state.weekdays[item]).length < 7) {
                            data.daily_budget = (this.state.schedule_budget / data.total).toFixed(2);
                        } else {
                            if (this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.id === 1) {
                                data.daily_budget = (this.state.schedule_budget / data.total).toFixed(2);
                            } else {
                                data.daily_budget = (this.state.schedule_budget / 30).toFixed(2);
                            }
                        }
                        console.log(data.daily_budget, "data.daily_budget")
                        console.log(this.state.weekdays, "this.state.weekdays")
                        //data.daily_budget = Math.floor((this.state.schedule_budget) / data.total);
                        //data.left_over = this.state.schedule_budget - (data.daily_budget * data.total);
                    }
                    graph_data.labels.push([data.total + " days", data.month, data.year]);
                    months.push(data);
                    continue;
                }
            }

            let dataset_daily = {
                label: 'Daily budget',
                data: [],
                borderColor: 'rgb(69, 63, 241)',
                backgroundColor: 'rgba(69, 63, 241, 0.7)'
            }

            let dataset_leftover = {
                label: 'Leftover added to last day',
                data: [],
                borderColor: 'rgb(255, 77, 84)',
                backgroundColor: 'rgba(255, 77, 84, 0.7)'
            }

            months.map((item) => {
                dataset_daily.data.push(item.daily_budget);
                dataset_leftover.data.push(item.left_over);
            });

            graph_data.datasets.push(dataset_daily);
            //graph_data.datasets.push(dataset_leftover);
            return graph_data;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={`h-full flex flex-col overflow-y-scroll`}>
                {
                    <div className="flex flex-col">
                        <div className="flex flex-1">
                            <div className="w-full">
                                <div className="grid-cols-12 gap-4 grid">

                                    {
                                        true &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <MenuAlt1Icon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div
                                                            className="font-semibold text-sm">Draft name</div>
                                                        {
                                                            this.state.name !== "" &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                <div className="text-purple-500 ml-1">{this.state.name}
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.name !== "" &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    name: !this.state.open.name,
                                                                    type: false,
                                                                    templates: false,
                                                                    campaigns: false,
                                                                    dates: false,
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.name ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.name &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.name &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.name}
                                                >
                                                    <div className="mt-4 grid-cols-12 grid gap-4">
                                                        <div className={"col-span-12 flex"}>
                                                            <div className='flex-col flex-1'>
                                                                <InputTailwind
                                                                    locked={false}
                                                                    label={"Draft name (required)"}
                                                                    placeholder={"Enter draft name"}
                                                                    value={this.state.name}
                                                                    onChange={async (value) => {
                                                                        if (value === "") {
                                                                            this.state.type = {};
                                                                            this.props.onDisable(true);
                                                                        }
                                                                        await this.promisedSetState({
                                                                            name: value,
                                                                            type: this.state.type
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-span-12 flex items-center justify-center">
                                                            <div onClick={async () => {
                                                                if (this.state.name !== "") {
                                                                    this.state.open.name = false;
                                                                    this.state.open.type = true;

                                                                    this.setState({
                                                                        open: this.state.open
                                                                    });
                                                                }
                                                            }}
                                                                className={(this.state.name !== "" ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                <span style={{ whiteSpace: "nowrap" }}
                                                                    className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                    Next step
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </SlideDown>
                                            </div>
                                            {
                                                this.state.name !== "" &&
                                                <div className="col-span-12">
                                                    <div className="border-b w-full"></div>
                                                </div>
                                            }
                                        </>
                                    }
                                    {
                                        this.state.name !== "" &&
                                        <div className={"col-span-12 px-6"}>
                                            <div className="flex flex-row">
                                                <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                    <CogIcon className="w-5 h-5 " />
                                                </div>
                                                <div className="flex flex-1 flex-col justify-center ml-3">
                                                    <div className="font-semibold text-sm">Select action</div>
                                                    {
                                                        this.state.type.value &&
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">You have selected <div className="text-purple-500 ml-1">{this.state.type.name}</div>
                                                        </div>
                                                    }
                                                    {
                                                        !this.state.type.value &&
                                                        <div className="font-medium text-xs text-gray-500">Select report type</div>
                                                    }
                                                </div>
                                                <div className='flex'>
                                                    {
                                                        this.state.type.value &&
                                                        <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                            <CheckIcon className="w-5 h-5 " />
                                                        </div>
                                                    }
                                                    <div>
                                                        <div onClick={() => {
                                                            this.state.open = {
                                                                type: !this.state.open.type,
                                                                name: false,
                                                                campaigns: false,
                                                                templates: false,
                                                                dates: false,
                                                            };
                                                            this.setState({
                                                                open: this.state.open
                                                            });

                                                        }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.type ? "opacity-100" : "opacity-50"}`}>
                                                            {
                                                                !this.state.open.type &&
                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                            }
                                                            {
                                                                this.state.open.type &&
                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* SELECT ACTION */}

                                            <SlideDown
                                                closed={!this.state.open.type}
                                            >
                                                <div className="mt-4 grid-cols-12 grid gap-4">
                                                    {
                                                        [
                                                            { name: `New draft`, value: "new", description: "Create a new draft campaigns" },
                                                            { name: "From template", value: "template", description: "Use one of our premade templates or one of your previously saved templates to create new draft" }
                                                        ].map((item, index) => {
                                                            return (
                                                                <div onClick={() => {
                                                                    if (item.value !== "template") {
                                                                        this.props.onDisable(false);
                                                                        if (this.state.scheduler) {
                                                                            this.state.open.type = false;
                                                                            this.state.open.scheduler = true;
                                                                        }
                                                                    } else {
                                                                        this.functions.getAllTemplates();
                                                                        this.state.open.type = false;
                                                                        this.state.open.templates = true;
                                                                        this.props.onDisable(true);
                                                                    }

                                                                    this.setState({
                                                                        type: item,
                                                                        open: this.state.open,
                                                                        selected_template: {},
                                                                        campaigns: [],
                                                                        dates: {}
                                                                    });
                                                                }} className={"cursor-pointer " + (this.state.type.value === item.value ? "bg-purple-100 border-purple-500" : "border-gray-300 bg-custom-input") + " flex flex-col col-span-6 relative p-4 rounded-md border-1.5 "}>
                                                                    <div className={(this.state.type.value === item.value ? "text-purple-500" : "") + " text-sm font-semibold mb-1"}>
                                                                        {item.name}
                                                                    </div>
                                                                    <div className={(this.state.type.value === item.value ? "text-gray-700" : "text-gray-500") + " text-sm font-medium "}>
                                                                        {item.description}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        this.state.type.value && (this.state.type.value === "template" || (this.state.type.value === "new" && this.state.scheduler)) &&
                                                        <div className="col-span-12 flex items-center justify-center">
                                                            <div onClick={async () => {
                                                                if (this.state.type.value) {
                                                                    if (this.state.type.value === "template") {
                                                                        this.state.open.templates = true;
                                                                    } else {
                                                                        this.state.open.scheduler = true;
                                                                    }
                                                                    this.state.open.type = false;

                                                                    this.setState({
                                                                        open: this.state.open
                                                                    });
                                                                }
                                                            }}
                                                                className={(this.state.type.value ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                <span style={{ whiteSpace: "nowrap" }}
                                                                    className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                    Next step
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </SlideDown>
                                        </div>
                                    }
                                    {
                                        this.state.type.value &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }



                                    {
                                        this.state.type.value === "template" &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <ClipboardListIcon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div
                                                            className="font-semibold text-sm">Select template</div>
                                                        {
                                                            this.state.selected_template.id &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                You have selected <div className="text-purple-500 ml-1">{this.state.selected_template.id ? this.state.selected_template.name : ""}
                                                                </div></div>
                                                        }
                                                        {
                                                            !this.state.selected_template.id &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">No template selected yet</div>

                                                        }
                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.selected_template.id &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    type: false,
                                                                    name: false,
                                                                    templates: !this.state.open.templates,
                                                                    campaigns: false,
                                                                    dates: false,
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.templates ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.templates &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.templates &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* SELECT TEMPLATE */}
                                                <SlideDown
                                                    closed={!this.state.open.templates}
                                                >
                                                    <div className="mt-4 grid-cols-12 grid gap-4">
                                                        <div className="col-span-12">
                                                            <DropdownTailwind
                                                                ref="searchDropdown"
                                                                label={"Templates"}
                                                                searchInput={true}
                                                                selected={this.state.selected_template.id ? this.state.selected_template : { name: "Search ..." }}
                                                                search={this.state.search}
                                                                options={this.state.templates}
                                                                onChange={async (template) => {
                                                                    this.state.open.templates = false;
                                                                    this.state.open.campaigns = true;
                                                                    await this.promisedSetState({
                                                                        selected_template: template,
                                                                        open: this.state.open
                                                                    });
                                                                    this.functions.getWizard(template.id);
                                                                }}
                                                                onSearch={async (value) => {
                                                                    await this.promisedSetState({
                                                                        search: value
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-span-12 flex items-center justify-center">
                                                            <div onClick={async () => {
                                                                if (this.state.selected_template.id) {
                                                                    this.state.open.templates = false;
                                                                    this.state.open.campaigns = true;
                                                                    this.setState({
                                                                        open: this.state.open
                                                                    });
                                                                }
                                                            }}
                                                                className={(this.state.selected_template.id ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                <span style={{ whiteSpace: "nowrap" }}
                                                                    className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                    Next step
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SlideDown>
                                            </div>
                                            {
                                                this.state.selected_template.id &&
                                                <div className="col-span-12">
                                                    <div className="border-b w-full"></div>
                                                </div>
                                            }
                                        </>
                                    }


                                    {
                                        this.state.name !== "" && this.state.type.value === "template" && this.state.selected_template.id && this.state.template_campaigns.length > 0 &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <MenuAlt1Icon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div className="font-semibold text-sm">Campaigns name</div>
                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            true &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    campaigns: !this.state.open.campaigns,
                                                                    type: false,
                                                                    templates: false,
                                                                    name: false,
                                                                    dates: false,
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.campaigns ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.campaigns &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.campaigns &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.campaigns}
                                                >
                                                    <div className="mt-4 grid-cols-12 grid gap-4">

                                                        {
                                                            this.state.template_campaigns.length > 0 &&
                                                            <div className="col-span-12 grid gap-4 grid-cols-2">
                                                                {
                                                                    Object.keys(this.state.channels).sort().map((channel) => {
                                                                        return (
                                                                            <div className="col-span-2">
                                                                                <div className="flex flex-row flex-1 items-center align-middle">

                                                                                    {
                                                                                        channel === "bidtheatre" &&
                                                                                        <div
                                                                                            className="bg-bidtheatre-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-4"
                                                                                                src={require('../assets/images/bidtheatre_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "google" &&
                                                                                        <div
                                                                                            className="bg-google-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/google_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "linkedin" &&
                                                                                        <div
                                                                                            className="bg-linkedin-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/linkedin_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "facebook" &&
                                                                                        <div
                                                                                            className="bg-facebook-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img style={{ width: '0.4rem' }}
                                                                                                src={require('../assets/images/facebook_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "google_analytics" &&
                                                                                        <div
                                                                                            className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/google_analytics_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "google_analytics_4" &&
                                                                                        <div
                                                                                            className="bg-googleanalytics-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/ga_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "adform" &&
                                                                                        <div
                                                                                            className="bg-adform-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/adform_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "twitter" &&
                                                                                        <div
                                                                                            className="bg-twitter-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/twitter_icon.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "tiktok" &&
                                                                                        <div
                                                                                            className="bg-tiktok-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/tiktok_icon.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "snapchat" &&
                                                                                        <div
                                                                                            className="bg-snapchat-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/snapchat.svg')} />
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        channel === "bing" &&
                                                                                        <div
                                                                                            className="bg-bing-500 mr-2 h-10 w-10 rounded flex justify-center align-middle items-center">
                                                                                            <img className="w-3"
                                                                                                src={require('../assets/images/bing-logo.png')} />
                                                                                        </div>
                                                                                    }
                                                                                    <div style={{ marginTop: "-6px" }} className="flex flex-col flex-1 justify-center">
                                                                                        <h3 className={"mb-0 font-semibold"}>
                                                                                            <span className="capitalize text-sm">{channel ? (channel.replace("_", " ").replace("_", " ")) : "..."}</span>
                                                                                        </h3>

                                                                                        <p className="text-xs font-medium flex items-center text-gray-500 leading-tight">
                                                                                            There are<span
                                                                                                className="ml-1 mr-1 text-purple-500">{this.state.template_campaigns.filter((item) => { return item.channel == channel }).length}</span> campaigns
                                                                                            available
                                                                                        </p>

                                                                                    </div>
                                                                                    <div className="flex flex-row">
                                                                                        <div onClick={() => {
                                                                                            this.state.open[channel] = !this.state.open[channel];
                                                                                            Object.keys(this.state.channels).map((item) => {
                                                                                                if (item !== channel) {
                                                                                                    this.state.open[item] = false;
                                                                                                }
                                                                                            });
                                                                                            this.setState({
                                                                                                open: this.state.open
                                                                                            })
                                                                                        }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open[channel] ? "opacity-100" : "opacity-50"}`}>
                                                                                            {
                                                                                                !this.state.open[channel] &&
                                                                                                <ChevronDownIcon className="w-5 h-5 " />
                                                                                            }
                                                                                            {
                                                                                                this.state.open[channel] &&
                                                                                                <ChevronUpIcon className="w-5 h-5 " />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <SlideDown
                                                                                    closed={!this.state.open[channel]}
                                                                                >
                                                                                    <div className="mt-4 grid-cols-12 grid gap-4">
                                                                                        {
                                                                                            this.state.template_campaigns.filter((item) => { return item.channel == channel }).map((campaign) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <div className="col-span-9">
                                                                                                            <InputTailwind
                                                                                                                label={"Campaign name"}
                                                                                                                placeholder={"Enter campaign name"}
                                                                                                                value={campaign.name}
                                                                                                                onChange={async (value) => {
                                                                                                                    this.state.template_campaigns.map((item) => {
                                                                                                                        if (item.id === campaign.id) {
                                                                                                                            item.name = value;
                                                                                                                        }
                                                                                                                        return item;
                                                                                                                    })
                                                                                                                    await this.promisedSetState({
                                                                                                                        template_campaigns: this.state.template_campaigns
                                                                                                                    })
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="col-span-3">
                                                                                                            <InputTailwind
                                                                                                                label={"Type"}
                                                                                                                locked={true}
                                                                                                                disabled={true}
                                                                                                                value={campaign.campaign_type}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </SlideDown>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }

                                                        <div className="col-span-12 flex items-center justify-center">
                                                            <div onClick={async () => {

                                                                this.state.open.campaigns = false;
                                                                this.state.open.dates = true;

                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }}
                                                                className={(this.state.name !== "" ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                <span style={{ whiteSpace: "nowrap" }}
                                                                    className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                    Next step
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </SlideDown>
                                            </div>
                                            {
                                                this.state.name !== "" &&
                                                <div className="col-span-12">
                                                    <div className="border-b w-full"></div>
                                                </div>
                                            }
                                        </>
                                    }


                                    {
                                        this.state.name !== "" && this.state.type.value === "template" && this.state.selected_template.id &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className={"h-10 w-10  cursor-pointer border-1.5 flex justify-center items-center rounded-md " + (this.state.dates.end && this.state.dates.start && (moment(this.state.dates.end).isBefore(this.state.dates.start)) ? " text-red-500 border-red-100 bg-red-100" : "text-purple-500 border-purple-100 bg-purple-100")}>
                                                        <CalendarIcon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div
                                                            className={"font-semibold text-sm" + (this.state.dates.end && this.state.dates.start && (moment(this.state.dates.end).isBefore(this.state.dates.start)) ? " text-red-500" : "")}>Select dates</div>
                                                        {
                                                            this.state.dates.start &&
                                                            <div className="font-medium text-xs text-gray-500 flex flex-row">
                                                                From <div className="text-purple-500 mx-1">{moment(this.state.dates.start).format("YYYY-MM-DD")} </div>
                                                                {
                                                                    this.state.dates.end &&
                                                                    <>
                                                                        To <div className="text-purple-500 ml-1">{moment(this.state.dates.end).format("YYYY-MM-DD")}</div>
                                                                    </>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            this.state.dates.start && (!this.state.dates.end || (this.state.dates.end && moment(this.state.dates.end).isAfter(this.state.dates.start))) &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        {
                                                            this.state.dates.start && (this.state.dates.end && moment(this.state.dates.end).isBefore(this.state.dates.start)) &&
                                                            <div className="h-10 w-10 text-red-500 mr-2 border-red-500 bg-red-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <ExclamationIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    type: false,
                                                                    name: false,
                                                                    templates: false,
                                                                    dates: !this.state.open.dates,
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });

                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.dates ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.dates &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.dates &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.dates}
                                                >
                                                    <div className="mt-4 grid-cols-12 grid gap-4">
                                                        <div className={"col-span-6"}>
                                                            <InputDatepickerTailwind
                                                                error={(this.state.dates.end && moment(this.state.dates.end).isBefore(this.state.dates.start)) ? true : false}
                                                                label={"Start date"}
                                                                value={this.state.dates.start ? moment(this.state.dates.start).format("YYYY-MM-DD") : ""}
                                                                onChange={async (value) => {
                                                                    this.state.dates.start = moment(value).format("YYYY-MM-DD");
                                                                    await this.promisedSetState({
                                                                        dates: this.state.dates
                                                                    })

                                                                    if (this.state.dates.start) {
                                                                        this.props.onDisable(false);
                                                                    }

                                                                    if (this.state.dates.end && moment(this.state.dates.end).isBefore(this.state.dates.start)) {
                                                                        this.props.onDisable(true);
                                                                    }

                                                                    if (this.state.scheduler) {
                                                                        await this.promisedSetState({
                                                                            start_date: this.state.dates.start
                                                                        })
                                                                        if ((!this.state.schedule || (this.state.schedule && Object.keys(this.state.weekdays).length > 0 && (!this.state.planer || (this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity))))) {
                                                                            this.props.onDisable(false);
                                                                        } else {
                                                                            this.props.onDisable(true);
                                                                        }
                                                                    } else {
                                                                        this.props.onDisable(false);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={"col-span-6"}>
                                                            <InputDatepickerTailwind
                                                                error={(this.state.dates.end && moment(this.state.dates.end).isBefore(this.state.dates.start)) ? true : false}
                                                                label={"End date"}
                                                                disabled={this.state.no_end}
                                                                labelRight={(this.state.no_end ? "Disable" : "Enable")}
                                                                placeholder={this.state.no_end ? "No end date" : ""}
                                                                value={this.state.dates.end ? moment(this.state.dates.end).format("YYYY-MM-DD") : ""}
                                                                onChange={async (value) => {
                                                                    this.state.dates.end = moment(value).format("YYYY-MM-DD");
                                                                    await this.promisedSetState({
                                                                        dates: this.state.dates
                                                                    })
                                                                    if (this.state.dates.start && moment(this.state.dates.end).isBefore(this.state.dates.start)) {
                                                                        this.props.onDisable(true);
                                                                    } else {
                                                                        if (this.state.scheduler) {
                                                                            if ((!this.state.schedule || (this.state.schedule && Object.keys(this.state.weekdays).length > 0 && (!this.state.planer || (this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity))))) {
                                                                                this.props.onDisable(false);
                                                                            } else {
                                                                                this.props.onDisable(true);
                                                                            }
                                                                        } else {
                                                                            this.props.onDisable(false);
                                                                        }
                                                                    }

                                                                }}
                                                                onLabelRightClick={async (value) => {
                                                                    if (!this.state.no_end) {
                                                                        delete this.state.dates.end
                                                                        if (this.state.dates.start) {
                                                                            this.props.onDisable(false);
                                                                        }
                                                                    } else {
                                                                        if (this.state.dates.start) {
                                                                            this.state.dates.end = moment(this.state.dates.start).add(1, 'days').format("YYYY-MM-DD");
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        dates: this.state.dates,
                                                                        no_end: !this.state.no_end
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                        {
                                                            this.state.dates.end && this.state.dates.start && moment(this.state.dates.end).isBefore(this.state.dates.start) &&
                                                            <div className="col-span-12 flex items-center justify-center">
                                                                <div className="bg-red-100 w-full p-4 text-red-500 text-sm font-medium rounded-md">
                                                                    Start date have to be before end date.
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            this.state.scheduler &&
                                                            <div className="col-span-12 flex items-center justify-center">
                                                                <div onClick={async () => {

                                                                    this.state.open.dates = false;
                                                                    this.state.open.scheduler = true;

                                                                    this.setState({
                                                                        open: this.state.open
                                                                    });

                                                                }}
                                                                    className={(this.state.dates.start ? "text-white bg-purple-500 cursor-pointer" : "text-gray-300 cursor-not-allowed bg-gray-100 border-1.5") + " py-2 w-32 flex flex-row h-10 overflow-hidden rounded-md items-center"}>
                                                                    <span style={{ whiteSpace: "nowrap" }}
                                                                        className={"text-sm font-medium flex flex-1 justify-center items-center text-center capitalize"}>
                                                                        Next step
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </SlideDown>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.scheduler && this.state.dates.start &&
                                        <div className="col-span-12">
                                            <div className="border-b w-full"></div>
                                        </div>
                                    }

                                    {/* SCHEDULER */}
                                    {
                                        this.state.scheduler && this.state.type && (this.state.type.value === "new" || (this.state.type.value === "template" && this.state.dates.start)) &&
                                        <>
                                            <div className="col-span-12 px-6">
                                                <div className="flex flex-row">
                                                    <div className="h-10 w-10 text-purple-500 cursor-pointer border-purple-100 bg-purple-100 border-1.5 flex justify-center items-center rounded-md">
                                                        <CalendarIcon className="w-5 h-5 " />
                                                    </div>
                                                    <div className="flex flex-1 flex-col justify-center ml-3">
                                                        <div className="font-semibold text-sm">Schedule</div>
                                                        <div className="font-medium text-xs text-gray-500 flex flex-row">Schedule settings</div>
                                                    </div>
                                                    <div className='flex'>
                                                        {
                                                            (!this.state.schedule || (this.state.schedule && Object.keys(this.state.weekdays).length > 0 && (!this.state.planer || (this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] !== Infinity)))) &&
                                                            <div className="h-10 w-10 text-green-500 mr-2 border-green-500 bg-green-100 border-1.5 flex justify-center items-center rounded-md">
                                                                <CheckIcon className="w-5 h-5 " />
                                                            </div>
                                                        }
                                                        <div>
                                                            <div onClick={() => {
                                                                this.state.open = {
                                                                    type: false,
                                                                    templates: false,
                                                                    name: false,
                                                                    dates: false,
                                                                    campaigns: false,
                                                                    facebook: false,
                                                                    google: false,
                                                                    linkedin: false,
                                                                    snapchat: false,
                                                                    twitter: false,
                                                                    scheduler: !this.state.open.scheduler
                                                                };
                                                                this.setState({
                                                                    open: this.state.open
                                                                });


                                                            }} className={`bg-gray-100 px-2 h-10 cursor-pointer border-1.5 flex justify-center items-center rounded-md ${this.state.open.scheduler ? "opacity-100" : "opacity-50"}`}>
                                                                {
                                                                    !this.state.open.scheduler &&
                                                                    <ChevronDownIcon className="w-5 h-5 " />
                                                                }
                                                                {
                                                                    this.state.open.scheduler &&
                                                                    <ChevronUpIcon className="w-5 h-5 " />
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <SlideDown
                                                    closed={!this.state.open.scheduler}
                                                >
                                                    <div className="mt-4 grid-cols-12 grid gap-4 border-gray-400 border rounded-md">
                                                        <div className='col-span-12'>
                                                            <div className={"bg-gray-100 flex flex-row items-center px-4 py-4"}>
                                                                <div className="font-medium text-sm flex flex-1 justify-start items-center">
                                                                    Schedule
                                                                </div>
                                                                <SwitchTailwind
                                                                    value={this.state.schedule ? true : false}
                                                                    onSwitch={async () => {
                                                                        await this.promisedSetState({
                                                                            schedule: !this.state.schedule
                                                                        })
                                                                        this.functions.validate();

                                                                    }}
                                                                />
                                                            </div>
                                                            <SlideDown
                                                                closed={!this.state.schedule}
                                                            >

                                                                <div className={"bg-white flex flex-col px-4 py-4"}>
                                                                    <div className='flex flex-wrap pt-4'>
                                                                        {
                                                                            [
                                                                                { name: "Monday", property: "scheduleMonday" },
                                                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                                                { name: "Thursday", property: "scheduleThursday" },
                                                                                { name: "Friday", property: "scheduleFriday" },
                                                                                { name: "Saturday", property: "scheduleSaturday" },
                                                                                { name: "Sunday", property: "scheduleSunday" }
                                                                            ].map((weekday) => {
                                                                                return (
                                                                                    <div onClick={async () => {
                                                                                        if (this.state.weekdays[weekday.property]) {
                                                                                            delete (this.state.weekdays[weekday.property]);
                                                                                        } else {
                                                                                            this.state.weekdays[weekday.property] = true;
                                                                                        }
                                                                                        await this.promisedSetState({
                                                                                            weekdays: this.state.weekdays
                                                                                        })
                                                                                        if (this.state.planer && +this.state.schedule_budget > 0) {
                                                                                            if (this.renders.budgetPlannerGraph().datasets[0].data[0] === Infinity) {
                                                                                                this.props.onDisable(true);
                                                                                            } else {
                                                                                                this.props.onDisable(false);
                                                                                            }
                                                                                        }
                                                                                        this.functions.validate();

                                                                                    }} className={(this.state.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                                                        <div>{weekday.name}</div>
                                                                                        <div className="ml-3">
                                                                                            <div className={(this.state.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                                                <CheckIcon className={(this.state.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>

                                                                    {
                                                                        (!this.state.order.googleBudgets || (Array.isArray(this.state.order.googleBudgets) && this.state.order.googleBudgets.length < 1)) &&
                                                                        <>
                                                                            <div className='flex flex-row border-t mt-4 pt-4'>
                                                                                <div className='flex flex-col flex-1 pr-4'>
                                                                                    <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                                                    <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                                                        <div className="flex flex-1 ml-5">
                                                                                            {(this.state.planer ? "Active" : "Disabled")}
                                                                                        </div>
                                                                                        <div className="relative overflow-hidden mr-5">
                                                                                            <SwitchTailwind
                                                                                                value={this.state.planer}
                                                                                                onSwitch={async () => {
                                                                                                    await this.promisedSetState({
                                                                                                        planer: !this.state.planer
                                                                                                    })
                                                                                                    this.functions.validate();
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='flex flex-1 pr-4'>
                                                                                    <div className='w-full'>
                                                                                        <InputTailwind
                                                                                            locked={!this.state.planer}
                                                                                            disabled={!this.state.planer}
                                                                                            label={"Monthly budget / channel"}
                                                                                            value={this.state.schedule_budget}
                                                                                            type={"number"}
                                                                                            onChange={async (value) => {
                                                                                                await this.promisedSetState({
                                                                                                    schedule_budget: value
                                                                                                })
                                                                                                this.functions.validate();
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    (this.state.order.maxBudgetSubAction || (!this.state.order.maxBudgetSubAction && Array.isArray(this.state.order.budgetPlans) && this.state.order.budgetPlans.length === 1)) &&
                                                                                    <div className='flex flex-1 pr-4'>
                                                                                        <div className='w-full'>
                                                                                            <InputTailwind
                                                                                                locked={true}
                                                                                                disabled={true}
                                                                                                label={"Order pacing"}
                                                                                                value={this.state.order.maxBudgetSubAction && this.state.order.maxBudgetSubAction.id ? (this.state.order.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.order.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : this.state.order.budgetPlans[0].sub_action.name}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className='flex flex-1 '>
                                                                                    <div className='w-full'>
                                                                                        <InputDatepickerTailwind
                                                                                            error={false}
                                                                                            disabled={!this.state.planer}
                                                                                            locked={!this.state.planer}
                                                                                            placeholder={moment(this.state.start_date).format("MM/DD/YYYY")}
                                                                                            label={"Start date"}
                                                                                            value={moment(this.state.start_date).format("YYYY-MM-DD")}
                                                                                            onChange={async (value) => {
                                                                                                this.state.start_date = moment(value).format("YYYY-MM-DD");
                                                                                                await this.promisedSetState({
                                                                                                    start_date: this.state.start_date
                                                                                                })
                                                                                                if (this.state.type.value === "template") {
                                                                                                    this.state.dates.start = this.state.start_date;
                                                                                                    await this.promisedSetState({
                                                                                                        dates: this.state.dates
                                                                                                    })
                                                                                                }
                                                                                                if (this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] === Infinity) {
                                                                                                    this.props.onDisable(true);
                                                                                                } else {
                                                                                                    if (this.state.type.value === "template") {
                                                                                                        if (this.state.dates.end) {
                                                                                                            if (moment(this.state.dates.end).isBefore(this.state.dates.start)) {
                                                                                                                this.props.onDisable(true);
                                                                                                            } else {
                                                                                                                this.props.onDisable(false);
                                                                                                            }
                                                                                                        } else {
                                                                                                            this.props.onDisable(false);
                                                                                                        }

                                                                                                    } else this.props.onDisable(false);
                                                                                                }
                                                                                                this.functions.validate();

                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.planer && this.state.schedule_budget > 0 && this.renders.budgetPlannerGraph().datasets[0].data[0] === Infinity &&
                                                                                <div className='flex flex-row border-t mt-4'>
                                                                                    <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                                                        OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            <SlideDown
                                                                                closed={(this.state.planer && Object.keys(this.state.weekdays).length > 0 && this.state.schedule_budget > 0 ? false : true)}
                                                                            >
                                                                                <div className=' h-64 border-t mt-4 pt-4'>
                                                                                    <Bar
                                                                                        options={{
                                                                                            maintainAspectRatio: false,
                                                                                            interaction: {
                                                                                                intersect: false,
                                                                                            },
                                                                                            legend: {
                                                                                                display: false
                                                                                            },
                                                                                            scales: {
                                                                                                yAxes: [{
                                                                                                    ticks: {
                                                                                                        suggestedMin: 0
                                                                                                    }
                                                                                                }]
                                                                                            },
                                                                                        }}
                                                                                        data={this.renders.budgetPlannerGraph()}
                                                                                    />
                                                                                </div>
                                                                            </SlideDown>
                                                                        </>
                                                                    }



                                                                </div>
                                                            </SlideDown>
                                                        </div>
                                                    </div>
                                                </SlideDown>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

export default NewCampaignWizard;
