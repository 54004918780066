import { KeyIcon, PlusIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import InputTailwind from './inputTailwind';
import SuccessModal from './successModal';
import TopNavigationAgent from './topNavigationAgent';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import { userRegister } from '../services/userRegister';
import OauthPopup from "react-oauth-popup";
import SwitchTailwind from './switchTailwind';
import currencies from "../assets/json/currencies.json";
import CopyButton from './copyButton';

class AccountAdform extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            me: {},
            is_manager: false,
            loading: false,
            loading_advertisers: false,
            advertiser: {},
            advertisers: [],
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending",
            agents: [],
            agent: { id: 0, name: "Select agent" },
            ocid: "",
            healthcheck: {},
            owner: false,
            disabled: false,
            throttling: {},
            currency: {},
            currencies: currencies
        }
    };

    async componentDidMount() {
        let url = "https://id.adform.com/sts/connect/authorize";
        url = url + "?scope=https://api.adform.com/scope/eapi https://api.adform.com/scope/ndw.public.fast.lines.readonly https://api.adform.com/scope/buyer.rtb.lineitem.readonly https://api.adform.com/scope/buyer.direct.lineitems https://api.adform.com/scope/buyer.direct.lineitems.readonly offline_access openid https://api.adform.com/scope/buyer.advertisers.readonly https://api.adform.com/scope/buyer.stats https://api.adform.com/scope/buyer.campaigns.api.readonly";
        url = url + "&response_type=code";
        url = url + "&client_id=reporting.adcredo@apps.adform.com";
        if (window.location.hostname.indexOf("dev") !== -1) {
            url = url + "&redirect_uri=https://dev.adcredo.io/callback/adform";
        } else if (window.location.hostname.indexOf("app") !== -1) {
            url = url + "&redirect_uri=https://app.adcredo.io/callback/adform";
        } else {
            url = url + "&redirect_uri=http://localhost:5001/callback/adform";
        }
        url = url + "&state=" + Math.floor((Math.random() * 9999999999) + 1);
        await this.promisedSetState({
            adform_url: url,
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me,
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {},
            currencies: this.state.currencies.map((item) => { item.id = item.name; return item; })
        });
        if (this.state.is_manager && !this.state.client.adform) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me,
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "adform",
                    token: token
                });
                if (this.props.onLogin) {
                    this.props.onLogin()
                }
                await self.promisedSetState({
                    user: { ...this.state.user, ...{ adform: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "adform"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ adform: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        listAdvertisers: async () => {
            await this.promisedSetState({
                loading_advertisers: true
            });
            try {
                let response = await this.calls.listAdvertisers();
                await this.promisedSetState({
                    advertisers: response.data
                });
            } catch (error) { }
            await this.promisedSetState({
                loading_advertisers: false
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                this.state.advertiser.currency = this.state.currency.id;
                await this.calls.connectToClient({
                    agent: this.state.agent,
                    advertiser: this.state.advertiser
                });
                this.state.client.adform = {
                    defaultAgent: this.state.agent,
                    advertiserId: this.state.advertiser.id,
                    currency: this.state.currency.id,
                    advertiserName: this.state.advertiser.name
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    currency: {},
                    client: this.state.client
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.client.adform);
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_account: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.adform;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    advertiser: {},
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents_pagination: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/adform/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adform/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        listAdvertisers: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adform/listAdvertisers?client=" + this.state.client.id + "&agent=" + this.state.agent.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=adform";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + ""}>

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Adform"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-adform-500 rounded-md flex justify-center items-center">
                        <div className="w-5 h-5">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/adform_icon.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Adform {(this.state.client && this.state.client.adform) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.adform) &&
                            <div className="text-xs text-gray-500">
                                Connect Adform to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.adform) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.adform) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                    if (this.state.is_manager && !this.state.client.adform) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            (
                                !this.state.user.id &&
                                this.state.client.adform &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.adform && this.state.client.adform.defaultAgent && this.state.client.adform.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.adform ? true : false}
                                onSwitch={async () => {
                                    if (!this.state.client.adform) {
                                        this.state.client.adform = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>
                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>
                    {
                        !this.state.user.id &&
                        <div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.adform}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.adform && this.state.me.adform.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.adform && this.state.client.adform.defaultAgent ? this.state.client.adform.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value,
                                        advertiser: {}
                                    });
                                    this.functions.listAdvertisers();
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (this.state.throttling.current) {
                                            //SKIP
                                        } else {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.client.adform || this.state.agent.id !== 0) &&
                        <div className="flex mt-5 items-center w-full">
                            <div className="flex-1">
                                <DropdownTailwind
                                    label={this.state.client.adform && this.state.client.adform.advertiserId ? "Advertiser" : "Advertisers"}
                                    loader={this.state.loading_advertisers}
                                    searchInput={true}
                                    locked={this.state.client.adform}
                                    selected={this.state.client.adform && this.state.client.adform.advertiserId ? { name: this.state.client.adform.advertiserName } : (this.state.advertiser.id ? this.state.advertiser : { name: "Select advertiser" })}
                                    options={this.state.advertisers}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            advertiser: value
                                        });
                                    }}
                                />
                            </div>
                            <CopyButton
                                buttonText="Copy"
                                disabled={!((this.state.client.adform && this.state.client.adform.advertiserId) || this.state.advertiser.id)}
                                copyText={(this.state.client.adform && this.state.client.adform.advertiserId) ? this.state.client.adform.advertiserId : this.state.advertiser.id}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        (this.state.advertiser.id || this.state.client.adform) &&
                        <div className="mt-5">
                            <DropdownTailwind
                                searchInput={true}
                                label={"Currency"}
                                locked={this.state.client.adform}
                                selected={this.state.client.adform && this.state.client.adform.currency ? { name: this.state.client.adform.currency } : (this.state.currency.id ? this.state.currency : { name: "Select currency" })}
                                options={this.state.currencies}
                                onChange={async (value) => {
                                    this.setState({
                                        currency: value
                                    });
                                }}
                            />
                        </div>
                    }
                    {
                        this.state.client.id &&
                        !this.state.client.adform &&
                        this.state.advertiser.id &&
                        <div className="flex w-full flex-row">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        this.functions.connectToClient();
                                    }
                                }}
                                className={(this.state.advertiser.id ? "bg-adform-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-4 shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Save Adform account</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-adform-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        (!this.state.user.adform || (this.state.user.adform && !this.state.user.adform.authenticated)) &&
                        <div className="mt-5">
                            <OauthPopup
                                url={this.state.adform_url}
                                onCode={(code) => {
                                    if (code) {
                                        this.functions.login(code);
                                    }
                                }}
                                onClose={() => {
                                    console.log('closed')
                                }}
                            >
                                <button className={(!(this.state.user.adform && this.state.user.adform.authenticated) ?
                                    "cursor-pointer bg-adform-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                                >
                                    <span>Login with Adform</span>
                                    {
                                        this.state.loading &&
                                        <div className={(!(this.state.user.adform && this.state.user.adform.authenticated) ? "bg-adform-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                            <div style={{ borderTopColor: "transparent" }}
                                                class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                </button>
                            </OauthPopup>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        this.state.user.adform && this.state.user.adform.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                }}
                                className={(!(this.state.user.adform && this.state.user.adform.authenticated) ?
                                    "cursor-pointer bg-adform-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.adform && this.state.user.adform.authenticated) ? "bg-adform-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        (this.state.error) &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        this.state.healthcheck && this.state.healthcheck.adform && this.state.healthcheck.adform.status != 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.adform.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.client.adform &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.adform && this.state.client.adform.defaultAgent && this.state.client.adform.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.adform &&
                        !(this.state.me.adform && this.state.me.adform.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated Adform, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.adform &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated Adform
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AccountAdform;
