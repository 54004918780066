import moment from 'moment';
class campaignLinkedin {
    static validate(campaign) {
        let error = {
            budget: false,
            start_date: false,
            end_date: false,
        };
        if (campaign.campaign_budget && campaign.campaign_budget.value) {
            if (!campaign.total_budget || (campaign.total_budget && (campaign.total_budget === "" || parseInt(campaign.total_budget) < 1 || isNaN(parseInt(campaign.total_budget))))) {
                error.budget = true;
            }
        }
        if (campaign.start_date && !campaign.status) {
            if (new Date(campaign.start_date).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime()) {
                error.start_date = true;
            }
        }
        if (!campaign.status && campaign.end_date) {
            if (new Date(campaign.end_date).getTime() <= new Date(campaign.start_date).getTime() || new Date(campaign.end_date).getTime() <= new Date().getTime()) {
                error.end_date = true;
            }
        }
        return error;
    }
}

export { campaignLinkedin }