import React, { Component, Fragment } from 'react';
import fuzzysort from "fuzzysort";
import FeatherIcon from "feather-icons-react";
import SlideDown from "react-slidedown";
import { BeatLoader } from "react-spinners";
import SwitchTailwind from "../switchTailwind";
import InputTailwind from "../inputTailwind";
import ReactTooltip from "react-tooltip";
import { CheckIcon, PencilAltIcon, XIcon } from "@heroicons/react/outline";
import MultiDropdown from "../multiDropdownTailwind";

class SelectCellDatasource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: {},
            accounts: [],
            campaigns: [],
            adgroups: [],
            ads: [],
            selected_channels_internal: {},
            selected_accounts_internal: {},
            selected_campaigns_internal: {},
            selected_adgroups_internal: {},
            selected_ads_internal: {},
            search_campaigns: "",
            search_placements: "",
            cm360_campaigns: [],
            cm360_sites: [],
            cm360_campaign_filter: [],
            cm360_site_filter: [],
            selected_data: [],
        };
    };

    async componentDidMount() {
        await this.promisedSetState({
            masterTemplate: this.props.masterTemplate,
            template: this.props.template,
            tab: this.props.tab ? this.props.tab : {},
            open: this.props.open,
            accounts: this.props.accounts ? this.props.accounts : [],
            campaigns: this.props.campaigns ? this.props.campaigns : [],
            adgroups: this.props.adgroups ? this.props.adgroups : [],
            ads: this.props.ads ? this.props.ads : [],
            selected_channels_internal: this.props.selectedChannelsInternal ? this.props.selectedChannelsInternal : {},
            selected_account_internal: this.props.selectedAccountsInternal ? this.props.selectedAccountsInternal : {},
            selected_campaigns_internal: this.props.selectedCampaignsInternal ? this.props.selectedCampaignsInternal : {},
            selected_adgroups_internal: this.props.selectedAdgroupsInternal ? this.props.selectedAdgroupsInternal : {},
            selected_ads_internal: this.props.selectedAdsInternal ? this.props.selectedAdsInternal : {}
        });

        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }

        this.functions.getCm360Camapigns();
        this.functions.getSelected();
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        await this.promisedSetState({
            masterTemplate: nextProps.masterTemplate,
            template: nextProps.template,
            tab: nextProps.tab ? nextProps.tab : {},
            open: nextProps.open,
            accounts: nextProps.accounts ? nextProps.accounts : [],
            campaigns: nextProps.campaigns ? nextProps.campaigns : [],
            adgroups: nextProps.adgroups ? nextProps.adgroups : [],
            ads: nextProps.ads ? nextProps.ads : [],
            selected_channels_internal: nextProps.selectedChannelsInternal ? nextProps.selectedChannelsInternal : {},
            selected_accounts_internal: nextProps.selectedAccountsInternal ? nextProps.selectedAccountsInternal : {},
            selected_campaigns_internal: nextProps.selectedCampaignsInternal ? nextProps.selectedCampaignsInternal : {},
            selected_adgroups_internal: nextProps.selectedAdgroupsInternal ? nextProps.selectedAdgroupsInternal : {},
            selected_ads_internal: nextProps.selectedAdsInternal ? nextProps.selectedAdsInternal : {},
        });

        if (!this.state.tab.settings) {
            this.state.tab.settings = {};
            await this.promisedSetState({
                tab: this.state.tab
            });
        }

        this.functions.getCm360Camapigns();
        this.functions.getSelected();
    }

    functions = {
        getChannelImage: (channel_name, extra_class = "", size) => {
            let channels = [
                { name: 'facebook', icon: 'facebook_icon.svg', style: { width: size == "small" ? "0.4rem" : '0.6rem' } },
                { name: 'google', icon: 'google_icon.svg', style: { width: '1.1rem' } },
                { name: 'google_analytics', icon: 'google_analytics_icon.png', style: { width: '0.75rem' } },
                { name: 'google_analytics_4', icon: 'ga_icon.png', style: { width: '0.75rem' } },
                { name: 'google_shopping', icon: 'google_shopping_icon.png', style: { width: '0.75rem' } },
                { name: 'google_search_console', icon: 'google_search_console.png', style: { width: '1.1rem' } },
                { name: 'adform', icon: 'adform_icon.png', style: { width: '0.75rem' } },
                { name: 'linkedin', icon: 'linkedin_icon.svg', style: { width: '1rem' } },
                { name: 'twitter', icon: 'twitter_icon.svg', style: { width: '0.75rem' } },
                { name: 'bidtheatre', icon: 'bidtheatre_icon.png', style: { width: '0.75rem' } },
                { name: 'snapchat', icon: 'snapchat.svg', style: { width: '0.75rem' } },
                { name: 'bing', icon: 'bing-logo.png', style: { width: '0.75rem' } },
                { name: 'dv360', icon: 'dv360-logo.svg', style: { width: '1rem' } },
                { name: 'cm360', icon: 'cm360-logo.png', style: { width: '1.1rem' } },
                { name: 'tiktok', icon: 'tiktok_icon.png', style: { width: '1rem' } }
            ];
            let channel = channels.find(channel => channel.name == channel_name);
            if (channel) {
                return (
                    <div>
                        <div
                            className={(size == "small" ? "h-7 w-7 " : "h-10 w-10 ") + "bg-" + channel.name.replace('_', '').replace('_', '') + "-500 mr-2 rounded flex justify-center align-middle items-center " + extra_class}>
                            <img className={channel.style ? "" : "w-3"}
                                style={channel.style ? channel.style : {}}
                                src={require('../../assets/images/' + channel.icon)} />
                        </div>
                    </div>
                )
            }
            return ''
        },
        getCm360Camapigns: () => {
            let cm360Placements = this.state.campaigns.filter(item => item.channel == "cm360");

            let campaigns = cm360Placements.map(item => {
                return {
                    id: item.campaignId,
                    name: item.campaignIdDimensionValue.name,
                }
            });

            let sites = cm360Placements.map(item => {
                return {
                    id: item.siteId,
                    name: item.siteIdDimensionValue.name,
                }
            });

            this.setState({
                cm360_campaigns: [...new Map(campaigns.map(item => [item.id, item])).values()],
                cm360_sites: [...new Map(sites.map(item => [item.id, item])).values()]
            })
        },
        getSelected: () => {
            let channels = [];

            console.log("this.state.selected_campaigns_internal", this.state.selected_campaigns_internal);
            console.log("this.state.selected_adgroups_internal", this.state.selected_adgroups_internal);

            Object.values(this.state.selected_campaigns_internal).forEach(campaign => {
                channels.push({
                    id: campaign.id,
                    type: "campaign",
                    channel: campaign.channel,
                    name: campaign.name,
                });
            });

            Object.values(this.state.selected_adgroups_internal).forEach(adgroup => {
                channels.push({
                    id: adgroup.id,
                    type: "adgroup",
                    channel: adgroup.channel,
                    name: adgroup.name,
                });
            });

            Object.values(this.state.selected_ads_internal).forEach(ad => {
                channels.push({
                    id: ad.id,
                    type: "ad",
                    channel: ad.channel,
                    name: ad.name,
                });
            });

            this.setState({
                selected_data: channels
            });
        },
        color: (item) => {
            let colors = {
                hex: "",
                rgb: ""
            }
            colors.hex = "#3A5998";
            if (item.channel === "facebook") {
                colors.hex = "#3A5998";
            } else if (item.channel === "google") {
                colors.hex = "#4384F4";
            } else if (item.channel === "linkedin") {
                colors.hex = "#0D65A1";
            } else if (item.channel === "google_analytics") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_analytics_4") {
                colors.hex = "#e8710a";
            } else if (item.channel === "google_shopping") {
                colors.hex = "#34A853";
            } else if (item.channel === "adform") {
                colors.hex = "#60C1A4";
            } else if (item.channel === "bidtheatre") {
                colors.hex = "#f48e22";
            } else if (item.channel === "tiktok") {
                colors.hex = "#000000";
            } else if (item.channel === "twitter") {
                colors.hex = "#50ABF1";
            } else if (item.channel === "snapchat") {
                colors.hex = "#FFFC00";
            } else if (item.channel === "bing") {
                colors.hex = "#0C8484";
            } else if (item.channel === "dv360") {
                colors.hex = "#34A853";
            } else if (item.channel === "cm360") {
                colors.hex = "#ffffff";
            } else if (item.channel === "google_search_console") {
                colors.hex = "#e8710a";
            }
            colors.rgb = colors.hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
                , (m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16));
            return colors;
        },
        valid: () => {
            return true;
        },
        multipleLevelsSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 1
        },
        anythingSelected: () => {
            let total = 0;
            if (this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0) {
                total = total + 1;
            }
            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0) {
                total = total + 1;
            }
            return total > 0
        }
    };

    renders = {
        preview: () => {
            try {
                return this.state.tab.celltype.value === "preview"
            } catch (error) {
                return false
            }
        },
        search: (search, options) => {
            if (!search || search == "") {
                return options;
            }
            let result = fuzzysort.go(search, options.map((item) => { return item.name }));
            let keywords = [];
            let old_keywords = JSON.parse(JSON.stringify(options));
            result.map((item) => {
                for (let i = 0; i < old_keywords.length; i++) {
                    if (item.target && item.target.toLowerCase() === old_keywords[i].name.toLowerCase()) {
                        let html_string = "";
                        if (Array.isArray(item.indexes) && item.indexes.length > 0) {
                            for (let m = 0; m < item.target.length; m++) {
                                if (!item.indexes.includes(m)) {
                                    html_string = html_string + '<span class="opacity-75">' + item.target.charAt(m) + '</span>';
                                } else {
                                    html_string = html_string + '<span class="">' + item.target.charAt(m) + '</span>';
                                }
                            }
                        }
                        old_keywords[i].html = html_string;
                        keywords.push(old_keywords[i]);
                        old_keywords.splice(i, 1);
                        break;
                    }
                }
            });
            return keywords;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        const renderCampaigns = (account) => {
            let campaigns = this.state.campaigns.filter((item) => {
                return item.client_id == account.client && item.channel == account.channel;
            }).filter((item) => {
                if (account.channel == 'cm360') {
                    const rules = {
                        filterByPlacement: (item, filter_name) => typeof filter_name == "string" && filter_name !== ""
                            ? item.name.toLowerCase().indexOf(filter_name.toLowerCase()) > -1
                            : true,
                        filterByCampaign: (item, campaigns) => campaigns.length
                            ? !!campaigns.find(campaign => campaign.id === item.campaignId)
                            : true,
                        filterBySite: (item, sites) => sites.length
                            ? !!sites.find(site => site.id === item.siteId)
                            : true,
                    };

                    const filters = {
                        filterByPlacement: this.state.search_placements,
                        filterByCampaign: this.state.cm360_campaign_filter,
                        filterBySite: this.state.cm360_site_filter,
                    }

                    return Object.keys(filters).every(rule =>
                        rules[rule](item, filters[rule])
                    )
                    // ) && item.kind !== "dfareporting#floodlightActivity"  //hide activities cm360
                } else if (typeof this.state[`search_campaigns_${account.id}`] == "string" && this.state[`search_campaigns_${account.id}`] !== "") {
                    return item.name.toLowerCase().indexOf(this.state[`search_campaigns_${account.id}`].toLowerCase()) > -1;
                } else {
                    return true;
                }
            }).sort((a, b) => {
                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
            }).filter((campaign) => {
                if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                    return true;
                } else if (account.show_paused || (typeof this.state[`search_campaigns_${account.id}`] == "string" && this.state[`search_campaigns_${account.id}`] !== "")) {
                    return true;
                } else {
                    return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                }
            })

            if (campaigns.length > 0) {
                return campaigns.map((campaign, index) => {
                    return (
                        <Fragment>
                            <div
                                className="flex flex-1 flex-row justify-center mb-2 pl-12">
                                <div onClick={() => {
                                    //GA ONLY HAVE CAMPAIGN LEVEL
                                    if (campaign.channel !== "google_analytics" && campaign.channel !== "google_analytics_4") {
                                        if (!campaign.open) {
                                            if (this.props.getAdgroups) {
                                                this.props.getAdgroups(campaign);
                                                campaign.open = true;
                                            }
                                        } else {
                                            campaign.open = false;
                                        }
                                        this.setState({
                                            campaigns: this.state.campaigns
                                        }, () => {
                                            if (this.props.onCampaigns) {
                                                this.props.onCampaigns(this.state.campaigns);
                                            }
                                        })
                                    }
                                }}
                                    className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    {
                                        campaign.channel !== "google_analytics" &&
                                        campaign.channel !== "google_analytics_4" &&
                                        !campaign.open && !campaign.loading &&
                                        <FeatherIcon className={'stroke-current'}
                                            size={16}
                                            icon="plus" />
                                    }
                                    {
                                        campaign.channel !== "google_analytics" &&
                                        campaign.channel !== "google_analytics_4" &&
                                        campaign.open && !campaign.loading &&
                                        <FeatherIcon className={'stroke-current'}
                                            size={16}
                                            icon="minus" />
                                    }
                                    {
                                        campaign.channel !== "google_analytics" &&
                                        campaign.channel !== "google_analytics_4" &&
                                        campaign.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={5}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    }
                                </div>
                                {
                                    !this.renders.preview() &&
                                    <div onClick={() => {

                                        if (!campaign.is_loading) {
                                            if (this.state.selected_campaigns_internal[campaign.id]) {
                                                delete this.state.selected_campaigns_internal[campaign.id]
                                            } else {
                                                let colors = this.functions.color(campaign);
                                                campaign.hex = colors.hex;
                                                campaign.rgb = colors.rgb;
                                                this.state.selected_campaigns_internal[campaign.id] = campaign;
                                            }
                                            this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                        }

                                    }}
                                        className={(this.state.selected_campaigns_internal[campaign.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.selected_campaigns_internal[campaign.id] &&
                                            <FeatherIcon className={'stroke-current'}
                                                size={14}
                                                icon="check" />
                                        }
                                        {
                                            campaign.is_loading &&
                                            <div
                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={4}
                                                    color={'#060534'}
                                                    loading={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="flex flex-1 truncate">
                                    <div className="flex flex-col truncate justify-center">
                                        <div data-tip='' data-for={campaign.id + "_" + index} key={campaign.id} className="font-medium truncate relative text-sm">
                                            {campaign.name}
                                            <ReactTooltip effect='solid' id={campaign.id + "_" + index}>
                                                {campaign.name}
                                            </ReactTooltip>
                                        </div>
                                        <div className="text-xxs flex flex-row">
                                            {
                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled") &&
                                                <div className="text-green-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "paused") &&
                                                <div className="text-orange-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() === "ended" || campaign.status.toLowerCase() === "removed") &&
                                                <div className="text-red-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof campaign.status == "string" && (campaign.status.toLowerCase() !== "active" && campaign.status.toLowerCase() !== "enabled" && campaign.status.toLowerCase() !== "ended" && campaign.status.toLowerCase() !== "removed" && campaign.status.toLowerCase() !== "paused") &&
                                                <div className="text-gray-500 font-medium text-xxs capitalize">{campaign.status.toLowerCase()}</div>
                                            }
                                            {
                                                this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                    return this.state.selected_adgroups_internal[key].client == campaign.client && this.state.selected_adgroups_internal[key].campaign == campaign.id;
                                                }).length > 0 &&
                                                <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Ads" : "Adgroups"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                            }
                                            {
                                                this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                    return this.state.selected_ads_internal[key].client == campaign.client && this.state.selected_ads_internal[key].campaign == campaign.id;
                                                }).length > 0 &&
                                                <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Creatives" : "Ads"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.selected_campaigns_internal[campaign.id] &&
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                                this.state.tab.settings.source_name = {};
                                            } else if (!this.state.tab.settings.source_name) {
                                                this.state.tab.settings.source_name = {};
                                            }
                                            if (campaign.id in this.state.tab.settings.source_name) {
                                                delete this.state.tab.settings.source_name[campaign.id];
                                            } else {
                                                this.state.tab.settings.source_name[campaign.id] = "";
                                            }
                                            if (this.props.onTab) {
                                                this.props.onTab(this.state.tab);
                                            }
                                        }} className="flex flex-1 pl-2 pt-2 justify-end">
                                            <div>
                                                <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                    {this.state.tab.settings && this.state.tab.settings.source_name && (campaign.id in this.state.tab.settings.source_name) && this.state.selected_campaigns_internal[campaign.id] ? "Remove" : "Edit"} name
                                                    {this.state.tab.settings && this.state.tab.settings.source_name && (campaign.id in this.state.tab.settings.source_name) && this.state.selected_campaigns_internal[campaign.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                this.state.tab.settings &&
                                this.state.tab.settings.source_name &&
                                (campaign.id in this.state.tab.settings.source_name) &&
                                this.state.selected_campaigns_internal[campaign.id] &&
                                <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                    <div className="flex flex-1">
                                        <div className="w-full pl-12">
                                            <InputTailwind
                                                label={`Custom name for ${account.channel == "cm360" ? 'placement' : 'campaign'}`}
                                                value={this.state.tab.settings.source_name[campaign.id]}
                                                onChange={(value) => {
                                                    this.state.tab.settings.source_name[campaign.id] = value;
                                                    console.log(this.state.tab.settings.source_name);
                                                    if (this.props.onTab) {
                                                        this.props.onTab(this.state.tab);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !this.state.masterTemplate &&
                                !this.state.template &&
                                campaign.open &&
                                !campaign.loading &&
                                <div className="pl-24">
                                    <div className="text-xs font-medium mb-1">{campaign.channel == "cm360" ? "Ads" : "Adgroups"}</div>
                                    <div
                                        className="flex flex-1 mb-4 flex-col justify-center">
                                        <InputTailwind
                                            label={null}
                                            placeholder={account.channel == "cm360" ? "Search ads ..." : "Search adgroups ..."}
                                            value={this.state[`search_adgroups_${campaign.id}`]}
                                            clearable={true}
                                            onChange={(value) => {
                                                this.setState({
                                                    [`search_adgroups_${campaign.id}`]: value
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                renderAdgroups(campaign, account)
                            }
                            {
                                (this.state.campaigns.length - 1) !== index &&
                                <div className="border-t flex-1 flex mb-2" />
                            }
                        </Fragment>
                    )
                })
            } else {
                return (
                    <div className="text-red-500 pl-12 rounded-md mb-2 p-4 text-sm font-medium">
                        No matching {account.channel == "cm360" ? 'placements' : 'campaigns'}
                    </div>
                )
            }
        }

        const renderAdgroups = (campaign, account) => {
            let adgroups = this.state.adgroups.filter((adgroup) => {
                return adgroup.campaign == campaign.id && campaign.open
            }).filter((adgroup) => {
                if (typeof this.state[`search_adgroups_${campaign.id}`] == "string" && this.state[`search_adgroups_${campaign.id}`] !== "") {
                    return adgroup.name.toLowerCase().indexOf(this.state[`search_adgroups_${campaign.id}`].toLowerCase()) > -1;
                } else {
                    return true;
                }
            }).sort((a, b) => {
                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
            })

            if (adgroups.length > 0) {
                return adgroups.map((adgroup, index) => {
                    return (
                        <Fragment>
                            <div
                                className="flex flex-1 flex-row justify-left pl-24 mb-2">
                                <div onClick={() => {
                                    if (!adgroup.open) {
                                        this.props.getAds(adgroup);
                                        adgroup.open = true;
                                    } else {
                                        adgroup.open = false;
                                    }
                                    this.props.onAdgroups(this.state.adgroups);
                                }}
                                    className="cursor-pointer min-w-10 min-h-10 h-10 w-10 flex rounded font-bold mr-2 bg-purple-100 justify-center align-middle items-center text-purple-500">
                                    {
                                        !adgroup.open && !adgroup.loading &&
                                        <FeatherIcon
                                            className={'stroke-current'}
                                            size={16} icon="plus" />
                                    }
                                    {
                                        adgroup.open && !adgroup.loading &&
                                        <FeatherIcon
                                            className={'stroke-current'}
                                            size={16} icon="minus" />
                                    }
                                    {
                                        adgroup.loading &&
                                        <BeatLoader
                                            sizeUnit={"px"}
                                            size={5}
                                            color={'#060534'}
                                            loading={true}
                                        />
                                    }
                                </div>
                                {
                                    !this.renders.preview() &&
                                    <div onClick={() => {
                                        if (!adgroup.is_loading) {
                                            if (this.state.selected_adgroups_internal[adgroup.id]) {
                                                delete this.state.selected_adgroups_internal[adgroup.id];
                                            } else {

                                                let colors = this.functions.color(adgroup);
                                                adgroup.hex = colors.hex;
                                                adgroup.rgb = colors.rgb;
                                                this.state.selected_adgroups_internal[adgroup.id] = adgroup;

                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                    this.state.tab.breakdown = {
                                                        title: "Overview",
                                                        subtitle: "Aggregated data",
                                                        icon: "globe",
                                                        value: "overview"
                                                    };
                                                }

                                            }
                                            this.props.onTab(this.state.tab);
                                            this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                        }
                                    }}
                                        className={(this.state.selected_adgroups_internal[adgroup.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 min-w-10 min-h-10 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.selected_adgroups_internal[adgroup.id] &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                        {
                                            adgroup.is_loading &&
                                            <div
                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={5}
                                                    color={'#060534'}
                                                    loading={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                <div className="flex flex-col truncate justify-center">
                                    <div data-tip='' data-for={adgroup.id + "_" + index} key={adgroup.id} className="font-medium truncate relative text-sm">
                                        {adgroup.name}
                                        <ReactTooltip effect='solid' id={adgroup.id + "_" + index}>
                                            {adgroup.name}
                                        </ReactTooltip>
                                    </div>
                                    <div className="text-xxs flex flex-row">
                                        {
                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "active" || adgroup.status.toLowerCase() === "enabled") &&
                                            <div className="text-green-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                        }
                                        {
                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "paused") &&
                                            <div className="text-orange-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                        }
                                        {
                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() === "ended" || adgroup.status.toLowerCase() === "removed") &&
                                            <div className="text-red-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                        }
                                        {
                                            typeof adgroup.status == "string" && (adgroup.status.toLowerCase() !== "active" && adgroup.status.toLowerCase() !== "enabled" && adgroup.status.toLowerCase() !== "ended" && adgroup.status.toLowerCase() !== "removed" && adgroup.status.toLowerCase() !== "paused") &&
                                            <div className="text-gray-500 font-medium text-xxs capitalize">{adgroup.status.toLowerCase()}</div>
                                        }
                                        {
                                            this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                return this.state.selected_ads_internal[key].client == adgroup.client && this.state.selected_ads_internal[key].adgroup == adgroup.id;
                                            }).length > 0 &&
                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Creatives" : "Ads"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.selected_adgroups_internal[adgroup.id] &&
                                    <div onClick={() => {
                                        if (!this.state.tab.settings) {
                                            this.state.tab.settings = { title: "" };
                                            this.state.tab.settings.source_name = {};
                                        } else if (!this.state.tab.settings.source_name) {
                                            this.state.tab.settings.source_name = {};
                                        }
                                        if (adgroup.id in this.state.tab.settings.source_name) {
                                            delete this.state.tab.settings.source_name[adgroup.id];
                                        } else {
                                            this.state.tab.settings.source_name[adgroup.id] = "";
                                        }
                                        if (this.props.onTab) {
                                            this.props.onTab(this.state.tab);
                                        }
                                    }} className="flex flex-1 pl-2 pt-2 justify-end">
                                        <div>
                                            <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                {this.state.tab.settings && this.state.tab.settings.source_name && (adgroup.id in this.state.tab.settings.source_name) && this.state.selected_adgroups_internal[adgroup.id] ? "Remove" : "Edit"} name
                                                {this.state.tab.settings && this.state.tab.settings.source_name && (adgroup.id in this.state.tab.settings.source_name) && this.state.selected_adgroups_internal[adgroup.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                this.state.tab.settings &&
                                this.state.tab.settings.source_name &&
                                (adgroup.id in this.state.tab.settings.source_name) &&
                                this.state.selected_adgroups_internal[adgroup.id] &&
                                <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                    <div className="flex flex-1 pl-12">
                                        <div className="w-full">
                                            <InputTailwind
                                                label={`Custom name for ${account.channel == "cm360" ? 'ad' : 'adgroup'}`}
                                                value={this.state.tab.settings.source_name[adgroup.id]}
                                                onChange={(value) => {
                                                    this.state.tab.settings.source_name[adgroup.id] = value;
                                                    //console.log(this.state.tab.settings.source_name);
                                                    if (this.props.onTab) {
                                                        this.props.onTab(this.state.tab);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !this.state.masterTemplate &&
                                !this.state.template &&
                                adgroup.open &&
                                !adgroup.loading &&
                                <div className="ml-12 pl-24">
                                    <div className="text-xs font-medium mb-1">{account.channel == "cm360" ? "Creatives" : "Ads"}</div>
                                    <div
                                        className="flex flex-1 mb-4 flex-col justify-center">
                                        <InputTailwind
                                            label={null}
                                            placeholder={account.channel == "cm360" ? "Search creatives ..." : "Search ads ..."}
                                            value={this.state[`search_ads_${adgroup.id}`]}
                                            clearable={true}
                                            onChange={(value) => {
                                                this.setState({
                                                    [`search_ads_${adgroup.id}`]: value
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                renderAds(adgroup, account)
                            }
                        </Fragment>
                    )
                })
            } else if (campaign.open && !campaign.loading && adgroups.length === 0) {
                return (
                    <div className="text-red-500 pl-24 rounded-md mb-2 p-4 text-sm font-medium">
                        No matching {account.channel == "cm360" ? 'ads' : 'adgroups'}
                    </div>
                )
            } else {
                return ''
            }
        }

        const renderAds = (adgroup, account) => {
            let ads = this.state.ads.filter((ad) => {
                return ad.adgroup == adgroup.id && adgroup.open
            }).filter((ads) => {
                if (typeof this.state[`search_ads_${adgroup.id}`] == "string" && this.state[`search_ads_${adgroup.id}`] !== "") {
                    return ads.name.toLowerCase().indexOf(this.state[`search_ads_${adgroup.id}`].toLowerCase()) > -1;
                } else {
                    return true;
                }
            }).sort((a, b) => {
                let a_active = typeof a.status == "string" && (a.status.toLowerCase() === "active" || a.status.toLowerCase() === "enabled");
                let b_active = typeof b.status == "string" && (b.status.toLowerCase() === "active" || b.status.toLowerCase() === "enabled");
                return (a_active === b_active) ? 0 : a_active ? -1 : 1;
            })

            if (ads.length > 0) {
                return ads.map((ad, index) => {
                    return (
                        <Fragment>
                            <div
                                className="flex flex-1 flex-row justify-left ml-12 pl-24 mb-2">
                                <div className="h-10 w-10 flex rounded mr-2 bg-purple-100">

                                </div>
                                {
                                    !this.renders.preview() &&
                                    <div onClick={() => {
                                        if (!ad.is_loading) {
                                            if (this.state.selected_ads_internal[ad.id]) {
                                                delete this.state.selected_ads_internal[ad.id];
                                            } else {

                                                let colors = this.functions.color(ad);
                                                ad.hex = colors.hex;
                                                ad.rgb = colors.rgb;
                                                this.state.selected_ads_internal[ad.id] = ad;

                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                    this.state.tab.breakdown = {
                                                        title: "Overview",
                                                        subtitle: "Aggregated data",
                                                        icon: "globe",
                                                        value: "overview"
                                                    };
                                                }

                                            }
                                            this.props.onTab(this.state.tab);
                                            this.props.onSelectedAds(this.state.selected_ads_internal);
                                        }
                                    }}
                                        className={(this.state.selected_ads_internal[ad.id] ? "border-purple-500 bg-purple-100" : "") + " min-h-10 min-w-10 cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.selected_ads_internal[ad.id] &&
                                            <FeatherIcon
                                                className={'stroke-current'}
                                                size={16}
                                                icon="check" />
                                        }
                                        {
                                            ad.is_loading &&
                                            <div
                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                <BeatLoader
                                                    sizeUnit={"px"}
                                                    size={5}
                                                    color={'#060534'}
                                                    loading={true}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.renders.preview() &&
                                    <div onClick={() => {
                                        if (!ad.is_loading) {
                                            if (ad.id in this.state.selected_ads_internal) {
                                                delete this.state.selected_ads_internal[ad.id];
                                            } else {
                                                for (let key in this.state.selected_ads_internal) {
                                                    delete this.state.selected_ads_internal[key];
                                                }
                                                let colors = this.functions.color(ad);
                                                ad.hex = colors.hex;
                                                ad.rgb = colors.rgb;
                                                this.state.selected_ads_internal[ad.id] = ad;

                                                //SET BREAKDOWN TO OVERVIEW IF SELECTED
                                                if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                    this.state.tab.breakdown = {
                                                        title: "Overview",
                                                        subtitle: "Aggregated data",
                                                        icon: "globe",
                                                        value: "overview"
                                                    };
                                                }
                                            }
                                            this.props.onTab(this.state.tab);
                                            this.props.onSelectedAds(this.state.selected_ads_internal);
                                        }
                                    }}
                                        className={((this.state.selected_ads_internal[ad.id]) ? "border-purple-500" : "") + " min-h-10 min-w-10 h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                        {
                                            this.state.selected_ads_internal[ad.id] &&
                                            <div
                                                className="w-full h-full rounded-full bg-purple-500" />
                                        }
                                    </div>
                                }
                                <div class="flex truncate flex-1">
                                    <div className="flex flex-col truncate justify-center">
                                        <div data-tip='' data-for={ad.id + "_" + index} key={ad.id} className="font-medium truncate relative text-sm">
                                            {ad.name}
                                            {
                                                !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                <>
                                                    {
                                                        Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                        <>
                                                            {ad.headlines[0]}
                                                        </>
                                                    }
                                                </>
                                            }
                                            <ReactTooltip effect='solid' id={ad.id + "_" + index}>
                                                <span>
                                                    {ad.name}
                                                    {
                                                        !ad.name && ad.advertising_type === "RESPONSIVE_DISPLAY_AD" &&
                                                        <>
                                                            {
                                                                Array.isArray(ad.headlines) && ad.headlines.length > 0 &&
                                                                <>
                                                                    {ad.headlines[0]}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div
                                            className="text-xxs flex flex-row">
                                            {
                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "active" || ad.status.toLowerCase() === "enabled") &&
                                                <div className="text-green-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "paused") &&
                                                <div className="text-orange-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof ad.status == "string" && (ad.status.toLowerCase() === "ended" || ad.status.toLowerCase() === "removed") &&
                                                <div className="text-red-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                            }
                                            {
                                                typeof ad.status == "string" && (ad.status.toLowerCase() !== "active" && ad.status.toLowerCase() !== "enabled" && ad.status.toLowerCase() !== "ended" && ad.status.toLowerCase() !== "removed" && ad.status.toLowerCase() !== "paused") &&
                                                <div className="text-gray-500 font-medium text-xxs capitalize">{ad.status.toLowerCase()}</div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.selected_ads_internal[ad.id] &&
                                        <div onClick={() => {
                                            if (!this.state.tab.settings) {
                                                this.state.tab.settings = { title: "" };
                                                this.state.tab.settings.source_name = {};
                                            } else if (!this.state.tab.settings.source_name) {
                                                this.state.tab.settings.source_name = {};
                                            }
                                            if (ad.id in this.state.tab.settings.source_name) {
                                                delete this.state.tab.settings.source_name[ad.id];
                                            } else {
                                                this.state.tab.settings.source_name[ad.id] = "";
                                            }
                                            if (this.props.onTab) {
                                                this.props.onTab(this.state.tab);
                                            }
                                        }} className="flex flex-1 pl-2 pt-2 justify-end">
                                            <div>
                                                <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 flex items-center rounded-full whitespace-no-wrap font-medium cursor-pointer text-xxs">
                                                    {this.state.tab.settings && this.state.tab.settings.source_name && (ad.id in this.state.tab.settings.source_name) && this.state.selected_ads_internal[ad.id] ? "Remove" : "Edit"} name
                                                    {this.state.tab.settings && this.state.tab.settings.source_name && (ad.id in this.state.tab.settings.source_name) && this.state.selected_ads_internal[ad.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                this.state.tab.settings &&
                                this.state.tab.settings.source_name &&
                                (ad.id in this.state.tab.settings.source_name) &&
                                this.state.selected_ads_internal[ad.id] &&
                                <div className="flex flex-1 w-full -mt-1 pl-24 mb-2">
                                    <div className="flex flex-1 pl-12">
                                        <div className="w-full">
                                            <InputTailwind
                                                label={`Custom name for ${account.channel == "cm360" ? 'creative' : 'ad'}`}
                                                value={this.state.tab.settings.source_name[ad.id]}
                                                onChange={(value) => {
                                                    this.state.tab.settings.source_name[ad.id] = value;
                                                    //console.log(this.state.tab.settings.source_name);
                                                    if (this.props.onTab) {
                                                        this.props.onTab(this.state.tab);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Fragment>
                    )
                })
            } else if (adgroup.open && !adgroup.loading && ads.length === 0) {
                return (
                    <div className="text-red-500 ml-12 pl-24 rounded-md mb-2 p-4 text-sm font-medium">
                        No matching {account.channel == "cm360" ? 'creatives' : 'ads'}
                    </div>
                )
            }
        }

        return (
            <>
                <div
                    className={(this.functions.valid() ? " " : " opacity-50 ") + " pt-6 px-4 sm:px-6 pb-6 border-b"}>
                    <div className="flex flex-1 flex-row justify-center items-center">
                        <div onClick={() => {
                            if (this.props.onToggle) {
                                this.props.onToggle(this.state.open);
                            }
                        }} className="flex flex-col flex-1 cursor-pointer">
                            <h3 className="mb-0 text-sm font-semibold">
                                Select data source
                            </h3>
                            {
                                !this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    Display data from selected data sources
                                </p>
                            }
                            {
                                this.functions.anythingSelected() &&
                                <p className="text-xxs flex items-center text-gray-700 leading-tight">
                                    You have selected
                                    {
                                        this.state.selected_channels_internal && Object.keys(this.state.selected_channels_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_channels_internal).length} channels</span>
                                    }
                                    {
                                        this.state.selected_accounts_internal && Object.keys(this.state.selected_accounts_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_accounts_internal).length} accounts</span>
                                    }
                                    {
                                        this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_campaigns_internal).length} campaigns</span>
                                    }
                                    {
                                        this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_adgroups_internal).length} adgroups</span>
                                    }
                                    {
                                        this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).length > 0 &&
                                        <span className="font-bold ml-1"> {Object.keys(this.state.selected_ads_internal).length} ads</span>
                                    }
                                </p>
                            }
                        </div>
                        <div className="flex flex-row">
                            {
                                this.functions.anythingSelected()
                                &&
                                <div
                                    className=" text-purple-500 border-purple-500 border-2 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                    <FeatherIcon className={'stroke-current'} size={16}
                                        icon={"check"} />
                                </div>
                            }
                            <div onClick={() => {
                                if (this.props.onToggle) {
                                    this.props.onToggle(this.state.open);
                                }
                            }}
                                className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold justify-center align-middle items-center">
                                {
                                    !this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-down"} />
                                }
                                {
                                    this.state.open &&
                                    <FeatherIcon className={'stroke-current'} size={17}
                                        icon={"chevron-up"} />
                                }
                            </div>
                        </div>
                    </div>
                    <SlideDown closed={!this.state.open}>
                        {
                            this.state.selected_data.length > 0 &&
                            <div>
                                <div className="w-full h-6" />
                                <div className="flex flex-wrap">
                                    {
                                        this.state.selected_data.map(selected => {
                                            return (
                                                <div className="inline-flex mb-2 mr-2 pl-1 pr-3 pt-1 pb-1 rounded-full bg-gray-100">
                                                    <div>
                                                        {this.functions.getChannelImage(selected.channel, "rounded-full", "small")}
                                                    </div>
                                                    <div className="flex flex-col justify-center h-full">
                                                        <div className="text-xxs font-medium">{selected.name}</div>
                                                        <div className="text-xxxs capitalize">{selected.type}</div>
                                                    </div>
                                                    <div
                                                        className="flex cursor-pointer"
                                                        onClick={() => {
                                                            switch (selected.type) {
                                                                case 'campaign':
                                                                    if (this.state.selected_campaigns_internal[selected.id]) {
                                                                        delete this.state.selected_campaigns_internal[selected.id]
                                                                        this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                                    }
                                                                    break;
                                                                case 'adgroup':
                                                                    if (this.state.selected_adgroups_internal[selected.id]) {
                                                                        delete this.state.selected_adgroups_internal[selected.id]
                                                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                                    }
                                                                    break;
                                                                case 'ad':
                                                                    if (this.state.selected_ads_internal[selected.id]) {
                                                                        delete this.state.selected_ads_internal[selected.id]
                                                                        this.props.onSelectedAds(this.state.selected_ads_internal);
                                                                    }
                                                                    break;
                                                            }
                                                        }}>
                                                        <XIcon className="w-5 ml-2" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            (this.state.masterTemplate || this.state.template) &&
                            <Fragment>
                                <div className="w-full h-4" />
                            </Fragment>
                        }
                        {
                            (this.state.masterTemplate || this.state.template) &&
                            [
                                { id: "adform", name: "adform", value: "adform", channel: "adform" },
                                { id: "bidtheatre", name: "bidtheatre", value: "bidtheatre", channel: "bidtheatre" },
                                { id: "bing", name: "bing", value: "bing", channel: "bing" },
                                { id: "dv360", name: "dv360", value: "dv360", channel: "dv360" },
                                { id: "cm360", name: "cm360", value: "cm360", channel: "cm360" },
                                { id: "facebook", name: "facebook", value: "facebook", channel: "facebook" },
                                { id: "google", name: "google", value: "google", channel: "google" },
                                { id: "google_analytics_4", name: "Google Analytics 4", value: "google_analytics_4", channel: "google_analytics_4" },
                                { id: "linkedin", name: "linkedin", value: "linkedin", channel: "linkedin" },
                                { id: "snapchat", name: "snapchat", value: "snapchat", channel: "snapchat" },
                                { id: "tiktok", name: "tiktok", value: "tiktok", channel: "tiktok" },
                                { id: "twitter", name: "twitter", value: "twitter", channel: "twitter" },
                                { id: "google_search_console", name: "Google Search Console", value: "google_search_console", channel: "google_search_console" },
                            ].map((account, index) => {
                                return (
                                    <div className="flex flex-1 flex-row justify-center mb-2">
                                        <div className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                        </div>
                                        {
                                            this.renders.preview() &&
                                            <div onClick={() => {
                                                if (!account.is_loading) {
                                                    if (this.state.selected_channels_internal[account.id]) {
                                                        delete this.state.selected_channels_internal[account.id];
                                                    } else {
                                                        this.state.selected_channels_internal = {};
                                                        let colors = this.functions.color(account);
                                                        account.hex = colors.hex;
                                                        account.rgb = colors.rgb;
                                                        this.state.selected_channels_internal[account.id] = account;
                                                        if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                            this.state.tab.breakdown = {
                                                                title: "Ads",
                                                                subtitle: "Ads",
                                                                icon: "globe",
                                                                value: "ads"
                                                            };
                                                        }
                                                    }
                                                    this.props.onTab(this.state.tab);
                                                    this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                }
                                            }}
                                                className={((this.state.selected_channels_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                {
                                                    this.state.selected_channels_internal[account.id] &&
                                                    <div
                                                        className="w-full h-full rounded-full bg-purple-500" />
                                                }
                                            </div>
                                        }
                                        {
                                            !this.renders.preview() &&
                                            <div onClick={() => {
                                                if (!account.is_loading) {
                                                    if (this.state.selected_channels_internal[account.id]) {
                                                        delete this.state.selected_channels_internal[account.id]
                                                    } else {
                                                        let colors = this.functions.color(account);
                                                        account.hex = colors.hex;
                                                        account.rgb = colors.rgb;
                                                        this.state.selected_channels_internal[account.id] = account;
                                                    }
                                                    this.props.onSelectedChannels(this.state.selected_channels_internal);
                                                }
                                            }}
                                                className={(this.state.selected_channels_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                {
                                                    this.state.selected_channels_internal[account.id] &&
                                                    <FeatherIcon className={'stroke-current'}
                                                        size={14}
                                                        icon="check" />
                                                }
                                                {
                                                    account.is_loading &&
                                                    <div
                                                        className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                        <BeatLoader
                                                            sizeUnit={"px"}
                                                            size={4}
                                                            color={'#060534'}
                                                            loading={true}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {
                                            this.functions.getChannelImage(account.channel)
                                        }
                                        <div className="flex flex-1 flex-col truncate">
                                            <div className="font-semibold text-sm truncate">
                                                {account.name}
                                            </div>
                                            <div className="text-xxs flex flex-row">
                                                Account level
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.masterTemplate &&
                            <div>
                                <div className="text-xs font-bold mt-4 mb-1">
                                    Account level data
                                </div>
                                <div className='flex flex-col w-full'>
                                    <div className='flex items-center justify-center border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                        <div className={"flex flex-1 text-sm ml-5"}>
                                            {this.state.tab.settings && this.state.tab.settings.overwrite_tags ? "Enabled" : "Disabled"}
                                        </div>
                                        <div className="relative overflow-hidden mr-5">
                                            <SwitchTailwind
                                                value={this.state.tab.settings && this.state.tab.settings.overwrite_tags ? true : false}
                                                onSwitch={async () => {
                                                    if (!this.state.tab.settings) {
                                                        this.state.tab.settings = { title: "" };
                                                    }
                                                    this.state.tab.settings.overwrite_tags = !this.state.tab.settings.overwrite_tags;
                                                    this.props.onTab(this.state.tab);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.masterTemplate &&
                            <div className="mt-4 bg-blue-100 text-blue-500 font-medium text-sm p-4 rounded-md">
                                OBS! If enabled this cell will always fetch data from account level
                            </div>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            this.state.accounts.length > 0 && this.state.selected_data.length < 1 &&
                            <Fragment>
                                <div className="w-full h-6" />
                            </Fragment>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            <div className="text-xs mt-2 font-medium mb-1">Accounts</div>
                        }
                        {
                            !this.state.masterTemplate &&
                            !this.state.template &&
                            this.state.accounts.map((account, index) => {
                                return (
                                    <Fragment>
                                        <div className="flex flex-1 flex-row justify-center mb-2">
                                            <div>
                                                <div onClick={() => {
                                                    account.show_campaigns = !account.show_campaigns;
                                                    this.setState({
                                                        accounts: this.state.accounts
                                                    });
                                                }} className="text-purple-500 bg-purple-100 cursor-pointer h-10 w-10 flex rounded font-bold mr-2 justify-center align-middle items-center">
                                                    {
                                                        !account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="plus" />
                                                    }
                                                    {
                                                        account.show_campaigns &&
                                                        <FeatherIcon className={'stroke-current'}
                                                            size={14}
                                                            icon="minus" />
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.renders.preview() &&
                                                <div>
                                                    <div onClick={() => {
                                                        if (account.channel === "google" || account.channel === "bing" || account.channel === "facebook" || account.channel === "tiktok" || account.channel === "linkedin" || account.channel === "snapchat") {
                                                            if (!account.is_loading) {
                                                                if (this.state.selected_accounts_internal[account.id]) {
                                                                    delete this.state.selected_accounts_internal[account.id]
                                                                } else {

                                                                    this.state.selected_accounts_internal = {};
                                                                    this.state.selected_ads_internal = {};

                                                                    let colors = this.functions.color(account);
                                                                    account.hex = colors.hex;
                                                                    account.rgb = colors.rgb;
                                                                    this.state.selected_accounts_internal[account.id] = account;

                                                                    if (this.state.tab.breakdown && this.state.tab.breakdown.value) {
                                                                        this.state.tab.breakdown = {
                                                                            title: "Ads",
                                                                            subtitle: "Ads",
                                                                            icon: "globe",
                                                                            value: "ads"
                                                                        };
                                                                    }

                                                                }
                                                                this.props.onTab(this.state.tab);
                                                                this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                                this.props.onSelectedAds(this.state.selected_ads_internal);
                                                            }
                                                        }
                                                    }}
                                                        className={((this.state.selected_accounts_internal[account.id]) ? "border-purple-500" : "") + " h-10 w-10 cursor-pointer p-1 rounded-full border-2 mr-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <div
                                                                className="w-full h-full rounded-full bg-purple-500" />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                !this.renders.preview() &&
                                                <div>
                                                    <div onClick={() => {
                                                        if (!account.is_loading) {
                                                            if (this.state.selected_accounts_internal[account.id]) {
                                                                delete this.state.selected_accounts_internal[account.id]
                                                            } else {
                                                                let colors = this.functions.color(account);
                                                                account.hex = colors.hex;
                                                                account.rgb = colors.rgb;
                                                                this.state.selected_accounts_internal[account.id] = account;
                                                            }
                                                            this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                        }
                                                    }}
                                                        className={(this.state.selected_accounts_internal[account.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>
                                                        {
                                                            this.state.selected_accounts_internal[account.id] &&
                                                            <FeatherIcon className={'stroke-current'}
                                                                size={14}
                                                                icon="check" />
                                                        }
                                                        {
                                                            account.is_loading &&
                                                            <div
                                                                className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">
                                                                <BeatLoader
                                                                    sizeUnit={"px"}
                                                                    size={4}
                                                                    color={'#060534'}
                                                                    loading={true}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.functions.getChannelImage(account.channel)
                                            }
                                            {
                                                account.custom &&
                                                <div
                                                    style={{ backgroundColor: "#" + account.backgroundColor }}
                                                    className={"mr-2 h-10 w-10 rounded flex justify-center align-middle items-center"}>
                                                    {
                                                        account.logo &&
                                                        <div
                                                            className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                            style={{ backgroundImage: "url(" + account.logo + ")" }}></div>
                                                    }
                                                    {
                                                        !account.logo &&
                                                        <div data-tip='' data-for={account.id} key={account.id + "_custom"} className="w-full h-full relative flex items-center justify-center text-semibold text-white text-sm uppercase">
                                                            {account.name[0]}{account.name[1]}
                                                            <ReactTooltip effect='solid' id={account.id}>
                                                                {account.name[0]}{account.name[1]}
                                                            </ReactTooltip>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <div className="flex flex-1 flex-row">
                                                <div className="flex flex-col flex-1 justify-center truncate">
                                                    <div data-tip='' data-for={account.id + "_" + index} key={account.id} className="font-medium relative text-sm">
                                                        <div className="w-full absolute pr-2 truncate">{account.name}</div>
                                                        <ReactTooltip effect='solid' id={account.id + "_" + index}>
                                                            {account.name}
                                                        </ReactTooltip>
                                                    </div>
                                                    <div className="text-xxs flex mt-5.5 flex-row">
                                                        <span className="font-medium text-purple-500">{account.client_name ? account.client_name : ""}</span>
                                                        {
                                                            this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Placements" : "Campaigns"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                        {
                                                            this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Ads" : "Adgroups"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                        {
                                                            this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                            }).length > 0 &&
                                                            <div className="flex flex-row items-center justify-center"><div className="px-1">&#x2022;</div>{account.channel == "cm360" ? "Creatives" : "Ads"} <CheckIcon className="ml-0.5 mb-0.5 w-3 h-3"></CheckIcon></div>
                                                        }
                                                    </div>
                                                </div>
                                                {((this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                    return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                }).length > 0) || (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                    return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                }).length > 0) || (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                    return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                }).length > 0)) &&
                                                    <div className="pt-2"><div onClick={() => {
                                                        if (!account.is_loading) {

                                                            //REMOVE ADS
                                                            if (this.state.selected_ads_internal && Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_ads_internal).filter((key) => {
                                                                    return this.state.selected_ads_internal[key].client == account.client && this.state.selected_ads_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_ads_internal[key]
                                                                });
                                                                this.props.onSelectedAds(this.state.selected_ads_internal);
                                                            }

                                                            //REMOVE ADGROUPS
                                                            if (this.state.selected_adgroups_internal && Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_adgroups_internal).filter((key) => {
                                                                    return this.state.selected_adgroups_internal[key].client == account.client && this.state.selected_adgroups_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_adgroups_internal[key]
                                                                });
                                                                this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);
                                                            }

                                                            //REMOVE CAMPAIGNS
                                                            if (this.state.selected_campaigns_internal && Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                            }).length > 0) {
                                                                Object.keys(this.state.selected_campaigns_internal).filter((key) => {
                                                                    return this.state.selected_campaigns_internal[key].client == account.client && this.state.selected_campaigns_internal[key].channel == account.channel;
                                                                }).map((key) => {
                                                                    delete this.state.selected_campaigns_internal[key]
                                                                });
                                                                this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);
                                                            }

                                                            //REMOVE ACCOUNT
                                                            if (this.state.selected_accounts_internal) {
                                                                delete this.state.selected_accounts_internal[account.id]
                                                                this.props.onSelectedAccounts(this.state.selected_accounts_internal);
                                                            }

                                                        }
                                                    }} className="px-2 py-1 hover:bg-gray-200 bg-gray-100 rounded-full font-medium cursor-pointer text-xxs">Clear selected</div></div>
                                                }
                                                {
                                                    this.state.selected_accounts_internal[account.id] &&
                                                    <div onClick={() => {
                                                        if (!this.state.tab.settings) {
                                                            this.state.tab.settings = { title: "" };
                                                            this.state.tab.settings.source_name = {};
                                                        } else if (!this.state.tab.settings.source_name) {
                                                            this.state.tab.settings.source_name = {};
                                                        }
                                                        if (account.id in this.state.tab.settings.source_name) {
                                                            delete this.state.tab.settings.source_name[account.id];
                                                        } else {
                                                            this.state.tab.settings.source_name[account.id] = "";
                                                        }
                                                        if (this.props.onTab) {
                                                            this.props.onTab(this.state.tab);
                                                        }
                                                    }} className="pt-2">
                                                        <div>
                                                            <div className="px-2 py-1 ml-1 hover:bg-gray-200 bg-gray-100 items-center whitespace-no-wrap rounded-full flex font-medium cursor-pointer text-xxs">
                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (account.id in this.state.tab.settings.source_name) && this.state.selected_accounts_internal[account.id] ? "Remove" : "Edit"} name
                                                                {this.state.tab.settings && this.state.tab.settings.source_name && (account.id in this.state.tab.settings.source_name) && this.state.selected_accounts_internal[account.id] ? <XIcon className="w-3 ml-1" /> : <PencilAltIcon className="w-3 ml-1" />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.tab.settings &&
                                            this.state.tab.settings.source_name &&
                                            (account.id in this.state.tab.settings.source_name) &&
                                            this.state.selected_accounts_internal[account.id] &&
                                            <div className="flex flex-1 w-full -mt-1 pl-12 mb-2">
                                                <div className="flex flex-1">
                                                    <div className="w-full">
                                                        <InputTailwind
                                                            label={"Custom name"}
                                                            value={this.state.tab.settings.source_name[account.id]}
                                                            onChange={(value) => {
                                                                this.state.tab.settings.source_name[account.id] = value;
                                                                if (this.props.onTab) {
                                                                    this.props.onTab(this.state.tab);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            account.channel == "cm360" &&
                                            account.show_campaigns &&
                                            <div>
                                                <div className="grid grid-cols-12 col-gap-4 row-gap-4">
                                                    <div className="col-span-6">
                                                        <MultiDropdown
                                                            fillOut={true}
                                                            defaultSelected={{ id: 0, name: "All campaigns (default)", value: "all" }}
                                                            defaultOptions={[]}
                                                            placeholder={"All campaigns (default)"}
                                                            selected={this.state.cm360_campaign_filter}
                                                            options={this.state.cm360_campaigns}
                                                            onChange={async (value) => {
                                                                this.setState({
                                                                    cm360_campaign_filter: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <MultiDropdown
                                                            fillOut={true}
                                                            defaultSelected={{ id: 0, name: "All sites (default)", value: "all" }}
                                                            defaultOptions={[]}
                                                            placeholder={"All sites (default)"}
                                                            selected={this.state.cm360_site_filter}
                                                            options={this.state.cm360_sites}
                                                            onChange={async (value) => {
                                                                this.setState({
                                                                    cm360_site_filter: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-12">
                                                        <InputTailwind
                                                            label={null}
                                                            placeholder={"Search placements ..."}
                                                            value={this.state.search_placements}
                                                            clearable={true}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    search_placements: value
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/*NO ACTIVE CAMPAIGNS*/}
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            account.show_campaigns &&
                                            this.state.campaigns.filter((item) => {
                                                return item.client_id == account.client && item.channel == account.channel;
                                            }).filter((campaign) => {
                                                if (account.channel == "google_analytics_4" || account.channel == "google_analytics") {
                                                    return true;
                                                } else if (account.show_paused) {
                                                    return true;
                                                } else {
                                                    return typeof campaign.status == "string" && (campaign.status.toLowerCase() === "active" || campaign.status.toLowerCase() === "enabled");
                                                }
                                            }).length < 1 &&
                                            <div className="text-orange-500 pl-12 rounded-md mb-2 p-4 text-sm font-medium">
                                                No active campaigns available <span onClick={() => {
                                                    account.show_paused = !account.show_paused;
                                                    this.setState({
                                                        accounts: this.state.accounts
                                                    });
                                                }} className="underline cursor-pointer">Click here</span> to show all non active {account.channel == "cm360" ? 'placements' : 'campaigns'}.
                                            </div>
                                        }
                                        {/*// for floodLight activities*/}
                                        {/*{*/}
                                        {/*    !this.state.masterTemplate &&*/}
                                        {/*    !this.state.template &&*/}
                                        {/*    (account.show_campaigns) &&*/}
                                        {/*    account.channel == "cm360" &&*/}
                                        {/*    this.state.campaigns.filter((item) => {*/}
                                        {/*        return item.channel == account.channel && item.kind === 'dfareporting#floodlightActivity';*/}
                                        {/*    }).length > 0 &&*/}
                                        {/*    <div>*/}
                                        {/*        <div className="text-xs font-medium mb-1 pl-12">Activities</div>*/}
                                        {/*        {*/}
                                        {/*            this.state.campaigns.filter((item) => {*/}
                                        {/*                return item.channel == account.channel && item.kind === 'dfareporting#floodlightActivity';*/}
                                        {/*            }).map((item, index) => {*/}
                                        {/*                return (*/}
                                        {/*                    <Fragment>*/}
                                        {/*                        <div*/}
                                        {/*                            className="flex flex-1 flex-row justify-center mb-2 pl-12">*/}
                                        {/*                            <div onClick={() => {*/}
                                        {/*                                if (this.state.selected_campaigns_internal[item.id]) {*/}
                                        {/*                                    delete this.state.selected_campaigns_internal[item.id]*/}
                                        {/*                                } else {*/}
                                        {/*                                    let colors = this.functions.color(item);*/}
                                        {/*                                    item.hex = colors.hex;*/}
                                        {/*                                    item.rgb = colors.rgb;*/}
                                        {/*                                    this.state.selected_campaigns_internal[item.id] = item;*/}
                                        {/*                                }*/}
                                        {/*                                this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);*/}
                                        {/*                            }}*/}
                                        {/*                                 className={(this.state.selected_campaigns_internal[item.id] ? "border-purple-500 bg-purple-100" : "") + " cursor-pointer mr-2 h-10 w-10 rounded text-purple-500 border-2 flex justify-center align-middle items-center"}>*/}
                                        {/*                                {*/}
                                        {/*                                    this.state.selected_campaigns_internal[item.id] &&*/}
                                        {/*                                    <FeatherIcon className={'stroke-current'}*/}
                                        {/*                                                 size={14}*/}
                                        {/*                                                 icon="check"/>*/}
                                        {/*                                }*/}
                                        {/*                                {*/}
                                        {/*                                    item.is_loading &&*/}
                                        {/*                                    <div*/}
                                        {/*                                        className="left-0 bg-white bg-opacity-75 right-0 top-0 bottom-0 absolute flex justify-center align-middle items-center">*/}
                                        {/*                                        <BeatLoader*/}
                                        {/*                                            sizeUnit={"px"}*/}
                                        {/*                                            size={4}*/}
                                        {/*                                            color={'#060534'}*/}
                                        {/*                                            loading={true}*/}
                                        {/*                                        />*/}
                                        {/*                                    </div>*/}
                                        {/*                                }*/}
                                        {/*                            </div>*/}
                                        {/*                            <div className="flex flex-1 truncate">*/}
                                        {/*                                <div*/}
                                        {/*                                    className="flex flex-col truncate justify-center">*/}
                                        {/*                                    <div data-tip=''*/}
                                        {/*                                         data-for={item.id + "_" + index}*/}
                                        {/*                                         key={item.id}*/}
                                        {/*                                         className="font-medium truncate relative text-sm">*/}
                                        {/*                                        {item.name}*/}
                                        {/*                                        <ReactTooltip effect='solid'*/}
                                        {/*                                                      id={item.id + "_" + index}>*/}
                                        {/*                                            {item.name}*/}
                                        {/*                                        </ReactTooltip>*/}
                                        {/*                                    </div>*/}
                                        {/*                                    <div className="text-xxs flex flex-row">*/}
                                        {/*                                        {*/}
                                        {/*                                            item.status === "ACTIVE" &&*/}
                                        {/*                                            <div*/}
                                        {/*                                                className="text-green-500 font-medium text-xxs capitalize">{item.status.toLowerCase()}</div>*/}
                                        {/*                                        }*/}
                                        {/*                                        {*/}
                                        {/*                                            item.status === "ARCHIVED" &&*/}
                                        {/*                                            <div*/}
                                        {/*                                                className="text-orange-500 font-medium text-xxs capitalize">{item.status.toLowerCase()}</div>*/}
                                        {/*                                        }*/}
                                        {/*                                        {*/}
                                        {/*                                            ['ARCHIVED_AND_DISABLED', 'DISABLED_POLICY'].includes(item.status) &&*/}
                                        {/*                                            <div*/}
                                        {/*                                                className="text-gray-500 font-medium text-xxs capitalize">{item.status.toLowerCase()}</div>*/}
                                        {/*                                        }*/}
                                        {/*                                    </div>*/}
                                        {/*                                </div>*/}
                                        {/*                            </div>*/}

                                        {/*                        </div>*/}
                                        {/*                    </Fragment>*/}
                                        {/*                )*/}
                                        {/*            })*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*}*/}
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            (account.show_campaigns) &&
                                            <div className="pl-12">
                                                <div className="text-xs font-medium mb-1">{account.channel == "cm360" ? "Placements" : "Campaigns"}</div>
                                                <div className="flex flex-1 mb-4 flex-col justify-center">
                                                    <InputTailwind
                                                        label={null}
                                                        placeholder={account.channel == "cm360" ? "Search placements ..." : "Search campaigns ..."}
                                                        value={this.state[`search_campaigns_${account.id}`]}
                                                        clearable={true}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                [`search_campaigns_${account.id}`]: value
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.masterTemplate &&
                                            !this.state.template &&
                                            (account.show_campaigns) &&
                                            renderCampaigns(account)
                                        }
                                        {
                                            !(typeof this.state[`search_campaigns_${account.id}`] == "string" && this.state[`search_campaigns_${account.id}`] !== "") &&
                                            account.show_campaigns &&
                                            <div className="mb-4 mt-2 pl-12">
                                                {
                                                    this.state.campaigns.length > 0 &&
                                                    account.channel !== "google_analytics_4" && account.channel !== "google_analytics" &&
                                                    <span onClick={() => {
                                                        account.show_paused = !account.show_paused;
                                                        this.setState({
                                                            accounts: this.state.accounts
                                                        });
                                                    }} className="text-sm underline font-medium cursor-pointer">
                                                        {!account.show_paused && <span>Show all {account.channel == "cm360" ? "placements" : "campaigns"}</span>}{account.show_paused && <span>Show less {account.channel == "cm360" ? "placements" : "campaigns"}</span>}
                                                    </span>
                                                }
                                            </div>
                                        }
                                    </Fragment>
                                )
                            })
                        }
                        {
                            this.functions.anythingSelected() &&
                            <div className="flex justify-center items-center mt-6">
                                <button onClick={() => {
                                    this.props.onNext();
                                }} class="bg-purple-500 shadow inline-flex relative justify-center rounded-md border border-transparent w-48 py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2" tabindex="0">Next</button>
                            </div>
                        }
                        {
                            this.functions.multipleLevelsSelected() &&
                            <div className="flex flex-1 bg-yellow-100 rounded-md p-4 mt-4">
                                <div className="text-xs text-yellow-500 font-bold">
                                    OBS! You have selected multiple levels of datasources <span onClick={() => {

                                        //REMOVE CHANNELS
                                        this.state.selected_channels_internal = {};
                                        this.props.onSelectedChannels(this.state.selected_channels_internal);

                                        //REMOVE ADS
                                        this.state.selected_ads_internal = {};
                                        this.props.onSelectedAds(this.state.selected_ads_internal);

                                        //REMOVE ADGROUPS
                                        this.state.selected_adgroups_internal = {};
                                        this.props.onSelectedAdgroups(this.state.selected_adgroups_internal);

                                        //REMOVE CAMPAIGNS
                                        this.state.selected_campaigns_internal = {};
                                        this.props.onSelectedCampaigns(this.state.selected_campaigns_internal);

                                        //REMOVE ACCOUNT
                                        this.state.selected_accounts_internal = {};
                                        this.props.onSelectedAccounts(this.state.selected_accounts_internal);

                                    }} className="underline cursor-pointer">Clear all</span>
                                </div>
                            </div>
                        }
                    </SlideDown>
                </div>
            </>
        )
    }

}

export default SelectCellDatasource