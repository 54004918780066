import React, { Component, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { BeakerIcon, CheckIcon, CursorClickIcon, MinusIcon, PlusIcon, LocationMarkerIcon, CheckCircleIcon, BookOpenIcon, ChatIcon, CameraIcon, NewspaperIcon, DeviceMobileIcon, FilmIcon, ExclamationIcon, ReplyIcon, InformationCircleIcon } from '@heroicons/react/outline'
import InputTailwind from './inputTailwind';
import { tokenRegister } from '../services/tokenRegister';
import { apiRegister } from '../services/apiRegister';
import cn from "classnames";
import DropdownTailwind from './dropdownTailwind';
import DropdownComboTailwind from './dropdownComboTailwind';
import InputDatepickerTailwind from './inputDatepickerTailwind';
import AdvertisingLocationMapRegions from '../components/advertising-location-map-regions';
import tiktokLocations from '../assets/json/tiktok_locations.json';
import tiktokLanguages from '../assets/json/tiktok_languages.json';
import moment from 'moment';
import InputTimepickerTailwind from './inputTimepickerTailwind';
import { adgroupTikTok } from '../validators/adgroupTiktok';
import { budgetPlannerGraph } from '../validators/budgetPlannerGraph';
import SwitchTailwind from './switchTailwind';
import { Bar } from 'react-chartjs-2';

class EditTikTokAdset extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaign: [],
            currency: "",
            open: false,
            adset: {},
            updated: {},
            client: {},
            location_options: [],
            audience_type: { id: 0, name: "Select ..." },
            hours_options: [
                { id: 1, name: "00:00" }, { id: 2, name: "00:30" }, { id: 3, name: "01:00" }, { id: 4, name: "01:30" }, { id: 5, name: "02:00" }, { id: 6, name: "02:30" },
                { id: 7, name: "03:00" }, { id: 8, name: "03:30" }, { id: 9, name: "04:00" }, { id: 10, name: "04:30" }, { id: 11, name: "05:00" }, { id: 12, name: "05:30" },
                { id: 13, name: "06:00" }, { id: 14, name: "06:30" }, { id: 15, name: "07:00" }, { id: 16, name: "07:30" }, { id: 17, name: "08:00" }, { id: 18, name: "08:30" },
                { id: 19, name: "09:00" }, { id: 20, name: "09:30" }, { id: 21, name: "10:00" }, { id: 22, name: "10:30" }, { id: 23, name: "11:00" }, { id: 24, name: "11:30" },
                { id: 25, name: "12:00" }, { id: 26, name: "12:30" }, { id: 27, name: "13:00" }, { id: 28, name: "13:30" }, { id: 29, name: "14:00" }, { id: 30, name: "14:30" },
                { id: 31, name: "15:00" }, { id: 32, name: "15:30" }, { id: 33, name: "16:00" }, { id: 34, name: "16:30" }, { id: 35, name: "17:00" }, { id: 36, name: "17:30" },
                { id: 37, name: "18:00" }, { id: 38, name: "18:30" }, { id: 39, name: "19:00" }, { id: 40, name: "19:30" }, { id: 41, name: "20:00" }, { id: 42, name: "20:30" },
                { id: 43, name: "21:00" }, { id: 44, name: "21:30" }, { id: 45, name: "22:00" }, { id: 46, name: "22:30" }, { id: 47, name: "23:00" }, { id: 48, name: "23:30" },
                { id: 49, name: "24:00" }
            ],
            end_hours: [],
            days: [
                { id: 1, name: "Monday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 2, name: "Tuesday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 3, name: "Wednesday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 4, name: "Thursday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 5, name: "Friday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 6, name: "Saturday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } },
                { id: 7, name: "Sunday", time: { id: 2, name: "Start - End" }, start: { id: 1, name: "00:00" }, end: { id: 49, name: "24:00" } }
            ],
            billing_event_options_new: {
                reach: [
                    { id: 2, name: "CPM", value: "CPM" }
                ],
                traffic: [
                    { id: 1, name: "CPC", value: "CPC" }
                ],
                conversions: [
                    { id: 4, name: "OCPM", value: "OCPM" },
                    { id: 1, name: "CPC", value: "CPC" }
                ]
            },
            billing_event_options: [
                { id: 1, name: "CPC", value: "CPC" },
                { id: 2, name: "CPM", value: "CPM" },
                { id: 3, name: "CPV", value: "CPV" },
                { id: 4, name: "OCPM", value: "OCPM" },
            ],
            creator_interactions: [
                { id: 1, name: "Followed", value: "FOLLOWING" },
                { id: 2, name: "Viewed profile", value: "VIEW_HOMEPAGE" }
            ],
            video_interactions: [
                { id: 1, name: "Watched till the end", value: "WATCHED_TO_END" },
                { id: 2, name: "Liked", value: "LIKED" },
                { id: 3, name: "Commented", value: "COMMENTED" },
                { id: 4, name: "Shared", value: "SHARED" }
            ],
            ages: [
                { id: 1, name: "All ages", value: "ALL", selected: true },
                { id: 2, name: "13-17", value: "AGE_13_17", selected: false },
                { id: 3, name: "18-24", value: "AGE_18_24", selected: false },
                { id: 4, name: "25-34", value: "AGE_25_34", selected: false },
                { id: 5, name: "35-44", value: "AGE_35_44", selected: false },
                { id: 6, name: "45-54", value: "AGE_45_54", selected: false },
                { id: 7, name: "50+", value: "AGE_55_100", selected: false }
            ],
            genders: [
                { id: 1, name: "All genders", value: "GENDER_UNLIMITED", selected: true },
                { id: 2, name: "Men only", value: "GENDER_MALE", selected: false },
                { id: 3, name: "Female only", value: "GENDER_FEMALE", selected: false }
            ],
            pacings: [
                { id: 1, name: "Standard (recommended)", value: true },
                { id: 2, name: "Accelerated", value: false }
            ],
            loader: false,
            tab: { id: 1, name: "Settings" },
            tabs: [
                { id: 1, name: "Settings" },
                { id: 2, name: "Promotion type" },
                { id: 3, name: "Placements" },
                { id: 4, name: "Creative Type" },
                { id: 5, name: "Targeting" },
                { id: 6, name: "Budget & Schedule" },
                { id: 7, name: "Bidding & Optimization" },
                //{ id: 8, name: "App" },
            ],
            optimization_event: [
                { id: 1, name: 'Add Payment Info', value: "ADD_BILLING" },
                { id: 2, name: 'Click Button', value: "BUTTON" },
                { id: 3, name: 'Search', value: "ON_WEB_SEARCH" },
                { id: 4, name: 'Contact', value: "CONSULT" },
                { id: 5, name: 'Download', value: "DOWNLOAD_START" },
                { id: 6, name: 'Subscribe', value: "ON_WEB_SUBSCRIBE" },
                { id: 7, name: 'Submit Form', value: "FORM" },
                { id: 8, name: 'Initiate Checkout', value: "INITIATE_ORDER" },
                { id: 9, name: 'Add To Wishlist', value: "ON_WEB_ADD_TO_WISHLIST" },
                { id: 10, name: 'Add To Cart', value: "ON_WEB_CART" },
                { id: 11, name: 'View Content', value: "ON_WEB_DETAIL" },
                { id: 12, name: 'Place An Order', value: "ON_WEB_ORDER" },
                { id: 13, name: 'Complete Registration', value: "ON_WEB_REGISTER" },
                { id: 14, name: 'Complete Payment', value: "SHOPPING" },
            ],
            optimization_event_secondary: [
                { id: 1, name: 'Purchase', value: "ACTIVE_PAY" },
                { id: 2, name: 'Add Payment Info', value: "ADD_PAYMENT_INFO" },
                { id: 3, name: 'Checkout', value: "IN_APP_ORDER" },
                { id: 4, name: 'Loan Application', value: "LOAN_APPLY" },
                { id: 5, name: 'Loan Approval', value: "LOAN_CREDIT" },
                { id: 6, name: 'StartTrial', value: "START_TRIAL" },
                { id: 7, name: 'Subscribe', value: "SUBSCRIBE" },
                { id: 8, name: 'ROI of the purchase', value: "PURCHASE_ROI" },
                { id: 9, name: 'Day 2 Retention', value: "NEXT_DAY_OPEN" },
            ],
            goals_new: {
                reach: [
                    { id: 10, name: 'Reach', value: "REACH" }
                ],
                traffic: [
                    { id: 8, name: 'Click', value: "CLICK" }
                ],
                conversions: [
                    { id: 5, name: 'Conversion', value: "CONVERT" },
                    { id: 8, name: 'Click', value: "CLICK" }
                ]
            },
            goals: [
                { id: 1, name: 'Show', value: "SHOW" },
                { id: 2, name: 'In App Event', value: "IN_APP_EVENT" },
                { id: 4, name: 'Product click in live', value: "PRODUCT_CLICK_IN_LIVE" },
                { id: 5, name: 'Conversion', value: "CONVERT" },
                { id: 7, name: 'Install', value: "INSTALL" },
                { id: 8, name: 'Click', value: "CLICK" },
                //{ id: 9, name: 'Leads', value: "LEAD_GENERATION" },
                { id: 10, name: 'Reach', value: "REACH" },
                { id: 11, name: 'MT Live room', value: "MT_LIVE_ROOM" },
                { id: 12, name: 'Followers', value: "FOLLOWERS" },
                { id: 13, name: '6-Second video views', value: "VIDEO_VIEW" },//?
                { id: 14, name: 'Profile views', value: "PROFILE_VIEWS" },
                { id: 15, name: 'Value', value: "VALUE" },
            ],
            location_level: { id: 1, name: "All", value: "ALL" },
            location_type: { id: 1, name: "Included", value: true },
            tiktok_custom_time: false,
            apps: {
                loading: false,
                options: []
            },
            targeting: {
                behaviors_creator: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                behaviors_video: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                categories: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                locations: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                pixels: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                languages: {
                    search: "",
                    loading: false,
                    options: tiktokLanguages.map((item) => { return { name: item.name, id: item.code } }),
                    type: { id: 1, name: "Included", value: true }
                },
                interests: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                hashtags: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                },
                apps: {
                    search: "",
                    loading: false,
                    options: [],
                    type: { id: 1, name: "Included", value: true }
                }
            },
            audience_min: 0,
            audience_max: 0,
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            adset: this.props.adset,
            client: this.props.client,
            wizard: this.props.wizard,
            campaign: this.props.campaign,
        })
        if (this.props.updated) {
            this.promisedSetState({
                updated: this.props.updated
            });

            if (this.state.campaign.length > 0 && this.state.campaign[0].scheduler && !this.state.campaign[0].scheduler.schedule && this.state.campaign[0].budget_optimization.id == 1 && this.state.updated.scheduler) {
                this.state.tabs.push({ id: 11, name: "Scheduler", value: "schedule" });
                if (this.state.updated.scheduler.planer) {
                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                    } else {
                        this.state.updated.daily_budget = 0;
                    }
                }
                await this.promisedSetState({
                    tabs: this.state.tabs,
                    updated: this.state.updated
                });
            }

            if (this.state.updated.campaign_budget_optimization) {
                this.state.goals_new.conversions = [{ id: 5, name: 'Conversion', value: "CONVERT" }];
                await this.promisedSetState({
                    goals_new: this.state.goals_new
                });
            }

        }
        if (this.state.client.channels.filter((item) => { return item.value === "tiktok" }).length > 0) {
            await this.promisedSetState({
                currency: this.state.client.channels.filter((item) => { return item.value === "tiktok" })[0].currency
            })
        }
        this.functions.listLocations();
        this.functions.listBehaviors();
        this.functions.listApps();
        this.functions.listCategories();
        this.functions.getPixels();
        this.functions.estimateAudience();
        this.functions.ages();
        this.functions.gender();
    }


    componentWillReceiveProps(nextProps) {
        /*this.promisedSetState({
            wizard: nextProps.wizard
        })
        if (nextProps.updated) {
            this.promisedSetState({
                updated: nextProps.updated
            });
        }*/
    }

    functions = {
        ages: async () => {
            try {
                if (this.state.updated.ages && this.state.updated.ages.length > 0) {
                    this.state.ages = this.state.ages.map((item) => {
                        if (this.state.updated.ages.filter((age) => { return age.id === item.id && age.selected }).length > 0) {
                            item.selected = true;
                        } else {
                            item.selected = false;
                        }
                        return item;
                    });
                    await this.promisedSetState({ ages: this.state.ages });
                }
            } catch (error) {

            }
        },
        gender: async () => {
            try {
                if (this.state.updated.gender) {
                    this.state.genders = this.state.genders.map((item) => {
                        if (item.value !== this.state.updated.gender.value) {
                            item.selected = false;
                        } else {
                            item.selected = true;
                        }
                        return item;
                    });
                    await this.promisedSetState({ genders: this.state.genders });
                }
            } catch (error) {

            }
        },
        update: async () => {
            if (!this.state.wizard) {

            } else {
                this.props.onUpdate(this.state.updated);
            }
        },
        listBehaviors: async () => {
            try {
                this.state.targeting["behaviors_creator"].loading = true;
                this.state.targeting["behaviors_video"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.behaviors();
                this.state.targeting["behaviors_creator"].options = response.data.filter((item) => { return item.type === "CREATOR_RELATED" });
                this.state.targeting["behaviors_video"].options = response.data.filter((item) => { return item.type === "VIDEO_RELATED" });
                this.state.targeting["behaviors_creator"].loading = false;
                this.state.targeting["behaviors_video"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["behaviors_creator"].options = [];
                this.state.targeting["behaviors_video"].options = [];
                this.state.targeting["behaviors_creator"].loading = false;
                this.state.targeting["behaviors_video"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listCategories: async () => {
            try {

                this.state.targeting["categories"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.categories();
                this.state.targeting["categories"].options = response.data;
                this.state.targeting["categories"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["categories"].options = [];
                this.state.targeting["categories"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        searchInterests: async (search) => {
            try {
                this.state.targeting["interests"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.interests(search);
                this.state.targeting["interests"].options = response.data;
                this.state.targeting["interests"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["interests"].options = [];
                this.state.targeting["interests"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        searchHashtags: async (search) => {
            try {
                this.state.targeting["hashtags"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.hashtags(search);
                this.state.targeting["hashtags"].options = response.data;
                this.state.targeting["hashtags"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["hashtags"].options = [];
                this.state.targeting["hashtags"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        getPixels: async () => {
            try {
                this.state.targeting["pixels"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.pixels();
                this.state.targeting["pixels"].options = response.data;
                this.state.targeting["pixels"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["pixels"].options = [];
                this.state.targeting["pixels"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listLocations: async () => {
            try {
                this.state.targeting["locations"].loading = true;
                await this.promisedSetState({ targeting: this.state.targeting });
                let response = await this.calls.locations();
                this.state.targeting["locations"].options = response.data;
                this.state.targeting["locations"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            } catch (error) {
                this.state.targeting["locations"].options = [];
                this.state.targeting["locations"].loading = false;
                await this.promisedSetState({ targeting: this.state.targeting });
            }
        },
        listApps: async () => {
            try {
                this.state.apps.loading = true;
                await this.promisedSetState({ apps: this.state.apps });
                let response = await this.calls.apps();
                this.state.apps.options = response.data;
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            } catch (error) {
                this.state.apps.options = [];
                this.state.apps.loading = false;
                await this.promisedSetState({ apps: this.state.apps });
            }
        },

        estimateAudience: async () => {
            try {
                //HASHTAGS
                let actions = [];
                if (Array.isArray(this.state.updated.hashtags) && this.state.updated.hashtags.length > 0) {
                    actions.push({
                        action_category_ids: this.state.updated.hashtags.map((item) => { return JSON.stringify(item.id) }),
                        action_scene: "HASHTAG_RELATED",
                        action_period: 0,
                        video_user_actions: ["VIEW_HASHTAG"]
                    })
                }

                //BEHAVIOR
                if (Array.isArray(this.state.updated.behaviors_creator) && this.state.updated.behaviors_creator.length > 0 && Array.isArray(this.state.updated.creator_interact) && this.state.updated.creator_interact.length > 0) {
                    actions.push({
                        action_category_ids: this.state.updated.behaviors_creator.map((item) => { return item.id }),
                        action_scene: "CREATOR_RELATED",
                        action_period: 0,
                        video_user_actions: Array.isArray(this.state.updated.creator_interact) ? this.state.updated.creator_interact.map((item) => { return item.value }) : []
                    })
                }

                //BEHAVIOR
                if (Array.isArray(this.state.updated.behaviors_video) && this.state.updated.behaviors_video.length > 0 && Array.isArray(this.state.updated.video_interact) && this.state.updated.video_interact.length > 0) {
                    actions.push({
                        action_category_ids: this.state.updated.behaviors_video.map((item) => { return item.id }),
                        action_scene: "VIDEO_RELATED",
                        action_period: this.state.updated.video_action_day.value,
                        video_user_actions: Array.isArray(this.state.updated.video_interact) ? this.state.updated.video_interact.map((item) => { return item.value }) : []
                    })
                }
                let placements = [];
                if (this.renders.autoPlacements().id === 2) {
                    if (this.state.updated.placements.tiktok) {
                        placements.push("PLACEMENT_TIKTOK");
                    }
                    if (this.state.updated.placements.pangle) {
                        placements.push("PLACEMENT_PANGLE");
                    }
                }
                let estimate_audience = await this.calls.estimateAudience({
                    objective_type: this.renders.campaignObjective().value,
                    optimization_goal: this.renders.goal().value,
                    location_ids: this.state.updated.locations.map((item) => { return item.id }),
                    placement_type: this.renders.autoPlacements().id === 1 ? "PLACEMENT_TYPE_AUTOMATIC" : "PLACEMENT_TYPE_NORMAL",
                    ...((this.renders.autoPlacements().id === 2 && placements.length > 0) && { placements: placements }),
                    gender: this.renders.gender().value ? this.renders.gender().value : "GENDER_UNLIMITED",
                    ...((this.renders.targetingMode().id === 2 && this.state.updated.ages.filter((item) => { return item.id === 1 }).length !== 1) && { age_groups: this.state.updated.ages.filter((item) => { return item.selected }).map((item) => { return item.value }) }),
                    ...((this.state.updated.languages && this.state.updated.languages.length > 0) && { languages: this.state.updated.languages.map((item) => { return item.id }) }),
                    ...((this.state.updated.categories && this.state.updated.categories.length > 0 && this.renders.targetingMode().id === 2) && { interest_category_ids: this.state.updated.categories.map((item) => { return item.id }) }),
                    ...((this.state.updated.interests && this.state.updated.interests.length > 0 && this.renders.targetingMode().id === 2) && { interest_keyword_ids: this.state.updated.interests.map((item) => { return JSON.stringify(item.id) }) }),
                    ...(actions.length > 0 && this.renders.targetingMode().id === 2) && { action_categories: actions }
                });
                let reach = {};
                if (estimate_audience.data && estimate_audience.data.user_count) {
                    reach = {
                        users_lower_bound: estimate_audience.data.user_count.lower_end,
                        users_upper_bound: estimate_audience.data.user_count.upper_end,
                        estimate_ready: true
                    }
                }

                if (this.props.onReach) {
                    this.props.onReach(reach ? reach : {});
                }
                //await this.promisedSetState({ estimate_audience: estimate_audience[0].total });
            } catch (error) {
                console.log("ERROE", error)
                if (this.props.onReach) {
                    this.props.onReach({});
                }
            }
        },
    };

    calls = {
        estimateAudience: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/getEstimateAudience?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        update: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/tiktok/updateAdset?client=" + this.state.client.id + "&id=" + this.state.adset.id;
            return apiRegister.call(options, url);
        },
        interests: (search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchKeywords?client=" + this.state.client.id + "&search=" + search;
            return apiRegister.call(options, url);
        },
        categories: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchCategories?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        behaviors: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchBehaviors?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        locations: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listLocations?client=" + this.state.client.id + "&objective=" + this.renders.campaignObjective().value + "&level=" + this.state.location_level.value;
            return apiRegister.call(options, url);
        },
        hashtags: (search) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/searchHashtags?client=" + this.state.client.id + "&search=" + search;
            return apiRegister.call(options, url);
        },
        pixels: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listPixels?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        apps: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/tiktok/listApps?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        }
    };

    renders = {
        audience_options: (type) => {
            try {
                return this.state.targeting[type].options
            } catch (error) {
                console.log("error HANDLE", error);
                return [];
            }
        },
        audience_type: (type) => {
            try {
                if (type in this.state.updated) {
                    return this.state.updated[type];
                } else {
                    return [];
                }
            } catch (error) {
                return [];
            }
        },
        name: () => {
            try {
                if ('name' in this.state.updated) {
                    return this.state.updated.name;
                } else if (this.state.adset.name) {
                    return this.state.adset.name;
                } else {
                    return "";
                }
            } catch (error) {
                return "";
            }
        },
        gender: () => {
            try {
                if (this.state.updated.gender) {
                    return this.state.updated.gender;
                } else {
                    return { id: 1, name: "All genders", value: null };
                }
            } catch (error) {
                return { id: 1, name: "All genders", value: null };
            }
        },
        age: () => {
            try {
                if ("age" in this.state.updated) {
                    return this.state.updated.age;
                } else {
                    return { id: 1, name: "All ages", value: "all" };
                }
            } catch (error) {
                return { id: 1, name: "All ages", value: "all" };
            }
        },
        campaignObjective: () => {
            try {
                if ("campaign_objective" in this.state.updated) {
                    return this.state.updated.campaign_objective;
                } else {
                    return { id: 0, value: null };
                }
            } catch (error) {
                return { id: 0, value: null };
            }
        },
        pacing: () => {
            try {
                if ("pacing" in this.state.updated) {
                    return this.state.updated.pacing;
                } else {
                    return { id: 1, name: "Standard (recommended)", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Standard (recommended)", value: true };
            }
        },
        campaignBudget: () => {
            try {
                if (this.state.updated.campaign_budget_optimization && this.state.updated.campaign_budget_optimization.name) {
                    return this.state.updated.campaign_budget_optimization;
                } else {
                    return { id: 2, name: "No", value: false };
                }
            } catch (error) {
                return { id: 2, name: "No", value: false };
            }
        },
        dailyBudget: () => {
            try {
                if ('daily_budget' in this.state.updated) {
                    return this.state.updated.daily_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        totalBudget: () => {
            try {
                if ('total_budget' in this.state.updated) {
                    return this.state.updated.total_budget;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        autoPlacements: () => {
            try {
                if ("auto_placements" in this.state.updated) {
                    return this.state.updated.auto_placements;
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        content_execution: () => {
            try {
                if ("content_execution" in this.state.updated) {
                    return this.state.updated.content_execution;
                } else {
                    return { id: 2, name: "Standard", value: "STARNDARD" };
                }
            } catch (error) {
                return { id: 2, name: "Standard", value: "STARNDARD" };
            }
        },
        userComment: () => {
            try {
                if ("user_comment" in this.state.updated) {
                    return this.state.updated.user_comment;
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        videoDownload: () => {
            try {
                if ("video_download" in this.state.updated) {
                    return this.state.updated.video_download;
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        videoShare: () => {
            try {
                if ("video_share" in this.state.updated) {
                    return this.state.updated.video_share;
                } else {
                    return { id: 1, name: "Yes", value: true };
                }
            } catch (error) {
                return { id: 1, name: "Yes", value: true };
            }
        },
        automatedCreative: () => {
            try {
                if ("automated_creative" in this.state.updated) {
                    return this.state.updated.automated_creative;
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "No", value: false };;
            }
        },
        targetingMode: () => {
            try {
                if ("targeting_mode" in this.state.updated) {
                    return this.state.updated.targeting_mode;
                } else {
                    return { id: 1, name: "Automatic targeting", value: "auto" };
                }
            } catch (error) {
                return { id: 1, name: "Automatic targeting", value: "auto" };
            }
        },
        creatorInteract: () => {
            try {
                if ("creator_interact" in this.state.updated) {
                    return this.state.updated.creator_interact;
                } else {
                    return { id: 1, name: "Followed", value: "FOLLOWING" };
                }
            } catch (error) {
                return { id: 1, name: "Followed", value: "FOLLOWING" };
            }
        },
        videoDays: () => {
            try {
                if ("video_action_day" in this.state.updated) {
                    return this.state.updated.video_action_day;
                } else {
                    return { id: 1, name: "7 Days", value: 7 };
                }
            } catch (error) {
                return { id: 1, name: "7 Days", value: 7 };
            }
        },
        videoInteract: () => {
            try {
                if ("video_interact" in this.state.updated) {
                    return this.state.updated.video_interact;
                } else {
                    return { id: 1, name: "Watched till end", value: "WATCHED_TO_END" };
                }
            } catch (error) {
                return { id: 1, name: "Watched till end", value: "WATCHED_TO_END" };
            }
        },
        startDate: () => {
            try {
                if ('start_date' in this.state.updated) {
                    return this.state.updated.start_date;
                } else {
                    return moment(this.state.adset.start_time).format("YYYY-MM-DD");
                }
            } catch (error) {
                return 0;
            }
        },
        startHour: (index) => {
            try {
                if ('items' in this.state.updated) {
                    return this.state.updated.items[index].start;
                } else {
                    return this.state.days[index].start;
                }
            } catch (error) {
                return 0;
            }
        },
        endHour: (index) => {
            try {
                if ('items' in this.state.updated) {
                    return this.state.updated.items[index].end;
                } else {
                    return this.state.days[index].end;
                }
            } catch (error) {
                return 0;
            }
        },
        dayTime: (index) => {
            try {
                if ('items' in this.state.updated) {
                    return this.state.updated.items[index].time;
                } else {
                    return this.state.days[index].time;
                }
            } catch (error) {
                return 0;
            }
        },
        start_end: (index) => {
            try {
                if ('items' in this.state.updated) {
                    if (this.state.updated.items[index].time.id === 2) {
                        return false
                    } else return true
                } else {
                    if (this.state.days[index].time.id === 2) {
                        return false
                    } else return true
                }
            } catch (error) {
                return 0;
            }
        },

        endDate: () => {
            try {
                if ('end_date' in this.state.updated) {
                    return this.state.updated.end_date ? this.state.updated.end_date : null;
                } else {
                    return this.state.adset.end_time ? moment(this.state.adset.end_time).format("YYYY-MM-DD") : null;
                }
            } catch (error) {
                return 0;
            }
        },
        promotionType: () => {
            try {
                if ("external_type" in this.state.updated) {
                    return this.state.updated.external_type;
                } else {
                    return { id: 1, name: "Website", value: "WEBSITE" };
                }
            } catch (error) {
                return { id: 1, name: "Website", value: "WEBSITE" };
            }
        },
        usePixel: () => {
            try {
                if ("use_pixel" in this.state.updated) {
                    return this.state.updated.use_pixel;
                } else {
                    return { id: 1, name: "No", value: false };
                }
            } catch (error) {
                return { id: 1, name: "No", value: false };
            }
        },
        optimization_event: () => {
            try {
                if ("optimization_event" in this.state.updated) {
                    return this.state.updated.optimization_event;
                } else {
                    return { id: 0, name: "Select ...", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select ...", value: false };
            }
        },
        pixel: () => {
            try {
                if ("pixel" in this.state.updated) {
                    return this.state.updated.pixel;
                } else {
                    return { id: 0, name: "Select pixel", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select pixel", value: false };
            }
        },
        app: () => {
            try {
                if ("app" in this.state.updated) {
                    return this.state.updated.app;
                } else {
                    return { id: 0, name: "Select app", value: false };
                }
            } catch (error) {
                return { id: 0, name: "Select app", value: false };
            }
        },
        budgetMode: () => {
            try {
                if ("budget_mode" in this.state.updated) {
                    return this.state.updated.budget_mode;
                } else {
                    return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
                }
            } catch (error) {
                return { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" };
            }
        },
        goal: () => {
            try {
                if ("goal" in this.state.updated) {
                    return this.state.updated.goal;
                } else {
                    return { id: 0, name: "Select goal", value: "" };
                }
            } catch (error) {
                return { id: 0, name: "Select goal", value: "" };
            }
        },
        goal_secondary: () => {
            try {
                if ("goal_secondary" in this.state.updated) {
                    return this.state.updated.goal_secondary;
                } else {
                    return { id: 0, name: "Select secondary goal", value: "" };
                }
            } catch (error) {
                return { id: 0, name: "Select secondary goal", value: "" };
            }
        },
        billing_event: () => {
            try {
                if ("billing_event" in this.state.updated) {
                    return this.state.updated.billing_event;
                } else {
                    return { id: 0, name: "Select billing event", value: "" };
                }
            } catch (error) {
                return { id: 0, name: "Select billing event", value: "" };
            }
        },
        bidStrategy: () => {
            try {
                if ("bid_strategy" in this.state.updated) {
                    return this.state.updated.bid_strategy;
                } else {
                    return { id: 1, name: "Lowest cost", value: "LOWEST" };
                }
            } catch (error) {
                return { id: 1, name: "Lowest cost", value: "LOWEST" };
            }
        },
        bidCap: () => {
            try {
                if ('bid_cap' in this.state.updated) {
                    return this.state.updated.bid_cap;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        pacing: () => {
            try {
                if ('pacing' in this.state.updated) {
                    return this.state.updated.pacing;
                } else {
                    return { id: 1, name: "Standard", value: "PACING_MODE_SMOOTH" };
                }
            } catch (error) {
                return { id: 1, name: "Standard", value: "PACING_MODE_SMOOTH" };
            }
        },
        frequency_cap: () => {
            try {
                if ('frequency_cap' in this.state.updated) {
                    return this.state.updated.frequency_cap;
                } else {
                    return { id: 1, name: "Show ads no more than 3 times every 7 days", value: "3_per_7" };
                }
            } catch (error) {
                return { id: 1, name: "Show ads no more than 3 times every 7 days", value: "3_per_7" };
            }
        },
        frequency: () => {
            try {
                if ('frequency' in this.state.updated) {
                    return this.state.updated.frequency;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        frequency_schedule: () => {
            try {
                if ('frequency_schedule' in this.state.updated) {
                    return this.state.updated.frequency_schedule;
                } else {
                    return 0;
                }
            } catch (error) {
                return 0;
            }
        },
        format: (num) => {
            return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        let selected_targeting = (type, add = false) => {
            return (
                this.state.updated[type].sort(function (x, y) {
                    return (x.included === y.included) ? 0 : x.included ? -1 : 1;
                }).map((item, index) => {
                    return (
                        <Fragment>

                            <div className={(item.included ? " bg-indigo-100 text-indigo-700" : " bg-red-100 text-red-700") + " flex m-1 rounded-full items-center py-1.5 px-3 text-sm font-medium "}>
                                <div className="flex flex-1">{item.name}</div>
                                <button
                                    onClick={() => {
                                        this.state.updated[type] = this.state.updated[type].filter((location) => {
                                            return location.id !== item.id;
                                        })
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                        this.functions.estimateAudience();
                                    }}
                                    type="button"
                                    className={(item.included ? "focus:bg-indigo-500 text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500" : "focus:bg-red-500 text-red-400 hover:bg-red-200 hover:text-red-500") + "flex-shrink-0 ml-1 h-4 w-4 rounded-full inline-flex items-center justify-center focus:outline-none focus:text-white"}
                                >
                                    <span className="sr-only">Remove</span>
                                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                                    </svg>
                                </button>
                            </div>
                        </Fragment>
                    )
                })

            )
        }

        return (
            <>
                <div className="grid grid-cols-3 gap-4">

                    <div className="col-span-3">
                        <div className="block overflow-scroll w-ful">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                {this.state.tabs.filter((item) => {
                                    return (item.id !== 2 && item.id !== 4) || (item.id === 2 && this.renders.campaignObjective().value !== "REACH") || (item.id === 4 && this.renders.campaignObjective().value !== "REACH")
                                })
                                    .map((tab) => (
                                        <div
                                            onClick={() => (
                                                this.setState({
                                                    tab: tab
                                                })
                                            )}
                                            key={tab.name}
                                            style={{ whiteSpace: "nowrap" }}
                                            className={cn(
                                                tab.id == this.state.tab.id ? 'bg-purple-100 text-purple-500' : 'text-gray-500 hover:text-purple-500',
                                                'px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap cursor-pointer flex'
                                            )}
                                            aria-current={tab.id == this.state.tab.id ? 'page' : undefined}
                                        >
                                            <div>
                                                {
                                                    (tab.id === 1 && adgroupTikTok.validate(this.state.updated).name) ||
                                                        (tab.id === 5 && adgroupTikTok.validate(this.state.updated).location) ||
                                                        (tab.id === 6 && (adgroupTikTok.validate(this.state.updated).start_date || adgroupTikTok.validate(this.state.updated).end_date || adgroupTikTok.validate(this.state.updated).budget)) ||
                                                        (tab.id === 7 && adgroupTikTok.validate(this.state.updated).bid_cap) ||
                                                        (tab.id === 11 && ((this.state.updated.scheduler.planer && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity) || Object.keys(this.state.updated.scheduler.weekdays).length < 1) && this.state.updated.scheduler.schedule) ? <ExclamationIcon className="h-5 w-5 text-red-600 mr-2" /> : ""
                                                }
                                            </div>
                                            {tab.name}
                                        </div>
                                    ))}
                            </nav>
                        </div>
                    </div>

                    {/*Ad group details*/}
                    {
                        this.state.tab.id == 1 &&
                        <div className="col-span-3">
                            <InputTailwind
                                label={"Ad group name"}
                                error={adgroupTikTok.validate(this.state.updated).name}
                                value={this.renders.name()}
                                onChange={(value) => {
                                    this.state.updated.name = value;
                                    this.setState({
                                        updated: this.state.updated
                                    })
                                }}
                            />
                        </div>
                    }

                    {/*Promotion type*/}
                    {//options={[{ id: 1, name: "Website", value: "WEBSITE" }, { id: 2, name: "App (Android)", value: "APP_ANDROID" }, { id: 2, name: "App (iOS)", value: "APP_IOS" }]}
                        this.state.tab.id === 2 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Type"}
                                    selected={this.renders.promotionType()}
                                    options={[{ id: 1, name: "Website", value: "WEBSITE" }]}
                                    onChange={async (value) => {
                                        this.state.updated.external_type = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        if (this.state.updated.external_type.value === "APP") {
                                            delete this.state.updated.pixel;
                                            await this.promisedSetState({
                                                updated: this.state.updated
                                            });
                                        }
                                    }}
                                />
                            </div>

                            {
                                this.renders.campaignObjective().value === "CONVERSIONS" &&
                                <div className="col-span-3 grid grid-cols-3 gap-4">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Select pixel"}
                                            searchInput={true}
                                            selected={this.renders.pixel()}
                                            options={this.state.targeting.pixels.options}
                                            loadingSearch={this.state.targeting.pixels.loading}
                                            onChange={async (value) => {
                                                this.state.updated.pixel = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Select optimization event"}
                                            searchInput={true}
                                            selected={this.renders.optimization_event()}
                                            options={this.state.optimization_event}
                                            onChange={async (value) => {
                                                this.state.updated.optimization_event = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.renders.campaignObjective().value === "CONVERSIONS" &&
                                !this.state.updated.pixel &&
                                <div className="bg-orange-100 rounded-md col-span-3 p-4 text-sm font-medium text-orange-500">
                                    OBS! Choose "Pixel and Event"
                                </div>
                            }
                        </>
                    }

                    {/*Placements*/}
                    {
                        this.state.tab.id == 3 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Automatic placements"}
                                    locked={this.renders.campaignObjective().value === "REACH" ? true : false}
                                    selected={this.renders.autoPlacements()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={async (value) => {
                                        this.state.updated.auto_placements = value;
                                        if (!this.state.updated.auto_placements.value) {
                                            this.state.updated.placements = { tiktok: true, pangle: true };
                                        }
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.estimateAudience();
                                    }}
                                />
                            </div>
                            {
                                this.renders.autoPlacements().id === 2 &&
                                <div className="col-span-3">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Placements
                                    </div>
                                    <div className="">
                                        {
                                            [
                                                { name: "TikTok", value: "tiktok", icon: FilmIcon },
                                                { name: "Pangle", value: "pangle", icon: DeviceMobileIcon }
                                            ].map((item) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.renders.campaignObjective().value !== "REACH") {
                                                            if (!this.state.updated.placements) {
                                                                this.state.updated.placements = {};
                                                            } else {
                                                                this.state.updated.placements[item.value] = !this.state.updated.placements[item.value];
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            });
                                                            this.functions.estimateAudience();
                                                        }
                                                    }} className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer flex flex-row rounded-full mb-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <item.icon className={"h-5 w-5 mr-2"} aria-hidden="true" />
                                                        <div className="flex flex-1">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={((this.state.updated.placements && this.state.updated.placements[item.value]) ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"User comment"}
                                    selected={this.renders.userComment()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={async (value) => {
                                        this.state.updated.user_comment = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Video download"}
                                    selected={this.renders.videoDownload()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={async (value) => {
                                        this.state.updated.video_download = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Video sharing"}
                                    selected={this.renders.videoShare()}
                                    options={[{ id: 1, name: "Yes", value: true }, { id: 2, name: "No", value: false }]}
                                    onChange={async (value) => {
                                        this.state.updated.video_share = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*Creative type*/}
                    {
                        this.state.tab.id === 4 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Automated creative optimization"}
                                    selected={this.renders.automatedCreative()}
                                    options={[{ id: 1, name: "No", value: false }, { id: 2, name: "Yes", value: true }]}
                                    onChange={async (value) => {
                                        this.state.updated.automated_creative = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>
                    }

                    {/*Targeting*/}
                    {
                        this.state.tab.id === 5 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    error={adgroupTikTok.validate(this.state.updated).location}
                                    ref="searchDropdown"
                                    label={"Locations"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    search={this.state.targeting.locations.search}
                                    options={(Array.isArray(this.state.targeting.locations.options) && this.state.targeting.locations.search !== "") ? this.state.targeting.locations.options.filter((item) => {
                                        if (Array.isArray(this.state.updated.locations)) {
                                            return this.state.updated.locations.filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                        } else {
                                            return true;
                                        }
                                    }) : []}
                                    loadingSearch={this.state.targeting.locations.loading}
                                    onChange={async (location) => {
                                        location.included = true;
                                        if (!Array.isArray(this.state.updated.locations)) {
                                            this.state.updated.locations = [];
                                        }
                                        this.state.updated.locations.unshift(location);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        this.functions.estimateAudience();
                                    }}
                                    onSearch={async (value) => {
                                        this.state.targeting.locations.search = value;
                                        await this.promisedSetState({
                                            targeting: this.state.targeting
                                        });
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.state.updated.locations) && this.state.updated.locations.length > 0 &&
                                <div className="col-span-3 flex flex-row flex-wrap">
                                    {selected_targeting("locations")}
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    ref="searchDropdown"
                                    label={"Languages"}
                                    searchInput={true}
                                    selected={{ name: "Search ..." }}
                                    options={Array.isArray(this.state.targeting.languages.options) ? this.state.targeting.languages.options.filter((item) => {
                                        if (Array.isArray(this.state.updated.languages)) {
                                            return this.state.updated.languages.filter((inner_item) => { return item.id === inner_item.id }).length < 1
                                        } else {
                                            return true;
                                        }
                                    }) : []}
                                    loadingSearch={this.state.targeting.languages.loading}
                                    onChange={async (language) => {
                                        language.included = true;
                                        if (!Array.isArray(this.state.updated.languages)) {
                                            this.state.updated.languages = [];
                                        }
                                        this.state.updated.languages.unshift(language);
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        })
                                        this.functions.estimateAudience();
                                    }}
                                    onSearch={async (value) => {
                                        this.state.targeting.languages.search = value;
                                        await this.promisedSetState({
                                            targeting: this.state.targeting
                                        });
                                    }}
                                />
                            </div>
                            {
                                Array.isArray(this.state.updated.languages) && this.state.updated.languages.length > 0 &&
                                <div className="col-span-3 flex flex-row flex-wrap">
                                    {selected_targeting("languages")}
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Select targeting mode"}
                                    selected={this.renders.targetingMode()}
                                    options={[{ id: 1, name: "Automatic targeting", value: "auto" }, { id: 2, name: "Custom targeting", value: "custom" }]}
                                    locked={this.renders.campaignObjective().value === "REACH" ? true : false}
                                    onChange={async (value) => {
                                        this.state.updated.targeting_mode = value;

                                        if (value.id === 1) {
                                            this.state.updated.gender = null;
                                            this.state.updated.ages = null;
                                        } else {
                                            this.state.updated.gender = { id: 1, name: "All genders", value: "ALL", selected: true };
                                            this.state.updated.ages = [{ id: 1, name: "All ages", value: "GENDER_UNLIMITED", selected: true }];
                                            this.functions.listBehaviors();
                                        }

                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                        this.functions.estimateAudience();
                                    }}
                                />
                            </div>
                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-3 text-lg font-semibold">
                                    Demographic
                                </div>
                            }


                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-3">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Gender
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.genders.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        item.selected = true;
                                                        this.state.updated.gender = item;
                                                        this.state.genders = this.state.genders.map((inner_item) => {
                                                            if (inner_item.id !== item.id) {
                                                                inner_item.selected = false;
                                                            } else {
                                                                inner_item.selected = true;
                                                            }
                                                            return inner_item;
                                                        })
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            genders: this.state.genders
                                                        })
                                                        this.functions.estimateAudience();

                                                    }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                this.renders.targetingMode().id === 2 &&
                                <div className="col-span-3">
                                    <div className="text-xs font-medium text-gray-700 mb-1">
                                        Ages
                                    </div>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            this.state.ages.map((item) => {
                                                return (
                                                    <div onClick={() => {
                                                        //item.selected = !item.selected;
                                                        let buff = !item.selected;
                                                        if (item.id === 1) {
                                                            this.state.updated.ages = [item];
                                                            this.state.ages = this.state.ages.map((inner_item) => {
                                                                if (inner_item.id !== item.id) {
                                                                    inner_item.selected = false;
                                                                } else {
                                                                    inner_item.selected = true;
                                                                }
                                                                return inner_item;
                                                            })
                                                        } else {
                                                            if (!Array.isArray(this.state.updated.ages)) {
                                                                this.state.updated.ages = [];
                                                            }
                                                            this.state.ages = this.state.ages.map((inner_item) => {
                                                                if (inner_item.id === 1) {
                                                                    inner_item.selected = false;
                                                                } else if (inner_item.id === item.id) {
                                                                    if (this.state.ages.filter((inner_item) => { return inner_item.selected; }).length === 1 && !buff) {
                                                                    } else {
                                                                        console.log("item.selected", this.state.ages.filter((inner_item) => { return inner_item.selected; }))
                                                                        inner_item.selected = buff;
                                                                    }
                                                                }
                                                                return inner_item;
                                                            })
                                                            this.state.updated.ages = this.state.ages.filter((inner_item) => {
                                                                return inner_item.id !== 1;
                                                            })
                                                            //this.state.updated.ages.push(item);
                                                        }
                                                        this.setState({
                                                            updated: this.state.updated,
                                                            ages: this.state.ages
                                                        })
                                                        this.functions.estimateAudience();

                                                    }} className={(item.selected ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div className="">{item.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(item.selected ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(item.selected ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            }









                            <div className="col-span-3 text-lg font-semibold">
                                Interests & Behaviors
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Type"}
                                    selected={this.state.audience_type}
                                    options={[{ id: 1, name: "Interests", value: "categories" }, { id: 2, name: "Additional interests", value: "interests" }, { id: 3, name: "Video interactions", value: "behaviors_video" }, { id: 4, name: "Creator interactions", value: "behaviors_creator" }, { id: 5, name: "Hashtags", value: "hashtags" }]}
                                    onChange={async (value) => {
                                        await this.promisedSetState({
                                            audience_type: value
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.audience_type.id !== 0 &&
                                <div className="col-span-3">
                                    <div className='flex'>
                                        <div className='mx-4 self-center'>
                                            <ReplyIcon className='h-6 w-6' style={{ transform: 'rotate(180deg)' }} />
                                        </div>
                                        <div className='flex-col flex-1'>
                                            <DropdownTailwind
                                                ref="searchDropdown"
                                                label={"Search: " + this.state.audience_type.name}
                                                searchInput={true}
                                                loader={this.state.loading_targeting}
                                                selected={{ name: "Search ..." }}
                                                options={this.renders.audience_options(this.state.audience_type.value)}
                                                loadingSearch={this.state.loading_targeting}
                                                onChange={(value) => {
                                                    let buffer = JSON.parse(JSON.stringify(value));
                                                    buffer.included = true;
                                                    buffer.name = buffer.name + " [" + this.state.audience_type.name + "] ";
                                                    if (!Array.isArray(this.state.updated[this.state.audience_type.value])) {
                                                        this.state.updated[this.state.audience_type.value] = [];
                                                    }
                                                    if (this.state.updated[this.state.audience_type.value].filter((item) => { return item.id === buffer.id }).length < 1) {
                                                        this.state.updated[this.state.audience_type.value].push(buffer);
                                                    }
                                                    this.setState({
                                                        updated: this.state.updated
                                                    })
                                                    this.functions.estimateAudience();
                                                }}
                                                onSearch={async (value) => {
                                                    if (this.state.audience_type.id === 2) {
                                                        this.functions.searchInterests(value);
                                                    } else if (this.state.audience_type.id === 5) {
                                                        this.functions.searchHashtags(value);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                <div className="col-span-3 flex flex-row flex-wrap">
                                    {
                                        ["categories", "interests", "behaviors_video", "behaviors_creator", "hashtags"].map((item) => {
                                            if (this.renders.targetingMode().id === 2 && Array.isArray(this.state.updated[item]) && this.state.updated[item].length > 0) {
                                                return selected_targeting(item)
                                            }
                                        })
                                    }
                                </div>
                            }
                        </>
                    }

                    {/*Budget & Schedule*/}
                    {
                        this.state.tab.id == 6 &&
                        <>

                            {
                                (!this.state.updated.campaign_budget_optimization) &&
                                <div className="col-span-3 grid grid-cols-6 col-gap-2">
                                    <div className="col-span-2">
                                        <DropdownTailwind
                                            label={"Mode"}
                                            selected={this.renders.budgetMode()}
                                            locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                            options={[
                                                { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" },
                                                { id: 2, name: "Lifetime budget", value: "BUDGET_MODE_TOTAL" }
                                            ]}
                                            onChange={(value) => {
                                                this.state.updated.budget_mode = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    {
                                        this.renders.budgetMode().id === 1 &&
                                        <div className="col-span-4">
                                            <InputTailwind
                                                label={"Budget"}
                                                error={adgroupTikTok.validate(this.state.updated).budget}
                                                leftSection={(this.state.currency !== "" ? true : false)}
                                                leftSectionLabel={(this.state.currency)}
                                                locked={this.state.updated.scheduler && this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer}
                                                value={this.renders.dailyBudget()}
                                                type={'number'}
                                                onChange={async (value) => {

                                                    this.state.updated.daily_budget = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    {
                                        this.renders.budgetMode().id === 2 &&
                                        <div className="col-span-4">
                                            <InputTailwind
                                                error={adgroupTikTok.validate(this.state.updated).budget}
                                                leftSection={(this.state.currency !== "" ? true : false)}
                                                leftSectionLabel={(this.state.currency)}
                                                label={"Budget"}
                                                type={'number'}
                                                value={this.renders.totalBudget()}
                                                onChange={async (value) => {
                                                    if (value < 0) value = 0;
                                                    this.state.updated.total_budget = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {

                                this.state.updated.campaign_budget_optimization &&
                                <div className="col-span-3">
                                    <div className="bg-blue-100 rounded-md inline-flex w-full p-4 text-sm font-medium text-blue-500">
                                        <InformationCircleIcon className="h-5 w-5 text-blue-500 mr-2" />

                                            {"Uses campaign budget" + (this.state.updated.campaign_budget && this.state.updated.campaign_budget.mode && this.state.updated.campaign_budget.mode.value === "BUDGET_MODE_DAY" ? " Daily: " : "Lifetime: ")} {this.state.updated.campaign_budget && this.state.updated.campaign_budget.value ? this.state.updated.campaign_budget.value : ""} {this.state.currency}
                                    </div>
                                </div>
                            }


                            <div className="col-span-3">
                                <div className="grid grid-cols-6 gap-4">
                                    <div className="col-span-3 grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                error={adgroupTikTok.validate(this.state.updated).start_date}
                                                label={"Start date"}
                                                value={moment(this.renders.startDate()).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.renders.startDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.start_date = moment(new_date);
                                                    if (this.state.updated.scheduler) {
                                                        this.state.updated.scheduler.start_date = moment(new_date);
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputTimepickerTailwind
                                                error={adgroupTikTok.validate(this.state.updated).start_date}
                                                label={"Start time"}
                                                value={moment(this.renders.startDate()).format("HH:mm")}
                                                onChange={async (value) => {
                                                    let date = moment(this.renders.startDate()).format("YYYY-MM-DD");
                                                    let new_date = date + " " + value;
                                                    this.state.updated.start_date = moment(new_date);
                                                    if (this.state.updated.scheduler) {
                                                        this.state.updated.scheduler.start_date = moment(new_date);
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className={"col-span-3 grid grid-cols-6 col-gap-4"}>
                                        <div className={(this.renders.endDate() ? "col-span-3" : "col-span-6")}>
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                error={adgroupTikTok.validate(this.state.updated).end_date}
                                                disabled={!this.renders.endDate()}
                                                labelRight={!this.state.updated.campaign_budget_optimization ? (this.renders.endDate() ? "Disable" : "Enable") : false}
                                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                                value={moment(this.renders.endDate()).format("YYYY-MM-DD")}
                                                onChange={async (value) => {
                                                    let time = moment(this.renders.endDate()).format("HH:mm");
                                                    let new_date = value + " " + time;
                                                    this.state.updated.end_date = moment(new_date);
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.renders.endDate()) {
                                                        this.state.updated.end_date = null;
                                                    } else {
                                                        let new_date = moment(this.renders.startDate()).add(1, 'days');
                                                        this.state.updated.end_date = moment(new_date);
                                                    }
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        {
                                            this.renders.endDate() &&
                                            <div className="col-span-3">
                                                <InputTimepickerTailwind
                                                    label={"End time"}
                                                    error={adgroupTikTok.validate(this.state.updated).end_date}
                                                    placeholder={!this.renders.endDate() ? "No end time" : ""}
                                                    disabled={!this.renders.endDate()}
                                                    value={moment(this.renders.endDate()).format("HH:mm")}
                                                    onChange={async (value) => {
                                                        let date = moment(this.renders.endDate()).format("YYYY-MM-DD");
                                                        let new_date = date + " " + value;
                                                        this.state.updated.end_date = moment(new_date);
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                false &&
                                <div className="col-span-3">
                                    <div className="grid grid-cols-6 gap-4">
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"Start date"}
                                                value={this.renders.startDate()}
                                                onChange={async (value) => {
                                                    this.state.updated.start_date = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-span-3">
                                            <InputDatepickerTailwind
                                                label={"End date"}
                                                labelRight={(this.renders.endDate() || this.renders.budgetMode().id === 2) ? "Enabled" : "Disabled"}
                                                placeholder={!this.renders.endDate() ? "No end date" : ""}
                                                disabled={!this.renders.endDate() && this.renders.budgetMode().id !== 2}
                                                value={this.renders.endDate()}
                                                onChange={async (value) => {
                                                    this.state.updated.end_date = value;
                                                    await this.promisedSetState({
                                                        updated: this.state.updated
                                                    })
                                                }}
                                                onLabelRightClick={async (value) => {
                                                    if (this.renders.budgetMode().id !== 2) {
                                                        if (this.renders.endDate()) {
                                                            this.state.updated.end_date = null;
                                                        } else {
                                                            let new_date = moment(this.renders.startDate(), "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
                                                            this.state.updated.end_date = new_date;
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                this.state.updated.items &&
                                this.state.updated.items.map((item, index) => {
                                    return (
                                        <div className="col-span-3 mt-2 grid grid-cols-12 gap-4">
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Day"}
                                                    selected={item}
                                                    options={this.state.days.filter((inner_item) => {
                                                        let buffer = true;
                                                        this.state.updated.items.map((inner_inner_item) => {
                                                            if (inner_inner_item.name === inner_item.name) {
                                                                buffer = false
                                                            }
                                                        })
                                                        return buffer
                                                    })}
                                                    onChange={(value) => {
                                                        this.state.updated.items[index] = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                    rightLabel={true}
                                                    rightLabelColor={"hover:text-red-700 text-red-500"}
                                                    rightLabelText={"Remove"}
                                                    onRightLabelClick={() => {
                                                        this.state.updated.items = this.state.updated.items.filter((number) => { return number.id !== item.id });
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"Start time"}
                                                    locked={this.renders.start_end(index)}
                                                    selected={this.renders.startHour(index)}
                                                    options={this.state.hours_options.slice(0, this.state.updated.items ? this.state.updated.items[index].end.id - 1 : -1)}
                                                    onChange={(value) => {
                                                        this.state.updated.items[index].start = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-span-4">
                                                <DropdownTailwind
                                                    label={"End time"}
                                                    locked={this.renders.start_end(index)}
                                                    selected={this.renders.endHour(index)}
                                                    options={this.state.hours_options.slice(this.state.updated.items ? this.state.updated.items[index].start.id : 1, this.state.hours_options.length)}
                                                    onChange={(value) => {
                                                        this.state.updated.items[index].end = value;
                                                        this.setState({
                                                            updated: this.state.updated
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (!this.state.updated.items || (this.state.updated.items && this.state.updated.items.length < 7)) &&
                                <div onClick={() => {
                                    if (!this.state.updated.items) {
                                        this.state.updated.items = [];
                                    }
                                    this.state.updated.items.push({ id: Math.floor((Math.random() * 9999999999) + 1), name: "Choose day", time: { id: 0, name: "Not specified" }, start: { id: 1, name: "00:00", hourValue: 0, minuteValue: "ZERO" }, end: { id: 97, name: "24:00", hourValue: 24, minuteValue: "ZERO" } });
                                    this.setState({
                                        updated: this.state.updated
                                    });
                                }} className="col-span-3 mt-4 text-sm font-medium cursor-pointer text-purple-500 hover:text-purple-700">
                                    Add specific time
                                </div>
                            }



                            {
                                //OLD CODE
                                false &&
                                !this.state.tiktok_custom_time &&
                                !this.state.updated.days &&
                                <div onClick={async () => {
                                    await this.promisedSetState({
                                        tiktok_custom_time: true
                                    });
                                }} className="mt-2 text-sm font-medium text-purple-500 hover:text-purple-600 cursor-pointer">
                                    Select specific time
                                </div>
                            }
                            {
                                //OLD CODE
                                false &&
                                (this.state.tiktok_custom_time || this.state.updated.days) &&
                                this.state.days.map((item, index) => {
                                    return (
                                        <div className="col-span-3">
                                            <div className="grid grid-cols-6 gap-4">
                                                <div className="col-span-6 grid grid-cols-12 gap-4">
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={item.name}
                                                            selected={this.renders.dayTime(index)}
                                                            options={[
                                                                { id: 2, name: "Start - End" },
                                                                { id: 1, name: "All day" },
                                                                { id: 0, name: "Not specified" }
                                                            ]}
                                                            onChange={(value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = this.state.days
                                                                }
                                                                this.state.updated.days[index].time = value;
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"Start time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.startHour(index)}
                                                            options={this.state.hours_options.slice(0, this.state.updated.days ? this.state.updated.days[index].end.id : -1)}
                                                            onChange={(value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = this.state.days
                                                                }
                                                                this.state.updated.days[index].start = value;
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-span-4">
                                                        <DropdownTailwind
                                                            label={"End time"}
                                                            locked={this.renders.start_end(index)}
                                                            selected={this.renders.endHour(index)}
                                                            options={this.state.hours_options.slice(this.state.updated.days ? this.state.updated.days[index].start.id : 1, this.state.hours_options.length)}
                                                            onChange={(value) => {
                                                                if (!this.state.updated.days) {
                                                                    this.state.updated.days = this.state.days
                                                                }
                                                                this.state.updated.days[index].end = value;
                                                                this.setState({
                                                                    updated: this.state.updated
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })
                            }



                        </>
                    }

                    {/*Bidding & Optimization*/}
                    {
                        this.state.tab.id == 7 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Optimization goal"}
                                    selected={this.renders.goal()}
                                    options={this.state.goals_new[this.renders.campaignObjective().value.toLowerCase()]}
                                    locked={this.state.goals_new[this.renders.campaignObjective().value.toLowerCase()].length === 1 ? true : false}
                                    onChange={(value) => {
                                        if (value.id === 8) {
                                            this.state.updated.billing_event = { id: 1, name: "CPC", value: "CPC" };
                                        } else if (value.id === 5) {
                                            this.state.updated.billing_event = { id: 4, name: "OCPM", value: "OCPM" };
                                        }
                                        this.state.updated.goal = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                (this.state.updated.goal && this.state.updated.goal.id && (this.state.updated.goal.id === 7 || this.state.updated.goal.id === 15)) &&
                                <div className="col-span-3">
                                    <DropdownTailwind
                                        label={"Secondary optimization goal"}
                                        selected={this.renders.goal_secondary()}
                                        options={this.state.optimization_event_secondary}
                                        locked={false}
                                        onChange={(value) => {
                                            this.state.updated.goal_secondary = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Bid strategy"}
                                    selected={this.renders.bidStrategy()}
                                    options={[
                                        { id: 1, name: "Lowest cost", value: "BID_TYPE_NO_BID" },
                                        { id: 2, name: "Bid cap", value: "BID_TYPE_CUSTOM" }
                                    ]}
                                    locked={false}
                                    onChange={(value) => {
                                        this.state.updated.bid_strategy = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.bidStrategy().id === 2 &&
                                <div className="col-span-3">
                                    <InputTailwind
                                        label={"Bid cap"}
                                        leftSection={false}
                                        error={adgroupTikTok.validate(this.state.updated).bid_cap}
                                        value={this.renders.bidCap()}
                                        type={'number'}
                                        onChange={async (value) => {
                                            if (value < 0) value = 0;
                                            this.state.updated.bid_cap = value;
                                            this.setState({
                                                updated: this.state.updated
                                            })
                                        }}
                                    />
                                </div>
                            }
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Billing event"}
                                    selected={this.renders.billing_event()}
                                    options={this.state.billing_event_options_new[this.renders.campaignObjective().value.toLowerCase()]}
                                    locked={true}
                                    onChange={(value) => {
                                        this.state.updated.billing_event = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"Delivery type"}
                                    selected={this.renders.pacing()}
                                    options={[
                                        { id: 1, name: "Standard", value: "PACING_MODE_SMOOTH" },
                                        { id: 2, name: "Accelerated", value: "PACING_MODE_FAST" }
                                    ]}
                                    locked={this.renders.bidStrategy().id === 1 || this.state.updated.campaign_budget_optimization ? true : false}
                                    onChange={(value) => {
                                        this.state.updated.pacing = value;
                                        this.setState({
                                            updated: this.state.updated
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.renders.goal().id === 10 &&
                                <div className="col-span-3">
                                    <div className="col-span-3">
                                        <DropdownTailwind
                                            label={"Content exclusions"}
                                            selected={this.renders.content_execution()}
                                            options={[{ id: 1, name: "Full", value: "FULL" }, { id: 2, name: "Standard", value: "STANDARD" }, { id: 3, name: "Limited", value: "LIMITED" }]}
                                            onChange={async (value) => {
                                                this.state.updated.content_execution = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3 mt-2">
                                        <DropdownTailwind
                                            label={"Frequency cap"}
                                            selected={this.renders.frequency_cap()}
                                            options={[{ id: 1, name: "Show ads no more than 3 times every 7 days", value: "3_per_7" }, { id: 2, name: "Show ads no more than once a day", value: "one_a_day" }, { id: 3, name: "Custom frequency cap", value: "custom" }]}
                                            onChange={async (value) => {
                                                if (value.id === 1) {
                                                    this.state.updated.frequency = 3;
                                                    this.state.updated.frequency_schedule = 7;
                                                } else if (value.id === 2) {
                                                    this.state.updated.frequency = 1;
                                                    this.state.updated.frequency_schedule = 1;
                                                }
                                                this.state.updated.frequency_cap = value;
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                this.renders.goal().id === 10 &&
                                this.renders.frequency_cap().id === 3 &&
                                <div className="grid grid-cols-6 gap-4 mt-2 col-span-3">
                                    <div className="col-span-3">
                                        <InputTailwind
                                            label={"Frequency (impressions)"}
                                            leftSection={false}
                                            type={'number'}
                                            value={this.renders.frequency()}
                                            onChange={(value) => {
                                                if (value < 0) value = 0;
                                                if (this.renders.frequency_schedule() === 0) {
                                                    if (value > 3) value = 3;
                                                } else {
                                                    if (value > this.renders.frequency_schedule() * 3) value = this.renders.frequency_schedule() * 3;
                                                }
                                                this.state.updated.frequency = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-span-3">
                                        <InputTailwind
                                            label={"Frequency schedule (days)"}
                                            leftSection={false}
                                            type={'number'}
                                            value={this.renders.frequency_schedule()}
                                            onChange={(value) => {
                                                if (value > 7) value = 7;
                                                if (value < 0) value = 0;
                                                this.state.updated.frequency_schedule = value;
                                                this.setState({
                                                    updated: this.state.updated
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {/*App*/}
                    {
                        this.state.tab.id == 8 &&
                        <>
                            <div className="col-span-3">
                                <DropdownTailwind
                                    label={"App"}
                                    selected={this.renders.app()}
                                    options={this.state.apps.options}
                                    onChange={async (value) => {
                                        this.state.updated.app = value;
                                        await this.promisedSetState({
                                            updated: this.state.updated
                                        });
                                    }}
                                />
                            </div>
                        </>

                    }

                    {/*SCHEDULER */}

                    {
                        this.state.tab.id == 11 &&
                        <>
                            <div className='col-span-6'>
                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Schedule</div>
                                <div className='flex items-center justify-center mt-5 mb-3 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                    <div className="flex flex-1 ml-5">
                                        {this.state.updated.scheduler.schedule ? "Active" : "Disabled"}
                                    </div>
                                    <div className="relative overflow-hidden mr-5">
                                        <SwitchTailwind
                                            value={this.state.updated.scheduler.schedule ? true : false}
                                            onSwitch={async () => {
                                                this.state.updated.scheduler.schedule = !this.state.updated.scheduler.schedule;

                                                if (this.state.updated.scheduler.schedule && this.state.updated.scheduler.planer) {
                                                    this.state.updated.budget_mode = { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" }
                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                    } else {
                                                        this.state.updated.daily_budget = 0;
                                                    }
                                                }
                                                await this.promisedSetState({
                                                    updated: this.state.updated
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'col-span-6 grid grid-cols-12 gap-4 transform transition-all duration-500 ease-in-out overflow-hidden' + (!this.state.updated.scheduler.schedule ? " h-0" : "")}>
                                <div className='col-span-12'>
                                    <div className={"text-xs font-medium mb-1 text-gray-700"}>
                                        Days
                                    </div>
                                    <div className='flex flex-wrap'>
                                        {
                                            [
                                                { name: "Monday", property: "scheduleMonday" },
                                                { name: "Tuesday", property: "scheduleTuesday" },
                                                { name: "Wednesday", property: "scheduleWednesday" },
                                                { name: "Thursday", property: "scheduleThursday" },
                                                { name: "Friday", property: "scheduleFriday" },
                                                { name: "Saturday", property: "scheduleSaturday" },
                                                { name: "Sunday", property: "scheduleSunday" }
                                            ].map((weekday) => {
                                                return (
                                                    <div onClick={async () => {
                                                        if (this.state.updated.scheduler.weekdays[weekday.property]) {
                                                            delete (this.state.updated.scheduler.weekdays[weekday.property]);
                                                        } else {
                                                            this.state.updated.scheduler.weekdays[weekday.property] = true;
                                                        }
                                                        if (this.state.updated.scheduler.planer) {
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                        }
                                                        await this.promisedSetState({
                                                            updated: this.state.updated
                                                        })
                                                    }} className={(this.state.updated.scheduler.weekdays[weekday.property] ? "bg-indigo-100 text-indigo-700" : "bg-gray-100 text-gray-500 hover:text-indigo-500") + " cursor-pointer inline-flex flex-row rounded-full mb-2 mr-2 items-center py-1.5 px-3 text-sm font-medium"}>
                                                        <div>{weekday.name}</div>
                                                        <div className="ml-3">
                                                            <div className={(this.state.updated.scheduler.weekdays[weekday.property] ? "border-indigo-500 bg-indigo-500" : "border-gray-500 ") + " h-5 w-5 flex justify-center items-center rounded-full border-2"}>
                                                                <CheckIcon className={(this.state.updated.scheduler.weekdays[weekday.property] ? "" : "opacity-0") + " h-5 w-5 text-white"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-span-12 overflow-hidden ease-in-out transition-all duration-500 grid grid-cols-4 gap-4'>
                                    <div className='col-span-4'>
                                        <div className='flex flex-row overflow-hidden ease-in-out transition-all duration-500'>
                                            <div className={'flex flex-col transition-all transform duration-500 overflow-hidden' + (!this.state.updated.scheduler.planer ? " flex-1" : " pr-4")}>
                                                <div className='-mb-4 text-xs font-medium text-gray-700 flex flex-1'>Daily budget planer</div>
                                                <div className='flex items-center justify-center my-5 border-gray-300 w-full bg-custom-input border-1.5 rounded-md' style={{ paddingTop: "0.5rem", paddingBottom: "0.6rem" }}>
                                                    <div className="flex flex-1 ml-5 min-w-24">
                                                        {(this.state.updated.scheduler.planer ? "Active" : "Disabled")}
                                                    </div>
                                                    <div className={"relative overflow-hidden mr-5 "}>
                                                        <SwitchTailwind
                                                            disabled={false}
                                                            value={this.state.updated.scheduler.planer}
                                                            onSwitch={async () => {

                                                                this.state.updated.scheduler.planer = !this.state.updated.scheduler.planer;
                                                                if (this.state.updated.scheduler.planer) {
                                                                    this.state.updated.budget_mode = { id: 1, name: "Daily budget", value: "BUDGET_MODE_DAY" }
                                                                    if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                        this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                                    } else {
                                                                        this.state.updated.daily_budget = 0;
                                                                    }
                                                                }
                                                                await this.promisedSetState({
                                                                    updated: this.state.updated
                                                                })


                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "pr-4" : "w-0 hidden")}>
                                                <div className=' pr-2'>
                                                    <InputTailwind
                                                        locked={!this.state.updated.scheduler.planer}
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        label={"Total monthly"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.scheduler.schedule_budget}
                                                        type={"number"}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.schedule_budget = value;

                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })

                                                        }}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <InputTailwind
                                                        locked={true}
                                                        disabled={true}
                                                        label={"Daily budget"}
                                                        leftSection={(this.state.currency !== "" ? true : false)}
                                                        leftSectionLabel={(this.state.currency)}
                                                        value={this.state.updated.daily_budget}
                                                        type={"number"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={'flex transition-all transform duration-500 overflow-hidden ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                <div className='w-full'>
                                                    <InputDatepickerTailwind
                                                        disabled={!this.state.updated.scheduler.planer}
                                                        locked={!this.state.updated.scheduler.planer}
                                                        placeholder={moment(this.state.updated.scheduler.start_date).format("MM/DD/YYYY")}
                                                        label={"Start date"}
                                                        value={moment(this.state.updated.scheduler.start_date).format("YYYY-MM-DD")}
                                                        onChange={async (value) => {
                                                            this.state.updated.scheduler.start_date = moment(value).format("YYYY-MM-DD");
                                                            if (this.state.updated.scheduler) {
                                                                this.state.updated.start_date = moment(value).format("YYYY-MM-DD");
                                                            }
                                                            if (budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] !== Infinity) {
                                                                this.state.updated.daily_budget = budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0];
                                                            } else {
                                                                this.state.updated.daily_budget = 0;
                                                            }
                                                            await this.promisedSetState({
                                                                updated: this.state.updated
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.updated.scheduler.order_info && this.state.updated.scheduler.order_info.maxBudgetSubAction &&
                                                <div className={'flex transition-all transform duration-500 overflow-hidden pl-4 ' + (this.state.updated.scheduler.planer ? "flex-1" : "w-0 hidden")}>
                                                    <div className=''>
                                                        <InputTailwind
                                                            locked={true}
                                                            disabled={true}
                                                            label={"Order pacing"}
                                                            value={this.state.updated.scheduler.order_info.maxBudgetSubAction && this.state.updated.scheduler.order_info.maxBudgetSubAction.id ? (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 1 ? "Spend total" : (this.state.updated.scheduler.order_info.maxBudgetSubAction.id === 2 ? "Spend pacing" : "Unkown")) : "Unkown"}
                                                        />
                                                    </div>

                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.updated.scheduler.planer && this.state.updated.scheduler.schedule_budget > 0 && budgetPlannerGraph.main(this.state.updated.scheduler).datasets[0].data[0] === Infinity &&
                                            <div className='flex flex-row border-t mt-4'>
                                                <div className="bg-orange-100 w-full p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                                                    OBS! No remaining weekdays this month match your selection. Please select a different start date or weekdays.
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className='col-span-4'>
                                        <div className={" mt-4 pt-4" + (this.state.updated.scheduler.planer ? " h-64" : " h-0")}>
                                            <Bar
                                                options={{
                                                    maintainAspectRatio: false,
                                                    interaction: {
                                                        intersect: false,
                                                    },
                                                    legend: {
                                                        display: false
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                suggestedMin: 0
                                                            }
                                                        }]
                                                    },
                                                }}
                                                data={budgetPlannerGraph.main(this.state.updated.scheduler)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>
                    }

                </div>
            </>
        )
    }
}

export default EditTikTokAdset;
