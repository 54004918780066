import React, { Component } from 'react';
import DropdownTailwind from './dropdownTailwind';
import WarningModalTailwind from './warningModalTailwind';
import { apiRegister } from "../services/apiRegister";
import { tokenRegister } from '../services/tokenRegister';
import SwitchTailwind from './switchTailwind';
import { PlusIcon, XIcon } from '@heroicons/react/outline';

class AccountGoogleSearchConsole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            client: {},
            message: "",
            loading: false,
            healthcheck: {},
            owner: false,
            loading_account: false,
            me: {},
            throttling: {},
            is_manager: false,
            agents: [],
            agent: { id: 0, name: "Select agent" },
            loading_agents_search: "",
            total: 0,
            limit: 10,
            page: 1,
            sort: "name",
            direction: "ascending"
        }
    };

    async componentDidMount() {
        await this.promisedSetState({
            is_manager: this.props.isManager,
            owner: this.props.owner,
            user: this.props.user ? this.props.user : {},
            me: this.props.me,
            healthcheck: this.props.healthcheck ? this.props.healthcheck : {},
            client: this.props.client ? this.props.client : {}
        });
        if (this.state.is_manager && !this.state.client.google_search_console) {
            this.functions.getAgents(true);
        }
        try {
            if (window.location.href.indexOf("v2/profile/connections") !== -1) {
                await this.promisedSetState({
                    open: true
                });
            }
        } catch (error) { }
    }

    componentWillReceiveProps(nextprops) {
        this.setState({
            is_manager: nextprops.isManager,
            user: nextprops.user ? nextprops.user : {},
            me: nextprops.me,
            owner: nextprops.owner,
            healthcheck: nextprops.healthcheck ? nextprops.healthcheck : {},
            client: nextprops.client ? nextprops.client : {}
        });
    }

    functions = {
        login: async (token) => {
            let self = this;
            try {
                await self.promisedSetState({
                    loading: true,
                    error: false
                });
                await self.calls.connect({
                    channel: "google_search_console",
                    token: token
                });

                if (this.props.onLogin) {
                    this.props.onLogin()
                }

                //TEMP SOLUTION
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
                //TEMP SOLUTION

                await self.promisedSetState({
                    user: { ...this.state.user, ...{ google_search_console: { authenticated: true } } },
                    loading: false,
                });
            } catch (error) {
                self.setState({
                    error: true,
                    message: "something went wrong",
                    loading: false,
                });
            }
        },
        logout: async () => {
            let self = this;
            await self.promisedSetState({
                loading: true,
                remove: false
            });
            await self.calls.disconnect({
                channel: "google_search_console"
            });
            await self.promisedSetState({
                user: { ...this.state.user, ...{ google_search_console: { authenticated: false } } },
                loading: false,
                code: ""
            });
        },
        connectToClient: async () => {
            await this.promisedSetState({
                loading_agents_search: true
            });
            try {
                await this.calls.connectToClient({
                    agent: this.state.agent,
                });
                this.state.client.google_search_console = {
                    defaultAgent: this.state.agent
                };
                this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    client: this.state.client
                });
                if (this.props.onChange) {
                    this.props.onChange(this.state.client.google_search_console);
                }
            } catch (error) {
                console.log(error);
            }
            await this.promisedSetState({
                loading_agents_search: false
            });
        },
        disconnectFromClient: async () => {
            await this.promisedSetState({
                loading_account: true
            });
            try {
                await this.calls.disconnectFromClient({});
                delete this.state.client.google_search_console;
                await this.promisedSetState({
                    agent: { id: 0, name: "Select agent" },
                    client: this.state.client
                });
                if (this.state.is_manager) {
                    this.functions.getAgents(true);
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_account: false
            });
        },
        getAgents: async (init, paginaton, search) => {
            await this.promisedSetState({
                loading_agents_search: search,
                loading_agents_pagination: paginaton,
                loading_agents: init
            });
            try {
                let response = await this.calls.getAgents();
                if (!paginaton) {
                    await this.promisedSetState({
                        agents: response.data.map((item) => { item.name = item.email; return item }),
                        total: response.meta.total,
                        loading_agents: false,
                    });
                } else {
                    this.state.agents = this.state.agents.concat(response.data.map((item) => { item.name = item.email; return item }));
                    await this.promisedSetState({
                        agents: this.state.agents,
                        total: response.meta.total,
                        loading_agents: false,
                    });
                }
            } catch (error) { }
            await this.promisedSetState({
                loading_agents_search: false,
                loading_agents: false
            });
        }
    };

    calls = {
        connect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'PUT', data);
            let url = apiRegister.url.api + "/v3/adcredo/updateAgentPlatform";
            return apiRegister.call(options, url);
        },
        disconnect: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgentPlatform";
            return apiRegister.call(options, url);
        },
        connectToClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'POST', data);
            let url = apiRegister.url.api + "/v3/google_search_console/connectAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        disconnectFromClient: (data) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', data);
            let url = apiRegister.url.api + "/v3/google_search_console/removeAccount?client=" + this.state.client.id;
            return apiRegister.call(options, url);
        },
        getAgents: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listAgencyAgents?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.search && this.state.search !== "" ? ("&search=" + this.state.search) : "") + "&channel=google_search_console";
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {
        return (
            <div className={(this.props.border ? "border rounded-md p-4" : "") + ""}>

                {/*LOGOUT MODAL*/}
                <WarningModalTailwind
                    open={this.state.remove ? true : false}
                    title={"Signout Google Search Console"}
                    description={'Are you sure you ?'}
                    cancelButtonText={"cancel"}
                    submitButtonText={"Sign out"}
                    showInput={false}
                    onClose={async (value) => {
                        await this.promisedSetState({ remove: null });
                    }}
                    onSubmit={(value) => {
                        this.functions.logout();
                    }}
                />

                <div className="flex flex-row flex-1 items-center">
                    <div className="h-10 w-10 mr-2 bg-googleanalytics-500 rounded-md flex justify-center items-center">
                        <div className="w-6 h-6">
                            <div
                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                style={{ backgroundImage: "url(" + require('../assets/images/google_search_console.png') + ")" }}></div>
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-sm font-medium">
                            Google Search Console<span className="text-purple-500 mx-1">BETA</span>{(this.state.client && this.state.client.google_search_console) && <span>connected</span>}
                        </div>
                        {
                            !(this.state.client && this.state.client.google_search_console) &&
                            <div className="text-xs text-gray-500">
                                Connect Google Search Console to this client
                            </div>
                        }
                        {
                            (this.state.client && this.state.client.google_search_console) &&
                            <div className="text-xs text-purple-500">
                                <span onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                }} className="underline cursor-pointer font-semibold">Click here</span> to show {!this.state.open && <span>selected values</span>}{this.state.open && <span>less</span>}
                            </div>
                        }
                    </div>
                    {
                        !this.state.user.id &&
                        !(this.state.client && this.state.client.google_search_console) &&
                        <div>
                            <button
                                onClick={async () => {
                                    await this.promisedSetState({
                                        open: !this.state.open
                                    });
                                    if (this.props.onSelect) {
                                        this.props.onSelect(this.state.open);
                                    }
                                    if (this.state.is_manager && !this.state.client.google_search_console) {
                                        if (this.state.open) {
                                            this.functions.getAgents(true);
                                        }
                                    }
                                }}
                                className={(this.state.open ? "bg-red-500" : "bg-purple-500") + " cursor-pointer shadow inline-flex justify-center rounded-md border border-transparent items-center h-10 w-10 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                {
                                    !this.state.open &&
                                    <span><PlusIcon className="w-5" /></span>
                                }
                                {
                                    this.state.open &&
                                    <span><XIcon className="w-5" /></span>
                                }
                            </button>
                        </div>
                    }
                    {
                        (
                            (
                                !this.state.user.id &&
                                this.state.client.google_search_console &&
                                (this.state.is_manager || (!this.state.is_manager && this.state.client.google_search_console && this.state.client.google_search_console.defaultAgent && this.state.client.google_search_console.defaultAgent.id === this.state.me.id))
                            )
                        ) &&
                        <div className="relative overflow-hidden">
                            <SwitchTailwind
                                value={this.state.client.google_search_console}
                                onSwitch={async () => {
                                    if (!this.state.client.google_search_console) {
                                        this.state.client.google_search_console = {};
                                        await this.promisedSetState({
                                            client: this.state.client
                                        });
                                    } else {
                                        this.functions.disconnectFromClient();
                                    }
                                }}
                            />
                            {
                                (this.state.loading_account) &&
                                <div className="w-full h-full bg-opacity-50 absolute bg-white top-0 bottom-0 rounded-md left-0 right-0 flex justify-center items-center">
                                    <div style={{ borderTopColor: "transparent" }}
                                        className="w-4 h-4 border-2 border-purple-500 absolute border-solid rounded-full animate-spin"></div>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className={(this.props.withBackgroundColor && this.state.open ? "bg-gray-100 p-4 rounded-md mt-4 " : "") + (this.state.open ? "" : "h-0 overflow-hidden") + " transform ease-in-out duration-100 transition"}>
                    {
                        !this.state.user.id &&
                        <div className={this.props.withBackgroundColor ? "" : "mt-4"}>
                            <DropdownTailwind
                                label={"Default agent"}
                                small={false}
                                locked={this.state.client.google_search_console}
                                skipInternalSearch={this.state.is_manager}
                                loader={this.state.loading_agents}
                                loadingPagination={this.state.loading_agents_pagination}
                                loadingSearch={this.state.loading_agents_search}
                                total={this.state.total}
                                searchInput={true}
                                placeholder={"..."}
                                defaultOptions={this.state.me.google_search_console && this.state.me.google_search_console.authenticated ? [{ id: this.state.me.id, name: "Myself (" + this.state.me.email + ")" }] : []}
                                pagination={this.state.total > (this.state.page * this.state.limit)}
                                selected={this.state.client.google_search_console && this.state.client.google_search_console.defaultAgent ? this.state.client.google_search_console.defaultAgent : this.state.agent}
                                options={this.state.agents}
                                onChange={async (value) => {
                                    await this.promisedSetState({
                                        agent: value
                                    });
                                }}
                                onPagination={async () => {
                                    if (!this.state.loading_agents_pagination) {
                                        await this.promisedSetState({
                                            page: this.state.page + 1
                                        });
                                        this.functions.getAgents(false, true, false);
                                    }
                                }}
                                onSearch={async (value) => {
                                    if (value && value === "") {
                                        await this.promisedSetState({
                                            search: "",
                                            page: 1
                                        });
                                        this.functions.getAgents(false, false, true);
                                    } else {
                                        if (!this.state.throttling.current) {
                                            if (value && value === "") {
                                                await this.promisedSetState({
                                                    search: value,
                                                    page: 1
                                                });
                                                this.functions.getAgents(false, false, true);
                                            } else {
                                                this.state.throttling.current = true;
                                                await this.promisedSetState({
                                                    search: value,
                                                    throttling: this.state.throttling
                                                });
                                                setTimeout(async () => {
                                                    this.state.throttling.current = false;
                                                    await this.promisedSetState({
                                                        throttling: this.state.throttling
                                                    });
                                                    await this.promisedSetState({
                                                        page: 1
                                                    });
                                                    this.functions.getAgents(false, false, true);
                                                }, 300);
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                    }
                    {
                        (this.state.client.id && !this.state.client.google_search_console && this.state.agent && this.state.agent.id) &&
                        <div className="flex w-full flex-row">
                            <button
                                onClick={() => {
                                    if (!this.state.loading_account) {
                                        this.functions.connectToClient();
                                    }
                                }}
                                className={(this.state.agent && this.state.agent.id ? "bg-purple-500 text-white" : "cursor-not-allowed bg-gray-300 text-gray-600") + " cursor-pointer mt-6 w-full shadow inline-flex relative justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Connect</span>
                                {
                                    this.state.loading_account &&
                                    <div className={"bg-purple-500 w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        (!this.state.user.google_search_console || (this.state.user.google_search_console && !this.state.user.google_search_console.authenticated)) &&
                        <div className="mt-5">
                            <button onClick={() => {
                                let self = this;
                                const script = document.createElement('script');
                                script.setAttribute("id", "google_api");
                                script.src = "https://accounts.google.com/gsi/client";
                                script.async = true;
                                script.defer = true;
                                script.onload = () => {
                                    const client = window.google.accounts.oauth2.initCodeClient({
                                        client_id: '794280665514-7m0nprmi6b1fed61d3aflh7ssl1s67r5.apps.googleusercontent.com',
                                        scope: 'https://www.googleapis.com/auth/webmasters https://www.googleapis.com/auth/webmasters.readonly',
                                        ux_mode: 'popup',
                                        callback: (response) => {
                                            self.functions.login(response.code);
                                        },
                                    });
                                    client.requestCode()
                                };
                                document.body.appendChild(script);

                            }} className="mt-5">
                                <img className="w-48 cursor-pointer" src={require("../assets/images/google_signin_button_2.png")} />
                            </button>
                        </div>
                    }
                    {
                        !this.state.client.id &&
                        this.state.user.google_search_console && this.state.user.google_search_console.authenticated &&
                        <div className="mt-5">
                            <button
                                onClick={() => {
                                    this.setState({
                                        remove: true
                                    });
                                }}
                                className={(!(this.state.user.google_search_console && this.state.user.google_search_console.authenticated) ?
                                    "cursor-pointer bg-google-500 text-white" : " bg-red-500 text-white cursor-pointer") + " shadow inline-flex relative justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                                }
                            >
                                <span>Remove</span>
                                {
                                    this.state.loading &&
                                    <div className={(!(this.state.user.google_search_console && this.state.user.google_search_console.authenticated) ? "bg-googleanalytics-500" : "bg-red-500") + " w-full h-full absolute  top-0 bottom-0 rounded-md border left-0 right-0 flex justify-center items-center"}>
                                        <div style={{ borderTopColor: "transparent" }}
                                            class="w-4 h-4 border-2 border-white absolute border-solid rounded-full animate-spin"></div>
                                    </div>
                                }
                            </button>
                        </div>
                    }
                    {
                        this.state.error &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            {this.state.message}
                        </div>
                    }
                    {
                        this.state.healthcheck && this.state.healthcheck.google_search_console && this.state.healthcheck.google_search_console.status != 200 &&
                        <div className="bg-red-100 p-4 mt-4 text-red-500 text-sm font-medium rounded-md">
                            Token Check: {this.state.healthcheck.google_search_console.message}. We recommend to sign out and sign in again.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.client.google_search_console &&
                        !(this.state.is_manager || (!this.state.is_manager && this.state.client.google_search_console && this.state.client.google_search_console.defaultAgent && this.state.client.google_search_console.defaultAgent.id === this.state.me.id)) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You are not "default agent" or "agency manager"
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        !this.state.is_manager &&
                        !this.state.client.google_search_console &&
                        !(this.state.me.google_search_console && this.state.me.google_search_console.authenticated) &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! You have not authenticated Google Search Console, go to My Profile.
                        </div>
                    }
                    {
                        !this.state.user.id &&
                        this.state.is_manager &&
                        !this.state.client.google_search_console &&
                        this.state.agents.length < 1 &&
                        <div className="bg-orange-100 p-4 mt-4 text-orange-500 text-sm font-medium rounded-md">
                            OBS! No available agents who has authenticated Google Search Console
                        </div>
                    }
                </div>

            </div>
        )
    }
}

export default AccountGoogleSearchConsole;
