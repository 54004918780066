import React, { Component } from 'react';
import {
    PlayIcon,
    PauseIcon,
    ClockIcon,
    ExclamationIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
} from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import moment from 'moment';
import { clientRegister } from '../services/clientRegister';
import { userRegister } from '../services/userRegister';
import ReactTooltip from 'react-tooltip'
import CampaignNotSpendingModal from '../moduleFiles/campaignNotSpendingModal';
import _ from 'lodash';

const months = [
    { id: 1, name: 'January', value: "01", unix: 0 },
    { id: 2, name: 'February', value: "02", unix: 1 },
    { id: 3, name: 'March', value: "03", unix: 2 },
    { id: 4, name: 'April', value: "04", unix: 3 },
    { id: 5, name: 'May', value: "05", unix: 4 },
    { id: 6, name: 'June', value: "06", unix: 5 },
    { id: 7, name: 'July', value: "07", unix: 6 },
    { id: 8, name: 'August', value: "08", unix: 7 },
    { id: 9, name: 'September', value: "09", unix: 8 },
    { id: 10, name: 'October', value: "10", unix: 9 },
    { id: 11, name: 'November', value: "11", unix: 10 },
    { id: 12, name: 'December', value: "12", unix: 11 }
];

const years = [
    { id: 2020, name: '2020', value: "2020" },
    { id: 2021, name: '2021', value: "2021" },
    { id: 2022, name: '2022', value: "2022" },
    { id: 2023, name: '2023', value: "2023" },
    { id: 2024, name: '2024', value: "2024" },
    { id: 2025, name: '2025', value: "2025" },
    { id: 2026, name: '2026', value: "2026" }
];

class Dashboard extends Component {

    constructor(props) {
        super(props);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        this.state = {
            loading: true,
            loading_calendar: false,
            loading_orders: true,
            loading_wizards: true,
            year: { id: year, name: year, value: year },
            years: years,
            month: months.filter((item) => { return item.unix === month })[0],
            months: months,
            days: [],
            meta: {},
            orders_statistics: {},
            wizards_statistics: {},
            client: {},
            user: {},
            statistic: 'campaign',
            campaign: false,
            loading_tokens_warnings: true,
            loading_campaigns_warnings: true,
            token_warnings: [],
            campaign_warnings: []
        };
    }

    componentDidMount() {
        this.functions.client();
        this.functions.user();
        this.functions.getTokensWarnings();
        this.functions.getCampaignsWarnings();
    }

    functions = {
        user: async () => {
            await this.promisedSetState({
                user: userRegister.get()
            });
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.campaigns(true);
            this.functions.ordersStatistics();
            this.functions.wizardStatistics();
        },
        getTokensWarnings: async () => {
            await this.promisedSetState({ loading_tokens_warnings: true });
            try {
                let response = await this.calls.getTokensWarnings();
                if (response && response.data) {
                    let uniqWarnings = _.uniqBy(response.data, item => `${item.user._id}-${item.warning}`);
                    await this.promisedSetState({ token_warnings: uniqWarnings });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_tokens_warnings: false });
        },
        getCampaignsWarnings: async () => {
            await this.promisedSetState({ loading_campaigns_warnings: true });
            try {
                let response = await this.calls.getCampaignsWarnings();
                if (response && response.data) {
                    await this.promisedSetState({ campaign_warnings: response.data });
                }
            } catch (error) { }
            await this.promisedSetState({ loading_campaigns_warnings: false });
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                client: client
            });
            this.functions.campaigns();
            this.functions.ordersStatistics();
            this.functions.wizardStatistics();
            this.functions.getCampaignsWarnings();
        },
        orders: async (init) => {
            await this.promisedSetState({
                loading_orders: true,
                days: this.functions.getMonthDetails(this.state.year.value, this.state.month.unix)
            });
            try {
                let response = await this.calls.orders();

                //ADD ORDERS TO DAYS
                response.data.map((order) => {
                    this.state.days = this.state.days.map((day) => {
                        if (moment(order.startDate).format("YYYY-MM-DD") == day.date) {
                            day.events.push(order);
                        }
                        return day;
                    });
                });

                await this.promisedSetState({ orders: response.data, days: this.state.days });
            } catch (error) { }
            await this.promisedSetState({
                loading_orders: false
            });
        },
        campaigns: async (init) => {
            await this.promisedSetState({
                loading_campaigns: true,
                days: this.functions.getMonthDetails(this.state.year.value, this.state.month.unix)
            });
            try {
                let response = await this.calls.campaigns();

                //ADD CAMPAIGNS TO DAYS
                response.data.map((campaign) => {
                    campaign.startDate = campaign.campaignStartDate;
                    campaign.endDate = campaign.campaignEndDate;

                    this.state.days = this.state.days.map((day) => {
                        if (moment(campaign.startDate).format("YYYY-MM-DD") == day.date) {
                            let index = day.events.findIndex(item => item.channel === campaign.channel)
                            if (index >= 0) {
                                day.events[index].channel_ids.push(campaign._id);
                                day.events[index].channel_names.push(campaign.campaignName);
                            } else {
                                campaign.channel_ids = [campaign._id];
                                campaign.channel_names = [campaign.campaignName];
                                day.events.push(campaign);
                            }
                        }
                        return day;
                    });
                });

                await this.promisedSetState({ campaigns: response.data, days: this.state.days });
            } catch (error) { }
            await this.promisedSetState({ loading_campaigns: false });
        },
        ordersStatistics: async () => {
            await this.promisedSetState({ loading_orders_statistics: true });
            try {
                let response = await this.calls.ordersStatistics();
                await this.promisedSetState({ orders_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_orders_statistics: false });
        },
        wizardStatistics: async () => {
            await this.promisedSetState({ loading_wizards_statistics: true });
            try {
                let response = await this.calls.wizardStatistics();
                await this.promisedSetState({ wizards_statistics: response.data });
            } catch (error) { }
            await this.promisedSetState({ loading_wizards_statistics: false });
        },
        getMonthDetails: (year, month) => {
            let firstDay = (new Date(year, month)).getDay() - 1;
            let numberOfDays = this.functions.getNumberOfDays(year, month);
            let monthArray = [];
            let rows = 6;
            let currentDay = null;
            let index = 0;
            let cols = 7;
            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    currentDay = this.functions.getDayDetails({
                        index,
                        numberOfDays,
                        firstDay,
                        year,
                        month
                    });
                    monthArray.push(currentDay);
                    index++;
                }
            }
            return monthArray;
        },
        getDayDetails: (args) => {
            let date = args.index - args.firstDay;
            let day = args.index % 7;
            let prevMonth = args.month - 1;
            let prevYear = args.year;
            if (prevMonth < 0) {
                prevMonth = 11;
                prevYear--;
            }
            let prevMonthNumberOfDays = this.functions.getNumberOfDays(prevYear, prevMonth);
            let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
            let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
            args.month = args.month + month;
            let date_correct_format = moment(new Date(args.year, args.month, _date)).format('YYYY-MM-DD')
            return {
                date: date_correct_format,
                day,
                isCurrentMonth: month === 0,
                isToday: date_correct_format === moment().format('YYYY-MM-DD'),
                events: []
            }
        },
        getNumberOfDays: (year, month) => {
            return 40 - new Date(year, month, 40).getDate();
        }
    };

    calls = {
        orders: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listOrders?year=" + this.state.year.value + (this.state.days.length > 0 ? ("&startSpan=" + this.state.days[0].date + "&endSpan=" + this.state.days[(this.state.days.length - 1)].date) : "") + (this.state.client.id && this.state.client.id !== 0 ? ("&client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        campaigns: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/exernal-campaigns-list?year=" + this.state.year.value + (this.state.days.length > 0 ? ("&startSpan=" + this.state.days[0].date + "&endSpan=" + this.state.days[(this.state.days.length - 1)].date) : "") + (this.state.client.id && this.state.client.id !== 0 ? ("&client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        ordersStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/ordersStatistics" + (this.state.client.id && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        getTokensWarnings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWarnings?group=user,warning&with=user&type=Token error";
            return apiRegister.call(options, url);
        },
        getCampaignsWarnings: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWarnings?type=Active order with no spend" + (this.state.client.id && this.state.client.id !== 0 ? ("&client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        },
        wizardStatistics: () => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getWizardStatistics" + (this.state.client.id && this.state.client.id !== 0 ? ("?client=" + this.state.client.id) : "");
            return apiRegister.call(options, url);
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex-1 flex flex-col">

                <CampaignNotSpendingModal
                    open={this.state.campaign ? true : false}
                    campaign={this.state.campaign}
                    onClose={() => {
                        this.setState({
                            campaign: null
                        });
                    }}
                />

                {/*LOADING VIEW*/}
                {
                    false &&
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col w-full">
                        <div style={{ borderTopColor: "transparent" }} className="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading ...</div>
                    </div>
                }

                {/*TOP SECTIONS*/}
                {
                    //!this.state.loading &&
                    <div className="p-4 pb-0">
                        <div className="flex flex-1 flex-col">
                            <div className={"grid grid-cols-1 sm:grid-cols-4 gap-4 mb-4"}>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/active")
                                }} className="col-span-1 cursor-pointer bg-white rounded-lg overflow-hidden relative shadow p-4 flex flex-row items-center">
                                    {
                                        this.state.loading_orders_statistics &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <div className="flex flex-1 flex-col">
                                        <div className="leading-6 text-2xl font-bold pt-1">{!this.state.loading_orders_statistics ? this.state.orders_statistics.active : "-"}</div>
                                        <p className="mt-1 text-xs text-gray-700">Active orders</p>
                                    </div>
                                    <div className="bg-green-100 h-12 w-12 flex justify-center items-center rounded-full">
                                        <PlayIcon className="h-6 w-6 text-green-500" />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/paused")
                                }} className="col-span-1 cursor-pointer bg-white overflow-hidden rounded-lg shadow p-4 flex flex-row relative">
                                    {
                                        this.state.loading_orders_statistics &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <div className="flex flex-1 flex-col">
                                        <div className="leading-6 text-2xl font-bold  pt-1">{!this.state.loading_orders_statistics ? this.state.orders_statistics.paused : "-"}</div>
                                        <p className="mt-1 text-xs text-gray-700">Paused orders</p>
                                    </div>
                                    <div className="bg-orange-100 h-12 w-12  flex justify-center items-center rounded-full">
                                        <PauseIcon className="h-6 w-6 text-orange-500" />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    this.props.history.push("/v2/orders/upcoming")
                                }} className="col-span-1 cursor-pointer bg-white overflow-hidden rounded-lg shadow p-4 flex flex-row relative">
                                    {
                                        this.state.loading_orders_statistics &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <div className="flex flex-1 flex-col">
                                        <div className="leading-6 text-2xl font-bold  pt-1">{!this.state.loading_orders_statistics ? this.state.orders_statistics.upcoming : "-"}</div>
                                        <p className="mt-1 text-xs text-gray-700">Upcoming orders</p>
                                    </div>
                                    <div className="bg-purple-100 h-12 w-12  flex justify-center items-center rounded-full">
                                        <ClockIcon className="h-6 w-6 text-purple-500" />
                                    </div>
                                </div>
                                <div onClick={() => {
                                    if (!(this.state.user && this.state.user.userRole && this.state.user.userRole === "sales")) {
                                        this.props.history.push("/v2/warnings");
                                    }
                                }} className={"col-span-1 bg-white rounded-lg relative overflow-hidden shadow p-4 flex flex-row" + (this.state.user && this.state.user.userRole && this.state.user.userRole === "sales" ? " cursor-not-allowed" : " cursor-pointer")}>
                                    {
                                        this.state.loading_wizards_statistics &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }
                                    <div className="flex flex-1 flex-col">
                                        <div className="leading-6 text-2xl font-bold  pt-1">{!this.state.loading_wizards_statistics ? this.state.wizards_statistics.error : "-"}</div>
                                        <p className="mt-1 text-xs text-gray-700">Errors</p>
                                    </div>
                                    <div className="bg-red-100 h-12 w-12  flex justify-center items-center rounded-full">
                                        <ExclamationIcon className="h-6 w-6 text-red-500" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {/*CALENDAR AND CAMPAIGNS NOT SPENDING*/}
                {
                    //!this.state.loading &&
                    <div className="flex flex-1 p-4 pt-0">
                        <div className={" bg-white rounded-lg shadow overflow-hidden flex flex-2 flex-col"}>
                            <div className="p-4 flex flex-row border-b w-full items-center">
                                <div className="flex flex-1 flex-col justify-start items-start">
                                    <div className="font-semibold text-sm">{this.state.month.name}</div>
                                    <div className=" text-gray-700 text-xs"> {this.state.year.name}</div>
                                </div>
                                <div className='flex flex-row'>
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (!this.state.loading_campaigns) {
                                                let month = null;
                                                let year = null;
                                                if (this.state.month.id === 1) {
                                                    month = { id: 12, name: 'December', value: "12", unix: 11 };
                                                    year = years.filter((item) => { return item.id == (+this.state.year.id - 1) })[0];
                                                } else {
                                                    month = months.filter((item) => { return item.id == (+this.state.month.id - 1) })[0];
                                                }
                                                await this.promisedSetState({
                                                    month: month ? month : this.state.month,
                                                    year: year ? year : this.state.year
                                                });
                                                this.state.statistic === 'order' ? this.functions.orders() : this.functions.campaigns();
                                            }
                                        }}
                                        className="inline-flex items-center mr-2 px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none border focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <ArrowLeftIcon className="w-4" />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (!this.state.loading_campaigns) {
                                                let month = null;
                                                let year = null;
                                                if (this.state.month.id === 12) {
                                                    month = { id: 1, name: 'January', value: "01", unix: 0 };
                                                    year = years.filter((item) => { return item.id == (+this.state.year.id + 1) })[0];
                                                } else {
                                                    month = months.filter((item) => { return item.id == (+this.state.month.id + 1) })[0];
                                                }
                                                await this.promisedSetState({
                                                    month: month ? month : this.state.month,
                                                    year: year ? year : this.state.year
                                                });
                                                this.state.statistic === 'order' ? this.functions.orders() : this.functions.campaigns();
                                            }
                                        }}
                                        className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:border-gray-500 focus:outline-none border focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <ArrowRightIcon className="w-4" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-1 w-full">
                                <div className="w-full h-full flex flex-col">
                                    <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                                        <div className="bg-white py-2">
                                            M<span className="sr-only sm:not-sr-only">on</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            T<span className="sr-only sm:not-sr-only">ue</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            W<span className="sr-only sm:not-sr-only">ed</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            T<span className="sr-only sm:not-sr-only">hu</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            F<span className="sr-only sm:not-sr-only">ri</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            S<span className="sr-only sm:not-sr-only">at</span>
                                        </div>
                                        <div className="bg-white py-2">
                                            S<span className="sr-only sm:not-sr-only">un</span>
                                        </div>
                                    </div>

                                    <div className="flex bg-white text-xs flex-1 relative leading-6 text-gray-700">

                                        {/*LOADER PARTIAL*/}
                                        {
                                            this.state.loading_campaigns &&
                                            <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                <div style={{ borderTopColor: "transparent" }} className="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                            </div>
                                        }

                                        <div className="absolute bg-white w-full h-full overflow-scroll">
                                            <div className="w-full bg-gray-200 border-b grid grid-cols-7 grid-rows-6 gap-px">
                                                {this.state.days.map((day, index) => (
                                                    <div
                                                        onClick={() => {
                                                            let campaigns = [];
                                                            if (Array.isArray(day.events) && day.events.length > 0) {
                                                                day.events.map((event) => {
                                                                    campaigns = campaigns.concat(event.channel_ids);
                                                                });
                                                                this.props.history.push("/v2/reports/campaigns?ids=" + campaigns.join(','));
                                                            }
                                                        }}
                                                        key={index}
                                                        className={classNames(
                                                            day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                                                            'relative py-2 px-3 hover:bg-gray-50 font-medium cursor-pointer'
                                                        )}
                                                    >
                                                        <time
                                                            dateTime={day.date}
                                                            className={
                                                                day.isToday
                                                                    ? 'flex h-6 w-6 items-center justify-center text-purple-500'
                                                                    : undefined
                                                            }
                                                        >
                                                            {day.date.split('-').pop().replace(/^0/, '')}
                                                        </time>
                                                        <ol className="mt-3 mb-3 flex flex-row">
                                                            {Array.isArray(day.events) && day.events.map((event) => (
                                                                <li key={event._id}>
                                                                    <div data-tip='' data-for={event._id} className="group relative flex cursor-pointer">
                                                                        {this.state.statistic === 'campaign' &&
                                                                            <div className="flex flex-row">
                                                                                {
                                                                                    Array.isArray(event.channel_ids) &&
                                                                                    event.channel_ids.map((item, index) => {
                                                                                        let image_path = "";
                                                                                        if (event.channel == 'linkedin' || event.channel == 'snapchat') {
                                                                                            image_path = event.channel + "_icon.svg"
                                                                                        } else {
                                                                                            image_path = event.channel + "_icon.png"
                                                                                        }
                                                                                        return (
                                                                                            <div key={`${event.id}_${index}`} className={(index !== 0 ? "-ml-3 " : "") + ""}>
                                                                                                <div className={`w-6 h-6 bg-${event.channel}-500 rounded-full p-1.5 border-1.5 border-white`}>
                                                                                                    <div className="block w-full h-full bg-contain bg-no-repeat bg-center" style={{ backgroundImage: "url(" + require(`../assets/images/${image_path}`) + ")" }}></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {
                                                                            Array.isArray(event.channel_names) &&
                                                                            this.state.statistic === 'campaign' &&
                                                                            <ReactTooltip effect='solid' id={event._id}>
                                                                                <ul>
                                                                                    {event.channel_names.map((name) => <li key={`${event.id}_${name}`} className="flex-row flex">&#x2022;<div className="w-1"></div>{name}</li>)}
                                                                                </ul>
                                                                            </ReactTooltip>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            ))}
                                                            {
                                                                day.show_all &&
                                                                <li onClick={() => {
                                                                    day.show_all = !day.show_all;
                                                                    this.setState({
                                                                        days: this.state.days
                                                                    });
                                                                }} className="text-gray-500 cursor-pointer hover:text-purple-600">
                                                                    Show less
                                                                </li>
                                                            }
                                                            {!day.show_all && Array.isArray(day.events) && day.events.length > 2 && <li
                                                                onClick={() => {
                                                                    day.show_all = !day.show_all;
                                                                    this.setState({
                                                                        days: this.state.days
                                                                    });
                                                                }} className="text-gray-500 cursor-pointer hover:text-purple-600">+ {day.events.length - 2} more</li>}
                                                        </ol>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        {
                            <div className="flex flex-1 pl-4">
                                <div className={" bg-white rounded-lg w-full shadow overflow-hidden flex flex-col"}>
                                    <div className={(((this.state.loading_tokens_warnings) || (!this.state.loading_tokens_warnings && Array.isArray(this.state.token_warnings) && this.state.token_warnings.length > 0)) ? "flex-1" : "") + " flex flex-col"}>
                                        <div className={(((this.state.loading_tokens_warnings) || (!this.state.loading_tokens_warnings && Array.isArray(this.state.token_warnings) && this.state.token_warnings.length > 0)) ? "border-b" : "") + " p-4 w-full items-center"}>
                                            <div className="flex flex-1 flex-col justify-start items-start">
                                                <div className="font-semibold text-sm">Token errors</div>
                                                {
                                                    !this.state.loading_tokens_warnings &&
                                                    <div className=" text-gray-700 text-xs">{Array.isArray(this.state.token_warnings) ? this.state.token_warnings.length : "0"} warnings</div>
                                                    ||
                                                    <div className=" text-gray-700 text-xs">...</div>
                                                }
                                            </div>
                                        </div>
                                        {
                                            ((this.state.loading_tokens_warnings) || (!this.state.loading_tokens_warnings && Array.isArray(this.state.token_warnings) && this.state.token_warnings.length > 0)) &&
                                            <div className="flex flex-1 relative">
                                                {
                                                    this.state.loading_tokens_warnings &&
                                                    <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                        <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                    </div>
                                                }
                                                <div className="absolute w-full overflow-y-scroll h-full">
                                                    {
                                                        Array.isArray(this.state.token_warnings) &&
                                                        this.state.token_warnings.map((item, index) => {
                                                            return (
                                                                <div key={item._id} onClick={() => {
                                                                    if (this.state.user.email === item.user.email) {
                                                                        let channel = item.warning.slice(0, item.warning.indexOf(':'))
                                                                        this.props.history.push({
                                                                            pathname: '/v2/profile/connections',
                                                                            state: { error: channel }
                                                                        })
                                                                    }
                                                                }}
                                                                    className={(index > 0 ? "border-t" : "") + " w-full flex h-14 hover:bg-gray-50 px-4 items-center justify-center flex-row " + (this.state.user.email === item.user.email ? "cursor-pointer" : ' ')}>
                                                                    <div
                                                                        className="bg-red-100 h-8 w-8 flex justify-center items-center rounded-full">
                                                                        <ExclamationIcon
                                                                            className="w-4 text-red-500" />
                                                                    </div>
                                                                    <div className="flex flex-1 pl-3 flex-col">
                                                                        <div className="text-xs font-medium">
                                                                            {item.user.email}
                                                                        </div>
                                                                        <div className="text-xs font-semibold">
                                                                            {item.warning}
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.user.email === item.user.email &&
                                                                        <div className="tect-gray-500">
                                                                            <ArrowRightIcon className="w-5"></ArrowRightIcon>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex flex-1 flex-col border-t">
                                        <div className="p-4 border-b w-full items-center">
                                            <div className="flex flex-1 flex-col justify-start items-start">
                                                <div className="font-semibold text-sm">Orders not delivering</div>
                                                {
                                                    !this.state.loading_campaigns_warnings &&
                                                    <div className=" text-gray-700 text-xs">{Array.isArray(this.state.campaign_warnings) ? this.state.campaign_warnings.length : "0"} warnings</div>
                                                    ||
                                                    <div className=" text-gray-700 text-xs">...</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-1 relative">
                                            {
                                                this.state.loading_campaigns_warnings &&
                                                <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} className="w-6 h-6 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }
                                            <div className="absolute w-full overflow-y-scroll h-full">
                                                {
                                                    Array.isArray(this.state.campaign_warnings) &&
                                                    this.state.campaign_warnings.map((item, index) => {
                                                        return (
                                                            <div key={item._id} onClick={() => {
                                                                if (window.location.hostname === 'localhost') {
                                                                    window.open("http://localhost:5001/v2/orders/" + item.order + "/budgetplaner");
                                                                } else if (window.location.hostname === 'app.adcredo.io') {
                                                                    window.open("https://app.adcredo.io/v2/orders/" + item.order + "/budgetplaner");
                                                                } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                    window.open("https://dev.adcredo.io/v2/orders/" + item.order + "/budgetplaner");
                                                                }
                                                            }}
                                                                className={(index > 0 ? "border-t" : "") + " w-full flex h-14 hover:bg-gray-50 px-4 items-center justify-center flex-row cursor-pointer"}>
                                                                <div
                                                                    className="bg-red-100 h-8 w-8 flex justify-center items-center rounded-full">
                                                                    <ExclamationIcon
                                                                        className="w-4 text-red-500" />
                                                                </div>
                                                                <div className="flex flex-1 pl-3 flex-col">
                                                                    <div className="text-xs font-medium">
                                                                        {item.orderName}
                                                                    </div>
                                                                    <div className="text-xs font-semibold">
                                                                        Start: <span className="text-xs font-semibold">{item.startDate}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="tect-gray-500">
                                                                    <ArrowRightIcon className="w-5"></ArrowRightIcon>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                }

            </div>
        )
    }
}

export default Dashboard;